<div class="activity-container">
  <div class="activity-header">
    <div class="activity-header-text">
      Activity
    </div>
    <div class="default-header-type" (click)="changeActiveType($event, headerTypeAll)"
        [ngClass]="activeHeaderType == headerTypeAll ? 'active-header-type': 'inactive-header-type'">
      {{headerTypeAll}}
    </div>
    <div class="default-header-type" (click)="changeActiveType($event, headerTypeOrder)"
          [ngClass]="activeHeaderType == headerTypeOrder ? 'active-header-type': 'inactive-header-type'">
      {{headerTypeOrder}}
    </div>
    <div class="default-header-type" (click)="changeActiveType($event, headerTypeItems)"
        [ngClass]="activeHeaderType == headerTypeItems ? 'active-header-type': 'inactive-header-type'">
      <div class="current-item-text">
        <span> {{ itemDisplay() }} </span>
      </div>
      <div class="items-dropdown-container custom-scrollbar-close" *ngIf="showItemDropdown && currentItems.length"
          (outsideClick)="outsideItemClick()">
        <div class="specific-item" (click)="filterItems($event, which)"
              *ngFor="let which of currentItems">
          <span> {{ singleItemDisplay(which) }} </span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading" class="activity-history-container">
    <p-skeleton [width]="'80px'" [height]="'14px'" styleClass="mb-2" borderRadius="16px"></p-skeleton>
    <p-skeleton [width]="'30px'" [height]="'14px'" styleClass="mb-2" borderRadius="16px"></p-skeleton>
    <p-skeleton [width]="'60px'" [height]="'14px'" styleClass="mb-2" borderRadius="16px"></p-skeleton>
  </div>
  <div *ngIf="!isLoading" class="activity-history-container custom-scrollbar-close"
        [ngStyle]="{'max-height': getMaxHeight}">
    <div class="single-history-container" *ngFor="let history of filteredHistories">
      <div class="activity-user">
        <span> {{history.user}} </span>
      </div>
      <div class="activity-history-text">
        <span>{{displayHistory(history)}}</span>
      </div>
      <div class="activity-history-date">
        <span>{{history.date}}</span>
      </div>
    </div>
  </div>
</div>
<!--<materlog-loader class="loader" *ngIf="isLoading "></materlog-loader>-->
<materlog-loader class="loader-center" *ngIf="isLoading || isGridDestroyed()"></materlog-loader>

<ag-grid-angular *ngIf="gridVisible && doneLoad"
  style="width: 100%; height: 100%"
  class="ag-theme-balham ag-grid-table"
  [gridOptions]="gridOptions"
  [defaultColDef]="defaultColDef"
  [groupDefaultExpanded]="-1"
  [columnDefs]="columns"
  [rowGroupPanelShow]="'never'"
  [groupDisplayType]="'groupRows'"
  [groupHideOpenParents]="false"
  [columnTypes]="columnTypes"
  [rowModelType]="'clientSide'"
  [enableAdvancedFilter]="true"
  [rowData]=jobs
  [suppressScrollOnNewData]="true"
  [stopEditingWhenCellsLoseFocus]="false"
  [rowSelection]="'single'"
  [getRowId]="getRowId"
  [noRowsOverlayComponent]="noRowsOverlayComponent"
  [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
  [getContextMenuItems]="getContextMenuItems"
  [statusBar]="statusBar"

  [includeHiddenColumnsInAdvancedFilter]="true"
  [animateRows]="false"
  [enableCellChangeFlash]="false"
  [rowBuffer]="20"

  [debounceVerticalScrollbar]="false"
  (gridReady)="onGridReady($event)"
  (rowDataUpdated)="onRowDataUpdated($event)"
  (sortChanged)="sortChanged($event)"
  (dragStopped)="columnResizedOrReordered($event)"
  (columnPinned)="interestingColumnEvent($event)"
  (cellValueChanged)="onCellValueChanged($event)"
  (filterChanged)="onFilterChanged($event)"
  [context]="dropdownOptions"
  (onModelUpdated)="onModelUpdated($event)"
  (columnEverythingChanged)="columnEverythingChanged($event)"

></ag-grid-angular>
<!--
(columnMoved)="interestingColumnEvent($event)"
(columnResized)="interestingColumnEvent($event)"
(columnRowGroupChanged)="columnGrouped($event)"
-->

<popup-comment
  *ngIf="showPopupComment"
  [rowNode]="currentRow"
  [formattedCell]="formattedCell"
  [mousePosition]="mousePosition"
  [orgUserEmails]="orgUserEmails"
  (closePopup)="showPopupComment = false"
></popup-comment>


<split-item
  *ngIf="showSplitItem"
  [rowNode]="currentRow"
  [mousePosition]="mousePosition"
  (closePopup)="showSplitItem = false"
  (newSubItems)="handleNewSubItems($event)"
></split-item>

<change-item-job
  *ngIf="showItemJob"
  [rowNode]="currentRow"
  [jobs]="jobs"
  [mousePosition]="mousePosition"
  (closePopup)="changeItemJob($event)"
  [activeJobsList]="activeJobsList"
  >
</change-item-job>


<p-dialog
  [(visible)]="showDialog"
  [style]="{ marginTop: '250px', width: '450px' }"
  [closeOnEscape]="false"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>Delete item</span>
    </div>
    <button class="dialog-close" type="button" (click)="closeDialog()"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="dialog__content">
      <span class="dialog__content-info">
        Are you sure you want to delete this item? Deleting the item means this record will no longer show up for anyone
        at your company and will no longer be tracked. This cannot be undone.
      </span>

      <div class="dialog__content-radio" *ngFor="let reason of dropdownOptions?.reason_to_not_display?.slice(1)">
        <p-radioButton [value]="reason.id" [label]="reason.value" [(ngModel)]="reasonToDelete"></p-radioButton>
      </div>

      <textarea
        *ngIf="reasonToDelete == 3"
        class="dialog__content-input dialog__content-input-text"
        pInputTextarea
        [(ngModel)]="reasonToDeleteText"
        placeholder="Please tell us why ..."
        maxlength="250"
        minlength="1"
      ></textarea>
      <span class="textfield__error-message" *ngIf="isSubmitted && isDeleteError">Please provide a reason</span>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="dialog-supplier__content-action">
      <button class="dialog-supplier__content-action-cancel" type="button" (click)="closeDialog()">Cancel</button>
      <button class="dialog-supplier__content-action-button" type="submit" (click)="deleteItem(deleteRowId)">
        Delete
      </button>
    </div>
  </ng-template>
</p-dialog>

import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { IRequestViewsPayload } from '../interfaces/request-views-payload.interface';

@Injectable({
  providedIn: 'root',
})
export class PayloadService {
  constructor() { }

  // if view id for the selected job is available returns the
  // results for the view id, if not available returns the results for job id,
  // if job id is not available returns all results.
  setViewsPayload(): IRequestViewsPayload {
    let savedViewId;
    let savedJobId = JSON.parse(localStorage.getItem('selectedJob') as any);

    let payload: IRequestViewsPayload = {
      page: 1,
      page_size: CommonConstants.resultsPerPage,
      include_items:
        savedJobId === 'all'
          ? CommonConstants.include_items.all
          : savedJobId === 'uncategorized'
            ? CommonConstants.include_items.uncategorized
            : CommonConstants.include_items.with_job,
      history_interval: localStorage.getItem('history') ? localStorage.getItem('history') : 0,
    };

    if (!!savedJobId) {
      savedViewId = localStorage.getItem(`viewIdFor${savedJobId}`) as any;

      if (savedJobId === 'uncategorized') {
        delete payload.history_interval;
        payload = { ...payload};
        if (!!savedViewId) payload = { ...payload, view_id: JSON.parse(savedViewId) };
      } else if (!!savedViewId) {
        savedViewId = JSON.parse(savedViewId);
        payload = { ...payload, view_id: savedViewId};
        if (savedJobId !== 'all') payload = {...payload, job_id: savedJobId}
      } else if (savedJobId !== 'all') {
          payload = { ...payload, job_id: savedJobId };
        }
      }
    return payload;
  }
}

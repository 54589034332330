import { IStoreApiItem, StoreApiItem } from "src/app/common/models/store-api-item.model";
import { IOrganization } from "../../models/company.model";

export interface IOrganizationState {
  organization: IStoreApiItem<IOrganization>;
}

export class OrganizationState implements IOrganizationState {
  organization: IStoreApiItem<IOrganization> = new StoreApiItem();
}

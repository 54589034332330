import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs';

import { TableConstants } from 'src/app/common/constants/table.constants';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IStoreApiList } from 'src/app/common/models/store-api-list.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { ReceiversActions } from '../../state/actions/receivers.actions';
import { selectReceiversPage, selectReceiverOptions } from '../../state/selectors/receivers-page.selector';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';

@Component({
  selector: 'materlog-receivers-page',
  templateUrl: './receivers-page.component.html',
  styleUrls: ['./receivers-page.component.sass'],
})
export class ReceiversPageComponent extends OnDestroyMixin() implements OnInit {
  isLoading = true;
  receivers: any[] = [];
  nextPage?: number | null;
  columnsReceivers = TableConstants.receiversTableColumns;
  columnsReceiverContacts = TableConstants.receiverContactsTableColumns;
  customColumnsLayout = [
    'index', 'expansion_button',
  ];
  dropdownOptions: any;
  private resultsPerPage: number = CommonConstants.resultsPerPage;

  constructor(
    private store: Store<IApplicationState>,
    private receiversActions: ReceiversActions,
  ) {
    super();
  }

  ngOnInit(): void {
    this.onRequestReceiversPage();
    this.onRequestOptions();
  }

  onRequestOptions() {
    this.receiversActions.requestReceiverOptions();
    this.store.pipe(
      select(selectReceiverOptions),
      filter((options: IStoreApiItem<IOptions>) => !options.isLoading),
      take(1)
    ).subscribe((userOptions: IStoreApiItem<IOptions>) => {
      if (userOptions?.data) {
        this.dropdownOptions = {
          state: userOptions?.data?.states,
          country: userOptions?.data?.countries,
        }
      }
    });
  }

  onRequestReceiversPage() {
    this.receiversActions.requestReceiversPage({ page_size: this.resultsPerPage, page: 1 });

    this.store
      .pipe(
        select(selectReceiversPage),
        filter((receiversPage: IStoreApiList<any>) => !receiversPage.isLoading),
        takeUntil(this.destroy)
      )
      .subscribe((receiversPage: IStoreApiList<any>) => {
        this.isLoading = false;
        this.nextPage = this.extractNextPageFromUrl(receiversPage.data.next);

        if (this.nextPage && this.nextPage == 2) {
          this.receivers = receiversPage?.data.results;
        } else {
          this.receivers = this.receivers.concat(receiversPage?.data.results);
        }
      });
  }

  trackByRows(index: number, item: any): string {
    return index + item?.id;
  }

  loadJobsLazy(): any {
    if (this.nextPage) this.receiversActions.requestReceiversPage({ page_size: this.resultsPerPage, page: this.nextPage });
  }

  private extractNextPageFromUrl(url: any): number | null {
    if (!!url) {
      const urlParts = url.match(/page=([^&]*)/);
      return !!urlParts ? urlParts[1] : null;
    }
    return null;
  }
}
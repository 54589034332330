<materlog-templates *ngIf="showTemplates"
    [selectedView]="selectedView"
    [show]="showTemplates"
    (close)="showTemplates = false">
</materlog-templates>

<div class="filters"
    (click)="showTemplatesModal($event)"
    (outsideClick)="outsideClickModal($event)">
  <span
    [ngClass]="{ 'templates-background': !isLoading && selectedView?.activeView?.template_id }"
  >
    <fa-icon class="filters__icon" [icon]="templateIcon"></fa-icon>
    {{templateText}}
  </span>

  <div
    class="filters__container-templates"
    *ngIf="toggleTemplatesModal"
    (click)="emptyTemplateClick($event)"
  >
    <div class="filters__container-templates-item" (click)="onOpenCreateTemplateModal($event)">
      <fa-icon class="filters__icon" [icon]="templateIcon"></fa-icon>
      Create template
    </div>

    <div class="filters__container-templates-item filters__container-templates-item--no-hover">
      <div class="filters__container-templates-item-row filter-text">
        <span>
          <fa-icon class="filters__icon me-1" [icon]="applyIcon"></fa-icon>
          Apply template
        </span>
        <p-dropdown
          optionLabel="name"
          placeholder="Select template"
          optionValue="id"
          [options]="activeTemplates"
          [ngModel]="selectedView?.activeView?.template_id"
          (onChange)="onApplyTemplate($event)"
        >
        </p-dropdown>
      </div>
    </div>
    <div
      class="filters__container-templates-item"
      [ngClass]="{ 'filters__container-templates-item--disabled': isSaveTemplateDisabled }"
      (click)="isSaveTemplateDisabled ? null : onSaveTemplate($event)"
    >
      <fa-icon class="filters__icon" [icon]="saveIcon"></fa-icon>
      Update template
    </div>

    <div
      class="filters__container-templates-item"
      [ngClass]="{ 'filters__container-templates-item--disabled': !selectedView?.activeView?.template_id }"
      (click)="selectedView?.activeView?.template_id ? onResetTemplate($event) : null"
    >
      <fa-icon class="filters__icon" [icon]="resetIcon"></fa-icon>
      Reset template
    </div>

    <div class="filters__container-templates-item" (click)="onViewTemplates()">
      <fa-icon class="filters__icon" [icon]="filtersIcon"></fa-icon>
      View all templates
    </div>

    <div class="filters__container-templates-item" (click)="onDeleteTemplate($event)">
      <fa-icon class="filters__icon" [icon]="trashIcon" style="font-size: .9rem;"></fa-icon>
      Remove template
    </div>
  </div>
</div>

<form>
  <p-dialog
    [(visible)]="addTemplateNameModal"
    [modal]="true"
    [resizable]="false"
    [draggable]="false"
    [closable]="false"
    [closeOnEscape]="false"
    [position]="'top'"
    [style]="{ marginTop: '150px', width: '588px' }"
    [maskStyleClass]="'dialog-mask small-dialog'"
  >
    <ng-template pTemplate="header">
      <div class="dialog__header">
        <fa-icon [icon]="addTemplateNameModal ? templateIcon : saveIcon"></fa-icon>
        Create template
      </div>
      <button class="dialog-close" type="button" (click)="onCloseAddTemplateNameModal()">
        <i class="bi bi-x-lg"></i>
      </button>
    </ng-template>
    <div class="row-with-label">
      <label
        [ngClass]="isAddEditTemplateNameSubmitted && !addTemplateForm.name.trim() ? 'warning' : null"
        for="addEditTemplateName"
      >
        Add a template name
      </label>
      <input
        pInputText
        type="text"
        id="addEditTemplateName"
        name="addEditTemplateName"
        placeholder="Name"
        [(ngModel)]="addTemplateForm.name"
      />
    </div>
    <div class="privacy">
      <div>Who can view and edit this template:</div>
      <div class="privacy__input" *ngFor="let dropdownOption of dropdownOptions">
        <p-radioButton
          name="privacy"
          [value]="dropdownOption.id"
          [label]="dropdownOption.value"
          [(ngModel)]="addTemplateForm.privacy"
        ></p-radioButton>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <div class="dialog__footer">
        <div>View all your templates <span class="link" (click)="onViewTemplates()">here</span>.</div>
        <button
          pButton
          type="submit"
          label="Save"
          class="button-primary p-button-raised mt-4"
          (click)="onSaveAddTemplate()"
        ></button>
      </div>
    </ng-template>
  </p-dialog>
</form>

<div class="approvals-container">
  <div class="icon-container" [ngClass]="sidePanelOpen ? 'ic-open' : 'ic-close'">
    <i [ngClass]="sidePanelOpen ? 'bi bi-arrow-bar-left' : 'bi bi-arrow-bar-right'" 
      (click)="sidePanelOpen = !sidePanelOpen">
    </i>
  </div>
  <div class="approvals-list" *ngIf="sidePanelOpen">
    <div class="approval-description">
      <strong>Pulls.</strong> This is the information MaterLog auto-magically pulled from your email. Our tech is pretty good but we know you care about every decimal. Please review the data pulled (new and updated info in <strong>BLACK</strong>; existing unchanged info in GREY). Any updates will reflect on the item tracker. Hit "Confirm" to let your coworkers know you've reviewed the pull. Pull emails disappear from this queue after 1 week."
    </div>

    <div class="approval-list-mail-short custom-scrollbar-close">
      <div class="single-approval"
          [ngClass]="item.id == selectedId ? 'selected-item' : null"
          (click)="emailClicked(item)" *ngFor="let item of approvals">
        <div class="item-not-confirmed" *ngIf="!item.viewed">
          <i class="bi bi-dot" style="color: blue; font-size: 28px"></i>
        </div>
        <div class="item-update-or-new"> {{ item.is_new_order ? 'New' : 'Update' }}</div>
        <div class="item-information">
          <div class="item-row-1">
            <div class="item-subject">
              {{item.infos?.subject ? item.infos.subject: ''}}
            </div>
            <div class="ml-auto item-date">
              {{ formatDate(item) }}
            </div>
          </div>
          <div class="item-row-2">
            <div class="email-value-display">
              <div class="badge-default status-color-cell">
                <span class="inner-text">{{ item.job }}</span>
              </div>
            </div>
            <div class="email-value-display">
              <div class="badge-outline status-color-cell">
                <span class="inner-text">{{ item.supplier }}</span>
              </div>
            </div>
            <div class="email-value-display">
              <div class="badge-secondary status-color-cell">
                <span class="inner-text">{{ item.order }}</span>
              </div>
            </div>
            <div class="mark-read ml-auto" (click)="markReadTooltip($event, item)">
              <i class="bi bi-three-dots"></i>
            </div>
            <div class="mark-read-tooltip" *ngIf="item.markedRead">
              <div class="new-option flex flex-row" (click)="markItemAsRead($event, item)">
                <i class="bi bi-check-lg"></i>
                <div class="confirm-text">
                  {{ !item.viewed ? 'Mark read' : 'Mark unread'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="email-viewer-container" #emailContainer>
    <div class="email-viewer"
        [ngClass]="sidePanelOpen ? 'side-panel-open-email': 'side-panel-closed-email'"
      *ngIf="selectedId">
    <div class="email-field-start email-subject" style="padding-top: 0px; font-size: 14px">
      {{formatField('subject')}}
    </div>
    <div class="flex flex-row">
      <div class="email-field-start email-subject" style="font-weight: bold; padding-right: 3px">
        {{ formatEmail(true) }}
      </div>
      <div class="email-subject" style="font-size: 10px; bottom: -2px">
        {{ formatEmail(false) }}
      </div>
    </div>
    <div class="flex flex-row">
      <div class="email-field-start email-subject" style="color: #666; font-size: 10px">
        to
      </div>
      <div class="email-subject" style="padding-left: 3px; font-size: 10px">
        {{formatField('to')}}
      </div>
    </div>
    <div class="flex flex-row" *ngIf="formatField('cc')">
      <div class="email-field-start email-subject" style="color: #666; font-size: 10px">
        cc
      </div>
      <div class="email-subject" style="padding-left: 3px; font-size: 10px">
        {{formatField('cc')}}
      </div>
    </div>
    <div class="attachments-container email-field-start email-subject" *ngIf="itemAttsCount">
      <div class="single-att"
            (click)="selectedAttachment = att.link; documentModal = true;"
            *ngFor="let att of itemAtts">
        <i class="bi bi-paperclip" style="padding-right: 1px"></i>
        {{att.name}}
      </div>
    </div>
    <div class="email-display">
      <div *ngIf="fetchingBody">
        <div *ngFor="let item of skeletonItems">
          <p-skeleton [width]="item.width" styleClass="mb-2" borderRadius="16px"></p-skeleton>
        </div>
      </div>
      <div *ngIf="!fetchingBody"
          class="mail-body custom-scrollbar-close" style="font-size: 10px"
          (click)="mailClicked($event)"
          [innerHtml]="itemInnerHtml">
      </div>
    </div>
    </div>
  </div>

  <div class="tracking-view" *ngIf="selectedId && trackingData">
    <tracking-pane
      [selectedView]="selectedView"
      [dropdownOptions]="dropdownOptions"
      [trackingData]="trackingData"
      [isLoading]="true"
      [confirmPanel]="true"
      [makeVisible]="true"
      (confirmMail)="onMailConfirm($event)"
      [allMailData]="allMailData"
      (trackFieldChanged)="onTrackFieldChange($event)">
    </tracking-pane>
  </div>
</div>

<p-dialog
  [(visible)]="documentModal"
  [style]="{ marginTop: '50px', width: '90vw', height: '90vh' }"
  [closeOnEscape]="true"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span></span>
    </div>
    <button class="dialog-close" type="button" (click)="documentModal = false"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <ng-template pTemplate="content">
    <iframe [src]="selectedAttachment | sanitize" allow="fullscreen" width="100%" height="98%"></iframe>
  </ng-template>
</p-dialog>
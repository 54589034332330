<div class="filters">
  <span
    id="show-fields-modal-button"
    (click)="showFieldsModal($event)"
    [ngClass]="!isLoading && numberOfHiddenFields > 0 ? 'filters-background' : 'filters-default'"
  >
    <fa-icon [icon]="faHidden"></fa-icon>
    {{
      numberOfHiddenFields > 0 && !isLoading
        ? numberOfHiddenFields + (numberOfHiddenFields === 1 ? ' hidden field' : ' hidden fields')
        : 'Hide fields'
    }}
  </span>

  <div
    class="filters__container-fields"
    *ngIf="toggleFieldsModal"
    (outsideClick)="clickOutsideModal($event)"

  >
    <div class="filters__container-fields-header">Hide fields</div>
    <p-divider></p-divider>
    <div >
      <input
        class="px-2 py-1 rounded-md text-xs border border-neutral-300 w-5/6"
        type="text"
        id="filter-hide-field"
        placeholder="Search..."
        (input)="onFilterHideFieldsChanged()"
      />

    </div>
    <p-divider></p-divider>
    <div class="filters__container-fields-footer">
      <button class="filters__container-fields-footer-button" type="button" (click)="hideAll()">Hide all</button>
      <button class="filters__container-fields-footer-button" type="button" (click)="showAll()">Show all</button>
      <!--<button class="filters__container-fields-footer-button" type="button" (click)="reset()">Reset</button>-->
    </div>
    <div class="filters__container-fields-container">
      <div class="filters__container-fields-content" *ngFor="let field of fields | orderBy: 'order'; trackBy: trackByFn">
        <div class="filters__container-fields-content-item">
          <ng-container *ngIf="field.field !== 'index' && inSearchTerm(field.header)">
            <p-inputSwitch
              styleClass="filters__container-fields-content-item-switch"
              [(ngModel)]="field.visible"
              (onChange)="triggerFieldsChange(field, field.visible)"
            ></p-inputSwitch>
            <p class="filters__container-fields-content-item-text">{{ field.header }}</p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { OnDestroyMixin } from '../../mixins/destroy-mixin';

@Component({
  selector: 'app-tawk-chat',
  templateUrl: './tawk-chat.component.html',
  styleUrls: ['./tawk-chat.component.sass'],
})
export class TawkChatComponent extends OnDestroyMixin() implements OnInit {
  @Input() id: string = '';

  script = this._renderer.createElement('script');

  constructor(private _renderer: Renderer2, @Inject(DOCUMENT) private _document: any,) {
    super();
  }

  ngOnInit() {
    setTimeout(() => {
      this.script.text = `
      var Tawk_API=Tawk_API||{}; 
      var Tawk_LoadStart=new Date();
      Tawk_API.visitor = JSON.parse(localStorage.getItem('profile'));

      (function(){ 
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/${this.id}/1g3km7efg';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();   

        Tawk_API.onLoad = function() {
          setTimeout(function() {
            Tawk_API.minimize();
          }, 1);
        };
     `;
      this._renderer.appendChild(this._document.body, this.script);
    }, 1000);
  }
}

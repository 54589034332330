import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OnDestroyMixin } from '../../mixins/destroy-mixin';

import { MessageService } from 'primeng/api';

@Component({
  selector: 'materlog-add-documents',
  templateUrl: './add-documents.component.html',
  styleUrls: ['./add-documents.component.sass'],
})
export class AddDocumentsComponent extends OnDestroyMixin() implements OnInit {
  @Input() supplier: boolean = false;
  @Input() document_types: any;
  @Input() document_id: any;
  @Input() isPO: boolean = false;

  showImage: boolean = false;

  @Input() docForms = [{
    number: null,
    document_type: 0,
    order_document_date: null,
    attachments: [{
      filename: '',
      attachment: '',
      id: null
    }],
    id: null,
  }];
  @Output() docFormsChange = new EventEmitter<any>();

  constructor(private messageService: MessageService) {
    super();
  }

  ngOnInit(): void {
    this.emitDocForms();
  }

  addForm() {
    this.docForms.push({
      number: null,
      document_type: 0,
      order_document_date: null,
      attachments: [{
        filename: '',
        attachment: '',
        id: null,
      }],
      id: null,
    });
  }

  deleteDocument(documentIndex: number) {
    if (this.docForms?.length > 1) {
      this.docForms.splice(documentIndex, 1);
    } else {
      this.docForms = [{
        number: null,
        document_type: 0,
        order_document_date: null,
        attachments: [{
          filename: '',
          attachment: '',
          id: null
        }],
        id: null,
      }]
    }
  }

  //Drag and drop functionality
  onFileDropped($event: any, index: number) {
    for (const item of $event) {
      this.convertBase64(item, index);
    }

    this.showImage = true;
  }

  get fileCount() {
    return this.docForms.filter((d: any) => {
      return d.attachments.filter((a: any) => a.attachment).length;
    }).length;
  }

  //Handles files browse
  fileBrowseHandler(files: File[], index: number) {
    this.showImage = true;

    for (const item of files) {
      this.convertBase64(item, index);
    }
  }

  deleteFile(formIndex: number, fileIndex: number) {
    if (this.docForms[formIndex]?.attachments?.length >= 1) {
      this.docForms[formIndex]?.attachments?.splice(fileIndex, 1);
    } else {
      this.docForms[formIndex].attachments = [{
        filename: '',
        attachment: '',
        id: null
      }];
    }
    this.emitDocForms();

  }

  checkFileType(file: any): boolean {
    const prepareFile: any = file.name.split('.');
    const fileType = prepareFile?.findLast((el: any) => el.length > 1).toLowerCase();
    const types = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'bmp', 'eml'];

    if (!types.includes(fileType)) { return false; }

    return true;
  }

  convertBase64(file: any, index: number) {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const data = String(reader.result).split('base64,');

      if (data[1] && this.checkFileType(file)) {
        if (this.docForms[index]?.attachments[0]?.attachment.length < 2) {
          this.docForms[index].attachments[0].attachment = data[1];
          this.docForms[index].attachments[0].filename = file.name;
          this.docForms[index].attachments[0].id = null;
        } else {
          this.docForms[index]?.attachments.push({
            filename: file.name,
            attachment: data[1],
            id: null
          });
        }
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please provide a valid file.',
          key: 'errorAddDocument',
        });
      }
    };

    this.emitDocForms();
  }

  emitDocForms() {
    if (!this.supplier) {
      this.docForms?.forEach(element => {
        element.document_type = 1;
      })
    }
    else {
      this.docForms?.forEach(element => {
        if(element){
          element.document_type = element.document_type === null ? 0 : element.document_type;
        }
      });
    }

    this.docFormsChange.emit(this.docForms);
  }

  verifyAttachment(file: any) {
    if (file.filename)
      return file.filename.length > 1;
    else return false
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ReceiversService } from '../../services/receivers.service';
import { ErrorReceiveReceiverOptions, ErrorReceiveReceiversPage, ReceiveReceiverOptions, ReceiveReceiversPage } from '../actions/receivers-action-type-creators/receivers-page-action-types.creators';
import { ReceiversActionTypes } from '../actions/receivers-action-types';


@Injectable()
export class ReceiversEffects {
  constructor(private actions$: Actions,
    private receiversService: ReceiversService
  ) { }

  requestReceiversPage$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReceiversActionTypes.REQUEST_RECEIVERS_PAGE),
      switchMap((params: any) =>
        this.receiversService.getReceiversPage(params).pipe(
          map(
            (receivers: any) => new ReceiveReceiversPage(receivers)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveReceiversPage(error)))
        )
      )
    );
  });

  requestReceiversOptions$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReceiversActionTypes.REQUEST_RECEIVERS_OPTIONS),
      switchMap(() =>
        this.receiversService.getOptions().pipe(
          map((user: any) => new ReceiveReceiverOptions(user)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveReceiverOptions(error)))
        )
      )
    );
  });
}

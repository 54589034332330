import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorPostPutItemData, receivePostPutItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IUserPassword } from '../../models/user.model';
import { UserActionTypes } from '../actions/user-action-types';
import { IUserPasswordState, UserPasswordState } from '../models/password.model';

export function userPasswordReducer(
  lastState: IUserPasswordState = new UserPasswordState(),
  action: GenericAction<UserActionTypes, any>
): IUserPasswordState {
  switch (action.type) {

    case UserActionTypes.REQUEST_UPDATE_USER_PASSWORD:
      return requestPostPutItemData<IUserPasswordState, IUserPassword>(lastState, ['userPassword'], action.payload);
    case UserActionTypes.RECEIVE_UPDATE_USER_PASSWORD:
      return receivePostPutItemData<IUserPasswordState, IUserPassword>(lastState, ['userPassword'], action.payload);
    case UserActionTypes.ERROR_RECEIVE_UPDATE_USER_PASSWORD:
      return errorPostPutItemData<IUserPasswordState, IUserPassword>(lastState, ['userPassword'], action.payload);

    default:
      return lastState;
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { TemplateService } from '../../services/template.service';
import { Action } from '@ngrx/store';
import { TemplateActionTypes } from '../actions/template-action-types';
import { ErrorReceiveDeleteTemplate, ErrorReceiveTemplates, ReceiveDeleteTemplate, ReceiveTemplates, ReceiveUpdateTemplate, ErrorReceiveUpdateTemplate } from '../actions/template-action-type-creators/template-action-types.creators';
import { HttpErrorResponse } from '@angular/common/http';
import { ITemplate } from '../../models/template.model';

@Injectable()
export class TemplateEffects {
  constructor(
    private actions$: Actions,
    private templateService: TemplateService,
  ) { }

  requestTemplates$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplateActionTypes.REQUEST_TEMPLATES),
      switchMap(() =>
        this.templateService.getTemplates().pipe(
          map(
            (templates: any) => new ReceiveTemplates(templates)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveTemplates(error)))
        )
      )
    );
  });

  requestDeleteTemplate$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplateActionTypes.REQUEST_DELETE_TEMPLATE),
      switchMap((templateId: any) =>
        this.templateService.deleteTemplate(templateId).pipe(
          map(
            (templates: any) => new ReceiveDeleteTemplate(templates)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteTemplate(error)))
        )
      )
    );
  });

  requestUpdateTemplate$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(TemplateActionTypes.REQUEST_UPDATE_TEMPLATE),
      switchMap((templates: ITemplate) =>
        this.templateService.updateTemplate(templates).pipe(
          map((template: any) => new ReceiveUpdateTemplate(template)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateTemplate(error)))
        )
      )
    );
  });

}

<ng-container *ngFor="let form of docForms; let i = index; let isFirst = first; let isLast = last">
  <div
    class="document"
    dropFile
    (fileDropped)="onFileDropped($event, i)"
    [id]="supplier ? 'fileSups' + i : 'fileDropRefs'"
  >
    <div class="document__data">
      <div class="document__data-delete-po" (click)="deleteDocument(i)"><i class="bi bi-x-lg" style="font-size: 10px;"></i></div>
      <div class="document__data-number">
        <input
          type="text"
          [id]="supplier ? i : i + 'po'"
          [name]="supplier ? 'number' : 'number' + i"
          [placeholder]="!supplier ? 'PO #' : 'Order #'"
          [(ngModel)]="form.number"
          (ngModelChange)="emitDocForms()"
        />
      </div>
      <div class="document__data-type">
        <!-- option changes based on what document you send. If it's po it's set by default and disabled, If it's supplier show just options without PO option -->
        <p-dropdown
          appendTo="body"
          [options]="supplier ? document_types?.slice(2) : document_types?.slice(1, 2)"
          styleClass="dropdown"
          [(ngModel)]="form.document_type"
          (ngModelChange)="emitDocForms()"
          name="document_type"
          optionValue="id"
          optionLabel="value"
          placeholder="Doc type"
          [disabled]="!supplier"
        ></p-dropdown>
      </div>
      <div class="document__data-date">
        <p-calendar
          appendTo="body"
          styleClass="datepicker"
          [(ngModel)]="form.order_document_date"
          (ngModelChange)="emitDocForms()"
          placeholder="MM/DD/YYYY"
          dateFormat="mm/dd/yy"
          dataType="date"
        ></p-calendar>
      </div>
      <div class="document__data-attach">
        <div class="document__data-attach-files" *ngFor="let file of form.attachments; let j = index">
          <span>{{ file?.filename }}</span>
          <i *ngIf="verifyAttachment(file)" class="bi bi-x-lg" (click)="deleteFile(i, j)"></i>
        </div>
        <label *ngIf="!isPO || (isPO && fileCount < 1)" [for]="supplier ? 'fileSup' + i : 'fileDropRef'">
          <i class="bi bi-paperclip attach-doc"></i> Attach doc
          <input
            type="file"
            hidden
            [id]="supplier ? 'fileSup' + i : 'fileDropRef'"
            [name]="supplier ? 'document_attachments' + i : 'document_attachments'"
            accept=".pdf,.PDF,.doc,.DOC,.docx,.DOCX,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.bmp,.BMP,.eml,.EML,image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
            (change)="fileBrowseHandler($any($event.target).files, i)"
            multiple
          />
        </label>
      </div>
    </div>
  </div>
</ng-container>
<button class="button" *ngIf="supplier" type="button" (click)="addForm()"><i class="bi bi-plus"></i>Add file</button>

<p-toast position="top-center" key="errorAddDocument"></p-toast>

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {decodeMsgPackSimple} from 'src/app/dashboard/services/do_msgpack'


@Injectable({
  providedIn: 'root'
})
export class ReceiversService {
  private baseUrl: string = environment.ENV.BASE_URL;
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  private httpOptionsMsgPack = {
    headers: new HttpHeaders({
      'Accept': 'application/x-msgpack',
    }),
    responseType: 'arraybuffer' as any
  };

  public getReceiversPage(params?: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.get<any>(`${this.baseUrl}receivers/`, {
      ...this.httpOptionsMsgPack,
      params: { ...params.payload },
    })
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }

  public getOptions(): Observable<IOptions[] | HttpErrorResponse> {
    return this.httpClient.get<Array<IOptions>>(`${this.baseUrl}options/?options=states&options=countries`, this.httpOptionsMsgPack)
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    ) as any;
  }
}

import { IViews } from '../../../models/dashboard.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { DashboardActionTypes } from '../dashboard-action-types';


export class RequestViews implements Action {
  readonly type: DashboardActionTypes.REQUEST_VIEWS = DashboardActionTypes.REQUEST_VIEWS;

  constructor(
    public payload?: IViews) { }
}
export class ReceiveViews implements Action {
  readonly type: DashboardActionTypes.RECEIVE_VIEWS = DashboardActionTypes.RECEIVE_VIEWS;

  constructor(
    public payload?: IViews) { }
}
export class ErrorReceiveViews implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_VIEWS = DashboardActionTypes.ERROR_RECEIVE_VIEWS;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class ReceiveAddView implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ADD_VIEW = DashboardActionTypes.RECEIVE_ADD_VIEW;

  constructor(
    public payload: IViews) { }
}

export class RequestAddView implements Action {
  readonly type: DashboardActionTypes.REQUEST_ADD_VIEW = DashboardActionTypes.REQUEST_ADD_VIEW;

  constructor(
    public payload?: string) { }
}

export class ErrorReceiveAddView implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ADD_VIEW = DashboardActionTypes.ERROR_RECEIVE_ADD_VIEW;
  constructor(
    public payload: HttpErrorResponse) { }
}

export class ReceiveUpdateView implements Action {
  readonly type: DashboardActionTypes.RECEIVE_UPDATE_VIEW = DashboardActionTypes.RECEIVE_UPDATE_VIEW;

  constructor(
    public payload: IViews) { }
}

export class RequestUpdateView implements Action {
  readonly type: DashboardActionTypes.REQUEST_UPDATE_VIEW = DashboardActionTypes.REQUEST_UPDATE_VIEW;

  constructor(
    public payload?: string) {

  }
}

export class RequestSaveView implements Action {
  readonly type: DashboardActionTypes.REQUEST_SAVE_VIEW = DashboardActionTypes.REQUEST_SAVE_VIEW;

  constructor(
    public payload?: string) {

  }
}


export class ErrorReceiveUpdateView implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_UPDATE_VIEW = DashboardActionTypes.ERROR_RECEIVE_UPDATE_VIEW;
  constructor(
    public payload: HttpErrorResponse) { }
}
export class ReceiveDeleteView implements Action {
  readonly type: DashboardActionTypes.RECEIVE_DELETE_VIEW = DashboardActionTypes.RECEIVE_DELETE_VIEW;

  constructor(
    public payload: IViews) { }
}

export class RequestDeleteView implements Action {
  readonly type: DashboardActionTypes.REQUEST_DELETE_VIEW = DashboardActionTypes.REQUEST_DELETE_VIEW;

  constructor(
    public payload?: string) { }
}

export class ErrorReceiveDeleteView implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_DELETE_VIEW = DashboardActionTypes.ERROR_RECEIVE_DELETE_VIEW;
  constructor(
    public payload: HttpErrorResponse) { }
}

export class ReceiveUpdateViewCell implements Action {
  readonly type: DashboardActionTypes.RECEIVE_UPDATE_VIEW_CELL = DashboardActionTypes.RECEIVE_UPDATE_VIEW_CELL;

  constructor(
    public payload: IViews) { }
}

export class RequestUpdateViewCell implements Action {
  readonly type: DashboardActionTypes.REQUEST_UPDATE_VIEW_CELL = DashboardActionTypes.REQUEST_UPDATE_VIEW_CELL;

  constructor(
    public payload?: string) { }
}

export class ErrorReceiveUpdateViewCell implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_UPDATE_VIEW_CELL = DashboardActionTypes.ERROR_RECEIVE_UPDATE_VIEW_CELL;
  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestUncategorizedView implements Action {
  readonly type: DashboardActionTypes.REQUEST_UNCATEGORIZED_VIEW = DashboardActionTypes.REQUEST_UNCATEGORIZED_VIEW;

  constructor(
    public payload?: IViews) { }
}
export class ReceiveUncategorizedView implements Action {
  readonly type: DashboardActionTypes.RECEIVE_UNCATEGORIZED_VIEW = DashboardActionTypes.RECEIVE_UNCATEGORIZED_VIEW;

  constructor(
    public payload?: IViews) { }
}
export class ErrorReceiveUncategorizedView implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_UNCATEGORIZED_VIEW = DashboardActionTypes.ERROR_RECEIVE_UNCATEGORIZED_VIEW;

  constructor(
    public payload?: HttpErrorResponse) { }
}
export class RequestAttachmentDelete implements Action {
  readonly type: DashboardActionTypes.REQUEST_ATTACHMENT_DELETE = DashboardActionTypes.REQUEST_ATTACHMENT_DELETE;

  constructor(
    public payload: IViews) { }
}
export class ReceiveAttachmentDelete implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ATTACHMENT_DELETE = DashboardActionTypes.RECEIVE_ATTACHMENT_DELETE;

  constructor(
    public payload: IViews) { }
}
export class ErrorReceiveAttachmentDelete implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ATTACHMENT_DELETE = DashboardActionTypes.ERROR_RECEIVE_ATTACHMENT_DELETE;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestUncategorizedViewBool implements Action {
  readonly type: DashboardActionTypes.REQUEST_UNCATEGORIZED_VIEW_BOOL = DashboardActionTypes.REQUEST_UNCATEGORIZED_VIEW_BOOL;

  constructor(
    public payload?: IViews) { }
}
export class ReceiveUncategorizedViewBool implements Action {
  readonly type: DashboardActionTypes.RECEIVE_UNCATEGORIZED_VIEW_BOOL = DashboardActionTypes.RECEIVE_UNCATEGORIZED_VIEW_BOOL;

  constructor(
    public payload?: IViews) { }
}
export class ErrorReceiveUncategorizedViewBool implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_UNCATEGORIZED_VIEW_BOOL = DashboardActionTypes.ERROR_RECEIVE_UNCATEGORIZED_VIEW_BOOL;

  constructor(
    public payload?: HttpErrorResponse) { }
}

import { Component } from '@angular/core';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CustomErrorService } from 'src/app/dashboard/services/custom-error.service';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';

@Component({
  selector: 'orders-doc-readonly-cell-renderer',
  templateUrl: './orders-doc-readonly-cell-renderer.component.html',
  styleUrls: ['orders-doc-readonly-cell-renderer.component.sass'],
})

export class OrdersDocReadonlyCellRenderer implements ICellRendererAngularComp {
  public params!: any;
  readonly faEye = faEye;

  documentModal: boolean = false;
  selectedAttachment: any;

  constructor(
    private dashboardActions: DashboardActions,
    private customErrorService: CustomErrorService,
  ) { }

  agInit(params: ICellRendererParams<any, number>): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams) {
    return false;
  }

  //checks if the file is an image or not
  checkFileType(filename: any): boolean {
    const prepareFile: any = filename.split('.');
    const fileType = prepareFile?.findLast((el: any) => el.length > 1);
    const types = ['png', 'jpeg', 'jpg', 'jfif', 'pjpeg', 'pjp', 'svg', 'webp', 'bmp'];

    return types.includes(fileType);
  }

  openDocumentModal(document: any, filename: string) {
    //the filetype check is needed for doc/docx types as they are not rendered as HTML pages by the AWS to be shown in the modal.
    const prepareFile: any = filename.split('.');
    const fileType = prepareFile?.findLast((el: any) => el.length > 1).toLowerCase();
    const docTypes = ['pdf'];
    if (document) {
      if (docTypes.includes(fileType)) {
        this.documentModal = true;
        this.selectedAttachment = document;
      } else {
        window.open(document);
      }
    } else {
      this.customErrorService.setCustomErrorMessage('documentNotFound');
    }
  }

  deleteFile(key: string, id: string, attachment_id: string) {
    this.dashboardActions.requestAttachmentDelete({ [`${key}`]: attachment_id, id });
  }

  closeDocumentModal() {
    this.documentModal = false;
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { IForgotPassword } from '../../../models/authentication.model';
import { AuthenticationActionTypes } from '../authentication-action-types';

export class RequestForgotPassword implements Action {
  readonly type: AuthenticationActionTypes.REQUEST_FORGOT_PASSWORD = AuthenticationActionTypes.REQUEST_FORGOT_PASSWORD;

  constructor(
    public payload: IForgotPassword) { }
}

export class ReceiveForgotPassword implements Action {
  readonly type: AuthenticationActionTypes.RECEIVE_FORGOT_PASSWORD = AuthenticationActionTypes.RECEIVE_FORGOT_PASSWORD;

  constructor(
    public payload: IForgotPassword) { }
}

export class ErrorReceiveForgotPassword implements Action {
  readonly type: AuthenticationActionTypes.ERROR_FORGOT_PASSWORD = AuthenticationActionTypes.ERROR_FORGOT_PASSWORD;

  constructor(
    public payload: HttpErrorResponse) { }
}

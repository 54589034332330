import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { INewItem } from './../../../models/dashboard.model';
import { DashboardActionTypes } from './../dashboard-action-types';

export class RequestAddItem implements Action {
  readonly type: DashboardActionTypes.REQUEST_ADD_ITEM = DashboardActionTypes.REQUEST_ADD_ITEM;

  constructor(
    public payload: INewItem) { }
}

export class ReceiveAddItem implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ADD_ITEM = DashboardActionTypes.RECEIVE_ADD_ITEM;

  constructor(
    public payload: INewItem) { }
}
export class ErrorReceiveAddItem implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ADD_ITEM = DashboardActionTypes.ERROR_RECEIVE_ADD_ITEM;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestDeleteItem implements Action {
  readonly type: DashboardActionTypes.REQUEST_DELETE_ITEM = DashboardActionTypes.REQUEST_DELETE_ITEM;

  constructor(
    public payload: INewItem) { }
}

export class ReceiveDeleteItem implements Action {
  readonly type: DashboardActionTypes.RECEIVE_DELETE_ITEM = DashboardActionTypes.RECEIVE_DELETE_ITEM;

  constructor(
    public payload: INewItem) { }
}
export class ErrorReceiveDeleteItem implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_DELETE_ITEM = DashboardActionTypes.ERROR_RECEIVE_DELETE_ITEM;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestUpdateItem implements Action {
  readonly type: DashboardActionTypes.REQUEST_UPDATE_ITEM = DashboardActionTypes.REQUEST_UPDATE_ITEM;

  constructor(
    public payload: INewItem) { }
}

export class ReceiveUpdateItem implements Action {
  readonly type: DashboardActionTypes.RECEIVE_UPDATE_ITEM = DashboardActionTypes.RECEIVE_UPDATE_ITEM;

  constructor(
    public payload: INewItem) { }
}
export class ErrorReceiveUpdateItem implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_UPDATE_ITEM = DashboardActionTypes.ERROR_RECEIVE_UPDATE_ITEM;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestDeleteItemReason implements Action {
  readonly type: DashboardActionTypes.REQUEST_DELETE_ITEM_REASON = DashboardActionTypes.REQUEST_DELETE_ITEM_REASON;

  constructor(
    public payload: any) { }
}

export class ReceiveDeleteItemReason implements Action {
  readonly type: DashboardActionTypes.RECEIVE_DELETE_ITEM_REASON = DashboardActionTypes.RECEIVE_DELETE_ITEM_REASON;

  constructor(
    public payload: any) { }
}
export class ErrorReceiveDeleteItemReason implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_DELETE_ITEM_REASON = DashboardActionTypes.ERROR_RECEIVE_DELETE_ITEM_REASON;

  constructor(
    public payload: HttpErrorResponse) { }
}

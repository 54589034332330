<div class="side-panel-container">
  <div class="logo-container">
    <img class="materlog-logo" src="/assets/img/materlog_logo.svg"
          alt="logo"  (click)="setItemPageActive()"/>
    <!--
    <div class="user-initials" (click)="userSettingsClicked()"> {{ userInitials }}</div>
    -->
  </div>
  <div class="new-options-container">
    <button class="new-options-button" #newOptionsButton
            (click)="setNewOptionsVisible()">
      <div class="button-content">
        <i class="bi bi-plus-lg"></i>
        <div class="text">New</div>
      </div>
    </button>
    <ng-container *ngIf="newOptionsVisible">
      <div class="new-option-dropdown">
        <div class="new-option"
        (click)="createNewThing(which)"
        *ngFor="let which of newOptionTypes">
          <span> {{ which }} </span>
        </div>
      </div>
    </ng-container>
  </div>
  <div style="margin-bottom: 8px"></div>
  <div class="notifications-container">
    <div class="notifications-text">Notifications</div>
    <div class="notification-box" *ngFor="let which of notificationTypes">
      <div class="notification-badge"
           [ngStyle]="{
            'background-color': notifCountForType(which) === 0 ? '#dcdcdc' : '#151316',
          }
           ">
           {{ notifCountForType(which) }} </div>
      <div class="notification-value"
            [ngClass]="{
              'notification-value-active': highlightSide(which),
              'inactive-container': zeroCount(which) || (isButtonInactive() && !alwaysClickable(which)),
              'active-container': !zeroCount(which) && (!isButtonInactive() || alwaysClickable(which))
            }"
            (click)="onNotificationClick(which)"
      > {{ which }} </div>
    </div>
  </div>
  <div class="notifications-container">
    <div class="notifications-text">Jobs</div>
    <active-job-list
      [activeJobs]="activeJobs"
      [isLoading]="isLoading"
      [isLoadingViews]="isLoadingViews"
      [activePage]="activePageType"
      (selectedJob)="selectJob($event)"
      [selectedView]="selectedView"
      [panelHeight]="panelHeight"
    ></active-job-list>
  </div>
  <div style="margin-bottom: 4px"></div>
  <div class="notifications-container">
    <div class="notifications-text">Emails</div>
    <div class="email-container email-container-margin"
        [ngClass]="isButtonInactive() ? 'inactive-container': 'active-container'"
        (click)="emailsClicked()">
      <i class="bi bi-envelope"
          style="color: #333; font-weight: bold; margin-right: 6px; margin-left: 2px;"></i>
      <div class="page-name"
        [ngClass]="activenotificationType == 'Emails' &&
                    !(activePageType == 'Items' && 
                    selectedViewStyle == 1) ? 'notification-value-active' : null">
        Emails
      </div>
    </div>
  </div>
  <div class="notifications-container">
    <div class="notifications-text">Pages</div>
    <div class="email-container workspace-margin active-container" (click)="suppliersClicked()">
      <i class="bi bi-people-fill"
          style="color: #333; font-weight: bold; margin-right: 6px; margin-left: 2px;"></i>
      <div class="page-name"
          [ngClass]="activePageType == 'Suppliers' ? 'notification-value-active' : null">
          Suppliers
      </div>
    </div>
    <div class="email-container active-container" (click)="jobsClicked()">
      <i class="bi bi-stack"
          style="color: #333; font-weight: bold; margin-right: 6px; margin-left: 2px;"></i>
      <div class="page-name"
          [ngClass]="activePageType == 'Jobs' ? 'notification-value-active' : null">
          Jobs
      </div>
    </div>
  </div>
  <div class="notifications-container">
    <div class="notifications-text">Workspace</div>
    <div class="email-container workspace-margin active-container" (click)="userSettingsClicked()">
      <i class="bi bi-person-fill"
          style="color: #333; font-weight: bold; margin-right: 6px; margin-left: 2px;"></i>
      <div class="page-name"
          [ngClass]="activePageType == 'User' ? 'notification-value-active' : null">
          User settings
      </div>
    </div>
    <div class="email-container workspace-margin active-container" (click)="companySettingsClicked()">
      <i class="bi bi-gear-fill"
          style="color: #333; font-weight: bold; margin-right: 6px; margin-left: 2px;"></i>
      <div class="page-name"
          [ngClass]="activePageType == 'Company' ? 'notification-value-active' : null">
          Company settings
      </div>
    </div>
    <div class="email-container workspace-margin active-container">
      <a href="mailto:support@materlog.com">
        <i class="bi bi-question-circle-fill text-azure"
           style="color: #333; font-weight: bold; margin-right: 6px; margin-left: 2px;"
        ></i>
      </a>
      <div class="page-name">
        Help
      </div>
    </div>
    <div class="email-container workspace-margin active-container" (click)="logoutClicked()">
      <i class="bi bi-box-arrow-right"
          style="color: #333; font-weight: bold; margin-right: 6px; margin-left: 2px;"></i>
      <div class="page-name">
        Logout
      </div>
    </div>
    <app-tawk-chat [id]="tawk_id"></app-tawk-chat>
  </div>
</div>


<materlog-templates *ngIf="showTemplates"
  [selectedView]="selectedView"
  [show]="showTemplates"
  (close)="showTemplates = false">
</materlog-templates>

<materlog-create-update-job *ngIf="showNewJob"
  [fromGetTracking]="false"
  (close)="showNewJob = false">
</materlog-create-update-job>

<materlog-create-update-job *ngIf="showNewReceiver"
  [onlyReceiver]="true"
  [sidePanelAdd]="true"
  [fromGetTracking]="false"
  (close)="showNewReceiver = false">
</materlog-create-update-job>

<materlog-add-edit-po *ngIf="showNewOrder"
  (close)="showNewOrder = false"
  (gotoNotif)="gotoNotifications()">
</materlog-add-edit-po>

<materlog-add-item *ngIf="showNewItem"
  [allMailData]="allMailData"
  (close)="showNewItem = false">
</materlog-add-item>

<p-dialog *ngIf="addSupplierModal"
  [(visible)]="addSupplierModal"
  [style]="{ marginTop: '250px', width: '500px' }"
  [closeOnEscape]="false"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>Add supplier</span>
    </div>
    <button class="dialog-close" type="button" (click)="addSupplierModal = false;"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <materlog-add-supplier-modal
    [resetSupplier]="addSupplierModal"
    [sidePanelAdd]="true"
    (supplierFormChange)="supplierFormChangeEvent($event)"
  ></materlog-add-supplier-modal>
</p-dialog>
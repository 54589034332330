import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[blockLateralArrowNavigation]'
})
export class BlockLateralArrowNavigationDirective {
  // This directive is used in order to block the default arrow navigation options of the
  // p-table component. By using this directive the users can navigate inside the input with the arrows
  // instead of changing cells with the arrows.
  @HostListener('keydown.arrowleft', ['$event']) arrowLeftPressed(
    event: KeyboardEvent
  ) {
    event.stopPropagation();
  }

  @HostListener('keydown.arrowright', ['$event']) arrowRightPressed(
    event: KeyboardEvent
  ) {
    event.stopPropagation();
  }

  @HostListener('keydown.arrowup', ['$event']) arrowUpPressed(
    event: KeyboardEvent
  ) {
    event.stopPropagation();
  }

  @HostListener('keydown.arrowdown', ['$event']) arrowDownPressed(
    event: KeyboardEvent
  ) {
    event.stopPropagation();
  }

  constructor() { }

}

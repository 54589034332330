import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';

import { ConfirmationService, MessageService } from 'primeng/api';
import { faBuilding, faExclamationTriangle, faPencil, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { selectOptions } from 'src/app/dashboard/state/selectors/dashboard.selector';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { CompanyActions } from '../../state/actions/company.action';
import { selectOrganization, selectOrganizationAddresses } from '../../state/selectors/company.select';
import { IOrganization, IOrganizationAddresses } from '../../models/company.model';

@Component({
  selector: 'materlog-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.sass']
})
export class CompanyManagementComponent extends OnDestroyMixin() implements OnInit {
  showDialog: boolean = false;
  updateCompanyName: boolean = false;
  isLoading: boolean = false;
  isSubmitted: boolean = false;

  faEdit = faPencil;
  faBuilding = faBuilding;
  faTriangle = faExclamationTriangle;
  faXMark = faXmark;
  faTrash = faTrashCan;

  editList: any = [true];

  logo: any;
  statesList: any;
  countriesList: any;

  company: IOrganization = {
    logo: null,
    slug: null,
    name: '',
  };

  addressList: any = [{
    address: null,
    name: '',
    address_id: null,
    state: null,
    country: null,
    zip: null,
    phone: null,
    city: null,
    is_default: false,
  }];

  constructor(
    private store: Store<IApplicationState>,
    private companyActions: CompanyActions,
    private dashboardActions: DashboardActions,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.onRequestOrganization();
    this.onRequestOrganizationAddresses();
    this.onRequestOptions();
  }

  onRequestOptions() {
    this.dashboardActions.requestOptions();

    this.store
      .pipe(
        select(selectOptions),
        filter((options: IStoreApiItem<IOptions>) => !options.isLoading),
        take(1)
      )
      .subscribe((options: IStoreApiItem<IOptions>) => {
        this.countriesList = options?.data?.countries;
        this.statesList = options?.data?.states;
      });
  }

  onRequestOrganization() {
    this.companyActions.requestOrganization();

    this.store
      .pipe(
        select(selectOrganization),
        filter((organization: IStoreApiItem<IOrganization>) => !organization.isLoading),
        take(1)
      )
      .subscribe((organization: IStoreApiItem<IOrganization>) => {
        if (organization.data != null) {
          this.company = JSON.parse(JSON.stringify(organization?.data));
          this.logo = organization?.data?.logo;
        }

        if (organization?.errors) {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong. Please try again later. If this issue persists, contact support@materlog.com.',
            key: 'error',
          });
        }
      });
  }

  onRequestOrganizationAddresses() {
    this.companyActions.requestOrganizationAddresses();

    this.store
      .pipe(
        select(selectOrganizationAddresses),
        filter((organizationAddresses: IStoreApiItem<IOrganizationAddresses>) => !organizationAddresses.isLoading),
        take(1)
      )
      .subscribe((organizationAddresses: IStoreApiItem<IOrganizationAddresses>) => {
        if (organizationAddresses.data != null) {
          this.addressList = JSON.parse(JSON.stringify(organizationAddresses?.data));
          this.addressList = this.addressList?.map((item: any) => {
            return {
              ...item,
              editable: false
            };
          });
        }
      });
  }

  //Handles files browse
  fileBrowseHandler(files: File[]) {
    for (const item of files) {
      this.convertBase64(item);
    }
  }

  checkFileType(file: any): boolean {
    const prepareFile: any = file.name.split('.');
    const fileType = prepareFile?.findLast((el: any) => el.length > 1);
    const types = ['png', 'jpeg', 'jpg', 'jfif', 'pjpeg', 'pjp', 'svg', 'webp', 'bmp'];

    return types.includes(fileType);
  }

  convertBase64(file: any) {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const data = String(reader.result).split('base64,');

      if (data[1] && this.checkFileType(file)) {
        this.company.logo = data[1];
        this.logo = reader.result;
        this.updateOrganizationCall();
      } else {
        this.messageService.clear();
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please provide a valid file.',
          key: 'error',
        });
      }
    };
  }

  closeDialog(item: any) {
    this.onRequestOrganizationAddresses();
    item.editable = !item.editable;
  }

  editName() {
    this.updateCompanyName = false;
    this.updateOrganizationCall();
  }

  closeNameDialog() {
    this.updateCompanyName = false;
    this.onRequestOrganization();
  }

  openEditNameDialog() {
    this.updateCompanyName = true;
  }

  complexAddress(address: any) {
    let tempState;
    let tempCountry;

    if (address.state !== null) {
      tempState = this.statesList?.filter((state: any) => state.id === address.state);
      tempState = tempState ? tempState[0] : null;
    }

    if (address.country !== null) {
      tempCountry = this.countriesList?.filter((country: any) => country.id === address.country);
      tempCountry = tempCountry ? tempCountry[0] : null;
    }

    return ((address.city || '') + ' ' + (tempState?.value || '') + ' ' + (address.zip || '') + ' ' + (tempCountry?.value || ''));
  }

  openDialog(item: any) {
    item.editable = !item.editable;
    this.isSubmitted = false;
  }

  addAddress() {
    this.isSubmitted = false;
    this.addressList.push({
      name: '',
      address_id: null,
      state: null,
      country: 1,
      zip: null,
      phone: null,
      city: null,
      is_default: false,
      editable: false,
    });
    this.openDialog(this.addressList.findLast((item: any) => item.address_id === null));
  }

  deleteAddress(id: string) {
    if (id !== null) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete this address?',
        accept: () => {
          this.deleteAddressCall(id);
        },
      });
    }
  }

  deleteAddressCall(id: string) {
    this.companyActions.requestDeleteOrganizationAddress(id);
    setTimeout(() => this.addressStoreCall(), 1000);
  }

  updateAddressCall(address: any) {
    const objToUpdate: IOrganizationAddresses = {
      name: address.name,
      address_id: address.address_id,
      address: address.address,
      state: address.state,
      country: address.country,
      zip: address.zip,
      phone: address.phone,
      city: address.city,
      is_default: address.is_default,
    };

    this.companyActions.requestUpdateOrganizationAddress(objToUpdate);
    this.addressStoreCall();
  }

  addAddressCall(address: any) {
    const objToAdd: any = {
      name: address.name,
      address: address.address,
      state: address.state,
      country: address.country,
      zip: address.zip,
      phone: address.phone,
      city: address.city,
      is_default: address.is_default,
    };

    this.companyActions.requestAddOrganizationAddress(objToAdd);
    this.addressStoreCall();
  }

  addressStoreCall() {
    this.store
      .pipe(
        select(selectOrganizationAddresses),
        filter((organizationAddress: IStoreApiItem<IOrganizationAddresses>) => !organizationAddress.isLoading),
        takeUntil(this.destroy)
      )
      .subscribe((organizationAddress: any) => {
        if (organizationAddress?.isSuccess) {
          this.isSubmitted = false;
        }
        if (organizationAddress?.errors) {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong. Please try again later. If this issue persists, contact support@materlog.com.',
            key: 'error',
          });

        }
      });
    setTimeout(() => {
      this.onRequestOrganizationAddresses();
    }, 500);
  }

  editAddress(item: any) {
    this.isSubmitted = true;

    if (item.name && item.address) {
      if (item.address_id !== null && item.editable) {
        this.updateAddressCall(item);
        item.editable = !item.editable;
      } else if (item.address_id === null && item.editable) {
        this.addAddressCall(item);
        item.editable = !item.editable;
      }
    }
  }

  updateOrganizationCall() {
    let objToUpdate;

    if (this.company.logo?.substring(0, 4) == 'http') {
      objToUpdate = {
        name: this.company.name,
      };
    } else {
      objToUpdate = {
        logo: this.company.logo,
        name: this.company.name,
      };
    }

    if (this.company.name?.length > 1) {
      this.companyActions.requestUpdateOrganization(objToUpdate);
      this.onRequestOrganization();
    }
  }

  placeSelected(event: any, index: any) {
    if (event) {
      this.addressList[index].zip = event.properties.postcode ?? null;
      this.addressList[index].address = event.properties.address_line1;
      this.statesList.forEach((state: any) => {
        if (state.value?.toLowerCase() == event.properties.state_code?.toLowerCase()) { this.addressList[index].state = state.id; return; }
      });
      if (event.properties.country_code === 'us') {
        this.addressList[index].country = 1;
      } else if (event.properties.country_code === 'ca') {
        this.addressList[index].country = 2;
      }
      this.addressList[index].city = event.properties.city ?? null;
    } else {
      this.addressList[index].state = null;
      this.addressList[index].city = null;
      this.addressList[index].zip = null;
      this.addressList[index].country = null;
      this.addressList[index].address = null;
    }
  }

}

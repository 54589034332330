import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorItemData, receiveItemData, requestItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IActiveOrderList } from '../../models/dashboard.model';
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { ActiveOrdersState, IActiveOrdersState } from '../models/activeOrders.model';


export function activeOrdersReducer(
  lastState: IActiveOrdersState = new ActiveOrdersState(),
  action: GenericAction<DashboardActionTypes, any>
): IActiveOrdersState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_ACTIVE_ORDERS:
      return requestItemData<IActiveOrdersState, IActiveOrderList>(lastState, ['activeOrders']);
    case DashboardActionTypes.RECEIVE_ACTIVE_ORDERS:
      return receiveItemData<IActiveOrdersState, IActiveOrderList>(lastState, ['activeOrders'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ACTIVE_ORDERS:
      return errorItemData<IActiveOrdersState, IActiveOrderList>(lastState, ['activeOrders'], action.payload);

    default:
      return lastState;
  }
}

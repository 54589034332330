import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { IRecoverPassword } from '../../../models/authentication.model';
import { AuthenticationActionTypes } from '../authentication-action-types';

export class RequestRecoverPassword implements Action {
  readonly type: AuthenticationActionTypes.REQUEST_RECOVER_PASSWORD = AuthenticationActionTypes.REQUEST_RECOVER_PASSWORD;

  constructor(
    public payload: IRecoverPassword) { }
}

export class ReceiveRecoverPassword implements Action {
  readonly type: AuthenticationActionTypes.RECEIVE_RECOVER_PASSWORD = AuthenticationActionTypes.RECEIVE_RECOVER_PASSWORD;

  constructor(
    public payload: IRecoverPassword) { }
}

export class ErrorReceiveRecoverPassword implements Action {
  readonly type: AuthenticationActionTypes.ERROR_RECOVER_PASSWORD = AuthenticationActionTypes.ERROR_RECOVER_PASSWORD;

  constructor(
    public payload: HttpErrorResponse) { }
}

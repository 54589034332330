<input
  pInputText
  nullValue
  type="text"
  name="addRoomName"
  class="form-controls dialog__content-input-text"
  placeholder="Name"
  [(ngModel)]="roomName"
  (keyup.enter)="onSaveRoomName(params)"
/>
<div class="textfield__error-message" *ngIf="isAddRoomNameSubmitted && !roomName?.trim()">Add a room name</div>
<div class="flex justify-end">
  <button
    pButton
    type="submit"
    label="Save"
    class="button-primary p-button-raised mt-4 mb-4"
    (click)="onSaveRoomName(params)"
  ></button>
</div>

import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorDeleteItem, errorPostPutItemData, receivePostPutItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { INewItem } from '../../models/dashboard.model';
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { IItemsState, ItemsState } from '../models/items.model';


export function itemsReducer(
  lastState: IItemsState = new ItemsState(),
  action: GenericAction<DashboardActionTypes, any>
): IItemsState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_ADD_ITEM:
      return requestPostPutItemData<IItemsState, INewItem>(lastState, ['items'], action.payload);
    case DashboardActionTypes.RECEIVE_ADD_ITEM:
      return receivePostPutItemData<IItemsState, INewItem>(lastState, ['items'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ADD_ITEM:
      return errorPostPutItemData<IItemsState, INewItem>(lastState, ['items'], action.payload);

    case DashboardActionTypes.REQUEST_DELETE_ITEM:
      return requestPostPutItemData<IItemsState, INewItem>(lastState, ['items'], action.payload);
    case DashboardActionTypes.RECEIVE_DELETE_ITEM:
      return receivePostPutItemData<IItemsState, INewItem>(lastState, ['items'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_DELETE_ITEM:
      return errorDeleteItem<IItemsState, INewItem>(lastState, ['items'], action.payload);

    case DashboardActionTypes.REQUEST_UPDATE_ITEM:
      return requestPostPutItemData<IItemsState, INewItem>(lastState, ['items'], action.payload);
    case DashboardActionTypes.RECEIVE_UPDATE_ITEM:
      return receivePostPutItemData<IItemsState, INewItem>(lastState, ['items'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_UPDATE_ITEM:
      return errorPostPutItemData<IItemsState, INewItem>(lastState, ['items'], action.payload);

    case DashboardActionTypes.REQUEST_DELETE_ITEM_REASON:
      return requestPostPutItemData<IItemsState, INewItem>(lastState, ['items'], action.payload);
    case DashboardActionTypes.RECEIVE_DELETE_ITEM_REASON:
      return receivePostPutItemData<IItemsState, INewItem>(lastState, ['items'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_DELETE_ITEM_REASON:
      return errorPostPutItemData<IItemsState, INewItem>(lastState, ['items'], action.payload);

    default:
      return lastState;
  }
}

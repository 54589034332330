import {
  ImagesReadonlyCellRenderer
} from "../../../dashboard/components/job-table-v2/images-read-only/images-readonly-cell-renderer.component";
import {
  OrdersDocReadonlyCellRenderer
} from "../../../dashboard/components/job-table-v2/orders-doc-readonly/orders-doc-readonly-cell-renderer.component";
import {
  OrdersReadonlyCellRenderer
} from "../../../dashboard/components/job-table-v2/orders/orders-readonly-cell-renderer.component";
import { ValueFormatterParams } from "ag-grid-community";

import { ImagesCellRenderer } from "src/app/dashboard/components/job-table-v2/images/images-cell-renderer.component";
import { OrdersDocCellRenderer } from "src/app/dashboard/components/job-table-v2/orders-doc/orders-doc-cell-renderer.component";
import { OrdersCellRenderer } from "src/app/dashboard/components/job-table-v2/orders/orders-cell-renderer.component";

export function getSharedColumns(this: any) {
  let tempcolumns =
  [
    {
      field: "images",
      cellRenderer: ImagesReadonlyCellRenderer,
    },
    {
      field: 'status',
      type: 'text',
      headerComponentParams: { template: createCustomHeaderTemplate()},
      comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
        let a = this.dropdownOptions.status.find((s: any) => s.value == valueA);
        valueA = a.id;
        let b = this.dropdownOptions.status.find((s: any) => s.value == valueB);
        valueB = b.id;
        return this.doColumnCompareNullLast(valueA,valueB,isDescending,'status');
      },
      cellRenderer: (params: any) => {
        if (params?.context?.status_colors) {
          let edit = params.context.editable;
          let statuses = params.context.status;
          if (!statuses) return params.value;
          let found = statuses.find((s: any) => (edit ? s.id : s.value) == params.value);
          if (!found) return params.value;
          let colors = params.context.status_colors;
          let color = colors.find((c: any) => c.id == found.id);
          if (!color) return params.value;
          let maxWidth = calculateMaxWidth(found.value);
          let element = `
            <div class="shared-status-cell-renderer"
                 style="max-width: ${maxWidth}">
              <div class="shared-status-color-cell"
                   style="background-color: ${color.value}">
                <span class="shared-button-text">${found.value}</span>
              </div>
            </div>
          `;
          return element;
        } else {
          return params.value;
        }
      }
    },
    {
      field: "po_document_number",
      cellRenderer: OrdersReadonlyCellRenderer,
      keyCreator: (params: any) => {
        if(params.data.po_document_number && params.data.po_document_number.number){
          return params.data.po_document_number.number.toString().toLowerCase();
        }
      },
      comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
        if(valueA == undefined && valueB== undefined) {
          return this.doColumnCompareNullLastNonZero(undefined, undefined, isDescending, 'category');
        }else if(valueA == undefined) {
          return this.doColumnCompareNullLastNonZero(undefined, valueB.number, isDescending, 'category');
        }else if(valueA == undefined) {
          return this.doColumnCompareNullLastNonZero(valueA.number, undefined, isDescending, 'category');
        }else{
          return this.doColumnCompareNullLastNonZero(valueA.number, valueB.number, isDescending, 'category');
        }
      },
      type: 'object',
    },
    {
      field: "po_document_attachments",
      cellRenderer: OrdersDocReadonlyCellRenderer,
      type: 'object',
    },
    {
      field: "supplier_documents_numbers",
      cellRenderer: OrdersReadonlyCellRenderer,
      keyCreator: (params: any) => {
        if(params.data.supplier_documents_numbers ){
          if(params.data.supplier_documents_numbers.length > 0){
            return params.data.supplier_documents_numbers[0].number;
          }
          return params.data.supplier_documents_numbers.number;
        }
      },
      type: 'object',
    },
    {
      field: "supplier_documents_attachments",
      cellRenderer: OrdersDocReadonlyCellRenderer,
      type: 'object',
    },
  ];
  return tempcolumns;
}

export function editableView(this: any) {
  let tempcolumns =
  [
    {
      field: "images",
      cellRenderer: ImagesCellRenderer,
      cellRendererParams: {
        imagesUpdated: this.onImagesUpdated.bind(this),
      },
      editable: true,
    },
    {
      field: "warehouse_rcpt_date",
      type: 'stringORdateColumn',
      editable: true,
    },
    {
      field: "pallet_number",
      type: 'text',
      editable: true,
    },
    {
      field: "warehouse_id",
      type: 'text',
      editable: true,
    },
    {
      field: 'status',
      editable: false,
      type: 'dropdown',
    },
    {
      field: "supplier_documents_numbers",
      editable: true,
      cellRenderer: OrdersCellRenderer,
      cellRendererParams: {
        orderUpdated: this.onAttachmentChange.bind(this),
      },
    }
  ];
  return tempcolumns;
}

function createCustomHeaderTemplate() {
  return `
    <div class="ag-cell-label-container" role="presentation">
      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
        <div class="column-header-content">
          <div class="icon-container">
            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
            <a href="http://www.materlog.com/statuschart" target="_blank" class="status-chart-link">
              <i class="bi-question-circle"></i>
            </a>
          </div>
        </div>
        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
      </div>
    </div>`;
}

function calculateMaxWidth(text: any) {
  let base = 90;
  let strlen = text?.length;
  if (strlen > 9) {
    base += 6 * (strlen - 9);
  }
  return `${base}px`;
}
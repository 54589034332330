import { IOrders } from './../../../models/dashboard.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { INewOrder } from 'src/app/dashboard/models/dashboard.model';
import { DashboardActionTypes } from '../dashboard-action-types';


export class RequestAddOrder implements Action {
  readonly type: DashboardActionTypes.REQUEST_ADD_ORDER = DashboardActionTypes.REQUEST_ADD_ORDER;

  constructor(
    public payload: INewOrder) { }
}
export class ReceiveAddOrder implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ADD_ORDER = DashboardActionTypes.RECEIVE_ADD_ORDER;

  constructor(
    public payload: INewOrder) { }
}
export class ErrorReceiveAddOrder implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ADD_ORDER = DashboardActionTypes.ERROR_RECEIVE_ADD_ORDER;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestOrders implements Action {
  readonly type: DashboardActionTypes.REQUEST_ORDERS = DashboardActionTypes.REQUEST_ORDERS;

  constructor(
    public payload?: IOrders) { }
}
export class ReceiveOrders implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ORDERS = DashboardActionTypes.RECEIVE_ORDERS;

  constructor(
    public payload?: IOrders) { }
}
export class ErrorReceiveOrders implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ORDERS = DashboardActionTypes.ERROR_RECEIVE_ORDERS;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class RequestAddRoom implements Action {
  readonly type: DashboardActionTypes.REQUEST_ADD_ROOM = DashboardActionTypes.REQUEST_ADD_ROOM;

  constructor(
    public payload: any) { }
}

export class ReceiveAddRoom implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ADD_ROOM = DashboardActionTypes.RECEIVE_ADD_ROOM;

  constructor(
    public payload: any) { }
}

export class ErrorReceiveAddRoom implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ADD_ROOM = DashboardActionTypes.ERROR_RECEIVE_ADD_ROOM;

  constructor(
    public payload: HttpErrorResponse) { }
}


export class RequestUpdateOrder implements Action {
  readonly type: DashboardActionTypes.REQUEST_UPDATE_ORDER = DashboardActionTypes.REQUEST_UPDATE_ORDER;

  constructor(
    public payload: any) { }
}

export class ReceiveUpdateOrder implements Action {
  readonly type: DashboardActionTypes.RECEIVE_UPDATE_ORDER = DashboardActionTypes.RECEIVE_UPDATE_ORDER;

  constructor(
    public payload: any) { }
}
export class ErrorReceiveUpdateOrder implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_UPDATE_ORDER = DashboardActionTypes.ERROR_RECEIVE_UPDATE_ORDER;

  constructor(
    public payload: HttpErrorResponse) { }
}

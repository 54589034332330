<p-calendar
  appendTo="body"
  placeholder="MM-DD-YYYY"
  dateFormat="mm/dd/yy"
  dataType="date"
  panelStyleClass="ag-custom-component-popup"
  [showClear]="true"

  [(ngModel)]="params.value"
  (onSelect)="onChangeDate()"
  (onClear)="onChangeDate()"
>
</p-calendar>

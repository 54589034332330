import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, isDevMode,
  OnChanges,
  Output,
  SimpleChanges
} from "@angular/core";

import { DatePipe } from "@angular/common";
import { select, Store } from "@ngrx/store";
import { filter, take } from "rxjs";

import {  faEye } from "@fortawesome/free-solid-svg-icons";
import {
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  ColumnApi, ColumnState, DateStringAdvancedFilterModel, ExportParams,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridReadyEvent, JoinAdvancedFilterModel,
  MenuItemDef, NumberAdvancedFilterModel,
  ProcessHeaderForExportParams,
  ProcessRowGroupForExportParams,
  StatusPanelDef, TextAdvancedFilterModel,
  ValueFormatterParams, ValueGetterParams
} from "ag-grid-community";


import { IApplicationState } from "src/app/common/state/models/app.state.model";
import { DashboardActions } from "src/app/dashboard/state/actions/dashboard.actions";
import { ActiveJobModel } from "../../models/active-job.model";
import { SelectedViewModel } from "../../models/selected-view.model";
import { JobTableService } from "../../services/job-table.service";
import {
  selectItems
} from "src/app/dashboard/state/selectors/dashboard.selector";
import { CustomErrorService } from "../../services/custom-error.service";
import { OnDestroyMixin } from "src/app/common/mixins/destroy-mixin";
import { CustomNoRowsOverlay } from "./custom-no-rows-overlay.component";
import { CalendarEditor } from "./calendar/calendar.component";
import { AutoCompleteEditor } from "./autocomplete/autocomplete.component";
import { DropDownEditor } from "./dropdown/dropdown.component";
import { DropDownCellRenderer } from "./dropdownv2/dropdownv2.component";
import { CountStatusBarComponent } from "./count-status-bar.component";
import { PayloadService } from "../../services/payload.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import {
  ManualCommunicationComponent
} from "src/app/dashboard/components/job-table-v2/manual-communication/manual-communication.component";
import { OrderService } from "../../services/order.service";
import { TableConstants } from "../../../common/constants/table.constants";
import { DashboardService } from "../../services/dashboard.service";
import {deepCopy, deepArrayClone, isEmptySort, emptySort} from "../../../common/utils/general"
import { CalendarDateEditor } from "./calendar-date/calendar-date.component";
import { getTempColumns, doTextColumnCompareNullLast } from "./job-table-v2.utils";
import { Table } from "primeng/table";
import { PopupComment } from "./popup-comment/popup-comment.component";
import { OptionsService } from "../../services/options.service";
import { ShipLinkCellRenderer } from "./ShipLinkCellRenderer/shiplink.component";
import { DashboardJobView } from "../../services/dashboard_job_view.service";
import { DependencyRenderer } from "./dependency-renderer/dependency-renderer.component";
import { CommonConstants } from "src/app/common/constants/common.constants";


function onCopyRow(gridApi: GridApi, includeHeaders: boolean = false): void {
  gridApi.copySelectedRowsToClipboard({ includeHeaders });
}


function headerCallback(params: ProcessHeaderForExportParams): string {
  return params.column.getColDef().headerName?.toUpperCase() || "";
}

function rowGroupCallback(params: ProcessRowGroupForExportParams): string {
  const node = params.node;
  return node.key?.toUpperCase() || "";
}

function getDropDownOptions(key: string, value: string) {

  return JobTableV2Component.sharedDropDownOptions[key]?.find(
    (dropdownItem: any) => dropdownItem?.id === value
  )?.value;


}


function isRowSelected(gridApi: GridApi): boolean {
  return gridApi.getSelectedRows().length !== 0;
}

@Component({
  selector: "job-table-v2",
  templateUrl: "./job-table-v2.component.html",
  styleUrls: ['../../../../../node_modules/ag-grid-enterprise/styles/ag-grid.min.css',
              '../../../../../node_modules/ag-grid-enterprise/styles/ag-theme-balham.min.css',
              "job-table-v2.component.sass"],
  providers: [DatePipe, DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobTableV2Component extends OnDestroyMixin() implements OnChanges {
  @Input() selectedView?: SelectedViewModel;
  @Input() activeJobsList!: ActiveJobModel[];
  @Input() isLoading!: boolean;
  @Input() isLoadingOverlay!: boolean;
  @Input() dropdownOptions: any;
  @Input() orgUserEmails!: any;
  static sharedDropDownOptions: any;
  @Input() activeJob: any;
  @Input() responseItems: any;
  @Input() orgSlug: any;
  @Input() isPasting: boolean = false;
  @Input() allMailData: any;

  duringLoading: boolean = false;
  @Output() selectJob: EventEmitter<any> = new EventEmitter();
  gridVisible: boolean = true;
  ref: DynamicDialogRef | undefined;
  fastOptionMap:Map<string,any>  = new Map();
  queue_subitems: any = undefined;
  showItemJob: boolean = false;
  isGridReady: boolean = false;

  currentRow!: any;
  formattedCell!: any;
  mousePosition = { x: 0, y: 0 };

  readonly faEye = faEye;
  //readonly faTriangle = faExclamationTriangle;
  //readonly faXMark = faXmark;
  //groupDefaultExpanded = 1;
  showPopupComment: boolean = false;
  showSplitItem: boolean = false;
  currentJobViewName = undefined;
  searchTerm = '';
  jobs: any;
  history: any;
  columns!: ColDef[];
  deleteRowId: any;
  showDialog: boolean = false;
  isSubmitted: boolean = false;
  reasonToDelete: any;
  reasonToDeleteText: any;
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  columnTypes: any;
  noRowsOverlayComponent: any = CustomNoRowsOverlay;
  noRowsOverlayComponentParams: any = {
    appliedFilters: () => this.appliedFilters,
    getOrgSlug: () => this.orgSlug
  };
  initedOnce: boolean = false;

  get doneLoad() {
    return this.initedOnce || (!this.isLoadingOverlay && !this.isLoading && this.selectedView && this.activeJobsList && this.hasDropDownOptions);
  }

  public gridOptions: any = {
    // other grid options...
    // enableCellTextSelection: true,  // when not set, calls colType valueFormatter
    enableRangeSelection: true,
    ensureDomOrder: true,
    alwaysMultiSort: true,
    suppressClipboardApi: true,
    suppressLoadingOverlay: true,
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 20,

    // copyHeadersToClipboard: true,
    // suppressCopyRowsToClipboard: true,
    sendToClipboard: this.sendToClipboard.bind(this),
    processCellForClipboard: this.processCellForClipboard.bind(this),
    processCellFromClipboard: this.processCellFromClipboard.bind(this),
    onCellKeyDown: this.deleteCellContents.bind(this),

    // suppressColumnVirtualisation: true,

    isExternalFilterPresent: () => {
      // Now 'this' refers to the JobTableV2Component instance
      return !!this.searchTerm;
    },
    
    doesExternalFilterPass: (node: any) => {
      const item = node.data;
      for (const key in item) {
        let value = item[key];
        if (this.applySearchTerm2(key, value, this.searchTerm)) {
          return true;
        }
      }
      return false;
    },
    getRowStyle: (params: any) => {
      if (params && params.data && params.data.has_subitems && params.data.has_subitems.item_id) {
        // return { backgroundColor: '#eeeeee', color: '#bbbbbb'};
        return { color: '#888888'};
      } else {
        return null;
      }
    },
  };


  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => `${params.data.id}`;
  public getRowNodeId: any = (params: any) => `${params.data.id}`;
  private search: any = {};
  private rowHeight = TableConstants.row_small;

  constructor(
    private jobTableService: JobTableService,
    private dashboardActions: DashboardActions,
    private customErrorService: CustomErrorService,
    private store: Store<IApplicationState>,
    private datePipe: DatePipe,
    private payloadService: PayloadService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private orderService: OrderService,
    private dashboardService: DashboardService,
    private optionsService: OptionsService,
    private djv: DashboardJobView,
  ) {
    super();
  }

  isGridDestroyed() {
    return !this.gridApi || (this.gridApi as any)?.destroyCalled || !this.isGridReady;
  }

  onGridReady(params: GridReadyEvent) {
    (window as any)['jobs'] = this;
    if ((params?.api as any)?.destroyCalled) {
      this.isGridReady = false;
      return;
    }
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.jobTableService.setGridApi(this.gridApi);
    this.jobTableService.setColumnApi(this.columnApi);

    this.isGridReady = true;
    this.initedOnce = true;

    this.gridApi.hideOverlay();
    this.initGridFunctions();
    this.resetGridState();
    let element = document.querySelector('.content-container') as HTMLElement;
    element.style.minHeight = `${element.offsetHeight}px`;
    this.gridApi.addEventListener('bodyScrollEnd', this.onBodyScrollEnd.bind(this));
    setTimeout(() => {
      this.addTrackingIconListener();
    }, 500);
  }

  ngAfterViewInit() {
  }

  override ngOnDestroy(): void {
    this.isGridReady = false;
    this.gridVisible = false;
    this.initedOnce = false;
  }


  initGridFunctions() {
    // needed for copy/paste for dropdown coltypes
    document.addEventListener('copy', (e) => {
      if (this.columnApi) {
        let cols = this.columnApi.getAllGridColumns();
        if (cols) {
          cols.forEach((c: any) => {
            if (c?.colDef?.type == 'dropdown') {
              c.colDef.editable = this.dropdownEditFunction.bind(this);
            }
          });
        }
      }
    });

    document.addEventListener('paste', (e) => {
      this.isPasting = true;
      setTimeout(() => {
          this.isPasting = false;
          // need to reset dropdown callback for some reason
          if (this.columnApi) {
            let cols = this.columnApi.getAllGridColumns();
            if (cols) {
              cols.forEach((c: any) => {
                if (c?.colDef?.type == 'dropdown') {
                  c.colDef.editable = this.dropdownEditFunction.bind(this);
                }
              })
            }
          }
        }, 1000);
    }, true);

    this.jobTableService.imageSizeObs
      .subscribe(result => {
      if(result == null){
        return;
      }
      switch (result) {
        case 'large':
          this.rowHeight = TableConstants.row_large;
          break;
        case 'medium':
          this.rowHeight = TableConstants.row_medium;
          break;
        case 'small':
          this.rowHeight = TableConstants.row_small;
          break;
        default:
          this.rowHeight = TableConstants.row_small;
          break;
      }
      if (this.rowHeight && this.gridApi) {
        this.gridApi?.setGetRowHeight(params => {
          return this.rowHeight;
        });
        this.gridApi?.resetRowHeights();
        this.gridApi.refreshCells({ columns: ['images'], force: true });
        setTimeout(() => {
          this.cdr.detectChanges()
        }, 100);
      }
    });

    this.jobTableService.showFilterObs
      .subscribe((result: any) => {
      if (this.selectedView && this.gridApi && this.columnApi && result) {
          // this.gridApi.setRowData(results.results);
          if (result.display) {
            let field = result.field;
            if (field == 'all') {
              this.columnApi.setColumnsVisible(this.columnApi.getAllGridColumns().map(
                col => col.getColId()), true);
                this.gridApi.refreshCells({ force: true });
            } else if (field == 'none') {
              let columnsToHide: any = this.columnApi.getAllGridColumns();
              if (columnsToHide) {
                columnsToHide = columnsToHide
                  .filter((col: any) => col.getColId() !== 'index')
                  .map((col: any) => col.getColId());
              this.columnApi.setColumnsVisible(columnsToHide, false);
              this.gridApi.refreshCells({ force: true });                
              }

  
            } else {
              const curColumn = this.columnApi.getColumn(field);
              if (curColumn) {
                this.columnApi.setColumnVisible(field, !curColumn.isVisible());
                this.gridApi.refreshCells({ columns: [field], force: true });
              }
            }
          } else {
            let field = result.field;
            if (field == 'all') {
              let cur_results = result.results;
              if (this.selectedView?.activeView?.show_subitems) {
                let with_subs: any = [];
                this.jobs.forEach((j: any) => {
                  if (j.has_subitems?.item_id) with_subs.push(j);
                  else {
                    let found = cur_results.find((r: any) => r.id == j.id);
                    if (found) with_subs.push(found);
                    else with_subs.push(j);
                  }
                });
                this.selectedView.results = with_subs;
                this.jobs = with_subs;
              } else {
                this.selectedView.results = result.results;
                this.jobs = result.results;
              }
              this.gridApi.applyTransaction({ update: this.jobs });
            } else if (field == 'none') {
              // pass
            } else {
              if (this.selectedView && this.selectedView.results) {
                result.results.forEach((r: any) => {
                  let found = this.selectedView?.results.find((item: any) => r.id == item.id);
                  if (found) found[field] = r[field];
                })
              }
              this.gridApi.refreshCells({ columns: [field], force: true });
            }
          }

      }
    });

    this.jobTableService.hideFilterObs
      .subscribe(result => {
      if(result){
        let visibleMap: Map<string,boolean> = new Map();
        result.forEach( (item:any) =>{
            if(item.visible){
              visibleMap.set(item.field,true);
            }else{
              visibleMap.set(item.field,false);
            }
        });
        visibleMap.set('index',true);//index always shows...

        let showcolumns: any[] = [];
        let hidecolumns: any[] = [];
        if (this.columnApi && this.columnApi.getAllGridColumns()) {
          this.columnApi.getAllGridColumns().forEach((col:any)=>{
            //check if current item is same as visible map ie no change
            if (visibleMap.has(col.colId)) {
              if(visibleMap.get(col.colId) != col.visible){
                col.visible = visibleMap.get(col.colId);
                if(visibleMap.get(col.colId)){
                  showcolumns.push(col.colId);
                }
                else {
                  hidecolumns.push(col.colId);
                }
              }
            }
          });
        }

        if(showcolumns.length > 0) {
          this.columnApi.setColumnsVisible(showcolumns, true);
        }
        if(hidecolumns.length > 0) {
          this.columnApi.setColumnsVisible(hidecolumns, false);
        }
      }

    });

    this.jobTableService.matchFilterObs
    .subscribe(result => {
      if (result == null) return;
      this.selectedView?.activeView.set_filters(result);
      this.doMatchingFilter();

    });

    this.jobTableService.matchFilterOperatorsObs
    .subscribe(result => {
      if (result == null) return;
      this.selectedView?.activeView.set_filters(result);
      this.doMatchingFilter();
    });

    this.jobTableService.sortingFilterObs
    .subscribe(result => {
      if (this.columnApi == undefined || !this.selectedView?.activeView || !result) {
        return;
      }
      this.selectedView.activeView.set_fields_selected_to_sort_json(deepCopy(result));
      this.doSortGroupWork();
      setTimeout(() => {
        this.djv.row_data_updated(true); // for card view
      }, 0);
    });

    this.jobTableService.groupOrderingObs
    .subscribe(result => {
      if (this.columnApi == undefined || !this.selectedView?.activeView || !result) {
        return;
      }
      this.doSortGroupWork();
    });

    this.jobTableService.searchFilterObs
    .subscribe(result => {
      this.search = result;
      this.applySearchTerm(0);
    });

    this.jobTableService.subItemsObs
    .subscribe(result => {
      if (result && !result.on) {
        let new_jobs = this.jobs.filter((job: any) => !job.has_subitems.item_id);
        if (new_jobs.length != this.jobs.length) {
          this.jobs = new_jobs;
          this.gridApi.setRowData(this.jobs);
        }
      } else if (result && result.on) {
        this.handleNewSubItemsToggle(result);
      }
    });
  }

  public defaultColDef: ColDef = {
    suppressFiltersToolPanel: true,
    resizable: true,
    filterParams: {
      caseSensitive: false,
      readOnly: false,
      suppressSelectAll : false,
      suppressMiniFilter: false,
    },
    sortable: true,
    floatingFilter: false,
    editable: true,   //by default,
    cellClass: (params: CellClassParams) => {
      if (params.data?.fields_for_history_visible?.includes(params.colDef.field)) {
        return "historyBackgroundColor";
      }
      return;
    },
   menuTabs: ['generalMenuTab'],
  };

  initDropdownOptions(options:any){
    this.fastOptionMap.clear();
    // see options.service.ts for why we ignore
    let ignore = ['faster', 'status_ordering'];
    for (let key of Object.keys(options)) {
      if(options[key]) {
        if (ignore.includes(key)) continue;
        options[key].forEach((keyElement: any) => {
          this.fastOptionMap.set(key + "_" + keyElement['id'], keyElement['value']);
        });
      }
    }
  }

  public fastLookupOptions(key:string, id:any){

    const lookup = key+"_"+id;
    if(this.fastOptionMap.has(lookup)){
      return this.fastOptionMap.get(lookup);
    }
    //else lets put into the fast lookup
    const found = this.dropdownOptions[key]?.find(
      (dropdownItem: any) => dropdownItem?.id === id
    )?.value;

    this.fastOptionMap.set(lookup,found);
    return found;


  }

  public statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      {
        statusPanel: CountStatusBarComponent,
        align: "left"
      }
    ]
  };

  maybeFilterOnChange(col_field: string) {
    let active_view = this.selectedView?.activeView;
    if (!active_view) return;
    this.doMatchingFilter();
    let group_fields = active_view.get_fields_selected_to_group();
    if (group_fields.some((item: any) => item.key == col_field)) {
      this.doSortGroupWork();
      return;
    }
    let sort_fields = active_view.get_fields_selected_to_sort_json();
    if (sort_fields.some((item: any) => item.key == col_field)) {
      this.doSortGroupWork();
      return;
    }
    return false;
  }

  isSortGroupFilterApplied() {
    if (this.isGridDestroyed()) return false;
    let active_view = this.selectedView?.activeView;
    if (!active_view || !this.gridApi) return false;
    let group_fields = active_view.get_fields_selected_to_group();
    if (group_fields.length) return true;
    let sort_fields = active_view.get_fields_selected_to_sort_json();
    if (sort_fields.length) return true;
    let filtered = this.gridApi.getAdvancedFilterModel();
    if (filtered) return true;
    return false;
  }

  doSortGroupWork() {
    if (this.isGridDestroyed()) return;
    let active_view = this.selectedView?.activeView;
    if (!active_view || !this.gridApi) return;

    let group_fields = active_view.get_fields_selected_to_group();
    let gfields_array = group_fields.map((x: any) => x.key);

    let sort_fields = active_view.get_fields_selected_to_sort_json();
    let sfields_array = sort_fields.map((x: any) => x.key);
    let columndef = this.gridApi.getColumnDefs();
    columndef?.forEach((item: any) => {
      if (!sfields_array.includes(item.field)) {
        item.sort = null;
      }
    });
    let newColState: any = [];
    let index = 0;
    sort_fields.forEach((item: any) => {
      let dir = item.direction ? 'asc': 'desc';
      newColState.push({ colId: item.key, sort: dir, sortIndex: index++ });
    });
    group_fields.forEach((groupby: any, _: number) => {
      let gfield = groupby.field;
      if (sfields_array.includes(gfield)) {
        newColState.forEach((x: any) => {
          if (x.colId === gfield) {
            x.rowGroup = true;
          }
        });
      } else {
        newColState.push({colId: gfield, rowGroup: true, sortIndex: newColState.length});
      }
    });

    this.columnApi.setRowGroupColumns(gfields_array);
    this.columnApi.applyColumnState(
      {
        state: newColState,
        defaultState: { sort: null },
    });

    this.jobTableService.sort_deleted = false; // reset
    if (this.jobTableService.getSortsClear()) {
      this.jobTableService.setUnclearAllSorts();
    }
  }

  sortChanged(event:any){
    // event where a user clicked a column
    if (event.source !== 'uiColumnSorted') return;
    let active_view = this.selectedView?.activeView;
    // if (!active_view || this.isColumnGroupingSorting) return;
    if (!active_view) {
      this.resetIndex();
      return;
    }
    let coldefs = this.columnApi.getColumnState();
    if (!coldefs) return;
    let sort_fields = active_view.get_fields_selected_to_sort_json();
    let active_sort_cols = coldefs.filter((item) => item.sort != null);
    let active_sort_cols_array = active_sort_cols.map((item) => item.colId);
    let cur_sort_fields: any = [];
    let index = 0;
    if (sort_fields.length == active_sort_cols.length) {
      sort_fields.forEach((item: any) => {
        if (active_sort_cols_array.includes(item.key)) {
          let item_copy = deepCopy(item);
          let cur = active_sort_cols.find((col) => col.colId == item.key) as any;
          item_copy.direction = cur.sort === 'asc' ? true : false;
          item_copy.position = index++;
          cur_sort_fields.push(item_copy);
        }
      });
    } else if (sort_fields.length < active_sort_cols.length) {
      // get the active sort col not in sort fields and add it
      sort_fields.forEach((item: any) => cur_sort_fields.push(item));
      active_sort_cols.forEach((col) => {
        if (!sort_fields.some((sf: any) => sf.key === col.colId)) {
          let new_sort_field = {
            key: col.colId,
            direction: col.sort === 'asc' ? true : false,
            position: cur_sort_fields.length
          };
          cur_sort_fields.push(new_sort_field);
        }
      });
    } else {
      // get the one in sort fields not in active sort cols and remove it
      sort_fields.forEach((item: any) => {
        if (active_sort_cols_array.includes(item.key)) {
          let item_copy = deepCopy(item);
          let cur = active_sort_cols.find((col) => col.colId == item.key) as any;
          item_copy.direction = cur.sort === 'asc' ? true : false;
          item_copy.position = index++;
          cur_sort_fields.push(item_copy);
        }
      });
    }

    active_view.set_fields_selected_to_sort_json(cur_sort_fields);
    this.jobTableService.setSortingArray(cur_sort_fields);
    this.doSortGroupWork();
  }

  columnGrouped(event: any){
  }


  deleteCellContents(event: any) {
    if (event.event.keyCode === 46 || event.event.keyCode === 8) {
      event.event.preventDefault();
      let col_field = event.colDef.field as string;
      if (TableConstants.dropDownEditable.includes(col_field)) {
        event.value = null;
        let item = this.jobs.find((j: any) => j.id == event.data.id);
        if (item) {
          item[col_field] = null;
          this.gridApi.applyTransaction({ update: [item] });
        }
        this.onCellValueChanged(event);
      }
    }
  }

  sendToClipboard(params: any) {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(params.data).then(() => {});
    }
  }

  processCellForClipboard(params: any) {
    return this.processCellCallback(params);
  }

  processCellFromClipboard(cell: any) {
    let coldef = cell.column.getColDef();
    let og = cell.node.data[coldef.field];
    if (coldef.type == 'dropdown') {
      let drops = this.dropdownOptions[coldef.field];
      let found = drops.find((val: any) => val.value == cell.value);
      return found ? found.id: og;
    } else if (coldef.type == 'number' || coldef.type == 'money') {
      let numberValue = coldef.type == 'number' ? Number(cell.value):
        parseFloat(cell.value.replace(/[^0-9.-]+/g, ''));
      return !isNaN(numberValue) ? numberValue: og;
    }
    return cell.value;
  }

  dropdownEditFunction(params: any) {
    // busy loop works?
    for (let i = 0; i < 1000000; i++) {}
    return this.isPasting;
  }


  exportParams(): ExportParams<any> {
    return {
      columnGroups: true,
      allColumns: false,
      columnKeys: this.columnApi.getAllDisplayedColumns()
        .filter(col =>
          !['index', 'images', 'po_document_attachments',
            'supplier_documents_attachments'].includes(col.getColId()))
        .map(col => col.getColId()),
      processRowGroupCallback: function(params) {
        if (params.node.group) {
          return null;
        }
        return params.node.data;
      },
      shouldRowBeSkipped: function(params) {
        return !params.node.data;
      },
      processHeaderCallback: headerCallback,
      processCellCallback: this.processCellCallback.bind(this),
    }
  };

  processCellCallback(cell: any) {
    
    let coldef = cell.column.getColDef();
    if (!cell.node || !cell.node.data) {
      return;
    }
    if (cell && coldef && coldef.valueFormatter) {
      // Use the valueFormatter to format the cell's value
      const formattedValue = (cell.column.getColDef() as any).valueFormatter({
        value: cell.value,
        data: cell.node.data,
        node: cell.node,
        colDef: cell.column.getColDef(),
        column: cell.column,
        isCSV: true,
        // Include other properties if your valueFormatter depends on them
      });
      return formattedValue;
    } else if (coldef.type == 'dropdown' && cell.node.data) {
      let colid = cell.column.getColId()
      let drops = cell.context[colid];
      let found = drops.find((val: any) => val.id == cell.node?.data[colid]);
      return found ? found.value : '';
    }
    else {
      return cell.value;
    }
  }

  onExportDataAsCsv(gridApi: GridApi, dropDownOptions: any): void {
    JobTableV2Component.sharedDropDownOptions = dropDownOptions;
    gridApi.exportDataAsCsv(this.exportParams());
  }
  
  onExportDataAsExcel(gridApi: GridApi, dropDownOptions: any): void {
    JobTableV2Component.sharedDropDownOptions = dropDownOptions;
    gridApi.exportDataAsExcel(this.exportParams());
  }

  updateMousePosition(event: MouseEvent) {
    this.mousePosition.x = event.clientX;
    this.mousePosition.y = event.clientY;
  }

  handleNewSubItemsToggle(result: any) {
    if (!this.jobs) {
      this.queue_subitems = result;
      return;
    }
    let subitems = result.subitems;
    let items = result.items;
    let new_rows: any = [];
    subitems.forEach((sub: any) => {
      if (this.jobs && this.jobs.find((job: any) => job.id == sub.id)) return;
      let found = items.find((i: any) => i.id == sub.item_id);
      if (!found) return;
      let new_row = {...deepCopy(found), ...deepCopy(sub)};
      delete new_row.item_id;
      new_row.has_subitems.has = false;
      new_row.has_subitems.item_id = found.id;
      new_rows.push(new_row);
    })
    this.addNewRowData(new_rows);
  }

  handleNewSubItems(event: any) {
    let node = event.node;
    let results = event.results;
    let item_data = results.items[0];
    let subitem_data = results.subitems;
    node.data.has_subitems.has = true;
    let new_rows: any = [];
    let cur_row = node.data;
    subitem_data.forEach((sub: any) => {
      let cur = deepCopy(sub);
      let new_row = {...deepCopy(item_data), ...cur};
      new_row.has_subitems.has = false;
      new_row.has_subitems.item_id = cur_row.id;
      new_rows.push(new_row);
    });
    this.addNewRowData(new_rows);
  }

  addNewRowData(new_rows: any) {
    this.jobs = this.jobs.concat(new_rows);
    const transaction: any = {add: new_rows};
    this.gridApi.applyTransaction(transaction);
    this.cdr.detectChanges();
  }

  showSubItems(params: any) {
    let item_id = params.node.data.id;
    // if subitems already showing, dont show
    if (this.jobs.find((j: any) => j?.has_subitems?.item_id == item_id)) return;
    this.orderService.showSubItem({id: item_id})
    .subscribe((results) => {
      this.handleNewSubItems({'node': params.node, results: results});
    })
  }

  deleteItemSplitFromSubitem(params: any) {
    let item_id = params.node.data.has_subitems.item_id;
    this.deleteItemSplit(item_id);
  }

  deleteItemSplitFromItem(params: any) {
    params.node.data.has_subitems.has = false;
    let item_id = params.node.data.id
    this.deleteItemSplit(item_id);
  }

  deleteItemSplit(item_id: string) {
    this.orderService.deleteItemSplit({id: item_id, 'delete': true})
    .subscribe((results: any) => {
      let sub_items = results.results;
      let cur_jobs = this.jobs.filter((job: any) => !sub_items.includes(job.id));
      let cur_item = cur_jobs.find((i: any) => i.id == item_id);
      if (cur_item) {
        cur_item.has_subitems.has = false;
      }
      this.jobs = cur_jobs;
      this.gridApi.setRowData(this.jobs);
    });
  }

  getContextMenuItems = (params: any) => {
    document.addEventListener('mousemove', this.updateMousePosition.bind(this));
    if(params == undefined || params == null|| params.node == null || params.node == undefined){
      return [];
    }


    var result: (string | MenuItemDef)[] = [
      {
        name: "Duplicate item",
        icon: "<i class=\"pi pi-copy\"></i>",
        action: () => {
          //need to duplicate all row attributes etc.
          let row = params.node.data;
          //first get clean copy
          this.orderService.getOrderItems({ "payload": { "id": row["id"] } }).subscribe((result: any) => {
            let newItem = deepCopy(result);

            //remove old id.
            delete newItem.id;
            if (newItem.delivery_date) {
              newItem.delivery_date = new Date(Date.parse(newItem.delivery_date)).toISOString().slice(0, 10);
            }
            if (newItem.ship_date) {
              newItem.ship_date = new Date(Date.parse(newItem.ship_date)).toISOString().slice(0, 10);
            }
            if (newItem.in_stock_date) {
              newItem.in_stock_date = new Date(Date.parse(newItem.in_stock_date)).toISOString().slice(0, 10);
            }
            if (newItem.requested_delivery_date) {
              newItem.requested_delivery_date = new Date(Date.parse(newItem.requested_delivery_date)).toISOString().slice(0, 10);
            }
            if (newItem.payment_sent) {
              newItem.payment_sent = new Date(Date.parse(newItem.payment_sent)).toISOString().slice(0, 10);
            }
            if (newItem.payment_received) {
              newItem.payment_received = new Date(Date.parse(newItem.payment_received)).toISOString().slice(0, 10);
            }
            if (newItem.warehouse_rcpt_date) {
              newItem.warehouse_rcpt_date = new Date(Date.parse(newItem.warehouse_rcpt_date)).toISOString().slice(0, 10);
            }
            if (newItem.custom6d) {
              newItem.custom6d = new Date(Date.parse(newItem.custom6d)).toISOString().slice(0, 10);
            }

            newItem.images = [];
            this.orderService.addItem({ "payload": newItem }).subscribe((result2: any) => {
              let newrow = deepCopy(row);
              newrow['id'] = result2.id;
              this.jobs.push(newrow);
              const transaction: any = {add: [newrow]};
              this.gridApi.applyTransaction(transaction);
              this.cdr.detectChanges();

            });

          });
        }
      },
      {
        name: "Delete item",
        icon: "<i class=\"pi pi-times\"></i>",
        action: () => {
          this.openDialog(params.node?.data);
          this.cdr.detectChanges();
        }
      },
      {
        name: "Move jobs",
        icon: "<i class=\"pi bi-arrow-repeat\"></i>",
        action: () => {
          this.openChangeItemJob(params.node?.data);
          this.cdr.detectChanges();
        }
      },
      "separator",
      {
        name: "Add a comment",
        icon: "<i class=\"pi bi-chat-right-text\"></i>",
        action: () => {
          this.showCommentPopup(params);
        }
      },
      {
        name: "Email supplier",
        icon: "<i class=\"pi pi-send\"></i>",
        action: () => {
          this.supplierCheckinDialog(params.node?.data);
          this.cdr.detectChanges();
        }
      },
      "separator",
      {
        name: "Export",
        subMenu: [
          {
            name: "CSV Export",
            action: () => this.onExportDataAsCsv(params?.api, params?.context),
            icon: "<span class=\"ag-icon ag-icon-csv\" unselectable=\"on\" role=\"presentation\"></span>"
          },
          {
            name: "Excel Export",
            action: () => this.onExportDataAsExcel(params?.api, params?.context),
            icon: "<span class=\"ag-icon ag-icon-excel\" unselectable=\"on\" role=\"presentation\"></span>"
          }
        ],
        icon: "<span class=\"ag-icon ag-icon-save\" unselectable=\"on\" role=\"presentation\"></span>"

      }
    ];
    if (params.node.data.has_subitems != null) {
      let subitems = params.node.data.has_subitems;
      if (subitems.has) {
        let show_subitems = {
          name: 'Show subitems',
          icon: '<i class="pi bi-arrow-down-up"></i>',
          action: () => {
              this.showSubItems(params);
          }
        }
        result.splice(2, 0, show_subitems);
        let delete_split = {
          name: 'Merge split',
          icon: '<i class="pi bi-arrows-collapse"></i>',
          action: () => {
              this.deleteItemSplitFromItem(params);
          }
        }
        result.splice(3, 0, delete_split);
      } else if (subitems.item_id) {
        let delete_split = {
          name: 'Merge split',
          icon: '<i class="pi bi-arrows-collapse"></i>',
          action: () => {
              this.deleteItemSplitFromSubitem(params);
          }
        }
        result.splice(4, 0, delete_split);
      } else if (subitems.q > 1) {
        let split = {
          name: "Split item",
          icon: "<i class=\"pi bi-arrows-expand\"></i>",
          action: () => {
            let cur_item = params.node.data;
            this.openSplitItem(params);
          }
        };
        result.splice(2, 0, split);
      }
     }
     if (params.node.data.has_subitems && params.node.data.has_subitems.item_id) {
        return result.slice(-5);
     }
    return result;
  };

  get appliedFilters(): any {
    return !!this.selectedView?.activeView?.get_filters()?.length;
  }

  resetIndex() {
    let customCounter = 0;
    if (this.gridApi) {
      this.gridApi.forEachNodeAfterFilterAndSort(function(node) {
        if (node.displayed && !node.group) {
          node.rowIndex = customCounter++;
        }
      });
      this.gridApi.refreshCells({ columns: ['index'], force: true });
      this.djv.row_data_updated(true);
    }
  }

  onModelUpdated(event: any) {
    this.resetIndex();
  }

  onFilterChanged(event: any) {
    this.resetIndex();
  }
  
  columnEverythingChanged(event: any) {
  }

  onRowDataUpdated(params: any) {
    this.doMatchingFilter();
    this.doSortGroupWork();
    this.resetIndex();
  }

  updateSupplierContacts() {
  }


  indexmap = new Map();

  applySearchTerm2(key: string, value: any, searchTerm: string) {
    if (value != null) {
      let map1 = this.columnApi.getColumn(key)?.getColDef().valueFormatter;
      let valcol = "";
      if (key === "po_document_number" || key === "supplier_documents_numbers") {
        valcol = "";
        if (value.number) {
          valcol = value.number;
        } else {
          for (let i = 0; i < value.length; i++) {
            let obj = value[i];
            valcol += obj.number;
          }
        }
      } else {
        valcol = value;
      }
      if (map1 != undefined && typeof map1 === "function") {
        if (valcol != undefined) {
          let json: any = {
            "colDef": { "field": key },
            "value": valcol
          };
          valcol = map1(json);
        }
      }
      if (searchTerm && valcol && valcol.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }
    }
    return false;
  }


  applySearchTerm(code:number) {
    if(this.search && this.selectedView?.activeView?.id){
      let keyst = ""+this.selectedView?.activeView.id;
      this.searchTerm = this.search.hasOwnProperty(keyst) ? this.search[keyst]: undefined;
      this.gridApi.onFilterChanged();
    }
}

  onStoreRefreshed(event: any) {
  }

  private static calculateOrdersNumber = function(params: ValueGetterParams) {

    let result: string[] = [];
    if (params && params.data && params.data.supplier_documents_numbers) {

      if (Array.isArray(params.data.supplier_documents_numbers)) {
        params.data.supplier_documents_numbers.forEach((doc: { documentType: number; number: string; }) => {

          result.push(doc.number);

        });

      } else {
        result.push(params.data.supplier_documents_numbers.number);
      }
    }
    return result;
  };

  private static calculatePurchaseOrdersNumber = function(params: ValueGetterParams) {

    let result: string[] = [];

    if (params && params.data && params.data.po_document_number) {
      if (Array.isArray(params.data.po_document_number)) {
        params.data.po_document_number.forEach((doc: { documentType: number; number: string; }) => {
          result.push(doc.number);
        });
      } else {
        result.push(params.data.po_document_number.number);
      }
    }
    return result;
  };


  setColDef() {
    if (this.columnApi == undefined){
      return;
    }

    let widthmap = new Map();
    let pinmap = new Map();
    let indexmap = new Map();
    let is_visible: any = ['index'];

    let fields_set = false;
    if (this.selectedView?.activeView &&
        this.selectedView?.activeView.fields_selected_to_display &&
        this.selectedView?.activeView.fields_selected_to_display.length) {
      fields_set = true;
      let fields_save = deepCopy(this.selectedView?.activeView.fields_selected_to_display);
      for (let i = 0; i < fields_save.length; i++) {
        let column = fields_save[i];
        if (column.field && column.field != "index") {
          widthmap.set(column.field, column.width);
          pinmap.set(column.field, column.pinned ? column.pinned: false);
        }
        if (column.visible) {
          is_visible.push(column.field);
        }
        if (!column.hasOwnProperty("order")) {
          indexmap.set(column.field, column.length + i);
        } else {
          indexmap.set(column.field, column.order);
        }
      }
    }
    let default_visible: any = [];
    let tableinfoMap = new Map();
    for (let i = 0; i < TableConstants.jobItemsTableColumns.length; i++) {
      let column = TableConstants.jobItemsTableColumns[i];
      if (!fields_set) {
        default_visible.push(column.field);
      }
      tableinfoMap.set(column.field, column);
    }
    let tempcolumns = getTempColumns.bind(this)(tableinfoMap, widthmap, pinmap);
    if (!fields_set) {  // always show something
      is_visible = default_visible;
    }
    tempcolumns.forEach((item: any) => {
      if (item.type == 'dropdown') {
        item.editable = this.dropdownEditFunction.bind(this);
        // item.editable = true;
        item.readOnlyEdit = true;
      }
      if (is_visible.includes(item.field)) {
        item.hide = false;
      } else {
        item.hide = true;
      }
    })
    let grid_cols = deepArrayClone(tempcolumns?.sort((a: any, b: any) => indexmap.get(a.field) - indexmap.get(b.field)));
    grid_cols.forEach((col: any) => {delete col.order;})
    this.columns = [...grid_cols];
    grid_cols = grid_cols.map((col: any) => ({ ...col }));
    this.columns = [...grid_cols];
    this.cdr.detectChanges();
    if (!fields_set && this.selectedView?.activeView && this.selectedView?.activeView.id) {
      this.dashboardActions.requestUpdateView({ fields_selected_to_display: TableConstants.jobItemsTableColumns,
                                                selectedViewId: this.selectedView?.activeView?.id });
    }
  }

  doMatchingFilter() {
    if (this.isGridDestroyed()) return;
    if (!this.gridApi || !this.selectedView || !this.selectedView.activeView) return;
    let active_view = this.selectedView.activeView;
    let flist = active_view.get_filters();

    if (!flist || !flist.length) {
      this.gridApi.setAdvancedFilterModel(null);
      return;
    }
    //for each filter, if we match field
    let anyfilter = false;
    let allfilters = [];
    for (let i = 0; i < flist.length ; i++) {

      let fil = flist[i];
      let key:string = fil['key'];

      ///1. Date based columns filtering.....
      if(TableConstants.dateColumnLayout.includes(key)){

        //lets see if its blank operations
        let myoperation =  this.getTextOperatorText(fil['operator'], key);
        if(myoperation == 'blank' || myoperation == 'empty' || myoperation == 'notBlank' || myoperation == 'notEmpty'  ) {
          let myfilters: DateStringAdvancedFilterModel = {
            colId: key,
            filterType: 'dateString',
            type: myoperation,

          }
          allfilters.push(myfilters);
        }
        else if(myoperation == 'notEqual') {
        //want to include nulls in not equals, so will make an advnaced filter with or condition
          let setItemMatches:any[] = [];
          let myfilters:DateStringAdvancedFilterModel = {
            colId: key,
            filterType: 'dateString',
            type: myoperation,
            filter: fil['value'][0]
          }
          setItemMatches.push(myfilters);
          let myfilters2:DateStringAdvancedFilterModel = {
            colId: key,
            filterType: 'dateString',
            type: 'blank',
          }
          setItemMatches.push(myfilters2);

          let advanced1: JoinAdvancedFilterModel = { type: "OR", filterType: "join", conditions: setItemMatches };
          allfilters.push(advanced1);

        }else {
          //const dateParts = ("" + fil['value'][0]).split('-');
          //let result = dateParts[0] + "-" + dateParts[1] + "-" + dateParts[2];
            let myfilters: DateStringAdvancedFilterModel = {
              colId: key,
              filterType: 'dateString',
              type: this.getTextOperatorText(fil['operator'], key),
              filter: fil['value'][0]
            }
            allfilters.push(myfilters);
        }

        //2. for set options
      }else if(TableConstants.optionsColumnsLayout.includes(key)){
        //for advanced filtering, if its single (contains/notcontains) its a simple column filter. other wise bunch of "and" or "or"s

        let testtype =  this.getTextOperatorText(fil['operator'], key);
        if(testtype == 'blank' || testtype == 'empty'  ){
          let myfilters:TextAdvancedFilterModel;
          if(TableConstants.optionsSpecialViewOutput.includes(key)){
              myfilters = {
              colId: key,
              filterType: 'text',
              type: 'equals',
              filter: "0"
            }
          }else{
            myfilters = {
              colId: key,
              filterType: 'text',
              type: 'blank',
            }
          }
          allfilters.push(myfilters);
        }else if(testtype == 'notBlank' || testtype == 'notEmpty'  ) {
          let myfilters: TextAdvancedFilterModel;
          if(TableConstants.optionsSpecialViewOutput.includes(key)) {
            myfilters = {
              colId: key,
              filterType: 'text',
              type: 'notEqual',
              filter: "0"
            }
          }else{
            myfilters = {
              colId: key,
              filterType: 'text',
              type: 'notBlank',
            }
          }
          allfilters.push(myfilters);
        } else{
          //matching or not against set of item values.....

          if(testtype == 'equal' || testtype == 'contains'){
            //if we are dealing with one item....
            let listvals:any[] = fil['value'];
            if(listvals.length == 1) {
              //let convertedValue = this.fastLookupOptions(key,listvals[0])
              /*this.dropdownOptions[key]?.find(
                (dropdownItem: any) => dropdownItem?.id == fil['value'][0]
              )?.value;*/
              let myfilters: TextAdvancedFilterModel = {
                colId: key,
                filterType: 'text',
                type: 'equals',
                filter: fil['value'][0]
              }
              if(TableConstants.optionsReverseMappingOutput.includes(key)){
                myfilters.filter = this.fastLookupOptions(key,listvals[0]);
              }
              if(TableConstants.limitedSelect.includes(key)){
                myfilters.type = this.getTextOperatorText(fil['operator'], key);
              }
              allfilters.push(myfilters);
            }else{
                //dealing with list of items its equal so collection of "ors"....
              let setItemMatches:any[] = [];
              for (let j = 0; j <listvals.length ; j++) {
                let convertedValue = this.dropdownOptions[key]?.find(
                  (dropdownItem: any) => dropdownItem?.id == listvals[j]
                )?.value;
                let myfilters: TextAdvancedFilterModel = {
                  colId: key,
                  filterType: 'text',
                  type: 'equals',
                  filter: listvals[j]
                }
                if(TableConstants.optionsReverseMappingOutput.includes(key)){
                  myfilters.filter = this.fastLookupOptions(key,listvals[j]);
                }
                if(TableConstants.limitedSelect.includes(key)){
                  myfilters.type = this.getTextOperatorText(fil['operator'], key);
                }
                setItemMatches.push(myfilters);
              }

              let advanced1: JoinAdvancedFilterModel = { type: "OR", filterType: "join", conditions: setItemMatches };
              allfilters.push(advanced1);
            }
          }else{
            let listvals:any[] = fil['value'];
            if(listvals.length == 1) {
              let v = this.dropdownOptions[key]?.find(
                (dropdownItem: any) => dropdownItem?.id == fil['value'][0]
              )?.value;
              let myfilters: TextAdvancedFilterModel = {
                colId: key,
                filterType: 'text',
                type: 'notEqual',
                filter: fil['value'][0]
              }
              if(TableConstants.optionsReverseMappingOutput.includes(key)){
                myfilters.filter = this.fastLookupOptions(key,listvals[0]);
              }
              if(TableConstants.limitedSelect.includes(key)){
                myfilters.type = this.getTextOperatorText(fil['operator'], key);
              }
              allfilters.push(myfilters);
            }else{
              //dealing with list of items its notequal so collection of "ands"....
              let setItemMatches:any[] = [];
              for (let j = 0; j <listvals.length ; j++) {
                let convertedValue = this.dropdownOptions[key]?.find(
                  (dropdownItem: any) => dropdownItem?.id == listvals[j]
                )?.value;
                let myfilters: TextAdvancedFilterModel = {
                  colId: key,
                  filterType: 'text',
                  type: 'notEqual',
                  filter: listvals[j]
                }
                if(TableConstants.optionsReverseMappingOutput.includes(key)){
                  myfilters.filter = this.fastLookupOptions(key,listvals[j]);
                }
                if(TableConstants.limitedSelect.includes(key)){
                  myfilters.type = this.getTextOperatorText(fil['operator'], key);
                }
                setItemMatches.push(myfilters);
              }

              let advanced1: JoinAdvancedFilterModel = { type: "AND", filterType: "join", conditions: setItemMatches };
              allfilters.push(advanced1);
            }
          }

        }
//3. number filtering
      } else if(TableConstants.numberColumnsLayout.includes(key)){
        let myoperation = this.getTextOperatorText(fil['operator'], key);

        //want to incude nulls in notEquals
        if(myoperation == '!=' || myoperation == 'notEqual'){
          // 2 operations, with or between
          let setItemMatches:any[] = [];
          let myfilters:NumberAdvancedFilterModel = {
            colId: key,
            filterType: 'number',
            type: myoperation,
            filter: fil['value'][0]
          }
          setItemMatches.push(myfilters);
          let myfilters2:NumberAdvancedFilterModel = {
            colId: key,
            filterType: 'number',
            type: 'blank',
          }
          setItemMatches.push(myfilters2);

          let advanced1: JoinAdvancedFilterModel = { type: "OR", filterType: "join", conditions: setItemMatches };
          allfilters.push(advanced1);

        }else{
          //just do the number filter normally....
          let myfilters:NumberAdvancedFilterModel = {
            colId: key,
            filterType: 'number',
            type: myoperation,
            filter: fil['value'][0]
          }
          allfilters.push(myfilters);
        }
//else text filtering
      }else{
        let testtype =  this.getTextOperatorText(fil['operator'], key);
        let myfilters:TextAdvancedFilterModel;
        if(testtype == 'blank' || testtype == 'empty'  ) {
          myfilters = {
            colId: key,
            filterType: 'text',
            type: 'blank',
          }
        }else if(testtype == 'notBlank' ) {
          myfilters = {
            colId: key,
            filterType: 'text',
            type: 'notBlank',
          }
        }else{
            myfilters = {
              colId: key,
              filterType: 'text',
              type: this.getTextOperatorText(fil['operator'], key),
              filter: fil['value'][0]
            }
          }
      /*  let myfilters:TextAdvancedFilterModel = {
          colId: key,
          filterType: 'text',
          type: this.getTextOperatorText(fil['operator'], key),
          filter: fil['value'][0]
        }*/
        allfilters.push(myfilters);
      }

      //"2"

    }//for each filter
    if (this.gridApi) {
      if(allfilters.length == 0) {
        this.gridApi.setAdvancedFilterModel(null);
      }else if(allfilters.length == 1){
        this.gridApi.setAdvancedFilterModel(allfilters[0]);
      }else{
        let operationtype: 'AND'|'OR' = this.selectedView.activeView.get_main_operator() == 0 ? "AND": "OR";
        let advanced: JoinAdvancedFilterModel = { type: operationtype, filterType: "join", conditions: allfilters };
        this.gridApi.setAdvancedFilterModel(advanced);
      }
      this.gridApi.onFilterChanged();
      this.cdr.detectChanges();
    }
  }

  getTextOperatorText(value:number,column_key:string):any{

    //need to figure out which  kind of column
    let comparetype = 'materlog';

    if(TableConstants.dateColumnLayout.includes(column_key)){
      if (value == 0) {
        return "contains"
      } else if (value == 1) {
        return "notContains"
      } else if (value == 2) {
        return "equals"
      } else if (value == 3) {
        return "notEqual"
      } else if (value == 4) {
        return "blank"
      } else if (value == 5) {
        return "notBlank"
      } else if (value == 6) {
        return "lessThan"
      } else if (value == 7) {
        return "greaterThan"
      } else if (value == 8) {
        return "lessThan"//was before
      } else if (value == 9) {
        return "greaterThan"//was after
      }

    }
    else if(TableConstants.optionsColumnsLayout.includes(column_key)){
      if (value == 0) {
        return "contains"
      } else if (value == 1) {
        return "notContains"
      } else if (value == 2) {
        return "equal"
      } else if (value == 3) {
        return "notEqual"
      } else if (value == 4) {
        return "blank"
      } else if (value == 5) {
        return "notBlank"
      }

    }
    else if(TableConstants.numberColumnsLayout.includes(column_key)){
      if (value == 0) {
        return "lessThan"
      } else if (value == 1) {
        return "greaterThan"
      } else if (value == 2) {
        return "="
      } else if (value == 3) {
        return "!="
      } else if (value == 4) {
        return "blank"
      } else if (value == 5) {
        return "notBlank"
      } else if (value == 6) {
        return "lessThan"
      } else if (value == 7) {
        return "greaterThan"
      } else if (value == 8) {
        return "notBlank"
      } else if (value == 9) {
        return "empty"
      }

    }
    else {
      if (value == 0) {
        return "contains"
      } else if (value == 1) {
        return "notContains"
      } else if (value == 2) {
        return "equals"
      } else if (value == 3) {
        return "notEqual"
      } else if (value == 4) {
        return "blank"
      } else if (value == 5) {
        return "notBlank"
      } else if (value == 6) {
        return "lessThanOrEqual"
      } else if (value == 7) {
        return "greaterThanOrEqual"
      } else if (value == 8) {
        return "lessThan"
      } else if (value == 9) {
        return "greaterThan"
      }
    }
    return "equals"
  }

  dropdownValueFormatter = (params: ValueFormatterParams) => {
    const field: any = params.colDef.field;
    const value = this.fastLookupOptions(field,params?.value);
    return value;
  };


  dropdownValueGetter = (params: any) => {
    if (params.isCSV) {
      if(params && params.value != undefined) {
        const field: any = params.colDef.field;
        return this.fastLookupOptions(field, params.value);
      } else {
        return '';
      }
    }
    if(params && params?.data) {
      const field: any = params.colDef.field;

      if (TableConstants.optionsSpecialViewOutput.includes(field)) {
        return '' + params.value;
      }
      else if (TableConstants.optionsColumnsLayout.includes(field)) {
        return '' + params.value;
      }
      return this.fastLookupOptions(field, params?.value);
    }
    else if(params && params.colDef && params.value){
      let field: any = params.colDef.field;
      return this.fastLookupOptions(field,params?.value) + '';
    }
    else {
      return params.value;
     }
   };


  dateStringValueGetter = (params:any) => {
    return params?.data[params.colDef.field];
  }

  dateValueFormatter = (params: any) => {
    if (params != null && params.value != null) {
      return new Date(params.value).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "UTC",
      });

    }
    return "";
  };

  agMultiColumnFilterDefinitionFilter = [
    {
      filter: 'agTextColumnFilter',
      filterParams: {
        caseSensitive: false,
        buttons: ['reset', 'cancel'],
        valueFormatter: this.dropdownValueFormatter,
      },
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: false,
        buttons: ['reset', 'cancel'],
        valueFormatter: this.dropdownValueFormatter,
      },
    },
  ];
  numberValueFormatter = function (params: ValueFormatterParams) {
    if(params&& params.column && params.column.getColId() && TableConstants.wholenumberColumns.includes(params.column.getColId())){
      return params.value;
    }

    if(params.value != null && params.value != undefined && typeof params.value === 'number') {

      return params.value.toFixed(2);
    }

    return params.value;
  };

  numberValueGetter = function(params:any){
  let field: any = params.colDef.field;
  //need to allow for 0
  if(params.data && params?.data[field] != undefined && params?.data[field]  != null ) {
    return parseFloat(params?.data[field]);
  }
  return null;
  }

  moneyValueFormatter = function(params: ValueFormatterParams) {

    if(params.value != null && params.value != undefined && typeof params.value === 'number') {
      return "$ " +  params.value.toFixed(2) ;
    }

    return params.value;
  }
  dateComparator(date1:any, date2:any) {
    const date1Timestamp = new Date(date1).getTime();
    const date2Timestamp = new Date(date2).getTime();

    if (date1Timestamp < date2Timestamp) {
      return -1;
    } else if (date1Timestamp > date2Timestamp) {
      return 1;
    } else {
      return 0;
    }
  }

  setColTypes() {
    this.columnTypes = {
      indexing:{
        cellDataType: 'number',
        filter: 'agNumberColumnFilter',
      },
      money: {
        type: 'numericColumn',
        cellDataType: 'number',
        cellEditor: 'agNumberCellEditor',
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: ["equals", "notEqual", "lessThan", "lessThanOrEqual", "greaterThan", "greaterThanOrEqual", "blank", "notBlank"],
          buttons: ['reset', 'cancel'],
          includeBlanksInEquals: false,
          includeBlanksInLessThan: true,
          includeBlanksInGreaterThan: false,
          includeBlanksInRange: true,
        },
        valueFormatter: this.moneyValueFormatter,
        valueGetter: this.numberValueGetter,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {

          return this.doColumnCompareNullLast(valueA,valueB,isDescending);
        },
      },
      number: {
        type: 'numericColumn',
        filterType: 'number',
        cellDataType: 'number',
        filter: 'agNumberColumnFilter',
        cellEditor: 'agNumberCellEditor',
        filterParams: {
          buttons: ['reset', 'cancel'],
          includeBlanksInEquals: false,
          includeBlanksInLessThan: false,
          includeBlanksInGreaterThan: false,
          includeBlanksInRange: false,
        },
        valueFormatter: this.numberValueFormatter,
        valueGetter: this.numberValueGetter,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {

          return this.doColumnCompareNullLast(valueA,valueB,isDescending);
        },
      },
      text: {
        type: 'text',
        cellDataType: 'text',
        filter: 'agTextColumnFilter',
        sortable: true,
        filterParams: {
          filterOptions: ["equals", "notEqual", "lessThan", "lessThanOrEqual", "greaterThan", "greaterThanOrEqual", "blank", "notBlank"],
          includeBlanksInEquals: false,
        },
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return doTextColumnCompareNullLast(valueA,valueB,isDescending);
        },
      },
      object: {
        cellDataType: 'object',
        type: 'object',
        valueFormatter: (params: any) => {
          if (params && Array.isArray(params.value)) {
            return params.value.map((item: any) => item.number).join(', ');
          } else if (params && params.value && params.value.number) {
            return params.value.number;
          } else if (params && typeof params.value === 'string') {
            return params.value;
          }
          return '';
        },
        valueParser: (params: any) => {
          return '';
        },
        filter: true
      },
      dateString: {
        type: 'dateString',
        filter: true,
        cellDataType: 'dateString',
        cellEditor: CalendarDateEditor,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doDateColumnCompareNullLast(valueA, valueB, isDescending);
        },
        filterParams: {
          filterOptions: ["equals", "notEqual", "lessThan", "lessThanOrEqual", "greaterThan", "greaterThanOrEqual", "blank", "notBlank"],

          includeBlanksInEquals: false,

          comparator: (filterLocalDateAtMidnight:Date, cellValue:any) => {

            const cellDate = new Date(cellValue);
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
          buttons: ["reset", "cancel"]
        },

        valueGetter: (params:any) =>{

          let field: any = params.colDef.field;

          if(params?.data == null){
            return null;
          }

          let value =  params?.data[field];
          if(value == null || value == undefined){
            return null;
          }
          if (value != null && value.match('\\d{2}/\\d{2}/\\d{4}')) {

            const dateParts = value.split('/');
            return dateParts[2] + "-" + dateParts[0] + "-" + dateParts[1];
          }

          return value;

        },
        valueParser: (params: any) => {

          if(params.newValue) {

            if (params.newValue != null && params.newValue.match('\\d{4}-\\d{2}-\\d{2}')) {
              return params.newValue;
            }

            if (params.newValue != null && params.newValue.match('\\d{2}/\\d{2}/\\d{4}')) {
              const dateParts = params.newValue.split('/');
              return dateParts[2] + "-" + dateParts[0] + "-" + dateParts[1];
              // return params.newValue;
            }
          }
          return null;
        },
        cellRenderer: (params: any) => {
          if(params.value == null || params.value == undefined){
            return '';
          }
          const dateParts = params.value.split('-');
          return dateParts[1] + "/" + dateParts[2] + "/" + dateParts[0];


        },
        valueFormatter: (
          params: any
        ) => {

          if(params.value == null || params.value == undefined){
            return '';
          }
          return params.value;
        },
      },

      stringORdateColumn: {
        cellEditor: CalendarEditor,
        filter: 'agTextColumnFilter',
        cellDataType: 'text',
      },
      link: {
        cellRenderer: (params: any) => {
          let href = params.value?.includes("://") ? params.value?.split("://")[1] : params.value;
          return params.value
            ? "<a  class=\"link link--no-underline\" href=\"//" + href + "\" target=\"_blank\" rel=\"noreferrer noopener\" >" + params.value + "</a> "
            : null;
        },
        filter: 'agTextColumnFilter',
        cellDataType: 'text',
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {

          return doTextColumnCompareNullLast(valueA,valueB,isDescending);
        },
      },
      autocomplete: {
        valueCache: true,
        cellDataType: 'text',
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: this.agMultiColumnFilterDefinitionFilter,

        },
        valueFormatter: this.dropdownValueGetter,
        cellEditor: AutoCompleteEditor,
        enableSorting: true,
        cellEditorParams: (params: any) => {
          return {
            options: this.dropdownOptions[params.colDef.field],
            optionLabel: "value",
            optionValue: "id"
          };
        },
      },
      dropdown: {
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: this.agMultiColumnFilterDefinitionFilter,
        },
        cellDataType: 'object',
        cellRenderer: DropDownCellRenderer,
        valueFormatter: this.dropdownValueGetter,
        sortable: true,
      },
      dependencies: {
        cellDataType: 'object',
        cellRenderer: DependencyRenderer,
        sortable: false,
      },
      ship_link: {
        filterParams: {
          filters: this.agMultiColumnFilterDefinitionFilter,
        },
        filter: 'agTextColumnFilter',
        filterValueGetter: (params: any) => {
          let colDef = params.colDef.field;
          if (params && Array.isArray(params.data[colDef])) {
            return params.data[colDef].join('');
          }
          return '';
        },
        cellRenderer: ShipLinkCellRenderer,
        cellRendererParams: {
          isEditable: true
        },
        sortable: true,
        cellDataType: 'object',
        type: 'object',
        valueFormatter: (params: any) => {
          if (params && Array.isArray(params.value)) {
            return params.value.join('');
          }
          return '';
        },
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return doTextColumnCompareNullLast(valueA.join(''),valueB.join(''),isDescending);
        },
      },

    };
  }
  assignHistoryToResults(data: any) {
    if (!data || !data.history) return;
    let selectedView2 = data;
    const historymap = new Map();
    for (let i = 0; i < selectedView2.history.length; i++) {
      if (selectedView2.history[i].fields_changed) {
        historymap.set(selectedView2.history[i].id, selectedView2.history[i].fields_changed);
      }
    }
    if (selectedView2.history) {
      selectedView2.results?.forEach((item: any) => {
        if (historymap.has(item.id)) {
          item.fields_for_history_visible = historymap.get(item.id);
        }
      });
    }
    if (this.gridApi) this.gridApi.refreshCells({force: true});
    return selectedView2.results;
  }
  
  get hasDropDownOptions() {
    return Object.keys(this.dropdownOptions).length > 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isPasting) {
      this.isPasting = changes.isPasting.currentValue;
    }
    if (changes.activeJob?.currentValue) {
      this.currentJobViewName = changes.activeJob?.currentValue.name;
    }
    if (changes.dropdownOptions) {
      this.initDropdownOptions(this.dropdownOptions);
      if (this.gridApi) {
        this.gridOptions.context = {...this.gridOptions.context, ...this.dropdownOptions};
        this.gridApi.refreshCells({force: true});
      }
      if (this.isSortGroupFilterApplied()) {
        this.doSortGroupWork();
      }
    }
    if (!!changes.selectedView?.currentValue && !!this.selectedView && this.gridApi) {
      this.resetGridState();
      this.gridApi.refreshCells({force: true});
    }
  }

  resetGridState() {
    if (this.isGridDestroyed()) return;
    this.setColTypes();
    this.setColDef();
    this.initDropdownOptions(this.dropdownOptions);
    this.jobs = this.selectedView ? this.selectedView.results : [];
    if (this.activeJob?.name == 'Uncategorized items') {
      // ensure uncat no job id
      this.jobs = this.jobs.filter((j: any) => !j.job_id);
    }
    if (this.queue_subitems) {
      this.handleNewSubItemsToggle(this.queue_subitems)
      this.queue_subitems = undefined;
    }
    this.assignHistoryToResults(this.selectedView);
  }

  changeFriendCell(event: any) {
    // change cell column field value to if order id is the same
    let data = event.data;
    let col_field = event.colDef.field as string;
    let order_id = data.order_id;
    let change = this.jobs.filter((job: any) => job.order_id == order_id && job.id != data.id);
    change.forEach((item: any) => {
      item[col_field] = event.value;
    })
    this.gridApi.applyTransaction({ update: change });

    if (event.colDef.field == 'job_id') {
      if ((this.activeJob?.name == 'Uncategorized items' && event.value) ||
          (this.activeJob?.name != 'Uncategorized items' && !event.value)
      ) {
        setTimeout(() => {
          this.dashboardActions.requestUncategorizedViewBool(
            { include_items: CommonConstants.include_items.uncategorized });
        }, 300);

          let changes = this.jobs.filter((job: any) => job.order_id == order_id);
          this.jobs = this.jobs.filter((job: any) => job.order_id != order_id);
          this.gridApi.applyTransaction({ remove: changes });
      }
    }
  }

  onCellValueChanged(event: CellValueChangedEvent) {
    if (!event.colDef) return;
    let col_type = event.colDef.type as string;
    let col_field = event.colDef.field as string;

    let data = event.data;
    let is_subitem = false;
    let applied_write = false;

    if (data?.has_subitems?.item_id) {
      // this is a sub-item
      if (col_field && TableConstants.SubItemFields.includes(col_field)) {
        let value = undefined;
        if (col_type == 'number') {
          value = Number(event.value);
        } else if (col_type == 'date' || col_type == 'stringORdateColumn' || col_type == 'dateString') {
          value = this.datePipe.transform(event.value, "yyyy-MM-dd");
        } else if (col_type == 'dropdown' || col_type == 'text') {
          value = event.value;
        } else if (col_field == "tracking_info" || col_field == "shipment_tracking") {
          value = event.value ? [event.value] : [];
        } if (!value) {
          data[col_field] = event.oldValue;
          return;          
        }
        let payload = {[col_field]: value};
        this.orderService.updateSubItem({id: data.id, data: payload}).subscribe((result) => {
        });
      } else {
        // dont allow writes outside of above categories for subitem rows
        data[col_field] = event.oldValue;
        return;
      }
      is_subitem = true;

    } else if (col_type === "dropdown") {
      let lookup = this.dropdownOptions[col_field].find((item: any) => 
        item.id == event.value
      );
      if (lookup || !event.value) {
        this.dashboardActions.requestUpdateViewCell({ [`${col_field}`]: event.value,
                                                    id: event.data["id"] });
        applied_write = true;
      }
    } else if (col_type === "date") {
      this.dashboardActions.requestUpdateViewCell({
        [`${col_field}`]: this.datePipe.transform(event.value, "yyyy-MM-dd"),
        id: event.data["id"]
      });
      applied_write = true;
    } else if (col_type === "stringORdateColumn") {
      this.dashboardActions.requestUpdateViewCell({
        [`${event.colDef.field}`]: this.datePipe.transform(event.value, "yyyy-MM-dd"),
        id: event.data["id"]
      });
      applied_write = true;
    } else if (col_field == "tracking_info" || col_field == "shipment_tracking") {
      let v = event.value ? [event.value] : [];
      // this.dashboardActions.requestUpdateViewCell({ [`${col_field}`]: v, id: event.data["id"] });
      applied_write = false;
    } else {
      if (event.value == null) {
        //replace column null with 'not selected for specific columns
        if (col_field && TableConstants.optionsSpecialViewOutput.includes(col_field)) {
          event.value = 0;
        }
        else if (col_field && TableConstants.TextNonNull.includes(col_field)) {
          event.value = '';
        }

      }
      if(!TableConstants.columnsWithoutBackendDataDBSupport.includes(col_field)) {
        this.dashboardActions.requestUpdateViewCell({ [`${col_field}`]: event.value, id: event.data["id"] });
        applied_write = true;
      }
    }

    let updateApplied = false;
    //delayed refresh since need to wait to settle on s3
    if (!is_subitem) {
      if (applied_write && TableConstants.cellWhichNeedReloadSinceAffectOthers.includes(col_field)) {
        this.changeFriendCell(event);
      }
      else if (applied_write && col_field == 'quantity' && data.has_subitems) {
        data.has_subitems.q = event.value;
      }
    }
    if (applied_write && data?.has_subitems?.has && col_field && !TableConstants.SubItemFields.includes(col_field)) {
      // is a parent of subitems on a column
      this.jobs.forEach((job: any) => {
        if (job?.has_subitems?.item_id == data.id) {
          job[col_field] = event.value;
        }
      });
      const updateTransactions = this.jobs.filter((job: any) => job?.has_subitems?.item_id == data.id)
        .map((job: any) => ({...job, [col_field]: event.value}));

      this.gridApi.applyTransaction({ update: updateTransactions });
      updateApplied = true;

    }
    if (!updateApplied) {
      const updateTrans = this.jobs.find((j: any) => j.id == event.data.id);
      if (updateTrans) {
        this.gridApi.applyTransaction({ update: [updateTrans] });
      }
    }

    this.requestJobSupplierUpdate(event, col_field, event.data.order_id);
    this.maybeFilterOnChange(col_field);
    event.api.refreshCells({
      force: true,
      columns: [col_field],
      rowNodes: [event.node]
    });
  }

  requestJobSupplierUpdate(event: any, col_field: any, order_id: any) {
    if (col_field == 'supplier_id' || col_field == 'job_id') {
      let curOrders = this.allMailData?.curOrders;
      if (curOrders) {
        let order = curOrders.find((o: any) => o.id == order_id);
        if (order) {
          order[col_field] = event.value;
        }
      }
      if (col_field == 'supplier_id') {
        this.dashboardActions.requestSuppliersSmall();
      } else if (col_field == 'job_id') {
        this.dashboardActions.requestActiveJobs();
      }
    }
  }

  openDialog(selectedRow: any) {
    this.showDialog = true;
    this.deleteRowId = selectedRow.id;
    this.resetModal();
  }

  showCommentPopup(params: any) {
    this.formattedCell = this.processCellCallback(params);
    this.currentRow = params;
    this.showPopupComment = true;
    this.cdr.detectChanges();
    document.removeEventListener('mousemove', this.updateMousePosition.bind(this));
  }

  openSplitItem(params: any) {
    this.currentRow = params;
    this.showSplitItem = true;
    this.cdr.detectChanges();
    document.removeEventListener('mousemove', this.updateMousePosition.bind(this));
  }

  openChangeItemJob(params: any) {
    this.currentRow = params;
    this.showItemJob = true;
    this.cdr.detectChanges();
    document.removeEventListener('mousemove', this.updateMousePosition.bind(this));
  }

  changeItemJob(data: any) {
    if (data) {
      let item = data.item;
      item.job_id = data.job.id;
      if (this.activeJob.name == 'All jobs') {
        this.gridApi.applyTransaction({ update: [item] });
      } else {
        this.jobs = this.jobs.filter((j: any) => j.id != item.id);
        this.gridApi.applyTransaction({ remove: [item] });
      }
      let payload = {id: item.id, changed_job_id: item.job_id};
      this.orderService.updateItem({payload: payload}).subscribe((result: any) => {
      })
    }
    this.showItemJob = false;
    this.cdr.detectChanges();
    document.removeEventListener('mousemove', this.updateMousePosition.bind(this));
  }

  onBodyScrollEnd(event: any) {
    if (event && event.direction == 'horizontal') {
      this.addTrackingIconListener();
    }
  }

  addTrackingIconListener() {
    const icons = document.querySelectorAll('.lightningTrackIcon');
    const tooltip = document.querySelector('.lightning-tooltip') as HTMLElement;
    if (tooltip) {
      const updateTooltipPosition = (e: any) => {
        let left = e.clientX - 40;
        const max_close = 230;
        if (window.innerWidth - e.clientX < max_close) {
          left = window.innerWidth - max_close;
        }
        tooltip.style.left = `${left}px`;
        tooltip.style.top = `${e.clientY + 20}px`;
      };
  
      icons.forEach(icon => {
        icon.addEventListener('mouseenter', (e: any) => {
          updateTooltipPosition(e);
          tooltip.style.display = 'block';
        });
        icon.addEventListener('mouseleave', () => {
          tooltip.style.display = 'none';
        });
      });
    }
  }
  

  supplierCheckinDialog(selectedRow: any) {
    let json = {
      "data": selectedRow
    };
    this.ref = this.dialogService.open(ManualCommunicationComponent, json);
  }

  closeDialog() {
    this.showDialog = false;
    this.resetModal();
  }

  deleteItem(itemId: any) {
    const sendDeleteRequestAndSelectJob = (payload: any) => {
      this.dashboardActions.requestDeleteItemReason(payload);
      this.store
        .pipe(
          select(selectItems),
          filter((items: any) => !items.items.isLoading),
          take(1)
        )
        .subscribe((item: any) => {
          if (item.items?.errors) {
            this.customErrorService.setCustomErrorMessage(item.items);
          } else {
            this.deleteRowLocally();
          }
        });

      this.closeDialog();
    };

    this.isSubmitted = true;

    if (this.reasonToDelete == 3 && this.reasonToDeleteText?.trim()?.length >= 1) {
      sendDeleteRequestAndSelectJob({
        id: itemId,
        reason_to_not_display: this.reasonToDelete,
        reason_to_not_display_text: this.reasonToDeleteText
      });
    } else if (this.reasonToDelete != 0 && this.reasonToDelete !== 3 && this.reasonToDelete) {
      sendDeleteRequestAndSelectJob({
        id: itemId,
        reason_to_not_display: this.reasonToDelete
      });
    }
  }

  deleteRowLocally() {
    let selectedRows = this.gridApi.getRowNode(this.deleteRowId);
    const transaction: any = {
      remove: [selectedRows]
    };
    this.gridApi.applyTransaction(transaction);
  }

  resetModal() {
    this.isSubmitted = false;
    this.reasonToDelete = null;
    this.reasonToDeleteText = null;
  }

  get isDeleteError() {
    if (this.reasonToDelete === 3 && (this.reasonToDeleteText?.trim()?.length <= 1 || !this.reasonToDeleteText)) {
      return true;
    } else if (!this.reasonToDelete) {
      return true;
    } else {
      return false;
    }
  }

  private pullAGRidInfo() {
    if (this.duringLoading == true) {
      return;
    }
    //lets caputre the state of the columns
    let cset = this.columnApi.getColumnState();
    const colid_width = new Map();
    const colid_pinned = new Map();
    const colid_order = new Map();
    for (let i = 0; i < cset.length; i++) {
      colid_width.set(cset[i].colId, cset[i].width);
      colid_pinned.set(cset[i].colId, cset[i].pinned);
      colid_order.set(cset[i].colId, i);
    }

    //lets adjust the current view model to reflect this info
    if (this.selectedView != undefined && this.selectedView.activeView != undefined) {
      let fields_save;
      if (this.selectedView.activeView.fields_selected_to_display == undefined) {
        fields_save = deepCopy(TableConstants.jobItemsTableColumns);
      } else {
        fields_save = deepCopy(this.selectedView.activeView.fields_selected_to_display);
      }

      for (let i = 0; i < fields_save.length; i++) {
        let column = deepCopy(fields_save[i]);
        if (column.field) {

          if (colid_width.has(column.field)) {
            fields_save[i].width = colid_width.get(column.field);
          }

          if (colid_order.has(column.field)) {
            fields_save[i].order = colid_order.get(column.field);
          }

          if (colid_pinned.has(column.field)) {
            fields_save[i].pinned = colid_pinned.get(column.field);
          } else {
            fields_save[i].pinned = undefined;//might need ''
          }

        }
      }


      if (this.duringLoading == false) {
        //directly save to be....
        this.dashboardService.saveView({
          "payload": {
            fields_selected_to_display: deepCopy(fields_save),
            selectedViewId: this.selectedView?.activeView.id
          }
        }).subscribe(val => {});

      }
    }
  }


  interestingColumnEvent(params: any) {

    if (this.duringLoading == false) {
      this.pullAGRidInfo();
    }
  }

  internalCompare(a:any, b:any, isDescending:boolean){
    const nullPosition = isDescending ? -100000 : 100000
    // if a is null, push it towards whichever end null elements should end up
    if (a === null) return nullPosition
    if (b === null) return -nullPosition

    // OTHERWISE, both elements are non-null, so sort normally.
    // if a < b AND
    //     if ascending, a comes first, so return -1
    //     if descending, a comes after, so return 1
    if (a < b) return -1;

    // return the opposite of the previous condition
    if (a > b) return 1;

    // return 0 if both elements are equal
    return 0
  }
  internalDateCompare(a:any, b:any, isDescending:boolean){
    const nullPosition = isDescending ? -100000 : 100000
    // if a is null, push it towards whichever end null elements should end up
    if (a === null) return nullPosition
    if (b === null) return -nullPosition

    a = new Date(a);
    b = new Date(b);
    // OTHERWISE, both elements are non-null, so sort normally.
    // if a < b AND
    //     if ascending, a comes first, so return -1
    //     if descending, a comes after, so return 1
    if (a < b) return -1;

    // return the opposite of the previous condition
    if (a > b) return 1;

    // return 0 if both elements are equal
    return 0
  }


  //https://stackoverflow.com/questions/29829205/sort-an-array-so-that-null-values-always-come-last
  doColumnCompare(valueA: any, valueB: any, isDescending:boolean,field:string){

    let a = (valueA===null||valueA===undefined)?null:this.fastLookupOptions(field,valueA);/*this.dropdownOptions[field]?.find(
      (dropdownItem: any) => dropdownItem?.id === valueA
    )?.value;*/
    let b = (valueB===null||valueB===undefined)?null:this.fastLookupOptions(field,valueB);/*this.dropdownOptions[field]?.find(
      (dropdownItem: any) => dropdownItem?.id === valueB
    )?.value;*/
    return this.internalCompare(a,b,isDescending);
  }

  //some columns don't use 0 value to display so lets treat as null
  doColumnCompareNonZero(valueA: any, valueB: any, isDescending:boolean,field:string,){


    let a = (valueA===null||valueA==0||valueA===undefined)?null:this.fastLookupOptions(field,valueA);/*this.dropdownOptions[field]?.find(
      (dropdownItem: any) => dropdownItem?.id === valueA
    )?.value;*/
    let b = (valueB===null||valueB==0||valueB===undefined)?null:this.fastLookupOptions(field,valueB);/*this.dropdownOptions[field]?.find(
      (dropdownItem: any) => dropdownItem?.id === valueB
    )?.value;*/


    return this.internalCompare(a,b,isDescending);

  }


  doColumnCompareNullLast(a: any, b: any, isDescending:boolean){
    const nullPosition = isDescending ? -100000 : 100000
    // if a is null, push it towards whichever end null elements should end up
    if (a === null||a==undefined) return nullPosition
    if (b === null||b==undefined) return -nullPosition
    return this.internalCompare(a,b,isDescending);

  }
  doColumnCompareNullLastNonZero(a: any, b: any, isDescending:boolean){

    const nullPosition = isDescending ? -100000 : 100000
    // if a is null, push it towards whichever end null elements should end up
    if (a === null|| a==0||a==undefined) return nullPosition
    if (b === null|| b==0||b==undefined) return -nullPosition

    return this.internalCompare(a,b,isDescending);
  }

  doDateColumnCompareNullLast(a: any, b: any, isDescending:boolean){

    const nullPosition = isDescending ? -100000 : 100000
    // if a is null, push it towards whichever end null elements should end up
    if (a === null|| a==0||a==undefined) return nullPosition
    if (b === null||b==0||b==undefined) return -nullPosition
    return this.internalDateCompare(a,b,isDescending);
  }




  columnResizedOrReordered(params: any) {

    if (this.duringLoading == true) {
      return;
    }

    if (params.target.className === "ag-header-cell-resize") {
      this.pullAGRidInfo();
    } else if (params.target.className === "ag-header-cell ag-focus-managed ag-header-active" ||
      params.target.className == "ag-header-cell ag-header-cell-sortable ag-focus-managed ag-header-active") {
      this.pullAGRidInfo();
    } else if (params.target.className === "ag-header-cell ag-focus-managed ag-header-cell-moving") {
      this.pullAGRidInfo();
    } else {
    }

    //update all the widths
    //update all the pinned
    //update all the orders
//


  }

  discountFormatNumber(value:any) {
    // Check if the value is a number
    if (isNaN(value)) {
      return value; // Return the value as-is if it's not a number
    }

    // Convert to a fixed number of decimal places, and use toLocaleString for formatting
    return Number(value).toFixed(2).toLocaleString();
  }

  applyTransactionOnTable(items: any) {
    if (this.gridApi) this.gridApi.applyTransaction({ update: items });
  }

  doDeleteAttachment(event: any, deleteOrder: boolean = true) {
    let dela = event.deleteAttachment;
    let item = this.jobs.find((j: any) => j.id == dela.id);
    let payload = undefined;
    if (!item) return;
    let items = this.jobs.filter((j: any) => j.order_id == item.order_id);
    if (dela.attachment_id) {
      items.forEach((item: any) => {
        let po_docs = item.po_document_attachments;
        if (po_docs) {
          po_docs = po_docs.filter((p: any) => p.id != dela.attachment_id);
          if (po_docs.length != item.po_document_attachments.length) {
            let order_doc_id = item.po_document_attachments.find((p: any) => p.id == dela.attachment_id);
            if (order_doc_id) {
              payload = {deleteOrder: {order_item_id: dela.id, order_id: order_doc_id.order_document_id}};
            }
            item.po_document_attachments = deepCopy(po_docs);
          }
        }

        let order_docs = item.supplier_documents_attachments;
        if (order_docs) {
          order_docs = order_docs.filter((p: any) => p.id != dela.attachment_id);
          if (order_docs.length != item.supplier_documents_attachments.length) {
            let order_doc_id = item.supplier_documents_attachments.find((p: any) => p.id == dela.attachment_id);
            if (order_doc_id) {
              payload = {deleteOrder: {order_item_id: dela.id, order_id: order_doc_id.order_document_id}};
            }
            item.supplier_documents_attachments = deepCopy(order_docs);
          }
        }
      });
      this.gridApi.applyTransaction({ update: items });
      // if (payload && deleteOrder) this.doDeleteOrder(payload, false);
    }
  }

  doDeleteOrder(event: any, deleteAttachment: boolean = true) {
    let order_item_id = event.deleteOrder.order_item_id
    let item = this.jobs.find((j: any) => j.id == order_item_id);
    let payload = undefined;
    if (!item) return;
    let items = this.jobs.filter((j: any) => j.order_id == item.order_id);
    let order_doc_id = event.deleteOrder.order_id;
    items.forEach((item: any) => {
      let po_docs = item.po_document_number;
      if (po_docs?.id == order_doc_id) {
        item.po_document_number = {};
        // now remove attachment
        let po_att = item.po_document_attachments.find((a: any) => a.order_document_id == order_doc_id);
        if (po_att) {
          payload = {deleteAttachment: { id: order_item_id, attachment_id: po_att.id } };
        }
      }
      let order_docs = item.supplier_documents_numbers;
      order_docs = order_docs.filter((p: any) => p.id != order_doc_id);
      if (order_docs.length != item.supplier_documents_numbers.length) {
        item.supplier_documents_numbers = deepCopy(order_docs);
        let sup_att = item.supplier_documents_attachments.find((a: any) => a.order_document_id == order_doc_id);
        if (sup_att) {
          payload = {deleteAttachment: { id: order_item_id, attachment_id: sup_att.id } };
        }
      }
    });
    this.gridApi.applyTransaction({ update: items });
    if (payload && deleteAttachment) this.doDeleteAttachment(payload, false);
  }

  onAttachmentChange(event: any) {
    if (event.deleteAttachment) {
      this.doDeleteAttachment(event);
    } else if (event.deleteOrder) {
      this.doDeleteOrder(event);
    }
  }
}

import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TableConstants } from 'src/app/common/constants/table.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { SelectedViewModel } from '../../models/selected-view.model';
import { JobTableService } from '../../services/job-table.service';
import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import {deepCopy, deepArrayClone} from "../../../common/utils/general"


@Component({
  selector: 'pin-fields',
  templateUrl: './pin-fields.component.html',
  styleUrls: ['./pin-fields.component.sass']
})
export class PinFieldsComponent extends OnDestroyMixin() implements OnChanges {
  @Input() selectedView?: SelectedViewModel;
  @Input() isLoading!: boolean;

  faHidden = faMapPin;
  toggleFieldsModal: boolean = false;

  fields = TableConstants.jobItemsTableColumns;

  constructor(
    private dashboardActions: DashboardActions,
    private jobTableService: JobTableService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.selectedView?.currentValue && !!this.selectedView) {
      this.fields = deepCopy(this.selectedView.selectedColumns);
    }
  }

  clickOutsideModal(event: any): void {
    if (event.target.id !== 'group-fields-modal-button') {
      this.toggleFieldsModal = false;
    }
  }

  get numberOfGroupFields(): number {
    return this.fields.filter((field: any) => field.rowGroup).length;
  }

  showFieldsModal(event: any): void {
    event.preventDefault();
    this.toggleFieldsModal = !this.toggleFieldsModal;
  }

  ungroupAll(): void {
    this.fields.slice(1).forEach((field: any) => (field.rowGroup = false));
    this.triggerFieldsChange();
  }

  groupAll(): void {
    this.fields.forEach((field: any) => (field.visible = true));
    this.triggerFieldsChange();
  }

  reset(): void {

    this.triggerFieldsChange();
  }


  triggerFieldsChange(): void {
    this.dashboardActions.requestUpdateView({ fields_selected_to_pin: deepCopy(this.fields), selectedViewId: this.selectedView?.activeView.id });
    this.jobTableService.setHideShowFilter(this.fields);
  }



  trackByFn(index: number) {
    return index;
  }
}

import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorItemData, errorPostPutItemData, receiveItemData, receivePostPutItemData, requestItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IOrganization } from '../../models/company.model';
import { CompanyActionTypes } from '../actions/company-action-types';
import { IOrganizationState, OrganizationState } from '../models/organization.model';

export function organizationReducer(
  lastState: IOrganizationState = new OrganizationState(),
  action: GenericAction<CompanyActionTypes, any>
): IOrganizationState {
  switch (action.type) {

    case CompanyActionTypes.REQUEST_ORGANIZATION:
      return requestItemData<IOrganizationState, IOrganization>(lastState, ['organization']);
    case CompanyActionTypes.RECEIVE_ORGANIZATION:
      return receiveItemData<IOrganizationState, IOrganization>(lastState, ['organization'], action.payload);
    case CompanyActionTypes.ERROR_RECEIVE_ORGANIZATION:
      return errorItemData<IOrganizationState, IOrganization>(lastState, ['organization'], action.payload);

    case CompanyActionTypes.REQUEST_UPDATE_ORGANIZATION:
      return requestPostPutItemData<IOrganizationState, IOrganization>(lastState, ['organization'], action.payload);
    case CompanyActionTypes.RECEIVE_UPDATE_ORGANIZATION:
      return receivePostPutItemData<IOrganizationState, IOrganization>(lastState, ['organization'], action.payload);
    case CompanyActionTypes.ERROR_RECEIVE_UPDATE_ORGANIZATION:
      return errorPostPutItemData<IOrganizationState, IOrganization>(lastState, ['organization'], action.payload);

    default:
      return lastState;
  }
}

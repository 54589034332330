import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IActiveJobs, IJobs, INewJob, IUpdateJob } from './../models/dashboard.model';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { environment } from 'src/environments/environment';
import {decodeMsgPackSimple} from 'src/app/dashboard/services/do_msgpack'
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';



@Injectable()
export class CommentsService {
  private baseUrl: string = environment.ENV.BASE_URL;
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }
  private httpOptionsMsgPack = {
    headers: new HttpHeaders({
      'Accept': 'application/x-msgpack',
    }),
    responseType: 'arraybuffer' as any
  };

  public addComment(newComment: any): Observable<HttpErrorResponse> {
    return this.httpClient.post<void>(`${this.baseUrl}comments/`, newComment.payload,
        this.httpOptionsMsgPack)
        .pipe(map((response: any) => {
          return decodeMsgPackSimple(response);
          })
        );
  }
  
  public deleteComment(comment: any): Observable<HttpErrorResponse> {
    return this.httpClient.delete<any>(`${this.baseUrl}comments/${comment.payload}`, this.httpOptionsMsgPack)
    .pipe(
      catchError(() => {return throwError('Bad Comment');})
    );
  }

  public editComment(comment: any): Observable<HttpErrorResponse> {
    return this.httpClient.put<any>(`${this.baseUrl}comments/${comment.id}/`,
                                    comment.payload, this.httpOptionsMsgPack)
    .pipe(
      catchError(() => {return throwError('Bad Comment');})
    );
  }

  public getComments(params?: any): Observable<IJobs | HttpErrorResponse> {
    return this.httpClient.get<IJobs>(`${this.baseUrl}comments/`, {
      ...this.httpOptionsMsgPack,
      params: { ...params.payload },
    })
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }

}

import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { INewOrder, IOrderDocument, IOrders } from '../../models/dashboard.model';

export interface IOrderDocumentState {
  orderDocument: IStoreApiItem<IOrderDocument>;
}

export class OrderDocumentState implements IOrderDocumentState {
  orderDocument: IStoreApiItem<IOrderDocument> = new StoreApiItem();
}

import { IReceiverContact } from './../models/dashboard.model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { environment } from 'src/environments/environment';
import { IReceiver } from '../models/dashboard.model';

@Injectable()
export class ReceiversService {
  private baseUrl: string = environment.ENV.BASE_URL;
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  public getReceivers(): Observable<IReceiver | HttpErrorResponse> {
    return this.httpClient.get<IReceiver>(`${this.baseUrl}receivers/`, this.httpHelper.options);
  }

  public addReceiver(newReceiver: IReceiver | any): Observable<IReceiver | HttpErrorResponse> {
    return this.httpClient.post<IReceiver>(`${this.baseUrl}receivers/`, newReceiver.payload, this.httpHelper.options);
  }

  public addReceiverContact(newReceiverContact: IReceiverContact | any): Observable<IReceiver | HttpErrorResponse> {
    return this.httpClient.post<IReceiver>(`${this.baseUrl}receivers/contacts/`, newReceiverContact.payload, this.httpHelper.options);
  }

  public getAutocompleteReceivers(params: any): Observable<IReceiver | HttpErrorResponse> {
    return this.httpClient.get<IReceiver>(`${this.baseUrl}autocomplete/receivers/`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: { ...params.payload },
    })
  }

}

import { IStoreApiItem, StoreApiItem } from "src/app/common/models/store-api-item.model";


export interface ISharedViewPageState {
  sharedViewPage: IStoreApiItem<any>;
}

export class SharedViewPageState implements ISharedViewPageState {
  sharedViewPage: IStoreApiItem<any> = new StoreApiItem();
}

<div class="comapny">
  <div class="company__management">
    <div class="company__management-logo">
      <fa-icon *ngIf="company.logo === null || company.logo === ''" class="building" [icon]="faBuilding"></fa-icon>
      <img *ngIf="company.logo !== null && company.logo.length >= 1" [src]="logo" />
      <label for="image">
        <fa-icon [icon]="faEdit"></fa-icon>
        <input
          type="file"
          hidden
          id="image"
          name="image"
          accept=".png, .jpeg, .jpg, .jfif, .pjpeg, .pjp, .svg, .webp, .bmp"
          (change)="fileBrowseHandler($any($event.target).files)"
          multiple
        />
      </label>
    </div>
    <div class="company__management-name">
      <form>
        <p-dialog
          [(visible)]="updateCompanyName"
          [modal]="true"
          [resizable]="false"
          [draggable]="false"
          [closable]="false"
          [closeOnEscape]="false"
          [position]="'top'"
          [style]="{ marginTop: '150px', width: '500px' }"
          [maskStyleClass]="'dialog-mask'"
        >
          <ng-template pTemplate="header">
            <div class="dialog__header">
              <span>Edit company name</span>
            </div>
            <button class="dialog-close" type="button" (click)="closeNameDialog()"><i class="bi bi-x-lg"></i></button>
          </ng-template>

          <ng-template pTemplate="content">
            <div class="dialog-supplier">
              <div class="dialog-supplier__content">
                <div class="dialog-supplier__content-item">
                  <input
                    class="form-controls"
                    name="company-name"
                    id="company-name"
                    type="text"
                    placeholder="Company name"
                    minlength="1"
                    [(ngModel)]="company.name"
                  />
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="footer">
            <div class="dialog-supplier__content-action">
              <button class="dialog-supplier__content-action-button" type="submit" (click)="editName()">Save</button>
            </div>
          </ng-template>
        </p-dialog>
      </form>
      <h4 class="company-title">{{ company.name }}</h4>
      <button class="edit-button" type="button" (click)="openEditNameDialog()">
        <fa-icon class="edit" [icon]="faEdit"></fa-icon>
      </button>
      <h5>Business</h5>
    </div>
  </div>
  <div class="company__addresses">
    <div class="company__addresses-single" *ngFor="let item of addressList; let i = index">
      <div class="company__addresses-single-title">
        <p class="title" *ngIf="item.name !== null">{{ item.name }}{{ item.is_default ? ' (default)' : '' }}</p>
        <div class="company__addresses-single-title-action">
          <button class="edit-button" type="button" (click)="openDialog(item)">
            <fa-icon class="edit" [icon]="faEdit"></fa-icon>
          </button>
          <button class="delete-button" (click)="deleteAddress(item.address_id)">
            <fa-icon class="close" [icon]="faTrash"></fa-icon>
          </button>
        </div>
      </div>
      <p *ngIf="item.address !== null">{{ item.address }}</p>
      <p *ngIf="complexAddress(item).length >= 1 || item.city">
        {{ complexAddress(item) }}
      </p>
      <p *ngIf="item.phone !== null">{{ item.phone }}</p>

      <!-- modal  -->
      <form>
        <p-dialog
          [(visible)]="item.editable"
          [modal]="true"
          [resizable]="false"
          [draggable]="false"
          [closable]="false"
          [closeOnEscape]="false"
          [position]="'top'"
          [style]="{ marginTop: '150px', width: '540px' }"
          [contentStyleClass]="'job-dialog'"
          [maskStyleClass]="'dialog-mask'"
        >
          <ng-template pTemplate="header">
            <div class="dialog__header">
              <span>{{ item.address_id ? 'Edit address' : 'Add address' }}</span>
            </div>
            <button class="dialog-close" type="button" (click)="closeDialog(item)"><i class="bi bi-x-lg"></i></button>
          </ng-template>

          <div class="dialog-supplier">
            <div class="dialog-supplier__content">
              <div class="dialog-supplier__content-item">
                <input
                  class="form-controls"
                  name="address"
                  type="text"
                  placeholder="Address name"
                  minlength="1"
                  [(ngModel)]="item.name"
                  [required]="true"
                />
                <p *ngIf="item.name.length <= 1 && isSubmitted" class="textfield__error-message">Add an address name</p>
              </div>
              <div class="dialog-supplier__content-item">
                <geoapify-geocoder-autocomplete
                  [value]="item.address"
                  [debounceDelay]="50"
                  [limit]="5"
                  [lang]="'en'"
                  [biasByCountryCode]="['us']"
                  [filterByCountryCode]="['us']"
                  placeholder="Address"
                  [skipIcons]="true"
                  [skipDetails]="true"
                  (placeSelect)="placeSelected($event, i)"
                  (userInput)="item.address = $event"
                >
                </geoapify-geocoder-autocomplete>
                <p *ngIf="!item.address && isSubmitted" class="textfield__error-message">Enter an address</p>
              </div>
              <div class="dialog-supplier__content-item">
                <input class="form-controls" name="city" type="text" placeholder="City" [(ngModel)]="item.city" />
              </div>
              <div class="group">
                <div class="dialog-supplier__content-item">
                  <p-dropdown
                    appendTo="body"
                    [options]="statesList"
                    id="state"
                    optionValue="id"
                    optionLabel="value"
                    placeholder="State"
                    name="state"
                    [(ngModel)]="item.state"
                    [showClear]="true"
                  >
                  </p-dropdown>
                </div>
                <div class="dialog-supplier__content-item">
                  <input
                    class="form-controls"
                    name="zip"
                    type="text"
                    placeholder="Zip"
                    [(ngModel)]="item.zip"
                    maxlength="10"
                  />
                </div>
              </div>
              <div class="dialog-supplier__content-item">
                <input
                  class="form-controls"
                  [id]="'address_phone' + i"
                  name="phone"
                  type="tel"
                  placeholder="Phone"
                  [(ngModel)]="item.phone"
                  maxlength="20"
                />
              </div>
              <div class="dialog-supplier__content-item">
                <p-checkbox
                  label="Is default address"
                  [(ngModel)]="item.is_default"
                  name="is_default"
                  [binary]="true"
                ></p-checkbox>
              </div>
            </div>
          </div>

          <ng-template pTemplate="footer">
            <div class="dialog-supplier__content-action">
              <button class="dialog-supplier__content-action-button" type="submit" (click)="editAddress(item)">
                Save
              </button>
            </div>
          </ng-template>
        </p-dialog>
      </form>
    </div>
    <button class="add-button" type="button" (click)="addAddress()">
      {{ addressList.length ? '+ Add another address' : '+ Add address' }}
    </button>
  </div>
</div>

<p-confirmDialog
  #cd
  [position]="'top'"
  [style]="{ marginTop: '350px' }"
  rejectButtonStyleClass="confirm-reject-button"
  acceptButtonStyleClass="confirm-accept-button"
>
  <ng-template pTemplate="header">
    <div class="confirm-header">
      <fa-icon [icon]="faTriangle"></fa-icon>
      <h5>Confirmation</h5>
    </div>
    <button type="button" class="confirm-close" (click)="cd.reject()"><fa-icon [icon]="faXMark"></fa-icon></button>
  </ng-template>
</p-confirmDialog>

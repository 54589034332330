import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { faFile, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faShare, faShuffle, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardRoutes } from '../../enums/dashboard-routes.enum';

@Component({
  selector: 'get-tracking',
  templateUrl: './get-tracking.component.html',
  styleUrls: ['./get-tracking.component.sass']
})
export class GetTrackingComponent extends OnDestroyMixin() {
  @Input() openModal: any;

  @Output() openModalChange = new EventEmitter<any>();

  faAdd = faPlus;
  faTrack = faShuffle;
  faOrder = faShare;
  faFile = faFile;
  faEnvelope = faEnvelope;
  faWand = faWandMagicSparkles;
  toggle: boolean[] = [true, false, false];

  constructor(
    public router: Router) {
    super();
  }

  clickPO() {
    this.toggle = [false, true, false];
  }

  clickTrack() {
    this.toggle = [false, false, true];
  }

  clickAddJob(): void {
    this.emitClose();
    this.router.navigate([{ outlets: { addJob: DashboardRoutes.AddJob } }], { skipLocationChange: true });
  }

  emitClose() {
    this.openModal = false;
    this.openModalChange.emit(this.openModal);
  }
}

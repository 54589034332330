import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorInputMessageData, receiveInputMessageData, requestInputMessageData } from "src/app/common/utils/reducer/reducer.utils";
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { IComments } from '../../models/comments.model';
import { ICommentsState, CommentsState } from '../models/comments.model';


export function commentsReducer(
  lastState: ICommentsState = new CommentsState(),
  action: GenericAction<DashboardActionTypes, any>
): ICommentsState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_ADD_COMMENT:
      return requestInputMessageData<ICommentsState, IComments>(lastState, ['comments']);
    case DashboardActionTypes.RECEIVE_ADD_COMMENT:
      return receiveInputMessageData<ICommentsState, IComments>(lastState, ['comments'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ADD_COMMENT:
      return errorInputMessageData<ICommentsState, IComments>(lastState, ['comments'], action.payload);

    default:
      return lastState;
  }
}

import { faEye } from '@fortawesome/free-regular-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CustomErrorService } from 'src/app/dashboard/services/custom-error.service';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { ImageCacheService } from "../../../../common/services/image-cache/image-cache.service";
import { Component, Output, EventEmitter, Input } from '@angular/core';



@Component({
  selector: 'orders-doc-cell-renderer',
  templateUrl: './orders-doc-cell-renderer.component.html',
  styleUrls: ['orders-doc-cell-renderer.component.sass'],
})

export class OrdersDocCellRenderer implements ICellRendererAngularComp {
  public params!: any;
  readonly faEye = faEye;

  @Input() isApprovals: boolean = false;
  @Input() cardView: boolean = false;
  @Input() docData: any = undefined;
  @Output() onOrderAttChange = new EventEmitter<any>();

  documentModal: boolean = false;
  selectedAttachment: any;
  private imageProcessingCache = new Map();  // for memoization

  constructor(
    private dashboardActions: DashboardActions,
    private customErrorService: CustomErrorService,
    private imageCacheService: ImageCacheService,

  ) { }

  agInit(params: ICellRendererParams<any, number>): void {
    this.params = params;
  }

  ngOnInit() {
    if (this.isApprovals) {
      this.params = this.docData;
    }
  }

  public handleImagesPre(images: any) {
    const cacheKey = JSON.stringify(images);
    if (this.imageProcessingCache.has(cacheKey)) {
      return this.imageProcessingCache.get(cacheKey);
    }
    if (!images || !images.length) return images;
    let out_images: any = [];
    const processImageUrl = (url: string) => {
      if (!url) return '';
      const baseUrl = url.split('?')[0];
      return this.imageCacheService.hasImage(baseUrl) && !this.imageCacheService.getImage(baseUrl) ? '' : url;
    };
    images.forEach(
      (img: any) => {
        let new_img = {
          attachment: processImageUrl(img.attachment),
          attachment_preview: processImageUrl(img.attachment_preview),
          filename: img.filename,
          id: img.id,
        }
        out_images.push(new_img);
      }
    );
    this.imageProcessingCache.set(cacheKey, out_images);
    return out_images;
  }

  handleMissingImage(event: Event, url: string) {
    if (url) {
      url = url.split('?')[0];
      this.imageCacheService.setImage(url, '');
    }
    (event.target as HTMLImageElement).style.display = 'none';
  }

  refresh(params: ICellRendererParams) {
    return false;
  }

  //checks if the file is an image or not
  checkFileType(filename: any): boolean {
    if (!filename) return false;
    const prepareFile: any = filename.toLowerCase().split('.');
    const fileType = prepareFile?.findLast((el: any) => el.length > 1);
    const types = ['png', 'jpeg', 'jpg', 'jfif', 'pjpeg', 'pjp', 'svg', 'webp', 'bmp'];
    return types.includes(fileType);
  }

  openDocumentModal(event: any, document: any, filename: string) {
    event.stopPropagation();
    //the filetype check is needed for doc/docx types as they are not rendered as HTML pages by the AWS to be shown in the modal.
    const prepareFile: any = filename.toLowerCase().split('.');
    const fileType = prepareFile?.findLast((el: any) => el.length > 1);
    const docTypes = ['pdf'];
    if (document) {
      if (docTypes.includes(fileType)) {
        (window as any)['doc'] = this;
        this.documentModal = true;
        this.selectedAttachment = document;
      } else {
        window.open(document);
      }
    } else {
      this.customErrorService.setCustomErrorMessage('documentNotFound');
    }
  }

  deleteFile(event: any, key: string, id: string, attachment_id: string) {
    event.stopPropagation();
    let payload = { [`${key}`]: attachment_id, id };
    (window as any)['docs'] = this;
    if (this.params.fileDeleted) {
      this.params.fileDeleted({deleteAttachment: { key, id, attachment_id }});
    }
    if (this.cardView) {
      this.onOrderAttChange.emit({id: attachment_id, deleteAttachment: true});
    }
    this.dashboardActions.requestAttachmentDelete(payload);
  }

  closeDocumentModal() {
    this.documentModal = false;
  }
}

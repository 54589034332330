import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonConstants } from '../../constants/common.constants';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  token: string | any;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = localStorage.getItem(CommonConstants.AUTH_TOKEN);

    if (this.token) {
      const cloned: HttpRequest<any> = req.clone({
        headers: req.headers
          .set('Authorization', 'Bearer ' + this.token)
      });

      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}

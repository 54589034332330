import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { CustomErrorService } from 'src/app/dashboard/services/custom-error.service';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { selectOrderDocument } from 'src/app/dashboard/state/selectors/dashboard.selector';
import { OptionsService } from 'src/app/dashboard/services/options.service';

@Component({
  selector: 'orders-cell-type-renderer',
  templateUrl: './orders-cell-type-renderer.component.html',
  styleUrls: ['orders-cell-renderer.component.sass'],
})

export class OrdersCellTypeRenderer implements ICellRendererAngularComp {
  public params!: ICellRendererParams;
  readonly faEye = faEye;

  id!: string;
  selectedOrder: any = null;
  showOrderDialog: boolean = false;
  isPo: boolean = false;
  orderOptions: any;
  selectedRowId: any;

  constructor(
    private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private cdr: ChangeDetectorRef,
    private customErrorService: CustomErrorService,
    readonly optionsService: OptionsService,
  ) {


  }

  agInit(params: ICellRendererParams<any, number>): void {
    this.params = params;

  }






  refresh(params: ICellRendererParams) {
    return false;
  }









  closeOrderModal(event: any) {
    this.showOrderDialog = event;
  }


  protected readonly console = console;
}

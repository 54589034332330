<div class="filters"
    (click)="showSortModal($event)"
    (outsideClick)="outsideClickModal($event)">
  <span [ngClass]="lit && !isLoading ? 'filters-background' : 'filters-default'">
    <fa-icon
      [icon]="isLoading ? faSortDefault : faSort"
      [ngClass]="rotateIcon || isLoading ? 'filters__icon' : null"
    ></fa-icon>
    {{sortText}}
  </span>
  <div class="filters__container-sort" *ngIf="toggleSortModal" 
      (click)="emptyTemplateClick($event)"
  >
    <div class="filters__container-filter-header">Sort fields</div>
    <p-divider></p-divider>
    <div class="filters__container-sort-form">
      <ng-container *ngFor="let sorting of sortingArray; let i = index">
        <div class="filters__container-sort-form-content">
          <p-dropdown
            [filter]="true"
            [options]="fields"
            optionLabel="header"
            placeholder="Choose"
            [(ngModel)]="sorting.key"
            optionValue="field"
            (onChange)="emitUpdateSort($event)"
          >
          </p-dropdown>
          <p class="filters__container-sort-form-content-text">from</p>
          <div class="filters__container-sort-form-content-option">
            <p-selectButton
              styleClass="filters-select"
              [options]="sortOptions"
              [ngModel]="sortingArray ? sortingArray[i].direction : true"
              optionLabel="header"
              optionValue="field"
              (onChange)="emitUpdateSortDirection($event, i)"
            ></p-selectButton>
          </div>
          <button
          class="filters__container-sort-form-content-group-button"
          type="button"
          (click)="onDeleteSortItem(i)">
          <i class="bi bi-x"></i>
        </button>
        </div>

      </ng-container>

    <button
      type="button"
      class="filters__container-sort-form-add"
      [disabled]="!isSortEnabled"
      (click)="onAddSortItem()">
    <i class="bi bi-plus-lg"></i>Add sort
    </button>
  </div>

    <br />
  <button type="button" class="filters__container-filter-form-clear" (click)="clearAllSorts()">
    Clear sorts</button>
  </div>
</div>
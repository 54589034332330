import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { environment } from 'src/environments/environment';
import { IOrganization, IOrganizationAddresses } from '../models/company.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private baseUrl: string = environment.ENV.BASE_URL;

  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  public getOrganization(): Observable<IOrganization | HttpErrorResponse> {
    return this.httpClient.get<IOrganization>(`${this.baseUrl}organizations/`, this.httpHelper.options);
  }

  public getOrganizationAddresses(): Observable<IOrganizationAddresses | HttpErrorResponse> {
    return this.httpClient.get<IOrganizationAddresses>(`${this.baseUrl}organizations/addresses/`, this.httpHelper.options);
  }

  public updateOrganization(organization: any): Observable<IOrganization | HttpErrorResponse> {
    return this, this.httpClient.put<IOrganization>(`${this.baseUrl}organizations/`, organization.payload, this.httpHelper.options);
  }

  public updateOrganizationAddress(organizationAddress: any): Observable<IOrganizationAddresses | HttpErrorResponse> {
    return this, this.httpClient.put<IOrganizationAddresses>(`${this.baseUrl}organizations/addresses/${organizationAddress.payload.address_id}/`, organizationAddress.payload, this.httpHelper.options);
  }

  public addOrganizationAddress(organizationAddress: any): Observable<IOrganizationAddresses | HttpErrorResponse> {
    return this.httpClient.post<IOrganizationAddresses>(`${this.baseUrl}organizations/addresses/`, organizationAddress.payload, this.httpHelper.options);
  }

  public deleteOrganizationAddress(id: any): Observable<IOrganizationAddresses | HttpErrorResponse> {
    return this.httpClient.delete<IOrganizationAddresses>(`${this.baseUrl}organizations/addresses/${id.payload}/`, this.httpHelper.options);
  }

}

<div class="dialog-supplier">
  <form class="dialog-supplier__content">
    <div *ngIf="credPage" class="contact-header">
      <span>New supplier</span>
      <div class="supplier-contact-close">
        <button class="dialog-close" type="button" (click)="closeSupplier()">
          <i class="bi bi-x-lg"></i>
        </button>
      </div>
    </div>
    <div class="dialog-supplier__content-item">
      <input #nameElement
        class="form-controls"
        name="name"
        type="text"
        placeholder="Supplier"
        (keydown.enter)="showSuppliers = false"
        (focus)="showSuppliers = true"
        (blur)="blurSelectSupplier($event)"
        (input)="filterSuppliers()"
        [(ngModel)]="supplierForm.name"
        minlength="1"
      />
      <ul *ngIf="showSuppliers && dropdownSuppliers && dropdownSuppliers.length > 0"
            class="dropdown-menu show supplier-dropdown">
        <li *ngFor="let supplier of dropdownSuppliers"
            (click)="selectSupplier(supplier)"
            class="dropdown-item">
          {{ supplier.name }}
        </li>
      </ul>
      <p *ngIf="wrongName" class="warning-text">Add a supplier name</p>
    </div>
    <div class="dialog-supplier__content-item">
      <input
        class="form-controls"
        name="website"
        type="text"
        placeholder="Supplier website"
        [(ngModel)]="supplierForm.website"
      />
    </div>
    <div class="dialog-supplier__content-item">
      <input
        class="form-controls"
        name="email"
        type="email"
        placeholder="Supplier email"
        [(ngModel)]="supplierForm.email"
      />
      <div class="textfield__error-message" *ngIf="!isSupplierEmail">Wrong email format.</div>
    </div>
    <div class="dialog-supplier__content-item">
      <input
        class="form-controls"
        name="phone"
        type="text"
        placeholder="Supplier phone"
        [(ngModel)]="supplierForm.phone"
        maxlength="20"
      />
    </div>
    <div *ngIf="sidePanelAdd" class="dialog-supplier__content-item">
      <div class="col d-flex gap-4 mt-1">
        Add a supplier contact?
        <div class="d-flex gap-3">
          <p-radioButton [(ngModel)]="showContact" [value]="true" name="is_visible" label="Yes"> </p-radioButton>
          <p-radioButton [(ngModel)]="showContact" [value]="false" name="is_visible" label="No"> </p-radioButton>
        </div>
      </div>
    </div>
    <div *ngIf="sidePanelAdd && showContact">
      <materlog-add-supplier-contact-modal
      [resetContact]="addSupplierContactModal"
      [sidePanelAdd]="true"
      [contactRoles]="contactRoles"
      [supplierList]="suppliers"
      [supplierId]=""
      (supplierContactFormChange)="supplierContactFormChangeEvent($event)"
      ></materlog-add-supplier-contact-modal>
    </div>
    <div class="dialog-supplier__content-action">
      <p-button
        class="dialog-supplier__content-action-button"
        type="submit"
        [disabled]="isLoading"
        [loading]="isSupplierSubmitted"
        (click)="submitSupplier()"
      >
        Save
      </p-button>
    </div>
  </form>
</div>

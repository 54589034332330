import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { environment } from 'src/environments/environment';
import { IShareView, IShareViewEmail, IShareViewInvite } from '../models/dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class ShareViewsService {

  private baseUrl: string = environment.ENV.BASE_URL;
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  public getShareView(params?: any): Observable<IShareView | HttpErrorResponse> {
    return this.httpClient.get<IShareView>(`${this.baseUrl}shared-views/${params.payload}/`, this.httpHelper.options);
  }

  public updateShareView(params?: any): Observable<IShareViewInvite | HttpErrorResponse> {
    return this.httpClient.put<IShareViewInvite>(`${this.baseUrl}shared-views/${params.payload.id}/`, params.payload, this.httpHelper.options);
  }

  public deleteShareViewLink(params?: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<any>(`${this.baseUrl}shared-views/${params.payload}/`, this.httpHelper.options);
  }

  public updateShareViewEmails(params?: any): Observable<IShareViewEmail | HttpErrorResponse> {
    return this.httpClient.put<IShareViewEmail>(`${this.baseUrl}shared-views/emails/${params.payload.id}/`, params.payload, this.httpHelper.options);
  }

  public deleteShareViewEmails(params?: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<any>(`${this.baseUrl}shared-views/emails/${params.payload}/`, this.httpHelper.options);
  }

}

import { IStoreApiList } from 'src/app/common/models/store-api-list.model';
import { MemoizedSelector, DefaultProjectorFn, createSelector } from '@ngrx/store';
import { ApplicationState, IApplicationState } from 'src/app/common/state/models/app.state.model';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IReceiversPageState } from '../models/receivers-page.model';

const receiversState = (state: IApplicationState) => state.receivers;

export const selectReceiversPage: MemoizedSelector<
  ApplicationState,
  IStoreApiList<any>,
  DefaultProjectorFn<IStoreApiList<any>>
> = createSelector(receiversState, (state: IReceiversPageState | any) => state.receiversPage);

export const selectReceiverOptions: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IOptions>,
  DefaultProjectorFn<IStoreApiItem<IOptions>>
> = createSelector(receiversState, (state: IReceiversPageState | any) => state.userOptions);

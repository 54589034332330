import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IReceiversState } from 'src/app/dashboard/state/models/receivers.model';
import { RequestReceiverOptions, RequestReceiversPage } from './receivers-action-type-creators/receivers-page-action-types.creators';

@Injectable({ providedIn: 'root' })
export class ReceiversActions {
  constructor(private store: Store<IReceiversState>) { }

  public requestReceiversPage(params?: any): void {
    this.store.dispatch(new RequestReceiversPage(params));
  }

  public requestReceiverOptions(params?: any): void {
    this.store.dispatch(new RequestReceiverOptions(params));
  }
}

import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { TableModule } from 'primeng/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SharedModule } from 'src/app/common/modules/shared.module';
import { SuppliersPageComponent } from '../components/suppliers-page/suppliers-page.component';
import { SuppliersEffects } from '../state/effects/suppliers.effects';
import { SuppliersService } from '../services/suppliers.service';
import { suppliersPageReducer } from '../state/reducers/suppliers-page.reducer';

@NgModule({
  declarations: [
    SuppliersPageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('suppliers', suppliersPageReducer),
    EffectsModule.forFeature([SuppliersEffects]),
    TableModule,
    FontAwesomeModule,
  ],
  exports: [SuppliersPageComponent],
  providers: [SuppliersService]
})
export class SuppliersPageModule { }

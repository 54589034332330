import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';

import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/common/modules/shared.module';
import { TooltipModule } from 'primeng/tooltip';

import { SignInComponent } from '../components/sign-in/sign-in.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { SignUpComponent } from '../components/sign-up/sign-up.component';
import { authenticationReducer } from '../state/reducers/authentication.reducer';
import { AuthenticationEffects } from '../state/effects/authentication.effects';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ButtonModule } from 'primeng/button';
import { RecoverPasswordComponent } from '../components/recover-password/recover-password.component';

@NgModule({
  declarations: [SignUpComponent, SignInComponent, ForgotPasswordComponent, RecoverPasswordComponent],
  imports: [
    StoreModule.forFeature('authentication', authenticationReducer),
    EffectsModule.forFeature([AuthenticationEffects]),
    CommonModule,
    SharedModule,
    TableModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    SelectButtonModule,
    TooltipModule
  ],
  exports: [SignUpComponent, SignInComponent, ForgotPasswordComponent, RecoverPasswordComponent],
})
export class AuthenticationModule { }

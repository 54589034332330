import { JobTableV2Component } from "./job-table-v2.component";
import { ValueFormatterParams } from "ag-grid-community";
import { OrdersRowRenderer } from "./orders/orders-row-renderer.component";
import { OrdersCellRenderer } from "./orders/orders-cell-renderer.component";
import { OrdersDocCellRenderer } from "./orders-doc/orders-doc-cell-renderer.component";
import { ImagesCellRenderer } from "./images/images-cell-renderer.component";
import { CommonConstants } from "src/app/common/constants/common.constants";
import { DependencyRenderer } from "./dependency-renderer/dependency-renderer.component";
import { track } from "logrocket";


export function getTempColumns(this: any, tableinfoMap: any, widthmap: any, pinmap: any) {
  let tempcolumns: any[] =
    [
      {
        field: "index",
        order: tableinfoMap.get('index').order,
        headerName: tableinfoMap.get('index').header,
        editable: tableinfoMap.get('index').editable,
        width: tableinfoMap.get('index').width,
        pinned: "left",
        lockPinned: true,
        valueGetter: "node.rowIndex + 1",
        type: tableinfoMap.get('index').type,
        /*
        cellRenderer: (params: any) => {
          if (params.data.has_subitems) {
            if (params.data.has_subitems.has) {
              return `<span style="color: #69DED2; top: -5px; right: 5px; position: absolute; font-size: 20px">•</span> ${params.value}`;
            }
          }
          return params.value;
        },
        */
        cellStyle: (params: any) => {
          return { borderLeft: "4px solid" + params.data?.status_color };
        }
        // cellRenderer: GridRowNumberComponent
      },
      {
        field: "job_id",
        order: tableinfoMap.get('job_id').order,
        headerName: tableinfoMap.get('job_id').header,
        editable: tableinfoMap.get('job_id').editable,
        width: widthmap.has("job_id") ? widthmap.get("job_id") : tableinfoMap.get('job_id').width,
        pinned: pinmap.get("job_id"),
        type: tableinfoMap.get('job_id').type,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompareNonZero(valueA,valueB,isDescending,'job_id');
        },
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "supplier_id",
        order:  tableinfoMap.get('supplier_id').order,
        editable: tableinfoMap.get('supplier_id').editable,
        headerName: tableinfoMap.get('supplier_id').header,
        pinned: pinmap.get("supplier_id"),
        width: widthmap.has("supplier_id") ? widthmap.get("supplier_id") : tableinfoMap.get('supplier_id').width,
        type: tableinfoMap.get('supplier_id').type,
        enableRowGroup: true,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompare(valueA,valueB,isDescending,'supplier_id');
        },
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: 'name',
        order: tableinfoMap.get('name').order,
        headerName: tableinfoMap.get('name').header,
        pinned: pinmap.get("name"),
        width: widthmap.has("name") ? widthmap.get("name") : tableinfoMap.get('name').width,
        enableRowGroup: true,
        type: tableinfoMap.get('name').type,
        editable: tableinfoMap.get('name').editable,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
        cellRenderer: (params: any) => {
          if (params.data.has_subitems) {
            if (params.data.has_subitems.has) {
              return `<span style="color: #69DED2; top: -7px; left: 0px; position: absolute; font-size: 20px">•</span> ${params.value}`;
            }
          }
          return params.value;
        },
      },
      {
        colId: "po_document_number",
        field: "po_document_number",
        headerName: tableinfoMap.get('po_document_number').header,
        order: tableinfoMap.get('po_document_number').order,
        type: tableinfoMap.get('po_document_number').type,
        filterValueGetter: (params: ValueFormatterParams) => {
          if(params && params.data && params.data.po_document_number){
            return params.data.po_document_number.id;
          }
        },
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
        cellRendererSelector: (params: any) => {
          if (params.colDef?.colId?.startsWith("ag-Grid-Auto")) {
            return {
              component: OrdersRowRenderer,
              params: {} // optional parameters
            };
          } else {
            return {
              component: OrdersCellRenderer,
              params: {
                orderUpdated: this.onAttachmentChange.bind(this),
              } // optional parameters
            };
          }
        },
        pinned: pinmap.get("po_document_number"),
        keyCreator: (params: any) => {
          if(params.data.po_document_number && params.data.po_document_number.number ){
            return params.data.po_document_number.number.toString().toLowerCase();
          }
        },
        width: widthmap.has("po_document_number") ? widthmap.get("po_document_number") : tableinfoMap.get('po_document_number').width,
        editable: tableinfoMap.get('po_document_number').editable,
        enableRowGroup: true,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          const nullPosition = isDescending ? -100000 : 100000
          // if a is null, push it towards whichever end null elements should end up
          if (valueA === null||valueA==undefined) {return nullPosition;}
          if (valueB === null||valueB==undefined) {return -nullPosition}
          return doTextColumnCompareNullLast(valueA.number, valueB.number, isDescending);
        },
      },
      {
        field: "po_document_date",
        headerName: tableinfoMap.get('po_document_date').header,
        order: tableinfoMap.get('po_document_date').order,
        pinned: pinmap.get("po_document_date"),
        width: widthmap.has("po_document_date") ? widthmap.get("po_document_date") : tableinfoMap.get('po_document_date').width,
        type: tableinfoMap.get('po_document_date').type,
        editable: tableinfoMap.get('po_document_date').editable,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "po_document_attachments",
        headerName: tableinfoMap.get('po_document_attachments').header,
        order: tableinfoMap.get('po_document_attachments').order,
        filter: true,
        filterParams: {
          filterOptions: ["blank", "notBlank"],
        },
        filterValueGetter: (params: ValueFormatterParams) => {
          if(params && params.data && params.data.po_document_attachments && params.data.po_document_attachments.length > 0){
            return params.data.po_document_attachments;
          }
          return null;
        },
        type: tableinfoMap.get('po_document_attachments').type,
        pinned: pinmap.get("po_document_attachments"),
        width: widthmap.has("po_document_attachments") ? widthmap.get("po_document_attachments") : tableinfoMap.get('po_document_attachments').width,
        cellRenderer: OrdersDocCellRenderer,
        cellRendererParams: {
          fileDeleted: this.onAttachmentChange.bind(this),
        },
        editable: tableinfoMap.get('po_document_attachments').editable,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        colId: "supplier_documents_numbers",
        field: "supplier_documents_numbers",
        type: tableinfoMap.get('supplier_documents_numbers').type,
        order: tableinfoMap.get('supplier_documents_numbers').order,
        headerName: tableinfoMap.get('supplier_documents_numbers').header,
        pinned: pinmap.get("supplier_documents_numbers"),
        editable: tableinfoMap.get('supplier_documents_numbers').editable,
        width: widthmap.has("supplier_documents_numbers") ? widthmap.get("supplier_documents_numbers") : tableinfoMap.get('supplier_documents_numbers').width,
        enableRowGroup: true,
        filter: true,
        filterValueGetter: (params: ValueFormatterParams) => {
          if (params && params.data && params.data.supplier_documents_numbers) {
            return  params.data.supplier_documents_numbers.map((item:any)=>{
              return item.number;
            }).join(', ');
          }
          return null;
        },
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          const nullPosition = isDescending ? -100000 : 100000
          if (valueA === null||valueA==undefined) {return nullPosition;}
          if (valueB === null||valueB==undefined) {return -nullPosition}
          valueA = valueA.map((item: any) => item.number).join(', ');
          valueB = valueB.map((item: any) => item.number).join(', ');
          return doTextColumnCompareNullLast(valueA, valueB, isDescending);
        },
        cellRendererSelector: (params: any) => {
          if (params.colDef?.colId?.startsWith("ag-Grid-Auto")) {
            return {
              component: OrdersRowRenderer,
              params: {} // optional parameters
            };
          } else {
            return {
              component: OrdersCellRenderer,
              params: {
                orderUpdated: this.onAttachmentChange.bind(this),
              } // optional parameters
            };
          }
        },
        keyCreator: (params: any) => {

          if(params.data.supplier_documents_numbers ){
            if(params.data.supplier_documents_numbers.length > 0){
              return params.data.supplier_documents_numbers[0].number;
            }
            return params.data.supplier_documents_numbers.number;
          }

        },
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        colId: "supplier_documents_types",
        field: "supplier_documents_types",
        type: tableinfoMap.get('supplier_documents_types').type,
        order: tableinfoMap.get('supplier_documents_types').order,
        headerName: tableinfoMap.get('supplier_documents_types').header,
        pinned: pinmap.get("supplier_documents_types"),
        editable: tableinfoMap.get('supplier_documents_types').editable,
        width: widthmap.has("supplier_documents_types") ? widthmap.get("supplier_documents_types") : tableinfoMap.get('supplier_documents_types').width,
        enableRowGroup: true,
        filter: true,
        cellDataType: 'text',
        cellRenderer: (params:any)=>{
          if(params.value){
            let outputstring =  "<div class=\"space-x-2\">";
            params.value.forEach( (item:any)=>{
              if(item != 1) {
                outputstring += "<span class=\"rounded-md text-xs bg-gray-100 border-2 text-black p-1\">" + this.fastLookupOptions("document_types", item) + "</span>"
              }
            });
            return outputstring + "</div>"

          }
          return "";
        },
        filterValueGetter: (params: ValueFormatterParams) => {
          let results:any[] = [];
          if (params && params.data && params.data.po_document_number && params.data.po_document_number.document_type) {
            let type = params.data.po_document_number.document_type;
            if (!results.includes(type)) {
              results.push(type);
            }
          }
          if (params && params.data && params.data.supplier_documents_numbers) {
            for (let i = 0; i < params.data.supplier_documents_numbers.length; i++) {
              let type = params.data.supplier_documents_numbers[i].document_type;
              if (!results.includes(type)) {
                results.push(type);
              }
            }
          }
          return results;
        },
        valueFormatter: (params:any)=>{
            if(params.value){
              return params.value.map((item:any)=>{
                return this.fastLookupOptions("document_types",item);
              });

            }
            return null;
          },
        valueGetter: (params:any)=> {
          let results:any[] = [];
          if (params && params.data && params.data.po_document_number && params.data.po_document_number.document_type) {
              let type = params.data.po_document_number.document_type;
              if (!results.includes(type)) {
                results.push(type);
              }
          }
          if (params && params.data && params.data.supplier_documents_numbers) {
            for (let i = 0; i < params.data.supplier_documents_numbers.length; i++) {
              let type = params.data.supplier_documents_numbers[i].document_type;
              if (!results.includes(type)) {
                results.push(type);
              }
            }
          }
          return results;
        },
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
        //cellRenderer: OrdersCellTypeRenderer,
      },
      {
        field: "supplier_documents_earliest_document_date",
        headerName: tableinfoMap.get('supplier_documents_earliest_document_date').header,
        order: tableinfoMap.get('supplier_documents_earliest_document_date').order,
        pinned: pinmap.get("supplier_documents_earliest_document_date"),
        width: widthmap.has("supplier_documents_earliest_document_date") ? widthmap.get("supplier_documents_earliest_document_date") : tableinfoMap.get('supplier_documents_earliest_document_date').width,
        editable: tableinfoMap.get('supplier_documents_earliest_document_date').editable,
        type: tableinfoMap.get('supplier_documents_earliest_document_date').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "supplier_documents_attachments",
        headerName: tableinfoMap.get('supplier_documents_attachments').header,
        order: tableinfoMap.get('supplier_documents_attachments').order,
        filter: true,
        filterParams: {
          filterOptions: ["blank", "notBlank"],
        },
        filterValueGetter: (params: ValueFormatterParams) => {
          if(params && params.data && params.data.supplier_documents_attachments && params.data.supplier_documents_attachments.length > 0){
            return params.data.supplier_documents_attachments;
          }
          return null;
        },
        pinned: pinmap.get("supplier_documents_attachments"),
        width: widthmap.has("supplier_documents_attachments") ? widthmap.get("supplier_documents_attachments") : tableinfoMap.get('supplier_documents_attachments').width,
        cellRenderer: OrdersDocCellRenderer,
        cellRendererParams: {
          fileDeleted: this.onAttachmentChange.bind(this),
        },
        editable: tableinfoMap.get('supplier_documents_attachments').editable,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompareNullLastNonZero(valueA,valueB,isDescending);
        },
        valueFormatter: (params: any) => {return '';},
        valueParser: (params: any) => {return '';},
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "status",
        order: tableinfoMap.get('status').order,
        headerName: tableinfoMap.get('status').header,
        pinned: pinmap.get("status"),
        editable: tableinfoMap.get('status').editable,
        width: widthmap.has("status") ? widthmap.get("status") : tableinfoMap.get('status').width,
        type: tableinfoMap.get('status').type,
        enableRowGroup: true,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          //need to map status to status_ordering
          let a = this.dropdownOptions.status_ordering[valueA];
          let a_value = this.fastLookupOptions('status', valueA);
          let b = this.dropdownOptions.status_ordering[valueB];
          let b_value = this.fastLookupOptions('status', valueB);
          return this.doColumnCompareNullLast(a,b,isDescending);
        },
        headerComponentParams: {   template: createCustomHeaderTemplate(true)}
      },
      {
        field: "images",
        order: tableinfoMap.get('images').order,
        type: tableinfoMap.get('images').type,
        headerName: tableinfoMap.get('images').header,
        pinned: pinmap.get("images"),
        width: widthmap.has("images") ? widthmap.get("images") : tableinfoMap.get('images').width,
        cellRenderer: ImagesCellRenderer,
        editable: tableinfoMap.get('images').editable,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          let a = valueA;
          if(valueA && valueA.image_name){
            a = valueA.image_name;
          }else  if(valueA && valueA[0] && valueA[0].image_name){
            a = valueA[0].image_name;
          }
          let b = valueB;
          if(valueB && valueB.image_name){
            b = valueB.image_name;
          }else if(valueB && valueB[0] && valueB[0].image_name){
            b = valueB[0].image_name;
          }
          return this.doColumnCompareNullLastNonZero(a,b,isDescending);
        },
        filterParams: {
          filterOptions: ["blank", "notBlank"],
        },
        filterValueGetter: (params: ValueFormatterParams) => {
          if(params && params.data && params.data.images && params.data.images.image_name){
            return  params.data.images.image_name;
          }
          if(params && params.data && params.data.images && params.data.images[0] &&  params.data.images[0].image_name){
            return  params.data.images[0].image_name;
          }


          return null;
        },
      },
      {
        field: "dependencies",
        order: tableinfoMap.get('dependencies').order,
        type: tableinfoMap.get('dependencies').type,
        headerName: tableinfoMap.get('dependencies').header,
        pinned: pinmap.get("dependencies"),
        width: widthmap.has("dependencies") ? widthmap.get("dependencies") : tableinfoMap.get('dependencies').width,
        editable: tableinfoMap.get('dependencies').editable,
        cellRenderer: DependencyRenderer,
      },
      {
        field: "sku",
        order: tableinfoMap.get('sku').order,
        type: tableinfoMap.get('sku').type,
        headerName: tableinfoMap.get('sku').header,
        pinned: pinmap.get("sku"),
        width: widthmap.has("sku") ? widthmap.get("sku") : tableinfoMap.get('sku').width,
        editable: tableinfoMap.get('sku').editable,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "spec",
        order: tableinfoMap.get('spec').order,
        type: tableinfoMap.get('spec').type,
        headerName: tableinfoMap.get('spec').header,
        pinned: pinmap.get("spec"),
        width: widthmap.has("spec") ? widthmap.get("spec") : tableinfoMap.get('spec').width,
        editable: tableinfoMap.get('spec').editable,
      },
      {
        field: "quantity",
        order: tableinfoMap.get('quantity').order,
        headerName: tableinfoMap.get('quantity').header,
        pinned: pinmap.get("quantity"),
        width: widthmap.has("quantity") ? widthmap.get("quantity") : tableinfoMap.get('quantity').width,
        editable: tableinfoMap.get('quantity').editable,
        type: tableinfoMap.get('quantity').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "unit_of_measure",
        order: tableinfoMap.get('unit_of_measure').order,
        headerName: tableinfoMap.get('unit_of_measure').header,
        pinned: pinmap.get("unit_of_measure"),
        width: widthmap.has("unit_of_measure") ? widthmap.get("unit_of_measure") : tableinfoMap.get('unit_of_measure').width,
        type:  tableinfoMap.get('unit_of_measure').type,
        editable: tableinfoMap.get('unit_of_measure').editable,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompareNullLastNonZero(valueA,valueB,isDescending);
        },
        enableRowGroup: true,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "description",
        order: tableinfoMap.get('description').order,
        headerName: tableinfoMap.get('description').header,
        type: tableinfoMap.get('description').type,
        pinned: pinmap.get("description"),
        width: widthmap.has("description") ? widthmap.get("description") : tableinfoMap.get('description').width,
        editable: tableinfoMap.get('description').editable,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "link_to_material",
        order: tableinfoMap.get('link_to_material').order,
        headerName: tableinfoMap.get('link_to_material').header,
        pinned: pinmap.get("link_to_material"),
        width: widthmap.has("link_to_material") ? widthmap.get("link_to_material") : tableinfoMap.get('link_to_material').width,
        editable: tableinfoMap.get('link_to_material').editable,
        type: tableinfoMap.get('link_to_material').type,
      },

      {
        field: "size_item",
        order: tableinfoMap.get('size_item').order,
        headerName: tableinfoMap.get('size_item').header,
        pinned: pinmap.get("size_item"),
        width: widthmap.has("size_item") ? widthmap.get("size_item") : tableinfoMap.get('size_item').width,
        editable: tableinfoMap.get('size_item').editable,
        enableRowGroup: true,
        type: tableinfoMap.get('size_item').type,
      },
      {
        field: "finish",
        order: tableinfoMap.get('finish').order,
        headerName: tableinfoMap.get('finish').header,
        pinned: pinmap.get("finish"),
        width: widthmap.has("finish") ? widthmap.get("finish") : tableinfoMap.get('finish').width,
        editable: tableinfoMap.get('finish').editable,
        enableRowGroup: true,
        type: tableinfoMap.get('finish').type,

      },
      {
        field: "category",
        order: tableinfoMap.get('category').order,
        headerName: tableinfoMap.get('category').header,
        width: widthmap.has("category") ? widthmap.get("category") : tableinfoMap.get('category').width,
        pinned: pinmap.get("category"),
        editable: tableinfoMap.get('category').editable,
        type: tableinfoMap.get('category').type,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompareNonZero(valueA,valueB,isDescending,'category');
        },
        enableRowGroup: true,
      },

      {
        field: "room_id",
        order: tableinfoMap.get('room_id').order,
        headerName: tableinfoMap.get('room_id').header,
        editable: tableinfoMap.get('room_id').editable,
        pinned: pinmap.get("room_id"),
        width: widthmap.has("room_id") ? widthmap.get("room_id") : tableinfoMap.get('room_id').width,
        type:  tableinfoMap.get('room_id').type,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          let a_value = this.fastLookupOptions('room_id', valueA);
          let b_value = this.fastLookupOptions('room_id', valueB)
          return doTextColumnCompareNullLast(a_value, b_value, isDescending);
        },
        enableRowGroup: true
      },
      /*
      {
        field: "floor",
        order: tableinfoMap.get('floor').order,
        headerName: tableinfoMap.get('floor').header,
        editable: tableinfoMap.get('floor').editable,
        pinned: pinmap.get("floor"),
        width: widthmap.has("floor") ? widthmap.get("floor") : tableinfoMap.get('floor').width,
        type:  tableinfoMap.get('floor').type,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompareNonZero(valueA,valueB,isDescending,'floor');
        },
        enableRowGroup: true
      },
      */
      {
        field: "delivery_method",
        order: tableinfoMap.get('delivery_method').order,
        headerName: tableinfoMap.get('delivery_method').header,
        editable: tableinfoMap.get('delivery_method').editable,
        pinned: pinmap.get("delivery_method"),
        width: widthmap.has("delivery_method") ? widthmap.get("delivery_method") : tableinfoMap.get('delivery_method').width,
        type:  tableinfoMap.get('delivery_method').type,
        enableRowGroup: true,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          let a = valueA;
          let b = valueB;
          if(a != null && a!=undefined){
            a = this.fastLookupOptions('delivery_method_ordering',a);
          }
          if(b != null && b!=undefined){
            b = this.fastLookupOptions('delivery_method_ordering', b);
          }
          //0 value is not selected so push down
          return this.doColumnCompareNullLastNonZero(a,b,isDescending);
        },
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "tracking_info",
        order: tableinfoMap.get('tracking_info').order,
        headerName: tableinfoMap.get('tracking_info').header,
        editable: tableinfoMap.get('tracking_info').editable,
        pinned: pinmap.get("tracking_info"),
        width: widthmap.has("tracking_info") ? widthmap.get("tracking_info") : tableinfoMap.get('tracking_info').width,
        type:  tableinfoMap.get('tracking_info').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "shipment_tracking",
        order: tableinfoMap.get('shipment_tracking').order,
        headerName: tableinfoMap.get('shipment_tracking').header,
        editable: tableinfoMap.get('shipment_tracking').editable,
        pinned: pinmap.get("shipment_tracking"),
        width: widthmap.has("shipment_tracking") ? widthmap.get("shipment_tracking") : tableinfoMap.get('shipment_tracking').width,
        type:  tableinfoMap.get('shipment_tracking').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "lead_time",
        order: tableinfoMap.get('lead_time').order,
        headerName: tableinfoMap.get('lead_time').header,
        editable: tableinfoMap.get('lead_time').editable,
        pinned: pinmap.get("lead_time"),
        width: widthmap.has("lead_time") ? widthmap.get("lead_time") : tableinfoMap.get('lead_time').width,
        type:  tableinfoMap.get('lead_time').type,
      },
      {
        field: "lead_time_start",
        order: tableinfoMap.get('lead_time_start').order,
        headerName: tableinfoMap.get('lead_time_start').header,
        editable: tableinfoMap.get('lead_time_start').editable,
        pinned: pinmap.get("lead_time_start"),
        width: widthmap.has("lead_time_start") ? widthmap.get("lead_time_start") : tableinfoMap.get('lead_time_start').width,
        type:  tableinfoMap.get('lead_time_start').type,
      },

      {
        field: "in_stock_date",
        order: tableinfoMap.get('in_stock_date').order,
        headerName: tableinfoMap.get('in_stock_date').header,
        editable: tableinfoMap.get('in_stock_date').editable,
        pinned: pinmap.get("in_stock_date"),
        width: widthmap.has("in_stock_date") ? widthmap.get("in_stock_date") : tableinfoMap.get('in_stock_date').width,
        type:  tableinfoMap.get('in_stock_date').type,
        enableRowGroup: true,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "ship_date",
        order: tableinfoMap.get('ship_date').order,
        headerName: tableinfoMap.get('ship_date').header,
        editable: tableinfoMap.get('ship_date').editable,
        pinned: pinmap.get("ship_date"),
        width: widthmap.has("ship_date") ? widthmap.get("ship_date") : tableinfoMap.get('ship_date').width,
        type:  tableinfoMap.get('ship_date').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "delivery_date",
        order: tableinfoMap.get('delivery_date').order,
        headerName: tableinfoMap.get('delivery_date').header,
        editable: tableinfoMap.get('delivery_date').editable,
        pinned: pinmap.get("delivery_date"),
        width: widthmap.has("delivery_date") ? widthmap.get("delivery_date") : tableinfoMap.get('delivery_date').width,
        type:  tableinfoMap.get('delivery_date').type,
        enableRowGroup: true,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "requested_delivery_date",
        order: tableinfoMap.get('requested_delivery_date').order,
        headerName: tableinfoMap.get('requested_delivery_date').header,
        editable: tableinfoMap.get('requested_delivery_date').editable,
        type:  tableinfoMap.get('requested_delivery_date').type,
        pinned: pinmap.get("requested_delivery_date"),
        width: widthmap.has("requested_delivery_date") ? widthmap.get("requested_delivery_date") : tableinfoMap.get('requested_delivery_date').width,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "in_stock_ship_tags",
        order: tableinfoMap.get('in_stock_ship_tags').order,
        headerName: tableinfoMap.get('in_stock_ship_tags').header,
        editable: tableinfoMap.get('in_stock_ship_tags').editable,
        pinned: pinmap.get("in_stock_ship_tags"),
        width: widthmap.has("in_stock_ship_tags") ? widthmap.get("in_stock_ship_tags") : tableinfoMap.get('in_stock_ship_tags').width,
        type:  tableinfoMap.get('in_stock_ship_tags').type,
      },
      {
        field: "item_com_selection",
        order: tableinfoMap.get('item_com_selection').order,
        headerName: tableinfoMap.get('item_com_selection').header,
        editable: tableinfoMap.get('item_com_selection').editable,
        pinned: pinmap.get("item_com_selection"),
        width: widthmap.has("item_com_selection") ? widthmap.get("item_com_selection") : tableinfoMap.get('item_com_selection').width,
        type:  tableinfoMap.get('item_com_selection').type,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompareNullLastNonZero(valueA,valueB,isDescending);
        },
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },


    /*  {
        field: "order_is_a_com",
        order: 34,
        headerName: tableinfoMap.get('order_is_a_com').header,
        editable: tableinfoMap.get('order_is_a_com').editable,
        pinned: pinmap.get("order_is_a_com"),
        width: widthmap.has("order_is_a_com") ? widthmap.get("order_is_a_com") : tableinfoMap.get('order_is_a_com').width,
        type:  tableinfoMap.get('order_is_a_com').type,

      },
      {
        field: "waiting_for_com",
        order: 35,
        cellDataType: 'text',
        headerName: tableinfoMap.get('waiting_for_com').header,
        editable: tableinfoMap.get('waiting_for_com').editable,
        pinned: pinmap.get("waiting_for_com"),
        width: widthmap.has("waiting_for_com") ? widthmap.get("waiting_for_com") : tableinfoMap.get('waiting_for_com').width,
        type:  tableinfoMap.get('waiting_for_com').type,
      },*/
      {
        field: "notes",
        order: tableinfoMap.get('notes').order,
        headerName: tableinfoMap.get('notes').header,
        editable: tableinfoMap.get('notes').editable,
        pinned: pinmap.get("notes"),
        width: widthmap.has("notes") ? widthmap.get("notes") : tableinfoMap.get('notes').width,
        type:  tableinfoMap.get('notes').type,


      },
      {
        field: "notes_external",
        order: tableinfoMap.get('notes_external').order,
        headerName: tableinfoMap.get('notes_external').header,
        editable: tableinfoMap.get('notes_external').editable,
        pinned: pinmap.get("notes_external"),
        width: widthmap.has("notes_external") ? widthmap.get("notes_external") : tableinfoMap.get('notes_external').width,
        type:  tableinfoMap.get('notes_external').type,

      },
      {
        field: "shipping_to",
        order: tableinfoMap.get('shipping_to').order,
        headerName: tableinfoMap.get('shipping_to').header,
        editable: tableinfoMap.get('shipping_to').editable,
        pinned: pinmap.get("shipping_to"),
        width: widthmap.has("shipping_to") ? widthmap.get("shipping_to") : tableinfoMap.get('shipping_to').width,
        type:  tableinfoMap.get('shipping_to').type,
        enableRowGroup: true,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompareNonZero(valueA,valueB,isDescending,'shipping_to');
        },
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "shipping_name",
        order: tableinfoMap.get('shipping_name').order,
        headerName: tableinfoMap.get('shipping_name').header,
        editable: tableinfoMap.get('shipping_name').editable,
        pinned: pinmap.get("shipping_name"),
        width: widthmap.has("shipping_name") ? widthmap.get("shipping_name") : tableinfoMap.get('shipping_name').width,
        enableRowGroup: true,
        type:  tableinfoMap.get('shipping_name').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "shipping_address",
        order: tableinfoMap.get('shipping_address').order,
        headerName: tableinfoMap.get('shipping_address').header,
        editable: tableinfoMap.get('shipping_address').editable,
        pinned: pinmap.get("shipping_address"),
        width: widthmap.has("shipping_address") ? widthmap.get("shipping_address") : tableinfoMap.get('shipping_address').width,
        type:  tableinfoMap.get('shipping_address').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "shipping_city",
        order: tableinfoMap.get('shipping_city').order,
        headerName: tableinfoMap.get('shipping_city').header,
        editable: tableinfoMap.get('shipping_city').editable,
        pinned: pinmap.get("shipping_city"),
        width: widthmap.has("shipping_city") ? widthmap.get("shipping_city") : tableinfoMap.get('shipping_city').width,
        type:  tableinfoMap.get('shipping_city').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "shipping_state",
        order: tableinfoMap.get('shipping_state').order,
        headerName: tableinfoMap.get('shipping_state').header,
        editable: tableinfoMap.get('shipping_state').editable,
        pinned: pinmap.get("shipping_state"),
        width: widthmap.has("shipping_state") ? widthmap.get("shipping_state") : tableinfoMap.get('shipping_state').width,
        type:  tableinfoMap.get('shipping_state').type,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompareNullLastNonZero(valueA,valueB,isDescending);
        },
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "shipping_zip",
        order: tableinfoMap.get('shipping_zip').order,
        headerName: tableinfoMap.get('shipping_zip').header,
        editable: tableinfoMap.get('shipping_zip').editable,
        type:  tableinfoMap.get('shipping_zip').type,
        pinned: pinmap.get("shipping_zip"),
        width: widthmap.has("shipping_zip") ? widthmap.get("shipping_zip") : tableinfoMap.get('shipping_zip').width,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      /*
      {
        field: "shipping_country",
        order: tableinfoMap.get('shipping_country').order,
        headerName: tableinfoMap.get('shipping_country').header,
        editable: tableinfoMap.get('shipping_country').editable,
        pinned: pinmap.get("shipping_country"),
        width: widthmap.has("shipping_country") ? widthmap.get("shipping_country") : tableinfoMap.get('shipping_country').width,
        type:  tableinfoMap.get('shipping_country').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      */
      {
        field: "fob_point",
        order: tableinfoMap.get('fob_point').order,
        headerName: tableinfoMap.get('fob_point').header,
        editable: tableinfoMap.get('fob_point').editable,
        pinned: pinmap.get("fob_point"),
        width: widthmap.has("fob_point") ? widthmap.get("fob_point") : tableinfoMap.get('fob_point').width,
        type:  tableinfoMap.get('fob_point').type,
      },

      {
        field: "total_cost",
        order: tableinfoMap.get('total_cost').order,
        headerName: tableinfoMap.get('total_cost').header,
        editable: tableinfoMap.get('total_cost').editable,
        pinned: pinmap.get("total_cost"),
        width: widthmap.has("total_cost") ? widthmap.get("total_cost") : tableinfoMap.get('total_cost').width,
        type: tableinfoMap.get('total_cost').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },

      {
        field: "cost_per_unit",
        order: tableinfoMap.get('cost_per_unit').order,
        headerName: tableinfoMap.get('cost_per_unit').header,
        editable: tableinfoMap.get('cost_per_unit').editable,
        pinned: pinmap.get("cost_per_unit"),
        type: tableinfoMap.get('cost_per_unit').type,
        width: widthmap.has("cost_per_unit") ? widthmap.get("cost_per_unit") : tableinfoMap.get('cost_per_unit').width,
        enableRowGroup: true,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "discount",
        order: tableinfoMap.get('discount').order,
        headerName: tableinfoMap.get('discount').header,
        editable: tableinfoMap.get('discount').editable,
        pinned: pinmap.get("discount"),
        width: widthmap.has("discount") ? widthmap.get("discount") : tableinfoMap.get('discount').width,
        enableRowGroup: true,
        type: tableinfoMap.get('discount').type,
        valueFormatter: (params: any) =>{

          if(params && params.value != undefined && params.value != null) {
            return '$' + this.discountFormatNumber(params.value);
          }
          return params.value;
        },
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "shipping_cost",
        order: tableinfoMap.get('shipping_cost').order,
        headerName: tableinfoMap.get('shipping_cost').header,
        editable: tableinfoMap.get('shipping_cost').editable,
        pinned: pinmap.get("shipping_cost"),
        width: widthmap.has("shipping_cost") ? widthmap.get("shipping_cost") : tableinfoMap.get('shipping_cost').width,
        enableRowGroup: true,
        type: tableinfoMap.get('shipping_cost').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "sales_tax",
        order: tableinfoMap.get('sales_tax').order,
        headerName: tableinfoMap.get('sales_tax').header,
        editable: tableinfoMap.get('sales_tax').editable,
        pinned: pinmap.get("sales_tax"),
        width: widthmap.has("sales_tax") ? widthmap.get("sales_tax") : tableinfoMap.get('sales_tax').width,
        enableRowGroup: true,
        type: tableinfoMap.get('sales_tax').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "payment_sent",
        order: tableinfoMap.get('payment_sent').order,
        headerName: tableinfoMap.get('payment_sent').header,
        editable: tableinfoMap.get('payment_sent').editable,
        type:  tableinfoMap.get('payment_sent').type,
        pinned: pinmap.get("payment_sent"),
        width: widthmap.has("payment_sent") ? widthmap.get("payment_sent") : tableinfoMap.get('payment_sent').width,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "payment_received",
        order: tableinfoMap.get('payment_received').order,
        headerName: tableinfoMap.get('payment_received').header,
        editable: tableinfoMap.get('payment_received').editable,
        type:  tableinfoMap.get('payment_received').type,
        pinned: pinmap.get("payment_received"),
        width: widthmap.has("payment_received") ? widthmap.get("payment_received") : tableinfoMap.get('payment_received').width,
      },
      {
        field: "payment_method",
        order: tableinfoMap.get('payment_method').order,
        headerName: tableinfoMap.get('payment_method').header,
        editable: tableinfoMap.get('payment_method').editable,
        pinned: pinmap.get("payment_method"),
        width: widthmap.has("payment_method") ? widthmap.get("payment_method") : tableinfoMap.get('payment_method').width,
        type:  tableinfoMap.get('payment_method').type,
        enableRowGroup: true,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompareNullLastNonZero(valueA,valueB,isDescending);
        },
      },
      {
        field: "last_4_card_digits",
        order: tableinfoMap.get('last_4_card_digits').order,
        headerName: tableinfoMap.get('last_4_card_digits').header,
        editable: tableinfoMap.get('last_4_card_digits').editable,
        pinned: pinmap.get("last_4_card_digits"),
        width: widthmap.has("last_4_card_digits") ? widthmap.get("last_4_card_digits") : tableinfoMap.get('last_4_card_digits').width,
        type:  tableinfoMap.get('last_4_card_digits').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "is_reimbursable",
        order: tableinfoMap.get('is_reimbursable').order,
        headerName: tableinfoMap.get('is_reimbursable').header,
        editable: tableinfoMap.get('is_reimbursable').editable,
        pinned: pinmap.get("is_reimbursable"),
        width: widthmap.has("is_reimbursable") ? widthmap.get("is_reimbursable") : tableinfoMap.get('is_reimbursable').width,
        type:  tableinfoMap.get('is_reimbursable').type,
      },
      {
        field: "supplier_phone",
        order: tableinfoMap.get('supplier_phone').order,
        headerName: tableinfoMap.get('supplier_phone').header,
        editable: tableinfoMap.get('supplier_phone').editable,
        pinned: pinmap.get("supplier_phone"),
        width: widthmap.has("supplier_phone") ? widthmap.get("supplier_phone") : tableinfoMap.get('supplier_phone').width,
        type:  tableinfoMap.get('supplier_phone').type,
      },
      {
        field: "supplier_email",
        order: tableinfoMap.get('supplier_email').order,
        headerName: tableinfoMap.get('supplier_email').header,
        editable: tableinfoMap.get('supplier_email').editable,
        pinned: pinmap.get("supplier_email"),
        width: widthmap.has("supplier_email") ? widthmap.get("supplier_email") : tableinfoMap.get('supplier_email').width,
        type:  tableinfoMap.get('supplier_email').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "supplier_website",
        order: tableinfoMap.get('supplier_website').order,
        headerName: tableinfoMap.get('supplier_website').header,
        editable: tableinfoMap.get('supplier_website').editable,
        pinned: pinmap.get("supplier_website"),
        width: widthmap.has("supplier_website") ? widthmap.get("supplier_website") : tableinfoMap.get('supplier_website').width,
        type:  tableinfoMap.get('supplier_website').type,
        enableRowGroup: true,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "supplier_contact_id",
        order: tableinfoMap.get('supplier_contact_id').order,
        headerName: tableinfoMap.get('supplier_contact_id').header,
        editable: tableinfoMap.get('supplier_contact_id').editable,
        pinned: pinmap.get("supplier_contact_id"),
        width: widthmap.has("supplier_contact_id") ? widthmap.get("supplier_contact_id") : tableinfoMap.get('supplier_contact_id').width,
        type:  tableinfoMap.get('supplier_contact_id').type,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompareNonZero(valueA,valueB,isDescending,'supplier_contact_id');
        },
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "supplier_contact_title",
        order: tableinfoMap.get('supplier_contact_title').order,
        type:  tableinfoMap.get('supplier_contact_title').type,
        headerName: tableinfoMap.get('supplier_contact_title').header,
        editable: tableinfoMap.get('supplier_contact_title').editable,
        pinned: pinmap.get("supplier_contact_title"),
        width: widthmap.has("supplier_contact_title") ? widthmap.get("supplier_contact_title") : tableinfoMap.get('supplier_contact_title').width,
      },
      {
        field: "supplier_contact_phone",
        order: tableinfoMap.get('supplier_contact_phone').order,
        type:  tableinfoMap.get('supplier_contact_phone').type,
        headerName: tableinfoMap.get('supplier_contact_phone').header,
        editable: tableinfoMap.get('supplier_contact_phone').editable,
        width: widthmap.has("supplier_contact_phone") ? widthmap.get("supplier_contact_phone") : tableinfoMap.get('supplier_contact_phone').width,
      },
      {
        field: "supplier_contact_email",
        order: tableinfoMap.get('supplier_contact_email').order,
        headerName: tableinfoMap.get('supplier_contact_email').header,
        editable: tableinfoMap.get('supplier_contact_email').editable,
        type:  tableinfoMap.get('supplier_contact_email').type,
        pinned: pinmap.get("supplier_contact_email"),
        width: widthmap.has("supplier_contact_email") ? widthmap.get("supplier_contact_email") : tableinfoMap.get('supplier_contact_email').width,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "supplier_contact_role",
        order: tableinfoMap.get('supplier_contact_role').order,
        headerName: tableinfoMap.get('supplier_contact_role').header,
        editable: tableinfoMap.get('supplier_contact_role').editable,
        pinned: pinmap.get("supplier_contact_role"),
        width: widthmap.has("supplier_contact_role") ? widthmap.get("supplier_contact_role") : tableinfoMap.get('supplier_contact_role').width,
        type:  tableinfoMap.get('supplier_contact_role').type,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompareNonZero(valueA,valueB,isDescending,'supplier_contact_role');
        },
      },
      {
        field: "warehouse_id",
        order: tableinfoMap.get('warehouse_id').order,
        headerName: tableinfoMap.get('warehouse_id').header,
        editable: tableinfoMap.get('warehouse_id').editable,
        pinned: pinmap.get("warehouse_id"),
        width: widthmap.has("warehouse_id") ? widthmap.get("warehouse_id") : tableinfoMap.get('warehouse_id').width,
        enableRowGroup: true,
        type:  tableinfoMap.get('warehouse_id').type,
      },
      {
        field: "warehouse_rcpt_date",
        order: tableinfoMap.get('warehouse_rcpt_date').order,
        headerName: tableinfoMap.get('warehouse_rcpt_date').header,
        editable: tableinfoMap.get('warehouse_rcpt_date').editable,
        pinned: pinmap.get("warehouse_rcpt_date"),
        width: widthmap.has("warehouse_rcpt_date") ? widthmap.get("warehouse_rcpt_date") : tableinfoMap.get('warehouse_rcpt_date').width,
        type:  tableinfoMap.get('warehouse_rcpt_date').type,


      },
      {
        field: "pallet_number",
        order: tableinfoMap.get('pallet_number').order,
        headerName: tableinfoMap.get('pallet_number').header,
        editable: tableinfoMap.get('pallet_number').editable,
        pinned: pinmap.get("pallet_number"),
        width: widthmap.has("pallet_number") ? widthmap.get("pallet_number") : tableinfoMap.get('pallet_number').width,
        enableRowGroup: true,
        type: tableinfoMap.get('pallet_number').type,
      },
      {
        field: "custom1t",
        order: tableinfoMap.get('custom1t').order,
        headerName: tableinfoMap.get('custom1t').header,
        editable: tableinfoMap.get('custom1t').editable,
        pinned: pinmap.get("custom1t"),
        width: widthmap.has("custom1t") ? widthmap.get("custom1t") : tableinfoMap.get('custom1t').width,
        type: tableinfoMap.get('custom1t').type,
      },
      {
        field: "custom2t",
        order: tableinfoMap.get('custom2t').order,
        headerName: tableinfoMap.get('custom2t').header,
        editable: tableinfoMap.get('custom2t').editable,
        pinned: pinmap.get("custom2t"),
        width: widthmap.has("custom2t") ? widthmap.get("custom2t") : tableinfoMap.get('custom2t').width,
        type: tableinfoMap.get('custom2t').type,
      },
      {
        field: "custom3t",
        order: tableinfoMap.get('custom3t').order,
        headerName: tableinfoMap.get('custom3t').header,
        editable: tableinfoMap.get('custom3t').editable,
        pinned: pinmap.get("custom3t"),
        width: widthmap.has("custom3t") ? widthmap.get("custom3t") : tableinfoMap.get('custom3t').width,
        type: tableinfoMap.get('custom3t').type,
      },
      {
        field: "custom5u",
        order: tableinfoMap.get('custom5u').order,
        headerName: tableinfoMap.get('custom5u').header,
        editable: tableinfoMap.get('custom5u').editable,
        pinned: pinmap.get("custom5u"),
        width: widthmap.has("custom5u") ? widthmap.get("custom5u") : tableinfoMap.get('custom5u').width,
        type: tableinfoMap.get('custom5u').type,
      },
      {
        field: "custom6d",
        order: tableinfoMap.get('custom6d').order,
        headerName: tableinfoMap.get('custom6d').header,
        editable: tableinfoMap.get('custom6d').editable,
        pinned: pinmap.get("custom6d"),
        width: widthmap.has("custom6d") ? widthmap.get("custom6d") : tableinfoMap.get('custom6d').width,
        type:  tableinfoMap.get('custom6d').type,
      },
      {
        field: "created_datetime",
        order: tableinfoMap.get('created_datetime').order,
        headerName: tableinfoMap.get('created_datetime').header,
        editable: tableinfoMap.get('created_datetime').editable,
        type:  tableinfoMap.get('created_datetime').type,
        pinned: pinmap.get("created_datetime"),
        width: widthmap.has("created_datetime") ? widthmap.get("created_datetime") : tableinfoMap.get('created_datetime').width,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "updated_datetime",
        order: tableinfoMap.get('updated_datetime').order,
        headerName: tableinfoMap.get('updated_datetime').header,
        editable: tableinfoMap.get('updated_datetime').editable,
        pinned: pinmap.get("updated_datetime"),
        width: widthmap.has("updated_datetime") ? widthmap.get("updated_datetime") : tableinfoMap.get('updated_datetime').width,
        type:  tableinfoMap.get('updated_datetime').type,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
//last check
      //last emailed

      {
        field: "supplier_id_2",
        order: tableinfoMap.get('supplier_id_2').order,
        filter: true,
        headerName: tableinfoMap.get('supplier_id_2').header,
        editable: tableinfoMap.get('supplier_id_2').editable,
        pinned: pinmap.get("supplier_id_2"),
        width: widthmap.has("supplier_id_2") ? widthmap.get("supplier_id_2") : tableinfoMap.get('supplier_id_2').width,
        valueGetter: (params: any) => {
          if(params && params.data && params.data.hasOwnProperty('supplier_id')) {
            return params.data.supplier_id;
          }
        },
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "order_id",
        order: tableinfoMap.get('order_id').order,
        headerName: tableinfoMap.get('order_id').header,
        editable: tableinfoMap.get('order_id').editable,
        filter: true,
        enableRowGroup: true,
        pinned: pinmap.get("order_id"),
        width: widthmap.has("order_id") ? widthmap.get("order_id") : tableinfoMap.get('order_id').width,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
      },
      {
        field: "id",
        order: tableinfoMap.get('id').order,
        filter: true,
        headerName: tableinfoMap.get('id').header,
        editable: tableinfoMap.get('id').editable,
        pinned: pinmap.get("id"),
        width: widthmap.has("id") ? widthmap.get("id") :  tableinfoMap.get('id').width,
        headerComponentParams: { template: createCustomHeaderTemplate(false)},
        cellRenderer: (params: any) => {
          if (params.data.has_subitems && params.data.has_subitems.item_id) {
            return params.data.has_subitems.item_id;
          }
          return params.value;
        }
      },

      {
        field: "track_order",
        order: tableinfoMap.get('track_order').order,
        headerName: tableinfoMap.get('track_order').header,
        editable: tableinfoMap.get('track_order').editable,
        pinned: pinmap.get("track_order"),
        width: widthmap.has("track_order") ? widthmap.get("track_order") : tableinfoMap.get('track_order').width,
        type:  tableinfoMap.get('track_order').type,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
          return this.doColumnCompare(valueA,valueB,isDescending,'track_order');
        },
      },
      {
        field: 'next_supplier_user_view',
        order: tableinfoMap.get('next_supplier_user_view').order,
        headerName: tableinfoMap.get('next_supplier_user_view').header,
        editable: tableinfoMap.get('next_supplier_user_view').editable,
        pinned: pinmap.get('next_supplier_user_view'),
        width: widthmap.has('next_supplier_user_view') ? widthmap.get('next_supplier_user_view') : 180,
        type:  tableinfoMap.get('next_supplier_user_view').type,
        cellClassRules: {
          "italic-text": (params: { value: string; }) => !CommonConstants.dateRegex.test(params.value)
        },
        enableRowGroup: true
      }
    ];

  return tempcolumns;
}


function statusText() {
  return `Click to see MaterLog's<br>
          defined order statuses`;
}

function trackText() {
  return `These are fields that MaterLog's<br>
          automations will update if data<br>
          about that field is in an<br>
          order update`;
}

function statusIcon() {
  const status_text = statusText();
  return `
      ${trackIcon()}
      <a href="http://www.materlog.com/statuschart" target="_blank" class="status-chart-link">
        <i class="bi-question-circle"></i>
      </a>
    `;
}

function trackIcon() {
  const track_text = trackText();
  return `
      <a target="_blank" class="status-chart-link">
        <i class="bi-lightning lightningTrackIcon"></i>
      </a>
    `;
}

function createCustomHeaderTemplate(with_status: boolean) {
  let icons = with_status ? statusIcon() : trackIcon();
  return `
    <div class="ag-cell-label-container" role="presentation">
      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
        <div class="column-header-content">
          <div class="icon-container">
            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
            ${icons}
          </div>
        </div>
        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
      </div>
    </div>`;
}


export function doTextColumnCompareNullLast(a: any, b: any, isDescending:boolean){
  const nullPosition = isDescending ? -100000 : 100000
  // if a is null, push it towards whichever end null elements should end up
  if (a === null||a==undefined) {return nullPosition;}
  if (b === null||b==undefined) {return -nullPosition}

  a = a.toString().toLowerCase().trim();
  b = b.toString().toLowerCase().trim();

  if (a.length < 1) {return nullPosition;}
  if (b.length < 1) {return -nullPosition;}

  const startsWithNumber = (str: any) => !isNaN(str.charAt(0)) && str !== '';

  const aStartsWithNumber = startsWithNumber(a);
  const bStartsWithNumber = startsWithNumber(b);

  // Handle cases where one starts with a number and the other with a letter
  if (aStartsWithNumber !== bStartsWithNumber) {
    return aStartsWithNumber ? 1 : -1;
  }

  // Normal string comparison if both start with the same type (number or letter)
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;

}
import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorDeleteItem, errorItemData, errorPostPutItemData, receiveItemData, receivePostPutItemData, requestItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IShareView, IShareViewEmail, IShareViewInvite } from '../../models/dashboard.model';
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { IShareViewState, ShareViewState } from '../models/shareView.model';


export function shareViewReducer(
  lastState: IShareViewState = new ShareViewState(),
  action: GenericAction<DashboardActionTypes, any>
): IShareViewState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_SHARE_VIEW:
      return requestItemData<IShareViewState, IShareView>(lastState, ['shareViews']);
    case DashboardActionTypes.RECEIVE_SHARE_VIEW:
      return receiveItemData<IShareViewState, IShareView>(lastState, ['shareViews'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_SHARE_VIEW:
      return errorItemData<IShareViewState, IShareView>(lastState, ['shareViews'], action.payload);

    case DashboardActionTypes.REQUEST_UPDATE_SHARE_VIEW:
      return requestPostPutItemData<IShareViewState, IShareViewInvite>(lastState, ['shareViews'], action.payload);
    case DashboardActionTypes.RECEIVE_UPDATE_SHARE_VIEW:
      return receivePostPutItemData<IShareViewState, IShareViewInvite>(lastState, ['shareViews'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_UPDATE_SHARE_VIEW:
      return errorPostPutItemData<IShareViewState, IShareViewInvite>(lastState, ['shareViews'], action.payload);

    case DashboardActionTypes.REQUEST_DELETE_SHARE_VIEW:
      return requestPostPutItemData<IShareViewState, IShareView>(lastState, ['shareViews'], action.payload);
    case DashboardActionTypes.RECEIVE_DELETE_SHARE_VIEW:
      return receivePostPutItemData<IShareViewState, IShareView>(lastState, ['shareViews'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_DELETE_SHARE_VIEW:
      return errorDeleteItem<IShareViewState, IShareView>(lastState, ['shareViews'], action.payload);

    case DashboardActionTypes.REQUEST_UPDATE_SHARE_VIEW_EMAIL:
      return requestPostPutItemData<IShareViewState, IShareViewEmail>(lastState, ['shareViewEmail'], action.payload);
    case DashboardActionTypes.RECEIVE_UPDATE_SHARE_VIEW_EMAIL:
      return receivePostPutItemData<IShareViewState, IShareViewEmail>(lastState, ['shareViewEmail'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_UPDATE_SHARE_VIEW_EMAIL:
      return errorPostPutItemData<IShareViewState, IShareViewEmail>(lastState, ['shareViewEmail'], action.payload);

    case DashboardActionTypes.REQUEST_DELETE_SHARE_VIEW_EMAIL:
      return requestPostPutItemData<IShareViewState, IShareViewEmail>(lastState, ['shareViewEmail'], action.payload);
    case DashboardActionTypes.RECEIVE_DELETE_SHARE_VIEW_EMAIL:
      return receivePostPutItemData<IShareViewState, IShareViewEmail>(lastState, ['shareViewEmail'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_DELETE_SHARE_VIEW_EMAIL:
      return errorDeleteItem<IShareViewState, IShareViewEmail>(lastState, ['shareViewEmail'], action.payload);

    default:
      return lastState;
  }
}

import { IOptions } from 'src/app/authentication/models/authentication.model';
import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorItemData, receiveItemData, requestItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { IOptionsState, OptionsState } from '../models/options.model';

export function optionsReducer(
  lastState: IOptionsState = new OptionsState(),
  action: GenericAction<DashboardActionTypes, any>
): IOptionsState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_OPTIONS:
      return requestItemData<IOptionsState, IOptions>(lastState, ['options']);
    case DashboardActionTypes.RECEIVE_OPTIONS:
      return receiveItemData<IOptionsState, IOptions>(lastState, ['options'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_OPTIONS:
      return errorItemData<IOptionsState, IOptions>(lastState, ['options'], action.payload);

    default:
      return lastState;
  }
}

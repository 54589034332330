import { Injectable } from '@angular/core';
import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { selectActiveJobs, selectSuppliers, selectViews} from '../../state/selectors/dashboard.selector';


@Injectable({
  providedIn: 'root'
})
export class ClassNameService {
  cn(...inputs: ClassValue[]): string {
    return twMerge(clsx(inputs));
  }
}


export function initData(this: any) {

  this.jobService.getJobList({payload: {'small': true}}).subscribe((jobs: any) => {
      if (jobs) {
        this.curJobs = jobs.reduce((map:any, item:any) => {
          map.set(item.id, item.name);
          return map;
        }, new Map());
        let jobDict = Array.from(this.curJobs as Iterable<[any, any]>).map(([id, name]: [any, any]) => ({
          id: id, value: name
        })) as any;
        jobDict.sort((a: any, b: any) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
        this.jobsArray = jobDict as any;
      }
    });

  this.store.select(selectActiveJobs as any).subscribe((activeJobsResults: any) => {
    if (activeJobsResults.data) {
      let data = activeJobsResults.data;
      let activeJobsMap: Map<string, string> = data.reduce((map:any, item:any) => {
        map.set(item.id, item.name);
        return map;
      }, new Map());
      let activeJobs = Array.from(activeJobsMap).map(([id, name]) => ({
        id: id, value: name
      }));
      activeJobs.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
      if (this.allJobsOption) this.activeJobsList = [this.allJobsOption].concat(activeJobs as any) as any;
      else this.activeJobsList = activeJobs;
    }
  });

  this.store.select(selectSuppliers as any).subscribe((activeSuppliers: any) => {
    if (activeSuppliers.data && activeSuppliers.data.results) {
      let data = activeSuppliers.data.results;
      let allSuppliersMap: Map<string, string>= data.reduce((map:any, item:any) => {
        map.set(item.id, item.name);
        return map;
      }, new Map());
      const supArray = Array.from(allSuppliersMap.entries()).map(([id, value]) => ({ id, value }));
      supArray.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
      if (this.allSuppliersOption) {
        this.supArray = [this.allSuppliersOption].concat(supArray) as any;
        this.selectedSupplier = this.allSuppliersOption;
      } else {
        this.supArray = supArray;
      }
    }
  });
  let payload = { approvals: true, include_items: 0,}
  this.dashboardService.getApprovalOrders({payload: payload}).subscribe((result: any) => {
    processOrderData.bind(this)(result || []);
  });

}

export function sortDocumentsByDate(documents: any) {
  documents.sort((a: any, b: any) => {
    if (!a.order_document_date) return 1;
    if (!b.order_document_date) return -1;
    if (a.order_document_date < b.order_document_date) return 1;
    if (a.order_document_date > b.order_document_date) return -1;
    return 0;
  });
}

export function deduplicateOrderTypes(documents: any) {
  let output: any = [];
  let found_types: any = [];
  documents.forEach((doc: any) => {
    if (found_types.includes(doc.document_type)) return;
    found_types.push(doc.document_type);
    output.push(doc);
  });
  return output;
}

function prefixAndNumber(data: any) {
  let out: any = [];
  data.forEach((d: any) => {
    let pre = getPrefix(d.document_type);
    out.push(`${pre}${d.number}`);
  });
  return out;
}

function processOrderData(this: any, data: any) {
  let cur_orders: any = [];
  data?.forEach((order: any) => {
    let cur: any = {...order};
    if (!order?.supplier_documents?.length) {
      let combine: any = ['No name', order.suppliers_name, order.job_name];
      combine = combine.filter((c: any) => c).join(' / ');
      cur.value = combine;
    } else {
      let docs = order.supplier_documents;
      sortDocumentsByDate(docs);
      docs = deduplicateOrderTypes(docs);
      let po = docs.find((doc: any) => doc.document_type == 1)?.number;
      let value = po ? `PO ${po}` : '';
      let others = docs.filter((d: any) => d.document_type != 1);
      others = prefixAndNumber(others).join(' / ');
      value = others ? `${value} ${others}`: value;
      cur.value = value;
    }
    cur_orders.push(cur);
  })

  cur_orders.sort((a: any, b: any) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
  if (this.allOrdersOption) {
    cur_orders = [this.allOrdersOption].concat(cur_orders) as any;
    this.selectedOrder = this.allOrdersOption;
  }

  this.curOrders = cur_orders;
}

function getPrefix(type: number) {
  switch(type) {
    case 0:
      return '';
    case 1:
      return 'PO ';
    case 2:
      return 'Est. ';
    case 3:
      return 'Order ';
    case 4:
      return 'Inv. ';
    case 5:
      return 'Receipt ';
    case 6:
      return '';
    case 7:
      return 'BOL ';
    case 8:
      return 'Warehouse receipt ';
    case 9:
      return 'Pro forma Inv. '
    default:
      return '';
  }
}
<form>
  <p-dialog
    [(visible)]="showDialog"
    [style]="{ marginTop: '50px', width: '60vw', minWidth: '630px', maxWidth: '800px', minHeight: '85vh' }"
    [closeOnEscape]="false"
    [modal]="true"
    [resizable]="false"
    [draggable]="false"
    [position]="'top'"
    [closable]="false"
    [maskStyleClass]="'dialog-mask'"
  >
    <materlog-loader class="loader-center" *ngIf="isLoading"></materlog-loader>
    <ng-template pTemplate="header">
      <div class="dialog__header">
        <ng-container *ngIf="!isLoading">
          <i *ngIf="!ordersForm.id" class="bi bi-plus icon-green"></i>
          <fa-icon *ngIf="ordersForm.id" [icon]="faEdit" style="margin-right: 10px"></fa-icon>
          <span> {{ orderText }} </span>
        </ng-container>
      </div>
      <button class="dialog-close" type="button" (click)="closeDialog()"><i class="bi bi-x-lg"></i></button>
    </ng-template>
    <ng-container>
      <div *ngIf="finishedUpload" class="order-complete-upload">
        <strong>Success!</strong> It will take a few minutes for your order to process. Once your order is processed, it will automatically be added to your account. You will be able to see an editable record of your order in your <span class="dashed-underline" (click)="gotoNotifications()">notifications panel</span>.
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading && !manualUpload && !finishedUpload">
      <div class="auto-upload-container">
        <div class="user-auto-upload">
          <div class="auto-upload-text">
            Automatically add new order
          </div>
          <div class="browse-attach-container">
            <div class="browse-text">
              <label class="attachment-upload" (click)="uploadFile($event)">
                <div class="browse-files-icon">
                  <i class="bi bi-arrow-up-circle arrow-icon"></i>
                  Browse files
                </div>
                <input type="file" #fileInput (change)="onFileSelected($event)">
              </label>
              <div class="browse-upload-options">
                Supported file types: .pdf, .png, .jpeg and .jpg
              </div>
            </div>
            <div class="attachments-container custom-scrollbar-close">
              <div class="single-file-name" *ngFor="let att of uploadDocs">
                <span class="file-name-wrapper">
                  <span class="file-names file-ready">{{att.filename}}</span>
                  <i class="bi bi-x file-name-delete" (click)="removeAttachment($event, att)"></i>
                </span>
              </div>
            </div>
            <button *ngIf="uploadDocs.length" class="upload-button" (click)="onUploadParseDoc()"
                    [disabled]="orderUploading">
              <div class="button-content">
                {{ orderUploading ? 'Uploading...': 'Run'}}
              </div>
            </button>
          </div>
        </div>
        <div class="user-auto-upload">
          <div class="auto-upload-text">
            Manually add order
          </div>
          <div class="manual-upload-text">
            Click the “Next: Add order” button <span class="full-underline">without</span> uploading files above to manually add an order.
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading && manualUpload">
      <div class="bottom">
        <!--<div class="bottom__title">Manually {{ ordersForm.id ? 'edit an order' : 'add an order' }}</div>-->
        <div class="bottom__form">
          <div class="bottom__form-control">
            <label [ngClass]="wrongJob ? 'warning' : null" class="bottom__form-control-label" for="job">
              {{ ordersForm.id ? 'Job' : 'Job*' }}
            </label>
            <div>
              <p-dropdown
                appendTo="body"
                [options]="activeJobs"
                [(ngModel)]="ordersForm.job"
                (onChange)="checkJob()"
                id="job"
                optionValue="id"
                optionLabel="name"
                placeholder="Job"
                name="job"
                [required]="!ordersForm.id"
                [showClear]="true"
                [filter]="true"
              >
              </p-dropdown>
              <p *ngIf="wrongJob" class="warning-text">Please select a Job.</p>
            </div>
          </div>

          <div class="bottom__form-control">
            <label [ngClass]="wrongSupplier ? 'warning' : null" class="bottom__form-control-label" for="supplier">
              Supplier*
            </label>
            <div>
              <p-autoComplete
                appendTo="body"
                [(ngModel)]="ordersForm.supplier.name"
                [suggestions]="filteredSuppliers"
                (completeMethod)="filterSupplier($event)"
                [dropdown]="true"
                placeholder="Supplier"
                (onSelect)="onSelectSupplier($event)"
                (onClear)="onClearSupplier()"
                (onDropdownClick)="getSuppliers()"
                name="name"
                field="name"
                [required]="true"
                [forceSelection]="true"
                [showEmptyMessage]="true"
                [showClear]="true"
              >
                <ng-template let-supplier pTemplate="item">
                  <div>
                    <div>{{ supplier.name }}</div>
                  </div>
                </ng-template>
                <ng-template let-supplier pTemplate="footer">
                  <button
                    pButton
                    type="button"
                    label="+ Add new supplier"
                    class="p-button-text p-button-plain"
                    (click)="onOpenModalAddSupplier()"
                  ></button>
                </ng-template>
              </p-autoComplete>
              <p *ngIf="wrongSupplier" class="warning-text">Please select a Supplier.</p>
            </div>
          </div>
          <div class="bottom__form-control">
            <label class="bottom__form-control-label" for="contact">Supplier contact</label>

            <div class="bottom__form-control-inputs">
              <p-autoComplete
                appendTo="body"
                [(ngModel)]="ordersForm.supplier_contacts.name"
                [suggestions]="filteredSupplierContacts"
                (completeMethod)="filterSupplierContacts($event)"
                [dropdown]="true"
                placeholder="Supplier Contact"
                (onSelect)="onSelectSupplierContact($event)"
                (onClear)="onClearSupplierContact()"
                name="contacts"
                field="name"
                [forceSelection]="true"
                [showEmptyMessage]="true"
                [showClear]="true"
              >
                <ng-template let-supplier_contacts pTemplate="item">
                  <div>
                    <div>{{ supplier_contacts.name }}</div>
                  </div>
                </ng-template>
                <ng-template let-supplier pTemplate="footer">
                  <button
                    *ngIf="ordersForm.supplier.id"
                    pButton
                    type="button"
                    label="+ Add new supplier contact"
                    class="p-button-text p-button-plain"
                    (click)="onOpenModalAddSupplierContact()"
                  ></button>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>

          <div class="bottom__form-control">
            <label [ngClass]="wrongDocs ? 'warning' : null" class="bottom__form-control-label" for="PO">PO</label>
            <div class="bottom__form-control-document">
              <materlog-add-documents
                [document_types]="documentTypes"
                [docForms]="poDocArray"
                (docFormsChange)="poDocFormChangeEvent($event)"
                [isPO]="true"
              ></materlog-add-documents>
            </div>
          </div>
          <div class="bottom__form-control">
            <label [ngClass]="wrongDocs ? 'warning' : null" class="bottom__form-control-label" for="docs"
              >Supplier docs</label
            >
            <div class="bottom__form-control-document">
              <materlog-add-documents
                [document_types]="documentTypes"
                [supplier]="true"
                [docForms]="supplierDocForm"
                (docFormsChange)="documentFormChangeEvent($event)"
              ></materlog-add-documents>
              <p *ngIf="wrongDocs" class="warning-text">Please provide a PO or Supplier document number.</p>
            </div>
          </div>

          <div class="bottom__form-control">
            <label class="bottom__form-control-label" for="ship">Ship to</label>
            <div class="bottom__form-control-inputs">
              <p-dropdown
                appendTo="body"
                [options]="shipTo"
                [(ngModel)]="ordersForm.shipping_to"
                id="ship"
                optionValue="id"
                optionLabel="value"
                placeholder="Shipping to"
                name="shippin_to"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div [ngClass]="{ 'dropdown-not-selected-option': item.value === 'Not selected' }">
                    {{ item.value }}
                  </div>
                </ng-template>
              </p-dropdown>
              <div *ngIf="ordersForm.shipping_to === 4" class="bottom__form-control-inputs">
                <input
                  type="text"
                  placeholder="Shipping name"
                  class="form-controls"
                  id="shipping_name"
                  name="shipping_name"
                  [(ngModel)]="ordersForm.shipping_name"
                />
                <geoapify-geocoder-autocomplete
                  [value]="ordersForm.shipping_address"
                  [debounceDelay]="50"
                  [limit]="5"
                  [lang]="'en'"
                  [biasByCountryCode]="['us']"
                  [filterByCountryCode]="['us']"
                  placeholder="Shipping address"
                  [skipIcons]="true"
                  [skipDetails]="true"
                  (placeSelect)="placeSelected($event)"
                  (userInput)="ordersForm.shipping_address = $event"
                >
                </geoapify-geocoder-autocomplete>
                <input
                  type="text"
                  placeholder="Shipping city"
                  class="form-controls"
                  id="shipping_city"
                  name="shipping_city"
                  [(ngModel)]="ordersForm.shipping_city"
                />
                <div class="bottom__form-control-inputs-group">
                  <input
                    type="text"
                    placeholder="Shipping zip"
                    class="form-controls"
                    id="shipping_zip"
                    name="shipping_zip"
                    [(ngModel)]="ordersForm.shipping_zip"
                  />
                  <p-dropdown
                    appendTo="body"
                    [options]="shippingStates"
                    [(ngModel)]="ordersForm.shipping_state"
                    id="state"
                    optionValue="id"
                    optionLabel="value"
                    placeholder="State"
                    name="shipping_state"
                    [showClear]="true"
                  >
                  </p-dropdown>
                </div>
              </div>
            </div>
          </div>

          <p-divider align="center" type="dashed" class="modal-divider modal-divider--dashed">
            <div class="bottom__form-details" (click)="additionalDetails = !additionalDetails">
              {{ additionalDetails ? '-' : '+' }} Add additional order details
            </div></p-divider
          >
          <ng-container *ngIf="additionalDetails">
            <div class="bottom__form-control">
              <label for="order_cost">Total order cost</label>
              <div class="icon-inside-input">
                <input
                  type="number"
                  placeholder="Cost"
                  class="form-controls"
                  id="order_cost"
                  name="order_cost"
                  [(ngModel)]="ordersForm.total_cost"
                  maxlength="10"
                  [pattern]="decimalRegex"
                />
                <i class="bi bi-currency-dollar"></i>
                <p *ngIf="!isDecimal" class="warning-text">
                  Total cost can have up to 6 integers and 2 decimal numbers.
                </p>
              </div>
            </div>
            <div class="bottom__form-control">
              <label for="payment">Payment Method</label>
              <p-dropdown
                appendTo="body"
                [options]="paymentMethod"
                [(ngModel)]="ordersForm.payment_method"
                id="payment"
                optionValue="id"
                optionLabel="value"
                placeholder="Choose"
                name="payment"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div [ngClass]="{ 'dropdown-not-selected-option': item.value === 'Not selected' }">
                    {{ item.value }}
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="bottom__form-control">
              <label for="last_digits">Last 4 digits of cc</label>
              <input
                minlength="4"
                maxlength="4"
                type="text"
                placeholder="Digits"
                class="form-controls"
                id="last_digits"
                name="last_digits"
                [(ngModel)]="ordersForm.last_4_card_digits"
              />
            </div>
            <div class="bottom__form-control">
              <label>Reimbursable</label>
              <div class="bottom__form-control-reimbursable">
                <p-radioButton [(ngModel)]="ordersForm.is_reimbursable" [value]="2" name="is_reimbursable" label="Yes">
                </p-radioButton>
                <p-radioButton [(ngModel)]="ordersForm.is_reimbursable" [value]="1" name="is_reimbursable" label="No">
                </p-radioButton>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-template pTemplate="footer">
      <ng-container *ngIf="!isLoading && !manualUpload && !finishedUpload">
        <div class="manual-upload-button">
          <button class="manual-upload-button-format mt-4" (click)="manualUpload = true">
            Next: Add order
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!isLoading && manualUpload">
        <div class="bottom__submit">
          <div class="bottom__submit-left">
            <button *ngIf="ordersForm.id && didAddItems" class="bottom__submit-left-back mt-4" type="button" (click)="backToItems()">
              Back to items
            </button>
          </div>
          <div class="bottom__submit-right">
            <button class="bottom__submit-right-button mt-4" type="submit" (click)="onCreateOrder()">
              {{ ordersForm.id && didAddItems ? 'Save changes' : 'Next: Add items' }}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </p-dialog>
</form>

<p-dialog
  [(visible)]="addSupplierContactModal"
  [style]="{ marginTop: '150px', width: '540px' }"
  [closeOnEscape]="false"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>Add supplier contact</span>
    </div>
    <button class="dialog-close" type="button" (click)="closeSupplierContact()"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <materlog-add-supplier-contact-modal
    [resetContact]="addSupplierContactModal"
    [contactRoles]="contactRoles"
    [supplierList]="suppliers"
    [supplierId]="ordersForm.supplier.id"
    (supplierContactFormChange)="supplierContactFormChangeEvent($event)"
  ></materlog-add-supplier-contact-modal>
</p-dialog>

<p-dialog
  [(visible)]="addSupplierModal"
  [style]="{ marginTop: '250px', width: '500px' }"
  [closeOnEscape]="false"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>Add supplier</span>
    </div>
    <button class="dialog-close" type="button" (click)="addSupplierModal = false;"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <materlog-add-supplier-modal
    [resetSupplier]="addSupplierModal"
    (supplierFormChange)="supplierFormChangeEvent($event)"
  ></materlog-add-supplier-modal>
</p-dialog>

<materlog-add-item *ngIf="addItemsModal"
  (close)="addItemsModal = false"
  (addedItems)="getCurrentOrderItems()"
  [fromOrder]="true"
  [curOrderId]="curOrderId"
  [itemList]="itemList">
</materlog-add-item>

<p-toast position="top-center" key="success"></p-toast>
<p-toast position="top-center" key="error"></p-toast>

import { Component, OnInit } from '@angular/core';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { selectOptions } from 'src/app/dashboard/state/selectors/dashboard.selector';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';

@Component({
  selector: 'materlog-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.sass']
})
export class UserSettingsComponent extends OnDestroyMixin() implements OnInit {
  countriesList: any;
  statesList: any;
  checkboxList: any;
  radiosList: any;

  constructor(
    private store: Store<IApplicationState>,
    private dashboardActions: DashboardActions) {
    super();
  }

  ngOnInit(): void {
    this.onRequestOptions();
  }

  onRequestOptions() {
    this.dashboardActions.requestOptions();

    this.store
      .pipe(
        select(selectOptions),
        filter((options: IStoreApiItem<IOptions>) => !options.isLoading),
        take(1)
      )
      .subscribe((options: IStoreApiItem<IOptions>) => {
        this.countriesList = options?.data?.countries;
        this.statesList = options?.data?.states;
        this.checkboxList = options?.data?.notification_types;
        this.radiosList = options?.data?.notification_item_date_types;
      });
  }

}

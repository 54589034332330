import { MemoizedSelector, createSelector, DefaultProjectorFn } from '@ngrx/store';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { INotifications, IUserManagement, IUserPassword } from '../../models/user.model';
import { IUserState } from '../models/user.model';


const userState = (state: IApplicationState) => state.user;

export const selectNotifications: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<INotifications>,
  DefaultProjectorFn<IStoreApiItem<INotifications>>
> = createSelector(userState, (state: IUserState | any) => state.notifications.notifications);

export const selectUserProfile: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IUserManagement>,
  DefaultProjectorFn<IStoreApiItem<IUserManagement>>
> = createSelector(userState, (state: IUserState | any) => state.userProfile.userProfile);


export const selectUserPassword: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IUserPassword>,
  DefaultProjectorFn<IStoreApiItem<IUserPassword>>
> = createSelector(userState, (state: IUserState | any) => state.userPassword.userPassword);

import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorItemData, errorPostPutItemData, receiveItemData, receivePostPutItemData, requestItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IUserManagement } from '../../models/user.model';
import { UserActionTypes } from '../actions/user-action-types';
import { IUserProfileState, UserProfileState } from '../models/user-profile.model';

export function userProfileReducer(
  lastState: IUserProfileState = new UserProfileState(),
  action: GenericAction<UserActionTypes, any>
): IUserProfileState {
  switch (action.type) {

    case UserActionTypes.REQUEST_USER_PROFILE:
      return requestItemData<IUserProfileState, IUserManagement>(lastState, ['userProfile']);
    case UserActionTypes.RECEIVE_USER_PROFILE:
      return receiveItemData<IUserProfileState, IUserManagement>(lastState, ['userProfile'], action.payload);
    case UserActionTypes.ERROR_RECEIVE_USER_PROFILE:
      return errorItemData<IUserProfileState, IUserManagement>(lastState, ['userProfile'], action.payload);

    case UserActionTypes.REQUEST_UPDATE_USER_PROFILE:
      return requestPostPutItemData<IUserProfileState, IUserManagement>(lastState, ['userProfile'], action.payload);
    case UserActionTypes.RECEIVE_UPDATE_USER_PROFILE:
      return receivePostPutItemData<IUserProfileState, IUserManagement>(lastState, ['userProfile'], action.payload);
    case UserActionTypes.ERROR_RECEIVE_UPDATE_USER_PROFILE:
      return errorPostPutItemData<IUserProfileState, IUserManagement>(lastState, ['userProfile'], action.payload);

    default:
      return lastState;
  }
}

import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";

import { ICellEditorAngularComp } from "ag-grid-angular";

@Component({
  selector: 'shared-calendar',
  templateUrl: "shared-calendar.component.html",
  providers: [DatePipe],
})
export class SharedCalendar implements ICellEditorAngularComp {
  public params: any;

  constructor(
    private datePipe: DatePipe,
  ) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  getValue(): any {
    if(this.params.value instanceof Date) {
      return this.datePipe.transform(this.params.value, 'YYYY/MM/dd')
    }else{
      return this.params.value;
    }
  }

  onChangeDate(): void {
    this.params.stopEditing();
  }
}

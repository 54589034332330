import { IOptions } from 'src/app/authentication/models/authentication.model';
import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorItemData, errorPostPutItemData, receiveItemData, receivePostPutItemData, requestItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { SuppliersActionTypes } from '../actions/suppliers-action-types';
import { ISuppliersPageState, SuppliersPageState } from '../models/suppliers-page.model';

export function suppliersPageReducer(
  lastState: ISuppliersPageState = new SuppliersPageState(),
  action: GenericAction<SuppliersActionTypes, any>
): ISuppliersPageState {
  switch (action.type) {

    case SuppliersActionTypes.REQUEST_SUPPLIERS_PAGE:
      return requestItemData<ISuppliersPageState, any>(lastState, ['suppliersPage']);
    case SuppliersActionTypes.RECEIVE_SUPPLIERS_PAGE:
      return receiveItemData<ISuppliersPageState, any>(lastState, ['suppliersPage'], action.payload);
    case SuppliersActionTypes.ERROR_RECEIVE_SUPPLIERS_PAGE:
      return errorItemData<ISuppliersPageState, any>(lastState, ['suppliersPage'], action.payload);

    case SuppliersActionTypes.REQUEST_SUPPLIERS_OPTIONS:
      return requestPostPutItemData<ISuppliersPageState, IOptions>(lastState, ['userOptions'], action.payload);
    case SuppliersActionTypes.RECEIVE_SUPPLIERS_OPTIONS:
      return receivePostPutItemData<ISuppliersPageState, IOptions>(lastState, ['userOptions'], action.payload);
    case SuppliersActionTypes.ERROR_RECEIVE_SUPPLIERS_OPTIONS:
      return errorPostPutItemData<ISuppliersPageState, IOptions>(lastState, ['userOptions'], action.payload);
    default:
      return lastState;
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageCacheService {
  private cache = new Map<string, string>();

  constructor() {}

  getImage(url: string): string {
    return <string>this.cache.get(url);
  }

  setImage(url: string, data: string) {
    this.cache.set(url, data);
  }

  hasImage(url: string): boolean {
    return this.cache.has(url);
  }
}

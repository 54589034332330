<div style="width: 800px; height: 800px;">
  <div class="font-bold text-4xl mb-4">Request supplier update</div>
  <div class="flex-row flex justify-between">
    <div class="font-normal">
      Asking about this item or the whole order?
    </div>
  <div>


      <div class="flex">
        <div class="flex items-center mr-4">
          <input id="inline-radio" type="radio" (change)="handleChange($event)"

                 checked
                 value="single" name="request-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
          <label for="inline-radio" class="ml-2 text-base font-normal text-gray-900 dark:text-gray-300">This item</label>
        </div>
        <div class="flex items-center mr-4">
          <input id="inline-2-radio" type="radio" (change)="handleChange($event)" value="whole" name="request-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
          <label for="inline-2-radio" class="ml-2 text-base font-normal text-gray-900 dark:text-gray-300">Whole order</label>
        </div>
        <div class="flex items-center mr-4">
          <input  id="inline-checked-radio" type="radio" (change)="handleChange($event)" value="all" name="request-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
          <label for="inline-checked-radio" class="ml-2 text-base font-normal text-gray-900 dark:text-gray-300">All open orders</label>
        </div>

      </div>


    </div>

  </div>

  <div class="w-full flex flex-col gap-4 mt-4" >

    <div class="grid grid-cols-10 gap-4 w-full">
      <label for="from-email" class="block col-span-1 text-base font-normal leading-6 text-gray-900 sm:pt-1.5">From</label>

      <input type="text" name="from-email" id="from-email"
             [(ngModel)]="from_email"
             class="block  col-span-9 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  text-base leading-6">

    </div>



    <div class="grid grid-cols-10 gap-4 w-full">
      <label for="to-email" class="block col-span-1 text-base font-normal leading-6 text-gray-900 sm:pt-1.5">To</label>

      <input type="text" name="to-email" id="to-email"
             [(ngModel)]="to_email"
             class="block  col-span-9 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  text-base leading-6">

    </div>

    <div class="grid grid-cols-10 gap-4 w-full">
      <label for="cc-email" class="block col-span-1 text-base font-normal leading-6 text-gray-900 sm:pt-1.5">Cc</label>

      <input type="text" name="cc-email" id="cc-email"
             [(ngModel)]="cc_email"
             class="block  col-span-9 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  text-base leading-6">

    </div>
    <div class="grid grid-cols-10 gap-4 w-full">
      <label for="bcc-email" class="block col-span-1 text-base font-normal leading-6 text-gray-900 sm:pt-1.5">Bcc</label>

      <input type="text" name="bcc-email" id="bcc-email"
             [(ngModel)]="bcc_email"
             class="block  col-span-9 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  text-base leading-6">

    </div>
    <div class="grid grid-cols-10 gap-4 w-full">
      <label for="subject-email" class="block col-span-1 text-base font-normal leading-6 text-gray-900 sm:pt-1.5">Subject</label>

      <input type="text" name="subject-email" id="subject-email"  [(ngModel)]="subject"
             class="block  col-span-9 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  text-base leading-6">

    </div>

    <div class="grid grid-cols-10 gap-4 w-full">
      <label for="body-email" class="block col-span-1 text-base font-normal leading-6 text-gray-900 sm:pt-1.5">Body</label>

      <angular-editor  name="body-email" id="body-email" [config]="editor_config"
                       style="list-style: disc; padding: 5px; list-style-type: disc; padding-inline-start: 20px"
                       [(ngModel)]="body"
                       class="block  col-span-9 w-full text-base leading-6 list-disc">
      </angular-editor>
    </div>
  </div>

  <label class="custom-file-upload">
    <i class="bi bi-paperclip" style="padding-right: 5px"></i>Choose files
    <input type="file" (change)="onFileSelected($event)">

  </label>

  <div *ngIf="selectedFiles.length > 0" class="file-names-container">
    <div *ngFor="let file of selectedFiles" class="single-file-name">
      <span class="file-name-wrapper">
        <span class="file-names">{{ file.name }}</span>
        <i class="bi bi-x file-name-delete" (click)="removeAttachment(file)"></i>
      </span>
    </div>
  </div>

  <div class="m-6 grid justify-items-end  justify-end items-end">
    <button (click)="sendSupplierEmail()" class="bg-blue-500 hover:bg-gray-100 text-white font-normal hover:text-black py-4 px-8 border border-blue-500 hover:border-transparent rounded-md text-base">
      Email supplier
    </button>
  </div>

</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DividerModule } from 'primeng/divider';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { UserSettingsComponent } from '../components/user-settings/user-settings.component';
import { PreferencesComponent } from '../components/preferences/preferences.component';
import { userReducer } from '../state/reducers/user.reducer';
import { UserEffects } from '../state/effects/user.effects';
import { UserManagementComponent } from '../components/user-management/user-management.component';

import { NotificationsService } from '../services/notifications.service';


@NgModule({
  declarations: [
    // UserSettingsComponent,
    // PreferencesComponent,
    // UserManagementComponent
  ],
  imports: [
    CommonModule,
    DividerModule,
    CheckboxModule,
    FormsModule,
    RadioButtonModule,
    StoreModule.forFeature('user', userReducer),
    EffectsModule.forFeature([UserEffects]),
    FontAwesomeModule,
    DialogModule,
    ConfirmDialogModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    DropdownModule,
    InputTextModule,
    DropdownModule,
    InputTextModule,
    TooltipModule,
  ],
  providers: [ConfirmationService, MessageService, NotificationsService]
})
export class UserModule { }

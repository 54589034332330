import { IStoreApiList } from 'src/app/common/models/store-api-list.model';
import { MemoizedSelector, DefaultProjectorFn, createSelector } from '@ngrx/store';
import { ApplicationState, IApplicationState } from 'src/app/common/state/models/app.state.model';
import { ISuppliersState } from 'src/app/dashboard/state/models/suppliers.model';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { ISuppliersPageState } from '../models/suppliers-page.model';

const suppliersState = (state: IApplicationState) => state.suppliers;

export const selectSuppliersPage: MemoizedSelector<
  ApplicationState,
  IStoreApiList<any>,
  DefaultProjectorFn<IStoreApiList<any>>
> = createSelector(suppliersState, (state: ISuppliersState | any) => state.suppliersPage);

export const selectSupplierOptions: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IOptions>,
  DefaultProjectorFn<IStoreApiItem<IOptions>>
> = createSelector(suppliersState, (state: ISuppliersPageState | any) => state.userOptions);

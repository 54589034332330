<div class="filters">
  <div class="filters__container">
    <hide-fields [isLoading]="isLoading" [selectedView]="selectedView"> </hide-fields>
    <mlogfilters
      [isLoading]="isLoading"
      [selectedView]="selectedView"
      [activeJob]="activeJob"
      [mailBoxVisible]="mailBoxVisible"
      [viewsOptions]="optionsService.viewsOptionsObs | async"
      [dropdownOptions]="optionsService.dropdownOptionsObs | async"
    >
    </mlogfilters>
    <sort
      [selectedView]="selectedView"
      [mailBoxVisible]="mailBoxVisible"
      [isLoading]="isLoading"
    ></sort>
    <group-fields
      [selectedView]="selectedView"
      [mailBoxVisible]="mailBoxVisible"
      [isLoading]="isLoading">
    </group-fields>

   <resizer
    [isLoading]="isLoading"
    [mailBoxVisible]="mailBoxVisible"
    [selectedView]="selectedView">
    </resizer>
   <subitems
    [isLoading]="isLoading"
    [mailBoxVisible]="mailBoxVisible"
    [selectedView]="selectedView">
    </subitems>

  <!-- <pin-fields [isLoading]="isLoading" [selectedView]="selectedView"> </pin-fields>-->

    <templates
      *ngIf="activeJob.name !== 'Uncategorized items'"
      [selectedView]="selectedView"
      [mailBoxVisible]="mailBoxVisible"
      [dropdownOptions]="optionsService.privacyOptionsObs | async"
      [isLoading]="isLoading"
    >
    </templates>
    <share
      *ngIf="activeJob.name !== 'Uncategorized items'"
      [dropdownOptions]="optionsService.dropdownOptionsObs | async"
      [viewId]="selectedView?.activeView?.id"
      [isShared]="selectedView?.activeView?.is_shared"
      [isLoading]="isLoading"
      [selectedView]="selectedView"
      [mailBoxVisible]="mailBoxVisible"
    >
    </share>
  </div>
  <div class="filters__container" #dashboardSearchAndHistory >
    <div>
      <input
        class="px-2 py-1 rounded-full text-xs border border-neutral-300 input-element"
        type="text"
        id="filter-text-box"
        placeholder="Search..."
        [(ngModel)] = "search[selectedView?.activeView?.id ?? 'default']"
        (input)="onFilterTextBoxChanged()"
      />
    </div>
    <div *ngIf="activeJob.name !== 'Uncategorized items'">
      <history
        [isLoading]="isLoading"
        [selectedView]="selectedView"
        [dropdownOptions]="optionsService.historyOptionsObs | async"
        (onUpdateLoadingOverlay)="onUpdateLoadingOverlay()"
      >
      </history>
    </div>
  </div>
</div>

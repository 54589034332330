import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TableConstants } from 'src/app/common/constants/table.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { SelectedViewModel } from '../../models/selected-view.model';
import { JobTableService } from '../../services/job-table.service';
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faArrowDownAZ, faArrowRightArrowLeft, faArrowUpAZ } from '@fortawesome/free-solid-svg-icons';
import {deepCopy, deepArrayClone} from "../../../common/utils/general"
import { ElementRef, HostListener, ViewChild } from '@angular/core';


@Component({
  selector: 'group-fields',
  templateUrl: './group-fields.component.html',
  styleUrls: ['./group-fields.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupFieldsComponent extends OnDestroyMixin() implements OnChanges {
  @Input() selectedView?: SelectedViewModel;
  @Input() isLoading!: boolean;
  @Input() mailBoxVisible: boolean = false;

  mapgroups = new Map();
  groupnumber:Map<string,number> = new Map();
  faGroup = faLayerGroup;
  toggleFieldsModal: boolean = false;
  private mapFromfrontEnd = new Map();
  searchterm: string = "";
  fields: any = [];
  @ViewChild('groupDiv') groupDiv!: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
     private dashboardActions: DashboardActions,
      private jobTableService: JobTableService) {
    super();
    for (let i = 0; i < TableConstants.jobItemsTableColumns.length; i++) {
      let column = TableConstants.jobItemsTableColumns[i];
      if (column.field != 'index') {
        this.mapFromfrontEnd.set(column.field, column);
      }
    }
  }
  triggerFieldsChange(): void {
  }

  get groupText() {
    if (this.mailBoxVisible) return '';
    return !this.isLoading ? 'Group '  : 'Groups';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.selectedView?.currentValue && !!this.selectedView) {
      //first lets list all possible columns which can be grouped...
      this.fields = [];
      this.mapgroups = new Map();
      if (this.selectedView?.activeView) {
        this.selectedView.activeView.get_fields_selected_to_group().forEach(
          (x: any) => {this.mapgroups.set(x.field, true);}
        );
      }
      let index = 0;
      TableConstants.jobItemsTableColumns.forEach(item=>{
        if(item.field != 'index' && this.mapFromfrontEnd.has(item.field)) {

          if (item.enableRowGroup) {
            let value = {
              field: item.field,
              header: item.header,
              group: false,
              order: 1000
            };
            //change header to new one
            if(this.mapFromfrontEnd.has(item.field)){
              value.header = this.mapFromfrontEnd.get(item.field).header;
            }
            let active_view = this.selectedView?.activeView;
            if (active_view) {
              let group_fields = active_view.get_fields_selected_to_group();
              if (group_fields.some((obj: any) => obj.field === item.field)){
                value.group = true;
                value.order = index++;
              }
            }
            this.fields.push(value);
          }
        }
      });
    }
  }

  triggerGroupChange(){
  }

  emitUpdateSort(key: any, value: any): void {
    const objToUpdate: any = {};
  }

  clickOutsideModal(event: any): void {
    if (event.target.id !== 'show-groups-modal-button') {
      this.toggleFieldsModal = false;
      this.searchterm = "";
    }
    if (!this.groupDiv.nativeElement.contains(event.target) && event.target.id !== 'show-groups-modal-button') {
      this.toggleFieldsModal = false;
      this.searchterm = "";
    }
  }

  showFieldsModal(event: any): void {
    event.preventDefault();
    this.toggleFieldsModal = !this.toggleFieldsModal;
  }

  clearAllSorts(){
  }

  inSearchTerm(fieldname:string){
    return fieldname.toLowerCase().includes(this.searchterm);
  }
  get isGrouping() {
    return !(this.mapgroups && this.mapgroups.size > 0);
  }
  trackByFn(index: number, item:any) {
   return index;
  }

  onFilterGroupFieldsChanged():void {
    let search = (document.getElementById('filter-group-field') as HTMLInputElement).value.toLowerCase()
    this.searchterm = search;
  }

  onModelChange($event: any) {
    if($event){
      this.fields.forEach((item:any)=>{
        if(item.group && !this.mapgroups.has(item.field)){
        this.mapgroups.set(item.field, true);
        item.order = this.mapgroups.size - 1;
        }
      });
    }else{
      this.fields.forEach((item:any)=>{
        if(item.group == false && this.mapgroups.has(item.field)){
          this.mapgroups.delete(item.field);
          item.order = 1000 ;
        }
      });

    }
    let saveVersion: any = new Array(this.mapgroups.size);
    if(this.mapgroups.size > 0){
      let index = 0;
      this.mapgroups.forEach((_, key) => {
          saveVersion[index]  = { 'field': key };
          let item = this.fields.find((obj: any) => obj.field === key);
          item.order = index;
          index++;
      });
      //need to filter out empty spots...
    }
    if (this.selectedView) {
      this.selectedView.setSelectedFieldsToGroup(saveVersion);
      if (saveVersion.length) {
        this.jobTableService.setGroup(saveVersion.filter(Boolean));
        this.dashboardActions.requestSaveView("fields_selected_to_group",saveVersion.filter(Boolean),this.selectedView.activeView.id);
      } else {
        this.jobTableService.setGroup([]);
        this.dashboardActions.requestSaveView("fields_selected_to_group",[],this.selectedView?.activeView.id);
      }
    }
    // this.cdr.detectChanges();
  }

  ungroupAll(): void {
    this.fields.forEach((field: any) => (field.group = false));
    this.onModelChange(undefined);
  }
}

<p-autoComplete
  panelStyleClass="ag-custom-component-popup"
  appendTo="body"
  [showClear]="true"
  [dropdown]="true"
  [suggestions]="checkSupplierSuggestions(params.data['id']) ? temporarySupplierList : params.options"
  (completeMethod)="filterSupplier($event, params.data)"
  (onDropdownClick)="getSuppliers(params.data)"
  name="name"
  field="name"
  [ngModel]="assignedNameToSupplierId"
  (ngModelChange)="setTemporarySupplierValue($event)"
  [placeholder]="params.colDef.header"
  (onSelect)="onChange(params.colDef.field, temporarySupplierValue, params.data['id']); closeSupplier();"

  (onClear)="onChange(params.colDef.field, null, params.data['id'])"
>
  <ng-template pTemplate="footer">
    <button
      pButton
      type="button"
      label="+ Add new supplier"
      class="p-button-text p-button-plain"
      (click)="onOpenModalAddSupplier(params.data)"
    ></button>
  </ng-template>
</p-autoComplete>

<p-dialog
  [(visible)]="addSupplierModal"
  [style]="{ marginTop: '250px', width: '500px' }"
  [closeOnEscape]="false"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>Add supplier</span>
    </div>
    <button class="dialog-close" type="button" (click)="closeSupplier()"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <materlog-add-supplier-modal
    [resetSupplier]="addSupplierModal"
    (supplierFormChange)="supplierFormChangeEvent($event)"
  ></materlog-add-supplier-modal>
</p-dialog>

import { GenericAction } from './../../../common/state/models/generic-action.model';
import { AuthenticationActionTypes } from './../actions/authentication-action-types';
import { AuthenticationState, IAuthenticationState } from './../models/authentication.model';
import { IForgotPassword, ILogin, IRecoverPassword, ISingUp, IOptions } from '../../models/authentication.model';
import { requestPostPutItemData, receivePostPutItemData, errorPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';

export function authenticationReducer(
  lastState: IAuthenticationState = new AuthenticationState(),
  action: GenericAction<AuthenticationActionTypes, any>
): IAuthenticationState {
  switch (action.type) {

    case AuthenticationActionTypes.REQUEST_LOGIN:
      return requestPostPutItemData<IAuthenticationState, ILogin>(lastState, ['login'], action.payload);
    case AuthenticationActionTypes.RECEIVE_LOGIN:
      return receivePostPutItemData<IAuthenticationState, ILogin>(lastState, ['login'], action.payload);
    case AuthenticationActionTypes.ERROR_LOGIN:
      return errorPostPutItemData<IAuthenticationState, ILogin>(lastState, ['login'], action.payload);

    case AuthenticationActionTypes.REQUEST_SING_UP:
      return requestPostPutItemData<IAuthenticationState, ISingUp>(lastState, ['singUp'], action.payload);
    case AuthenticationActionTypes.RECEIVE_SING_UP:
      return receivePostPutItemData<IAuthenticationState, ISingUp>(lastState, ['singUp'], action.payload);
    case AuthenticationActionTypes.ERROR_SING_UP:
      return errorPostPutItemData<IAuthenticationState, ISingUp>(lastState, ['singUp'], action.payload);

    case AuthenticationActionTypes.REQUEST_FORGOT_PASSWORD:
      return requestPostPutItemData<IAuthenticationState, IForgotPassword>(lastState, ['forgotPassword'], action.payload);
    case AuthenticationActionTypes.RECEIVE_FORGOT_PASSWORD:
      return receivePostPutItemData<IAuthenticationState, IForgotPassword>(lastState, ['forgotPassword'], action.payload);
    case AuthenticationActionTypes.ERROR_FORGOT_PASSWORD:
      return errorPostPutItemData<IAuthenticationState, IForgotPassword>(lastState, ['forgotPassword'], action.payload);

    case AuthenticationActionTypes.REQUEST_RECOVER_PASSWORD:
      return requestPostPutItemData<IAuthenticationState, IRecoverPassword>(lastState, ['recoverPassword'], action.payload);
    case AuthenticationActionTypes.RECEIVE_RECOVER_PASSWORD:
      return receivePostPutItemData<IAuthenticationState, IRecoverPassword>(lastState, ['recoverPassword'], action.payload);
    case AuthenticationActionTypes.ERROR_RECOVER_PASSWORD:
      return errorPostPutItemData<IAuthenticationState, IRecoverPassword>(lastState, ['recoverPassword'], action.payload);

    case AuthenticationActionTypes.REQUEST_USER_OPTIONS:
      return requestPostPutItemData<IAuthenticationState, IOptions>(lastState, ['userOptionsInfo', 'userOptions'], action.payload);
    case AuthenticationActionTypes.RECEIVE_USER_OPTIONS:
      return receivePostPutItemData<IAuthenticationState, IOptions>(lastState, ['userOptionsInfo', 'userOptions'], action.payload);
    case AuthenticationActionTypes.ERROR_USER_OPTIONS:
      return errorPostPutItemData<IAuthenticationState, IOptions>(lastState, ['userOptionsInfo', 'userOptions'], action.payload);

    case AuthenticationActionTypes.REQUEST_COMPANY:
      return requestPostPutItemData<IAuthenticationState, any>(lastState, ['userOptionsInfo', 'company'], action.payload);
    case AuthenticationActionTypes.RECEIVE_COMPANY:
      return receivePostPutItemData<IAuthenticationState, any>(lastState, ['userOptionsInfo', 'company'], action.payload);
    case AuthenticationActionTypes.ERROR_COMPANY:
      return errorPostPutItemData<IAuthenticationState, any>(lastState, ['userOptionsInfo', 'company'], action.payload);

    case AuthenticationActionTypes.REQUEST_ROLE:
      return requestPostPutItemData<IAuthenticationState, any>(lastState, ['userOptionsInfo', 'role'], action.payload);
    case AuthenticationActionTypes.RECEIVE_ROLE:
      return receivePostPutItemData<IAuthenticationState, any>(lastState, ['userOptionsInfo', 'role'], action.payload);
    case AuthenticationActionTypes.ERROR_ROLE:
      return errorPostPutItemData<IAuthenticationState, any>(lastState, ['userOptionsInfo', 'role'], action.payload);

    default:
      return lastState;
  }
}


import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IComments } from '../../models/comments.model';

export interface ICommentsState {
  comments: IStoreApiItem<IComments>;
}

export class CommentsState implements ICommentsState {
  comments: IStoreApiItem<IComments> = new StoreApiItem();
}

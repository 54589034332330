<div class="h-full flex flex-col">
  <div #content class="split-container">
    <div class="split-header">
      <div class="split-header-title">
           Split item
      </div>
      <div class="close-popup" (click)="onClosePopup()">
        <i class="bi bi-x"></i>
      </div>
    </div>
    <div class="splits-count-container">
      <div class="how-many-splits">
        <div class="prompt-for-user">
            How many?
        </div>
        <div #textarea class="single-comment-textarea"
            contenteditable="true"
            [attr.data-placeholder]="getPlaceholderText()"
            (input)="updateCommentText($event)">
        </div>
      </div>
      <div class="split-error" *ngIf="splitError">
        {{ splitError }}
      </div>
    </div>
    <div class="split-actions" *ngIf="!doCustomize" >
      <div class="accept-default-button" (click)="onAcceptDefault($event)">
        Split
      </div>
      <div class="customize"(click)="onCustomize()">
        or Customize
      </div>
    </div>
    <div class="show-customize" *ngIf="doCustomize">
      <div class="split-error" *ngIf="quantityError">
        {{ quantityError }}
      </div>
      <div class="splits-per-row">
        How many subitems per row?
      </div>
      <div class="close-customize" (click)="onCloseCustomize($event)">
        <i class="bi bi-x"></i>
      </div>
      <div class="quantity-choice" *ngFor="let item of userSplits; let i = index">
        <div class="quantity-prompt">
          Quantity
        </div>
        <div #textarea class="single-comment-textarea"
            contenteditable="true"
            (input)="updateQuantityText($event, i)">
        </div>
      </div>
      <div class="accept-customize-button" (click)="onAcceptCustomize($event)">
        Split
      </div>
    </div>
  </div>
</div>

import { IStoreApiList } from 'src/app/common/models/store-api-list.model';
import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { ApplicationState, IApplicationState } from 'src/app/common/state/models/app.state.model';
import { IActiveJobs, IJobs, IReceiver, ISuppliers, INewOrder, IReceiverContact, INewItem, IActiveOrderList, IOrders, IViews, ITemplates, IOrderDocument, IShareView, IShareViewEmail, IShareViewInvite } from '../../models/dashboard.model';
import { IDashboardState } from '../models/dashboard.model';
import { ActionReducer } from '@ngrx/store';
import { StoreModule } from '@ngrx/store';


const dashboardState = (state: IApplicationState) => state.dashboard;


export const selectJobs: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IJobs>,
  DefaultProjectorFn<IStoreApiItem<IJobs>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.jobs.jobs);

export const selectReceivers: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IReceiver[]>,
  DefaultProjectorFn<IStoreApiItem<IReceiver[]>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.receivers.receivers);

export const selectReceiversContact: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IReceiverContact>,
  DefaultProjectorFn<IStoreApiItem<IReceiverContact>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.receivers.receiverContacts);

export const selectOptions: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IOptions>,
  DefaultProjectorFn<IStoreApiItem<IOptions>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.options.options);

export const selectActiveJobs: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IActiveJobs>,
  DefaultProjectorFn<IStoreApiItem<IActiveJobs>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.activeJobs.activeJobs);

export const selectSuppliers: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<ISuppliers>,
  DefaultProjectorFn<IStoreApiItem<ISuppliers>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.suppliers.suppliers);

export const selectJobsList: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IJobs>,
  DefaultProjectorFn<IStoreApiItem<IJobs>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.jobs.jobsList);

export const selectOrders: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IOrders>,
  DefaultProjectorFn<IStoreApiItem<IOrders>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.orders.orders);

export const selectNewOrders: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<INewOrder>,
  DefaultProjectorFn<IStoreApiItem<INewOrder>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.orders);

export const selectAddRoom: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IActiveOrderList>,
  DefaultProjectorFn<IStoreApiItem<IActiveOrderList>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.orders.roomOptions);

export const selectSupplierContact: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<ISuppliers>,
  DefaultProjectorFn<IStoreApiItem<ISuppliers>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.suppliers.supplierContact);

export const selectItems: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<INewItem>,
  DefaultProjectorFn<IStoreApiItem<INewItem>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.items);

export const selectActiveOrders: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IActiveOrderList>,
  DefaultProjectorFn<IStoreApiItem<IActiveOrderList>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.activeOrders.activeOrders);

export const selectViews: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IViews>,
  DefaultProjectorFn<IStoreApiItem<IViews>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.views.views);

export const selectUncategorizedView: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IViews>,
  DefaultProjectorFn<IStoreApiItem<IViews>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.views.uncategorizedView);

export const selectActiveTemplates: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<ITemplates[]>,
  DefaultProjectorFn<IStoreApiList<ITemplates[]>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.activeTemplates.templates);

export const selectOrderDocument: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IOrderDocument>,
  DefaultProjectorFn<IStoreApiItem<IOrderDocument>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.orderDocument.orderDocument);

export const selectShareView: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IShareView>,
  DefaultProjectorFn<IStoreApiItem<IShareView>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.shareView.shareViews);

export const selectShareViewEmail: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IShareViewEmail>,
  DefaultProjectorFn<IStoreApiItem<IShareViewEmail>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.shareView.shareViewEmail);

export const selectShareViewInvite: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IShareViewInvite>,
  DefaultProjectorFn<IStoreApiItem<IShareViewInvite>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.shareView.shareViewInvite);

export const selectAutocompleteSuppliers: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<ISuppliers>,
  DefaultProjectorFn<IStoreApiItem<ISuppliers>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.suppliers.suppliersAutocomplete);

export const selectAutocompleteReceivers: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IReceiver>,
  DefaultProjectorFn<IStoreApiItem<IReceiver>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.receivers.receiversAutocomplete);

export const selectComments: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IReceiver>,
  DefaultProjectorFn<IStoreApiItem<IReceiver>>
> = createSelector(dashboardState, (state: IDashboardState | any) => state.comments);

import { Component, Input, OnInit } from '@angular/core';
import { faExclamationTriangle, faPencil, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { select, Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import { UserActions } from '../../state/actions/user.actions';
import { selectUserPassword, selectUserProfile } from '../../state/selectors/user.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IUserManagement, IUserPassword } from '../../models/user.model';
import { CustomErrorService } from 'src/app/dashboard/services/custom-error.service';

@Component({
  selector: 'materlog-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.sass']
})
export class UserManagementComponent extends OnDestroyMixin() implements OnInit {
  showDialog: boolean = false;
  showNameDialog: boolean = false;
  showPasswordDialog: boolean = false;
  isLoading: boolean = false;
  wrongPassword: boolean = false;
  differentPassword: boolean = false;
  isPasswordSubmited: boolean = false;
  isProfileSubmited: boolean = false;

  faEdit = faPencil;
  faUser = faUser;
  faTriangle = faExclamationTriangle;
  faXMark = faXmark;

  @Input() statesList: any;
  @Input() countriesList: any;

  image: any;

  userSettings: any = {
    first_name: '',
    last_name: '',
    user_email: '', // Email edit not for MVP
    image_profile: null,
    address: '',
    city: '',
    state: null,
    country: null,
    zip: '',
    phone: '',
    timezone: '',
  };

  passwordForm: any = {
    old_password: '',
    new_password1: '',
    new_password2: '',
  };

  constructor(private store: Store<IApplicationState>,
    private userActions: UserActions,
    private messageService: MessageService,
    private customErrorService: CustomErrorService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.userActions.requestUserProfile();
    this.onRequestProfile();
  }

  onRequestProfile() {
    this.store
      .pipe(
        select(selectUserProfile),
        filter((userProfile: IStoreApiItem<IUserManagement>) => !userProfile.isLoading),
        take(1)
      )
      .subscribe((userProfile: IStoreApiItem<IUserManagement>) => {
        this.userSettings = JSON.parse(JSON.stringify(userProfile?.data));
        this.image = userProfile?.data?.image_profile;
      });
  }

  complexAddress() {
    return ((this.userSettings.state || '') + (this.userSettings.zip || '') + (this.userSettings.country || ''));
  }

  //Handles files browse
  fileBrowseHandler(files: File[]) {
    for (const item of files) {
      this.convertBase64(item);
    }
  }

  checkFileType(file: any): boolean {
    const prepareFile: any = file.name.split('.');
    const fileType = prepareFile?.findLast((el: any) => el.length > 1);
    const types = ['png', 'jpeg', 'jpg', 'jfif', 'pjpeg', 'pjp', 'svg', 'webp'];

    if (!types.includes(fileType)) { return false; }

    return true;
  }

  convertBase64(file: any) {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const data = String(reader.result).split('base64,');

      if (data[1] && this.checkFileType(file)) {
        this.userSettings.image_profile = data[1];
        this.image = reader.result;
        this.updateProfileCall();
      } else {
        this.messageService.clear();
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please provide a valid file.',
          key: 'error',
        });
      }
    };
  }

  openNameDialog() {
    this.showNameDialog = true;
  }

  openPasswordDialog() {
    this.showPasswordDialog = true;
  }

  closeNameDialog() {
    this.showNameDialog = false;
    this.onRequestProfile();
  }

  closePasswordDialog() {
    this.resetPassword();
    this.showPasswordDialog = false;
  }

  editPassword() {
    this.isPasswordSubmited = true;

    if (this.passwordForm.new_password1.length >= 8 && this.passwordForm.new_password2.length >= 8) {
      if (this.passwordForm.new_password1 === this.passwordForm.new_password2) {
        this.updatePasswordCall();
        this.resetPassword();
      }
    }

  }

  resetPassword() {
    this.passwordForm.old_password = '';
    this.passwordForm.new_password1 = '';
    this.passwordForm.new_password2 = '';
    this.isPasswordSubmited = false;

  }

  editAddress() {
    this.showDialog = true;
  }

  closeDialog() {
    this.showDialog = false;
  }

  onSave() {
    this.updateProfileCall();
    this.showDialog = false;
    this.showNameDialog = false;
    this.isProfileSubmited = true;
  }

  updateProfileCall() {
    this.isProfileSubmited = true;

    const isHttpImageProfile = this.userSettings.image_profile?.startsWith('http');
    let objToUpdate: IUserManagement = {
      first_name: this.userSettings.first_name,
      last_name: this.userSettings.last_name,
      address: this.userSettings.address,
      city: this.userSettings.city,
      state: this.userSettings.state,
      country: this.userSettings.country,
      zip: this.userSettings.zip,
      phone: this.userSettings.phone,
      timezone: this.userSettings.timezone,
    };
    if (!isHttpImageProfile) {
      objToUpdate.image_profile = this.userSettings.image_profile
    }

    this.userActions.requestUpdateUserProfile(objToUpdate);

    this.store
      .pipe(
        select(selectUserProfile),
        filter((userProfile: IStoreApiItem<IUserManagement>) => !userProfile.isLoading),
        takeUntil(this.destroy)
      )
      .subscribe((userProfile: any) => {
        if (userProfile?.errors && !this.isLoading) {
          this.isProfileSubmited = false;
          this.customErrorService.setCustomErrorMessage(userProfile);
          setTimeout(() => {
            this.onRequestProfile();
          }, 300);
        }
      });
  }

  updatePasswordCall() {
    const objToUpdate: IUserPassword = {
      old_password: this.passwordForm.old_password,
      new_password1: this.passwordForm.new_password1,
      new_password2: this.passwordForm.new_password2,
    };

    this.userActions.requestUpdateUserPassword(objToUpdate);

    this.store
      .pipe(
        select(selectUserPassword),
        filter((userPassword: IStoreApiItem<IUserPassword>) => !userPassword.isLoading),
        takeUntil(this.destroy)
      )
      .subscribe((userPassword: any) => {
        if (userPassword?.isSuccess) {
          this.showPasswordDialog = false;
        }

        if (userPassword?.errors && !this.isLoading) {
          this.customErrorService.setCustomErrorMessage(userPassword);
        }
      });

  }

  get isPasswordShort() {
    if (this.passwordForm.new_password1?.length >= 8 && this.passwordForm.new_password2?.length >= 8) {
      return false;
    } else { return true; }

  }

  get isPasswordDifferent() {
    if (this.passwordForm.new_password1 === this.passwordForm.new_password2) {
      return false;
    }
    else { return true; }
  }
}

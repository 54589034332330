import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IOrganizationAddresses } from 'src/app/company-settings/models/company.model';
import { CompanyActionTypes } from '../company-action-types';

export class RequestOrganizationAddresses implements Action {
  readonly type: CompanyActionTypes.REQUEST_ORGANIZATION_ADDRESSES = CompanyActionTypes.REQUEST_ORGANIZATION_ADDRESSES;

  constructor(
    public payload?: string) { }
}
export class ReceiveOrganizationAddresses implements Action {
  readonly type: CompanyActionTypes.RECEIVE_ORGANIZATION_ADDRESSES = CompanyActionTypes.RECEIVE_ORGANIZATION_ADDRESSES;

  constructor(
    public payload?: IOrganizationAddresses) { }
}
export class ErrorReceiveOrganizationAddresses implements Action {
  readonly type: CompanyActionTypes.ERROR_RECEIVE_ORGANIZATION_ADDRESSES = CompanyActionTypes.ERROR_RECEIVE_ORGANIZATION_ADDRESSES;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class RequestAddOrganizationAddress implements Action {
  readonly type: CompanyActionTypes.REQUEST_ADD_ORGANIZATION_ADDRESS = CompanyActionTypes.REQUEST_ADD_ORGANIZATION_ADDRESS;

  constructor(
    public payload: IOrganizationAddresses) { }
}

export class ReceiveAddOrganizationAddress implements Action {
  readonly type: CompanyActionTypes.RECEIVE_ADD_ORGANIZATION_ADDRESS = CompanyActionTypes.RECEIVE_ADD_ORGANIZATION_ADDRESS;

  constructor(
    public payload: IOrganizationAddresses) { }
}
export class ErrorReceiveAddOrganizationAddress implements Action {
  readonly type: CompanyActionTypes.ERROR_RECEIVE_ADD_ORGANIZATION_ADDRESS = CompanyActionTypes.ERROR_RECEIVE_ADD_ORGANIZATION_ADDRESS;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestUpdateOrganizationAddress implements Action {
  readonly type: CompanyActionTypes.REQUEST_UPDATE_ORGANIZATION_ADDRESS = CompanyActionTypes.REQUEST_UPDATE_ORGANIZATION_ADDRESS;

  constructor(
    public payload: IOrganizationAddresses) { }
}

export class ReceiveUpdateOrganizationAddress implements Action {
  readonly type: CompanyActionTypes.RECEIVE_UPDATE_ORGANIZATION_ADDRESS = CompanyActionTypes.RECEIVE_UPDATE_ORGANIZATION_ADDRESS;

  constructor(
    public payload: IOrganizationAddresses) { }
}

export class ErrorReceiveUpdateOrganizationAddress implements Action {
  readonly type: CompanyActionTypes.ERROR_RECEIVE_UPDATE_ORGANIZATION_ADDRESS = CompanyActionTypes.ERROR_RECEIVE_UPDATE_ORGANIZATION_ADDRESS;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestDeleteOrganizationAddress implements Action {
  readonly type: CompanyActionTypes.REQUEST_DELETE_ORGANIZATION_ADDRESS = CompanyActionTypes.REQUEST_DELETE_ORGANIZATION_ADDRESS;

  constructor(
    public payload: IOrganizationAddresses) { }
}

export class ReceiveDeleteOrganizationAddress implements Action {
  readonly type: CompanyActionTypes.RECEIVE_DELETE_ORGANIZATION_ADDRESS = CompanyActionTypes.RECEIVE_DELETE_ORGANIZATION_ADDRESS;

  constructor(
    public payload: IOrganizationAddresses) { }
}
export class ErrorReceiveDeleteOrganizationAddress implements Action {
  readonly type: CompanyActionTypes.ERROR_RECEIVE_DELETE_ORGANIZATION_ADDRESS = CompanyActionTypes.ERROR_RECEIVE_DELETE_ORGANIZATION_ADDRESS;

  constructor(
    public payload: HttpErrorResponse) { }
}

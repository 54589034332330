export class ActiveJobModel {

  static fromJSON(object: any): ActiveJobModel {
    return new ActiveJobModel(
      object.id,
      object.name,
      object.color
    );
  }

  constructor(
    readonly id: string,
    readonly name: string,
    readonly color: number = 5) {
  }

  get isSelected(): boolean {
    return this.localStorageSelectedJobId === this.id
  }

  private get localStorageSelectedJobId(): string | null {
    let savedJobId = localStorage.getItem('selectedJob') as any;
    return !!savedJobId ? JSON.parse(savedJobId) : null
  }
}

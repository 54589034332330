import { MemoizedSelector, DefaultProjectorFn, createSelector } from '@ngrx/store';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { IOrganization, IOrganizationAddresses } from '../../models/company.model';
import { ICompanyState } from '../models/company.model';

const companyState = (state: IApplicationState) => state.company;


export const selectOrganization: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IOrganization>,
  DefaultProjectorFn<IStoreApiItem<IOrganization>>
> = createSelector(companyState, (state: ICompanyState | any) => state.organization.organization);

export const selectOrganizationAddresses: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IOrganizationAddresses>,
  DefaultProjectorFn<IStoreApiItem<IOrganizationAddresses>>
> = createSelector(companyState, (state: ICompanyState | any) => state.organizationAddresses.organizationAddresses);


// eslint-disable-next-line no-shadow
export enum CompanyActionTypes {
  REQUEST_ORGANIZATION = 'REQUEST_ORGANIZATION',
  RECEIVE_ORGANIZATION = 'RECEIVE_ORGANIZATION',
  ERROR_RECEIVE_ORGANIZATION = 'ERROR_RECEIVE_ORGANIZATION',

  REQUEST_UPDATE_ORGANIZATION = 'REQUEST_UPDATE_ORGANIZATION',
  RECEIVE_UPDATE_ORGANIZATION = 'RECEIVE_UPDATE_ORGANIZATION',
  ERROR_RECEIVE_UPDATE_ORGANIZATION = 'ERROR_RECEIVE_UPDATE_ORGANIZATION',

  REQUEST_ORGANIZATION_ADDRESSES = 'REQUEST_ORGANIZATION_ADDRESSES',
  RECEIVE_ORGANIZATION_ADDRESSES = 'RECEIVE_ORGANIZATION_ADDRESSES',
  ERROR_RECEIVE_ORGANIZATION_ADDRESSES = 'ERROR_RECEIVE_ORGANIZATION_ADDRESSES',

  REQUEST_UPDATE_ORGANIZATION_ADDRESS = 'REQUEST_UPDATE_ORGANIZATION_ADDRESS',
  RECEIVE_UPDATE_ORGANIZATION_ADDRESS = 'RECEIVE_UPDATE_ORGANIZATION_ADDRESS',
  ERROR_RECEIVE_UPDATE_ORGANIZATION_ADDRESS = 'ERROR_RECEIVE_UPDATE_ORGANIZATION_ADDRESS',

  REQUEST_ADD_ORGANIZATION_ADDRESS = 'REQUEST_ADD_ORGANIZATION_ADDRESS',
  RECEIVE_ADD_ORGANIZATION_ADDRESS = 'RECEIVE_ADD_ORGANIZATION_ADDRESS',
  ERROR_RECEIVE_ADD_ORGANIZATION_ADDRESS = 'ERROR_RECEIVE_ADD_ORGANIZATION_ADDRESS',

  REQUEST_DELETE_ORGANIZATION_ADDRESS = 'REQUEST_DELETE_ORGANIZATION_ADDRESS',
  RECEIVE_DELETE_ORGANIZATION_ADDRESS = 'RECEIVE_DELETE_ORGANIZATION_ADDRESS',
  ERROR_RECEIVE_DELETE_ORGANIZATION_ADDRESS = 'ERROR_RECEIVE_DELETE_ORGANIZATION_ADDRESS',
}

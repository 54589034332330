<div class="filters"
    (click)="showFiltersModal($event)"
    (outsideClick)="clickOutsideModal($event)">
  <span [ngClass]="!isLoading && !!nrOfFilters ? 'filters-background' : 'filters-default'">
    <fa-icon [icon]="faFilter"></fa-icon>
    {{ filterText }}
  </span>
  <div class="filters__container-filter" *ngIf="toggleFiltersModal"
      (click)="emptyTemplateClick($event)">
    <div class="filters__container-filter-header">Filter fields</div>
    <p-divider></p-divider>
    <div class="filters_container-filter-form">
      <ng-container *ngFor="let filterArray of filterArrays; let i = index">
        <div class="filters__container-filter-form-content">
          <label *ngIf="i === 0" for="filter" class="filters__container-filter-form-content-label">Where</label>

          <div *ngIf="i > 0" class="filters__container-filter-form-content-left">
            <p-dropdown
              styleClass="filter-dropdowns filter-small"
              [options]="mainOperators"
              optionLabel="value"
              optionValue="id"
              [ngModel]="mainOperator"
              (onChange)="onChangeMainOperator($event.value)"
            >
              <ng-template let-selectedItem pTemplate="selectedItem">
                {{ selectedItem.value.toLowerCase() }}
              </ng-template>
              <ng-template let-item pTemplate="item">
                {{ item.value.toLowerCase() }}
              </ng-template>
            </p-dropdown>
          </div>
          <div id="filter" class="filters__container-filter-form-content-group">
            <p-dropdown
              [filter]="true"
              [resetFilterOnHide]="true"
              styleClass="filter-dropdowns"
              [options]="fieldsToFilterArray"
              optionLabel="header"
              optionValue="field"
              placeholder="Choose"
              [(ngModel)]="filterArray.key"
              (onChange)="onChangeKey($event.value, i)"
              optionDisabled="disabled"
            >
            </p-dropdown>

            <p-dropdown
              styleClass="filter-dropdowns"
              [options]="getOperators(filterArray.key)"
              optionLabel="value"
              optionValue="id"
              placeholder="Choose"
              [(ngModel)]="filterArray.operator"
              (onChange)="filterArray.value = []; onChangeOperator(filterArray.operator)"
              *ngIf="filterArray.key"
            >
            </p-dropdown>
            <div *ngIf="!filterArray.key">
              <input pInputText class="filter-input" type="text" [disabled]="true" />
            </div>

            <div *ngIf="filterArray.operator !== null">
              <div ngSwitch="{{ getFilterType(filterArray.key, filterArray.operator) }}">
                <div *ngSwitchCase="CommonConstants.filterTypes.ALPHANUMERIC">
                  <input
                    pInputText
                    type="text"
                    [(ngModel)]="filterArray.value[0]"
                    class="filter-input"
                    placeholder="value"
                    (change)="onChangeValue($event.target)"
                  />
                </div>
                <div *ngSwitchCase="CommonConstants.filterTypes.NUMERIC">
                  <input
                    pInputText
                    type="number"
                    [(ngModel)]="filterArray.value[0]"
                    class="filter-input"
                    placeholder="value"
                    (change)="onChangeValue($event.target)"
                  />
                </div>
                <div *ngSwitchCase="CommonConstants.filterTypes.SELECT">
                  <p-multiSelect
                    styleClass="filter-multiselect"
                    placeholder="Value"
                    [(ngModel)]="filterArray.value"
                    optionValue="id"
                    optionLabel="value"
                    [options]="getOptions(filterArray.key)"
                    (onChange)="onChangeValue($event)"
                  >
                  </p-multiSelect>
                </div>
                <div *ngSwitchCase="CommonConstants.filterTypes.DATE">
                  <p-calendar
                    placeholder="MM-DD-YYYY"
                    dateFormat="mm/dd/yy"
                    dataType="string"
                    [readonlyInput]="true"
                    [ngModel]="setDateModel(filterArray.value[0])"
                    (ngModelChange)="filterArray.value[0] = $event"
                    styleClass="filter-calendar"
                    (onSelect)="onChangeDate(i)"
                  >
                  </p-calendar>
                </div>
                <div *ngSwitchDefault>
                  <input pInputText class="filter-input" type="text" [disabled]="true" />
                </div>
              </div>
            </div>
            <div *ngIf="filterArray.operator === null">
              <input pInputText class="filter-input" type="text" [disabled]="true" />
            </div>
            <button
              class="filters__container-filter-form-content-group-button"
              type="button"
              (click)="onDeleteFilterItem(i)"
            >
              <i class="bi bi-x"></i>
            </button>
          </div>
        </div>
      </ng-container>

      <button
        type="button"
        class="filters__container-filter-form-add"
        [disabled]="!isAddFilterStateEnabled"
        (click)="onAddFilterItem()"
      >
        <i class="bi bi-plus-lg"></i>Add filter
      </button>
      <br />
      <button type="button" class="filters__container-filter-form-clear" (click)="onClearAllFilters()">
        Clear filters
      </button>
    </div>
  </div>
</div>

import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorDeleteItem, errorItemData, receiveItemData, receivePostPutItemData, requestItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { ITemplate } from '../../models/template.model';
import { TemplateActionTypes } from '../actions/template-action-types';
import { ITemplatesState, TemplatesState } from '../models/templates.model';

export function templatesReducer(
  lastState: ITemplatesState = new TemplatesState(),
  action: GenericAction<TemplateActionTypes, any>
): ITemplatesState {
  switch (action.type) {

    case TemplateActionTypes.REQUEST_TEMPLATES:
      return requestItemData<ITemplatesState, ITemplate>(lastState, ['templates']);
    case TemplateActionTypes.RECEIVE_TEMPLATES:
      return receiveItemData<ITemplatesState, ITemplate>(lastState, ['templates'], action.payload);
    case TemplateActionTypes.ERROR_RECEIVE_TEMPLATES:
      return errorItemData<ITemplatesState, ITemplate>(lastState, ['templates'], action.payload);

    case TemplateActionTypes.REQUEST_DELETE_TEMPLATE:
      return requestPostPutItemData<ITemplatesState, ITemplate>(lastState, ['templates'], action.payload);
    case TemplateActionTypes.RECEIVE_DELETE_TEMPLATE:
      return receivePostPutItemData<ITemplatesState, ITemplate>(lastState, ['templates'], action.payload);
    case TemplateActionTypes.ERROR_RECEIVE_DELETE_TEMPLATE:
      return errorDeleteItem<ITemplatesState, ITemplate>(lastState, ['templates'], action.payload);

    case TemplateActionTypes.REQUEST_UPDATE_TEMPLATE:
      return requestPostPutItemData<ITemplatesState, ITemplate>(lastState, ['templates'], action.payload);
    case TemplateActionTypes.RECEIVE_UPDATE_TEMPLATE:
      return receivePostPutItemData<ITemplatesState, ITemplate>(lastState, ['templates'], action.payload);
    case TemplateActionTypes.ERROR_RECEIVE_UPDATE_TEMPLATE:
      return errorDeleteItem<ITemplatesState, ITemplate>(lastState, ['templates'], action.payload);

    default:
      return lastState;
  }
}

import { IStoreApiItem, StoreApiItem } from "src/app/common/models/store-api-item.model";
import { IShareView, IShareViewEmail, IShareViewInvite } from "../../models/dashboard.model";


export interface IShareViewState {
  shareViews: IStoreApiItem<IShareView>;
  shareViewEmail: IStoreApiItem<IShareViewEmail>;
  shareViewInvite: IStoreApiItem<IShareViewInvite>;
}

export class ShareViewState implements IShareViewState {
  shareViews: IStoreApiItem<IShareView> = new StoreApiItem();
  shareViewEmail: IStoreApiItem<IShareViewEmail> = new StoreApiItem();
  shareViewInvite: IStoreApiItem<IShareViewInvite> = new StoreApiItem();
}

export enum AuthenticationActionTypes {
  REQUEST_LOGIN = 'REQUEST_LOGIN',
  RECEIVE_LOGIN = 'RECEIVE_LOGIN',
  ERROR_LOGIN = 'ERROR_LOGIN',
  RESET_LOGIN = 'RESET_LOGIN',

  REQUEST_SING_UP = 'REQUEST_SING_UP',
  RECEIVE_SING_UP = 'RECEIVE_SING_UP',
  ERROR_SING_UP = 'ERROR_SING_UP',
  RESET_SING_UP = 'RESET_SING_UP',

  REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD',
  RECEIVE_FORGOT_PASSWORD = 'RECEIVE_FORGOT_PASSWORD',
  ERROR_FORGOT_PASSWORD = 'ERROR_FORGOT_PASSWORD',
  RESET_FORGOT_PASSWORD = 'RESET_FORGOT_PASSWORD',

  REQUEST_RECOVER_PASSWORD = 'REQUEST_RECOVER_PASSWORD',
  RECEIVE_RECOVER_PASSWORD = 'RECEIVE_RECOVER_PASSWORD',
  ERROR_RECOVER_PASSWORD = 'ERROR_RECOVER_PASSWORD',
  RESET_RECOVER_PASSWORD = 'RESET_RECOVER_PASSWORD',

  REQUEST_USER_OPTIONS = 'REQUEST_USER_OPTIONS',
  RECEIVE_USER_OPTIONS = 'RECEIVE_USER_OPTIONS',
  ERROR_USER_OPTIONS = 'ERROR_USER_OPTIONS',
  RESET_USER_OPTIONS = 'RESET_USER_OPTIONS',

  REQUEST_COMPANY = 'REQUEST_COMPANY',
  RECEIVE_COMPANY = 'RECEIVE_COMPANY',
  ERROR_COMPANY = 'ERROR_COMPANY',
  RESET_COMPANY = 'RESET_COMPANY',

  REQUEST_ROLE = 'REQUEST_ROLE',
  RECEIVE_ROLE = 'RECEIVE_ROLE',
  ERROR_ROLE = 'ERROR_ROLE',
  RESET_ROLE = 'RESET_ROLE',
}

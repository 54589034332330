<p-dialog
  [(visible)]="showDialog"
  [style]="{ marginTop: '250px', width: '400px' }"
  [closeOnEscape]="false"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  appendTo="body"
  [maskStyleClass]="'dialog-mask'"
>
  <materlog-loader class="loader-center" *ngIf="isLoading"></materlog-loader>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>{{ getTitle() }}</span>
    </div>
    <button class="dialog-close" type="button" (click)="closeModal()"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="dialog__content">
      <div class="dialog__content-single">
        <label for="number">{{ isPO ? 'PO number' : 'Order number' }}</label>
        <input
          pInputText
          type="text"
          placeholder="Number"
          class="form-controls"
          id="number"
          name="number"
          [(ngModel)]="orderDoc.number"
          (keyup.enter)="savePO()"
        />
        <span *ngIf="numberError" class="textfield__error-message">Number can not be empty.</span>
      </div>
      <div class="dialog__content-single">
        <label *ngIf="!isPO" for="type">Order type</label>
        <p-dropdown
          *ngIf="!isPO"
          appendTo="body"
          [options]="docTypes"
          [(ngModel)]="orderDoc.document_type"
          id="type"
          optionValue="id"
          optionLabel="value"
          placeholder="Document type"
          name="type"
          (keyup.enter)="savePO()"
        >
        </p-dropdown>
      </div>
      <div class="dialog__content-single">
        <label for="date">{{ isPO ? 'PO date' : 'Order date' }}</label>
        <p-calendar
          appendTo="body"
          id="date"
          name="date"
          [(ngModel)]="orderDoc.order_document_date"
          [readonlyInput]="true"
          placeholder="MM/DD/YYYY"
          [showClear]="true"
          (keyup.enter)="savePO()"
        ></p-calendar>
      </div>
      <div class="dialog__content-single">
        <label>Attachments</label>
        <div>
          <label class="dialog__content-single-attachment" for="attachment">
            <i class="bi bi-paperclip"></i> Add attachment
            <input
              type="file"
              hidden
              id="attachment"
              name="attachment"
              accept=".pdf,.PDF,.doc,.DOC,.docx,.DOCX,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.bmp,.BMP,.eml,.EML,image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
              (change)="fileBrowseHandler($any($event.target).files)"
              multiple
            />
          </label>
          <div class="dialog__content-single-filename" *ngFor="let file of orderDoc.attachments; let i = index">
            <span>{{ file?.filename }}</span>
            <i *ngIf="file?.filename?.length > 1" class="bi bi-x-lg" (click)="deleteFile(i)"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="dialog-supplier__content-action">
      <button class="dialog-supplier__content-action-cancel" type="button" (click)="closeModal()">Cancel</button>
      <button class="dialog-supplier__content-action-button" type="submit" (click)="savePO()">Save</button>
    </div>
  </ng-template>
</p-dialog>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Action, StoreModule, ActionReducer, MetaReducer  } from '@ngrx/store';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
// import LogRocket from 'logrocket';
import { UserModule } from './../../user-settings/modules/user.module';
import { SharedModule } from 'src/app/common/modules/shared.module';
import { AppComponent } from './../components/app/app.component';
import { ContainerComponent } from '../components/container/container.component';
import { AuthenticationModule } from 'src/app/authentication/modules/authentication.module';
import { AppRoutingModule } from './app-routing.module';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { AuthenticationModuleGuard } from 'src/app/authentication/modules/authentication.module.guard';
import { PermissionsService } from '../services/permissions.service';
import { HeaderComponent } from '../components/header/header.component';
import { ErrorHandlerModule } from './error-handler.module';
import { HomePageComponent } from '../components/home-page/home-page.component';
import { JobsService } from './../../dashboard/services/job.service';
import { environment } from 'src/environments/environment';
import { TemplateModule } from 'src/app/templates/modules/template.module';
import { CompanyModule } from 'src/app/company-settings/modules/company.module';
import { SharedViewModule } from 'src/app/shared-view/modules/shared-view.module';
import { DashboardModule } from 'src/app/dashboard/modules/dashboard.module';
import { ReceiversPageModule } from 'src/app/receivers/modules/receivers-page.module';
import { GeoapifyGeocoderAutocompleteModule } from '@geoapify/angular-geocoder-autocomplete';
import { SuppliersPageModule } from 'src/app/suppliers/modules/suppliers-page.module';
import { SupplierCredentialsPageModule} from 'src/app/suppliers/modules/suppliers-credentials-page.module'
import {AngularEditorModule} from "@kolkov/angular-editor";
import {
  ManualCommunicationComponent
} from "../../dashboard/components/job-table-v2/manual-communication/manual-communication.component";
import {DynamicDialogModule} from "primeng/dynamicdialog";
import { reducers, metaReducers } from 'src/app/common/utils/reducer/reducer.utils';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// import { OrderUploadService } from 'src/app/dashboard/services/order-upload.service';


/*
const reduxMiddleware = LogRocket.reduxMiddleware();

export function logrocketMiddleware(reducer: (arg0: any, arg1: any) => any): ActionReducer<any, any> {
  let currentState: any;
  const fakeDispatch = reduxMiddleware({
    getState: () => currentState,
  })(() => {});

  return function (state, action) {
    const newState = reducer(state, action);
    currentState = state;
    fakeDispatch(action);
    return newState;
  };
}
*/

const socketConfig: SocketIoConfig = { url: environment.ENV.ASGI_URL, options: {} };


@NgModule({
  declarations: [AppComponent, ContainerComponent, HeaderComponent, HomePageComponent,ManualCommunicationComponent],
  imports: [
    BrowserModule,
    DashboardModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    DynamicDialogModule,
    FormsModule,
    SharedModule,
    AuthenticationModule,
    UserModule,
    CompanyModule,
    SharedViewModule,
    ReceiversPageModule,
    SuppliersPageModule,
    SupplierCredentialsPageModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    StoreModule.forRoot<IApplicationState, Action>({
      router: routerReducer,
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([]),
    HttpClientModule,
    AngularEditorModule,
    TemplateModule,
    StoreRouterConnectingModule.forRoot(),
    ErrorHandlerModule,
    BadgeModule,
    FontAwesomeModule,
    MessageModule,
    ToastModule,
    DividerModule,
    GeoapifyGeocoderAutocompleteModule.withConfig(environment.ENV.GEOAPIFY_API),
    SocketIoModule.forRoot(socketConfig),
  ],

  providers: [
    AuthenticationService,
    JobsService,
    HttpHelper,
    AuthenticationModuleGuard,
    PermissionsService,
    // OrderUploadService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { Component, OnInit, ViewChild, Renderer2, Input, ChangeDetectorRef } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { SelectedViewModel } from '../../models/selected-view.model';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { ElementRef, HostListener } from '@angular/core';
import { MailBoxCommunicationService } from '../../services/mailbox_comm.service';
import { selectComments, selectViews} from '../../state/selectors/dashboard.selector';
import { selectActiveJobs } from '../../state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { CommentsService } from '../../services/comment.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { MentionModule } from 'angular-mentions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { deepCopy } from 'src/app/common/utils/general';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { ActiveJobModel } from '../../models/active-job.model';
import { JobTableService } from '../../services/job-table.service';
import { initData } from '../mail-view/utils';
import { JobsService } from '../../services/job.service';
import { DashboardService } from '../../services/dashboard.service';
import { OrderService } from '../../services/order.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'submittals-export',
  templateUrl: './submittals-export.component.html',
  styleUrls: ['./submittals-export.component.css'],
})
export class ExportSubmittalsComponent implements OnInit {
 
  @Input() job: any = undefined;
  @Output() close = new EventEmitter<void>();
  @ViewChild('submittalsExportContent', { static: false }) submittalsExportContent!: ElementRef;
  @ViewChild('submittalsScroll', { static: false }) submittalsScroll!: ElementRef;

  currentTypes: any = [];
  allSuppliers: any = [];
  job_number: any = '';
  org_logo: any = '';

  ngOnInit() {
    this.getSubmittals();
  }

  constructor(
    private orderService: OrderService,
    private http: HttpClient,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
    ) {
      (window as any)['sexport'] = this;
  }

  data: any = undefined;
  acc: any = undefined;

  typeForItem(item: any, type: any) {
    return item.values.find((v: any) => v.type_id == type.id);
  }

  dateForItem(item: any, type: any) {
    let val = item.values.find((v: any) => v.type_id == type.id);
    if (!val) return ''
    return val.change_date;
  }

  notesForItem(item: any) {
    let notes = item.values.map((v: any) => v.notes);
    let allNotes: any = [];
    notes.forEach((n: any) => {
      if (!n) return;
      allNotes = allNotes.concat(n.split('\n'));
    })
    allNotes = allNotes.filter((n: any) => n);
    return allNotes;
  }

  colorForStatus(item: any, type: any) {
    let val = item.values.find((v: any) => v.type_id == type.id);
    switch (val.status) {
      case 1:
        return '#C5C5C5';
      case 2:
        return '#F1B73F';
      case 3:
        return '#5FC64C';
      default:
        return '#C5C5C5';
    }
  }

  onImagesUpdated(event: any) {}

  getImageDate(images: any) {
    return {value: images};
  }

  groupByField(data: any, field: any) {
    return data.reduce((acc: any, obj: any) => {
      const key = obj[field];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }
  
  sortSubmittals(result: any) {
    let data = result.data;
    let options = result.options;
    const groupedByOrderId = data.reduce((acc: any, obj: any) => {
      const key = obj.supplier_name;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    let groupedBySupplier = this.groupByField(data, 'supplier_name');
    let allSuppliers: any = [];
    Object.entries(groupedBySupplier).forEach(([supplier_name, orders]: [any, any]) => {
      let orderGroups = this.groupByField(orders, 'order_id');
      let allOrders: any = [];
      Object.entries(orderGroups).forEach(([order_id, items]: [any, any]) => {
        allOrders = allOrders.concat(items);
      });
      allSuppliers.push(allOrders);
    });
    this.allSuppliers = allSuppliers;
  }

  getSubmittals() {
    if (this.job) {
      this.orderService.getJobSubmittals({job: this.job.id}).subscribe((result: any) => {
        this.org_logo = result.logo;
        this.job_number = result.job_number;
        let data = result.data;
        let currentTypes = data.map((d: any) => (
          d.values.map((v: any) => v.type_id)
        )).flat();
        currentTypes = [...new Set(currentTypes)]
        this.data = data;
        let options = result.options;
        currentTypes = options.types.filter((t: any) => currentTypes.includes(t.id));
        currentTypes.forEach((c: any) => {
          c.name = c.name[0].toUpperCase() + c.name.slice(1,);
        })
        this.currentTypes = currentTypes;
        this.sortSubmittals(result);
      })
    }
  }

  exportSubmittalsToNewTab() {

  }

  closeDialog() {
    this.close.emit();
  }


  creatingPDF: boolean = false;
  downloadPDF(event: any)  {
    event.stopPropagation();
    this.creatingPDF = true;
    this.cdr.detectChanges();
    const content = this.submittalsExportContent.nativeElement;
    const subScroll = this.submittalsScroll.nativeElement;
    if (subScroll) {
      let too_large = '1000000px'
      subScroll.style.maxHeight = too_large;
      subScroll.style.maxWidth = too_large;
    }

    setTimeout(() => {
      html2canvas(content, { scale: 2, useCORS: true, logging: true }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('l', 'mm', 'a4');
        const margin = 10; // Add 20mm margin on each side
        let imgWidth = 300 - 2 * margin; // A4 width in landscape is 297mm
        const pageHeight = 210 - 2 * margin; // A4 height in landscape is 210mm
        let imgHeight = canvas.height * imgWidth / canvas.width; // Maintain aspect ratio
        let heightLeft = imgHeight;
  
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
        const scaleX = imgWidth / canvasWidth;
        const scaleY = pageHeight / canvasHeight;
        const scale = Math.min(scaleX, scaleY);

        imgWidth = canvasWidth * scale;
        imgHeight = canvasHeight * scale;

        let position = margin;
  
        // Add the image to the PDF
        pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
  
        // Add additional pages if necessary
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight + margin;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
  
        // Save the PDF
        pdf.save('download.pdf');
        subScroll.style.maxHeight = '72vh';
        subScroll.style.maxWidth = '88vw';
        this.creatingPDF = false;
      });      
    }, 10);

  }
}
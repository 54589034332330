<materlog-loader class="loader center" *ngIf="isLoading"></materlog-loader>

<ng-container *ngIf="!isNotValidLink && !isLoading">
  <header class="header">
    <div class="header__left">
      <fa-icon
        *ngIf="organization_logo === null || organization_logo === ''"
        class="building"
        [icon]="faBuilding"
      ></fa-icon>
      <img *ngIf="organization_logo !== null && organization_logo.length >= 1" [src]="organization_logo" />
    </div>
    <div class="header__center">{{ job_name }}</div>

    <div class="header__right" >
      <div (click)="redirectHome()" class="go-home">
        <img src="/assets/img/logo.svg" alt="Materlog logo" />
      </div>
      <div>
        <button (click)="exportGrid()" class="export-button">
          <i class="bi bi-download"></i>
        </button>
        <button (click)="printGrid()" class="print-button">
          <i class="bi bi-printer"></i>
        </button>
      </div>
      <div class="single-comment-textarea"
          contenteditable="true"
          [attr.data-placeholder]="getPlaceHolderText()"
          (input)="searchItems($event)">
      </div>
    </div>
  </header>
  <div class="view">
    <materlog-loader class="loader" *ngIf="isLoading"></materlog-loader>

    <ag-grid-angular
      style="width: 100%; height: 100%"
      class="ag-theme-balham ag-grid-table"
      [defaultColDef]="defaultColDef"
      [gridOptions]="gridOptions"
      [groupDefaultExpanded]="-1"
      (gridReady)="onGridReady($event)"
      [columnDefs]="columns"
      [rowGroupPanelShow]="'never'"
      (rowDataUpdated)="onRowDataUpdated($event)"
      [groupDisplayType]="'groupRows'"
      [groupHideOpenParents]="false"
      [columnTypes]="columnTypes"
      [rowModelType]="'clientSide'"
      [rowData]=jobs
      [getContextMenuItems]="getContextMenuItems"

      [suppressScrollOnNewData]="true"
      [stopEditingWhenCellsLoseFocus]="false"
      [rowSelection]="'single'"
      [animateRows]="false"
      [enableCellChangeFlash]="false"
      [rowBuffer]="20"
      [debounceVerticalScrollbar]="false"
      (cellValueChanged)="onCellValueChanged($event)"
      (sortChanged)="sortChanged($event)"
      [context]="dropdownOptions"
    ></ag-grid-angular>
  </div>
</ng-container>

<ng-container *ngIf="isNotValidLink">
  <div class="invalid">
    <img src="assets/img/logo.svg" alt="Materlog Logo" (click)="redirectHome()" />
    <h3>Sorry, the link you requested does not exist.</h3>
    <h5>Please confirm you have the correct URL.</h5>
    <p>
      If you continue to encounter issues, contact
      <a href="mailto:support@materlog.com" target="_blank">support@materlog.com</a>
      .
    </p>
  </div>
</ng-container>

<popup-comment
  *ngIf="showPopupComment"
  [rowNode]="currentRow"
  [formattedCell]="formattedCell"
  [mousePosition]="mousePosition"
  [orgUserEmails]="[]"
  [sharedView]="true"
  (closePopup)="showPopupComment = false"
></popup-comment>

import { HttpErrorResponse } from '@angular/common/http';

export interface IStoreApiList<T> {
  isLoading: boolean;
  data: T;
  errors: HttpErrorResponse;
}

export class StoreApiList<T> implements IStoreApiList<T> {
  isLoading: boolean = false;
  data: T = null as any;
  errors: HttpErrorResponse = null as any;
}

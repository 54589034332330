import { requestItemData, receiveItemData, errorItemData, requestPostPutItemData, receivePostPutItemData, errorPostPutItemData } from './../../../common/utils/reducer/reducer.utils';
import {ISuppliers, ISupplierContacts, ISupplierCredentials} from '../../models/dashboard.model';
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { GenericAction } from './../../../common/state/models/generic-action.model';

import { ISupplierCredentialsState, SupplierCredentialsState } from './../models/suppliersCredentials.model';


const resetSuppliersCredentialsCRUDState = (lastState: ISupplierCredentialsState): ISupplierCredentialsState => ({
  ...lastState,
  credentials: {
    ...lastState.credentials,
    errors: null as any,
    isSuccess: null as any,
  },
});

export function suppliersCredentialsReducer(
  lastState: ISupplierCredentialsState = new SupplierCredentialsState(),
  action: GenericAction<DashboardActionTypes, any>
): ISupplierCredentialsState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_SUPPLIER_CREDENTIALS:
      return requestItemData<ISupplierCredentialsState, ISupplierCredentials>(lastState, ['credentials']);
    case DashboardActionTypes.RECEIVE_SUPPLIER_CREDENTIALS:
      return receiveItemData<ISupplierCredentialsState, ISupplierCredentials>(lastState, ['credentials'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_SUPPLIER_CREDENTIALS:
      return errorItemData<ISupplierCredentialsState, ISupplierCredentials>(lastState, ['credentials'], action.payload);

    case DashboardActionTypes.REQUEST_ADD_SUPPLIER_CREDENTIALS:
      return requestPostPutItemData<ISupplierCredentialsState, ISupplierCredentials>(lastState, ['credentials'], action.payload);
    case DashboardActionTypes.RECEIVE_ADD_SUPPLIER_CREDENTIALS:
      return receivePostPutItemData<ISupplierCredentialsState, ISupplierCredentials>(lastState, ['credentials'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ADD_SUPPLIER_CREDENTIALS:
      return errorPostPutItemData<ISupplierCredentialsState, ISupplierCredentials>(lastState, ['credentials'], action.payload);


    case DashboardActionTypes.RESET_CRUD_SUPPLIER_CREDENTIALS_STATE:
      return resetSuppliersCredentialsCRUDState(lastState);
    default:
      return lastState;
  }
}

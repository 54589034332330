import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[nullValue]'
})
export class NullDefaultValueDirective {
  constructor(private ngControl: NgControl) { }

  @HostListener('change') onChange() {
    const currentValue = this.ngControl.value;
    if (currentValue === '') {
      this.ngControl.control?.setValue(null);
    }
  }

  @HostListener('keydown.enter') onEnter() {
    const currentValue = this.ngControl.value;
    if (currentValue === '') {
      this.ngControl.control?.setValue(null);
    }
  }
}

import { ChangeDetectorRef, Input, Output, EventEmitter} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Component, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { PayloadService } from "../../../services/payload.service";
import { HttpClient } from "@angular/common/http";
import { JobTableService } from "../../../services/job-table.service";
import { OptionsService } from 'src/app/dashboard/services/options.service';
import tippy from 'tippy.js';
import { DashboardRoutes } from 'src/app/dashboard/enums/dashboard-routes.enum';
import { FormsModule } from '@angular/forms';
import { ListboxModule } from 'primeng/listbox';
import { DialogModule } from 'primeng/dialog';
import { AddRoomModalComponent } from '../add-room-dialog/add-room-modal.component';
import { AddSupplierContactModalComponent } from '../../add-supplier-contact-modal/add-supplier-contact-modal.component';
import { MentionModule } from 'angular-mentions';
import { DashboardJobView } from 'src/app/dashboard/services/dashboard_job_view.service';


@Component({
  selector: 'popup-comment',
  templateUrl: './popup-comment.component.html',
  styleUrls: ['./popup-comment.component.sass'],
  imports: [CommonModule, FormsModule, ListboxModule, DialogModule,
            MentionModule, AddRoomModalComponent, AddSupplierContactModalComponent],
  standalone: true
})
export class PopupComment {
  private tippyInstance!: any;
  isOpen: boolean = false;
  
  @Input() cardView: boolean = false;
  @Input() rowNode!: any;
  @Input() formattedCell!: any;
  @Input() mousePosition = { x: 0, y: 0 };
  @Input() orgUserEmails!: any;
  @Input() sharedView: boolean = false;
  @Output() closePopup = new EventEmitter<void>();
  @ViewChild('content') content!: ElementRef;
  @ViewChild('commentTextarea') commentTextarea!: ElementRef;
  commentText: string = '';
  commentEmail: string = '';
  taggedUsers: any = [];
  beenMutated: boolean = false;
  observer!: any;

  constructor(
    private httpClient: HttpClient,
    private dashboardActions: DashboardActions,
    private jobTableService: JobTableService,
    private payloadService: PayloadService,
    readonly optionsService: OptionsService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    public router: Router,
    private djv: DashboardJobView
  ) {
  }

  ngAfterViewInit() {
    (window as any)['pop']= this;
    this.isOpen = true;
    this.cdr.detectChanges();

    this.commentTextarea.nativeElement.addEventListener('input', this.handleInput.bind(this));

    this.configureTippyInstance();
    this.tippyInstance.setContent(this.content.nativeElement);
    this.changeScrollBarDynamically();
  }

  changeScrollBarDynamically() {
    const targetNode = document.getElementById('comment-container-id');
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node: any) => {
          if (node instanceof HTMLElement) {
            const mentionMenu = node.querySelector('.mention-menu');
            if (mentionMenu) {
              mentionMenu.classList.add('custom-scrollbar');
              this.beenMutated = true;
            }
          }
        });
      });
      if (this.beenMutated) this.observer.disconnect();
    });
    if (targetNode) {
      this.observer.observe(targetNode, {
        childList: true,
        subtree: true
      });
    }
  }

  handleInput() {
    this.autoResize(this.commentTextarea.nativeElement);
  }

  getPlaceholderText() {
    return this.sharedView ? 'Add a comment...': 'Add a comment or add others with @...';
  }

  onMentionSelected(event: any) {
    if (event && event.label) {
      this.taggedUsers.push(event.label);
    }
  }

  autoResize(textarea: HTMLTextAreaElement) {
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  onComment() {
    this.destroyTippy();
    let final_users: any = [];
    this.taggedUsers.forEach((u: string) => {
      if (this.commentText.includes(`@${u}`)) {
        final_users.push(u);
      }
    })
    if (this.cardView) {
      let payload: any = {
        'order_id': this.rowNode.items[0].order_id,
        'description': this.commentText,
        'tagged': final_users,
        'field': this.rowNode.doc_string,
        'cell': '',
      };
      this.dashboardActions.requestAddComment(payload);
    } else {
      let row_data = this.rowNode.node.data;
      let row_id = row_data.id;
      if (row_data.has_subitems && row_data.has_subitems.item_id) {
        row_id = row_data.has_subitems.item_id;
      }
      let payload: any = {
        'item_id': row_id,
        'description': this.commentText,
        'tagged': final_users,
        'field': this.rowNode.column.colDef.headerName,
        'cell': this.formattedCell || '',
      };
      if (this.sharedView) {
        payload['email'] = this.commentEmail;
        payload['shared'] = true;
        payload['sharedUrl'] = window.location.href;
      }
      this.dashboardActions.requestAddComment(payload);
    }
    this.djv.request_metadata();
  }

  onCancel() {
    this.destroyTippy();
  }

  handleKeyDown(event: any) {
    if (event.key == 'Enter') {
      event.preventDefault();
      event.target.blur();
      return;
    }
  }

  updateCommentEmail(event: any) {
    this.commentEmail = event.target.innerText;
  }

  getPlaceholderEmailText() {
    return this.commentEmail ? '': 'Your email';
  }

  destroyTippy() {
    if (this.isOpen) {
      this.isOpen = false;
      this.closePopup.emit();
      this.tippyInstance.destroy();
    }
  }

  configureTippyInstance() {
    this.tippyInstance = tippy(this.content.nativeElement);
    this.tippyInstance.enable();
    this.tippyInstance.show();
    const y_add = 50;
    this.tippyInstance.setProps({
      trigger: 'manual',
      placement: 'bottom',
      getReferenceClientRect: () => ({
        width: 0,
        height: 0,
        top: this.mousePosition.y - y_add,
        bottom: this.mousePosition.y - y_add,
        left: this.mousePosition.x,
        right: this.mousePosition.x,
      }),
      arrow: true,
      interactive: true,
      hideOnClick: false,
      offset: 0,
      onClickOutside: (instance: any, event: any) => {
        this.destroyTippy();
      },
    });
  }
}
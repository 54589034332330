import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IOrganizationAddresses } from '../../models/company.model';
import { OrganizationService } from '../../services/organization.service';
import { CompanyActionTypes } from '../actions/company-action-types';
import { ErrorReceiveOrganization, ErrorReceiveUpdateOrganization, ReceiveOrganization, ReceiveUpdateOrganization } from '../actions/company-action-types-creators/organization-action-types-creators';
import { ErrorReceiveAddOrganizationAddress, ErrorReceiveDeleteOrganizationAddress, ErrorReceiveOrganizationAddresses, ErrorReceiveUpdateOrganizationAddress, ReceiveAddOrganizationAddress, ReceiveDeleteOrganizationAddress, ReceiveOrganizationAddresses, ReceiveUpdateOrganizationAddress } from '../actions/company-action-types-creators/organization-addresses-action-types-creators';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private organizationService: OrganizationService
  ) { }

  requestOrganization$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActionTypes.REQUEST_ORGANIZATION),
      switchMap(() =>
        this.organizationService.getOrganization().pipe(
          map(
            (organization: any) => new ReceiveOrganization(organization)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveOrganization(error)))
        )
      )
    );
  });

  requestUpdateOrganization$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActionTypes.REQUEST_UPDATE_ORGANIZATION),
      switchMap((organization: any) =>
        this.organizationService.updateOrganization(organization).pipe(
          map((organization: any) => new ReceiveUpdateOrganization(organization)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateOrganization(error)))
        )
      )
    );
  });

  requestOrganizationAddresses$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActionTypes.REQUEST_ORGANIZATION_ADDRESSES),
      switchMap(() =>
        this.organizationService.getOrganizationAddresses().pipe(
          map(
            (organizationAddresses: any) => new ReceiveOrganizationAddresses(organizationAddresses)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveOrganizationAddresses(error)))
        )
      )
    );
  });

  requestAddOrganizationAddress$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActionTypes.REQUEST_ADD_ORGANIZATION_ADDRESS),
      switchMap((newAddress: IOrganizationAddresses) =>
        this.organizationService.addOrganizationAddress(newAddress).pipe(
          map((organizationAddress: any) => new ReceiveAddOrganizationAddress(organizationAddress)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddOrganizationAddress(error)))
        )
      )
    );
  });

  requestDeleteOrganizationAddress$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActionTypes.REQUEST_DELETE_ORGANIZATION_ADDRESS),
      switchMap((addressId: any) =>
        this.organizationService.deleteOrganizationAddress(addressId).pipe(
          map((organizationAddress: any) => new ReceiveDeleteOrganizationAddress(organizationAddress)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteOrganizationAddress(error)))
        )
      )
    );
  });

  requestUpdateOrganizationAddress$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActionTypes.REQUEST_UPDATE_ORGANIZATION_ADDRESS),
      switchMap((addressId: any) =>
        this.organizationService.updateOrganizationAddress(addressId).pipe(
          map((organizationAddress: any) => new ReceiveUpdateOrganizationAddress(organizationAddress)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateOrganizationAddress(error)))
        )
      )
    );
  });
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FormsModule } from '@angular/forms';

import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { ConfirmationService, MessageService } from 'primeng/api';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CompanySettingsComponent } from '../components/company-settings/company-settings.component';
import { CompanyManagementComponent } from '../components/company-management/company-management.component';
import { companyReducer } from '../state/reducers/company.reducer';
import { CompanyEffects } from '../state/effects/company.effects';

import { GeoapifyGeocoderAutocompleteModule } from '@geoapify/angular-geocoder-autocomplete';
import { environment } from 'src/environments/environment';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [
    // CompanySettingsComponent,
    // CompanyManagementComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DividerModule,
    StoreModule.forFeature('company', companyReducer),
    EffectsModule.forFeature([CompanyEffects]),
    FontAwesomeModule,
    DialogModule,
    ConfirmDialogModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    DropdownModule,
    CheckboxModule,
    InputTextModule,
    GeoapifyGeocoderAutocompleteModule.withConfig(environment.ENV.GEOAPIFY_API)

  ],
  providers: [ConfirmationService, MessageService]
})
export class CompanyModule { }

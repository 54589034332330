<div class="main-container">
  <div class="submittals-options">
    <div *ngIf="!creatingPDF" class="close-submittals"
          style="padding-right: 14px" (click)="downloadPDF($event)">
      <i class="bi bi-download"></i>
    </div>
    <div *ngIf="creatingPDF" class="spinner-border spinner-border-sm download-spinner" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="close-submittals"
          style="padding-right: 20px" (click)="closeDialog()">
      <i class="bi bi-x-lg"></i>
    </div>
  </div>
  <div class="export-container" #submittalsExportContent>
    <div class="export-header">
      <div class="close-and-header">
        <div class="export-header-text submittal-header">
          Submittal Report
        </div>
        <img [src]="org_logo" class="circular-img" crossorigin="anonymous"/>
      </div>
      <div class="export-header-text">
        <div class="export-header-type">
          Job name
        </div>
        <div class="export-header-text job-text">
          {{job.name}}
        </div>
      </div>
      <div class="export-header-text">
        <div class="export-header-type">
          Job #
        </div>
        <div class="export-header-text job-text">
          {{job_number ? job_number : '--'}}
        </div>
      </div>
    </div>
    <div class="export-submittals-container custom-scrollbar-close" #submittalsScroll>
      <div class="export-descriptions pinned-column">
        <div class="item-sku-width current-types">
          ITEM SPEC / PO #
        </div>
        <div class="item-name-width current-types">
          ITEM
        </div>
        <div class="item-submittal-width submittal-overflow current-types" *ngFor="let t of currentTypes">
          {{t.name.toUpperCase()}}
        </div>
      </div>
      <div class="order-submittals" *ngFor="let supplier of allSuppliers">
        <div class="order-header">
          {{ supplier[0].supplier_name }}
        </div>
        <div *ngFor="let item of supplier; let isLast=last" class="item-values"
            [ngClass]="isLast ? '': 'non-last-item-value'">
          <div class="item-inner-values">
            <div class="item-sku item-sku-width submittal-overflow">
              {{item.item_spec}}
            </div>
            <div class="item-sku item-name-width submittal-overflow">
              {{item.item_name}}
            </div>
            <div class="type-container">
              <div class="type-holder item-submittal-width submittal-overflow" *ngFor="let t of currentTypes">
                <div *ngIf="typeForItem(item, t)" class="submittal-option">
                  <i class="bi bi-bullseye" style="padding-right: 8px"
                      [ngStyle]="{'color': colorForStatus(item, t)}"></i>
                  <div class="status-date">
                    {{ dateForItem(item, t) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order-info">
            <div class="type-holder item-sku-width submittal-overflow po-number">
              {{item.order_name}}
            </div>
            <div class="item-images custom-scrollbar-close">
              <div *ngFor="let img of item.images">
                <img [src]="img.image" class="square-img" crossorigin="anonymous"/>
              </div>
            </div>
            <div class="item-notes">
              <div class="notes-header po-number">
                NOTES
              </div>
              <div class="notes-container">
                <div class="single-line-notes" *ngFor="let note of notesForItem(item)">
                  {{note}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

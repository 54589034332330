<div class="filters">
  <span
    id="resize-modal-button"
    (click)="showFieldsModal($event)"
    [ngClass]="!!imageNotSmall ? 'filters-background' : 'filters-default'"
  >
    <fa-icon [icon]="faResize"></fa-icon>
    {{resizeText}}

  </span>

  <div
    class="filters__container-fields"
    *ngIf="toggleFieldsModal"
    (outsideClick)="clickOutsideModal($event)"
    outsideClick
  >
    <div class="filters__container-fields-header">Resize Images</div>
    <p-divider></p-divider>
    <div class="flex flex-row justify-between items-baseline">
      <div class="flex flex-col m-2 items-center">
        <fa-icon  [icon]="faImageOutline" class="text-xl text-gray-200 m-4"></fa-icon>
        <div class="flex items-center">
          <input id="image-size-choice-small" name="image-size-choice" type="radio" [checked]="isChosen('small')"
                (change)="image_size_change($event)" ng-ch class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" >
          <label for="image-size-choice-small" class="ml-2 block text-sm font-medium leading-6 text-gray-900">Small</label>
        </div>
      </div>
      <div class="flex flex-col m-2 items-center">
        <fa-icon [icon]="faImageOutline" class="text-4xl text-gray-200 m-4"></fa-icon>
        <div class="flex items-center">
          <input id="image-size-choice-medium" name="image-size-choice" type="radio" [checked]="isChosen('medium')" 
                 (change)="image_size_change($event)" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" >
          <label for="image-size-choice-medium" class="ml-2 block text-sm font-medium leading-6 text-gray-900">Medium</label>
        </div>

      </div>
      <div class="flex flex-col m-2 items-center">
        <fa-icon [icon]="faImageOutline" class="text-6xl text-gray-200 m-4"></fa-icon>
        <div class="flex items-center">
          <input id="image-size-choice-large" name="image-size-choice" type="radio" [checked]="isChosen('large')"
                 (change)="image_size_change($event)" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" >
          <label for="image-size-choice-large" class="ml-2 block text-sm font-medium leading-6 text-gray-900">Large</label>
        </div>
      </div>
    </div>

    <div class="filters__container-fields-footer">
    </div>
    <div class="filters__container-fields-container">
     <div>
     </div>
    </div>
  </div>
</div>


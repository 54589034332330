export class HistoryModel {

  static fromJSON(object: any): HistoryModel {
    return new HistoryModel(
      object.id,
      object.fields_changed,
    )
  }

  constructor(
    readonly id: string,
    readonly fields_changed: [],
  ) { }
}

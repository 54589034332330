<p-dropdown
  appendTo="body"
  placeholder="Select"
  [showClear]="true"
  [options]="params.options[params.colDef.field]"
  [(ngModel)]="params.value"
  name="dropdown"
  [optionValue]="params.optionValue"
  [optionLabel]="params.optionLabel"
  [filter]="true"
  panelStyleClass="ag-custom-component-popup"
  (onChange)="onChange()"
>
  <ng-template pTemplate="footer" *ngIf="params.addItemOptions.visible">
    <button
      pButton
      type="button"
      [label]="params.addItemOptions.footerText"
      class="p-button-text p-button-plain"
      (click)="onOpenModal()"
    ></button>
  </ng-template>
</p-dropdown>

<p-dialog
  [(visible)]="addItemModal"
  [style]="{ marginTop: '150px', width: '540px' }"
  [closeOnEscape]="false"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>{{ params.addItemOptions.footerText.substring(1) }}</span>
    </div>
    <button class="dialog-close" type="button" (click)="closeModal()"><i class="bi bi-x-lg"></i></button>
  </ng-template>

  <materlog-add-supplier-contact-modal
    *ngIf="params.colDef.field === 'supplier_contact_id'"
    [resetContact]="addItemModal"
    [contactRoles]="params.options['supplier_contact_role']"
    [supplierList]="params.options['supplier_id']"
    [supplierId]="params.data.supplier_id"
    (supplierContactFormChange)="supplierContactFormChangeEvent($event); closeModalAndSave()"
  ></materlog-add-supplier-contact-modal>

  <materlog-add-room-modal
    [params]="params"
    *ngIf="params.colDef.field === 'room_id'"
    (addRoom)="closeModalAndSave()"
  ></materlog-add-room-modal>
</p-dialog>


  <p-dialog
    [(visible)]="showDialog"
    [modal]="true"
    [resizable]="false"
    [draggable]="false"
    [closable]="false"
    [closeOnEscape]="false"
    [position]="'top'"
    [style]="{ marginTop: '50px', width: '688px' }"
    [maskStyleClass]="'dialog-mask'"
  >
    <materlog-loader class="loader-center" *ngIf="isLoading"></materlog-loader>
    <ng-template pTemplate="header">
      <div class="dialog__header">
        <span>Add items</span>
      </div>
      <button class="dialog-close" type="button" (click)="closeDialog()"><i class="bi bi-x-lg"></i></button>
    </ng-template>
    <div class="container">
      <div *ngIf="!fromOrder">
        <div class="container__item">
          <label [ngClass]="isSubmitted && isItemOrderId ? 'warning' : null" class="container__item-label" for="po"
            >PO</label
          >
          <p-dropdown
            appendTo="body"
            [options]="poList"
            [(ngModel)]="po"
            id="po"
            [filter]="true"
            optionValue="id"
            optionLabel="number"
            placeholder="PO"
            name="po"
            [showClear]="true"
            (ngModelChange)="onSelectPo($event)"
            (onClear)="onClearPo()"
          >
          </p-dropdown>
        </div>
        <div class="container__item">
          <div></div>
          <div class="and">and / or</div>
        </div>
        <div class="container__item">
          <label [ngClass]="isSubmitted && isItemOrderId ? 'warning' : null" class="container__item-label" for="order"
            >Order*</label
          >
          <div>
            <p-dropdown
              appendTo="body"
              [options]="orderList"
              [(ngModel)]="order"
              id="order"
              [filter]="true"
              optionValue="id"
              optionLabel="number"
              placeholder="Order"
              name="order"
              [showClear]="true"
              (ngModelChange)="onSelectOrder($event)"
              (onClear)="onClearOrder()"
              [style]="{'max-width':'415px'}"
            >
            </p-dropdown>
            <p *ngIf="isSubmitted && isItemOrderId" class="warning-text">Please select a PO or Order.</p>
          </div>
        </div>
        <p-divider></p-divider>
      </div>

      <div class="container__item">
        <label [ngClass]="isSubmitted && isItemName ? 'warning' : null" class="container__item-label" for="name"
          >Item name*</label
        >
        <div>
          <input
            class="form-controls"
            type="text"
            id="name"
            name="name"
            [(ngModel)]="addItemForm.name"
            required
            placeholder="Item name"
          />
          <p *ngIf="isSubmitted && isItemName" class="warning-text">Item name is required.</p>
        </div>
      </div>
      <div class="container__item">
        <label class="container__item-label" for="description">Description</label>
        <textarea
          [rows]="2"
          name="description"
          id="description"
          pInputTextarea
          [autoResize]="true"
          [(ngModel)]="addItemForm.description"
          placeholder="Description"
        ></textarea>
      </div>
      <div class="container__item">
        <label class="container__item-label">Image</label>
        <div class="container__item-rows">
          <label for="image">
            <i class="bi bi-paperclip"></i> Attach Image
            <input
              type="file"
              hidden
              id="image"
              name="image"
              accept=".png, .jpeg, .jpg, .jfif, .pjpeg, .pjp, .svg, .webp, .bmp"
              (change)="fileBrowseHandler($any($event.target).files)"
              multiple
            />
          </label>
          <div class="container__item-rows-filename" *ngFor="let img of addItemForm?.images; let i = index">
            <span>{{ img?.image_name }}</span>
            <i *ngIf="img.image_name.length > 1" class="bi bi-x-lg" (click)="deleteFile(i)"></i>
          </div>
        </div>
      </div>
      <div class="container__item">
        <label [ngClass]="wrongQuantity ? 'warning' : null" class="container__item-label" for="quantity">Qty</label>
        <div>
          <input
            class="form-controls"
            type="number"
            id="quantity"
            name="quantity"
            [(ngModel)]="addItemForm.quantity"
            placeholder="Quantity"
            maxlength="10"
          />
          <p *ngIf="wrongQuantity" class="warning-text">Quantity must be a number.</p>
        </div>
      </div>
      <div class="container__item">
        <label class="container__item-label" for="sku">SKU</label>
        <input class="form-controls" type="text" id="sku" name="sku" [(ngModel)]="addItemForm.sku" placeholder="SKU" />
      </div>
      <div class="container__item">
        <label class="container__item-label" for="link_to_material">URL</label>
        <textarea
          [rows]="1"
          name="link_to_material"
          id="link_to_material"
          pInputTextarea
          [autoResize]="true"
          [(ngModel)]="addItemForm.link_to_material"
          placeholder="URL"
        ></textarea>
      </div>
      <p-divider></p-divider>

      <div class="container__item">
        <label class="container__item-label" for="category">Category</label>
        <p-dropdown
          appendTo="body"
          [options]="categoryList"
          [(ngModel)]="addItemForm.category"
          id="category"
          optionValue="id"
          optionLabel="value"
          placeholder="Category"
          name="category"
        >
          <ng-template let-item pTemplate="selectedItem">
            <div [ngClass]="{ 'dropdown-not-selected-option': item.value === 'Not selected' }">
              {{ item.value }}
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="container__item">
        <label class="container__item-label" for="room">Room</label>
        <p-dropdown
          appendTo="body"
          [options]="roomList"
          [filter]="true"
          [(ngModel)]="addItemForm.room_id"
          id="room"
          optionValue="id"
          optionLabel="value"
          placeholder="Not selected"
          name="room"
        >
          <ng-template let-item pTemplate="selectedItem">
            <div [ngClass]="{ 'dropdown-not-selected-option': item.value === 'Not selected' }">
              {{ item.value }}
            </div>
          </ng-template>
          <ng-template pTemplate="footer">
            <button
              pButton
              type="button"
              label="+ Add new room"
              class="p-button-text p-button-plain"
              (click)="onOpenAddRoomModal()"
            ></button>
          </ng-template>
        </p-dropdown>
      </div>
      <!--
      <div class="container__item">
        <label class="container__item-label" for="floor">Floor</label>
        <p-dropdown
          appendTo="body"
          [options]="floorList"
          [(ngModel)]="addItemForm.floor"
          id="floor"
          optionValue="id"
          optionLabel="value"
          placeholder="Floor"
          name="floor"
        >
          <ng-template let-item pTemplate="selectedItem">
            <div [ngClass]="{ 'dropdown-not-selected-option': item.value === 'Not selected' }">
              {{ item.value }}
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      -->
      <p-divider></p-divider>

      <div class="container__item">
        <label class="container__item-label" for="status">Status</label>
        <p-dropdown
          appendTo="body"
          [options]="statusList"
          [(ngModel)]="addItemForm.status"
          id="status"
          optionValue="id"
          optionLabel="value"
          placeholder="Status"
          name="status"
        >
          <ng-template let-item pTemplate="selectedItem">
            <div [ngClass]="{ 'dropdown-not-selected-option': item.value === 'Not selected' }">
              {{ item.value }}
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="container__item">
        <label class="container__item-label">In stock date</label>
        <p-calendar
          appendTo="body"
          id="in_stock_date"
          [maxDate]="maxDateCompletion"
          name="in_stock_date"
          [(ngModel)]="addItemForm.in_stock_date"
          (ngModelChange)="checkDates()"
          [readonlyInput]="true"
          placeholder="MM/DD/YYYY"
          [showClear]="true"
        ></p-calendar>
      </div>
      <div class="container__item">
        <label class="container__item-label" for="ship_date">Ship date</label>
        <p-calendar
          appendTo="body"
          [minDate]="minDateShip"
          [maxDate]="maxDateShip"
          id="ship_date"
          name="ship_date"
          [(ngModel)]="addItemForm.ship_date"
          (ngModelChange)="checkDates()"
          [readonlyInput]="true"
          placeholder="MM/DD/YYYY"
          [showClear]="true"
        ></p-calendar>
      </div>
      <div class="container__item">
        <label class="container__item-label" for="delivery_date">Delivery date</label>
        <p-calendar
          appendTo="body"
          [minDate]="minDateDelivery"
          id="delivery_date"
          name="delivery_date"
          [(ngModel)]="addItemForm.delivery_date"
          (ngModelChange)="checkDates()"
          [readonlyInput]="true"
          placeholder="MM/DD/YYYY"
          [showClear]="true"
        ></p-calendar>
      </div>
      <div class="container__item">
        <label class="container__item-label" for="delivery_method">Delivery method</label>
        <p-dropdown
          appendTo="body"
          [options]="deliveryList"
          [(ngModel)]="addItemForm.delivery_method"
          id="delivery_method"
          optionValue="id"
          optionLabel="value"
          placeholder="Delivery"
          name="delivery_method"
        >
          <ng-template let-item pTemplate="selectedItem">
            <div [ngClass]="{ 'dropdown-not-selected-option': item.value === 'Not selected' }">
              {{ item.value }}
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="container__item">
        <label class="container__item-label" for="tracking_info">Tracking info</label>
        <textarea
          [rows]="1"
          name="tracking_info"
          id="tracking_info"
          pInputTextarea
          [autoResize]="true"
          [(ngModel)]="addItemForm.tracking_info"
          placeholder="Tracking info"
        ></textarea>
      </div>

      <div class="container__item">
        <label class="container__item-label" for="item_com_selection">COM</label>
      <p-dropdown
        appendTo="body"
        [options]="comSelection"
        [(ngModel)]="addItemForm.item_com_selection"
        id="item_com_selection"
        optionValue="id"
        optionLabel="value"
        placeholder="Com"
        name="item_com_selection"
        [showClear]="true"
      >
      </p-dropdown>
        </div>

      <p-divider></p-divider>
      <div class="container__item">
        <label class="container__item-label" for="notes">Notes</label>
        <textarea
          [rows]="2"
          name="notes"
          id="notes"
          pInputTextarea
          [autoResize]="true"
          [(ngModel)]="addItemForm.notes"
          placeholder="Notes"
        ></textarea>
      </div>
      <p-divider type="dashed"></p-divider>
      <div class="container__item">
        <label class="container__item-label" for="items">Items in order</label>
        <div>
          <button class="container__item-button" type="button" (click)="addItem()">
            <i class="bi bi-plus-lg"></i>{{ !edit ? 'Add another item' : 'Save and add another item' }}
          </button>
          <div class="container__item-box" *ngIf="itemList != null && itemList?.length">
            <ng-container *ngFor="let item of itemList; let i = index">
              <div class="container__item-box-single">
                <div class="container__item-box-single-left"></div>
                <div class="container__item-box-single-right" (click)="selectItem(item)">
                  <p class="item-title">{{ item.name }}</p>
                  <p class="item-quantity">Qty:{{ item.quantity }}</p>
                </div>
                <div class="container__item-box-single-delete" (click)="openDeleteDialog(item.id)">
                  <i class="bi bi-x-lg"></i>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="dialog-supplier__content-action">
        <button
          class="dialog-supplier__content-action-button mt-4"
          type="submit"
          [disabled]="isLoading"
          (click)="onSave()"
        >
          Save
        </button>
      </div>
    </ng-template>
  </p-dialog>

<p-toast position="top-center" key="error"></p-toast>
<p-toast position="top-center" key="success"></p-toast>

<p-dialog
  [(visible)]="showDeleteDialog"
  [style]="{ marginTop: '250px', width: '450px' }"
  [closeOnEscape]="false"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>Delete item</span>
    </div>
    <button class="dialog-close" type="button" (click)="closeDeleteDialog()">
      <i class="bi bi-x-lg"></i>
    </button>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="dialog__content">
      <span class="dialog__content-info">
        Are you sure you want to delete this item? Deleting the item means this record will no longer show up for anyone
        at your company and will no longer be tracked. This cannot be undone.
      </span>

      <div class="dialog__content-radio" *ngFor="let reason of reason_to_not_display?.slice(1)">
        <p-radioButton
          name="reason"
          [value]="reason.id"
          [label]="reason.value"
          [(ngModel)]="reasonToDelete"
        ></p-radioButton>
      </div>

      <textarea
        *ngIf="reasonToDelete == 3"
        class="dialog__content-input"
        pInputTextarea
        name="reasonToDeleteText"
        [(ngModel)]="reasonToDeleteText"
        placeholder="Please tell us why ..."
        maxlength="250"
        minlength="1"
      ></textarea>
      <span class="textfield__error-message" *ngIf="isDeleteSubmited && isDeleteError">Please provide a reason</span>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="dialog-supplier__content-action">
      <button class="dialog-supplier__content-action-cancel" type="button" (click)="closeDeleteDialog()">Cancel</button>
      <button class="dialog-supplier__content-action-button" type="button" (click)="deleteItem(deleteId)">
        Delete
      </button>
    </div>
  </ng-template>
</p-dialog>

<form>
  <p-dialog
    [(visible)]="addRoomModal"
    [modal]="true"
    [resizable]="false"
    [draggable]="false"
    [closable]="false"
    [closeOnEscape]="false"
    [position]="'top'"
    [style]="{ marginTop: '150px', width: '588px' }"
    [maskStyleClass]="'dialog-mask'"
  >
    <ng-template pTemplate="header">
      <div class="dialog__header">
        <span> Add new room </span>
      </div>
      <button class="dialog-close" type="button" (click)="onCloseAddRoomModal()">
        <i class="bi bi-x-lg"></i>
      </button>
    </ng-template>
    <ng-template pTemplate="content">
      <input
        pInputText
        nullValue
        type="text"
        name="addRoomName"
        class="form-controls"
        placeholder="Name"
        [(ngModel)]="roomName"
      />
      <div class="textfield__error-message" *ngIf="isAddRoomNameSubmitted && !roomName?.trim()">Add a room name</div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button
        pButton
        type="submit"
        label="Save"
        class="button-primary p-button-raised mt-4"
        (click)="onSaveRoomName()"
      ></button
    ></ng-template>
  </p-dialog>
</form>

import { MemoizedSelector, DefaultProjectorFn, createSelector } from '@ngrx/store';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { ApplicationState, IApplicationState } from 'src/app/common/state/models/app.state.model';
import { ITemplate } from '../../models/template.model';
import { ITemplateState } from '../models/template.model';

const templateState = (state: IApplicationState) => state.template;

export const selectTemplates: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<ITemplate>,
  DefaultProjectorFn<IStoreApiItem<ITemplate>>
> = createSelector(templateState, (state: ITemplateState | any) => state.templates.templates);

import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ITemplate } from 'src/app/templates/models/template.model';
import { TemplateActionTypes } from '../template-action-types';

export class RequestTemplates implements Action {
  readonly type: TemplateActionTypes.REQUEST_TEMPLATES = TemplateActionTypes.REQUEST_TEMPLATES;

  constructor(
    public payload: string) { }
}
export class ReceiveTemplates implements Action {
  readonly type: TemplateActionTypes.RECEIVE_TEMPLATES = TemplateActionTypes.RECEIVE_TEMPLATES;

  constructor(
    public payload: ITemplate) { }
}
export class ErrorReceiveTemplates implements Action {
  readonly type: TemplateActionTypes.ERROR_RECEIVE_TEMPLATES = TemplateActionTypes.ERROR_RECEIVE_TEMPLATES;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestDeleteTemplate implements Action {
  readonly type: TemplateActionTypes.REQUEST_DELETE_TEMPLATE = TemplateActionTypes.REQUEST_DELETE_TEMPLATE;

  constructor(
    public payload: ITemplate) { }
}

export class ReceiveDeleteTemplate implements Action {
  readonly type: TemplateActionTypes.RECEIVE_DELETE_TEMPLATE = TemplateActionTypes.RECEIVE_DELETE_TEMPLATE;

  constructor(
    public payload: ITemplate) { }
}
export class ErrorReceiveDeleteTemplate implements Action {
  readonly type: TemplateActionTypes.ERROR_RECEIVE_DELETE_TEMPLATE = TemplateActionTypes.ERROR_RECEIVE_DELETE_TEMPLATE;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestUpdateTemplate implements Action {
  readonly type: TemplateActionTypes.REQUEST_UPDATE_TEMPLATE = TemplateActionTypes.REQUEST_UPDATE_TEMPLATE;

  constructor(
    public payload: ITemplate) { }
}

export class ReceiveUpdateTemplate implements Action {
  readonly type: TemplateActionTypes.RECEIVE_UPDATE_TEMPLATE = TemplateActionTypes.RECEIVE_UPDATE_TEMPLATE;

  constructor(
    public payload: ITemplate) { }
}
export class ErrorReceiveUpdateTemplate implements Action {
  readonly type: TemplateActionTypes.ERROR_RECEIVE_UPDATE_TEMPLATE = TemplateActionTypes.ERROR_RECEIVE_UPDATE_TEMPLATE;

  constructor(
    public payload: HttpErrorResponse) { }
}

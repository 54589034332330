import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";

import { ICellEditorAngularComp } from "ag-grid-angular";

@Component({
  selector: 'calendar-date-cell-editor',
  templateUrl: "calendar-date.component.html"
})
export class CalendarDateEditor implements ICellEditorAngularComp {
  public params: any;

  constructor(
    private datePipe: DatePipe,
  ) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  getValue(): any {
    if(this.params.value instanceof Date) {
      return this.datePipe.transform(this.params.value, 'yyyy-MM-dd')
      //return this.params.value;
    }else{
      return this.params.value;
    }
  }

  onChangeDate(): void {
    this.params.stopEditing();
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { PayloadService } from '../../services/payload.service';

@Component({
  selector: 'history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.sass'],
})
export class HistoryComponent extends OnDestroyMixin() implements OnChanges {
  @Input() selectedView?: any;
  @Input() dropdownOptions!: any;
  @Input() isLoading!: boolean;

  @Output() onUpdateLoadingOverlay: EventEmitter<any> = new EventEmitter();

  faClockRotateLeft = faClockRotateLeft;
  toggleHistoryModal: boolean = false;
  viewHistoryOption: any;

  constructor(
    private dashboardActions: DashboardActions,
    private payloadService: PayloadService,
  ) {
    super();
  }

  ngOnChanges(changes: any): void {
    if ('selectedView' in changes) {
      this.viewHistoryOption = localStorage.getItem('history') || 0;
    }
  }

  clickOutsideModal(): void {
    this.toggleHistoryModal = false;
  }

  showHistoryModal(event: any): void {
    event.preventDefault();
    this.toggleHistoryModal = !this.toggleHistoryModal;
  }

  onClearHistory(): void {
    localStorage.setItem('history', '0');
    this.viewHistoryOption = 0;
    this.dashboardActions.requestViews({ ...this.payloadService.setViewsPayload() });
    this.onUpdateLoadingOverlay.emit();
  }

  emitUpdateHistory(event: any): void {
    localStorage.setItem('history', event);
    this.dashboardActions.requestViews({ ...this.payloadService.setViewsPayload() });
    this.onUpdateLoadingOverlay.emit();
  }
}

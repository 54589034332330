import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IActiveOrderList } from 'src/app/dashboard/models/dashboard.model';
import { DashboardActionTypes } from '../dashboard-action-types';


export class RequestActiveOrders implements Action {
  readonly type: DashboardActionTypes.REQUEST_ACTIVE_ORDERS = DashboardActionTypes.REQUEST_ACTIVE_ORDERS;

  constructor(
    public payload?: IActiveOrderList) { }
}
export class ReceiveActiveOrders implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ACTIVE_ORDERS = DashboardActionTypes.RECEIVE_ACTIVE_ORDERS;

  constructor(
    public payload?: IActiveOrderList) { }
}
export class ErrorReceiveActiveOrders implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ACTIVE_ORDERS = DashboardActionTypes.ERROR_RECEIVE_ACTIVE_ORDERS;

  constructor(
    public payload?: HttpErrorResponse) { }
}


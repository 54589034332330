import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ITemplateState } from '../models/template.model';
import { RequestDeleteTemplate, RequestTemplates, RequestUpdateTemplate } from './template-action-type-creators/template-action-types.creators';

@Injectable({ providedIn: 'root' })
export class TemplateActions {
  constructor(private store: Store<ITemplateState>) { }

  public requestTemplates(params?: any): void {
    this.store.dispatch(new RequestTemplates(params));
  }

  public requestDeleteTemplate(params?: any): void {
    this.store.dispatch(new RequestDeleteTemplate(params));
  }

  public requestUpdateTemplate(params?: any): void {
    this.store.dispatch(new RequestUpdateTemplate(params));
  }
}

// eslint-disable-next-line no-shadow
export enum SuppliersActionTypes {
  REQUEST_SUPPLIERS_PAGE = 'REQUEST_SUPPLIERS_PAGE',
  REQUEST_SUPPLIER_CREDENTIALS_PAGE = 'REQUEST_SUPPLIER_CREDENTIALS_PAGE',
  RECEIVE_SUPPLIERS_PAGE = 'RECEIVE_SUPPLIERS_PAGE',
  RECEIVE_SUPPLIER_CREDENTIALS_PAGE = 'RECEIVE_SUPPLIER_CREDENTIALS_PAGE',
  ERROR_RECEIVE_SUPPLIERS_PAGE = 'ERROR_RECEIVE_SUPPLIERS_PAGE',
  ERROR_RECEIVE_SUPPLIER_CREDENTIAL_PAGE = 'ERROR_RECEIVE_SUPPLIER_CREDENTIAL_PAGE',

  REQUEST_SUPPLIERS_OPTIONS = 'REQUEST_SUPPLIERS_OPTIONS',
  REQUEST_SUPPLIER_CREDENTIALS_OPTIONS = 'REQUEST_SUPPLIER_CREDENTIALS_OPTIONS',
  RECEIVE_SUPPLIERS_OPTIONS = 'RECEIVE_SUPPLIERS_OPTIONS',
  ERROR_RECEIVE_SUPPLIERS_OPTIONS = 'ERROR_RECEIVE_SUPPLIERS_OPTIONS',


  RECEIVE_SUPPLIER_CREDENTIALS_OPTIONS = 'RECEIVE_SUPPLIER_CREDENTIALS_OPTIONS',
  ERROR_RECEIVE_SUPPLIER_CREDENTIALS_OPTIONS = 'ERROR_RECEIVE_SUPPLIER_CREDENTIALS_OPTIONS',
}

import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { DashboardActionTypes } from '../dashboard-action-types';
import { IComments } from 'src/app/dashboard/models/comments.model';


export class RequestAddComment implements Action {
  readonly type: DashboardActionTypes.REQUEST_ADD_COMMENT = DashboardActionTypes.REQUEST_ADD_COMMENT;

  constructor(
    public payload: IComments) { }
}
export class ReceiveAddComment implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ADD_COMMENT = DashboardActionTypes.RECEIVE_ADD_COMMENT;

  constructor(
    public payload: IComments) { }
}
export class ErrorReceiveAddComment implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ADD_COMMENT = DashboardActionTypes.ERROR_RECEIVE_ADD_COMMENT;

  constructor(
    public payload: HttpErrorResponse) { }
}
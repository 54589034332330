<div #commentContainer
     class="h-full flex flex-col"
     [ngStyle]="{'display': curDisplay,
               'max-height': curHeight, 'min-height': '100px'}">
     <div class="comment-container">
          <div *ngIf="!cardView" class="comment-header">
               <div class="comment-header-title">
                    Comments
               </div>
               <div class="close-comments" (click)="closeToggle()">
                    <i class="bi bi-x-lg" style="font-size: 0.8rem; color: #202124"></i>
               </div>
          </div>
          <div *ngIf="!cardView" class="dropdown-container">
               <div class="default-filters default-filter-width" #filterDiv
                    [class.active]="filterDropdownVisible">
                    <div class="dropdown-view" (click)="toggleFilterDropdown()">
                         <div class="default-filters-caption">
                              {{ activeFilter }}
                         </div>
                         <div class="default-filters-dropdown">
                              <i class="bi bi-caret-down-fill" style="font-size: 0.8rem;"></i>
                         </div>
                    </div>
                    <div class="dropdown-options dropdown-options-comments dropdown-filter-options" *ngIf="filterDropdownVisible">
                         <div class="filter-options"
                              (click)="setActiveFilter(fopt)"
                              *ngFor="let fopt of filterOptions">
                              <i class="bi bi-check-lg"
                                   [style.visibility]="fopt === activeFilter ? 'visible' : 'hidden'"></i>
                              <span> {{ fopt }} </span>
                         </div>
                    </div>
               </div>
               <div class="default-filters default-filter-width" #jobDiv
                    [class.active]="jobDropdownVisible">
                    <div class="dropdown-view" (click)="toggleJobView()">
                         <div class="default-filters-caption">
                              {{ getJobName(activeJob) }}
                         </div>
                         <div class="default-filters-dropdown">
                              <i class="bi bi-caret-down-fill" style="font-size: 0.8rem;"></i>
                         </div>
                    </div>
                    <div class="dropdown-options dropdown-options-comments dropdown-job-options custom-scrollbar" *ngIf="jobDropdownVisible">
                         <div class="filter-options"
                              (click)="setActiveJob(fopt)"
                              *ngFor="let fopt of activeJobs">
                              <i class="bi bi-check-lg"
                                   [style.visibility]="isJobSelected(fopt) ? 'visible' : 'hidden'"></i>
                              <span> {{ getJobName(fopt) }} </span>
                         </div>
                    </div>
               </div>
          </div>
          <div class="comment-display custom-scrollbar-close">
               <div class="single-comment-container"
                    [ngClass]="{'resolved-hover-effect': isResolved(thread), 'open-hover-effect': !isResolved(thread)}"
                    *ngFor="let thread of filteredThreads">
                    <div class="single-comment-header">
                         {{ formatHeader(thread) }}
                         <br><span class="small-header-text">{{ formatHeaderBelow(thread) }}</span>
                    </div>
                    <div class="single-comment-comments"
                         [ngStyle]="isResolved(thread) ? {'background-color': '#f2f2f2'} : {}"
                         *ngFor="let comment of thread.comments; let isFirst = first; let isLast = last">
                         <div class="single-comment-reply">
                              <div class="single-reply-contents">
                                   <div class="single-reply-metadata">
                                        <div class="single-reply-header">
                                             {{ formatUser(comment) }}
                                        </div>
                                        <div class="single-reply-timestamp">
                                             {{ formatTimestamp(comment) }}
                                        </div>
                                   </div>
                                   <div class="comment-action" *ngIf="isFirst && thread.options == 0">
                                        <div class="custom-resolve-tooltip">Mark thread resolved</div>
                                        <div class="mark-resolved options-display" (click)="onResolveThread(thread)">
                                          <i class="bi bi-check2-square"></i>
                                        </div>
                                   </div>
                                   <div #tooltipRefOptions class="comment-action" *ngIf="comment.user == userId">
                                        <div class="custom-resolve-tooltip" *ngIf="!comment.show_options">More options...</div>
                                        <div class="show-options options-display" (click)="showMoreOptions($event, comment)">
                                             <i class="bi bi-three-dots-vertical"></i>
                                        </div>
                                        <div class="options-tooltip" *ngIf="comment.show_options">
                                             <div class="options-tooltip-choice filter-options" (click)="deleteComment(thread, comment)">
                                                  Delete
                                             </div>
                                             <div class="options-tooltip-choice filter-options" (click)="editComment(comment)">
                                                  Edit
                                             </div>
                                        </div>
                                   </div>
                                   <div class="commenting-container">
                                        <div class="single-reply-text" *ngIf="!comment.editing"
                                             [innerHTML]="formatText(thread, comment)"></div>
                                        <div class="single-comment-input" *ngIf="comment.editing"
                                             id="single-comment-input-id">
                                             <div #editCommentBox class="single-comment-textarea editing-textarea"
                                                  contenteditable="true"
                                                  [mention]="orgUserEmails"
                                                  (itemSelected)="onMentionSelected($event)"
                                                  (input)="updateEditText($event)">
                                             </div>
                                             <div class="editing-button-container">
                                                  <button
                                                  [ngClass]="editingText ? 'comment-button-enabled' : 'comment-button-disabled'"
                                                  (click)="onCommentDoneEditing(thread, comment)"
                                                  [disabled]="!editingText">
                                                  Save
                                                  </button>
                                                  <button
                                                  class="cancel-button"
                                                  (click)="onCancelEditing(comment)">
                                                  Cancel
                                                  </button>
                                             </div>
                                        </div>
                                   </div>

                              </div>
                         </div>
                    </div>
                    <div class="single-comment-input" id="single-comment-input-id"
                         [ngStyle]="isResolved(thread) ? {'background-color': '#f2f2f2'} : {}">
                         <div class="main-textarea single-comment-textarea"
                              contenteditable="true"
                              [mention]="orgUserEmails"
                              (itemSelected)="onMentionSelected($event)"
                              [attr.data-placeholder]="getPlaceholderText(thread)"
                              (input)="updateCommentText($event, thread)">
                         </div>
                         <div class="button-container" *ngIf="isCurrentThread(thread)">
                              <button
                                [ngClass]="commentText ? 'comment-button-enabled' : 'comment-button-disabled'"
                                (click)="onComment(thread)"
                                [disabled]="!commentText">
                                Reply
                              </button>
                              <button
                                class="cancel-button"
                                (click)="onCancel(thread)">
                                Cancel
                              </button>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</div>

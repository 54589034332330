import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import {ISuppliers, ISupplierContacts, ISupplierCredentials} from 'src/app/dashboard/models/dashboard.model';
import { DashboardActionTypes } from '../dashboard-action-types';


export class RequestSuppliersSmall implements Action {
  readonly type: DashboardActionTypes.REQUEST_SUPPLIERS_SMALL = DashboardActionTypes.REQUEST_SUPPLIERS_SMALL;
  constructor(
    public payload: string) { }
}
export class ReceiveSuppliersSmall implements Action {
  readonly type: DashboardActionTypes.RECEIVE_SUPPLIERS_SMALL = DashboardActionTypes.RECEIVE_SUPPLIERS_SMALL;

  constructor(
    public payload: ISuppliers) { }
}

export class ErrorReceiveSuppliersSmall implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_SUPPLIERS_SMALL = DashboardActionTypes.ERROR_RECEIVE_SUPPLIERS_SMALL;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestSuppliers implements Action {
  readonly type: DashboardActionTypes.REQUEST_SUPPLIERS = DashboardActionTypes.REQUEST_SUPPLIERS;

  constructor(
    public payload: string) { }
}
export class ReceiveSuppliers implements Action {
  readonly type: DashboardActionTypes.RECEIVE_SUPPLIERS = DashboardActionTypes.RECEIVE_SUPPLIERS;

  constructor(
    public payload: ISuppliers) { }
}

export class ReceiveSupplierCredentials implements Action {
  readonly type: DashboardActionTypes.RECEIVE_SUPPLIER_CREDENTIALS = DashboardActionTypes.RECEIVE_SUPPLIER_CREDENTIALS;

  constructor(
    public payload: ISupplierCredentials) {
  }
}

export class ErrorReceiveSuppliers implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_SUPPLIERS = DashboardActionTypes.ERROR_RECEIVE_SUPPLIERS;

  constructor(
    public payload: HttpErrorResponse) { }
}


export class RequestAddSuppliers implements Action {
  readonly type: DashboardActionTypes.REQUEST_ADD_SUPPLIERS = DashboardActionTypes.REQUEST_ADD_SUPPLIERS;

  constructor(
    public payload: ISuppliers) { }
}
export class ReceiveAddSuppliers implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ADD_SUPPLIERS = DashboardActionTypes.RECEIVE_ADD_SUPPLIERS;

  constructor(
    public payload: ISuppliers) { }
}
export class ErrorReceiveAddSuppliers implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ADD_SUPPLIERS = DashboardActionTypes.ERROR_RECEIVE_ADD_SUPPLIERS;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestAddSupplierContact implements Action {
  readonly type: DashboardActionTypes.REQUEST_ADD_SUPPLIER_CONTACT = DashboardActionTypes.REQUEST_ADD_SUPPLIER_CONTACT;

  constructor(
    public payload: ISupplierContacts) { }
}
export class ReceiveAddSupplierContact implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ADD_SUPPLIER_CONTACT = DashboardActionTypes.RECEIVE_ADD_SUPPLIER_CONTACT;

  constructor(
    public payload: ISupplierContacts) { }
}
export class ErrorReceiveAddSupplierContact implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ADD_SUPPLIER_CONTACT = DashboardActionTypes.ERROR_RECEIVE_ADD_SUPPLIER_CONTACT;

  constructor(
    public payload: HttpErrorResponse) { }
}
export class ResetSupplierCRUDState implements Action {
  readonly type: DashboardActionTypes.RESET_CRUD_SUPPLIERS_STATE = DashboardActionTypes.RESET_CRUD_SUPPLIERS_STATE;
}

export class RequestAutocompleteSuppliers implements Action {
  readonly type: DashboardActionTypes.REQUEST_AUTOCOMPLETE_SUPPLIERS = DashboardActionTypes.REQUEST_AUTOCOMPLETE_SUPPLIERS;

  constructor(
    public payload: string) { }
}
export class ReceiveAutocompleteSuppliers implements Action {
  readonly type: DashboardActionTypes.RECEIVE_AUTOCOMPLETE_SUPPLIERS = DashboardActionTypes.RECEIVE_AUTOCOMPLETE_SUPPLIERS;

  constructor(
    public payload: ISuppliers) { }
}
export class ErrorReceiveAutocompleteSuppliers implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_AUTOCOMPLETE_SUPPLIERS = DashboardActionTypes.ERROR_RECEIVE_AUTOCOMPLETE_SUPPLIERS;

  constructor(
    public payload: HttpErrorResponse) { }
}

export enum UserActionTypes {
  REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS',
  RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS',
  ERROR_RECEIVE_NOTIFICATIONS = 'ERROR_RECEIVE_NOTIFICATIONS',

  REQUEST_UPDATE_NOTIFICATION = 'REQUEST_UPDATE_NOTIFICATION',
  RECEIVE_UPDATE_NOTIFICATION = 'RECEIVE_UPDATE_NOTIFICATION',
  ERROR_RECEIVE_UPDATE_NOTIFICATION = 'ERROR_RECEIVE_UPDATE_NOTIFICATION',

  REQUEST_USER_PROFILE = 'REQUEST_USER_PROFILE',
  RECEIVE_USER_PROFILE = 'RECEIVE_USER_PROFILE',
  ERROR_RECEIVE_USER_PROFILE = 'ERROR_RECEIVE_USER_PROFILE',

  REQUEST_UPDATE_USER_PROFILE = 'REQUEST_UPDATE_USER_PROFILE',
  RECEIVE_UPDATE_USER_PROFILE = 'RECEIVE_UPDATE_USER_PROFILE',
  ERROR_RECEIVE_UPDATE_USER_PROFILE = 'ERROR_RECEIVE_UPDATE_USER_PROFILE',

  REQUEST_UPDATE_USER_PASSWORD = 'REQUEST_UPDATE_USER_PASSWORD',
  RECEIVE_UPDATE_USER_PASSWORD = 'RECEIVE_UPDATE_USER_PASSWORD',
  ERROR_RECEIVE_UPDATE_USER_PASSWORD = 'ERROR_RECEIVE_UPDATE_USER_PASSWORD',
}

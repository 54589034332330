import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ISuppliersState } from 'src/app/dashboard/state/models/suppliers.model';
import {
  RequestSupplierCredentialsOptions,
  RequestSupplierCredentialsPage
} from './suppliers-action-type-creators/suppliers-page-action-types.creators';
import {ISupplierCredentialsState} from "../../../dashboard/state/models/suppliersCredentials.model";

@Injectable({ providedIn: 'root' })
export class SupplierCredentialsActions {
  constructor(private store: Store<ISupplierCredentialsState>) { }

  public requestSupplierCredentialsPage(params?: any): void {
    this.store.dispatch(new RequestSupplierCredentialsPage(params));
  }

  public requestSupplierCredentialsOptions(params?: any): void {
    this.store.dispatch(new RequestSupplierCredentialsOptions(params));
  }
}

import { combineReducers } from 'redux';
import { ICompanyState } from '../models/company.model';
import { organizationAddressesReducer } from './organization-addresses.reducer';
import { organizationReducer } from './organization.reducer';

export const companyReducer: any =
  combineReducers<ICompanyState>({
    organization: organizationReducer,
    organizationAddresses: organizationAddressesReducer
  });

<div class="orders-row">
  <span class="orders-items">
    <div
      *ngFor="let item of params.data.supplier_documents_numbers"
      class="number"
    >
      <div *ngIf="item && item.document_type">
        <span
          class="orders-item--button"

        >
          {{ params.context.document_types[item.document_type].value }}
        </span>

      </div>
    </div>
  </span>

</div>




import { ElementRef, Injectable, Output, Renderer2, RendererFactory2, ViewChild } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IViews } from 'src/app/dashboard/models/dashboard.model';
import { IRequestViewsPayload } from '../interfaces/request-views-payload.interface';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {decodeMsgPack} from 'src/app/dashboard/services/do_msgpack'


@Injectable({
  providedIn: 'root'
})
export class JobTableService extends OnDestroyMixin() {
  private baseUrl: string = environment.ENV.BASE_URL;
  @Output() show_inbox: boolean = false;
  private dashboardContainer1?: ElementRef ;
  private dashboardContainer2?: ElementRef ;
  private dashboardSearchAndHistory?: ElementRef;

  private hideFilters$ = new BehaviorSubject<any>(null);
  private showFilters$ = new BehaviorSubject<any>(null);
  private matchingFilters$ = new BehaviorSubject<any>(null);
  private matchingFiltersOperators$ = new BehaviorSubject<any>(null);
  private sortingFilters$ = new BehaviorSubject<any>(null);
  private sortingDirectionFilters$ = new BehaviorSubject<any>(null);
  private searchFilters$ = new BehaviorSubject<any>(null);
  private subItemsObservable$ = new BehaviorSubject<any>(null);
  private imageFilters$ = new BehaviorSubject<any>(null);
  private groupFilters$ = new BehaviorSubject<any>(null);
  private sorts_cleared: boolean = false;
  public sort_deleted: boolean = false;
  private setSortArray$ = new BehaviorSubject<any>(null);

  private gridApiRef$ = new BehaviorSubject<any>(null);
  private columnApiRef$ = new BehaviorSubject<any>(null);
  private resetIndex$ = new BehaviorSubject<any>(null);

  setGridApi(api: any): void {
    this.gridApiRef$.next(api);
  }

  get getGridApi(): Observable<any> {
    return this.gridApiRef$.asObservable();
  }

  setColumnApi(api: any): void {
    this.columnApiRef$.next(api);
  }

  get getResetIndex(): Observable<any> {
    return this.resetIndex$.asObservable();
  }

  setResetIndex(data: any): void {
    this.resetIndex$.next(data);
  }

  get getColumnApi(): Observable<any> {
    return this.columnApiRef$.asObservable();
  }

  get showFilterObs(): Observable<any> {
    return this.showFilters$.asObservable();
  }

  setShowFilter(value: any): void {
    this.showFilters$.next(value);
  }

  get hideFilterObs(): Observable<any> {
    return this.hideFilters$.asObservable();
  }

  setHideShowFilter(value: any): void {
    this.hideFilters$.next(value);
  }

  get matchFilterObs(): Observable<any> {
    return this.matchingFilters$.asObservable();
  }

  setMatchFilter(value: any): void {
    this.matchingFilters$.next(value);
  }

  get matchFilterOperatorsObs(): Observable<any> {
    return this.matchingFiltersOperators$.asObservable();
  }

  setMatchFilterOperators(value: any): void {
    this.matchingFiltersOperators$.next(value);
  }

  setClearAllSorts() {
    this.sorts_cleared = true;
  }

  getSortsClear() {
    return this.sorts_cleared;
  }

  setUnclearAllSorts() {
    this.sorts_cleared = false;
  }


  get sortingFilterObs(): Observable<any> {
    return this.sortingFilters$.asObservable();
  }

  setSortingFilter(value: any): void {
    this.sortingFilters$.next(value);
  }

  get sortingArrayObs(): Observable<any> {
    return this.setSortArray$.asObservable();
  }

  setSortingArray(value: any): void {
    this.setSortArray$.next(value);
  }


  refreshCurrentView(){
  }

  get searchFilterObs(): Observable<any> {
    return this.searchFilters$.asObservable();
  }

  get imageSizeObs(): Observable<any> {
    return this.imageFilters$.asObservable();
  }

  get groupOrderingObs(): Observable<any> {
    return this.groupFilters$.asObservable();
  }

  setSearchFilter(value: any): void {
    this.searchFilters$.next(value);
  }

  setImageSize(size:string){
    this.imageFilters$.next(size);
  }

  setGroup(groupOrder:any){
    this.groupFilters$.next(groupOrder);
  }

  setSubItems(subitems: any) {
    this.subItemsObservable$.next(subitems);
  }

  get subItemsObs(): Observable<any> {
    return this.subItemsObservable$.asObservable();
  }

  private renderer: Renderer2;

  constructor(private httpClient: HttpClient,
              private rendererFactory: RendererFactory2) {

    super();
    this.renderer = rendererFactory.createRenderer(null, null);

  }

  loadJobList(payload: IRequestViewsPayload): Observable<IViews> {
    // NOT USED
    return this.httpClient.get<IViews>(`${this.baseUrl}views/NO_ROUTE/`, {
      headers: new HttpHeaders({
        'Accept': 'application/x-msgpack',
      }),
      params: { ...payload },
      responseType: 'arraybuffer' as any
    }).pipe(map((response: any) => {
      return decodeMsgPack(response);
      })
    );
  }

}

<div class="settings">
  <div class="settings__user">
    <h3>Account Overview</h3>
    <materlog-user-management [countriesList]="countriesList" [statesList]="statesList"></materlog-user-management>
  </div>
  <p-divider></p-divider>

  <div class="settings__preferences">
    <h4>Communication preferences</h4>
    <p>Choose what messages you’d like to receive about your MaterLog account.</p>

    <materlog-preferences [checkboxList]="checkboxList" [radiosList]="radiosList"></materlog-preferences>

    <p-divider></p-divider>
  </div>
</div>

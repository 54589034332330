import { IActiveOrderList } from '../../models/dashboard.model';
import { IStoreApiItem, StoreApiItem } from './../../../common/models/store-api-item.model';


export interface IActiveOrdersState {
  activeOrders: IStoreApiItem<IActiveOrderList>;
}

export class ActiveOrdersState implements IActiveOrdersState {
  activeOrders: IStoreApiItem<IActiveOrderList> = new StoreApiItem();
}
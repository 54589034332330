<div #content *ngIf="isOpen" class="comment-container" id="comment-container-id">
  <div class="comment-header">Add comment</div>
  <div *ngIf="sharedView" class="email-body-to-current">
    <div class="email-body-textarea email-body-textarea-regular" #commentEmail
      contenteditable="true"
      (keydown)="handleKeyDown($event)"
      [attr.data-placeholder]="getPlaceholderEmailText()"
      (input)="updateCommentEmail($event)">
    </div>
  </div>
  <div class="comment-margin-bottom"></div>
  <textarea #commentTextarea
    class="comment-textarea"
    [placeholder]="getPlaceholderText()"
    [mention]="orgUserEmails"
    [(ngModel)]="commentText"
    (itemSelected)="onMentionSelected($event)">
  </textarea>

  <div class="button-container">
    <button
      [ngClass]="commentText ? 'comment-button-enabled' : 'comment-button-disabled'"
      (click)="onComment()"
      [disabled]="!commentText">
      Comment
    </button>
    <button
      class="cancel-button"
      (click)="onCancel()">
      Cancel
    </button>
  </div>
</div>

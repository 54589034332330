import { combineReducers } from 'redux';
import { IUserState } from '../models/user.model';
import { notificationsReducer } from './notifications.reducer';
import { userPasswordReducer } from './password.reducer';
import { userProfileReducer } from './user-profile.reducer';


export const userReducer: any =
  combineReducers<IUserState>({
    notifications: notificationsReducer,
    userProfile: userProfileReducer,
    userPassword: userPasswordReducer,
  });

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {Observable, of, tap} from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SupplierCredentialsService } from '../../services/suppliers.credentials.service';
import {
  ErrorReceiveSupplierCredentialsOptions, ErrorReceiveSupplierCredentialsPage,
  ReceiveSupplierCredentialsOptions, ReceiveSupplierCredentialsPage,
} from '../actions/suppliers-action-type-creators/suppliers-page-action-types.creators';
import { SuppliersActionTypes } from '../actions/suppliers-action-types';



import {DashboardActionTypes} from "../../../dashboard/state/actions/dashboard-action-types";


@Injectable()
export class SupplierCredentialsEffects {
  constructor(private actions$: Actions,
              private supplierCredentialsService: SupplierCredentialsService
  ) {

  }

  requestSupplierCredentialsPage$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SuppliersActionTypes.REQUEST_SUPPLIER_CREDENTIALS_PAGE),
      switchMap((params?: any) =>
        this.supplierCredentialsService.getSupplierCredentials(params).pipe(
          tap(data => {}),
          map((results: any) => new ReceiveSupplierCredentialsPage(results)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveSupplierCredentialsPage(error)))
        )
      )
    );
  });
  /*requestSharedViewPage$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedViewActionTypes.REQUEST_SHARED_VIEW_PAGE),
      switchMap((params: any) =>
        this.sharedViewService.getSharedViewPage(params).pipe(
          map(
            (sharedView: any) => new ReceiveSharedViewPage(sharedView)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveSharedViewPage(error)))
        )
      )
    );
  });


  requestSupplierCredentialsPage$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SuppliersActionTypes.REQUEST_SUPPLIER_CREDENTIALS_PAGE),
      switchMap((params: any) =>
        this.supplierCredentialsService.getSupplierCredentials(params).pipe(
          map(
            (results: any) => new ReceiveSupplierCredentialsPage(results)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveSupplierCredentials(error)))
        )
      )
    );
  });*/

  requestSupplierCredentialsOptions$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SuppliersActionTypes.REQUEST_SUPPLIER_CREDENTIALS_OPTIONS),
      switchMap(() =>
        this.supplierCredentialsService.getOptions().pipe(
          tap(data => {}),
          map((credential: any) => new ReceiveSupplierCredentialsOptions(credential)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveSupplierCredentialsOptions(error)))
        )
      )
    );
  });


}

import { IOrders } from './../../models/dashboard.model';
import { requestPostPutItemData, receivePostPutItemData, errorPostPutItemData } from './../../../common/utils/reducer/reducer.utils';
import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorItemData, receiveItemData, requestItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { INewOrder } from 'src/app/dashboard/models/dashboard.model';
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { IOrdersState, OrdersState } from '../models/orders.model';

export function ordersReducer(
  lastState: IOrdersState = new OrdersState(),
  action: GenericAction<DashboardActionTypes, any>
): IOrdersState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_ADD_ORDER:
      return requestPostPutItemData<IOrdersState, INewOrder>(lastState, ['newOrders'], action.payload);
    case DashboardActionTypes.RECEIVE_ADD_ORDER:
      return receivePostPutItemData<IOrdersState, INewOrder>(lastState, ['newOrders'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ADD_ORDER:
      return errorPostPutItemData<IOrdersState, INewOrder>(lastState, ['newOrders'], action.payload);

    case DashboardActionTypes.REQUEST_ORDERS:
      return requestItemData<IOrdersState, IOrders>(lastState, ['orders']);
    case DashboardActionTypes.RECEIVE_ORDERS:
      return receiveItemData<IOrdersState, IOrders>(lastState, ['orders'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ORDERS:
      return errorItemData<IOrdersState, IOrders>(lastState, ['orders'], action.payload);

    case DashboardActionTypes.REQUEST_UPDATE_ORDER:
      return requestPostPutItemData<IOrdersState, any>(lastState, ['orders'], action.payload);
    case DashboardActionTypes.RECEIVE_UPDATE_ORDER:
      return receivePostPutItemData<IOrdersState, any>(lastState, ['orders'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_UPDATE_ORDER:
      return errorPostPutItemData<IOrdersState, any>(lastState, ['orders'], action.payload);

    case DashboardActionTypes.REQUEST_ADD_ROOM:
      return requestPostPutItemData<IOrdersState, any>(lastState, ['roomOptions'], action.payload);
    case DashboardActionTypes.RECEIVE_ADD_ROOM:
      return receivePostPutItemData<IOrdersState, any>(lastState, ['roomOptions'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ADD_ROOM:
      return errorPostPutItemData<IOrdersState, any>(lastState, ['roomOptions'], action.payload);

    default:
      return lastState;
  }
}

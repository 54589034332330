import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { PermissionsService } from 'src/app/root/services/permissions.service';

@Injectable()
export class AuthenticationModuleGuard extends OnDestroyMixin() implements CanActivate {

  constructor(
    private permissionService: PermissionsService
  ) {
    super();
  }

  canActivate(): boolean {
    return this.permissionService.isLoggedIn();
  }

}

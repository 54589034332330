import { HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { environment } from 'src/environments/environment';
import { ILogin, IForgotPassword, IRecoverPassword, ISingUp, IOptions } from '../models/authentication.model';
import { map } from 'rxjs/operators';
import {decodeMsgPackSimple} from 'src/app/dashboard/services/do_msgpack'


@Injectable()
export class AuthenticationService {
  private baseUrl: string = environment.ENV.BASE_URL;
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }
  private httpOptionsMsgPack = {
    headers: new HttpHeaders({
      'Accept': 'application/x-msgpack',
    }),
    responseType: 'arraybuffer' as any
  };


  public login(login: ILogin | any): Observable<ILogin | HttpErrorResponse> {
    return this.httpClient.post<ILogin>(`${this.baseUrl}users/login/`, login.payload);
  }

  public singUp(singUp: ISingUp | any): Observable<ISingUp | HttpErrorResponse> {
    return this.httpClient.post<ISingUp>(`${this.baseUrl}users/register/`, singUp.payload);
  }

  public forgotPassword(forgotPassword: IForgotPassword | any): Observable<IForgotPassword | HttpErrorResponse> {
    return this.httpClient.post<IForgotPassword>(`${this.baseUrl}users/forgot-password/`, forgotPassword.payload);
  }

  public recoverPassword(recoverPassword: IRecoverPassword | any): Observable<IRecoverPassword | HttpErrorResponse> {
    return this.httpClient.post<IRecoverPassword>(`${this.baseUrl}users/recover-password/`, recoverPassword.payload);
  }

  public getOptions(): Observable<IOptions[] | HttpErrorResponse> {
    return this.httpClient.get<Array<IOptions>>(`${this.baseUrl}options/?options=organization_types&options=user_roles`, this.httpOptionsMsgPack)
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    ) as any;
  }

  public addCompany(companyType: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<any>(`${this.baseUrl}organizations/types/`, companyType.payload, this.httpHelper.options);
  }

  public addRole(role: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<any>(`${this.baseUrl}users/roles/`, role.payload, this.httpHelper.options);
  }
}

import { ChangeDetectorRef, Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';

@Component({
  selector: 'status-component',
  template: `
    <div class="ag-status-name-value" >
      <span>Total records:&nbsp;</span>
      <span class="ag-status-name-value-value">{{ count }}</span>
    </div>
  `,
})
export class CountStatusBarComponent implements IStatusPanelAngularComp {
  private params!: IStatusPanelParams;
  public count: number | null = null;

  constructor(
    private cdr: ChangeDetectorRef,
  ) { }

  agInit(params: IStatusPanelParams): void {
    this.params = params;
    this.params.api.addEventListener('columnEverythingChanged', this.onViewportChanged.bind(this));
    this.params.api.addEventListener('filterChanged', this.onViewportChanged.bind(this));
    this.params.api.addEventListener('rowDataUpdated', this.onViewportChanged.bind(this));
  }

  onViewportChanged() {
    let rowCount = 0;
    this.params.api.forEachNodeAfterFilterAndSort((node) => {
      if (node.displayed && !node.group) {
        rowCount++;
      }
    });

    this.count = rowCount;
    this.cdr.detectChanges();
  }
}
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs';
import { ChangeDetectorRef} from '@angular/core';

import { IStoreApiItem } from "src/app/common/models/store-api-item.model";
import { IApplicationState } from "src/app/common/state/models/app.state.model";
import { DashboardActions } from "src/app/dashboard/state/actions/dashboard.actions";
import { selectAddRoom } from "src/app/dashboard/state/selectors/dashboard.selector";
import { CustomErrorService } from "src/app/dashboard/services/custom-error.service";
import { OnDestroyMixin } from "src/app/common/mixins/destroy-mixin";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


@Component({
  selector: 'materlog-add-room-modal',
  templateUrl: "add-room-modal.component.html",
  standalone: true,
  imports: [CommonModule, FormsModule]
})
export class AddRoomModalComponent extends OnDestroyMixin() implements OnInit {
  @Input() params: any;
  @Output() addRoom: EventEmitter<any> = new EventEmitter();

  addRoomModal: boolean = false;
  isAddRoomNameSubmitted: boolean = false;
  roomName: string = '';

  constructor(
    private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private customErrorService: CustomErrorService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    (window as any)['room'] = this;
  }

  ngOnInit(): void {
    this.isAddRoomNameSubmitted = false;
    this.roomName = '';
  }

  onCloseAddRoomModal() {
    this.addRoomModal = false;
  }

  onSaveRoomName(id: string) {
    if (this.roomName.trim()?.length >= 1) {
      this.addRoomModal = false;
      this.dashboardActions.requestAddRoom({ name: this.roomName, item: this.params.data.id });
      
      this.store
        .pipe(
          select(selectAddRoom),
          filter((room: IStoreApiItem<any>) => !room.isLoading),
          take(1)
        )
        .subscribe((room: any) => {
          if (room?.isSuccess) {
            this.addRoom.emit({id: room.data.id, value: this.roomName});
            this.dashboardActions.requestOptions();
            this.dashboardActions.requestUpdateViewCell({ room_id: room.data.id, id: this.params.data.id });
          }
          if (room?.errors) {
            this.customErrorService.setCustomErrorMessage(room);
          }
        });
    }
  }
}

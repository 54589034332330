import { Component } from "@angular/core";

import { ICellEditorAngularComp } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-enterprise";
import { PayloadService } from "src/app/dashboard/services/payload.service";
import { DashboardActions } from "src/app/dashboard/state/actions/dashboard.actions";

@Component({
  selector: 'dropdown-cell-editor',
  templateUrl: "dropdown.component.html"
})
export class DropDownEditor implements ICellEditorAngularComp {
  public params: any;
  public addItemModal: any;
  selectedSupplier: any;

  constructor(
    private dashboardActions: DashboardActions,
    private payloadService: PayloadService,
  ) { }

  agInit(params: ICellEditorParams): void {
    this.params = params;
  }

  getValue(): any {
    return this.params.value;
  }

  onOpenModal() {
    this.addItemModal = true;
  }

  isPopup(): boolean {
      return true;
  }

  onChange() {
    //needed to turn int to text for the rest of the column processing....
    if(this.params.value != undefined && this.params.value != null) {
      this.params.value = "" + this.params.value;
    }
    this.params.stopEditing();
  }

  closeModal() {
    this.addItemModal = false;
  }

  closeModalAndSave() {
    this.addItemModal = false;
    this.dashboardActions.requestViews({ ...this.payloadService.setViewsPayload() });
  }

  supplierContactFormChangeEvent(event: any) {
    this.dashboardActions.requestUpdateViewCell({ supplier_contact_id: event.id, id: this.params.data['id'] });
    this.dashboardActions.requestSuppliers();
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ISuppliersState } from 'src/app/dashboard/state/models/suppliers.model';
import { RequestSupplierOptions, RequestSuppliersPage } from './suppliers-action-type-creators/suppliers-page-action-types.creators';

@Injectable({ providedIn: 'root' })
export class SuppliersActions {
  constructor(private store: Store<ISuppliersState>) { }

  public requestSuppliersPage(params?: any): void {
    this.store.dispatch(new RequestSuppliersPage(params));
  }

  public requestSupplierOptions(params?: any): void {
    this.store.dispatch(new RequestSupplierOptions(params));
  }
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

import { MessageService } from 'primeng/api';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';

@Component({
  selector: 'add-edit-doc',
  templateUrl: './add-edit-doc.component.html',
  styleUrls: ['./add-edit-doc.component.sass'],
  providers: [DatePipe]
})
export class AddEditDocComponent extends OnDestroyMixin() implements OnChanges {
  @Input() showDialog: any;
  @Input() isPO: any;
  @Input() data: any;
  @Input() itemId: any;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  @Output() addUpdateOrder: EventEmitter<any> = new EventEmitter();

  @Input() docTypes: any;
  orderDoc: any = {
    id: null,
    number: null,
    order_document_date: null,
    document_type: 0,
    attachments: []
  };
  numberError: boolean = false;
  isLoading: boolean = false;

  constructor(
    public router: Router,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {
    super();
    (window as any)['adddoc'] = this;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.docTypes) { this.docTypes = JSON.parse(JSON.stringify(this.docTypes)); }

    if (changes.data?.currentValue) {
      this.orderDoc = JSON.parse(JSON.stringify(this.data));
      this.isLoading = Object.keys(this.orderDoc).length === 0;
      this.orderDoc.order_document_date = this.orderDoc.order_document_date ? new Date(this.orderDoc.order_document_date) : null;
    }

    this.cdr.markForCheck();
  }

  fileBrowseHandler(files: File[]) {
    for (const item of files) {
      this.convertBase64(item);
    }
  }

  deleteFile(fileIndex: number) {
    if (this.orderDoc.attachments?.length > 1) {
      this.orderDoc.attachments.splice(fileIndex, 1);
    } else {
      this.orderDoc.attachments = [];
    }
  }

  checkFileType(file: any): boolean {
    const prepareFile: any = file.name.split('.');
    const fileType = prepareFile?.findLast((el: any) => el.length > 1).toLowerCase();
    const types = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'bmp', 'eml'];

    if (!types.includes(fileType)) { return false; }

    return true;
  }

  convertBase64(file: any) {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const data = String(reader.result).split('base64,');

      if (data[1] && this.checkFileType(file)) {
        if (this.orderDoc.attachments[0]?.filename === null) {
          this.orderDoc.attachments[0].id = null;
          this.orderDoc.attachments[0].attachment = data[1];
          this.orderDoc.attachments[0].filename = file.name;
        } else {
          this.orderDoc.attachments.push({
            id: null,
            attachment: data[1],
            filename: file.name,
          });
        }

        this.cdr.markForCheck();
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please provide a valid file.',
          key: 'error',
        });
      }

      this.cdr.markForCheck();
    };
  }

  closeModal() {
    this.closeDialog.emit(false);
    this.resetOrderDoc();
  }

  savePO() {
    const objToSave = {
      document_id: this.orderDoc.id,
      order_item_id: this.itemId,
      number: this.orderDoc.number,
      document_type: this.isPO ? 1 : this.orderDoc.document_type === null ? 0 : this.orderDoc.document_type,
      order_document_date: this.orderDoc.order_document_date !== null ? this.datePipe.transform(this.orderDoc.order_document_date, 'yyyy-MM-dd') : null,
      attachments: JSON.parse(JSON.stringify(this.orderDoc.attachments)),
    };

    if (objToSave.document_id != null && String(objToSave.number).length >= 1 && objToSave.number) {
      objToSave.attachments?.forEach((elem: any) => {
        if (elem.id !== null) {
          elem.attachment = '';
        }
      });
      this.addUpdateOrder.emit({ updateOrder: objToSave });
      this.closeDialog.emit(false);
      this.resetOrderDoc();
    } else if (objToSave.document_id === null && String(objToSave.number).length >= 1 && objToSave.number) {
      objToSave.attachments = objToSave.attachments[0]?.filename === null ? [] : objToSave.attachments;
      this.addUpdateOrder.emit({ addOrder: objToSave });
      this.closeDialog.emit(false);
      this.resetOrderDoc();
    } else {
      this.numberError = true;
    }
  }

  resetOrderDoc() {
    this.orderDoc = {
      id: null,
      number: null,
      order_document_date: null,
      document_type: 0,
      attachments: [{
        id: null,
        attachment: null,
        filename: null,
      }]
    };
    this.numberError = false;
    this.isLoading = false;
  }

  getTitle() {
    if (this.isPO) {
      if (this.orderDoc.id) { return 'Edit PO'; }
      else { return 'Add PO'; }
    } else {
      if (this.orderDoc.id) { return 'Edit Order'; }
      else { return 'Add Order'; }
    }
  }
}

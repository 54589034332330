<div
  class="job-list"
  [ngStyle]="{
    'width': '100%',
    height: !collapsed ? '100%' : 'auto'
  }"
>
  <div *ngIf="!isLoadingUncategorizedView && activeJobs">
    <div class="job-container">
      <div
        class="job-list__item"
        ngClass="{{ isActive('all') ? 'job-list__name job-list__name--active' : 'job-list__name' }}"
        (click)="selectJob('all')"
       >
        <i class="bi bi-stack" style="color: black"></i>
        <div class="main-header-name">All jobs</div>
      </div>
      <div *ngIf="selectedJobId === 'all'"  class="view-list-container">
        <view-tab-list
          [activePage]="activePage"
          [selectedView]="selectedView"
          [isLoading]="isLoading"
          [isLoadingViews]="isLoadingViews"
          (scrollDown)="scrollDown()"
        ></view-tab-list>
      </div>
    </div>

    <div
      *ngIf="showUncategorizedView"
      class="job-list__item"
      ngClass="{{ isActive('uncategorized') ? 'job-list__name job-list__name--active' : 'job-list__name' }}"
      (click)="selectJob('uncategorized')"
    >
    <img alt="job-img" src="/assets/img/job.svg" />
      <div class="main-header-name">Uncategorized</div>
      <div class="notification-dot"></div>
    </div>

    <div #jobContainer class="job-display custom-scrollbar-close">
      <div
        class="job-container"
        *ngFor="let job of activeJobs; let i = index; trackBy: trackByFn"
      >
        <div class="single-job"
            (click)="selectJob(job.id)"
            ngClass="{{ isActive(job.id) ? 'job-list__name job-list__name--active' : 'job-list__name' }}"
        >
          <div class="job-item-svg" (contextmenu)="openContextMenu($event, job)">
            <svg style="min-width: 12px" width="11" height="10" viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg">
              <path [attr.fill]="getJobColor(job)" d="M6.35938 0.0625C6.05859 0.0625 5.84375 0.298828 5.84375 0.578125V4.01562C5.84375 4.31641 6.05859 4.53125 6.35938 4.53125H10.4844C10.7637 4.53125 11 4.31641 11 4.01562V0.578125C11 0.298828 10.7637 0.0625 10.4844 0.0625H6.35938ZM4.64062 0.0625H0.515625C0.214844 0.0625 0 0.298828 0 0.578125V4.01562C0 4.31641 0.214844 4.53125 0.515625 4.53125H4.64062C4.91992 4.53125 5.15625 4.31641 5.15625 4.01562V0.578125C5.15625 0.298828 4.91992 0.0625 4.64062 0.0625ZM0 5.73438V9.17188C0 9.47266 0.214844 9.6875 0.515625 9.6875H4.64062C4.91992 9.6875 5.15625 9.47266 5.15625 9.17188V5.73438C5.15625 5.45508 4.91992 5.21875 4.64062 5.21875H0.515625C0.214844 5.21875 0 5.45508 0 5.73438ZM6.35938 9.6875H10.4844C10.7637 9.6875 11 9.47266 11 9.17188V5.73438C11 5.45508 10.7637 5.21875 10.4844 5.21875H6.35938C6.05859 5.21875 5.84375 5.45508 5.84375 5.73438V9.17188C5.84375 9.47266 6.05859 9.6875 6.35938 9.6875Z" fill="#17E1D2"/>
            </svg>
            <span class="single-job-name">
              {{ formatJobName(job.name) }}
            </span>
          </div>
        </div>

        <div *ngIf="selectedJobId === job.id"  class="view-list-container">
          <view-tab-list
            [selectedView]="selectedView"
            [isLoading]="isLoading"
            [isLoadingViews]="isLoadingViews"
            (scrollDown)="scrollDown()"
          ></view-tab-list>
        </div>
      </div>
    </div>
  </div>
</div>

<p-contextMenu #cm [model]="jobMenu"></p-contextMenu>

import { ITemplates } from '../../models/dashboard.model';
import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorPostPutItemData, receivePostPutItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { ITemplatesState, TemplatesState } from '../models/templates.model';

export function templatesReducer(
  lastState: ITemplatesState = new TemplatesState(),
  action: GenericAction<DashboardActionTypes, any>
): ITemplatesState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_ACTIVE_TEMPLATES:
      return requestPostPutItemData<ITemplatesState, ITemplates>(lastState, ['templates'], action.payload);
    case DashboardActionTypes.RECEIVE_ACTIVE_TEMPLATES:
      return receivePostPutItemData<ITemplatesState, ITemplates>(lastState, ['templates'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ACTIVE_TEMPLATES:
      return errorPostPutItemData<ITemplatesState, ITemplates>(lastState, ['templates'], action.payload);

    case DashboardActionTypes.REQUEST_ADD_ACTIVE_TEMPLATE:
      return requestPostPutItemData<ITemplatesState, ITemplates>(lastState, ['templates'], action.payload);
    case DashboardActionTypes.RECEIVE_ADD_ACTIVE_TEMPLATE:
      return receivePostPutItemData<ITemplatesState, ITemplates>(lastState, ['templates'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ADD_ACTIVE_TEMPLATE:
      return errorPostPutItemData<ITemplatesState, ITemplates>(lastState, ['templates'], action.payload);

    case DashboardActionTypes.REQUEST_UPDATE_ACTIVE_TEMPLATE:
      return requestPostPutItemData<ITemplatesState, ITemplates>(lastState, ['templates'], action.payload);
    case DashboardActionTypes.RECEIVE_UPDATE_ACTIVE_TEMPLATE:
      return receivePostPutItemData<ITemplatesState, ITemplates>(lastState, ['templates'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_UPDATE_ACTIVE_TEMPLATE:
      return errorPostPutItemData<ITemplatesState, ITemplates>(lastState, ['templates'], action.payload);
    default:
      return lastState;
  }
}

import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CommonConstants } from 'src/app/common/constants/common.constants';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorService {

  constructor(private messageService: MessageService) { }

  setCustomErrorMessage(data: any) {
    let errorMessage: string;

    switch (data?.errors?.status) {
      case 400:
        const error = Object.values(data.errors?.error)[0] as any;

        if (typeof error == 'string') {
          errorMessage = CommonConstants.defaultError + ' (' + error + ')';
        }

        if (typeof error[0] === 'object') {
          const errorMessage2 = Object.values(error)[0] as string;

          if (typeof error[0] === 'object') {
            const errorMessage3 = Object.values(errorMessage2)[0] as string;
            if (typeof errorMessage3 === 'object') {
              errorMessage = Object.values(errorMessage3)[0] ? CommonConstants.defaultError + ' (' + Object.values(errorMessage3)[0] + ')' : CommonConstants.defaultError;
            } else {
              errorMessage = Object.values(errorMessage2)[0] ? CommonConstants.defaultError + ' (' + Object.values(errorMessage2)[0] + ')' : CommonConstants.defaultError;
            }
          } else {
            if (Object.keys(CommonConstants.errorMessages).includes(Object.keys(data?.errors?.error)[0])) {
              errorMessage = Object.values(error)[0] as string;
            } else {
              errorMessage = CommonConstants.defaultError + ' (' + Object.values(error)[0] + ')';
            }
          }
        } else {
          errorMessage = Object.values(data.errors?.error)[0] as string;
        }

        break;
      case 404:
        errorMessage = CommonConstants.defaultError + '(Not found)';
        break;
      case 500:
        errorMessage = CommonConstants.defaultServerError;
        break;
      default:
        errorMessage = CommonConstants.defaultError;
        break;
    }

    if (data === 'documentNotFound') {
      errorMessage = CommonConstants.defaultError + '(Document not found)'
    }

    this.messageService.clear();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: errorMessage,
      key: 'error',
    });
  }
}

import { ActiveJobsState, IActiveJobsState } from './../models/activeJobs.model';
import { GenericAction } from './../../../common/state/models/generic-action.model';
import { DashboardActionTypes } from './../actions/dashboard-action-types';
import { requestItemData, receiveItemData, errorItemData } from './../../../common/utils/reducer/reducer.utils';
import { IActiveJobs } from './../../models/dashboard.model';


export function activeJobsReducer(
  lastState: IActiveJobsState = new ActiveJobsState(),
  action: GenericAction<DashboardActionTypes, any>
): IActiveJobsState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_ACTIVE_JOBS:
      return requestItemData<IActiveJobsState, IActiveJobs>(lastState, ['activeJobs']);
    case DashboardActionTypes.RECEIVE_ACTIVE_JOBS:
      return receiveItemData<IActiveJobsState, IActiveJobs>(lastState, ['activeJobs'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ACTIVE_JOBS:
      return errorItemData<IActiveJobsState, IActiveJobs>(lastState, ['activeJobs'], action.payload);

    default:
      return lastState;
  }
}

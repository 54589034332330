import { ITemplates } from '../../../models/dashboard.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { DashboardActionTypes } from '../dashboard-action-types';

export class RequestActiveTemplates implements Action {
  readonly type: DashboardActionTypes.REQUEST_ACTIVE_TEMPLATES = DashboardActionTypes.REQUEST_ACTIVE_TEMPLATES;

  constructor(
    public payload?: ITemplates) { }
}
export class ReceiveActiveTemplates implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ACTIVE_TEMPLATES = DashboardActionTypes.RECEIVE_ACTIVE_TEMPLATES;

  constructor(
    public payload?: ITemplates) { }
}
export class ErrorReceiveActiveTemplates implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ACTIVE_TEMPLATES = DashboardActionTypes.ERROR_RECEIVE_ACTIVE_TEMPLATES;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class ReceiveAddTemplate implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ADD_ACTIVE_TEMPLATE = DashboardActionTypes.RECEIVE_ADD_ACTIVE_TEMPLATE;

  constructor(
    public payload: ITemplates) { }
}

export class RequestAddTemplate implements Action {
  readonly type: DashboardActionTypes.REQUEST_ADD_ACTIVE_TEMPLATE = DashboardActionTypes.REQUEST_ADD_ACTIVE_TEMPLATE;

  constructor(
    public payload?: string) { }
}

export class ErrorReceiveAddTemplate implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ADD_ACTIVE_TEMPLATE = DashboardActionTypes.ERROR_RECEIVE_ADD_ACTIVE_TEMPLATE;
  constructor(
    public payload: HttpErrorResponse) { }
}

export class ReceiveUpdateTemplate implements Action {
  readonly type: DashboardActionTypes.RECEIVE_UPDATE_ACTIVE_TEMPLATE = DashboardActionTypes.RECEIVE_UPDATE_ACTIVE_TEMPLATE;

  constructor(
    public payload: ITemplates) { }
}

export class RequestUpdateTemplate implements Action {
  readonly type: DashboardActionTypes.REQUEST_UPDATE_ACTIVE_TEMPLATE = DashboardActionTypes.REQUEST_UPDATE_ACTIVE_TEMPLATE;

  constructor(
    public payload?: string) { }
}

export class ErrorReceiveUpdateTemplate implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_UPDATE_ACTIVE_TEMPLATE = DashboardActionTypes.ERROR_RECEIVE_UPDATE_ACTIVE_TEMPLATE;
  constructor(
    public payload: HttpErrorResponse) { }
}

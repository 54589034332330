<p-dialog
  [(visible)]="showDialog"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [closable]="false"
  [closeOnEscape]="false"
  [position]="'top'"
  [style]="{ marginTop: '6vh', width: '80vw', maxWidth: '1500px', maxHeight: '1050px' }"
  [maskStyleClass]="'dialog-mask'"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>Templates</span>
    </div>
    <button class="dialog-close" type="button" (click)="closeDialog()"><i class="bi bi-x-lg"></i></button>
  </ng-template>

  <div class="templates">
    <div class="templates__header">
      <div class="templates__settings">
        <div></div>
        <div>
          <h5>Personal dashboard settings</h5>
          <p>
            Update these settings to select which views you see on your dashboard. This won’t affect what your teammates
            see.
          </p>
        </div>
        <p-divider layout="vertical"></p-divider>
        <div>
          <h5>Company settings</h5>
          <p>These selections impact what your teammates can see and edit.</p>
        </div>
      </div>

      <div class="templates__columns">
        <div></div>
        <h5>Applied to jobs</h5>
        <h5>Applied to each new job</h5>
        <p-divider layout="vertical"></p-divider>
        <h5>Hidden</h5>
        <h5>Filter</h5>
        <h5>Who can view and edit template</h5>
        <h5>Created by</h5>
      </div>
    </div>

    <materlog-loader class="loader" *ngIf="isLoading"></materlog-loader>

    <div *ngIf="templateList?.length < 1 && !isLoading">No templates available</div>
    <div *ngIf="!isLoading">
      <div class="templates__row" *ngFor="let template of templateList; let i = index">
        <input
          type="text"
          class="form-controls"
          [(ngModel)]="template.name"
          (change)="onUpdateTemplate(template)"
          [disabled]="!template.is_owner"
        />
        <div class="templates-dropdown job">
          <p-multiSelect
            [options]="jobList"
            appendTo="body"
            [id]="i + 'applied_to'"
            optionValue="id"
            optionLabel="name"
            placeholder="Select"
            name="applied_to_job"
            [(ngModel)]="template.jobs"
            (onChange)="onUpdateTemplate(template)"
          >
          </p-multiSelect>
        </div>
        <div class="templates-switch">
          <p-inputSwitch
            styleClass="filters__container-fields-content-item-switch"
            [(ngModel)]="template.apply_to_each_new_jobs"
            (onChange)="onUpdateTemplate(template)"
          ></p-inputSwitch>
        </div>
        <p-divider layout="vertical"></p-divider>
        <p>{{ template.hidden_fields_count + ' hidden fields' }}</p>
        <p>{{ template.filters_count + ' filters applied' }}</p>
        <div class="templates-dropdown">
          <p-dropdown
            [disabled]="!template.is_owner"
            [options]="privacyList"
            appendTo="body"
            [id]="i + 'privacy'"
            optionValue="id"
            optionLabel="value"
            placeholder="Select"
            name="privacy"
            [(ngModel)]="template.privacy"
            (onChange)="onUpdateTemplate(template)"
          >
          </p-dropdown>
        </div>
        <p class="creator">{{ template.added_by_name }}</p>
        <fa-icon
          *ngIf="template.is_owner"
          class="delete"
          [icon]="faXMark"
          (click)="deleteTemplate(template.id)"
        ></fa-icon>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer"> </ng-template>
</p-dialog>

<p-toast position="top-center" key="error"></p-toast>
<p-toast position="top-center" key="success"></p-toast>

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { TableConstants } from 'src/app/common/constants/table.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { SelectedViewModel } from '../../models/selected-view.model';
import { JobTableService } from '../../services/job-table.service';
import { faImages , faImage} from "@fortawesome/free-solid-svg-icons";
import { DashboardService } from "../../services/dashboard.service";
import {deepCopy, deepArrayClone} from "../../../common/utils/general"


@Component({
  selector: 'resizer',
  templateUrl: './resizer.component.html',
  styleUrls: ['./resizer.component.sass']
})
export class ResizerComponent extends OnDestroyMixin() implements OnChanges {
  @Input() selectedView?: SelectedViewModel;
  @Input() isLoading!: boolean;
  @Input() mailBoxVisible: boolean = false;

  @Output() imageSizeChange = new EventEmitter<string>();

  image_size: string = "image-size-choice-small";
  faResize = faImages;
  faImageOutline = faImage;
  toggleFieldsModal: boolean = false;
  customization: any;

  fields = TableConstants.jobItemsTableColumns;

  constructor(
    private dashboardActions: DashboardActions,
    private dashboardService: DashboardService,
    private jobTableService: JobTableService) {
    super();
      (window as any)['image'] = this;
  }

  get resizeText() {
    return this.mailBoxVisible ? '': 'Resize';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.selectedView?.currentValue && !!this.selectedView) {
      if(this.selectedView && this.selectedView.activeView && this.selectedView.activeView.customization) {
        this.customization = deepCopy(this.selectedView.activeView.customization);
        if (this.selectedView.activeView) {
          this.image_size = this.selectedView.activeView.customization["image_size"] || 'small';
        }
      }
      if(this.image_size.endsWith('large')){
        this.jobTableService.setImageSize('large');
        this.imageSizeChange.emit('large');
      }else if(this.image_size.endsWith('medium')){
        this.imageSizeChange.emit('medium');
        this.jobTableService.setImageSize('medium');
      }else if(this.image_size.endsWith('small')){
        this.imageSizeChange.emit('small');
        this.jobTableService.setImageSize('small');
      }
    }
  }

  get imageNotSmall(): number{
    return !this?.customization?.image_size || this.customization?.image_size == 'small' ? 0: 1;
  }

  isChosen(match:string){
    if(match.endsWith('large') && this.image_size.endsWith('large')){
      return true;
    }else if(match.endsWith('medium') && this.image_size.endsWith('medium')){
      return true;
    }else if(match.endsWith('small') && this.image_size.endsWith('small')){
      return true;
    }
    return false;
  }

  image_size_change(event:any){
    if(event.target.id.endsWith('large')){
      this.image_size = this.customization["image_size"] = 'large';
      this.jobTableService.setImageSize('large');
    }else if(event.target.id.endsWith('medium')){
      this.image_size = this.customization["image_size"] = 'medium';
      this.jobTableService.setImageSize('medium');
    }else if(event.target.id.endsWith('small')){
      this.image_size = this.customization["image_size"] = 'small';
      this.jobTableService.setImageSize('small');
    }
    this.triggerFieldsChange();
  }

  clickOutsideModal(event: any): void {
    if (event.target.id !== 'resize-modal-button') {
      this.toggleFieldsModal = false;
    }
  }


  showFieldsModal(event: any): void {
    event.preventDefault();
    this.toggleFieldsModal = !this.toggleFieldsModal;
  }

  hideAll(): void {
    this.fields.slice(1).forEach((field: any) => (field.visible = false));
    this.triggerFieldsChange();
  }

  showAll(): void {
    this.fields.forEach((field: any) => (field.visible = true));
    this.triggerFieldsChange();
  }

  reset(): void {
    this.triggerFieldsChange();
  }


  triggerFieldsChange(): void {
    this.dashboardActions.requestSaveView('customization',this.customization,this.selectedView?.activeView.id);
  }

  trackByFn(index: number) {
    return index;
  }
}

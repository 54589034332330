


export let freshdesk_map_item = [
  {'Name': {fr_1: 'item_name', mlog: 'name'}},
  {'Description': {fr_1: 'item_description', mlog: 'description'}},
  {'Quantity': {fr_1: 'item_quantity', mlog: 'quantity', number: true}},
  /* {'Sidemark': {fr_1: 'item_sidemark', mlog: 'item_sidemark'}}, */
  {'Unit cost': {fr_1: 'item_unit_cost', mlog: 'cost_per_unit', number: true}},
  /* {'Total cost': {fr_1: 'item_total_cost', mlog: 'item_total_cost', number: true}}, */
  {'SKU': {fr_1: 'item_sku', mlog: 'sku'}},
  {'Spec': {fr_1: 'item_spec', mlog: 'spec'}},
  {'Is COM': {fr_1: 'is_material_component', mlog: 'item_com_selection', dropdown: true}},
  {'In stock date': {fr_1: 'item_in_stock_date', mlog: 'in_stock_date'}},
  {'Ship date': {fr_1: 'item_ship_date', mlog: 'ship_date'}},
  {'Delivery date': {fr_1: 'item_delivery_date', mlog: 'delivery_date'}},
  {'Status': {fr_1: 'item_status', mlog: 'status', dropdown: true}},
  {'Unit of measure': {fr_1: 'item_unit_of_measure', mlog: 'unit_of_measure', dropdown: true}},
  {'Supplier tracking': {fr_1: 'tracking_info', mlog: 'tracking_info', array: true}},
  {'Shipment tracking': {fr_1: 'shipment_tracking', mlog: 'shipment_tracking', array: true}},
];

export let freshdesk_map_order = [
  {'Order': {fr_1: 'cf_order_id', mlog: 'order_id'}},
  {'Job': {fr_1: 'cf_job_names', fr_2: 'cf_job_name', mlog: 'job_id', dropdown: 'job_id'}},
  {'Supplier': {fr_1: 'cf_supplier_names', fr_2: 'cf_supplier_name', mlog: 'supplier_id', dropdown: 'supplier_id'}},
  {'Order docs': {fr_1: 'order_doc_ids'}},
  {'Bill to name': {fr_1: 'cf_bill_to_name', mlog: 'billing_name'}},
  {'Bill to zip': {fr_1: 'cf_bill_to_zip', mlog: 'billing_zip'}},

  {'Ship to name': {fr_1: 'cf_ship_to_name', mlog: 'shipping_name'}},
  {'Ship to address': {fr_1: 'cf_ship_to_address', mlog: 'shipping_address'}},
  {'Ship to city': {fr_1: 'cf_ship_to_city', mlog: 'shipping_city'}},
  {'Ship to state': {fr_1: 'cf_ship_to_state', mlog: 'shipping_state', dropdown: 'shipping_state'}},
  {'Ship to zip': {fr_1: 'cf_ship_to_zip', mlog: 'shipping_zip'}},

  /* {'Sidemark': {fr_1: 'cf_sidemark', mlog: 'order_sidemark'}}, */
  {'Supplier contact': {fr_1: 'cf_supplier_contact_email', mlog: 'supplier_contact'}},
  {'Total cost': {fr_1: 'cf_total_cost', mlog: 'total_cost'}},
  {'Discount': {fr_1: 'cf_discount', mlog: 'discount'}},
  {'Sales tax': {fr_1: 'cf_tax', mlog: 'sales_tax'}},
  {'Shipping cost': {fr_1: 'cf_shipping_cost', mlog: 'shipping_cost'}},
  {'In stock date': {fr_1: 'cf_in_stock_date', mitem: 'in_stock_date', item_level: true}},
  {'Ship date': {fr_1: 'cf_ship_date', mitem: 'ship_date', item_level: true}},
  {'Delivery date': {fr_1: 'cf_delivery_date', mitem: 'delivery_date', item_level: true}},
  {'Payment method': {fr_1: 'cf_payment_methods', mlog: 'payment_method', dropdown: 'payment_method'}},
  {'Delivery method': {fr_1: 'cf_delivery_services', mlog: 'delivery_method', dropdown: 'delivery_method', item_level: true}},
  {'Ship via': {fr_1: 'cf_ship_via', mlog: 'ship_via'}},
  {'Last four cc': {fr_1: 'cf_last_four_cc', mlog: 'last_4_card_digits'}},
  {'Supplier tracking': {fr_1: 'tracking_info', mitem: 'tracking_info', array: true, item_level: true}},
  {'Shipment tracking': {fr_1: 'shipment_tracking', mitem: 'shipment_tracking', array: true, item_level: true}},
];

export function label_for_id(data: any, type: any, search: any, inverse: boolean = false) {
  let all_jobs = data.data.jobs;
  let all_suppliers = data.data.suppliers;
  let options = data.options;

  let to_search = null;
  if (type == 'job_id') {
    to_search = all_jobs;
  } else if (type == 'supplier_id') {
    to_search = all_suppliers;
  } else {
    to_search = options[type];
  }
  if (inverse) {
    return to_search.find((i: any) => i.value == search);
  } else {
    return to_search.find((i: any) => i.id == search);
  }
}

function merge_and_group(data: any) {
  const grouped: any = {};
  const order: any = [];
  data.forEach(({id, value}: {id: string, value: any}) => {
    if (!grouped.hasOwnProperty(id)) {
      order.push(id);
      grouped[id] = [value];
    } else {
      grouped[id].push(value);
    }
  });
  
  // Convert back to array, preserving the order
  const mergedArray = order.map((id: any) => {
    const values = grouped[id];
    return values.length === 1 ? { id, value: values[0] } : { id, value: values };
  });
  return mergedArray;
}

let moneyFields = ['Total cost', 'Discount', 'Sales tax', 'Shipping cost', 'Unit cost'];

function createItemData(data: any) {
  let item_data: any = [];
  let fr_items = data.result.freshdesk.items || [];
  let items = data.result.items;

  let idx = 1;
  items.forEach((i: any) => {
    let ivalues: any = [];
    let cur_item: any = {};
    let fri = fr_items.find((fi: any) => fi.id == i.id);
    freshdesk_map_item.forEach((entry: any) => {
      Object.entries(entry).forEach(([label, fields]) => {
        let mlog = (fields as any).mlog;
        let is_fr = fri && (fri[mlog] != undefined);
        let value = is_fr ? fri[mlog] : i[mlog];
        if (['Supplier tracking', 'Shipment tracking'].includes(label)) {
          let value: any = [];
          if (fri && fri[mlog]) {
            if (Array.isArray(fri[mlog])) value = value.concat(fri[mlog]);
            else value = value.concat(fri[mlog].split('\n'));
          }
          if (i && i[mlog]) {
            if (Array.isArray(i[mlog])) value = value.concat(i[mlog]);
            else value = value.concat(i[mlog].split('\n'));
          }
          value = [...new Set(value)];
          let ship_values = [];
          if (value.length) {
            value.forEach((v: any) => {
              ship_values.push({value: v ? v: '--', fr: is_fr})
            });
          } else {
            ship_values.push({value: '--', fr: is_fr});
          }
          ivalues.push({id: label, value: ship_values});

        } else {
          if (moneyFields.includes(label)) {
            const numericValue = parseFloat(value);
            if (!isNaN(numericValue)) {
              value = `$${numericValue.toFixed(2)}`;
            }
          }
          ivalues.push({id: label, value: {value: value, fr: is_fr}});
        }
        if (label == 'Status') cur_item.status = value;
        if (label == 'Name') cur_item.name = value;
      });
    });
    ivalues = merge_and_group(ivalues);
    cur_item.value = ivalues;
    cur_item.item_id = i.id;
    cur_item.id = `Item ${idx}`;
    cur_item.fr = fri != undefined;
    idx++;
    item_data.push(cur_item);
  })

  return item_data;
}

function formatOrderDocData(order_data: any) {
  let out: any = [];
  order_data.forEach((o: any) => {
    if (o.number) out.push({fr: o.fr, value: o.number});
    if (o.type) out.push({fr: o.fr, value: o.type});
    if (o.date) out.push({fr: o.fr, value: o.date});
    out.push(' ');
  });
  if (out.length) {
    out.pop();
  }
  return out;
}

function formatPoDate(dateString: string) {
  const parts = dateString.split("/");
  return `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
}

function createOrderDocData(value: any, data: any) {
  let items = data.result.items;
  let item = items.length? items[0]: null;
  let fr = data.result.freshdesk || {};
  let fr_order_data: any = [];
  let order_data: any = [];
  let odoc: any = [];
  for (let i = 1; i <= 10; i++) {
    let cur: any = {};
    let added = false
    value.forEach((arrayItem: any) => {
      for (const [key, val] of Object.entries(arrayItem as any)) {
        let ival = fr[`${val}_${i}`];
        if (ival) {
          if (val == 'cf_order_document_date') ival = ival.replaceAll('/', '-');
          let k = val == 'cf_order_document' ? 'number' :
                  val == 'cf_order_document_types' ? 'type': 'date';
          cur[k] = ival;
          added = true;
        }
      }
    });
    if (added) {
      cur.fr = true;
      fr_order_data.push(cur);
    }
  }
  if (item) {
    if (item.po_document_number) {
      order_data.push({number: item.po_document_number.number,
                type: 'PO',
                date: item.po_document_date ? formatPoDate(item.po_document_date): ''});
    }
    if (item.supplier_documents_numbers) {
      item.supplier_documents_numbers.forEach((d: any) => {
        let ftype = label_for_id(data, 'document_types', d.document_type);
        order_data.push({number: d.number,
                  type: ftype ? ftype.value: 'Other',
                  date: d.order_document_date ? d.order_document_date.replaceAll('/', '-'): ''});
      });
    }
  }

  if (!fr_order_data.length && !order_data.length) {
    return undefined;
  } else if (!fr_order_data.length && order_data.length) {
    return formatOrderDocData(order_data);
  } else if (fr_order_data.length && !order_data.length) {
    return formatOrderDocData(fr_order_data);
  } else {
    let out: any = [];
    let dont: any = [];
    fr_order_data.forEach((o: any) => {
      let found = order_data.find((od: any) => o.number == od.number);
      if (found) {
        dont.push(o.number);
        o.date = o.date ? o.date:
                  found.date ? found.date : '';
        out.push(o);
      } else {
        out.push(o);
      }
    });
    order_data.forEach((o: any) => {
      if (!dont.find((f: any) => f == o.number)) out.push(o);
    });
    return formatOrderDocData(out);
  }
}

function createOrderData(data: any) {
  let items = data.result.items;
  let item = items.length? items[0]: null;
  let fr = data.result.freshdesk;

  let options = data.options;
  let docs: any = [];

  let order_data: any = [];
  let empty_data: any = [];
  freshdesk_map_order.forEach((val: any) => {
    Object.entries(val).forEach(([label, value]) => {
      
      if (label == 'Order docs') {
        let values: any = [];
        if (item) {
          values = value && (value as any).fr_1 ? fr[(value as any).fr_1] : [];
          values = values || [];
          let odocs: any = [];
          let doc_atts: any = [];
          if (item.po_document_number?.id) odocs.push(item.po_document_number);
          if (item.supplier_documents_numbers?.length) odocs = odocs.concat(item.supplier_documents_numbers);
          if (item.po_document_attachments?.length) doc_atts.push(item.po_document_attachments);
          if (item.supplier_documents_attachments?.length) doc_atts = doc_atts.concat(item.supplier_documents_attachments);
          let fr_docs = odocs.filter((d: any) => values && values.includes(d.id));
          fr_docs = fr_docs.map((f: any) => ({fr: true, value: f}));
          let not_fr_docs = odocs.filter((d: any) => values && !values.includes(d.id));
          not_fr_docs = not_fr_docs.map((f: any) => ({fr: false, value: f}));
          values = fr_docs.concat(not_fr_docs);
          values.forEach((v: any) => {
            v.data = {id: item.id},
            v.colDef = 'po_document_number';
            let atts = doc_atts.find((a: any) => a.order_document_id == v.value.id);
            v.att = atts ? [atts]: undefined;
          })
        }
        if (!values.length) values.push({
          fr: false, data: {id: item?.id}, colDef: 'po_document_number', att: undefined
        });
        order_data.push({id: 'Order docs', value: values});
      } else if (['Supplier tracking', 'Shipment tracking'].includes(label)) {
        let ival = value && (value as any).fr_1 && fr[(value as any).fr_1] && fr[(value as any).fr_1].length ?
                    fr[(value as any).fr_1]
                    : undefined;
        ival = ival ? [...new Set(ival)]: undefined;
        ival = ival ? ival.map((t: any) => ({fr: true, value: t})): undefined;
        let cur = {id: label, value: ival};
        if (ival) order_data.push(cur);
        else {
          cur.value = [{fr: false, value: '--'}];
          empty_data.push(cur);
        }
      } else {
        let been_added = false;
        let ival = null;
        for (const [key, val] of Object.entries(value as any)) {
          if (been_added) break;
          if (key.startsWith('fr') && fr[val as any]) {
            ival = fr[val as any];
            been_added = true;
          } else if (key.startsWith('mlog') && item) {
            if (options[val as any]) {
              let label_val = label_for_id(data, (value as any).dropdown || val, item[val as any]);
              if (label_val) ival = label_val.value; 
            } else {
              ival = item[val as any];
            }
          }
        }
        if (moneyFields.includes(label)) {
          const numericValue = parseFloat(ival);
          if (!isNaN(numericValue)) {
            ival = `$${numericValue.toFixed(2)}`;
          }
        }
        let cur = {id: label, value: {fr: been_added, value: ival}}
        if (ival && been_added)  order_data.push(cur);
        else empty_data.push(cur);
      }
    });
  })
  order_data = order_data.concat(empty_data);
  return merge_and_group(order_data);
}


export function createOrderItemData(data: any) {
  let order_data: any = createOrderData(data);
  let item_data: any = createItemData(data);
  return {'order': order_data, 'item': item_data};

}
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { INotifications, IUserManagement, IUserPassword } from '../../models/user.model';
import { NotificationsService } from '../../services/notifications.service';
import { UserService } from '../../services/user.service';
import { UserActionTypes } from '../actions/user-action-types';
import { ErrorReceiveNotifications, ErrorReceiveUpdateNotification, ReceiveNotifications, ReceiveUpdateNotification } from '../actions/user-action-types-creators/notifications-action-types-creators';
import { ErrorReceiveUpdateUserPassword, ReceiveUpdateUserPassword } from '../actions/user-action-types-creators/password-action-types-creators';
import { ErrorReceiveUpdateUserProfile, ErrorReceiveUserProfile, ReceiveUpdateUserProfile, ReceiveUserProfile } from '../actions/user-action-types-creators/user-profile-action-types-creators';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private userService: UserService
  ) { }

  requestNotifications$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.REQUEST_NOTIFICATIONS),
      switchMap(() =>
        this.notificationsService.getNotifications().pipe(
          map(
            (notifications: any) => new ReceiveNotifications(notifications)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveNotifications(error)))
        )
      )
    );
  });

  requestUpdateNotification$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.REQUEST_UPDATE_NOTIFICATION),
      switchMap((notifications: INotifications) =>
        this.notificationsService.updateNotification(notifications).pipe(
          map((notification: any) => new ReceiveUpdateNotification(notification)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateNotification(error)))
        )
      )
    );
  });

  requestUserProfile$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.REQUEST_USER_PROFILE),
      switchMap(() => this.userService.getUserProfile().pipe(
        map((userProfile: any) => new ReceiveUserProfile(userProfile)),
        catchError((error: HttpErrorResponse) => of(new ErrorReceiveUserProfile(error)))
      )
      )
    );
  });

  requestUpdateUserProfile$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.REQUEST_UPDATE_USER_PROFILE),
      switchMap((updateUserProfile: IUserManagement) =>
        this.userService.updateUserProfile(updateUserProfile).pipe(
          map((userProfile: any) => new ReceiveUpdateUserProfile(userProfile)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateUserProfile(error)))
        )
      )
    );
  });

  requestUpdateUserPassword$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.REQUEST_UPDATE_USER_PASSWORD),
      switchMap((updateUserPassword: IUserPassword) =>
        this.userService.updatePassword(updateUserPassword).pipe(
          map((userPassword: any) => new ReceiveUpdateUserPassword(userPassword)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateUserPassword(error)))
        )
      )
    );
  });

}

import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IJobs } from '../../models/dashboard.model';

export interface IJobsState {
  jobs: IStoreApiItem<IJobs>;
  jobsList: IStoreApiItem<IJobs>;
}

export class JobsState implements IJobsState {
  jobs: IStoreApiItem<IJobs> = new StoreApiItem();
  jobsList: IStoreApiItem<IJobs> = new StoreApiItem();
}

import { Component } from '@angular/core';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Observable, ReplaySubject } from 'rxjs';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { PayloadService } from "../../../services/payload.service";

@Component({
  selector: 'images-cell-renderer-readonly',
  templateUrl: './images-readonly-cell-renderer.component.html',
  styleUrls: ['images-readonly-cell-renderer.component.sass'],
})

export class ImagesReadonlyCellRenderer implements ICellRendererAngularComp {
  public displayValue!: any;
  readonly faEye = faEye;
  public size = 30;
  params!: any;
  imagesList: any[] = [];

  constructor(
    private dashboardActions: DashboardActions,
    private payloadService: PayloadService,
  ) { }

  agInit(params: ICellRendererParams<any, number>): void {
    this.params = params;

    this.size = this.params.node.rowHeight;
  }

  refresh(params: ICellRendererParams) {
    return false;
  }


  addImages(key: string, files: File[], id: string) {
    this.imagesList = [];

    for (const item of files) {
      this.onSendImages(key, item, id, files.length);
    }
  }

  onSendImages(key: string, file: any, id: string, length: number) {
    this.onConvertBase64(file).subscribe((base64: string) => {
      this.imagesList.push({
        image_name: file.name,
        image: base64,
      });

      if (this.imagesList.length === length) {

        this.dashboardActions.requestUpdateViewCell({ [`${key}`]: this.imagesList, id });
        setTimeout(() => {
          this.dashboardActions.requestViews({...this.payloadService.setViewsPayload()});
        }, 300);
      }
    });
  }

  onConvertBase64(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();

    reader.readAsBinaryString(file);
    reader.onload = (event: any) => result.next(btoa(event.target?.result?.toString()));

    return result;
  }

  deleteFile(attachment_id: string) {

    this.dashboardActions.requestAttachmentDelete({ image_id_to_delete: attachment_id, id: this.params.data.id });
    setTimeout(() => {

      this.dashboardActions.requestViews({...this.payloadService.setViewsPayload()});
    }, 300);


  }
}

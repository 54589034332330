import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { AuthenticationActionTypes } from '../authentication-action-types';
import { ISingUp } from '../../../models/authentication.model';
import { ILoginResponse } from './../../../models/authentication.model';


export class RequestSingUp implements Action {
  readonly type: AuthenticationActionTypes.REQUEST_SING_UP = AuthenticationActionTypes.REQUEST_SING_UP;

  constructor(
    public payload: ISingUp) { }
}

export class ReceiveSingUp implements Action {
  readonly type: AuthenticationActionTypes.RECEIVE_SING_UP = AuthenticationActionTypes.RECEIVE_SING_UP;

  constructor(
    public payload: ILoginResponse) { }
}

export class ErrorReceiveSingUp implements Action {
  readonly type: AuthenticationActionTypes.ERROR_SING_UP = AuthenticationActionTypes.ERROR_SING_UP;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestUserOptions implements Action {
  readonly type: AuthenticationActionTypes.REQUEST_USER_OPTIONS = AuthenticationActionTypes.REQUEST_USER_OPTIONS;
}

export class ReceiveUserOptions implements Action {
  readonly type: AuthenticationActionTypes.RECEIVE_USER_OPTIONS = AuthenticationActionTypes.RECEIVE_USER_OPTIONS;

  constructor(
    public payload: any) { }
}

export class ErrorReceiveUserOptions implements Action {
  readonly type: AuthenticationActionTypes.ERROR_USER_OPTIONS = AuthenticationActionTypes.ERROR_USER_OPTIONS;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestCompany implements Action {
  readonly type: AuthenticationActionTypes.REQUEST_COMPANY = AuthenticationActionTypes.REQUEST_COMPANY;

  constructor(
    public payload: any) { }
}

export class ReceiveCompany implements Action {
  readonly type: AuthenticationActionTypes.RECEIVE_COMPANY = AuthenticationActionTypes.RECEIVE_COMPANY;

  constructor(
    public payload: any) { }
}

export class ErrorReceiveCompany implements Action {
  readonly type: AuthenticationActionTypes.ERROR_COMPANY = AuthenticationActionTypes.ERROR_COMPANY;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestRole implements Action {
  readonly type: AuthenticationActionTypes.REQUEST_ROLE = AuthenticationActionTypes.REQUEST_ROLE;

  constructor(
    public payload: any) { }
}

export class ReceiveRole implements Action {
  readonly type: AuthenticationActionTypes.RECEIVE_ROLE = AuthenticationActionTypes.RECEIVE_ROLE;

  constructor(
    public payload: any) { }
}

export class ErrorReceiveRole implements Action {
  readonly type: AuthenticationActionTypes.ERROR_ROLE = AuthenticationActionTypes.ERROR_ROLE;

  constructor(
    public payload: HttpErrorResponse) { }
}

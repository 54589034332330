import { Component, OnInit } from '@angular/core';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';

@Component({
  selector: 'materlog-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.sass']
})
export class CompanySettingsComponent extends OnDestroyMixin() implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}

import { ClassNameService } from './utils';
import { ViewChild, ElementRef, Renderer2, Injectable, Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject, timer, debounceTime } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { map, switchMap } from 'rxjs/operators';
import { HostListener } from '@angular/core';
import { ViewChildren, QueryList } from '@angular/core';
import { IInputMessages } from "../../models/dashboard.model";
import { MailBoxCommunicationService } from '../../services/mailbox_comm.service';
import { SelectedViewModel } from '../../models/selected-view.model';
import { Store } from '@ngrx/store';
import { deepCopy } from 'src/app/common/utils/general';
import { JobsService } from '../../services/job.service';
import { selectActiveJobs, selectSuppliers, selectViews} from '../../state/selectors/dashboard.selector';
import {unzipByteData, decodeMsgPackSimple} from 'src/app/dashboard/services/do_msgpack';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CalendarModule } from 'primeng/calendar';
import { initData } from './utils';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { DashboardService } from '../../services/dashboard.service';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';


interface MessageInfo {
  subject: string;
  snippet: string;
  to: string;
  from: string;
  attachments: boolean;
  attachments_paths: any;
}

interface Mail {
  id: string; // or number, depending on your data
  message_timestamp: string; // assuming string, adjust as needed
  message_id: string;
  order_id: string;
  order_name: string;
  supplier_name: string;
  supplier_id: string;
  job_name: string;
  job_id: string;
  message_info: MessageInfo;
}


@Component({
  selector: 'app-mail-list',
  templateUrl: './mail-list.component.html',
  styleUrls: ['./mail.css', '../comment-view/comment-pane.component.sass'],
})
export class MailListComponent {
  @ViewChild('emailViewer') emailViewer!: ElementRef<HTMLDivElement>;
  @ViewChild('emailContainer') emailContainer!: ElementRef<HTMLDivElement>;
  @Input() selectedView?: SelectedViewModel;
  @Input() makeVisible: boolean = false;
  @Input() cardView: boolean = false;
  @Input() cardViewShowDrafts: boolean = false;
  @Input() card: any;
  @Input() cardHeight: number = 160;
  @Input() isLoading: any;
  @Input() allMailData: any;

  selectedOrder: any;
  selectedSupplier: any;
  selectedJob: any;

  cardViewBeenInited: boolean = false;
  hasBeenInited: boolean = false;
  mailResultsMap: Map<string, string> = new Map<string, string>();
  mailResultsShowMap: Map<string, string> = new Map<string, string>();
  searchQuery: string = '';
  private baseUrl: string = environment.ENV.BASE_URL;

  editor_config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '100',
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        // 'bold',
        // 'italic',
        // 'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        // 'indent',
        // 'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        // 'fontName'
      ],
      [
        // 'fontSize',
        // 'textColor',
        // 'backgroundColor',
        'customClasses',
        // 'link',
        '// unlink',
        // 'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],
    enableToolbar: true,
    showToolbar: true,
    sanitize: false,
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    toolbarPosition: 'bottom'
  };
  documentModal: boolean = false;
  selectedAttachment = undefined;

  loading = false;
  moreMailLoading = false;
  dropdownLoading = false;

  selectedMailId!: string;
  curId!: string;
  fetchingBody: boolean = false;

  all_items!: any[];
  filtered_items!: any[];

  checkin_items!: any[];
  filtered_checkin_items!: any[];

  lastAllMailTime = '';
  lastFilterMailTime = '';
  lastCheckinEmailTime = '';
  lastCheckinFilterMailTime = '';

  job_string = 'Job';
  sup_string = 'Supplier';
  order_string = 'Order';

  lastResultsCount: number = 25;
  lastCheckinCount: number = 25;
  savedJobId: string = '';

  @ViewChild('emailDiv') emailDiv!: ElementRef;
  @ViewChild('emailFilterDraft') emailFilterDraft!: ElementRef;
  allEmailsOption = [
    {id: 'inbox', value: 'Inbox'},
    {id: 'drafts', value: 'Drafts & Scheduled'}
  ];
  emailDropDownVisible: boolean = false;
  selectedEmailOption = this.allEmailsOption[0];
  isEditingBody: boolean = false;

  @ViewChild('emailBodyTextareaTo') emailBodyTextareaTo!: ElementRef;
  @ViewChild('emailBodyTextareaCc') emailBodyTextareaCc!: ElementRef;
  @ViewChild('emailBodyTextareaSubject') emailBodyTextareaSubject!: ElementRef;
  @ViewChild('emailCcNbsp') emailCcNbsp!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;

  @ViewChild('jobDiv') jobDiv!: ElementRef;
  @ViewChild('emailFilterJob') emailFilterJob!: ElementRef;
  allJobsOption = {id: 'all', value: 'All Jobs'};
  jobDropDownVisible: boolean = false;
  @ViewChild('supplierDiv') supplierDiv!: ElementRef;
  @ViewChild('emailFilterSupplier') emailFilterSupplier!: ElementRef;
  allSuppliersOption = {id: 'all', value: 'All Suppliers'};
  supplierDropDownVisible: boolean = false;
  @ViewChild('orderDiv') orderDiv!: ElementRef;
  @ViewChild('emailFilterOrder') emailFilterOrder!: ElementRef;
  allOrdersOption = {id: 'all', value: 'All Orders'};

  calendarVisible = false;
  orderDropDownVisible: boolean = false;
  private bodyChanges = new Subject<any>();

  constructor(
    private classNameService: ClassNameService,
    private httpClient: HttpClient,
    private mailCommunicationService: MailBoxCommunicationService,
    private renderer: Renderer2,
    private jobService: JobsService,
    private store: Store,
    private djv: DashboardJobView,
    private dashboardService: DashboardService,
    private httpHelper: HttpHelper,
    private cdr: ChangeDetectorRef,
    ) {
      (window as any)['mail'] = this;
    }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: any) {
    if (changes?.allMailData?.currentValue && this.cardView) {
      this.initCardView();
    }

    if (changes?.selectedView?.currentValue && this.makeVisible) {
      let cur_job_id = changes.selectedView.currentValue.selectedJobId;
      if (cur_job_id == 'uncategorized') {
        this.all_items = [];
        this.filtered_items = [];
        this.setJob('');
        return;
      };
      if (cur_job_id != this.savedJobId && this.selectedEmailOption.id == 'inbox') {
        // this.selectedEmailOption = this.allEmailsOption[0];
        this.mailCommunicationService.get_item_data({isVisible: false});
        this.getEmailsForJob(cur_job_id);
      }
      this.setJob(cur_job_id);
      this.doFilterResults(this.all_items);
    }
  }

  initCardView() {
    if (this.cardViewBeenInited) return;
    this.loading = true;
    let order: any = undefined;
    if (this.card?.is_order) {
      order = this.card?.items[0]?.orderIdVal;
    } else {
      order = this.allMailData?.curOrders?.find((o: any) => o.id == this.card.items[0].order_id);
    }
    if (order && this.allDataLoaded()) {
      if (this.cardViewBeenInited) return;
      this.cardViewBeenInited = true;
    } else {
      return;
    }
    let job: any = this.allMailData.jobsArray.find((o: any) => o.id == this.card.items[0].job_id);
    let supplier = this.allMailData.supArray.find((o: any) => o.id == this.card.items[0].supplier_id);
    this.selectedOrder = order;
    this.selectedSupplier = supplier;
    this.selectedJob = job;
    this.moreMailLoading = true;
    this.setJob(job?.id);
    if (this.cardViewShowDrafts) {
      this.getDrafts();
    } else {
      this.getEmailsForOrder(order?.id);
    }
    this.setScrollBar('.comment-display');
  }

  setScrollBar(v: string) {
    setTimeout(() => {
      let jdisplays = document.querySelectorAll(v);
      if (jdisplays) {
        jdisplays.forEach((jdisplay: any) => {
          jdisplay.addEventListener('mouseenter', function() {
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
          });
          jdisplay.addEventListener('mouseleave', function() {
            (jdisplay as any).classList.remove('custom-scrollbar');
            (jdisplay as any).classList.add('custom-scrollbar-close');
          });
        })
      }
    }, 500);

  }

  allDataLoaded() {
    return (this.allMailData?.curOrders && this.allMailData?.jobsArray
            && this.allMailData?.activeJobsList && this.allMailData?.supArray) || false;
  }
  get dataLoaded() {
    return !this.loading && this.allDataLoaded(); 
  }

  closeToggle() {
    this.djv.close_email();
  }

  setJob(job_id: string) {
    this.selectedJob = this.allMailData?.activeJobsList?.find((j: any) => j.id == job_id);
    this.savedJobId = job_id;
  }

  ngOnInit() {
    if (this.cardView) {
      this.selectedEmailOption = this.cardViewShowDrafts ? 
            this.allEmailsOption[1]: this.allEmailsOption[0];
    }
    this.setJob(JSON.parse(localStorage.getItem('selectedJob') as any));
    this.bodyChanges.pipe(
      debounceTime(2000)
    ).subscribe((value: any) => {
      this.saveBody(value);
    });

    this.mailCommunicationService.inboxVisibility$.subscribe((data: any) => {

      let isVisible = data.show;
      let drafts = data.drafts;
      this.makeVisible = isVisible;
      if (!isVisible) {
        this.curId = '';
        this.selectedMailId = '';
        return;
      }
      this.loading = true;
      let job_id = this.selectedView?.selectedJobId || 'all';
      this.setJob(job_id);
      this.selectedOrder = this.allOrdersOption;
      this.selectedSupplier = this.allSuppliersOption;
      if (drafts) {
        this.selectedEmailOption = this.allEmailsOption[1];
        this.getDrafts();
      } else {
        this.selectedEmailOption = this.allEmailsOption[0];
        if (this.selectedView?.selectedJobId == 'uncategorized') return;
        this.getEmailsForJob(job_id);
      }

      this.setScrollBar('.comment-display');
    });
  }

  emailsForValue() {
    this.lastAllMailTime = '';
    this.lastFilterMailTime = '';
    this.all_items = [];
    this.filtered_items = [];
    this.loading = true;
    this.dropdownLoading = true;
  }

  getEmailsForOrder(order_id: string) {
    let params = {'order_id': order_id};
    this.initOnFirstToggle(params);
  }

  getEmailsForJob(job_id: string) {
    this.setJob(job_id);
    let params = this.savedJobId == 'all' ? {} : {'job_id': this.savedJobId};
    this.initOnFirstToggle(params);
  }

  initOnFirstToggle(params: any) {
    this.loadInputMessages(params)
      .subscribe((result: any) => {
        this.hasBeenInited = this.hasBeenInited || this.selectedJob?.id == 'all';
        this.all_items = [...result.results];
        this.createOrderTriplets(this.all_items);
        this.filtered_items = this.all_items;
        this.lastResultsCount = this.all_items.length;
        this.loading = false;
        this.moreMailLoading = false;
        this.dropdownLoading = false;
        this.lastAllMailTime = this.getLastTimeFromBatch(result.results);
        this.lastFilterMailTime = this.lastAllMailTime;
    });
    this.selectedMailId = '';
    this.curId = '';
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.emailDiv && !this.emailDiv.nativeElement.contains(event.target)) {
      this.emailDropDownVisible = false;
    }
    if (this.jobDiv && !this.jobDiv.nativeElement.contains(event.target)) {
      this.jobDropDownVisible = false;
    }
    if (this.supplierDiv && !this.supplierDiv.nativeElement.contains(event.target)) {
      this.supplierDropDownVisible = false;
    }
    if (this.orderDiv && !this.orderDiv.nativeElement.contains(event.target)) {
      this.orderDropDownVisible = false;
    }
  }

  get activeEmailType() {
    return this.selectedEmailOption?.value;
  }

  get activeJobName() {
    return this.selectedJob?.value || 'All Jobs';
  }

  get activeSupplierName() {
    return this.selectedSupplier?.value || 'All Suppliers';
  }

  get activeOrderName() {
    return this.selectedOrder?.value || 'All Orders';
  }

  get filterActiveJobs() {
    const text = this.emailFilterJob?.nativeElement?.innerText?.trim().toLowerCase();
    if (!this.allMailData?.activeJobsList) return [];
    if (!text) return this.allMailData.activeJobsList;
    return this.allMailData.activeJobsList.filter((j: any) => j.value.toLowerCase().includes(text)) || [];
  }

  get filterActiveSuppliers() {
    const text = this.emailFilterSupplier?.nativeElement?.innerText?.trim().toLowerCase();
    if (!this.allMailData?.supArray) return [];
    if (!text) return this.allMailData.supArray;
    return this.allMailData.supArray.filter((j: any) => j.value.toLowerCase().includes(text));
  }

  get filterActiveOrders() {
    const text = this.emailFilterOrder?.nativeElement?.innerText?.trim().toLowerCase();
    if (!this.allMailData?.curOrders) return [];
    let curJobOrders: any = this.allMailData.curOrders;
    if (this.selectedJob.id != 'all') {
      curJobOrders = curJobOrders.filter((o: any) => o.job_id == this.selectedJob.id);
    }
    if (!text) return curJobOrders;
    return curJobOrders.filter((j: any) => j.value.toLowerCase().includes(text));
  }

  toggleEmailTypeDropdown() {
    this.emailDropDownVisible = !this.emailDropDownVisible;
  }

  toggleJobDropdown() {
    this.jobDropDownVisible = !this.jobDropDownVisible;
    if (this.jobDropDownVisible) {
      setTimeout(() =>  this.emailFilterJob.nativeElement.focus(), 50);
    }
  }

  toggleSupplierDropdown() {
    this.supplierDropDownVisible = !this.supplierDropDownVisible;
    if (this.supplierDropDownVisible) {
      setTimeout(() =>  this.emailFilterSupplier.nativeElement.focus(), 50);
    }
  }

  toggleOrderDropdown() {
    this.orderDropDownVisible = !this.orderDropDownVisible;
    if (this.orderDropDownVisible) {
      setTimeout(() =>  this.emailFilterOrder.nativeElement.focus(), 50);
    }
  }

  resetEmailValues() {
    this.mailCommunicationService.get_item_data({isVisible: false});
    this.all_items = [];
    this.filtered_items = [];
    this.lastAllMailTime = '';
    this.lastFilterMailTime = '';
  }

  getDrafts() {
    this.resetEmailValues();
    this.loading = true;
    this.getCheckinEmails();
  }

  onSetEmailType(email: any) {
    this.emailDropDownVisible = false;
    let onchange = email.id != this.selectedEmailOption.id;
    this.selectedEmailOption = email;
    return onchange;
  }

  setEmailTypeFilter(email: any) {
    let onchange = this.onSetEmailType(email);
    this.loading = true;

    if (onchange) {
      this.emailsForValue();
      if (email.id == 'drafts') {
        this.getDrafts();
      } else {
        this.moreMailLoading = true;
        this.getMoreMail(false);
      }
    }
    this.loading = false;
  }

  setJobFilter(job: any) {
    let onchange = job.id != this.selectedJob?.id;
    this.selectedJob = job;
    this.jobDropDownVisible = false;
    if (onchange) {
      this.filterMailsDropdown(job);
    }
  }

  setSupplierFilter(supplier: any) {
    let onchange = supplier.id != this.selectedSupplier?.id;
    this.selectedSupplier = supplier;
    this.supplierDropDownVisible = false;
    if (onchange) {
      this.filterMailsDropdown(supplier);
    }
  }

  setOrderFilter(order: any) {
    let onchange = order.id != this.selectedOrder?.id;
    this.selectedOrder = order;
    this.orderDropDownVisible = false;
    if (onchange) {
      this.filterMailsDropdown(order);
    }
  }

  updateEmailFilter(event: any) {
    const target = event.target as HTMLElement;
    target.dataset.placeholder = target.innerText ? '': this.getPlaceholderText();
  }

  getPlaceholderText() {
    return 'Filter...';
  }

  isEmailTypeVisible(email: any) {
    return email.id == this.selectedEmailOption?.id;
  }

  isJobVisible(job: any) {
    return job.id == this.selectedJob?.id;
  }

  isSupplierVisible(supplier: any) {
    if (!this.selectedSupplier) return false;
    return supplier.id == this.selectedSupplier.id;
  }
  isOrderVisible(order: any) {
    if (!this.selectedOrder) return false;
    return order.id == this.selectedOrder.id;
  }

  formatName(val: any) {
    return val.value;
  }

  selectMail(item: any) {
    if (this.selectedEmailOption.id == 'inbox') {
      this.selectMailInbox(item);
    } else {
      this.selectMailDraft(item);
    }
  }

  selectMailDraft(item: any) {
    if (this.curId == item.id) {
      this.curId = '';
      this.isEditingBody = false;
      return;
    }
    this.curId = item.id;
    setTimeout(() => {
      if (this.emailBodyTextareaTo) {
        this.emailBodyTextareaTo.nativeElement.innerText = item.email.to || '';
      }
      if (this.emailBodyTextareaSubject) {
        this.emailBodyTextareaSubject.nativeElement.innerText = item.email.subject || '';
      }
    }, 50);
  }

  positionCursorAtEnd(event: any) {
    event.stopPropagation();
    let contentEditableElement = event.target;
    contentEditableElement.focus();
    if (window.getSelection && document.createRange) {
        const range = document.createRange();
        const selection = window.getSelection() as any;
        const lastChild = contentEditableElement.lastChild;
        range.selectNodeContents(lastChild ? lastChild : contentEditableElement);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
    }
  }

  ccFieldClick(event: any) {
    event.stopPropagation();
    this.emailBodyTextareaCc.nativeElement.blur();
  }

  removeCcField(event: any, item: any, cc: string) {
    const index = item.email.cc.indexOf(cc);
    if (index > -1) {
      item.email.cc.splice(index, 1);
    }
    event.stopPropagation();
    this.saveDraft(event, item);
  }

  saveBody(value: any) {
    this.saveDraft(undefined, value.item);
  }

  onModelChange(value: string, item: any) {
    this.bodyChanges.next({value: value, item: item});
  }

  setEmailBodyFilter(event: any, item: any, field: string) {
    event.stopPropagation();
  }

  updateEmailBody(event: any, item: any, field: string) {
    const target = event.target as HTMLElement;
    if (field != 'cc') {
      item.email[field] = target.innerText;
    }
  }

  handleKeyDown(event: any) {
    if (event.key == 'Enter') {
      event.preventDefault();
      event.target.blur();
      return;
    }
  }


  ccActive: boolean = false;
  addCc(item: any) {
    item.email.cc.push({value: '', placeholder: ''});
  }

  isBlankCc(item: any) {
    return !item.email.cc.length || !item.email.cc.find((c: any) => !c.value);
  }

  onCcFocus(item: any): void {
    this.ccActive = true;
  }

  onCcBlur(event: any, item: any): void {
    this.ccActive = false;
    this.saveDraft(event, item);
  }

  ccKeyDown(event: any): void {
    event.target.blur();
  }

  handleKeyDownCc(event: any, item: any) {
    if (event.key == 'Enter') {
      event.preventDefault();
      const lines = event.target.innerText.split('\n');
      lines.forEach((line: string) => {
        const trimmedLine = line.trim();
        if (!item.email.cc.includes(trimmedLine) && trimmedLine !== '') {
          item.email.cc.push(trimmedLine);
        }
      });
      this.emailCcNbsp.nativeElement.innerText = '\n';
      this.emailCcNbsp.nativeElement.innerHTML = '&nbsp;';
      return;
    }
    if (event.key === 'Backspace') {
      const contentText = event.target.innerText;
      if (/\n\s*$/.test(contentText)) {
        event.preventDefault();
      }
    }
  }

  editorClick(event: any) {
    event.stopPropagation();
  }

  isSavingDraft: boolean = false;
  get saveDraftText() {
    return this.isSavingDraft ? 'Saved!' : 'Save draft';
  }

  isSendingEmail: boolean = false;
  get sendEmailNow() {
    return this.isSendingEmail ? 'Sending!' : 'Send now';
  }

  saveDraft(event: any, item: any) {
    if (event) event.stopPropagation();
    let email = {...item.email};
    email.cc = [...email.cc];
    email.cc = email.cc.map((c: any) => (c.value));
    let payload = {
      id: item.id,
      email: email,
    } as any;
    this.isSavingDraft = true;
    setTimeout(() => {
      this.isSavingDraft = false;
    }, 1000);
    this.updateCheckin(payload);
  }

  createDraft(inReplyItem: any = undefined, selectItem: boolean = false) {
    let onchange = false;
    if (this.selectedEmailOption.id == 'inbox') {
      this.onSetEmailType(this.allEmailsOption[1]);
      this.resetEmailValues();
      onchange = true;
    }
    this.doCreateDraft({draft: true}, inReplyItem, selectItem, onchange);
  }

  updateCheckinOrderChange(payload: any, item: any,
                          selectItem: boolean = false, emailChange: boolean = false) {
    this.updateCheckinOrder(payload).subscribe((result: any) => {
      item.supplier = result.supplier_id;
      item.changed = true;
      this.cdr.detectChanges();
      let old_order = payload.old_order_id;
      let new_order = payload.new_order_id; 
      let found = item.orders_info.find((o: any) => o.order_id == old_order);
      if (found && new_order) {
        found.order_id = new_order;
        found.job_id = result.job_id;
        found.supplier_id = result.supplier_id;
      } else if (item.orders_info.length == 1) {
        let cur = item.orders_info[0];
        if (result.job) cur.job_id = result.job;
        if (result.supplier) cur.supplier_id = result.supplier;
      }
      if (result.to || result.email?.to) {
        item.email.to = result.to || result.email.to;
        if (this.emailBodyTextareaTo) {
          this.emailBodyTextareaTo.nativeElement.innerText = item.email.to || '';
        }
      }
      if (result.cc || result.email?.cc) {
        let cc = result.cc || result.email.cc;
        item.email.cc = cc.map((c: any) => ({value: c, placeholder: c}));
      }
      if (result.subject) {
        item.email.subject = result.subject;
        if (this.emailBodyTextareaSubject) {
          this.emailBodyTextareaSubject.nativeElement.innerText = item.email.subject || '';
        }
      }
      item.show_reply = false;
      if (result.thread_reply_id || result.thread_id) {
        item.thread_reply_id = result.thread_reply_id || result.thread_id;
      }
      if (result.attachments) {
        item.attachments = item.attachments.concat(result.attach_links);
        item.email.attachments = result.attachments;
      }
      item.changed = false;
      this.loading = false;
      this.selectMail(item);
      if (selectItem || emailChange) {
        this.getCheckinEmails();
      }
      this.djv.request_metadata();
    })
  }

  draftOrderChange(event: any, item: any) {
    let old_order = event.data.info[event.data.index].order_id;
    let new_order = event.event.value.id;
    let subject = event.event.value.value;
    let payload = {
        id: item.id,
        new_order_id: new_order,
        old_order_id: old_order,
        subject: subject,
      };
    this.updateCheckinOrderChange(payload, item);
  }

  markSend(event: any, item: any) {
    event.stopPropagation();
    item.marked_send = true;
    item.is_draft = false;
    let payload = { id: item.id, marked_send: item.marked_send, is_draft: item.is_draft};
    this.updateCheckin(payload);
  }

  dontPropogate(event: any) {
    event.stopPropagation();
  }

  checkDate(event: any, item: any) {
    const selectedDate = new Date(event);
    const currentDate = new Date();
    selectedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    if (selectedDate < currentDate) {
      alert("You can't set that date because it needs to be a future date.");
      let to_set = item.send_date ? item.send_date : undefined;
      item.send_date = to_set;
      setTimeout(() => item.send_date = to_set, 10);
    } else {
      let dstring = event.toISOString();
      dstring = dstring.split('T')[0];
      let payload = { id: item.id, send_date: dstring};
      this.updateCheckin(payload);
    }
  }

  openCalendar(event: any, item: any): void {
    event.stopPropagation();
    if (this.selectedEmailOption.id == 'inbox') return;
    const targetElement = event.target as HTMLElement;
    const parentDiv: any = targetElement.closest('.date-layout');
    const calendarElement = parentDiv.querySelector('.p-calendar');
    const calendarWrapper = parentDiv.querySelector('.calendar-wrapper');
    this.calendarVisible = !this.calendarVisible;

    if (this.curId != item.id) {
      calendarElement.style.display = 'none';
      this.selectMailDraft(item);
      return;
    }
    calendarElement.style.position = 'absolute';
    calendarElement.style.right = '350px';
    calendarElement.style.top = '-60px';
    calendarWrapper.style.display = calendarWrapper.style.display === 'none' ? 'block' : 'none';
    calendarElement.style.display = calendarWrapper.style.display; 
  }

  sendNow(event: any, item: any) {
    event.stopPropagation(); 
    item.marked_send = true;
    item.is_draft = false;
    let email = {...item.email};
    email.cc = [...email.cc];
    email.cc = email.cc.map((c: any) => (c.value));
    let payload = {
      email: email,
      id: item.id,
      marked_send: item.marked_send,
      is_draft: item.is_draft,
      send_now: true,
    }
    this.isSendingEmail = true;
    setTimeout(() => {
      this.filtered_items = this.filtered_items.filter((i: any) => i.id != item.id);
      this.all_items = this.all_items.filter((i: any) => i.id != item.id);
      this.isSendingEmail = false;
    }, 600);

    this.updateCheckin(payload);

  }

  generateRandomString() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 15; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  attachmentClicked(event: any, item: any, file: any) {
    event.stopPropagation();
    let found = this.fileIsReady(item, file);
    if (!found) return;
    this.selectedAttachment = found.link;
    this.documentModal = true;
  }

  closeDocumentModal() {
    this.documentModal = false;
    this.selectedAttachment = undefined;
  }

  fileIsReady(item: any, file: any) {
    return item.attachments.find((a: any) => a.location == file.location);
  }

  onFileSelected(event: any, item: any) {
    const input = event.target as HTMLInputElement;
    const formData = new FormData();
    let newfile = false;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        let cur = input.files[i];
        if (item.email.attachments.find((a: any) => a.filename == cur.name)) {
          continue;
        }
        let rstring = this.generateRandomString();
        let location = 'order_documents/' + rstring;
        let att = {filename: cur.name, id: rstring, location: location};
        item.email.attachments.push(att);
        formData.append('file', cur, cur.name);
        formData.append(cur.name, location);
        newfile = true;
      }
    }
    if (newfile) {
      formData.append('id', item.id);
      this.uploadAttachments(formData, item);
    }
    event.target.value = ''; // reset form
  }

  uploadFile(event: any) {
    event.stopPropagation();
    const change = new Event('change', {
      bubbles: true,
      cancelable: true,
    });
    
    this.fileInput.nativeElement.dispatchEvent(change);
  }

  removeAttachment(event: any, item: any, index: number) {
    event.stopPropagation();
    item.email.attachments.splice(index, 1);
    let payload = { id: item.id, attach: item.email.attachments};
    this.updateCheckin(payload);
  }


  setLinksTarget() {
    setTimeout(() => {
      const mailBodyElement = this.emailContainer.nativeElement.querySelector('.mail-body');
      if (mailBodyElement) {
        const links = mailBodyElement.querySelectorAll('a');
        links.forEach(link => {
          this.renderer.setAttribute(link, 'target', '_blank');
        });
      }
    }, 400);
  }

  mailClicked(event: any) {
    event.stopPropagation();
  }

  selectMailInbox(item: any) {
    // the user just clicked this message
    let id = item.id;
    this.curId = id;
    if (this.selectedMailId === id) {
      this.selectedMailId = '';
      this.mailCommunicationService.get_item_data({isVisible: false});
    } else {
      // hide cur_id text and set id text
      this.mailCommunicationService.get_item_data(
          {
            isVisible: true,
            item: item,
            id: id,
            jobs: this.allMailData.jobsArray,
            suppliers: this.allMailData.supArray,
          }
          );
      let found = this.mailResultsMap.get(id);
      if (found) {
        this.selectedMailId = id;
        this.setLinksTarget();
      } else {
        this.fetchingBody = true;
        this.loadInputMessageParts(id).subscribe((result) => {
          this.mailResultsMap.set(id, result);
          this.mailResultsShowMap.set(id, result);
          this.fetchingBody = false;
          this.selectedMailId = id;
          this.setLinksTarget();
        });
      }
    }
  }

  _filter(item: any, target: any, type: string) {
    return target && (item[type] == target.id ||
            (item.orders_info && item.orders_info.some((val: any) => val[type] == target.id)));
  }

  doFilterResults(items: any) {
    let cur_list = [] as any;
    items.sort((a: any, b: any) => {
      const dateA = new Date(a.message_timestamp) as any;
      const dateB = new Date(b.message_timestamp) as any;
      return dateB - dateA;
    });

    if (this.cardView) {
      this.filtered_items = [...items];
    } else {
      items.forEach((item: any) => {
        // add only the items to cur_list that match the selected job/supplier/order
        if ((this.selectedJob?.id == 'all' || this._filter(item, this.selectedJob, 'job_id')) &&
            (this.selectedSupplier?.id == 'all' || this._filter(item, this.selectedSupplier, 'supplier_id')) &&
            (this.selectedOrder?.id == 'all' || this._filter(item, this.selectedOrder, 'order_id'))) {
          cur_list.push(item);
        }
      });
      this.filtered_items = cur_list;
    }
  }


  formatEmail(item: any) {
    let email = this.selectedEmailOption.id != 'inbox'
      ? item.email?.to
      : item.message_info?.from;
    if (!email) return '';
    let cleanedText = email.replace(/<[^>]*>/g, '');
    cleanedText = cleanedText.replace(/[^A-Za-z0-9 .-_@]+/g, '')
    return cleanedText.trim();
  }

  formatEmailFields(email: string, type: string) {
    if (!email) return '';
    const regex = /<([^>]+)>/g;
    const matches = email.match(regex);
    if (matches) {
      const extracted = matches.map(match => match.slice(1, -1));
      return type + ": " + extracted.join(', ');
    }
    return '';
  }

  formatDate(item: any): string {
    let date = this.selectedEmailOption.id != 'inbox'
      ? (item.send_date ? item.send_date : item.send_date)
      : item.message_timestamp;
    date = new Date(date);
    date = date.toISOString();
    let cur = date.split('T')[0];
    cur = cur.split('-');
    let last = cur[0].substring(2);
    cur = cur.slice(-2).join('/');
    return cur + '/' + last;
  }

  formatSendDate(item: any) {
    let date = new Date(item.send_date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedMonth = month < 10 ? `0${month}` : month.toString();
    const formattedDay = day < 10 ? `0${day}` : day.toString();
    return `${year}-${formattedMonth}-${formattedDay}`;
  }
  

  isOverDo(item: any) {
    if (this.selectedEmailOption.id == 'inbox') return false;
    let date = new Date(item.send_date);
    let cur = date.toISOString().split('T')[0];
    date = new Date(cur);

    let nd = new Date()
    nd = new Date(nd.toISOString().split('T')[0]);
    return date < nd;
  }

  formatSubject(item: any) {
    return this.selectedEmailOption.id != 'inbox'
      ? item.email.subject
      : item.message_info.subject;
  }

  hasAttachment(att: string) {
    return att == 'True' ? true: false;
  }

  formatAttachments(message_info: any, only_names: boolean) {
    if (!message_info?.attachments_paths) return [];
    let ap = JSON.parse(message_info.attachments_paths);
    if (only_names) {
      ap = ap.map((path: any) => path.name);
      return [...new Set(ap)];
    }
    return ap;
  }

  loadingStates: any = {};
  att_result: any = null;
  retrieveAttachment(event: any, item: any, att: any) {
    event.stopPropagation();
    let atts = this.formatAttachments(item.message_info, false);
    let path = atts.find((a: any) => a.name == att);
    if (!path) return;
    this.selectedAttachment = path.aws;
    this.documentModal = true;
    /*
    this.loadingStates[item.id + att] = true;
    this.loadMessageAttachment(item.id, path.aws)
      .subscribe((result) => {
        this.att_result = result;
        this.loadingStates[item.id + att] = false;

        const blob = new Blob([result], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);

        // Create an anchor (`<a>`) element to trigger the download
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = path.name; // Use the attachment's name as the filename
        document.body.appendChild(anchor); // Append the anchor to the body
        anchor.click(); // Trigger the download
  
        // Clean up by revoking the URL and removing the anchor
        window.URL.revokeObjectURL(url);
        document.body.removeChild(anchor);
      })
    */
  }

  getMailClasses(mail: IInputMessages): string {
    return this.classNameService.cn(
      "flex flex-col items-start rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
      this.selectedMailId === mail.id && "bg-muted"
    );
  }

  createOrderTriplets(items: any, from_drafts: boolean = false) {
    items.forEach((item: any) => {
      let cur_orders: any = [];
      if (item.email) {
        item.orders.forEach((o: any) => {
          cur_orders.push({'order_id': o.order, 'job_id': o.job, 'supplier_id': item.supplier});
        });
        if (!cur_orders.length) {
          let updatedOrder: any = {};
          if (item.job) updatedOrder['job_id'] = item.job;
          if (item.supplier) updatedOrder['supplier_id'] = item.supplier;
          if (item.job || item.supplier) cur_orders.push(updatedOrder);
        }
      }
      else {
        if (item.orders_info) {
          item.orders_info.forEach((oi: any) => {
            if (!cur_orders.some((cu: any) => cu.order_id === oi.order_id)) {
              cur_orders.push(oi);
            }
          });
        }
        
        if (item.order_id || item.job_id || item.supplier_id) {
          let updatedOrder: any = {};
          let matchingOrders: any = this.allMailData.curOrders.find((opt: any) => opt.id === item.order_id);
          if (matchingOrders) {
            updatedOrder['order_id'] = matchingOrders.id;
          }
          if (item.job_id) {
            updatedOrder['job_id'] = item.job_id;
          }
          if (item.supplier_id) {
            updatedOrder['supplier_id'] = item.supplier_id;
          }
          cur_orders.push(updatedOrder);
        }
      }


      item.orders_info = cur_orders;
      if (!item.orders_info.length) {
        item.orders_info = [{}];
      }
      item.orders_info.forEach((val: any) => {
        val.id = item.id;
      })
    });
  }

  replyToEmail(event: any, item: any) {
    event.stopPropagation();
    this.loading = true;
    this.onSetEmailType(this.allEmailsOption[1]);
    this.resetEmailValues();
    this.createDraft(item, true);
  }

  showReplyEmail(event: any, item: any) {
    event.stopPropagation();
    if (item.show_reply) {
      item.show_reply = false;
      return;
    }
    let rid = item.thread_reply_id || item.thread_id;
    let found = this.mailResultsMap.get(rid);
    if (found) {
      item.show_reply = true;
      return;
    }
    
    item.loading_reply = true;
    let payload = {most_recent: true};
    this.loadInputMessageParts(rid, payload).subscribe((result) => {
      this.mailResultsMap.set(rid, result);
      this.mailResultsShowMap.set(rid, result);
      item.show_reply = true;
      item.loading_reply = false;
      this.setScrollBar('.thread-reply-email');
    });
  }

  itemOrders(item: any) {
    const length = item && item.orders_info ? Math.max(1, item.orders_info.length) : 1;
    return new Array(length).fill(null);
  }

  addNewRow(event: any, item: any) {
    event.stopPropagation();
    if (!item.orders_info) {
      item.orders_info.push({id: item.id});
      return;
    }
    if (item.orders_info.some((item: any) => !item.order_id)) {
      return;
    }
    item.orders_info.push({id: item.id});
  }

  getOrderInfo(item: any): any {
    let infoMap: any = {
      job: item['job_name'],
      supplier: item['supplier_name'],
      msg_id: item.id,
      order_id: item.order_id,
      // order: item['order_name']
    };
  
    // Optionally, remove keys with undefined or empty values
    Object.keys(infoMap).forEach(key => {
      if (!infoMap[key]) {
        delete infoMap[key];
      }
    });
  
    return infoMap;
  }

  changeMailInfo(event: any) {
    let data = event.data.info;
    let index = event.data.index;
    let new_id = event.event.value.id;
    let cur_mail = this.all_items.find((item: any) => item.id == data[index].id) as any;

    if (event.colField == 'supplier_id') {
      cur_mail.orders_info[index].supplier_id = new_id;
    } else if (event.colField == 'job_id') {
      cur_mail.orders_info[index].job_id = new_id;
    } else {
      cur_mail.orders_info[index].order_id = new_id;
    }
    this.writeInputMessages({'payload': cur_mail.orders_info}, data[index].id);
  }
  
  getLabelClasses(type: string): string {
    return 'badge-' + this.getBadgeVariantFromLabel(type);
  }


  getOptions(type: string) {
    switch (type.toLowerCase()) {
      case 'job':
        return this.allMailData.jobsArray.filter((j: any) => j.id != 'all');
      case 'supplier':
        return this.allMailData.supArray.filter((s: any) => s.id != 'all');
      case 'order':
        return this.allMailData.curOrders.filter((o: any) => o.id != 'all');
      default:
        return []; 
    }
  }

  private getBadgeVariantFromLabel(type: string): string {
    switch (type.toLowerCase()) {
      case 'job':
        return 'default'; // Style for job
      case 'supplier':
        return 'outline'; // Style for supplier
      case 'order':
        return 'secondary'; // Style for order
      // Add more cases as needed
      default:
        return 'secondary'; // Default style
    }
  }

  // gets last timestamp or returns null if no results
  getLastTimeFromBatch(results: any): any {
    if (!results.length) return null;
    let timestamp = results[results.length - 1].message_timestamp;
    return timestamp ? timestamp: null;
  }

  formatDateToDesiredString(dateString: any) {
    let date = new Date(dateString);
    const pad = (num: any) => String(num).padStart(2, '0');
    
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    // Get the timezone offset in minutes
    const timezoneOffset = date.getTimezoneOffset();
    const absOffset = Math.abs(timezoneOffset);
    const offsetHours = pad(Math.floor(absOffset / 60));
    const offsetMinutes = pad(absOffset % 60);
    const timezoneSign = timezoneOffset > 0 ? '-' : '+';
    const timezone = `${timezoneSign}${offsetHours}:${offsetMinutes}`;
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezone}`;
}

  // with_prompt implies the user cicked "more mail"
  getMoreMail(with_prompt: boolean) {
    let params: any = {};
    params.timestamp = this.isFilterApplied() ? this.lastFilterMailTime: this.lastAllMailTime;
    if (!params.time) {
      delete params.time;
    }
    if (!params.timestamp) {
      delete params.timestamp;
    } else {
      params.timestamp = this.formatDateToDesiredString(params.timestamp);
    }
    let setParam = (value: any) => value && value.id !== 'all';
    if (setParam(this.selectedJob) && !this.cardView) params.job_id = this.selectedJob.id;
    if (setParam(this.selectedSupplier) && !this.cardView) params.supplier_id = this.selectedSupplier.id;
    if (setParam(this.selectedOrder)) params.order_id = this.selectedOrder.id;

    this.doFilterResults(this.all_items); // pre-filter before load
    this.loadInputMessages(params)
      .subscribe((results: any) => {
        this.hasBeenInited = this.hasBeenInited || this.selectedJob?.id == 'all';
        this.moreMailLoading = false;
        this.dropdownLoading = false;
        let new_items: any = [];
        results.results.forEach((item: any) => {
          if (!this.all_items.some(existingItem => existingItem.id === item.id)) {
            new_items.push(item);
          }
        });
        this.lastResultsCount = results.results.length;
        this.createOrderTriplets(new_items);
        this.all_items = this.all_items.concat(new_items);
        this.doFilterResults(this.all_items);

        if (this.isFilterApplied()) {
          this.lastFilterMailTime = this.getLastTimeFromBatch(results.results);
        } else {
          this.lastAllMailTime = this.getLastTimeFromBatch(results.results);
          this.lastFilterMailTime = this.lastAllMailTime;
        }
    });
  }

  get moreMailButtonText() {
    let more = 'More Mail';
    let nomore = 'No More!';
    if (this.lastResultsCount < 25) return nomore;
    let which = this.isFilterApplied() ? this.lastFilterMailTime : this.lastAllMailTime
    return which != null ? more : nomore;
  }

  loadMoreMail() {
    this.moreMailLoading = true;
    this.getMoreMail(true);
  }

  isFilterApplied() {
    return (this.selectedJob?.id != 'all') ||
            this.selectedSupplier?.id != 'all' ||
            this.selectedOrder?.id != 'all';
  }

  get curHeight() {
    return this.cardView ? `${this.cardHeight - 80}px` : 'none';
  }

  filterMailsDropdown(event: any) {
    if (this.selectedEmailOption.id != 'inbox') {
      this.doFilterResults(this.all_items);
      return;
    }

    this.moreMailLoading = true;
    this.emailViewer.nativeElement.scrollTop = 0;
    this.lastFilterMailTime = this.lastAllMailTime;
    this.doFilterResults(this.all_items);
    this.getMoreMail(false);
  }

  loadInputMessageParts(msg_id: any, payload: any = {}): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}input_messages/view/`+ msg_id +`/`, {
      headers: new HttpHeaders({ 'Accept': 'application/octet-stream'}),
      params: payload,
      responseType: 'arraybuffer' as any,
    }).pipe(map((response: any) => unzipByteData(response, true)));
  }

  loadInputMessages(payload: any): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}input_messages/`, {
      headers: new HttpHeaders({ 'Accept': 'application/x-msgpack' }),
      params: payload,
      responseType: 'arraybuffer'
    }).pipe(map(response => decodeMsgPackSimple(response)));
  }

  loadMessageAttachment(msg_id: any, path: any): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}input_messages/view/`+ msg_id +`/`, {
      headers: new HttpHeaders({ 'Accept': 'application/octet-stream'}),
      responseType: 'arraybuffer' as any,
      params: {'attachment': true, 'path': path},
    }).pipe(map((response: any) => unzipByteData(response, false)));
  }

  writeInputMessages(payload: any, msg_id: string): void {
    this.httpClient.put(`${this.baseUrl}input_messages/view/` + msg_id + `/`,
    payload,
    { headers: new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8'})}
    ).subscribe(() => {});
  }

  getCheckinNameForId(item: any, idx: number, which: string) {
    let cur = item.orders_info[idx];
    let arr = undefined;
    let fid: any = undefined;
    if (which == 'job') {
      arr = this.allMailData.jobsArray;
      fid = cur['job_id'];
    } else if (which == 'supplier') {
      arr = this.allMailData.supArray;
      fid = cur['supplier_id'];
    }
    else {
      arr = this.allMailData.curOrders;
      fid = cur['order_id'];
    }
    let found = arr.find((a: any) => a.id == fid) as any;
    return found ? found.value : '';
  }

  cancelSend(event: any, item: any) {
    event.stopPropagation();
    item.marked_send = false;
    item.is_draft = true;
    let payload = {
      id: item.id,
      marked_send: item.marked_send,
      is_draft: item.is_draft,
    };
    this.updateCheckin(payload);
  }

  deleteDraft(event: any, item: any) {
    event.stopPropagation();
    this.updateCheckin({id: item.id, delete: true});
    this.filtered_items = this.filtered_items.filter((i: any) => i.id != item.id);
    this.all_items = this.all_items.filter((i: any) => i.id != item.id);
  }

  getCheckinEmails() {
    let params: any = {};
    this.loadCheckinEmails(params)
      .subscribe((results: any) => {
        this.moreMailLoading = false;
        this.dropdownLoading = false;
        let new_items: any = [];
        results.forEach((item: any) => {
          if (!this.all_items.some(existingItem => existingItem.id === item.id)) {
            new_items.push(item);
          }
        });
        new_items.forEach((n: any) => {
          if (n.email.cc) {
            let ccs = n.email.cc.reduce((acc: any, current: any) => {
              if (!acc.includes(current)) {
                  acc.push(current);
              }
              return acc;
          }, []);
            n.email.cc = ccs.map((c: any) => ({value: c, placeholder: c}));
          }
        })

        this.lastCheckinCount = results.length;
        this.createOrderTriplets(new_items, true);
        if (this.cardView) {
          let order_id = this.card.items[0].order_id;
          new_items = new_items.filter((i: any) => i.orders_info.find((o: any) => o.order_id == order_id));
        }
        this.all_items = this.all_items.concat(new_items);
        this.doFilterResults(this.all_items);
        this.loading = false;
    });
  }

  loadCheckinEmails(payload: any): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}input_messages/checkin/`, {
      headers: new HttpHeaders({ 'Accept': 'application/x-msgpack' }),
      params: payload,
      responseType: 'arraybuffer' as any,
    }).pipe(map(response => decodeMsgPackSimple(response)));
  }

  updateCheckin(payload: any): void {
    this.httpClient.put(`${this.baseUrl}input_messages/checkin/`,
    payload,
    { headers: new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8'})}
    ).subscribe(() => {
      this.djv.request_metadata();
    });
  }

  updateCheckinOrder(payload: any): Observable<any> {
    return this.httpClient.put(`${this.baseUrl}input_messages/checkin/`,
    payload,
    { headers: new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8'})}
    );
  }

  uploadAttachments(formData: any, item: any): void {
    this.httpClient.post(`${this.baseUrl}input_messages/checkin/`,
      formData)
      .subscribe(
        response => {
          item.attachments.push(deepCopy(response));
        },
        error => {}
      );
  }

  doCreateDraft(payload: any, inReplyItem: any = undefined,
                selectItem: boolean = false, emailChange: boolean = false): void {
    this.httpClient.post(`${this.baseUrl}input_messages/checkin/`,
      payload, this.httpHelper.options)
      .subscribe(
        (response: any) => {
          this.createOrderTriplets([response]);
          this.all_items.unshift(response);
          this.filtered_items.unshift(response);
          if (this.cardView) {
            let payload: any = {
              id: response.id,
              new_order_id: this.card.items[0].order_id,
              old_order_id: undefined,
            };
            if (inReplyItem) {
              payload.reply_to = inReplyItem.id;
            }
            this.updateCheckinOrderChange(payload, response, selectItem, emailChange);
          } else {
            this.loading = false;
            let payload = {
              id: response.id,
              filtered_job: this.selectedJob?.id != 'all' ? this.selectedJob.id: undefined,
              filtered_supplier: this.selectedSupplier?.id != 'all' ? this.selectedSupplier.id: undefined,
            }
            if (payload.filtered_job || payload.filtered_supplier) {
              this.updateCheckinOrderChange(payload, response, selectItem, emailChange);
            } else {
              this.selectMail(response);
            }
            this.djv.request_metadata();
          }
        },
        error => {}
      );
  }
}

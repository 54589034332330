export const environment = {
  production: false,

  ENV: {
    BASE_URL: $ENV.MATERLOG_BASE_URL,
    ASGI_URL: $ENV.MATERLOG_ASGI_BASE_URL,
    TAWK_ID: $ENV.MATERLOG_TAWK_ID,
    GEOAPIFY_API: $ENV.MATERLOG_GEOAPIFY_API_KEY
  },
};

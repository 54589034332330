import { ChangeDetectorRef, Input, Output, EventEmitter} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Component, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { PayloadService } from "../../../services/payload.service";
import { HttpClient } from "@angular/common/http";
import { JobTableService } from "../../../services/job-table.service";
import { OptionsService } from 'src/app/dashboard/services/options.service';
import tippy from 'tippy.js';
import { DashboardRoutes } from 'src/app/dashboard/enums/dashboard-routes.enum';
import { FormsModule } from '@angular/forms';
import { ListboxModule } from 'primeng/listbox';
import { DialogModule } from 'primeng/dialog';
import { DashboardJobView } from 'src/app/dashboard/services/dashboard_job_view.service';


@Component({
  selector: 'change-item-job',
  templateUrl: './change-item-job.component.html',
  styleUrls: ['./change-item-job.component.css'],
  imports: [CommonModule, FormsModule, ListboxModule, DialogModule],
  standalone: true
})
export class ChangeItemJob {
  
  search: string = '';
  filteredJobs: any = [];
  @Input() jobs: any = undefined;
  @Input() rowNode!: any;
  @Input() mousePosition = { x: 0, y: 0 };
  @Output() closePopup = new EventEmitter<void>();
  @Input() activeJobsList!: any;
  isOpen: boolean = false;
  private tippyInstance!: any;
  @ViewChild('container') container!: ElementRef;
  @ViewChild('textInput') textInput!: ElementRef;

  constructor(
    private httpClient: HttpClient,
    private dashboardActions: DashboardActions,
    private jobTableService: JobTableService,
    private payloadService: PayloadService,
    readonly optionsService: OptionsService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    public router: Router,
    private djv: DashboardJobView
  ) {
  }

  ngOnInit() {
    (window as any)['change'] = this;
    this.filteredJobs = this.activeJobsList;
  }

  ngAfterViewInit() {
    this.isOpen = true;
    this.configureTippyInstance();
    this.tippyInstance.setContent(this.container.nativeElement);
    setTimeout(() => {
      this.textInput.nativeElement.focus();
    })
  }

  stopTyping(event: any) {
    event.target.blur();
  }

  startTyping(event: any) {

  }

  onSearchChange() {
    if (!this.search) {
      this.filteredJobs = this.activeJobsList;
      return;
    }
    let lsearch = this.search.toLowerCase();
    this.filteredJobs = this.activeJobsList.filter((j: any) => j.name.toLowerCase().includes(lsearch));
  }

  jobSelected(job: any) {
    this.destroyTippy({job: job, item: this.rowNode});
  }

  configureTippyInstance() {
    this.tippyInstance = tippy(this.container.nativeElement);
    this.tippyInstance.enable();
    this.tippyInstance.show();
    const y_add = 60;
    this.tippyInstance.setProps({
      trigger: 'manual',
      placement: 'bottom',
      getReferenceClientRect: () => ({
        width: 0,
        height: 0,
        top: this.mousePosition.y - y_add,
        bottom: this.mousePosition.y - y_add,
        left: this.mousePosition.x,
        right: this.mousePosition.x,
      }),
      arrow: true,
      interactive: true,
      hideOnClick: false,
      offset: 0,
      onClickOutside: (instance: any, event: any) => {
        this.destroyTippy();
      },
    });
  }

  destroyTippy(data: any = undefined) {
    if (this.isOpen) {
      this.isOpen = false;
      this.closePopup.emit(data);
      this.tippyInstance.destroy();
    }
  }
}
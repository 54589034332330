<div class="share">
  <span class="share-button" [ngClass]="isShared && !isLoading ? 'share-button--active' : null" (click)="openDialog()">
    <fa-icon [icon]="faShare"></fa-icon>
    {{shareText}}
  </span>

  <p-dialog
    [(visible)]="showDialog"
    [modal]="true"
    [resizable]="false"
    [draggable]="false"
    [closable]="false"
    [closeOnEscape]="false"
    [position]="'top'"
    [style]="{ marginTop: '50px', width: '45vw', minWidth: '900px' }"
    [maskStyleClass]="'dialog-mask'"
  >
    <ng-template pTemplate="header">
      <div class="dialog__header">
        <span>Share this view</span>
      </div>
      <button class="dialog-close" type="button" (click)="closeDialog()"><i class="bi bi-x-lg"></i></button>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="content overflow-hidden">
        <p class="content-subtitle">People you invite can only see the cells visible in this view.</p>
        <div class="content__box">
          <div class="content__box-tabs">
            <p [ngClass]="inviteTab ? 'content__box-tabs--active' : null" (click)="selectInvite()">Email invite</p>
            <p [ngClass]="!inviteTab ? 'content__box-tabs--active' : null" (click)="selectLink()">Create link</p>
          </div>
          <ng-container *ngIf="inviteTab">
            <div class="content__box-emails">
              <div class="content__box-emails-input">
                <p-chips
                  [(ngModel)]="emails"
                  [allowDuplicate]="false"
                  (onAdd)="handleChipAdd($event)" 
                  [addOnBlur]="true"
                  [addOnTab]="true"
                  separator=","
                  placeholder="name@company.com , name2@company.com"
                ></p-chips>
              </div>
              <p class="content-subtitle break-normal text-wrap inline-block">Separate multiple email addresses with commas</p>
            </div>
            <div class="textfield__error-message" *ngIf="isEmailDuplicate">
              There are emails for which there is already an invitation sent.
            </div>
            <div class="textfield__error-message" *ngIf="isNotEmail">Invalid email.</div>
            <div class="content__box-message">
              <textarea
                name="message"
                pInputTextarea
                [autoResize]="true"
                [rows]="1"
                placeholder="Add a message... (recommended)"
                [(ngModel)]="message"
              ></textarea>
              <button
                type="submit"
                class="content__box-message-button"
                pTooltip="Invite sent"
                tooltipPosition="top"
                tooltipEvent="focus"
                [life]="1500"
                (click)="emailInvite()"
                [disabled]="isNotEmail || isEmailDuplicate || emails.length < 1"
              >
                Invite
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="!inviteTab">
            <div class="p-inputgroup">
              <input type="text" pInputText placeholder="Link" [(ngModel)]="sharedView.link" [disabled]="true" />
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-copy"
                styleClass="p-button-warn"
                (click)="copyToClipboard()"
                pTooltip="Copied to clipboard"
                tooltipPosition="top"
                tooltipEvent="focus"
                [life]="1500"
              ></button>
            </div>
            <button
              type="button"
              class="content__box-disable"
              (click)="disableLink()"
              pTooltip="Shared link disabled"
              tooltipPosition="right"
              tooltipEvent="focus"
              [life]="1500"
            >
              <fa-icon class="content__box-disable-icon" [icon]="faDelete"></fa-icon>
              Disable shared view
            </button>
          </ng-container>
        </div>
        <ng-container *ngIf="inviteTab && sharedView?.emails?.length > 0">
          <div class="content__users">
            <span class="content__users-title">Shared with</span>
            <p class="content-subtitle">
              Everyone in your company can see and modify these views by default. The below users can also see (but can
              not edit) this view:
            </p>
            <div class="content__users-table">
              <div class="content__users-table-head">
                <p></p>
                <p>Email</p>
                <p>Customer name</p>
                <p>Access
                  <fa-icon
                    [icon]="faQuestion"
                    pTooltip="{{ accessMessage }}"
                    tooltipPosition="top"
                    [escape]="false"
                  ></fa-icon>
                </p>
                <p>
                  Send update
                  <fa-icon
                    [icon]="faQuestion"
                    pTooltip="{{ tooltipMessage }}"
                    tooltipPosition="top"
                    [escape]="false"
                  ></fa-icon>
                </p>
                <p>View count</p>
                <p>Invited by</p>
              </div>
              <div class="emails">
                <div class="content__users-table-body" *ngFor="let email of sharedView?.emails">
                  <p-divider></p-divider>
                  <div class="content__users-table-body-row">
                    <div class="content__users-table-body-row-logo">
                      <fa-icon
                        [style.background-color]="color"
                        class="content__users-table-body-row-logo-user"
                        [icon]="faUser"
                      ></fa-icon>
                    </div>
                    <p>{{ email.email }}</p>
                    <input
                      class="form-controls"
                      type="text"
                      placeholder="First name"
                      [(ngModel)]="email.first_name"
                      (change)="updateEmail(email)"
                    />
                    <div class="content__users-table-body-row-dropdown">
                      <p-cascadeSelect
                        appendTo="body"
                        [(ngModel)]="email.access"
                        [options]="commentAccess"
                        optionLabel="value"
                        optionValue="id"
                        optionGroupLabel="value"
                        [optionGroupChildren]="['options']"
                        placeholder="Access"
                        (onChange)="updateEmail(email)"
                      >
                      </p-cascadeSelect>
                    </div>
                    <div class="content__users-table-body-row-dropdown">
                      <p-cascadeSelect
                        appendTo="body"
                        [(ngModel)]="email.updates_frequency"
                        [options]="sharedUpdatesFreq"
                        optionLabel="value"
                        optionValue="id"
                        optionGroupLabel="value"
                        [optionGroupChildren]="['options']"
                        placeholder="Select frequency"
                        (onChange)="updateEmail(email)"
                      >
                      </p-cascadeSelect>
                    </div>
                    <div class="content__users-table-body-row-inviter">
                      <p>{{ email.view_count}}</p>
                      <p>{{ formatTime(email.last_access) }}</p>
                    </div>
                    <div class="content__users-table-body-row-inviter">
                      <p>{{ email.added_by_name }}</p>
                      <p>{{ email.added_by_email }}</p>
                    </div>
                    <div class="content__users-table-body-row-delete" (click)="deleteEmail(email.id)">
                      <i class="bi bi-x-lg"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </p-dialog>
</div>

<p-confirmDialog
  #cd
  [position]="'top'"
  [style]="{ marginTop: '350px' }"
  rejectButtonStyleClass="confirm-reject-button"
  acceptButtonStyleClass="confirm-accept-button"
>
  <ng-template pTemplate="header">
    <div class="confirm-header">
      <h5>Remove user from view</h5>
    </div>
    <button type="button" class="confirm-close" (click)="cd.reject()"><fa-icon [icon]="faDelete"></fa-icon></button>
  </ng-template>
</p-confirmDialog>

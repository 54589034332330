import { IUserPassword } from './../../../models/user.model';
import { UserActionTypes } from '../user-action-types';
import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export class RequestUpdateUserPassword implements Action {
  readonly type: UserActionTypes.REQUEST_UPDATE_USER_PASSWORD = UserActionTypes.REQUEST_UPDATE_USER_PASSWORD;

  constructor(
    public payload: IUserPassword) { }
}

export class ReceiveUpdateUserPassword implements Action {
  readonly type: UserActionTypes.RECEIVE_UPDATE_USER_PASSWORD = UserActionTypes.RECEIVE_UPDATE_USER_PASSWORD;

  constructor(
    public payload: IUserPassword) { }
}
export class ErrorReceiveUpdateUserPassword implements Action {
  readonly type: UserActionTypes.ERROR_RECEIVE_UPDATE_USER_PASSWORD = UserActionTypes.ERROR_RECEIVE_UPDATE_USER_PASSWORD;

  constructor(
    public payload: HttpErrorResponse) { }
}

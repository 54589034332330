import { IStoreApiItem, StoreApiItem } from "src/app/common/models/store-api-item.model";
import { IUserManagement } from "../../models/user.model";

export interface IUserProfileState {
  userProfile: IStoreApiItem<IUserManagement>;
}

export class UserProfileState implements IUserProfileState {
  userProfile: IStoreApiItem<IUserManagement> = new StoreApiItem();
}

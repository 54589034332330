import { IOptions } from 'src/app/authentication/models/authentication.model';
import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorItemData, errorPostPutItemData, receiveItemData, receivePostPutItemData, requestItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { SuppliersActionTypes } from '../actions/suppliers-action-types';
import { ISupplierCredentialsPageState, SupplierCredentialsPageState } from '../models/suppliers-credentials-page.model';

export function suppliersCredentialsPageReducer(
  lastState: ISupplierCredentialsPageState = new SupplierCredentialsPageState(),
  action: GenericAction<SuppliersActionTypes, any>
): ISupplierCredentialsPageState {
  switch (action.type) {

    case SuppliersActionTypes.REQUEST_SUPPLIER_CREDENTIALS_PAGE:
      return requestItemData<ISupplierCredentialsPageState, any>(lastState, ['supplierCredentialsPage']);



    case SuppliersActionTypes.RECEIVE_SUPPLIER_CREDENTIALS_PAGE:
      return receiveItemData<ISupplierCredentialsPageState, any>(lastState, ['supplierCredentialsPage'], action.payload);
    case SuppliersActionTypes.ERROR_RECEIVE_SUPPLIER_CREDENTIAL_PAGE:
      return errorItemData<ISupplierCredentialsPageState, any>(lastState, ['supplierCredentialsPage'], action.payload);

    case SuppliersActionTypes.REQUEST_SUPPLIER_CREDENTIALS_OPTIONS:
      return requestPostPutItemData<ISupplierCredentialsPageState, IOptions>(lastState, ['credentialOptions'], action.payload);

    case SuppliersActionTypes.ERROR_RECEIVE_SUPPLIER_CREDENTIALS_OPTIONS:
      return errorPostPutItemData<ISupplierCredentialsPageState, IOptions>(lastState, ['credentialOptions'], action.payload);
    default:
      return lastState;






  }
}

import { requestItemData, receiveItemData, errorItemData, requestPostPutItemData, receivePostPutItemData, errorPostPutItemData } from './../../../common/utils/reducer/reducer.utils';
import { ISuppliers, ISupplierContacts } from '../../models/dashboard.model';
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { GenericAction } from './../../../common/state/models/generic-action.model';
import { ISuppliersState, SuppliersState } from './../models/suppliers.model';

const resetSuppliersCRUDState = (lastState: ISuppliersState): ISuppliersState => ({
  ...lastState,
  suppliers: {
    ...lastState.suppliers,
    errors: null as any,
    isSuccess: null as any,
  },
});

export function suppliersReducer(
  lastState: ISuppliersState = new SuppliersState(),
  action: GenericAction<DashboardActionTypes, any>
): ISuppliersState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_SUPPLIERS:
      return requestItemData<ISuppliersState, ISuppliers>(lastState, ['suppliers']);
    case DashboardActionTypes.RECEIVE_SUPPLIERS:
      return receiveItemData<ISuppliersState, ISuppliers>(lastState, ['suppliers'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_SUPPLIERS:
      return errorItemData<ISuppliersState, ISuppliers>(lastState, ['suppliers'], action.payload);

    case DashboardActionTypes.REQUEST_SUPPLIERS_SMALL:
      return requestItemData<ISuppliersState, ISuppliers>(lastState, ['suppliers']);
    case DashboardActionTypes.RECEIVE_SUPPLIERS_SMALL:
      return receiveItemData<ISuppliersState, ISuppliers>(lastState, ['suppliers'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_SUPPLIERS_SMALL:
      return errorItemData<ISuppliersState, ISuppliers>(lastState, ['suppliers'], action.payload);

    case DashboardActionTypes.REQUEST_ADD_SUPPLIERS:
      return requestPostPutItemData<ISuppliersState, ISuppliers>(lastState, ['suppliers'], action.payload);
    case DashboardActionTypes.RECEIVE_ADD_SUPPLIERS:
      return receivePostPutItemData<ISuppliersState, ISuppliers>(lastState, ['suppliers'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ADD_SUPPLIERS:
      return errorPostPutItemData<ISuppliersState, ISuppliers>(lastState, ['suppliers'], action.payload);

    case DashboardActionTypes.REQUEST_ADD_SUPPLIER_CONTACT:
      return requestPostPutItemData<ISuppliersState, ISupplierContacts>(lastState, ['supplierContact'], action.payload);
    case DashboardActionTypes.RECEIVE_ADD_SUPPLIER_CONTACT:
      return receivePostPutItemData<ISuppliersState, ISuppliers>(lastState, ['supplierContact'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ADD_SUPPLIER_CONTACT:
      return errorPostPutItemData<ISuppliersState, ISupplierContacts>(lastState, ['supplierContact'], action.payload);

    case DashboardActionTypes.REQUEST_AUTOCOMPLETE_SUPPLIERS:
      return requestItemData<ISuppliersState, ISuppliers>(lastState, ['suppliersAutocomplete']);
    case DashboardActionTypes.RECEIVE_AUTOCOMPLETE_SUPPLIERS:
      return receiveItemData<ISuppliersState, ISuppliers>(lastState, ['suppliersAutocomplete'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_AUTOCOMPLETE_SUPPLIERS:
      return errorItemData<ISuppliersState, ISuppliers>(lastState, ['suppliersAutocomplete'], action.payload);

    case DashboardActionTypes.RESET_CRUD_SUPPLIERS_STATE:
      return resetSuppliersCRUDState(lastState);
    default:
      return lastState;
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { environment } from 'src/environments/environment';
import { ITemplate } from '../models/template.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  private baseUrl: string = environment.ENV.BASE_URL;

  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  public getTemplates(): Observable<ITemplate | HttpErrorResponse> {
    return this.httpClient.get<ITemplate>(`${this.baseUrl}templates/`, this.httpHelper.options);
  }

  public deleteTemplate(itemId: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<any>(`${this.baseUrl}templates/${itemId.payload}/`, this.httpHelper.options);
  }

  public updateTemplate(template: any): Observable<ITemplate | HttpErrorResponse> {
    return this.httpClient.put<ITemplate>(`${this.baseUrl}templates/${template.payload.id}/`, template.payload, this.httpHelper.options);
  }
}

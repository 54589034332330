import { IStoreApiItem, StoreApiItem } from "src/app/common/models/store-api-item.model";
import { IUserPassword } from "../../models/user.model";

export interface IUserPasswordState {
  userProfile: IStoreApiItem<IUserPassword>;
}

export class UserPasswordState implements IUserPasswordState {
  userProfile: IStoreApiItem<IUserPassword> = new StoreApiItem();
}

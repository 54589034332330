import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { TableConstants } from 'src/app/common/constants/table.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { SelectedViewModel } from '../../models/selected-view.model';
import { JobTableService } from '../../services/job-table.service';
import { faImages , faImage} from "@fortawesome/free-solid-svg-icons";
import { DashboardService } from "../../services/dashboard.service";
import {deepCopy, deepArrayClone} from "../../../common/utils/general"
import { OrderService } from "../../services/order.service";


@Component({
  selector: 'subitems',
  templateUrl: './subitems.component.html',
  styleUrls: ['./subitems.component.sass']
})
export class SubitemsComponent extends OnDestroyMixin() {
  @Input() selectedView?: SelectedViewModel;
  @Input() isLoading!: boolean;
  @Input() mailBoxVisible: boolean = false;

  appliedSubitems: boolean = false;
  cachedResults: any = undefined;

  constructor(
    private dashboardActions: DashboardActions,
    private dashboardService: DashboardService,
    private orderService: OrderService,
    private jobTableService: JobTableService) {
    super();
    // TODO add a cache so we dont need to retrieve on every toggle!!
  }

  toggleSubItems() {
    this.appliedSubitems = !this.appliedSubitems;
    if (!this.appliedSubitems) {
      this.turnOffSubitems();
    } else {
      this.turnOnSubitems();
    }
  }

  get subitemText() {
    return this.mailBoxVisible ? '': 'Subitems';
  }

  ngOnChanges(changes: any) {
    let active_view = changes.selectedView?.currentValue?.activeView;
    if (active_view) {
      if (active_view.show_subitems) {
        this.appliedSubitems = true;
        this.turnOnSubitems();
      } else {
        this.appliedSubitems = false;
      }
    }

  }

  turnOffSubitems() {
    this.jobTableService.setSubItems({'on': false});
    if (this.selectedView && this.selectedView.activeView) {
      let data = {'hide_subitems': true, 'view_id': this.selectedView?.activeView.id};
      this.orderService.showAllSubItems(data).subscribe((results) => {});
    }

  }

  turnOnSubitems() {
    if (this.selectedView && this.selectedView.activeView) {
      let data = {
        'job_id': this.selectedView?.selectedJobId,
        'view_id': this.selectedView?.activeView.id
      };
      this.orderService.showAllSubItems(data).subscribe((results) => {
        this.jobTableService.setSubItems({'on': true, ...results});
      });
    }
  }
}

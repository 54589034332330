<div class="notifications-container">
  <div *ngIf="notifications.length" class="icon-container"
      [ngClass]="sidePanelOpen ? 'ic-open' : 'ic-close'">
    <i [ngClass]="sidePanelOpen ? 'bi bi-arrow-bar-left' : 'bi bi-arrow-bar-right'" 
      (click)="sidePanelOpen = !sidePanelOpen">
    </i>
  </div>
  <div class="notifications-list" *ngIf="sidePanelOpen">
    <div class="notification-header">
      Notifications
    </div>
    <div class="notifications-list-short custom-scrollbar-close"
        *ngIf="sidePanelOpen && notifications.length">
      <div class="single-notification"
          [ngClass]="item.id == selectedId ? 'selected-item' : null"
          (click)="itemClicked(item)" *ngFor="let item of notifications">
        <div class="item-update-or-new"
            [ngStyle]="{'background': backgroundForItem(item), 'border': borderForItem(item)}">
        </div>
        <div class="item-information">
          <div class="item-row-1">
            <div class="item-subject"
                  [ngClass]="item.date ? 'item-with-date': 'item-no-date'">
              {{item.text}}
            </div>
            <div *ngIf="item.date" class="ml-auto item-date">
              {{ formatDate(item) }}
            </div>
          </div>
          <div class="item-row-2">
            <div class="email-value-display" *ngIf="item.job_name">
              <div class="badge-default status-color-cell">
                <span class="inner-text">{{ item.job_name }}</span>
              </div>
            </div>
            <div class="email-value-display" *ngIf="item.supplier_name">
              <div class="badge-outline status-color-cell">
                <span class="inner-text">{{ item.supplier_name }}</span>
              </div>
            </div>
            <div class="email-value-display" *ngIf="item.order_name">
              <div class="badge-secondary status-color-cell">
                <span class="inner-text">{{ item.order_name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="note-display-container" *ngIf="currentItem && !newItem">
    <div class="note-display-text">
      {{currentItem.text}}
    </div>
    <div class="all-info-container custom-scrollbar-close">
      <div class="note-order" #noteOrder *ngIf="currentItem.order_id && dropdownRetrieved">
        <div class="note-supplier-text">
          Order info
        </div>
        <div class="note-card-view">
          <card-view
          [notificationsView]="true"
          [order_id]="currentItem.order_id"
          [dropdownOptions]="dropdownOptions"
          [panelOpen]="sidePanelOpen"
          [allMailData]="allMailData"
          (cardIsOpen)="cardIsOpenChange($event)"
          (orderDeleted)="orderDeleted()"
          >
          </card-view>
        </div>
      </div>
      <div class="note-supplier" #noteSupplier *ngIf="currentItem.supplier_id">
        <div class="note-supplier-text">
          Supplier info
        </div>
        <materlog-suppliers-credentials-page
          [notificationsView]="true"
          [supplierId]="currentItem.supplier_id"
          [panelOpen]="sidePanelOpen"
          [allMailData]="allMailData"
        ></materlog-suppliers-credentials-page>
      </div>
      <div class="note-activity" *ngIf="currentItem.order_id">
        <activity-history
          [dropdownOptions]="dropdownOptions"
          [orderId]="currentItem.order_id"
        >
        </activity-history>
      </div>
    </div>
  </div>
</div>
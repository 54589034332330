import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SharedViewService } from '../../services/shared-view.service';
import { SharedViewActionTypes } from '../actions/shared-view-action-types';
import { ErrorReceiveSharedViewPage, ReceiveSharedViewPage } from '../actions/shared-view-action-type-creators/shared-view-page-action-types.creators';

@Injectable()
export class SharedViewEffects {
  constructor(private actions$: Actions,
    private sharedViewService: SharedViewService
  ) { }

  requestSharedViewPage$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SharedViewActionTypes.REQUEST_SHARED_VIEW_PAGE),
      switchMap((params: any) =>
        this.sharedViewService.getSharedViewPage(params).pipe(
          map(
            (sharedView: any) => new ReceiveSharedViewPage(sharedView)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveSharedViewPage(error)))
        )
      )
    );
  });
}

import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorDeleteItem, errorItemData, errorPostPutItemData, receiveItemData, receivePostPutItemData, requestItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IOrganizationAddresses } from '../../models/company.model';
import { CompanyActionTypes } from '../actions/company-action-types';
import { IOrganizationAddressesState, OrganizationAddressesState } from '../models/organization-addresses.model';


export function organizationAddressesReducer(
  lastState: IOrganizationAddressesState = new OrganizationAddressesState(),
  action: GenericAction<CompanyActionTypes, any>
): IOrganizationAddressesState {
  switch (action.type) {

    case CompanyActionTypes.REQUEST_ORGANIZATION_ADDRESSES:
      return requestItemData<IOrganizationAddressesState, IOrganizationAddresses>(lastState, ['organizationAddresses']);
    case CompanyActionTypes.RECEIVE_ORGANIZATION_ADDRESSES:
      return receiveItemData<IOrganizationAddressesState, IOrganizationAddresses>(lastState, ['organizationAddresses'], action.payload);
    case CompanyActionTypes.ERROR_RECEIVE_ORGANIZATION_ADDRESSES:
      return errorItemData<IOrganizationAddressesState, IOrganizationAddresses>(lastState, ['organizationAddresses'], action.payload);

    case CompanyActionTypes.REQUEST_ADD_ORGANIZATION_ADDRESS:
      return requestPostPutItemData<IOrganizationAddressesState, IOrganizationAddresses>(lastState, ['organizationAddresses'], action.payload);
    case CompanyActionTypes.RECEIVE_ADD_ORGANIZATION_ADDRESS:
      return receivePostPutItemData<IOrganizationAddressesState, IOrganizationAddresses>(lastState, ['organizationAddresses'], action.payload);
    case CompanyActionTypes.ERROR_RECEIVE_ADD_ORGANIZATION_ADDRESS:
      return errorPostPutItemData<IOrganizationAddressesState, IOrganizationAddresses>(lastState, ['organizationAddresses'], action.payload);

    case CompanyActionTypes.REQUEST_DELETE_ORGANIZATION_ADDRESS:
      return requestPostPutItemData<IOrganizationAddressesState, IOrganizationAddresses>(lastState, ['organizationAddresses'], action.payload);
    case CompanyActionTypes.RECEIVE_DELETE_ORGANIZATION_ADDRESS:
      return receivePostPutItemData<IOrganizationAddressesState, IOrganizationAddresses>(lastState, ['organizationAddresses'], action.payload);
    case CompanyActionTypes.ERROR_RECEIVE_DELETE_ORGANIZATION_ADDRESS:
      return errorDeleteItem<IOrganizationAddressesState, IOrganizationAddresses>(lastState, ['organizationAddresses'], action.payload);

    case CompanyActionTypes.REQUEST_UPDATE_ORGANIZATION_ADDRESS:
      return requestPostPutItemData<IOrganizationAddressesState, IOrganizationAddresses>(lastState, ['organizationAddresses'], action.payload);
    case CompanyActionTypes.RECEIVE_UPDATE_ORGANIZATION_ADDRESS:
      return receivePostPutItemData<IOrganizationAddressesState, IOrganizationAddresses>(lastState, ['organizationAddresses'], action.payload);
    case CompanyActionTypes.ERROR_RECEIVE_UPDATE_ORGANIZATION_ADDRESS:
      return errorPostPutItemData<IOrganizationAddressesState, IOrganizationAddresses>(lastState, ['organizationAddresses'], action.payload);

    default:
      return lastState;
  }
}

<div class="status-cell-renderer">
  <button #trigger
          data-action="toggle"
          [ngClass]="['status-color-cell',
                      isMail ? customStyles : '',
                      approvalsPage ? 'approvals-style': '',
                      cardView ? 'cardview-style': '']"
          [ngStyle]="isMail ?
                {'max-width.px': maxWidth} :
                {'background-color': backgroundColor, 'max-width.px': maxWidth}"
          (click)="$event.stopPropagation(); togglePopup($event)">
          <img src="/assets/img/updown.svg" width="15" height="15">
          <span
            [ngClass]="approvalsPage ? 'button-text-approvals' : 
                  (cardView ? 'button-text-approvals button-text-cardview' : 'button-text')" #buttonText></span> 
    </button>

  <div #content *ngIf="isOpen" class="menu-container">
    <p-listbox #listbox
                [filter]="true"
                [options]="options"
                [(ngModel)]="selectedUsers"
                optionLabel="value"
                [multiple]="isMultiple"
                [metaKeySelection]="false"
                (onChange)="onStatusChange($event)"
                (onHide)="onDropdownHide()"
                filterBy="value"
                [listStyle]="{ 'max-height': '250px' }"
                [style]="{ width: '15rem' }">
        <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
                <span>{{ group.value }}</span>
            </div>
        </ng-template>
    </p-listbox>
  </div>
</div>

<p-dialog
  *ngIf="addModalCards"
  [(visible)]="addModalCards"
  [style]="{ marginTop: '150px', width: '540px' }"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="true"
  [maskStyleClass]="'dialog-mask'"
  [dismissableMask]="true"
  (onHide)="closeModal()"
  appendTo="body"
>
  <materlog-add-supplier-modal
  *ngIf="colField === 'supplier_id'"
  (supplierFormChange)="supplierFormChangeEvent($event)"
  ></materlog-add-supplier-modal>
</p-dialog>

<p-dialog
  *ngIf="addItemModal"
  [(visible)]="addItemModal"
  [style]="{ marginTop: '150px', width: '540px' }"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="true"
  [maskStyleClass]="'dialog-mask'"
  [dismissableMask]="true"
  (onHide)="closeModal()"
  appendTo="body"
>

  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>{{ newItemText.substr(2) }}</span>
    </div>
    <button class="dialog-close" type="button" (click)="closeModal()"><i class="bi bi-x-lg"></i></button>
  </ng-template>

  <materlog-add-supplier-contact-modal
    *ngIf="params.colDef.field === 'supplier_contact_id'"
    [resetContact]="addItemModal"
    [contactRoles]="params.context.supplier_contact_role"
    [supplierList]="params.context.supplier_id"
    [supplierId]="params.data.supplier_id"
    (supplierContactFormChange)="supplierContactFormChangeEvent($event)"
  ></materlog-add-supplier-contact-modal>

  <materlog-add-room-modal
    *ngIf="params.colDef.field === 'room_id'"
    [params]="params"
    (addRoom)="closeRoomModalAndSave($event)"
  ></materlog-add-room-modal>

  <materlog-add-supplier-modal
    *ngIf="params.colDef.field === 'supplier_id'"
    (supplierFormChange)="supplierFormChangeEvent($event)"
  ></materlog-add-supplier-modal>
</p-dialog>



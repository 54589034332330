import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';

import { AppHttpInterceptor } from '../services/app-interceptor/app-interceptor';
import { OutsideClickDirective } from 'src/app/common/directives/outside-click.directive';
import { AddDocumentsComponent } from '../components/add-documents/add-documents.component';
import { DropFileDirective } from '../directives/drop-file.directive';
import { LoaderComponent } from '../components/loader/loader.component';
import { TawkChatComponent } from '../components/tawk-chat/tawk-chat.component';
import { BlockLateralArrowNavigationDirective } from '../directives/block-lateral-arrow-navigation.directive';
import { SanitizePipe } from '../pipes/sanitize.pipe';
import { NullDefaultValueDirective } from '../directives/input-debounce.directive';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
  declarations: [
    OutsideClickDirective,
    NullDefaultValueDirective,
    AddDocumentsComponent,
    DropFileDirective,
    LoaderComponent,
    TawkChatComponent,
    BlockLateralArrowNavigationDirective,
    SanitizePipe,
  ],
  imports: [
    CommonModule,
    TableModule,
    EffectsModule.forRoot(),
    ButtonModule,
    SelectButtonModule,
    FormsModule,
    CalendarModule,
    DropdownModule,
    ReactiveFormsModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    ConfirmDialogModule,
    AgGridModule,
  ],
  exports: [
    OutsideClickDirective,
    NullDefaultValueDirective,
    DropFileDirective,
    AddDocumentsComponent,
    LoaderComponent,
    TawkChatComponent,
    BlockLateralArrowNavigationDirective,
    AgGridModule,
    SanitizePipe,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
  ],
})
export class SharedModule { }

import { ChangeDetectorRef, Input, Output, EventEmitter} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Component, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { PayloadService } from "../../../services/payload.service";
import { HttpClient } from "@angular/common/http";
import { JobTableService } from "../../../services/job-table.service";
import { OptionsService } from 'src/app/dashboard/services/options.service';
import tippy from 'tippy.js';
import { DashboardRoutes } from 'src/app/dashboard/enums/dashboard-routes.enum';
import { FormsModule } from '@angular/forms';
import { ListboxModule } from 'primeng/listbox';
import { DialogModule } from 'primeng/dialog';
import { OrderService } from 'src/app/dashboard/services/order.service';


@Component({
  selector: 'dependency-renderer',
  templateUrl: './dependency-renderer.component.html',
  styleUrls: ['./dependency-renderer.component.css',],
  imports: [CommonModule, FormsModule, ListboxModule, DialogModule],
  standalone: true
})

export class DependencyRenderer implements ICellRendererAngularComp, AfterViewInit {

  params: any = undefined;
  private tippyInstance!: any;
  isOpen: boolean = false;
  dependsOn: any = [];
  filterInput: any = '';
  itemOptions: any = [];
  filterItemOptions: any = [];
  isDependsOnActive: boolean = true;
  isEditable: boolean = true;

  @ViewChild('trigger') trigger!: ElementRef;
  @ViewChild('content') content!: ElementRef;
  @ViewChild('inputText') inputText!: ElementRef;


  constructor(
    private httpClient: HttpClient,
    private dashboardActions: DashboardActions,
    private jobTableService: JobTableService,
    private payloadService: PayloadService,
    readonly optionsService: OptionsService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private orderService: OrderService,
    public router: Router,
  ) {
    (window as any)['dep'] = this;
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
  
  }

  setItemOption(option: any) {
    option.depends = !option.depends;
    let payload: any = {from: option.id, to: this.params.data.id};
    if (option.depends) {
      this.params.data.dependencies.push(option.id);
    } else {
      this.params.data.dependencies = this.params.data.dependencies.filter((d: any) => d != option.id);
      payload.delete = true;
    }
    this.orderService.changeItemDependencies(payload).subscribe({
      next: (response: any) => {
        if (response == 'ERROR') {
          option.depends = false;
          this.params.data.dependencies = this.params.data.dependencies.filter((d: any) => d != option.id);
          this.cdr.detectChanges();
        }
      },
    });
  }

  resortItems(itemsOut: any) {
    itemsOut = itemsOut.filter((i: any) => i.id != this.params.data.id);
    itemsOut.sort((a: any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    let depOn = itemsOut.filter((i: any) => this.params.data.dependencies.includes(i.id));
    depOn.forEach((i: any) => {
      i.depends = true;
    })
    itemsOut = itemsOut.filter((i: any) => !this.params.data.dependencies.includes(i.id));
    itemsOut = depOn.concat(itemsOut);
    return itemsOut;
  }

  createItemOptions() {
    let itemsOut: any = [];
    this.params.api.forEachNodeAfterFilterAndSort(function(node: any) {
      if (!node.group) {
        let data = node.data;
        let sku = data.sku ? `SKU: ${data.sku}`: ''
        let spec = data.spec ? `Spec: ${data.spec}`: '';
        let po = data.po_document_number?.number;
        po = po ? `PO: ${po}`: '';
        let aux = [sku, spec, po].filter((d: any) => d).join(', ');
        let cur: any = {id: data.id, name: data.name, aux: aux, depends: false};
        itemsOut.push(cur);
      }
    });
    itemsOut = this.resortItems(itemsOut);
    this.filterItemOptions = this.itemOptions = itemsOut;
    return [];
  }

  togglePopup(event: any) {
    (window as any)['drop'] = this;
    this.createItemOptions();
    event.stopPropagation();
    this.isOpen = !this.isOpen;
    if (!this.isOpen) {
      this.tippyInstance.destroy();
      return;
    }

    this.cdr.detectChanges();

    this.configureTippyInstance();
    this.tippyInstance.setContent(this.content.nativeElement);
    const inputElement = this.inputText.nativeElement;
    if (inputElement) inputElement.focus();

  }

  onFilterTextBoxChanged() {
    let s = this.filterInput.toLowerCase();
    this.filterItemOptions = this.itemOptions.filter((i: any) => 
      (i.aux && i.aux.toLowerCase().includes(s)) || (i.name && i.name.toLowerCase().includes(s))
    )
  }

  keydownEnter() {}

  getDependsText() {
    let dependsOn = this.params.data.dependencies;
    if (!dependsOn?.length) return '';
    let with_s = dependsOn.length > 1? 's': '';
    return `Depends on ${dependsOn.length} Item${with_s}`;
  }

  setCell(params: any) {
    this.params = params;
  }

  agInit(params: any) {
    if (params.isEditable != undefined) {
      this.isEditable = (params as any).isEditable;
    }

    this.setCell(params);
  }

  refresh(params: any): boolean {
    this.setCell(params);
    return true;
  }

  getGui(): any {
  }

  configureTippyInstance() {
    let x = 50;
    let y = -40;

    this.tippyInstance = tippy(this.trigger.nativeElement);
    this.tippyInstance.enable();
    this.tippyInstance.show();
    this.tippyInstance.setProps({
      trigger: 'manual',
      placement: 'bottom',
      popperOptions: {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [x, y],
            },
          },
        ],
      },
      arrow: true,
      interactive: true,
      hideOnClick: false,
      offset: 0,
      onClickOutside: (instance: any, event: any) => {
        this.isOpen = false;
        instance.destroy();
      },
    });
  }
}
import { SuppliersActionTypes } from '../suppliers-action-types';
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

export class RequestSuppliersPage implements Action {
  readonly type: SuppliersActionTypes.REQUEST_SUPPLIERS_PAGE = SuppliersActionTypes.REQUEST_SUPPLIERS_PAGE;

  constructor(
    public payload?: any) {
  }
}

export class RequestSupplierCredentialsPage implements Action {
  readonly type: SuppliersActionTypes.REQUEST_SUPPLIER_CREDENTIALS_PAGE = SuppliersActionTypes.REQUEST_SUPPLIER_CREDENTIALS_PAGE;

  constructor(public payload?: any) {

  }
}

export class ErrorSuppliersCredentialsPage implements Action {
  readonly type: SuppliersActionTypes.REQUEST_SUPPLIER_CREDENTIALS_OPTIONS = SuppliersActionTypes.REQUEST_SUPPLIER_CREDENTIALS_OPTIONS;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class ReceiveSuppliersPage implements Action {
  readonly type: SuppliersActionTypes.RECEIVE_SUPPLIERS_PAGE = SuppliersActionTypes.RECEIVE_SUPPLIERS_PAGE;

  constructor(
    public payload?: any) { }
}


export class ReceiveSupplierCredentialsPage implements Action {
  readonly type: SuppliersActionTypes.RECEIVE_SUPPLIER_CREDENTIALS_PAGE = SuppliersActionTypes.RECEIVE_SUPPLIER_CREDENTIALS_PAGE;

  constructor(public payload?: any) {
  }
}

export class ErrorReceiveSupplierCredentialsPage implements Action {
  readonly type: SuppliersActionTypes.ERROR_RECEIVE_SUPPLIERS_PAGE = SuppliersActionTypes.ERROR_RECEIVE_SUPPLIERS_PAGE;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class ErrorReceiveSuppliersPage implements Action {
  readonly type: SuppliersActionTypes.ERROR_RECEIVE_SUPPLIERS_PAGE = SuppliersActionTypes.ERROR_RECEIVE_SUPPLIERS_PAGE;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class RequestSupplierOptions implements Action {
  readonly type: SuppliersActionTypes.REQUEST_SUPPLIERS_OPTIONS = SuppliersActionTypes.REQUEST_SUPPLIERS_OPTIONS;

  constructor(
    public payload?: any) { }
}


export class RequestSupplierCredentialsOptions implements Action {
  readonly type: SuppliersActionTypes.REQUEST_SUPPLIER_CREDENTIALS_OPTIONS = SuppliersActionTypes.REQUEST_SUPPLIER_CREDENTIALS_OPTIONS;

  constructor(
    public payload?: any) { }
}



export class ReceiveSupplierOptions implements Action {
  readonly type: SuppliersActionTypes.RECEIVE_SUPPLIERS_OPTIONS = SuppliersActionTypes.RECEIVE_SUPPLIERS_OPTIONS;

  constructor(
    public payload?: any) { }
}

export class ErrorReceiveSupplierOptions implements Action {
  readonly type: SuppliersActionTypes.ERROR_RECEIVE_SUPPLIERS_OPTIONS = SuppliersActionTypes.ERROR_RECEIVE_SUPPLIERS_OPTIONS;

  constructor(
    public payload?: HttpErrorResponse) { }
}


export class ReceiveSupplierCredentialsOptions implements Action {
  readonly type: SuppliersActionTypes.RECEIVE_SUPPLIER_CREDENTIALS_OPTIONS = SuppliersActionTypes.RECEIVE_SUPPLIER_CREDENTIALS_OPTIONS;

  constructor(
    public payload?: any) { }
}

export class ErrorReceiveSupplierCredentialsOptions implements Action {
  readonly type: SuppliersActionTypes.ERROR_RECEIVE_SUPPLIER_CREDENTIALS_OPTIONS = SuppliersActionTypes.ERROR_RECEIVE_SUPPLIER_CREDENTIALS_OPTIONS;

  constructor(
    public payload?: HttpErrorResponse) { }
}

<div class="view-tab-all"
      [ngStyle]="{'height': viewHeight}">
  <div class="faint-vertical-line"></div>

  <div class="view-menu" [ngStyle]="{ visibility: views.length ? 'visible' : 'hidden' }">
    <div *ngIf="isLoadingViews">
      <div *ngFor="let x of skeletonRows(); let i = index" class="view-container skeleton-margin">
        <p-skeleton width="3rem" height="10px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
      </div>
    </div>
    
    <div *ngIf="!isLoadingViews">
      <div *ngFor="let view of views; let i = index"
            class="view-container">
        <ng-container *ngIf="isChangingView && selectedViewId == view.id">
          <div class="loading-dots">
            <span class="dot"></span><span class="dot"></span><span class="dot"></span>
          </div>
        </ng-container>
        <div *ngIf="!(isChangingView && selectedViewId == view.id)"
          [ngClass]="view.is_selected
            ? (view.template_id ? 'view-display view-display-template view-display-active'
                              : 'view-display view-display-active-regular view-display-active')
            : (view.template_id ?
              'view-display view-display-template' :
              'view-display view-display-inactive')"
          (click)="selectView(view.id)">
          <div class="view-block" style="padding-left: 4px"></div>
          <span class="view-block view-name">{{ view.name }}</span>
          <div class="caret-icon" (click)="viewSettings($event, i)">
            <i class="bi bi-caret-down-fill" style="font-size: 0.7rem;"></i>
          </div>
        </div>
        <ng-container *ngIf="settingsModal && activeSettingsPanel === i">
          <div #viewToolTip class="view-choice">
            <div class="view-option" (click)="renameView(view)">
              <i class="bi bi-pencil-fill"></i>
              Rename view
            </div>
            <div class="view-option" (click)="deleteView(view)">
              <i class="bi bi-trash-fill"></i>
              Delete view
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  
    <ng-container *ngIf="isAddingView">
      <span class="loading-dots">
        <span class="dot"></span><span class="dot"></span><span class="dot"></span>
      </span>
    </ng-container>
  
  </div>
</div>


<form>
  <p-dialog
    [(visible)]="editViewNameModal"
    [modal]="true"
    [resizable]="false"
    [draggable]="false"
    [closable]="false"
    [closeOnEscape]="false"
    [position]="'top'"
    [style]="{ marginTop: '150px', width: '588px' }"
    [maskStyleClass]="'dialog-mask'"
  >
    <ng-template pTemplate="header">
      <div class="dialog__header">
        <span> {{ editViewNameModal ? 'Update view name' : 'Add a view name' }}</span>
      </div>
      <button class="dialog-close" type="button" (click)="closeAddOrEditViewModal()">
        <i class="bi bi-x-lg"></i>
      </button>
    </ng-template>
    <input
      pInputText
      nullValue  
      type="text"
      name="newViewName"
      class="form-controls"
      placeholder="Name"
      [(ngModel)]="newViewName"
    />
    <div class="textfield__error-message" *ngIf="viewNameSubmitted && !newViewName?.trim()">Add a view name</div>

    <ng-template pTemplate="footer">
      <button
        pButton
        type="submit"
        label="{{ editViewNameModal ? 'Update view' : 'Create view' }}"
        class="button-primary p-button-raised mt-4"
        (click)="saveViewName(editViewNameModal)"
      ></button
    ></ng-template>
  </p-dialog>
</form>

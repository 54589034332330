import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomePageComponent } from '../components/home-page/home-page.component';
import { ForgotPasswordComponent } from 'src/app/authentication/components/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from 'src/app/authentication/components/recover-password/recover-password.component';
import { SignInComponent } from 'src/app/authentication/components/sign-in/sign-in.component';
import { SignUpComponent } from 'src/app/authentication/components/sign-up/sign-up.component';
import { AuthenticationModuleGuard } from 'src/app/authentication/modules/authentication.module.guard';
import { UserSettingsComponent } from 'src/app/user-settings/components/user-settings/user-settings.component';
import { CompanySettingsComponent } from 'src/app/company-settings/components/company-settings/company-settings.component';
import { SharedViewComponent } from 'src/app/shared-view/components/shared-view/shared-view.component';
import { TemplatesDialogComponent } from 'src/app/templates/components/templates/templates-dialog.component';
import { CreateUpdateJobComponent } from 'src/app/dashboard/components/create-update-job/create-update-job.component';
import { AddEditPoComponent } from 'src/app/dashboard/components/add-edit-po/add-edit-po.component';
import { AddItemComponent } from 'src/app/dashboard/components/add-item/add-item.component';
import { ViewJobsComponent } from 'src/app/dashboard/components/view-jobs/view-jobs.component';
import { DashboardRoutes } from 'src/app/dashboard/enums/dashboard-routes.enum';
import { ReceiversPageComponent } from 'src/app/receivers/components/receivers-page/receivers-page.component';
import { SuppliersPageComponent } from 'src/app/suppliers/components/suppliers-page/suppliers-page.component';
import { DashboardV2Component } from 'src/app/dashboard/dashboard-v2.component';
import {
  SupplierCredentialsPageComponent
} from "../../suppliers/components/suppliers-credentials-page/suppliers-credentials-page.component";

export const routes: Routes = [
  {
    path: 'login', component: SignInComponent
  },
  {
    path: 'signup', component: SignUpComponent
  },
  {
    path: 'forgot-password', component: ForgotPasswordComponent
  },
  {
    path: 'recover-password', component: RecoverPasswordComponent
  },
  {
    path: 'recover-password/:uid/:token', component: RecoverPasswordComponent
  },
  {
    path: 'views/shared/:id', component: SharedViewComponent
  },
  /*
  {
    path: 'home', component: DashboardV2Component, canActivate: [AuthenticationModuleGuard]
  },
  */
  /*
  {
    path: 'user-settings', component: UserSettingsComponent, canActivate: [AuthenticationModuleGuard]
  },
  {
    path: 'company-settings', component: CompanySettingsComponent, canActivate: [AuthenticationModuleGuard]
  },
  
  {
    path: DashboardRoutes.Templates, component: TemplatesDialogComponent, outlet: 'templates', canActivate: [AuthenticationModuleGuard]
  },
  */

  {
    path: DashboardRoutes.AddJob, component: CreateUpdateJobComponent, outlet: 'addJob', canActivate: [AuthenticationModuleGuard]
  },
  {
    path: DashboardRoutes.AddOrder, component: AddEditPoComponent, outlet: 'addOrder', canActivate: [AuthenticationModuleGuard]
  },
  {
    path: DashboardRoutes.AddItem, component: AddItemComponent, outlet: 'addItem', canActivate: [AuthenticationModuleGuard]
  },
  
  /*
  {
    path: 'jobs', component: ViewJobsComponent, canActivate: [AuthenticationModuleGuard]
  },
  */
  /*
   {
     path: 'receivers', component: ReceiversPageComponent, canActivate: [AuthenticationModuleGuard]
   },
  */
   /*
   {
     path: 'suppliers', component: SuppliersPageComponent, canActivate: [AuthenticationModuleGuard]
  },
  */
 /*
  {
    path: 'suppliers', component: SupplierCredentialsPageComponent, canActivate: [AuthenticationModuleGuard]
  },
  */
  /*
  {
    path: 'v1', component: DashboardComponent, canActivate: [AuthenticationModuleGuard]
  },
  */
  {
    path: 'v2', component: DashboardV2Component, canActivate: [AuthenticationModuleGuard]
  },
  {
    path: '', component: DashboardV2Component, canActivate: [AuthenticationModuleGuard]
  },
  {
    path: '**', redirectTo: '', pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

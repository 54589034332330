<form class="form-group" *ngIf="!onlyReceiver && !onlyReceiverContact">
  <p-dialog
    [(visible)]="showDialog"
    [modal]="true"
    [resizable]="false"
    [draggable]="false"
    [closable]="false"
    [closeOnEscape]="false"
    [position]="'top'"
    [style]="{ marginTop: '50px', width: '40vw', minWidth: '630px' }"
    [contentStyleClass]="'job-dialog'"
    [maskStyleClass]="'dialog-mask'"
  >
    <materlog-loader class="loader-center" *ngIf="isLoading"></materlog-loader>
    <ng-template pTemplate="header">
      <div class="dialog__header">
        <i class="bi bi-plus icon-green"></i>
        <span>Add a job</span>
      </div>
      <button class="dialog-close" type="button" (click)="closeDialog()"><i class="bi bi-x-lg"></i></button>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="textfield">
              <input
                pInputText
                nullValue
                type="text"
                name="name"
                [(ngModel)]="addJobForm.name"
                class="form-controls"
                placeholder="Job name"
              />
              <div class="textfield__error-message" *ngIf="isSubmitted && !addJobForm.name">Enter your job name</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="textfield">
            <geoapify-geocoder-autocomplete
              [value]="addJobForm.address"
              [debounceDelay]="50"
              [limit]="5"
              [lang]="'en'"
              [biasByCountryCode]="['us']"
              [filterByCountryCode]="['us']"
              placeholder="Address"
              [skipIcons]="true"
              [skipDetails]="true"
              (placeSelect)="placeSelected($event)"
              (userInput)="addJobForm.address = $event"
            >
            </geoapify-geocoder-autocomplete>
            <div class="textfield__error-message" *ngIf="isSubmitted && isAddressRequired">Enter an address</div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="textfield">
              <input
                pInputText
                nullValue
                type="text"
                name="city"
                [(ngModel)]="addJobForm.city"
                class="form-controls"
                placeholder="City"
              />
              <div class="textfield__error-message" *ngIf="isSubmitted && isCityRequired">Enter a city</div>
            </div>
          </div>
          <div class="col">
            <div class="textfield">
              <p-dropdown
                appendTo="body"
                [showClear]="true"
                [options]="states"
                [(ngModel)]="addJobForm.state"
                name="state"
                optionValue="id"
                optionLabel="value"
                placeholder="State"
              >
              </p-dropdown>
              <div class="textfield__error-message" *ngIf="isSubmitted && isStateRequired">Select a state</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="textfield">
              <input
                pInputText
                nullValue
                type="text"
                name="zip"
                (keydown)="checkZipLength()"
                [(ngModel)]="addJobForm.zip"
                class="form-controls"
                placeholder="Zip"
              />
              <div class="textfield__error-message" *ngIf="!isJobZip">Zip can't have more than 10 characters.</div>
            </div>
          </div>
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col d-flex gap-4 mt-5">
            Are you using a receiver for any materials for this project?
            <div class="d-flex gap-3">
              <p-radioButton [(ngModel)]="showReceiver" [value]="true" name="is_visible" label="Yes"> </p-radioButton>
              <p-radioButton [(ngModel)]="showReceiver" [value]="false" name="is_visible" label="No"> </p-radioButton>
            </div>
          </div>
        </div>
        <div *ngIf="showReceiver">
          <div class="row">
            <div class="col">
              <div class="textfield">
                <p-autoComplete
                  appendTo="body"
                  name="warehouse_name"
                  field="warehouse_name"
                  [showClear]="true"
                  [(ngModel)]="addJobForm.receiver.warehouse_name"
                  [suggestions]="filteredReceivers"
                  (completeMethod)="filterReceivers($event)"
                  [forceSelection]="true"
                  [dropdown]="true"
                  [showEmptyMessage]="true"
                  placeholder="Receiver or warehouse name"
                  (onSelect)="onSelectReceiver($event)"
                  (onClear)="onClearReceiver()"
                  (onDropdownClick)="onRequestReceivers()"
                >
                  <ng-template let-receiver pTemplate="item">
                    <div>{{ receiver.warehouse_name }}</div>
                  </ng-template>
                  <ng-template let-receiver pTemplate="footer">
                    <button
                      pButton
                      type="button"
                      label="+ Add new receiver"
                      class="p-button-text p-button-plain"
                      (click)="onOpenModalAddReceiver()"
                    ></button>
                  </ng-template>
                </p-autoComplete>
                <div class="textfield__error-message" *ngIf="isSubmitted && isReceiverNameRequired">
                  Enter a receiver or warehouse name
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="textfield">
                <geoapify-geocoder-autocomplete
                  [value]="addJobForm.receiver.address"
                  [debounceDelay]="50"
                  [limit]="5"
                  [lang]="'en'"
                  [biasByCountryCode]="['us']"
                  [filterByCountryCode]="['us']"
                  placeholder="Address"
                  [skipIcons]="true"
                  [skipDetails]="true"
                  (placeSelect)="placeSelectedReceiver($event)"
                  (userInput)="addJobForm.receiver.address = $event"
                >
                </geoapify-geocoder-autocomplete>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="textfield">
                <input
                  pInputText
                  nullValue
                  type="text"
                  name="receiverCity"
                  [(ngModel)]="addJobForm.receiver.city"
                  class="form-controls"
                  placeholder="City"
                />
              </div>
            </div>
            <div class="col">
              <div class="textfield">
                <p-dropdown
                  appendTo="body"
                  [showClear]="true"
                  [options]="states"
                  [(ngModel)]="addJobForm.receiver.state"
                  name="receiverState"
                  optionValue="id"
                  optionLabel="value"
                  placeholder="State"
                >
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="textfield">
                <input
                  pInputText
                  nullValue
                  type="text"
                  name="receiverZip"
                  [(ngModel)]="addJobForm.receiver.zip"
                  class="form-controls"
                  placeholder="Zip"
                />
                <div class="textfield__error-message" *ngIf="!isReceiverZip">
                  Zip can't have more than 10 characters.
                </div>
              </div>
            </div>
            <div class="col">
              <div class="textfield">
                <input
                  pInputText
                  nullValue
                  type="tel"
                  name="receiverPhone"
                  [(ngModel)]="addJobForm.receiver.phone"
                  class="form-controls"
                  placeholder="Phone"
                  maxlength="20"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="textfield">
                <input
                  pInputText
                  nullValue
                  type="email"
                  name="receiverEmail"
                  [(ngModel)]="addJobForm.receiver.email"
                  class="form-controls"
                  placeholder="Email"
                />
                <div class="textfield__error-message" *ngIf="!isSelectedReceiverEmail">Wrong email address.</div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showReceiver">
          <div class="row">
            <div class="col">
              <div class="textfield">
                <p-autoComplete
                  appendTo="body"
                  name="receiver_contact_name"
                  [showClear]="true"
                  [(ngModel)]="addJobForm.receiver_contact.name"
                  [suggestions]="filteredReceiversContacts"
                  (completeMethod)="filterReceiversContacts($event)"
                  [dropdown]="true"
                  [forceSelection]="true"
                  [dropdown]="true"
                  [showEmptyMessage]="true"
                  placeholder="Receiver contact name"
                  (onSelect)="onSelectReceiverContact($event)"
                  (onClear)="onClearReceiverContact()"
                >
                  <ng-template let-receiver pTemplate="item">
                    <div>{{ receiver.name }}</div>
                  </ng-template>
                  <ng-template let-receiver pTemplate="footer">
                    <button
                      *ngIf="this.addJobForm.receiver?.warehouse_name"
                      pButton
                      type="button"
                      label="+ Add new receiver contact"
                      class="p-button-text p-button-plain"
                      (click)="onOpenModalAddReceiverContact()"
                    ></button>
                  </ng-template>
                </p-autoComplete>
                <div class="textfield__error-message" *ngIf="isSubmitted && isReceiverContactNameRequired">
                  Select a receiver or warehouse contact name
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="textfield">
                <input
                  pInputText
                  nullValue
                  type="email"
                  name="receiverContactEmail"
                  [(ngModel)]="addJobForm.receiver_contact.email"
                  class="form-controls"
                  placeholder="Email"
                />
                <div class="textfield__error-message" *ngIf="!isSelectedReceiverContactEmail">Wrong email address.</div>
              </div>
            </div>
            <div class="col">
              <div class="textfield">
                <input
                  pInputText
                  nullValue
                  type="text"
                  name="receiverContactPhone"
                  [(ngModel)]="addJobForm.receiver_contact.phone"
                  class="form-controls"
                  placeholder="Phone"
                  maxlength="20"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button
        pButton
        [disabled]="showReceiver === null || isLoading"
        type="submit"
        label="Create job"
        class="button-primary p-button-raised mt-4"
        (click)="onCreateJob()"
      ></button>
    </ng-template>
  </p-dialog>
</form>

<form *ngIf="addReceiverModal">
  <p-dialog
    [(visible)]="addReceiverModal"
    [modal]="true"
    [draggable]="false"
    [closable]="false"
    [closeOnEscape]="false"
    [position]="'top'"
    [style]="{ marginTop: '150px', width: '588px' }"
    [contentStyleClass]="'job-dialog'"
    [maskStyleClass]="'dialog-mask'"
  >
    <materlog-loader class="loader-center" *ngIf="isLoading"></materlog-loader>
    <ng-template pTemplate="header">
      <div class="dialog__header">
        <span>Add receiver</span>
      </div>
      <button class="dialog-close" type="button" (click)="closeReceiverDialog()"><i class="bi bi-x-lg"></i></button>
    </ng-template>
    <div class="row">
      <div class="col">
        <div class="textfield">
          <input
            pInputText
            nullValue
            type="text"
            name="addReceiverContactName"
            class="form-controls"
            placeholder="Receiver or warehouse name"
            [(ngModel)]="addReceiverForm.warehouse_name"
          />
          <div class="textfield__error-message" *ngIf="isAddReceiverSubmitted && !addReceiverForm.warehouse_name">
            Enter a receiver or warehouse name
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="textfield">
          <geoapify-geocoder-autocomplete
            [value]="addReceiverForm.address"
            [debounceDelay]="50"
            [limit]="5"
            [lang]="'en'"
            [biasByCountryCode]="['us']"
            [filterByCountryCode]="['us']"
            placeholder="Address"
            [skipIcons]="true"
            [skipDetails]="true"
            (placeSelect)="placeSelectedReceiverModal($event)"
            (userInput)="addReceiverForm.address = $event"
          >
          </geoapify-geocoder-autocomplete>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="textfield">
          <input
            pInputText
            nullValue
            type="text"
            name="addReceiverContactCity"
            class="form-controls"
            placeholder="City"
            [(ngModel)]="addReceiverForm.city"
          />
        </div>
      </div>
      <div class="col">
        <div class="textfield">
          <p-dropdown
            appendTo="body"
            [showClear]="true"
            [options]="states"
            name="addReceiverContactState"
            optionValue="id"
            optionLabel="value"
            placeholder="State"
            [(ngModel)]="addReceiverForm.state"
          >
          </p-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="textfield">
          <input
            pInputText
            nullValue
            type="text"
            name="addReceiverContactZip"
            class="form-controls"
            placeholder="Zip"
            [(ngModel)]="addReceiverForm.zip"
          />
        </div>
      </div>
      <div class="col">
        <div class="textfield">
          <input
            pInputText
            nullValue
            type="tel"
            name="addReceiverPhone"
            [(ngModel)]="addReceiverForm.phone"
            class="form-controls"
            placeholder="Phone"
            maxlength="20"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="textfield">
          <input
            pInputText
            nullValue
            type="email"
            name="addReceiverEmail"
            [(ngModel)]="addReceiverForm.email"
            class="form-controls"
            placeholder="Email"
          />
          <div class="textfield__error-message" *ngIf="!isReceiverEmail">Wrong email format.</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex gap-4 mt-5">
        Add a receiver contact?
        <div class="d-flex gap-3">
          <p-radioButton [(ngModel)]="wantReceiverContact" [value]="true" name="is_visible" label="Yes"> </p-radioButton>
          <p-radioButton [(ngModel)]="wantReceiverContact" [value]="false" name="is_visible" label="No"> </p-radioButton>
        </div>
      </div>
    </div>
    <div *ngIf="sidePanelAdd && wantReceiverContact">
      <div class="dialog__header" style="margin-top: 18px">
        <span>Receiver contact</span>
      </div>
      <div class="row">
        <div class="col">
          <div class="textfield">
            <input
              pInputText
              nullValue
              type="text"
              name="addNewReceiverContactName"
              class="form-controls"
              placeholder="Name"
              [(ngModel)]="addReceiverContactForm.name"
            />
            <div class="textfield__error-message" *ngIf="isAddReceiverContactSubmitted && !addReceiverContactForm.name">
              Select a receiver or warehouse contact name
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="textfield">
            <input
              pInputText
              nullValue
              type="email"
              name="addNewReceiverContactEmail"
              [(ngModel)]="addReceiverContactForm.email"
              class="form-controls"
              placeholder="Email"
            />
            <div class="textfield__error-message" *ngIf="!isReceiverContactEmail">Wrong email format.</div>
          </div>
        </div>
        <div class="col">
          <div class="textfield">
            <input
              pInputText
              nullValue
              type="tel"
              name="addNewReceiverContactPhone"
              [(ngModel)]="addReceiverContactForm.phone"
              class="form-controls"
              placeholder="Phone"
              maxlength="20"
            />
          </div>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <button
        pButton
        type="submit"
        label="Create receiver"
        class="button-primary p-button-raised mt-4"
        [disabled]="isLoading"
        (click)="onSaveReceiver()"
      ></button
    ></ng-template>
  </p-dialog>
</form>

<form>
  <p-dialog
    [(visible)]="addReceiverContactModal"
    [modal]="true"
    [resizable]="false"
    [draggable]="false"
    [closable]="false"
    [closeOnEscape]="false"
    [position]="'top'"
    [style]="{ marginTop: '150px', width: '588px' }"
    [maskStyleClass]="'dialog-mask'"
  >
    <materlog-loader class="loader-center" *ngIf="isLoading"></materlog-loader>
    <ng-template pTemplate="header">
      <div class="dialog__header">
        <span>Add receiver contact</span>
      </div>
      <button class="dialog-close" type="button" (click)="closeReceiverContactDialog()">
        <i class="bi bi-x-lg"></i>
      </button>
    </ng-template>
    <div class="row">
      <div class="col">
        <div class="textfield">
          <input
            pInputText
            nullValue
            type="text"
            name="addNewReceiverContactName"
            class="form-controls"
            placeholder="Name"
            [(ngModel)]="addReceiverContactForm.name"
          />
          <div class="textfield__error-message" *ngIf="isAddReceiverContactSubmitted && !addReceiverContactForm.name">
            Select a receiver or warehouse contact name
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="textfield">
          <input
            pInputText
            nullValue
            type="email"
            name="addNewReceiverContactEmail"
            [(ngModel)]="addReceiverContactForm.email"
            class="form-controls"
            placeholder="Email"
          />
          <div class="textfield__error-message" *ngIf="!isReceiverContactEmail">Wrong email format.</div>
        </div>
      </div>
      <div class="col">
        <div class="textfield">
          <input
            pInputText
            nullValue
            type="tel"
            name="addNewReceiverContactPhone"
            [(ngModel)]="addReceiverContactForm.phone"
            class="form-controls"
            placeholder="Phone"
            maxlength="20"
          />
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <button
        pButton
        type="submit"
        label="Create receiver contact"
        class="button-primary p-button-raised mt-4"
        [disabled]="isLoading"
        (click)="onSaveReceiverContact()"
      ></button
    ></ng-template>
  </p-dialog>
</form>

import { IReceiverContact } from './../../../models/dashboard.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IReceiver } from 'src/app/dashboard/models/dashboard.model';
import { DashboardActionTypes } from '../dashboard-action-types';

export class RequestReceivers implements Action {
  readonly type: DashboardActionTypes.REQUEST_RECEIVERS = DashboardActionTypes.REQUEST_RECEIVERS;

  constructor(
    public payload?: string) { }
}
export class ReceiveReceivers implements Action {
  readonly type: DashboardActionTypes.RECEIVE_RECEIVERS = DashboardActionTypes.RECEIVE_RECEIVERS;

  constructor(
    public payload: IReceiver) { }
}
export class ErrorReceiveReceivers implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_RECEIVERS = DashboardActionTypes.ERROR_RECEIVE_RECEIVERS;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestAddReceiver implements Action {
  readonly type: DashboardActionTypes.REQUEST_ADD_RECEIVER = DashboardActionTypes.REQUEST_ADD_RECEIVER;

  constructor(
    public payload: IReceiver) { }
}

export class ReceiveAddReceiver implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ADD_RECEIVER = DashboardActionTypes.RECEIVE_ADD_RECEIVER;

  constructor(
    public payload: IReceiver) { }
}

export class ErrorReceiveAddReceiver implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ADD_RECEIVER = DashboardActionTypes.ERROR_RECEIVE_ADD_RECEIVER;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestAddReceiverContact implements Action {
  readonly type: DashboardActionTypes.REQUEST_ADD_RECEIVER_CONTACT = DashboardActionTypes.REQUEST_ADD_RECEIVER_CONTACT;

  constructor(
    public payload: IReceiverContact) { }
}

export class ReceiveAddReceiverContact implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ADD_RECEIVER_CONTACT = DashboardActionTypes.RECEIVE_ADD_RECEIVER_CONTACT;

  constructor(
    public payload: IReceiverContact) { }
}

export class ErrorReceiveAddReceiverContact implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ADD_RECEIVER_CONTACT = DashboardActionTypes.ERROR_RECEIVE_ADD_RECEIVER_CONTACT;

  constructor(
    public payload: HttpErrorResponse) { }
}
export class ResetReceiverCRUDState implements Action {
  readonly type: DashboardActionTypes.RESET_CRUD_RECEIVER_STATE = DashboardActionTypes.RESET_CRUD_RECEIVER_STATE;
}


export class RequestAutocompleteReceivers implements Action {
  readonly type: DashboardActionTypes.REQUEST_AUTOCOMPLETE_RECEIVERS = DashboardActionTypes.REQUEST_AUTOCOMPLETE_RECEIVERS;

  constructor(public payload: string) { }
}
export class ReceiveAutocompleteReceivers implements Action {
  readonly type: DashboardActionTypes.RECEIVE_AUTOCOMPLETE_RECEIVERS = DashboardActionTypes.RECEIVE_AUTOCOMPLETE_RECEIVERS;

  constructor(public payload: IReceiver) { }
}
export class ErrorReceiveAutocompleteReceivers implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_AUTOCOMPLETE_RECEIVERS = DashboardActionTypes.ERROR_RECEIVE_AUTOCOMPLETE_RECEIVERS;

  constructor(public payload: HttpErrorResponse) { }
}

import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { SelectedViewModel } from '../../models/selected-view.model';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { ElementRef, HostListener } from '@angular/core';
import { MailBoxCommunicationService } from '../../services/mailbox_comm.service';
import { selectComments, selectViews} from '../../state/selectors/dashboard.selector';
import { selectActiveJobs } from '../../state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { CommentsService } from '../../services/comment.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { MentionModule } from 'angular-mentions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { deepCopy } from 'src/app/common/utils/general';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { ActiveJobModel } from '../../models/active-job.model';
import { JobTableService } from '../../services/job-table.service';
import { initData } from '../mail-view/utils';
import { JobsService } from '../../services/job.service';
import { DashboardService } from '../../services/dashboard.service';
import { OrderService } from '../../services/order.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';



@Component({
  selector: 'submittals',
  templateUrl: './submittals.component.html',
  styleUrls: ['./submittals.component.css'],
})
export class SubmittalsComponent implements OnInit {
 
  @Input() itemID: any;
  @Input() submittalData: any;
  @Input() submittalHistory: any;
  @Input() options: any;

  @Output() newSubmittalOptions = new EventEmitter<void>();

  newSubmittalName: any = '';
  @ViewChild('submittalContainer') submittalContainer!: ElementRef;
  @ViewChild('submittalTop') submittalTop!: ElementRef;

  itemSubHistory: any = [];

  curSubmittals: any = [];
  constructor(
    private mailCommunicationService: MailBoxCommunicationService,
    private store: Store,
    private commentsService: CommentsService,
    private dashboardService: DashboardService,
    private dashboardActions: DashboardActions,
    private elRef: ElementRef,
    private sanitizer: DomSanitizer,
    private djv: DashboardJobView,
    private jobTableService: JobTableService,
    private jobService: JobsService,
    private cdr: ChangeDetectorRef,
    private orderService: OrderService,
    private httpClient: HttpClient,
    ) {
      (window as any)['sub'] = this;
    }

  ngOnInit() {
    this.getTodayDateInEST();
  }

  ngOnChanges(changes: any) {
    if (changes?.submittalData?.currentValue) {
      let cv = changes.submittalData.currentValue;
      cv = cv.find((c: any) => c.item_id == this.itemID);
      if (cv) {
        this.onResortSubmittals(cv.values);
        this.setScrollBar('.card-tracking-links');
      }
    }
    if (changes?.submittalHistory?.currentValue) {
      let sh = changes.submittalHistory.currentValue;
      let itemHistory = sh.filter((s: any) => s.item == this.itemID);
      this.createSubmittalHistory(itemHistory);
    }

  }

  ngAfterViewInit() {
    this.resetScrollBars();
  }

  resetScrollBars() {
    this.setScrollBar('.submittal-list-container');
    this.setScrollBar('.sub-history-container');
    this.setScrollBar('.attachments-container');
  }

  makeFakeSubmittal() {

    let date = new Date();
    const options = {
      timeZone: 'America/New_York',
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = formatter.format(date);
    let cur = {
      id: undefined,
      type_id: '',
      type_name: '',
      status: 1,
      change_date: formattedDate,
      attachments: [],
      tracking: [],
      notes: '',
    }
    return cur;
  }

  createNewSubmittal(event: any) {
    event.stopPropagation();
    (window as any)['sub'] = this;
    if (this.curSubmittals.find((s: any) => !s.id)) return;
    this.curSubmittals.push(this.makeFakeSubmittal());
  }

  deleteSubmittal(event: any, submittal: any) {
    event.stopPropagation();
    this.curSubmittals = this.curSubmittals.filter((s: any) => s != submittal);
    if (submittal.id) {
      this.orderService.deleteSubmittalValue(submittal.id).subscribe(() => {
      });
    }
    this.onResortSubmittals(this.curSubmittals);
  }

  onResortSubmittals(values: any) {
    let noTypes = values.filter((v: any) => !v.type_id);
    let withTypes = values.filter((v: any) => v.type_id);
    let itemSub = this.submittalData.find((c: any) => c.item_id == this.itemID);
    itemSub.values = withTypes;
    withTypes.sort((a: any, b: any) => {
      return a.type_name.toLowerCase().localeCompare(b.type_name.toLowerCase())
    });
    this.curSubmittals = withTypes.concat(noTypes);
    this.resetScrollBars();
  }

  onUpdateDropdown(event: any, submittal: any) {
    this.curSubmittals.forEach((s: any) => s.make_new = false);
    if (event.event.value.id == -1) {
      submittal.make_new = true;
      setTimeout(() => {
        this.submittalContainer.nativeElement.querySelector('.item-text-input').focus();
      }, 50);
    } else {
      this.updateAddSubmittalValue(submittal, 'type_id', 'type', event.event.value.id);
    }
  }

  updateAddSubmittalValue(submittal: any, sub_field: any, field: any, value: any) {
    if (!submittal.id) {
      let payload: any = {item: this.itemID, [field]: value};
      this.orderService.addSubmittalValue(payload).subscribe((result: any) => {
        let value = result.find((c: any) => c.item_id == this.itemID);
        this.onResortSubmittals(value.values);
      })
    } else {
      submittal[sub_field] = value;
      let data = {[field]: value}
      let payload: any = {id: submittal.id, payload: data};
      this.orderService.updateSubmittalValue(payload).subscribe((result: any) => {
        this.onResortSubmittals(this.curSubmittals);
      })
    }
  } 

  dataForType(submittal: any) {
    return this.options.types.find((o: any) => o.id == submittal.type_id);
  }

  startEditingSubmittalName(event: any, sub: any) {
    event.stopPropagation();
  }

  doesTypeExist(name: any) {
    return this.options.types.find((t: any) => t.value.toLowerCase() == name.toLowerCase());
  }

  stopEditingSubmittalName(submittal: any) {
    if (this.newSubmittalName) {
      if (this.doesTypeExist(this.newSubmittalName)) {
        this.newSubmittalName = '';
        alert('Cannot add submittal type with same name!');
        return;
      }
      let payload: any = {name: this.newSubmittalName};
      this.newSubmittalName = '';
      if (submittal.id) {
        payload.id = submittal.id;
        this.orderService.updateSubmittalValue(payload).subscribe((result: any) => {
          let value = result.find((c: any) => c.item_id == this.itemID);
          this.onResortSubmittals(value.values);
          this.newSubmittalOptions.emit();
        })
      } else {
        payload.item = this.itemID;
        this.orderService.addSubmittalValue(payload).subscribe((result: any) => {
          let value = result.find((c: any) => c.item_id == this.itemID);
          this.onResortSubmittals(value.values);
          this.newSubmittalOptions.emit();
        })
      }
    }
  }

  blurSubmittalName(event: any) {
    event.target.blur();
  }

  setScrollBar(v: string) {
    setTimeout(() => {
      let jdisplays = this.submittalTop.nativeElement.querySelectorAll(v);
      if (jdisplays) {
        jdisplays.forEach((jdisplay: any) => {
          jdisplay.addEventListener('mouseenter', function() {
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
          });
          jdisplay.addEventListener('mouseleave', function() {
            (jdisplay as any).classList.remove('custom-scrollbar');
            (jdisplay as any).classList.add('custom-scrollbar-close');
          });
        })
      }
    }, 500);

  }

  colorForStatus(submittal: any) {
    switch (submittal.status) {
      case 1:
        return '#C5C5C5';
      case 2:
        return '#F1B73F';
      case 3:
        return '#5FC64C';
      default:
        return '#C5C5C5';
    }
  }

  dataForStatus(submittal: any) {
    return this.options?.statuses?.find((s: any) => s.id == submittal.status);
  }

  onUpdateStatus(event: any, submittal: any) {
    submittal.status = event.event.value.id;
    this.updateAddSubmittalValue(submittal, 'status', 'status', submittal.status);
  }

  formatDate(d: any) {
    let date = d.toISOString();
    let cur = date.split('T')[0];
    cur = cur.split('-');
    let last = cur[0].substring(2);
    cur = cur.slice(-2).join('/');
    return cur + '/' + last;
  }

  createDate(d: any) {
    if (!d) return '--';
    return this.formatDate(new Date(d));
  }

  calendarVisible: boolean = false;
  calendar_value: any = undefined;
  @ViewChild('calendar') calendar!: ElementRef;
  openCalendar(event: any, submittal: any) {
    event.stopPropagation();
    this.calendar_value = submittal;
    this.calendarVisible = !this.calendarVisible;
    if (!this.calendarVisible) return;
    setTimeout(() => {
      const calendarElement: any = this.calendar.nativeElement;
      const x = event.clientX;
      const y = event.clientY;
      calendarElement.style.left = `285px`;
      calendarElement.style.top = `${y - 680}px`;
    }, 10);
  }

  onChangeDate(event: any) {
    this.calendarVisible = false;
    let date = event.toISOString();
    if (this.calendar_value) {
      date = date.split('T')[0];
      this.calendar_value.change_date = date;
      this.updateAddSubmittalValue(this.calendar_value, 'change_date', 'change_date', date);
    }
  }

  deleteDate(event: any, submittal: any) {
    event.stopPropagation();
    submittal.change_date = null;
    this.updateAddSubmittalValue(submittal, 'change_date', 'change_date', submittal.change_date);
  }

  onLinkChange(event: any, submittal: any) {
    this.updateAddSubmittalValue(submittal, 'tracking', 'tracking', event.values); 
  }

  onNotesClick(event: any, card: any) {
    event.stopPropagation();
  }

  onNotesFocus(event: any, item: any) {
  }

  onNotesBlur(event: any, submittal: any) {
    this.updateAddSubmittalValue(submittal, 'notes', 'notes', submittal.notes);
  }

  onNotesKeydown(event: any, submittal: any) {
    if (event.code == 'KeyV') {
      event.stopPropagation();
    }
    if (event.code == 'KeyC') {
      event.stopPropagation();
      navigator.clipboard.writeText(submittal.notes).then(() => {});
    }
  }

  changeToString(changes: any) {
    let out: any = [];
    changes.forEach((c: any) => {
      let val = '';
      if (['type', 'attachments', 'notes', 'tracking'].includes(c.field)) {
        val = 'was changed';
      } else if (c.field == 'status') {
        let found = this.options.statuses.find((s: any) => c.to == s.id);
        val = found ? `changed to ${found.value}`: 'was changed';
      } else {
        val = `to ${c.to}`;
      }
      let field = c.field;
      if (c.field == 'change_date') {
        field = 'Status date';
      }
      val = `${field} ${val}`;
      val = val[0].toUpperCase() + val.slice(1);
      out.push(val);
    });
    return out;
  }

  createHistories(data: any) {
    let histories: any = [];
    let prev_user: any = undefined;
    let prev_date: any = undefined;
    let prev_changes: any = [];
    data.forEach((h: any) => {
      let cur: any = {};
      cur.user = h.user.endsWith('@materlog.com') || h.user == 'System' ? 'System': h.username;
      let fullname = cur.user.split(' ');
      if (fullname.length == 2) {
        cur.user = `${fullname[0]} ${fullname[1][0]}.`;
      }
      cur.date = h.date;
      let changes = h.changes;

      if (prev_user == cur.user && prev_date == cur.date) {
        changes = changes.filter((c: any) => !prev_changes.find((p: any) => p.field == c.field));
        if (!changes.length) return;
        let pchanges = histories[histories.length - 1].changes;
        let cur_changes = this.changeToString(changes);
        prev_changes = prev_changes.concat(changes);
        histories[histories.length - 1].changes = pchanges.concat(cur_changes);
        return;
      }
      prev_user = cur.user;
      prev_date = cur.date;
      prev_changes = changes;
      cur.changes = this.changeToString(changes);
      histories.push(cur);
    })
    return histories;
  }

  todayDate: any = undefined;
  getTodayDateInEST() {
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'America/New_York'
    });
  
    const parts: any = formatter.formatToParts(new Date());
    const year = parts.find((part: any) => part.type === 'year').value;
    const month = parts.find((part: any) => part.type === 'month').value;
    const day = parts.find((part: any) => part.type === 'day').value;
  
    this.todayDate =`${year}-${month}-${day}`;
  }

  sortByDate(values: any) {
    values.sort((a: any, b: any) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      if (dateA < dateB) {
        return 1;
      }
      if (dateA > dateB) {
        return -1;
      }
      return 0;
    });
  }

  createSubmittalHistory(records: any) {
    let out: any = [];
    records.forEach((h: any) => {
      let ch = this.createHistories(h.records);
      let histories: any = [];
      ch.forEach((h: any) => {
        histories.push({history: h.changes.join(', '), date: h.date, user: h.user});
      });
      this.sortByDate(histories);
      histories.forEach((h: any) => {
        if (h.date == this.todayDate) {
          h.date = 'today';
        } else {
          let d = h.date.split('-');
          h.date = d.slice(1).concat(d[0].slice(2)).join('/');
        }
      });
      out.push({id: h.id, history: histories});
    });
    this.itemSubHistory = out;
  }

  getSubmittalHistory(submittal: any) {
    if (!submittal.id) return [];
    let found = this.itemSubHistory.find((i: any) => i.id == submittal.id);
    return found ? found.history : [];
  }


  uploadFile(event: any) {
    event.stopPropagation();
  }

  onFileSelected(event: any, submittal: any) {
    const input = event.target as HTMLInputElement;
    const formData = new FormData();
    let newfile = false;
    let thename = '';
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        let cur = input.files[i];
        if (submittal.attachments.find((a: any) => a[0] == cur.name)) {
          continue;
        }
        formData.append('file', cur, cur.name);
        thename = cur.name;
        newfile = true;
      }
    }
    if (newfile) {
      formData.append('id', submittal.id);
      let fake = {filename: thename, id: undefined};
      submittal.attachments.push(fake);
      this.uploadAttachments(formData, submittal);
    }
    event.target.value = ''; // reset form
  }

  fileIsReady(submittal: any, file: any) {
    return submittal.attachments.find((a: any) => file.id && a.id == file.id);
  }

  documentModal: boolean = false;
  selectedAttachment: any = undefined;
  attachmentClicked(event: any, submittal: any, file: any) {
    event.stopPropagation();
    let found: any = this.fileIsReady(submittal, file);
    if (!found) return;
    this.selectedAttachment = found.attachment;
    this.documentModal = true;
  }

  closeDocumentModal() {
    this.documentModal = false;
    this.selectedAttachment = undefined;
  }

  removeAttachment(event: any, sub: any, att: any) {
    sub.attachments = sub.attachments.filter((a: any) => a.id != att.id);
    let payload = {attachment: att.id};
    this.orderService.deleteSubmittalAttachment(payload).subscribe(() => {});
  }

  uploadAttachments(formData: any, submittal: any): void {
    this.orderService.addSubmittalAttachments(formData).subscribe((result: any) => {
      let value = result.find((c: any) => c.item_id == this.itemID);
      this.onResortSubmittals(value.values);
    })
  }
}
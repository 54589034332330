import { TableConstants } from "../../common/constants/table.constants";
import {deepCopy, deepArrayClone, emptySort} from "src/app/common/utils/general"


export class ViewModel {

  private fields_selected_to_sort: any;
  private fields_selected_to_sort_json: any;
  private fields_selected_to_group: any;
  private sort_ascendant:boolean;
  private filters: [];
  private main_operator: number;
  name: string;
  is_shared: boolean;
  selected: boolean;
  show_subitems: boolean;
  template_id: string | null | undefined;
  view_style: number = 0;

  static fromJSON(object: any): ViewModel {
    //set to undefined to mirror something diferent than empty list etc.
    let sortable: any = undefined;
    if (object.fields_selected_to_display) {
      let fields = object.fields_selected_to_display.filter((d: any) => {
        return TableConstants.jobItemsTableColumns.find((ji: any) => ji.field == d.field);
      })
      sortable = [];
      for (let i = 0; i < fields.length; i++) {
        let temp = JSON.parse(JSON.stringify(fields[i]));
        if (!temp.hasOwnProperty("order")) {
          temp["order"] = i;
        }
        sortable.push(temp);
      }
      sortable = sortable.sort((a: any, b: any) => a.order - b.order);
    }


    return new ViewModel(
      object.fields_selected_to_sort != null ? object.fields_selected_to_sort : ['created_datetime'],
      object.fields_selected_to_sort_json ? object.fields_selected_to_sort_json : emptySort(),
      sortable,
      object.fields_selected_to_group != null ? object.fields_selected_to_group : [],
      object.fields_selected_to_pin,
      object.customization,
      object.filters,
      object.id,
      object.is_selected,
      object.is_shared,
      object.main_operator,
      object.name,
      object.sort_ascendant,
      object.template_id,
      object.show_subitems,
      object.view_style
    )
  }

  constructor(
    fields_selected_to_sort: any,
    fields_selected_to_sort_json: any,
    readonly fields_selected_to_display: any,
    fields_selected_to_group: any,
    readonly fields_selected_to_pin: any,
    readonly customization: any,
    filters: [],
    readonly id: string,
    selected: boolean,
    is_shared: boolean,
    main_operator: number,
    name: string,
    sort_ascendant: boolean,
    template_id: string,
    show_subitems: boolean,
    view_style: number,
  ) {
    this.filters = filters;
    this.main_operator = main_operator;
    this.sort_ascendant = sort_ascendant;
    this.fields_selected_to_sort = deepCopy(fields_selected_to_sort);
    this.fields_selected_to_sort_json = deepCopy(fields_selected_to_sort_json);
    this.fields_selected_to_group = deepCopy(fields_selected_to_group);
    this.name = name;
    this.is_shared = is_shared;
    this.selected = selected;
    this.show_subitems = show_subitems;
    this.template_id = template_id;
    this.view_style = view_style;
  }

  set_sort_ascendant(value:boolean){
    this.sort_ascendant = value;
  }

  get_sort_ascendant(){
    return this.sort_ascendant;
  }

  set_filters(value:[]){
    this.filters = value;
  }

  get_filters(){
    return this.filters;
  }

  set_filters_operator(value:number){
    this.main_operator = value;
  }

  get_main_operator(): number{
    return this.main_operator;
  }

  set_fields_selected_to_group(info: any) {
    this.fields_selected_to_group = deepCopy(info);
  }

  get_fields_selected_to_group() {
    return this.fields_selected_to_group;
  }

  get_fields_selected_to_sort_json() {
    return this.fields_selected_to_sort_json;
  }

  set_fields_selected_to_sort_json(info: any) {
    this.fields_selected_to_sort_json = deepCopy(info);
  }
  
  get is_selected(): boolean {
    return this.selected;
  }

  select(): void {
    this.selected = true;
  }

  deselect(): void {
    this.selected = false;
  }




}

import { ChangeDetectorRef, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ElementRef, HostListener, ViewChild } from '@angular/core';

import { faArrowDownAZ, faArrowRightArrowLeft, faArrowUpAZ } from '@fortawesome/free-solid-svg-icons';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { SelectedViewModel } from '../../models/selected-view.model';
import { JobTableService } from "../../services/job-table.service";
import {deepCopy, deepArrayClone, emptySort} from "../../../common/utils/general"
import { TableConstants } from "../../../common/constants/table.constants";
import { SortItemPayload } from "../../interfaces/sort-item-payload.interface";




@Component({
  selector: 'sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortComponent extends OnDestroyMixin() implements OnChanges {
  @Input() selectedView?: SelectedViewModel;
  @Input() isLoading!: boolean;
  @Input() mailBoxVisible: boolean = false;

  displaySort: any;
  sortingArray: any = [];
  isSortEnabled: boolean;
  isLit: boolean = false;
  private mapFromfrontEnd = new Map();

  faSortDefault = faArrowRightArrowLeft;
  faSort = faArrowRightArrowLeft;
  rotateIcon: boolean = true;
  toggleSortModal: boolean = false;

  sortOptions = [
    { field: true, header: 'A-Z' },
    { field: false, header: 'Z-A' },
  ];

  selectedColumns: any = [];
  fields: any = [];

  constructor(private dashboardActions: DashboardActions,
              private  jobTableService: JobTableService,
              private cdr: ChangeDetectorRef,
              ) {
    super();
    this.sortingArray = [];
    this.isSortEnabled = true;
    for (let i = 0; i < TableConstants.jobItemsTableColumns.length; i++) {
      let column = TableConstants.jobItemsTableColumns[i];
      if (column.field != 'index') {
        this.mapFromfrontEnd.set(column.field, column);
      }
    }
    this.jobTableService.sortingArrayObs.subscribe(result => {
      if (result === null || this.isLoading || !this.selectedView?.activeView?.id) return;
      this.sortingArray = result;
      this.dashboardActions.requestSaveView('fields_selected_to_sort_json', this.sortingArray , this.selectedView?.activeView.id);
      this.cdr.detectChanges();
    });
  }

  get sortText() {
    return this.mailBoxVisible ? '': 'Sort';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.selectedView?.currentValue && !!this.selectedView) {
      //reset fields
      this.fields = [];
      this.selectedView.selectedColumnsToSort.forEach((item:any)=>{
        //make sure only in use columns in play....
        if(this.mapFromfrontEnd.has(item.field)) {
          this.fields.push(item);
        }
      });

      //this is for adding new columns into the saved view etc.
      let mapFromBackend = new Map();
      this.fields.forEach((item: { field: any; })=>{
        mapFromBackend.set(item.field, item);
      });
      TableConstants.jobItemsTableColumns.forEach(item=>{
        if(item.field != 'index') {
          if (!mapFromBackend.has(item.field)) {
            this.fields.push(deepCopy(item));
          }
        }
      });
      //use latest frontend header info instead of whats coming from backend...
      this.fields.forEach((item: { field: any; header: any; })=>{
        if(this.mapFromfrontEnd.has(item.field)){
          item.header = this.mapFromfrontEnd.get(item.field).header;
        }
      });

      this.sortingArray = [];
      let active_view = this.selectedView?.activeView;
      if (active_view) {
        let fields = active_view.get_fields_selected_to_sort_json();
        if (fields) {
          this.sortingArray = deepCopy(fields);
        }
        active_view.set_fields_selected_to_sort_json(this.sortingArray);
      }
      this.jobTableService.setSortingFilter(this.sortingArray);
      this.isSortEnabled = true;
    }
  }
  
  get lit() {
    if (!this.sortingArray.length) {
      return false;
    } else if (this.sortingArray.length == 1 &&
              this.sortingArray[0].key == '') {
      return false;
    } else {
      return true;
    }
  }

  onDeleteSortItem(index: any) {
    this.sortingArray.splice(index, 1);
    this.sortingArray = this.sortingArray.sort((a: any, b: any) => a.position - b.position);
    this.jobTableService.sort_deleted = true;
    this.dashboardActions.requestSaveView('fields_selected_to_sort_json', this.sortingArray , this.selectedView?.activeView.id);
    this.jobTableService.setSortingFilter(this.sortingArray);
  }

  onAddSortItem() {
    let new_item = { key: "", direction: true, position: this.sortingArray.length };
    if (this.sortingArray) {
      if (!this.sortingArray.length ||
          this.sortingArray[this.sortingArray.length - 1].key != "") {
        this.sortingArray.push(new_item);
      }
    }
    this.isSortEnabled = false;
  }

  isLastElementKeyDuplicate(sarray: any) {
    const lastElement = sarray[sarray.length - 1];
    const keyToCheck = lastElement.key;
    return sarray.some((element: any, index: number) => {
        return index !== sarray.length - 1 && element.key === keyToCheck;
    });
  }

  emitUpdateSort(event: any): void {
    const objToUpdate: any = {};
    if(this.sortingArray) {
      if (this.isLastElementKeyDuplicate(this.sortingArray)) {
        this.sortingArray.pop();
        this.onAddSortItem();
      } else {
        this.dashboardActions.requestSaveView('fields_selected_to_sort_json', this.sortingArray , this.selectedView?.activeView.id);
        this.jobTableService.setSortingFilter(this.sortingArray);
      }
      this.isSortEnabled = true;
    }
  }

  emitUpdateSortDirection( event: any, index: number): void {
    let value = event.value;
    this.sortingArray[index].direction = event.value;
    this.jobTableService.setSortingFilter(this.sortingArray);
    this.isSortEnabled = true;
    this.dashboardActions.requestSaveView('fields_selected_to_sort_json', this.sortingArray , this.selectedView?.activeView.id);
  }

  showSortModal(event: any): void {
    this.toggleSortModal = !this.toggleSortModal;
    event.stopPropagation();
    event.preventDefault();
  }

  outsideClickModal(event: any) {
    if (!this.toggleSortModal) return;
    this.toggleSortModal = false;
    event.stopPropagation();
    event.preventDefault();
  }


  clearAllSorts() {
    this.sortingArray = [];
    this.jobTableService.setClearAllSorts();
    this.dashboardActions.requestSaveView('fields_selected_to_sort_json', this.sortingArray, this.selectedView?.activeView.id);
    this.jobTableService.setSortingFilter(this.sortingArray);
    this.isSortEnabled = true;
    this.toggleSortModal = false;
  }

  emptyTemplateClick(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

}
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { environment } from 'src/environments/environment';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { IOrderDocument, IViews } from '../models/dashboard.model';
import { map } from 'rxjs/operators';
import {decodeMsgPack, decodeMsgPackSimple} from 'src/app/dashboard/services/do_msgpack';
import {unPackOrderItems} from 'src/app/dashboard/services/do_msgpack';


@Injectable()
export class DashboardService {
  private baseUrl: string = environment.ENV.BASE_URL;
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  private httpOptionsMsgPack = {
    headers: new HttpHeaders({
      'Accept': 'application/x-msgpack',
    }),
    responseType: 'arraybuffer' as any
  };

  public getOptions(): Observable<IOptions[] | HttpErrorResponse> {
    return this.httpClient.get<Array<IOptions>>(`${this.baseUrl}options/`, this.httpOptionsMsgPack)
      .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }

  public getViews(params?: any): Observable<IViews | HttpErrorResponse> {
    return this.httpClient.get<IViews>(`${this.baseUrl}views/items/`, {
      ...this.httpOptionsMsgPack,
      params: { ...params.payload },
    }
    ).pipe(map((response: any) => {return decodeMsgPack(response);})
    ).pipe(map((response: any) => {return unPackOrderItems(response);})
    )
  }

  public getApprovalOrders(params?: any): Observable<IViews | HttpErrorResponse> {
    return this.httpClient.get<IViews>(`${this.baseUrl}views/approvals/`, {
      ...this.httpOptionsMsgPack,
      params: { ...params.payload },
    }).pipe(map((response: any) => {
        return decodeMsgPack(response);
      })
    );
  }

  public getUncategorized(params?: any): Observable<IViews | HttpErrorResponse> {
    return this.httpClient.get<IViews>(`${this.baseUrl}views/uncategorized/`, {
      ...this.httpOptionsMsgPack,
      params: { ...params.payload },
    }).pipe(map((response: any) => {
        return decodeMsgPack(response);
      })
    );
  }


  public getSingleView(data: any): Observable<IViews | HttpErrorResponse> {
    return this.httpClient.get<IViews>(`${this.baseUrl}views/${data.view_id}/`, {
      ...this.httpOptionsMsgPack,
      params: {...data}
    }).pipe(map((response: any) => {
        return decodeMsgPack(response);
      })
    );
  }

  public deleteViewAndNewView(deleteView: any): Observable<any> {
    return this.httpClient.get<ArrayBuffer>(`${this.baseUrl}views/${deleteView.payload.new_id}/`,
    {
      ...this.httpOptionsMsgPack,
      params: {...deleteView.payload}
    }
    ).pipe(
      map(response => decodeMsgPack(response))
    );
  }

  public addView(addView: any): Observable<any> {
    return this.httpClient.post<ArrayBuffer>(`${this.baseUrl}views/`,
                                            addView.payload, this.httpOptionsMsgPack).pipe(
      map(response => decodeMsgPack(response))
    );
  }

  public updateView(updateView: any): Observable<any> {
    return this.httpClient.put<ArrayBuffer>(`${this.baseUrl}views/${updateView.payload.selectedViewId}/`,
                                            updateView.payload, this.httpOptionsMsgPack).pipe(
      map(response => decodeMsgPack(response))
    );
  }

  public saveView(saveView: any): Observable<any> {
    return this.httpClient.put<ArrayBuffer>(`${this.baseUrl}views/${saveView.payload.selectedViewId}/`,
                                            saveView.payload, this.httpOptionsMsgPack).pipe(
      map(response => decodeMsgPack(response))
    );
  }

  public deleteView(deleteView: any): Observable<any> {
    return this.httpClient.delete<ArrayBuffer>(`${this.baseUrl}views/${deleteView.payload.id}/`, this.httpOptionsMsgPack).pipe(
      map(response => decodeMsgPack(response))
    );
  }

  public updateViewCell(updateViewCell: any | any): Observable<any | HttpErrorResponse> {
    return this.httpClient.patch<any>(`${this.baseUrl}views/cell/`, updateViewCell.payload, this.httpHelper.options);
  }

  public deleteAttachment(params?: any): Observable<IViews | HttpErrorResponse> {
    return this.httpClient.patch<any>(`${this.baseUrl}views/cell/`, params.payload, this.httpHelper.options);
  }

  public getOrderDocument(orderId: any): Observable<IOrderDocument | HttpErrorResponse> {
    let payload = orderId.payload;
    let orderIdString = payload.shared ? payload.orderId: payload;
    return this.httpClient.get<IOrderDocument>(`${this.baseUrl}views/documents/${orderIdString}/`, {
      params: { ...payload },
      ...this.httpHelper.options
    });
  }

  public addOrderDocument(orderDocument: any): Observable<IOrderDocument | HttpErrorResponse> {
    return this.httpClient.post<IOrderDocument>(`${this.baseUrl}views/documents/`, orderDocument.payload, this.httpHelper.options);
  }

  public updateOrderDocument(orderDocument: any): Observable<IOrderDocument | HttpErrorResponse> {
    return this.httpClient.put<IOrderDocument>(`${this.baseUrl}views/documents/${orderDocument.payload.document_id}/`, orderDocument.payload, this.httpHelper.options);
  }

  public deleteOrderDocument(orderId: any): Observable<IOrderDocument | HttpErrorResponse> {
    return this.httpClient.delete<any>(`${this.baseUrl}views/documents/${orderId.payload.order_id}/`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { 
          order_item_id: orderId.payload.order_item_id,
          view_id: orderId.payload.view_id,
          order_id: orderId.payload.orders_order_id},
    });
  }
  public getItemsData(params: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.get<any>(`${this.baseUrl}items/`, {
      ...this.httpOptionsMsgPack,
      params: { ...params.payload },
    }).pipe(map((response: any) => {
        return decodeMsgPack(response);
      })
    );
  }
  public getViews2Data(params: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.get<any>(`${this.baseUrl}views2/`, {
      params: { ...params.payload },
      ...this.httpHelper.options
    });
}
}

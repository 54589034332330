import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IStoreApiList, StoreApiList } from 'src/app/common/models/store-api-list.model';
import { IForgotPassword, ILogin, IRecoverPassword, ISingUp, IOptions } from '../../models/authentication.model';

export interface IAuthenticationState {
  singUp: IStoreApiItem<ISingUp>;
  login: IStoreApiItem<ILogin>;
  forgotPassword: IStoreApiItem<IForgotPassword>;
  recoverPassword: IStoreApiItem<IRecoverPassword>;
  userOptionsInfo: {
    userOptions: IStoreApiItem<IOptions>;
    company: IStoreApiItem<any>;
    role: IStoreApiItem<any>;
  };
}

export class AuthenticationState implements IAuthenticationState {
  singUp: IStoreApiItem<ISingUp> = new StoreApiItem();
  login: IStoreApiItem<ILogin> = new StoreApiItem();
  forgotPassword: IStoreApiItem<IForgotPassword> = new StoreApiItem();
  recoverPassword: IStoreApiItem<IRecoverPassword> = new StoreApiItem();
  userOptionsInfo: {
    userOptions: IStoreApiItem<IOptions>;
    company: IStoreApiItem<any>;
    role: IStoreApiItem<any>;
  } = {
      userOptions: new StoreApiItem(),
      company: new StoreApiItem(),
      role: new StoreApiItem(),
    };
}


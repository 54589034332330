<div class="flex flex-col items-center">
  <div class="home max-w-2xl">
    <h3 class="m-5">{{ company.name }}</h3>
    <div class="overflow-hidden bg-white">
      <ul role="list" class="divide-y-4">
        <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
        <li class="border rounded-lg border-gray-300 m-2 cursor-pointer">
          <div
            (click)="navigateToDashboard()"
            class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-3 py-2 text-sm font-medium"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="flex min-w-0 flex-1 items-center">
                <div class="flex-shrink-0">
                  <!-- Current: "text-gray-500", Default: "text-gray-400 group-hover:text-gray-500" -->
                  <fa-icon class="track" [icon]="faCalendar" size="2x"></fa-icon>
                </div>
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <span class="text-nowrap text-2xl font-bold">Item Tracking</span>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="border rounded-lg border-gray-300 m-2 cursor-pointer">
          <div
            (click)="navigateToJobs()"
            class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-3 py-2 text-sm font-medium"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="flex min-w-0 flex-1 items-center">
                <div class="flex-shrink-0">
                  <fa-icon class="jobs" [icon]="faList" size="2x"></fa-icon>
                </div>
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <span class="text-nowrap text-2xl font-bold">Jobs</span>
                </div>
              </div>
            </div>
          </div>
        </li>
    <!--   <li class="border rounded-lg border-gray-300 m-2 cursor-pointer">
          <div
            (click)="navigateToSuppliers()"
            class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-3 py-2 text-sm font-medium"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="flex min-w-0 flex-1 items-center">
                <div class="flex-shrink-0">
                  <fa-icon class="supplier" [icon]="faBadge" size="2x"></fa-icon>
                </div>
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <span class="text-nowrap text-2xl font-bold">Suppliers address book</span>
                </div>
              </div>
            </div>
          </div>
        </li>-->

        <li class="border rounded-lg border-gray-300 m-2 cursor-pointer">
          <div
            (click)="navigateToSuppliersCredentials()"
            class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-3 py-2 text-sm font-medium"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="flex min-w-0 flex-1 items-center">
                <div class="flex-shrink-0">
                  <fa-icon class="supplier" [icon]="faPassport" size="2x"></fa-icon>
                </div>
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <span class="text-nowrap text-2xl font-bold">Suppliers</span>
                </div>
              </div>
            </div>
          </div>
        </li>

<!--

        <li class="border rounded-lg border-gray-300 m-2 cursor-pointer">
          <div
            (click)="navigateToReceivers()"
            class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center rounded-md px-3 py-2 text-sm font-medium"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="flex min-w-0 flex-1 items-center">
                <div class="flex-shrink-0">
                  <fa-icon class="supplier" [icon]="faBadge" size="2x"></fa-icon>
                </div>
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <span class="text-nowrap text-2xl font-bold">Receivers address book</span>
                </div>
              </div>
            </div>
          </div>
        </li>



-->


        <li class="border rounded-lg border-gray-300 m-2">
          <div class="text-gray-600 group flex items-center rounded-md px-3 py-2 text-sm font-medium">
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="flex min-w-0 flex-1 items-center">
                <div class="flex-shrink-0 disabled">
                  <fa-icon class="orders" [icon]="faFile" size="2x"></fa-icon>
                </div>
                <div class="min-w-0 px-4 md:gap-4 flex items-center justify-center">
                  <span class="text-gray-400 text-nowrap text-2xl font-bold">Orders</span>
                </div>
              </div>
            </div>
            <div class="place-self-start pt-2">
              <span
                class="pl-5 inline-flex items-center rounded-full bg-blue-500 px-3 py-1 text-sm font-medium text-white max-w-max"
                >Coming Soon!</span
              >
            </div>
          </div>
        </li>
        <li class="border rounded-lg border-gray-300 m-2">
          <div class="text-gray-600 group flex items-center rounded-md px-3 py-2 text-sm font-medium">
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="flex min-w-0 flex-1 items-center">
                <div class="flex-shrink-0 disabled">
                  <fa-icon class="inbox" [icon]="faInbox" size="2x"></fa-icon>
                </div>
                <div class="min-w-0 px-4 md:gap-4 flex items-center justify-center">
                  <span class="text-gray-400 text-nowrap text-2xl font-bold">Inbox</span>
                </div>
              </div>
            </div>
            <div class="place-self-start pt-2">
              <span
                class="pl-5 inline-flex items-center rounded-full bg-blue-500 px-3 py-1 text-sm font-medium text-white max-w-max"
                >Coming Soon!</span
              >
            </div>
          </div>
        </li>
      </ul>

      <!-- <div class="home__container">
    <h3 class="home__container-company-name">{{company.name}}</h3>
    <div class="home__container-item" (click)="navigateToDashboard()">
      <fa-icon class="track" [icon]="faCalendar" size="2x"></fa-icon>
      <h4 class="home__container-item-text">Item tracking</h4>
    </div>
    <div class="home__container-item" (click)="navigateToJobs()">
      <fa-icon class="jobs" [icon]="faList" size="2x"></fa-icon>
      <h4 class="home__container-item-text">Jobs</h4>
    </div>
    <div class="home__container-item" (click)="navigateToReceivers()">
      <fa-icon class="receiver" [icon]="faBadge" size="2x"></fa-icon>
      <h4 class="home__container-item-text">Receiver address book</h4>
    </div>
    <div class="home__container-item" (click)="navigateToSuppliers()">
      <fa-icon class="supplier" [icon]="faBadge" size="2x"></fa-icon>
      <h4 class="home__container-item-text">Supplier address book</h4>
    </div>
    <div class="home__container-item disabled">
      <fa-icon class="orders" [icon]="faFile" size="2x"></fa-icon>
      <h4 class="home__container-item-text disable" pBadge value="Coming soon!">Orders</h4>
    </div>
    <div class="home__container-item disabled">
      <fa-icon class="inbox" [icon]="faInbox" size="2x"></fa-icon>
      <h4 class="home__container-item-text disable" pBadge value="Coming soon!">Inbox</h4>
    </div>
  </div>-->
      <div class="home__upgrade" *ngIf="toggleUpgrade">
        <h4 class="home__upgrade-title">Upgrade before your trial expires</h4>
        <p class="home__upgrade-paragraph">
          Keep the power you need to manage complex workflows, run reports and more with tools like:
        </p>
        <div class="home__upgrade-tools">
          <i class="bi bi-check-lg"></i>
          <p>Test</p>
        </div>
        <div class="home__upgrade-tools">
          <i class="bi bi-check-lg"></i>
          <p>Test</p>
        </div>
        <div class="home__upgrade-tools">
          <i class="bi bi-check-lg"></i>
          <p>Test</p>
        </div>
        <div class="home__upgrade-actions">
          <button class="home__upgrade-actions-button"><i class="bi bi-stars"></i>Upgrade now</button>
        </div>
      </div>
    </div>
  </div>
</div>

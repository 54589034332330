import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import {decodeMsgPack, unPackOrderItems} from 'src/app/dashboard/services/do_msgpack'
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharedViewService {
  private baseUrl: string = environment.ENV.BASE_URL;
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  public getSharedViewPage(params?: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.get<any>(`${this.baseUrl}shared-views/items/${params.payload.id}/`, {
      headers: new HttpHeaders({
        'Accept': 'application/x-msgpack',
      }),
      params: { ...params.payload.pagination },
      responseType: 'arraybuffer' as any
    }
    ).pipe(map((response: any) => { return decodeMsgPack(response);})
    ).pipe(map((response: any) => {return unPackOrderItems(response);})
    );
  }
}


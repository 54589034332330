import { HttpErrorResponse } from '@angular/common/http';
import { DashboardActionTypes } from './../dashboard-action-types';
import { Action } from '@ngrx/store';
import { IActiveJobs } from './../../../models/dashboard.model';

export class RequestActiveJobs implements Action {
  readonly type: DashboardActionTypes.REQUEST_ACTIVE_JOBS = DashboardActionTypes.REQUEST_ACTIVE_JOBS;

  constructor(
    public payload?: string) {  }
}
export class ReceiveActiveJobs implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ACTIVE_JOBS = DashboardActionTypes.RECEIVE_ACTIVE_JOBS;

  constructor(
    public payload?: IActiveJobs) { }
}
export class ErrorReceiveActiveJobs implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ACTIVE_JOBS = DashboardActionTypes.ERROR_RECEIVE_ACTIVE_JOBS;

  constructor(
    public payload?: HttpErrorResponse) { }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { IForgotPassword, ILogin, IRecoverPassword, ISingUp } from '../../models/authentication.model';
import { IAuthenticationState } from '../models/authentication.model';
import { RequestForgotPassword } from './authentication-action-types-creators/forgot-password-action-types-creators';
import { RequestLogin } from './authentication-action-types-creators/login-action-types-creators';
import { RequestRecoverPassword } from './authentication-action-types-creators/recover-password-action-types-creators';
import { RequestCompany, RequestRole, RequestSingUp, RequestUserOptions } from './authentication-action-types-creators/sing-up-action-types-creators';

@Injectable({ providedIn: 'root' })
export class AuthenticationActions {
  constructor(private store: Store<IAuthenticationState>) { }

  public requestLogin(obj: ILogin): void {
    this.store.dispatch(new RequestLogin(obj));
  }

  public requestSingUp(obj: ISingUp): void {
    this.store.dispatch(new RequestSingUp(obj));
  }

  public requestForgotPassword(obj: IForgotPassword): void {
    this.store.dispatch(new RequestForgotPassword(obj));
  }

  public requestRecoverPassword(obj: IRecoverPassword): void {
    this.store.dispatch(new RequestRecoverPassword(obj));
  }

  public requestUserOptions(): void {
    this.store.dispatch(new RequestUserOptions());
  }

  public requestCompany(obj: any): void {
    this.store.dispatch(new RequestCompany(obj));
  }

  public requestRole(obj: any): void {
    this.store.dispatch(new RequestRole(obj));
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { INotifications } from 'src/app/user-settings/models/user.model';
import { UserActionTypes } from '../user-action-types';


export class RequestNotifications implements Action {
  readonly type: UserActionTypes.REQUEST_NOTIFICATIONS = UserActionTypes.REQUEST_NOTIFICATIONS;

  constructor(
    public payload?: string) { }
}
export class ReceiveNotifications implements Action {
  readonly type: UserActionTypes.RECEIVE_NOTIFICATIONS = UserActionTypes.RECEIVE_NOTIFICATIONS;

  constructor(
    public payload?: INotifications) { }
}
export class ErrorReceiveNotifications implements Action {
  readonly type: UserActionTypes.ERROR_RECEIVE_NOTIFICATIONS = UserActionTypes.ERROR_RECEIVE_NOTIFICATIONS;

  constructor(
    public payload?: HttpErrorResponse) { }
}
export class RequestUpdateNotification implements Action {
  readonly type: UserActionTypes.REQUEST_UPDATE_NOTIFICATION = UserActionTypes.REQUEST_UPDATE_NOTIFICATION;

  constructor(
    public payload: INotifications) { }
}
export class ReceiveUpdateNotification implements Action {
  readonly type: UserActionTypes.RECEIVE_UPDATE_NOTIFICATION = UserActionTypes.RECEIVE_UPDATE_NOTIFICATION;

  constructor(
    public payload: INotifications) { }
}
export class ErrorReceiveUpdateNotification implements Action {
  readonly type: UserActionTypes.ERROR_RECEIVE_UPDATE_NOTIFICATION = UserActionTypes.ERROR_RECEIVE_UPDATE_NOTIFICATION;

  constructor(
    public payload: HttpErrorResponse) { }
}

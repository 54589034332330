<div class="h-full flex flex-col tracking-outer"
     [ngStyle]="{'display': makeVisible ? 'block' : 'none',
               'max-height': 'calc(100vh - 20px)', 'min-height': 'calc(100vh - 20px)'}">

  <div *ngIf="!fr_status" class="tracking-container">
    <div class="order-item-header">
      <div class="order-item-value selected-order-item">
        ORDER BEING PROCESSED...
      </div>
    </div>
  </div>
  <div *ngIf="fr_status" class="tracking-container">
    <div class="order-item-header">
      <div class="order-item-value"
           [ngClass]="{'selected-order-item': showOrder, 'not-selected-order-item': !showOrder}"
           (click)="showOrderItem('order')">
        ORDER INFO
      </div>
      <div class="order-item-value"
           [ngClass]="{'selected-order-item': !showOrder, 'not-selected-order-item': showOrder}"
           (click)="showOrderItem('item')">
        {{ itemHeader }}
      </div>
    </div>
    <div *ngIf="!hasDataLoaded">
      <div class="spacer"></div>
      <p-skeleton width="10rem" height="30px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
      <p-skeleton width="14rem" height="56px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
      <p-skeleton width="18rem" height="40px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
      <div *ngFor="let item of skeletonItems">
        <p-skeleton [width]="item.width" styleClass="mb-2" borderRadius="16px"></p-skeleton>
      </div>
    </div>
    <div *ngIf="hasDataLoaded" class="show-order-container custom-scrollbar-close">
      <div class="show-order-info" *ngFor="let val of orderItemData; let i = index;">
        <div class="item-container" *ngIf="isItem(val)" >
          <div class="single-item-container">
            <div class="single-item-carrot" (click)="toggleItemVisibility(val)">
              <div class="single-item-info" >
                <span [ngStyle]="freshDeskRetrieved(val)">
                  {{ formatOrderTypeItem(val) }}
                </span>
              </div>
              <div class="the-carrot">
                <i class="bi" [ngClass]="isItemVisible(val) ? 'bi-caret-down-fill' : 'bi-caret-right-fill'"
                    style="font-size: 0.6rem;"></i>
              </div>
            </div>
            <div class="item-name-header"
                [ngStyle]="{'background-color': getItemHeaderBackground(val)}">
              <div class="item-name-header-title">
                  {{ isItemVisible(val) ? getItemHeaderStatus(val) : getItemHeaderName(val) }}
              </div>
            </div>
            <div class="delete-item" (click)="deleteItem(val)">
              <i class="bi bi-x"></i>
            </div>
          </div>
          <div *ngIf="isItemVisible(val)">
            <div class="item-information" *ngFor="let item_val of val.value">
              <div class="single-item-container">
                <div class="single-info single-info-value item-key-value single-info-key">
                  {{ formatOrderValType(item_val) }}
                </div>
                <div class="single-info single-info-value item-key-value single-info-value-array">
                  <div class="single-info-array-value"
                        *ngFor="let item of formatOrderVal(item_val); let i = index; let isLast=last"
                        [ngStyle]="freshDeskRetrieved(item)">
                    <div *ngIf="isDropdown(item_val)">
                      <dropdown-cell-renderer
                      [options]="optionsForTrackName(item_val)"
                      [isAgGrid]="false"
                      [jobsPage]="true"
                      [approvalsPage]="true"
                      [data]="dataForTrackName(item_val, item, true)"
                      [colField]="fieldForTrackName(item_val)"
                      (statusChanged)="currentItemId = val.item_id; item.fr = true; didStatusChange($event, val); fieldEdited($event, item_val, true)"
                      ></dropdown-cell-renderer>
                    </div>
                    <div #calendarWrapper *ngIf="isDate(item_val)" class="calendar-wrapper">
                      <div (click)="currentItemId = val.item_id; openCalendar($event, item_val)" style="z-index: 500">
                        {{formatSendDate(item.value)}}
                      </div>
                    </div>
                    <div *ngIf="isLink(item_val)">
                      <div *ngIf="!formatOrderLink(item_val, item, i)" class="flex flex-row tracking-links">
                        <input class="tracking-link-input"
                                type="text"
                                (focus)="startEditingTracking(item_val, item, i)"
                                (keydown.enter)="currentItemId = val.item_id; blurValue($event)"
                                (blur)="currentItemId = val.item_id; stopEditingTracking(item_val, item)"
                                [(ngModel)]="item.value"/>
                      </div>
                      <div *ngIf="formatOrderLink(item_val, item, i)" class="flex flex-row tracking-links">
                        <a [href]="item_val.value" target="_blank" style="color: #0d6efd">
                          {{ formatOrderLink(item_val, item, i) }}
                        </a>
                        <i class="bi bi-x"
                            (click)="currentItemId = val.item_id; clearLink(item_val, item)"
                            style="padding-left: 4px; position: relative"></i>
                        <i  *ngIf="isLast" class="bi bi-plus"
                            (click)="addLink(item_val)"
                            style="padding-left: 2px; position: relative"></i>
                      </div>
                    </div>
                    <div *ngIf="!isDropdown(item_val) && !isDate(item_val) && !isLink(item_val) && preProcessDecimal(item_val, true)">
                      <input class="order-input"
                              (blur)="currentItemId = val.item_id; stopEditingItemValue(item_val, item)"
                              (keydown.enter)="currentItemId = val.item_id; blurValue($event)"
                              type="text" [(ngModel)]="item.value"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item-information" *ngIf="!isItem(val)">
          <div class="single-item-container">
            <div class="single-info single-info-key">
              {{ formatOrderType(val) }}
            </div>
            <div class="single-info single-info-value single-info-value-array">
              <div class="single-info-array-value" *ngFor="let item of formatOrderVal(val); let i = index; let isLast=last">
                <div class="spacer-line" *ngIf="addSpace(item)"></div>
                <div [ngStyle]="freshDeskRetrieved(item)">
                  <div *ngIf="isDropdown(val)">
                    <dropdown-cell-renderer
                    [options]="optionsForTrackName(val)"
                    [isAgGrid]="false"
                    [jobsPage]="true"
                    [approvalsPage]="true"
                    [data]="dataForTrackName(val, item)"
                    [colField]="fieldForTrackName(val)"
                    (statusChanged)="fieldEdited($event, val, true)"
                    ></dropdown-cell-renderer>
                  </div>
                  <div #calendarWrapper *ngIf="isDate(val)" class="calendar-wrapper">
                    <div (click)="openCalendar($event, val)" style="z-index: 500">
                      {{formatSendDate(val?.value?.value)}}
                    </div>
                  </div>
                  <div *ngIf="isOrderDocs(val) && item?.value?.id" class="order-docs-cell custom-scrollbar">
                    <orders-cell-renderer
                    [isApprovals]="true"
                    [docData]="dataForOrder(item)">
                    </orders-cell-renderer>
                    <orders-doc-cell-renderer *ngIf="item.att"
                      [isApprovals]="true"
                      [docData]="dataForOrderAtt(item)">
                    </orders-doc-cell-renderer>
                  </div>
                  <div *ngIf="isOrderDocs(val) && isLast" class="new-order-doc">
                    <orders-cell-renderer
                    [isApprovals]="true"
                    [docData]="dataForOrderNew(item)">
                    </orders-cell-renderer>
                  </div>
                  <div *ngIf="isLink(val)">
                    <div *ngIf="!formatOrderLink(val, item, i)" class="flex flex-row tracking-links">
                      <input class="order-input"
                              type="text"
                              (focus)="startEditingTracking(val, item, i)"
                              (keydown.enter)="blurValue($event)"
                              (blur)="stopEditingTracking(val, item)"
                              [(ngModel)]="item.value"/>
                    </div>
                    <div *ngIf="formatOrderLink(val, item, i)" class="flex flex-row tracking-links">
                      <a [href]="item.value" target="_blank" style="color: #0d6efd">
                        {{ formatOrderLink(val, item, i) }}
                      </a>
                      <i class="bi bi-x"
                          (click)="clearLink(val, item)"
                          style="padding-left: 4px; position: relative"></i>
                      <i  *ngIf="isLast" class="bi bi-plus"
                          (click)="addLink(val)"
                          style="padding-left: 2px; position: relative"></i>
                    </div>
                  </div>
                  <div *ngIf="!isDropdown(val) && !isDate(val) && !isOrderDocs(val) &&
                              !isLink(val) && preProcessDecimal(val)">
                    <input class="order-input"
                            (blur)="stopEditingValue(val)"
                            (keydown.enter)="blurValue($event)"
                            type="text" [(ngModel)]="val.value.value"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showOrder" class="add-item" (click)="addItem()">
        Add item <i class="bi bi-plus-lg"></i>
      </div>
    </div>
    <div class="confirm-data-container" *ngIf="confirmPanel">
      <div class="confirm-button" (click)="onMailConfirm()">
        <i class="bi bi-check-lg"></i>
        <div class="confirm-text">
          Confirm
        </div>
      </div>
    </div>
  </div>
</div>

<div #calendar class="calendar-container" *ngIf="calendarVisible">
  <p-calendar class="max-w-full"
  [style]="{
    'width':'100%', 'height': '100%', 'z-index': '5',
    'position': 'relative',
    'bottom': '-72px',
  }"
  placeholder="YYYY-MM-DD"
  dateFormat="yy/mm/dd"
  dataType="date"
  [inline]="true"
  appendTo="body"
  (onSelect)="onChangeDate($event)"
  (onClear)="onChangeDate($event)">
</p-calendar>
</div>
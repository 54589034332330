import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { Renderer2 } from '@angular/core';

import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';

import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { selectUncategorizedView } from 'src/app/dashboard/state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardRoutes } from '../../enums/dashboard-routes.enum';
import { ActiveJobModel } from '../../models/active-job.model';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { TemplatesDialogComponent } from 'src/app/templates/components/templates/templates-dialog.component';
import { SelectedViewModel } from '../../models/selected-view.model';
import { JobsService } from '../../services/job.service';

@Component({
  selector: 'active-job-list',
  templateUrl: './active-job-list.component.html',
  styleUrls: ['./active-job-list.component.sass'],
})
export class ActiveJobListComponent extends OnDestroyMixin() implements OnInit {
  @Input() activeJobs: ActiveJobModel[] = [];
  @Input() selectedView?: SelectedViewModel;
  @Input() panelHeight!: 750;
  @Input() isLoading: any;
  @Input() isLoadingViews: any;
  @Input() activePage: any;

  @Output() selectedJob: EventEmitter<any> = new EventEmitter();
  @ViewChild('cm') public contextMenu!: ContextMenu;
  @ViewChild('jobContainer') jobContainer!: ElementRef;

  private documentClickListener!: () => void;
  contextMenuJob!: ActiveJobModel;
  uncatViewReceived: boolean = false;

  isLoadingUncategorizedView: any = true;
  filtersIcon = faFilter;
  plusIcon = faPlus;

  collapsed: boolean = false;

  DashboardRoutes = DashboardRoutes;
  showUncategorizedView: boolean = false;

  showTemplates = false;
  jobMenu: MenuItem[] = [
    { label: 'Hide job', icon: 'pi bi bi-eye-slash', command: () => this.turnOffTracking()},
    { separator: true},
    { label: 'Archive job', icon: 'pi bi-archive-fill', command: () => this.archiveJob()},
  ];

  constructor(
    public router: Router,
    private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private renderer: Renderer2,
    private jobsService: JobsService) {
    super();
    this.initUncategorizedViewSubscription();
    (window as any)['active'] = this;
  }

  toggleTemplatesDisplay() {
    this.showTemplates = !this.showTemplates;
  }

  selectJob(id: string): void {
    if (id == this.selectedJobId && this.activePage == 'Items') {
      return;
    }
    localStorage.setItem('selectedJob', JSON.stringify(id));
    this.selectedJob.emit(id);
  }

  openContextMenu(event: any, job: any) {
    this.contextMenuJob = job;
    event.preventDefault();
    this.contextMenu.show(event);
    setTimeout(() => {
      const contextMenuElement = this.contextMenu.containerViewChild.nativeElement as HTMLElement;
      if (contextMenuElement) {
        const newYPosition = event.clientY + 10;
        contextMenuElement.style.position = 'fixed';
        contextMenuElement.style.left = `${event.clientX}px`;
        contextMenuElement.style.top = `${newYPosition}px`;
        contextMenuElement.style.fontWeight = '200';
      }
    }, 1);

    this.documentClickListener = this.renderer.listen('document', 'click', (e: MouseEvent) => {
      if (this.contextMenu) {
        this.contextMenu.hide();
      }
    });
  }

  getPanelHeight() {
    return `${this.panelHeight - 140 - 25 * 3}px`;
  }

  removeJobFromActiveJobs(job: ActiveJobModel) {
    this.activeJobs = this.activeJobs.filter((j: any) => j.id != job.id);
  }

  turnOffTracking() {
    let payload = {...this.contextMenuJob, user_tracking: false, context_menu: true};
    this.removeJobFromActiveJobs(this.contextMenuJob);
    this.jobsService.updateJob({payload: payload}).subscribe((result: any) => {});
  }

  archiveJob() {
    let payload = {...this.contextMenuJob, is_archived: true, context_menu: true};
    this.removeJobFromActiveJobs(this.contextMenuJob);
    this.jobsService.updateJob({payload: payload}).subscribe((result: any) => {});
  }

  get selectedJobId(): string | null {
    const savedJobId = localStorage.getItem('selectedJob') as any;

    return !!savedJobId ? JSON.parse(savedJobId) : null;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let jdisplay = document.querySelector('.job-display');
      if (jdisplay) {
        jdisplay.addEventListener('mouseenter', function() {
          (jdisplay as any).classList.add('custom-scrollbar');
          (jdisplay as any).classList.remove('custom-scrollbar-close');
        });
        jdisplay.addEventListener('mouseleave', function() {
          (jdisplay as any).classList.remove('custom-scrollbar');
          (jdisplay as any).classList.add('custom-scrollbar-close');
        });
      }
    }, 600);


  }

  ngOnChanges(changes: any) {
    if (changes?.isLoading?.currentValue == false && !this.uncatViewReceived) {
      // defer initing until view data receieved
      this.uncatViewReceived = true;
      this.dashboardActions.requestUncategorizedViewBool({ include_items: CommonConstants.include_items.uncategorized });
      this.isLoadingUncategorizedView = true;
    }
  }

  ngOnInit(): void {
  }

  trackByFn(index: number, job: any): string {
    return index + job.id;
  }

  isActive(name: string) {
    return this.selectedJobId === name && this.activePage == 'Items';
  }

  private initUncategorizedViewSubscription(): void {
    this.store
      .pipe(
        select(selectUncategorizedView),
        filter((views: IStoreApiItem<any>) => !views.isLoading),
        takeUntil(this.destroy)
      )
      .subscribe((views: IStoreApiItem<any>) => {
        this.showUncategorizedView = !!views?.data?.uncategorized;
        this.isLoadingUncategorizedView = false;
      });
  }

  openAddOrder() {
    this.router.navigate([{ outlets: { addOrder: DashboardRoutes.AddOrder } }], { skipLocationChange: true }).then(() => {
      localStorage.removeItem('itemBreadcrumb');
    })
  }

  getJobColor(job: ActiveJobModel) {
    switch (job.color) {
      case 0:
        return '#eeeeee'; // gray
      case 1:
        return '#e44263'; // red
      case 2:
        return '#5fc64c'; // green
      case 3:
        return '#67d6d1'; // turquoise
      case 4:
        return '#59bcf9'; // blue
      default:
        return "#69DED2"; // current materlog color
    }
  }

  formatJobName(name: string) {
    let max_length = 20;
    return name.length > max_length ? name.substring(0, max_length) + '...' : name;
  }

  scrollDown() {
    if (this.jobContainer?.nativeElement) {
      this.jobContainer.nativeElement.scrollBy(0, 50);
    }
  }
}

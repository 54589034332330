import { Component, Input, OnInit } from '@angular/core';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { select, Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { IStoreApiItem } from './../../../common/models/store-api-item.model';
import { UserActions } from '../../state/actions/user.actions';
import { selectNotifications } from '../../state/selectors/user.selector';
import { INotifications } from '../../models/user.model';
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'materlog-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.sass']
})
export class PreferencesComponent extends OnDestroyMixin() implements OnInit {
  isLoading: boolean = false;

  notifications = {
    new_jobs_notification: [1],
    new_order_notification: [2],
    item_added_notification: [],
    item_not_ordered_notification: [2],
    item_ordered_notification: [2],
    item_in_stock_notification: [2],
    item_shipped_notification: [2],
    item_delivered_notification: [2],
    in_stock_date_type: 1,
    in_stock_date_notification: [2],
    ship_date_type: 1,
    ship_date_notification: [2],
    delivery_date_type: 1,
    delivery_date_notification: [2],
    job_delivered_notification: [2],
  };
  @Input() checkboxList: any;
  @Input() radiosList: any;

  constructor(
    private store: Store<IApplicationState>,
    private userActions: UserActions,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.onRequestNotifications();
  }



  onRequestNotifications() {
    this.userActions.requestNotifications();

    this.store
      .pipe(
        select(selectNotifications),
        filter((notifications: IStoreApiItem<INotifications>) => !notifications.isLoading),
        take(1)
      )
      .subscribe((notifications: IStoreApiItem<INotifications>) => {
        this.notifications = JSON.parse(JSON.stringify(notifications?.data));
        this.cdr.detectChanges();
      });
  }

  updateNotification(key: any, value: any) {

    const objToUpdate: any = {};

    objToUpdate[key] = value;

    this.userActions.requestUpdateNotification(objToUpdate);
    this.store
      .pipe(
        select(selectNotifications),
        filter((notification: IStoreApiItem<INotifications>) => !notification.isLoading),
        takeUntil(this.destroy)
      )
      .subscribe((notification: any) => {
        if (notification?.errors && !this.isLoading) {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong. Please try again later. If this issue persists, contact support@materlog.com.',
            key: 'error',
          });

        }
      });
  }

  //receives the type of notification using the checkbox id and returns the tooltip message
  notificationTooltip(checkboxId: any) {
    if (checkboxId === 1) {
      return 'Every hour';
    } else if (checkboxId === 2) {
      return 'Every day at 08:00 AM';
    } else {
      return 'Every Monday at 10:15 AM';
    }
  }

}

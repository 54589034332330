import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";

import { ICellEditorAngularComp } from "ag-grid-angular";

@Component({
  selector: 'calendar-cell-editor',
  templateUrl: "calendar.component.html"
})
export class CalendarEditor implements ICellEditorAngularComp {
  public params: any;

  constructor(
    private datePipe: DatePipe,
  ) { }

  agInit(params: any): void {
    this.params = params;
  }

  getValue(): any {
    if(this.params.value instanceof Date) {
      return this.datePipe.transform(this.params.value, 'MM/dd/yyyy')
    }else{
      return this.params.value;
    }
  }

  onChangeDate(): void {
    this.params.stopEditing();
  }
}

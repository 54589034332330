import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { FormControl, FormGroup, Validators } from '@angular/forms';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { CommonConstants } from '../../../common/constants/common.constants';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { AuthenticationActions } from '../../state/actions/authentication.actions';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IForgotPassword } from '../../models/authentication.model';
import { selectForgotPassword } from '../../state/selectors/authentication.selector';
import { PermissionsService } from 'src/app/root/services/permissions.service';

@Component({
  selector: 'materlog-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent extends OnDestroyMixin() implements OnInit {
  permissionService: PermissionsService;
  isSubmitted: boolean = false;
  errorMessage: string | undefined = '';
  forgotPasswordForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.pattern(CommonConstants.emailRegex)]),
  });

  constructor(
    public router: Router,
    private injector: Injector,
    private store: Store<IApplicationState>,
    private authenticationActions: AuthenticationActions,) {
    super();
    this.permissionService = this.injector.get<PermissionsService>(PermissionsService);
  }

  ngOnInit(): void {
    if (this.permissionService.isLoggedIn()) {
      this.router.navigate(['']);
    }
  }

  onSubmit() {
    this.forgotPasswordForm.markAllAsTouched();
    this.isSubmitted = true;

    if (this.forgotPasswordForm.valid) {
      this.handleForgotPassword();
    }
  }

  handleForgotPassword(): void {
    const objToUpdate: any = this.forgotPasswordForm.value;

    this.authenticationActions.requestForgotPassword({ ...objToUpdate });
    this.store.pipe(
      select(selectForgotPassword),
      filter((login: IStoreApiItem<IForgotPassword>) => !login.isLoading),
      take(1)
    ).subscribe(() => {
      this.errorMessage = undefined;
    });
  }

  onNavigateHome() {
    if (this.permissionService.isLoggedIn()) {
      this.router.navigate(['']);
    } else {
      this.router.navigate(['login'])
    }
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailBoxCommunicationService {
  private inboxButtonClickSubject = new Subject<void>();
  private inboxVisibilitySubject = new Subject<boolean>();
  private mailItemVisibilitySubject = new Subject<boolean>();
  private commentPaneClickSubject = new Subject<void>();
  private commentPaneVisibilitySubject = new Subject<boolean>();

  // Observable to subscribe to inbox button click events
  inboxButtonClick$ = this.inboxButtonClickSubject.asObservable();
  inboxVisibility$ = this.inboxVisibilitySubject.asObservable();
  mailVisibility$ = this.mailItemVisibilitySubject.asObservable();
  commentPaneClick$ = this.commentPaneClickSubject.asObservable();
  commentPaneVisibility$ = this.commentPaneVisibilitySubject.asObservable();

  // how header component talks to dashboard component
  toggle_inbox() {
    this.inboxButtonClickSubject.next();
  }

  // how dashboard component talks to email component
  get_email(data: any) {
    this.inboxVisibilitySubject.next(data);
  }

  get_item_data(data: any) {
    this.mailItemVisibilitySubject.next(data);
  }

  // how header component talks to dashboard component
  toggle_comments() {
    this.commentPaneClickSubject.next();
  }

  // how dashboard component talks to email component
  get_comments(show: boolean) {
    this.commentPaneVisibilitySubject.next(show);
  }
}

import { ShareViewsService } from './../../services/share-views.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {Observable, of, tap} from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import {
  INewJob,
  IUpdateJob,
  ISuppliers,
  ISupplierContacts,
  INewOrder,
  ISupplierCredentials, Suppliers, SupplierCredentials
} from '../../models/dashboard.model';
import { DashboardService } from '../../services/dashboard.service';
import { JobsService } from '../../services/job.service';
import { ReceiversService } from '../../services/receivers.service';
import { SupplierService } from './../../services/supplier.service';
import { OrderService } from './../../services/order.service';
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { ErrorJobList, ErrorReceiveAddJob, ErrorUpdateJob, ReceiveAddJob, ReceiveJobList, ReceiveUpdateJob } from '../actions/dashboard-action-types-creators/jobs-action-types.creators';
import { ErrorReceiveOptions, ReceiveOptions } from '../actions/dashboard-action-types-creators/options-action-types.creators';
import { ErrorReceiveAddOrder, ErrorReceiveAddRoom, ErrorReceiveOrders, ErrorReceiveUpdateOrder, ReceiveAddOrder, ReceiveAddRoom, ReceiveOrders, ReceiveUpdateOrder } from '../actions/dashboard-action-types-creators/orders-action-types.creators';
import { ReceiveReceivers, ErrorReceiveReceivers, ReceiveAddReceiver, ErrorReceiveAddReceiver, ReceiveAddReceiverContact, ErrorReceiveAddReceiverContact, ReceiveAutocompleteReceivers, ErrorReceiveAutocompleteReceivers } from '../actions/dashboard-action-types-creators/receivers-action-types.creators';
import {
  ReceiveSuppliers,
  ErrorReceiveSuppliers,
  ErrorReceiveAddSuppliers,
  ErrorReceiveAddSupplierContact,
  ReceiveAddSuppliers,
  ReceiveAddSupplierContact,
  ReceiveAutocompleteSuppliers,
  ErrorReceiveAutocompleteSuppliers, ReceiveSupplierCredentials,
  ReceiveSuppliersSmall, ErrorReceiveSuppliersSmall
} from './../actions/dashboard-action-types-creators/suppliers-action-types.creators';
import { ReceiveActiveJobs, ErrorReceiveActiveJobs } from './../actions/dashboard-action-types-creators/activeJobs-action-types.creators';
import { ErrorReceiveAddItem, ErrorReceiveDeleteItem, ErrorReceiveDeleteItemReason, ErrorReceiveUpdateItem, ReceiveAddItem, ReceiveDeleteItem, ReceiveDeleteItemReason, ReceiveUpdateItem } from '../actions/dashboard-action-types-creators/items-action-types.creators';
import { ErrorReceiveActiveOrders, ReceiveActiveOrders } from '../actions/dashboard-action-types-creators/activeOrders-action-types-creators';
import { ErrorReceiveViews, ErrorReceiveUncategorizedView, ReceiveViews, ReceiveUncategorizedView, ReceiveUpdateViewCell,
  ErrorReceiveUpdateViewCell, ErrorReceiveAttachmentDelete, ReceiveAttachmentDelete, ReceiveAddView, ErrorReceiveAddView,
  ErrorReceiveUpdateView, ReceiveUpdateView, ErrorReceiveDeleteView, ReceiveDeleteView,
  ReceiveUncategorizedViewBool, ErrorReceiveUncategorizedViewBool } from '../actions/dashboard-action-types-creators/views-action-types.creators';
import { TemplatesService } from '../../services/templates.service';
import { ReceiveAddTemplate, ErrorReceiveAddTemplate, ReceiveUpdateTemplate, ErrorReceiveUpdateTemplate, ReceiveActiveTemplates, ErrorReceiveActiveTemplates } from '../actions/dashboard-action-types-creators/templates-action-types.creators';
import { ErrorReceiveAddOrderDocument, ErrorReceiveDeleteOrderDocument, ErrorReceiveOrderDocument, ErrorReceiveUpdateOrderDocument, ReceiveAddOrderDocument, ReceiveDeleteOrderDocument, ReceiveOrderDocument, ReceiveUpdateOrderDocument } from '../actions/dashboard-action-types-creators/orderDocument-action-types.creators';
import { ErrorReceiveDeleteShareView, ErrorReceiveDeleteShareViewEmail, ErrorReceiveShareView, ErrorReceiveUpdateShareView, ErrorReceiveUpdateShareViewEmail, ReceiveDeleteShareView, ReceiveDeleteShareViewEmail, ReceiveShareView, ReceiveUpdateShareView, ReceiveUpdateShareViewEmail } from '../actions/dashboard-action-types-creators/shareView-action-types-creators';
import {SupplierCredentialsService} from "../../../suppliers/services/suppliers.credentials.service";
import { CommentsService } from '../../services/comment.service';
import { ErrorReceiveAddComment, ReceiveAddComment } from '../actions/dashboard-action-types-creators/comments-action-types.creators';

import {
  RequestAddSupplierCredentials
} from "../actions/dashboard-action-types-creators/supplier-credentials-action-types.creators";
import { IComments } from '../../models/comments.model';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private jobsService: JobsService,
    private receiversService: ReceiversService,
    private dashboardService: DashboardService,
    private templatesService: TemplatesService,
    private supplierService: SupplierService,
    private supplierCredentialsService: SupplierCredentialsService,
    private orderService: OrderService,
    private shareViewService: ShareViewsService,
    private commentService: CommentsService
  ) { }

  requestReceivers$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_RECEIVERS),
      switchMap(() =>
        this.receiversService.getReceivers().pipe(
          map(
            (receivers: any) => new ReceiveReceivers(receivers)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveReceivers(error)))
        )
      )
    );
  });

  requestOptions$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_OPTIONS),
      switchMap(() =>
        this.dashboardService.getOptions().pipe(
          map((options: any) => new ReceiveOptions(options)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveOptions(error)))
        )
      )
    );
  });

  requestActiveJobs$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ACTIVE_JOBS),
      switchMap(() =>
        this.jobsService.getActiveJobs().pipe(
          map((activeJob: any) => new ReceiveActiveJobs(activeJob)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveActiveJobs(error)))
        )
      )
    );
  });

  requestAddJob$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ADD_JOB),
      switchMap((newJob: INewJob) =>
        this.jobsService.addJob(newJob).pipe(
          map((job: any) => new ReceiveAddJob(job)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddJob(error)))
        )
      )
    );
  });

  requestUpdateJob$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_UPDATE_JOB),
      switchMap((updateJob: IUpdateJob) =>
        this.jobsService.updateJob(updateJob).pipe(
          map((job: any) => new ReceiveUpdateJob(job)),
          catchError((error: HttpErrorResponse) => of(new ErrorUpdateJob(error)))
        )
      )
    );
  });
  

  requestJobList$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_JOB_LIST),
      switchMap((params?: any) =>
        this.jobsService.getJobList(params).pipe(
          map((jobList: any) => new ReceiveJobList(jobList)),
          catchError((error: HttpErrorResponse) => of(new ErrorJobList(error)))
        )
      )
    );
  });

  requestAddOrder$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ADD_ORDER),
      switchMap((newOrder: INewOrder) =>
        this.orderService.addOrder(newOrder).pipe(
          map(
            (order: any) =>
              new ReceiveAddOrder(order)
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddOrder(error)))
        )
      )
    );
  });

  requestAddComment$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ADD_COMMENT),
      switchMap((newComment: IComments) => 
        this.commentService.addComment(newComment).pipe(
          map(
            (comment: any) =>
              new ReceiveAddComment(comment)
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddComment(error)))
        )
      )
    );
  });

  requestSuppliers$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_SUPPLIERS),
      switchMap(() =>
        this.supplierService.getSuppliers().pipe(
          map((supplier: any) => new ReceiveSuppliers(supplier)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveSuppliers(error)))
        )
      )
    );
  });

  requestSuppliersSmall$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_SUPPLIERS_SMALL),
      switchMap(() =>
      this.supplierService.getSuppliersSmall().pipe(
        map((supplier: any) => new ReceiveSuppliersSmall(supplier)),
        catchError((error: HttpErrorResponse) => of(new ErrorReceiveSuppliersSmall(error)))
        )
      )
    );
  });


  requestSupplierCredentials$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_SUPPLIER_CREDENTIALS),
      switchMap(() =>
        this.supplierCredentialsService.getSupplierCredentials().pipe(
          map((supplier: any) => new ReceiveSupplierCredentials(supplier)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveSuppliers(error)))
        )
      )
    );
  });

  requestAddReceiver$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ADD_RECEIVER),
      switchMap((newReceiver: any) =>
        this.receiversService.addReceiver(newReceiver).pipe(
          map((receiver: any) => new ReceiveAddReceiver(receiver)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddReceiver(error)))
        )
      )
    );
  });

  requestAddReceiverContact$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ADD_RECEIVER_CONTACT),
      switchMap((newReceiverContact: any) =>
        this.receiversService.addReceiverContact(newReceiverContact).pipe(
          map((receiverContact: any) => new ReceiveAddReceiverContact(receiverContact)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddReceiverContact(error)))
        )
      )
    );
  });
  requestAddSuppliers$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ADD_SUPPLIERS),
      switchMap((newSupplier: ISuppliers) =>
        this.supplierService.addSupplier(newSupplier).pipe(
          map((supplier: any) => new ReceiveAddSuppliers(supplier)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddSuppliers(error)))
        )
      )
    );
  });

  requestAddSupplierCredentials$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(

      ofType(DashboardActionTypes.REQUEST_ADD_SUPPLIER_CREDENTIALS),
      switchMap((newSupplier: ISupplierCredentials) =>
        this.supplierCredentialsService.addSupplierCredentials(newSupplier).pipe(
          map((supplier: any) => new RequestAddSupplierCredentials(supplier)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddSuppliers(error)))
        )
      )
    );
  });

  requestAddSupplierContact$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ADD_SUPPLIER_CONTACT),
      switchMap((newSupplierContact: ISupplierContacts) =>
        this.supplierService.addSupplierContact(newSupplierContact).pipe(
          map((supplierContact: any) => new ReceiveAddSupplierContact(supplierContact)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddSupplierContact(error)))
        )
      )
    );
  });

  requestAddItem$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ADD_ITEM),
      switchMap((newItem: any) =>
        this.orderService.addItem(newItem).pipe(
          map((item: any) => new ReceiveAddItem(item)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddItem(error)))
        )
      )
    );
  });

  requestActiveOrders$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ACTIVE_ORDERS),
      switchMap((params?: any) =>
        this.orderService.getActiveOrders(params).pipe(
          map((activeOrders: any) => new ReceiveActiveOrders(activeOrders)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveActiveOrders(error)))
        )
      )
    );
  });

  requestOrders$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ORDERS),
      switchMap((params?: any) =>
        this.orderService.getOrders(params).pipe(
          map((orders: any) => new ReceiveOrders(orders)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveOrders(error)))
        )
      )
    );
  });

  requestViews$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_VIEWS),
      concatMap((params?: any) =>
        this.dashboardService.getViews(params).pipe(
          map((view: any) => new ReceiveViews(view)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveViews(error)))
        )
      )
    );
  });

  requestAddView$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ADD_VIEW),
      switchMap((addView: any) =>
        this.dashboardService.addView(addView).pipe(
          map((view: any) => new ReceiveAddView(view)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddView(error)))
        )
      )
    );
  });

  requestUpdateView$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_UPDATE_VIEW),
      switchMap((updateView: any) =>
        this.dashboardService.updateView(updateView).pipe(
          map((view: any) => new ReceiveUpdateView(view)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateView(error)))
        )
      )
    );
  });


 /* requestSaveView$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_SAVE_VIEW),
      switchMap((updateView: any) =>
        this.dashboardService.saveView(updateView).pipe(
          map((view: any) => new ReceiveUpdateView(view)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateView(error)))
        )
      )
    );
  });*/


  requestDeleteView$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_DELETE_VIEW),
      switchMap((deleteView: any) =>
        this.dashboardService.deleteView(deleteView).pipe(
          map((view: any) => new ReceiveDeleteView(view)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteView(error)))
        )
      )
    );
  });

  requestUpdateViewCell$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_UPDATE_VIEW_CELL),
      switchMap((updateViewCell: any) =>
        this.dashboardService.updateViewCell(updateViewCell).pipe(
          map((view: any) => new ReceiveUpdateViewCell(view)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateViewCell(error)))
        )
      )
    );
  });

  requestUncategorizedView$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_UNCATEGORIZED_VIEW),
      switchMap((params?: any) =>
        this.dashboardService.getViews(params).pipe(
          map((view: any) => new ReceiveUncategorizedView(view)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUncategorizedView(error)))
        )
      )
    );
  });

  requestUncategorizedViewBool$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_UNCATEGORIZED_VIEW_BOOL),
      switchMap((params?: any) => {
        const modifiedPayload = { ...params.payload, small: true};
        const modifiedParams = {...params, payload: modifiedPayload};
        return this.dashboardService.getUncategorized(modifiedParams).pipe(
          map((view: any) => new ReceiveUncategorizedViewBool(view)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUncategorizedViewBool(error)))
          )
        }
      )
    );
  });

  requestAttachmentDelete$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ATTACHMENT_DELETE),
      switchMap((itemId: any) =>
        this.dashboardService.deleteAttachment(itemId).pipe(
          map((item: any) => new ReceiveAttachmentDelete(item)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAttachmentDelete(error)))
        )
      )
    );
  });

  requestDeleteItem$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_DELETE_ITEM),
      switchMap((itemId: any) =>
        this.orderService.deleteItem(itemId).pipe(
          map((item: any) => new ReceiveDeleteItem(item)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteItem(error)))
        )
      )
    );
  });

  requestUpdateItem$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_UPDATE_ITEM),
      switchMap((itemId: any) =>
        this.orderService.updateItem(itemId).pipe(
          map((item: any) => new ReceiveUpdateItem(item)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateItem(error)))
        )
      )
    );
  });

  requestAddRoom$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ADD_ROOM),
      switchMap((param: any) =>
        this.orderService.addRoom(param).pipe(
          map((item: any) => new ReceiveAddRoom(item)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddRoom(error)))
        )
      )
    );
  });

  requestDeleteItemReason$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_DELETE_ITEM_REASON),
      switchMap((param: any) =>
        this.orderService.deleteItemReason(param).pipe(
          map((item: any) => new ReceiveDeleteItemReason(item)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteItemReason(error)))
        )
      )
    );
  });

  requestOrderDocument$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ORDER_DOCUMENT),
      switchMap((params?: any) =>
        this.dashboardService.getOrderDocument(params).pipe(
          map((orderDocument: any) => new ReceiveOrderDocument(orderDocument)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveOrderDocument(error)))
        )
      )
    );
  });

  requestAddOrderDocument$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ADD_ORDER_DOCUMENT),
      switchMap((params?: any) =>
        this.dashboardService.addOrderDocument(params).pipe(
          map((orderDocument: any) => new ReceiveAddOrderDocument(orderDocument)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddOrderDocument(error)))
        )
      )
    );
  });

  requestUpdateOrderDocument$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_UPDATE_ORDER_DOCUMENT),
      switchMap((params?: any) =>
        this.dashboardService.updateOrderDocument(params).pipe(
          map((orderDocument: any) => new ReceiveUpdateOrderDocument(orderDocument)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateOrderDocument(error)))
        )
      )
    );
  });

  requestDeleteOrderDocument$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_DELETE_ORDER_DOCUMENT),
      switchMap((params?: any) =>
        this.dashboardService.deleteOrderDocument(params).pipe(
          map((orderDocument: any) => new ReceiveDeleteOrderDocument(orderDocument)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteOrderDocument(error)))
        )
      )
    );
  });

  requestTemplates$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ACTIVE_TEMPLATES),
      switchMap(() =>
        this.templatesService.getTemplates().pipe(
          map((template: any) => new ReceiveActiveTemplates(template)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveActiveTemplates(error))))
      )
    );
  });

  requestAddTemplate$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_ADD_ACTIVE_TEMPLATE),
      switchMap((addTemplate: any) =>
        this.templatesService.addTemplate(addTemplate).pipe(
          map((template: any) => new ReceiveAddTemplate(template)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddTemplate(error))))
      )
    );
  });

  requestUpdateTemplate$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_UPDATE_ACTIVE_TEMPLATE),
      switchMap((updateTemplate: any) =>
        this.templatesService.updateTemplate(updateTemplate).pipe(
          map((template: any) => new ReceiveUpdateTemplate(template)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateTemplate(error)))
        )
      )
    );
  });

  requestShareView$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_SHARE_VIEW),
      switchMap((shareView: any) =>
        this.shareViewService.getShareView(shareView).pipe(
          map((shareView: any) => new ReceiveShareView(shareView)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveShareView(error)))
        )
      )
    );
  });

  requestUpdateShareView$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_UPDATE_SHARE_VIEW),
      switchMap((shareView: any) =>
        this.shareViewService.updateShareView(shareView).pipe(
          map((shareView: any) => new ReceiveUpdateShareView(shareView)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateShareView(error)))
        )
      )
    );
  });

  requestDeleteShareView$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_DELETE_SHARE_VIEW),
      switchMap((shareView: any) =>
        this.shareViewService.deleteShareViewLink(shareView).pipe(
          map((shareView: any) => new ReceiveDeleteShareView(shareView)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteShareView(error)))
        )
      )
    );
  });

  requestUpdateShareViewEmail$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_UPDATE_SHARE_VIEW_EMAIL),
      switchMap((shareViewEmail: any) =>
        this.shareViewService.updateShareViewEmails(shareViewEmail).pipe(
          map((shareViewEmail: any) => new ReceiveUpdateShareViewEmail(shareViewEmail)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateShareViewEmail(error)))
        )
      )
    );
  });

  requestDeleteShareViewEmail$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_DELETE_SHARE_VIEW_EMAIL),
      switchMap((shareViewEmail: any) =>
        this.shareViewService.deleteShareViewEmails(shareViewEmail).pipe(
          map((shareViewEmail: any) => new ReceiveDeleteShareViewEmail(shareViewEmail)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteShareViewEmail(error)))
        )
      )
    );
  });

  requestAutocompleteSuppliers$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_AUTOCOMPLETE_SUPPLIERS),
      switchMap((param: any) =>
        this.supplierService.getAutocompleteSuppliers(param).pipe(
          map((supplier: any) => new ReceiveAutocompleteSuppliers(supplier)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAutocompleteSuppliers(error)))
        )
      )
    );
  });

  requestAutocompleteReceivers$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_AUTOCOMPLETE_RECEIVERS),
      switchMap((param: any) =>
        this.receiversService.getAutocompleteReceivers(param).pipe(
          map((receiver: any) => new ReceiveAutocompleteReceivers(receiver)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAutocompleteReceivers(error)))
        )
      )
    );
  });

  requestUpdateOrder$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActionTypes.REQUEST_UPDATE_ORDER),
      switchMap((param: any) =>
        this.orderService.updateOrder(param).pipe(
          map((order: any) => new ReceiveUpdateOrder(order)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateOrder(error)))
        )
      )
    );
  });

}

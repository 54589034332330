import { AfterViewInit,Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

import { ActiveJobModel } from '../../models/active-job.model';
import { SelectedViewModel } from '../../models/selected-view.model';
import { OptionsService } from '../../services/options.service';
import {Router} from '@angular/router';
import { JobTableService } from "../../services/job-table.service";

@Component({
  selector: 'dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrls: ['./dashboard-filters.component.sass'],
})
export class DashboardFiltersComponent implements AfterViewInit{
  @Input() selectedView?: SelectedViewModel;
  @Input() isLoading!: boolean;
  @Input() activeJob!: ActiveJobModel;
  @Input() mailBoxVisible: boolean = false;
  @Output() searchFormsChange = new EventEmitter<string>();
  @Output() imageSizeChange = new EventEmitter<string>();
  @Output() updateLoadingOverlay: EventEmitter<any> = new EventEmitter();
  @ViewChild('dashboardSearchAndHistory', {static: false}) dashboardSearchAndHistory?: ElementRef;

  sidePanelOpen: boolean = true;

  search?:any = {};
  constructor(
    readonly optionsService: OptionsService,
    private jobTableService: JobTableService,
    private router: Router) {
  }

  onUpdateLoadingOverlay() {
    this.updateLoadingOverlay.emit()
  }

  isV1Route():boolean{
    return this.router.url.endsWith("v1");
  }

  ngOnInit() {
    let searchTerm = localStorage.getItem('searchPhrase');
    if (this.selectedView?.activeView?.id) {
      this.search = {[this.selectedView.activeView.id]: searchTerm} as any;
    }
  }

  ngAfterViewInit(): void {
  }

  clearSearch(){
    let key = ""+this.selectedView?.activeView.id;
    this.search[key] = undefined;
    //this.search = undefined;
    this.searchFormsChange.emit( this.search );

  }

  onFilterTextBoxChanged():void {
    //let search = (document.getElementById('filter-text-box') as HTMLInputElement).value
    let key = ""+this.selectedView?.activeView.id;
    this.searchFormsChange.emit(this.search );
  }


}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ISharedViewState } from '../models/shared-view.model';
import { RequestSharedViewPage } from './shared-view-action-type-creators/shared-view-page-action-types.creators';

@Injectable({ providedIn: 'root' })
export class SharedViewActions {
  constructor(private store: Store<ISharedViewState>) { }

  public requestSharedViewPage(params?: any): void {
    this.store.dispatch(new RequestSharedViewPage(params));
  }

}

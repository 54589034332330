<div class="dialog-supplier">
  <form class="dialog-supplier__content">
    <div *ngIf="sidePanelAdd" class="contact-header">
      <span>Supplier contact</span>
      <div class="supplier-contact-close" *ngIf="credPage">
        <button class="dialog-close" type="button" (click)="closeSupplierContact()">
          <i class="bi bi-x-lg"></i>
        </button>
      </div>
    </div>
    <div *ngIf="!sidePanelAdd" class="dialog-supplier__content-item">
      <p-dropdown
        [options]="supplierList"
        [(ngModel)]="supplierId"
        id="supplierName"
        optionValue="id"
        optionLabel="name"
        placeholder="Supplier"
        name="supplierName"
        [required]="true"
        [disabled]="true"
      >
      </p-dropdown>
      <p *ngIf="isSubmitted && isSupplierSelected" class="warning-text">Add a supplier name</p>
    </div>

    <div class="group">
      <div class="dialog-supplier__content-item">
        <input
          class="form-controls"
          name="name"
          type="text"
          placeholder="Supplier contact - First Name"
          [(ngModel)]="supplierContactForm.first_name"
        />
        <p *ngIf="isSubmitted && isSupplierContactFirstName" class="warning-text">Add first name</p>
      </div>
      <div class="dialog-supplier__content-item">
        <input
          class="form-controls"
          name="name"
          type="text"
          placeholder="Supplier contact - Last Name"
          [(ngModel)]="supplierContactForm.last_name"
        />
      </div>
    </div>

    <div class="group">
      <div class="dialog-supplier__content-item">
        <input
          class="form-controls"
          name="website"
          type="text"
          placeholder="Supplier contact - Title"
          [(ngModel)]="supplierContactForm.title"
        />
      </div>
      <p-dropdown
        [options]="contactRoles"
        [(ngModel)]="supplierContactForm.role"
        id="role"
        optionValue="id"
        optionLabel="value"
        placeholder="Supplier role"
        name="role"
      >
      </p-dropdown>
    </div>
    <div class="dialog-supplier__content-item">
      <input
        class="form-controls"
        name="email"
        type="email"
        placeholder="Supplier contact - Email"
        [(ngModel)]="supplierContactForm.email"
      />
      <div class="textfield__error-message" *ngIf="!isSupplierContactEmail">Wrong email format.</div>
      <p *ngIf="isSubmitted && isSupplierContactEmail && isEmailInputed" class="warning-text">Add contact email</p>
    </div>
    <div class="dialog-supplier__content-item">
      <input
        class="form-controls"
        name="phone"
        type="text"
        placeholder="Supplier contact - Phone"
        [(ngModel)]="supplierContactForm.phone"
        maxlength="20"
      />
    </div>
    <div *ngIf="!sidePanelAdd || credPage" class="dialog-supplier__content-action">
      <button
        class="dialog-supplier__content-action-button"
        type="submit"
        [disabled]="isLoading"
        (click)="submitSupplierContact()"
      >
        Save
      </button>
    </div>
  </form>
</div>

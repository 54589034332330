import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { INotifications } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private baseUrl: string = environment.ENV.BASE_URL;

  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  public getNotifications(): Observable<INotifications | HttpErrorResponse> {
    return this.httpClient.get<INotifications>(`${this.baseUrl}users/notifications/`, this.httpHelper.options);
  }

  public updateNotification(notification: any): Observable<INotifications | HttpErrorResponse> {
    return this.httpClient.put<INotifications>(`${this.baseUrl}users/notifications/`, notification.payload, this.httpHelper.options);
  }

}

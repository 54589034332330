import { ChangeDetectionStrategy, Component, Input, OnInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs';

import { faFilter, faFloppyDisk, faLayerGroup, faMagicWandSparkles, faRotate, faXmark } from '@fortawesome/free-solid-svg-icons';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { selectActiveTemplates } from 'src/app/dashboard/state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { ITemplates } from 'src/app/dashboard/models/dashboard.model';
import { CustomErrorService } from '../../services/custom-error.service';
import { SelectedViewModel } from '../../models/selected-view.model';
import { DashboardRoutes } from '../../enums/dashboard-routes.enum';
import { DashboardJobView } from '../../services/dashboard_job_view.service';

@Component({
  selector: 'templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatesComponent extends OnDestroyMixin() implements OnInit, OnChanges {
  @Input() selectedView?: SelectedViewModel;
  @Input() dropdownOptions!: any;
  @Input() isLoading!: boolean;
  @Input() mailBoxVisible: boolean = false;


  activeTemplates: any[] = [];
  showTemplates: boolean = false;

  templateIcon = faMagicWandSparkles;
  saveIcon = faFloppyDisk;
  resetIcon = faRotate;
  applyIcon = faLayerGroup;
  filtersIcon = faFilter;
  trashIcon = faXmark;

  toggleTemplatesModal: boolean = false;
  addTemplateNameModal: boolean = false;
  isAddEditTemplateNameSubmitted: boolean = false;
  isSaveTemplateDisabled: boolean = false;
  addTemplateForm: any = {
    name: '',
    privacy: 1,
  };

  constructor(
    public router: Router,
    private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private cdr: ChangeDetectorRef,
    private djv: DashboardJobView,
    private customErrorService: CustomErrorService) {
    super();
    this.initActiveTemplatesSubscription();
  }

  ngOnInit(): void {
    this.dashboardActions.requestActiveTemplates();
    (window as any)['template'] = this;
  }
  
  get templateText() {
    return this.mailBoxVisible ? '': 'Templates';
  }

  ngOnChanges(): void {
    this.onCheckSaveTemplateDisabled();
  }

  private initActiveTemplatesSubscription() {
    this.store.pipe(
      select(selectActiveTemplates),
      filter((activeTemplates: IStoreApiItem<ITemplates[]>) => !activeTemplates.isLoading),
      takeUntil(this.destroy)
    ).subscribe((activeTemplates: IStoreApiItem<ITemplates[]>) => {
      if (activeTemplates.isSuccess) {
        this.activeTemplates = activeTemplates?.data;
        this.onCheckSaveTemplateDisabled();
      }

      if (activeTemplates.errors) {
        this.customErrorService.setCustomErrorMessage(activeTemplates);
      }
    });
  }

  emptyTemplateClick(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  showTemplatesModal(event: any) {
    if (this.addTemplateNameModal) return;
    event.stopPropagation();
    event.preventDefault();
    this.toggleTemplatesModal = !this.toggleTemplatesModal;
    this.cdr.detectChanges();
  }

  outsideClickModal(event: any) {
    if (!this.toggleTemplatesModal) return;
    event.stopPropagation();
    event.preventDefault();
    this.toggleTemplatesModal = false;
    this.cdr.detectChanges();
  }

  onViewTemplates(): void {
    this.toggleTemplatesModal = false;
    this.onCloseAddTemplateNameModal();
    this.showTemplates = true;
    this.cdr.detectChanges();
  }

  onOpenCreateTemplateModal(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.toggleTemplatesModal = false;
    this.addTemplateNameModal = true;
  }

  onCloseAddTemplateNameModal() {
    this.addTemplateNameModal = false;
    this.isAddEditTemplateNameSubmitted = false;
    this.addTemplateForm = {
      name: '',
      privacy: 1,
    };
  }

  onCheckSaveTemplateDisabled() {
    this.isSaveTemplateDisabled = !(this.activeTemplates?.find((template: any) => 
      template.id === this.selectedView?.activeView?.template_id)?.can_be_updated);
  }

  updateViewWithPayload(payload: any) {
    this.dashboardActions.requestUpdateViewObs(payload).subscribe((result: any) => {
      if (result?.length) {
        let data = {'view': result[0]};
        this.djv.get_view_data({'retrieve': data});
      }
    });
  }

  onApplyTemplate(event: any) {
    let og_event = event.originalEvent;
    if (og_event) {
      this.closeAndPrevent(og_event);
    }

    let payload = { template_id: event.value, selectedViewId: this.selectedView?.activeView?.id }
    this.updateViewWithPayload(payload);
  }

  onSaveAddTemplate() {
    this.isAddEditTemplateNameSubmitted = true;
    if (this.addTemplateForm.name?.trim()) {
      this.triggerTemplateUpdates();
      this.onCloseAddTemplateNameModal();
    }
  }

  closeAndPrevent(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.toggleTemplatesModal = false;
    this.cdr.detectChanges();
  }

  onSaveTemplate(event: any) {
    this.closeAndPrevent(event);
    this.dashboardActions.requestUpdateTemplate(
      { id: this.selectedView?.activeView.template_id, view_id: this.selectedView?.activeView?.id });
  }

  onResetTemplate(event: any) {
    let payload = {
      template_id: this.selectedView?.activeView?.template_id,
      selectedViewId: this.selectedView?.activeView?.id
    };
    this.updateViewWithPayload(payload);
    this.closeAndPrevent(event);
  }

  onDeleteTemplate(event: any) {
    if (this.selectedView?.activeView?.template_id) {
      this.selectedView.activeView.template_id = null;
    }
    let payload = { template_id: null, selectedViewId: this.selectedView?.activeView?.id };
    this.updateViewWithPayload(payload);
    this.closeAndPrevent(event);
  }


  private triggerTemplateUpdates(): void {
    this.dashboardActions.requestAddTemplate({ ...this.addTemplateForm, view_id: this.selectedView?.activeView?.id });
    this.store
      .pipe(
        select(selectActiveTemplates),
        filter((activeTemplates: IStoreApiItem<ITemplates[]>) => !activeTemplates.isLoading),
        take(1)
      )
      .subscribe((activeTemplates: IStoreApiItem<ITemplates[]>) => {
        if (activeTemplates.isSuccess) {
          this.activeTemplates = activeTemplates?.data;
          this.dashboardActions.requestViews({ view_id: this.selectedView?.activeView?.id });
        }

        if (activeTemplates.errors) {
          this.customErrorService.setCustomErrorMessage(activeTemplates);
        }
      });
  }
}

<div class="orders-row">
  <span class="orders-items">
    <div *ngIf="params?.value?.length" class="d-flex gap-1">
      <div
        style="cursor: pointer"
        *ngFor="let attachment of params?.value"
        [ngClass]="attachment.attachment_preview ? 'orders-item' : 'orders-item orders-item--no-border'"
      >
        <!-- if it's an image open the p-image , if not call openDocumentModal -->
        <div *ngIf="!checkFileType(attachment.filename); else isImage">
          <p-image
            class="cell-image"
            src="{{ attachment.attachment_preview ? attachment.attachment_preview : 'assets/img/default-doc-img.png' }}"
            alt="{{ attachment.filename }}"
            width="20"
            (click)="openDocumentModal(attachment.attachment, attachment.filename)"
          >
            <ng-template pTemplate="indicator">
              <fa-icon class="icon" [icon]="faEye"></fa-icon>
            </ng-template>
          </p-image>
        </div>
        <ng-template #isImage>
          <p-image
            appendTo="body"
            class="cell-image"
            src="{{ attachment.attachment ? attachment.attachment : 'assets/img/default-doc-img.png' }}"
            alt="{{ attachment.filename }}"
            [preview]="true"
            width="20"
          >
            <ng-template pTemplate="indicator">
              <fa-icon class="icon" [icon]="faEye"></fa-icon>
            </ng-template>
          </p-image>
        </ng-template>

      </div>
    </div>
  </span>
</div>

<p-dialog
  [(visible)]="documentModal"
  [style]="{ marginTop: '50px', width: '90vw', height: '90vh' }"
  [closeOnEscape]="true"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span></span>
    </div>
    <button class="dialog-close" type="button" (click)="closeDocumentModal()"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <ng-template pTemplate="content">
    <iframe [src]="selectedAttachment | sanitize" allow="fullscreen" width="100%" height="98%"></iframe>
  </ng-template>
</p-dialog>

import { IViews } from '../../models/dashboard.model';
import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorItemData, errorPostPutItemData, receiveItemData, receivePostPutItemData, requestItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { IViewsState, ViewsState } from '../models/views.model';

export function viewsReducer(
  lastState: IViewsState = new ViewsState(),
  action: GenericAction<DashboardActionTypes, any>
): IViewsState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_VIEWS:
      return requestItemData<IViewsState, IViews>(lastState, ['views']);
    case DashboardActionTypes.RECEIVE_VIEWS:
      return receiveItemData<IViewsState, IViews>(lastState, ['views'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_VIEWS:
      return errorItemData<IViewsState, IViews>(lastState, ['views'], action.payload);

    case DashboardActionTypes.REQUEST_ADD_VIEW:
      return requestPostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);
    case DashboardActionTypes.RECEIVE_ADD_VIEW:
      return receivePostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ADD_VIEW:
      return errorPostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);

    case DashboardActionTypes.REQUEST_UPDATE_VIEW:
      return requestPostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);
    case DashboardActionTypes.RECEIVE_UPDATE_VIEW:
      return receivePostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_UPDATE_VIEW:
      return errorPostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);

    case DashboardActionTypes.REQUEST_DELETE_VIEW:
      return requestPostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);
    case DashboardActionTypes.RECEIVE_DELETE_VIEW:
      return receivePostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_DELETE_VIEW:
      return errorPostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);

    case DashboardActionTypes.REQUEST_UNCATEGORIZED_VIEW:
    case DashboardActionTypes.REQUEST_UNCATEGORIZED_VIEW_BOOL:
      return requestItemData<IViewsState, IViews>(lastState, ['uncategorizedView']);
    case DashboardActionTypes.RECEIVE_UNCATEGORIZED_VIEW:
    case DashboardActionTypes.RECEIVE_UNCATEGORIZED_VIEW_BOOL:
      return receiveItemData<IViewsState, IViews>(lastState, ['uncategorizedView'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_UNCATEGORIZED_VIEW:
    case DashboardActionTypes.ERROR_RECEIVE_UNCATEGORIZED_VIEW_BOOL:
      return errorItemData<IViewsState, IViews>(lastState, ['uncategorizedView'], action.payload);

    case DashboardActionTypes.REQUEST_UPDATE_VIEW_CELL:
      return requestPostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);
    case DashboardActionTypes.RECEIVE_UPDATE_VIEW_CELL:
      return receivePostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_UPDATE_VIEW_CELL:
      return errorPostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);

    case DashboardActionTypes.REQUEST_ATTACHMENT_DELETE:
      return requestPostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);
    case DashboardActionTypes.RECEIVE_ATTACHMENT_DELETE:
      return receivePostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ATTACHMENT_DELETE:
      return errorPostPutItemData<IViewsState, IViews>(lastState, ['views'], action.payload);

    case   DashboardActionTypes.REQUEST_SAVE_VIEW:
      return lastState;

    default:
      return lastState;
  }
}

import { TableConstants } from "src/app/common/constants/table.constants";
import { ITableColumn } from "src/app/common/models/table.model";
import { IRequestViewsPayload } from "../interfaces/request-views-payload.interface";
import { ISelectedField } from "../interfaces/selected-field.interface";
import { HistoryModel } from "./history.model";
import { JobModel } from "./job.model";
import { ViewModel } from "./view.model";
import { CommonConstants } from "src/app/common/constants/common.constants";
import {deepCopy, deepArrayClone} from "src/app/common/utils/general"



export class SelectedViewModel {
  private nextPageUrl: string | null;
  private fields_to_sort: string[];
  private fields_to_filter: string[];
  private fields_to_group: string[];
  results: any[];
  show_subitems: boolean = false;

  static fromJSON(object: any): SelectedViewModel {
    return new SelectedViewModel(
      object.count,
      object.fields_to_edit,
      object.fields_to_filter,
      object.fields_to_sort,
      object.fields_to_group,
      object.fields_to_pin,
      object.customization,
      object.next,
      object.previous,
      object.results.map((job: JobModel) => JobModel.fromJSON(job)),
      object.views.map((view: ViewModel) => ViewModel.fromJSON(view)),
      object.history ? object.history.map((job: HistoryModel) => HistoryModel.fromJSON(job)) : undefined,
      object.resultsPerNextPage,
      object.show_subitems,
    )
  }

  constructor(
    readonly totalNrOfJobs: number,
    readonly fields_to_edit: string[],
    fields_to_filter: string[],
    fields_to_sort: string[],
    fields_to_group: string[] = [],
    readonly fields_to_pin: string[],
    readonly customization: any,
    readonly nextPage: string | null,
    readonly previousPage: string | null,
    results: any[],
    readonly views: ViewModel[],
    readonly history: HistoryModel[],
    readonly resultsPerNextPage: number,
    show_subitems: boolean)
  {
    this.fields_to_group = fields_to_group ? deepCopy(fields_to_group) : [];
    this.fields_to_sort = deepCopy(fields_to_sort);
    this.fields_to_filter = deepCopy(fields_to_filter);
    this.results = results ? deepCopy(results): [];

    this.initSelectedView();
    this.nextPageUrl = nextPage;

    if(this.fields_to_pin == undefined){
      this.fields_to_pin = [];
    }
    if(this.customization == undefined){
      this.customization = {};
    }
  }

  get selectedColumns(): ITableColumn[] {
    //check if order already there,
    return   this.activeView?.fields_selected_to_display
      ? this.activeView?.fields_selected_to_display
      : TableConstants.jobItemsTableColumns;
  }

  get selectedColumnsToFilter(): ISelectedField[] {
    return this.selectedColumns.filter((item: ITableColumn) =>
      this.fields_to_filter.includes(item.field))
      .map((column: ISelectedField) => ({ field: column.field, header: column.header }));
  }
  get selectedColumnsToGroup(): any[] {
    return this.activeView.get_fields_selected_to_group();
  /*  return this.selectedColumns.filter((item: ITableColumn) =>

      this.fields_to_group.includes(item.field))
      .map((column: ISelectedField) => ({ field: column.field, header: column.header }));*/
  }

  setSelectedFieldsToGroup(info: any) {
    this.fields_to_group = info;
    if (this.activeView) {
      this.activeView.set_fields_selected_to_group(info);
    }
  }

  get selectedColumnsToPin(): ISelectedField[] {
    return this.activeView?.fields_selected_to_pin;
  /*  return this.selectedColumns.filter((item: ITableColumn) =>
      this.fields_to_pin.includes(item.field))
      .map((column: ISelectedField) => ({ field: column.field, header: column.header }));*/
  }

  get selectedColumnsToSort(): ISelectedField[] {
    return this.selectedColumns.filter((item: ITableColumn) =>
      this.fields_to_sort.includes(item.field))
      .map((column: ITableColumn) => ({ field: column.field, header: column.header }));
  }

  setSelectedColumnsToSort(info:any){
    this.fields_to_sort = deepCopy(info);
  }

  get selectedJobId(): string | null {
    const savedJobId = localStorage.getItem('selectedJob') as any;
    return !!savedJobId ? JSON.parse(savedJobId) : null;
  }

  get activeView(): ViewModel {
    return this.views.find((view: ViewModel) => view.is_selected) || this.views[0];
  }

  private initSelectedView(): void {
    let savedViewId = localStorage.getItem(`viewIdFor${this.selectedJobId}`) as any;
    if (!!savedViewId) {
      savedViewId = JSON.parse(savedViewId);
    }
    this.setViewAsSelected(savedViewId || (!!this.views.length ? this.views[0].id : null));
  }

  setViewAsSelected(id: string | null): void {
    this.views?.forEach((view: ViewModel) => {
      if (view.id === id) {
        view.select();
        if (!!this.selectedJobId) {
          localStorage.setItem(`viewIdFor${this.selectedJobId}`, JSON.stringify(id));
        }
      } else {
        view.deselect();
      }
    });
  }

  updateNextPageUrl(pageUrl: string | null) {
    this.nextPageUrl = pageUrl;
  }

  private extractNextPageFromUrl(url: any): number | null {
    if (!!url) {
      const urlParts = url.match(/page=([^&]*)/);
      return !!urlParts ? urlParts[1] : null;
    }
    return null;
  }

  get requestNextPagePayload(): IRequestViewsPayload | null {
    const nextPage = this.extractNextPageFromUrl(this.nextPageUrl);
    if (!nextPage) {
      return null
    }

    let savedViewId;
    let savedJobId = JSON.parse(localStorage.getItem('selectedJob') as any);

    let payload: IRequestViewsPayload = {
      page: nextPage,
      page_size: this.resultsPerNextPage,
      include_items:
        savedJobId === 'all'
          ? CommonConstants.include_items.all
          : savedJobId === 'uncategorized'
            ? CommonConstants.include_items.uncategorized
            : CommonConstants.include_items.with_job,
      history_interval: localStorage.getItem('history') ? localStorage.getItem('history') : 0,
    };

    if (!!savedJobId) {
      savedViewId = localStorage.getItem(`viewIdFor${savedJobId}`) as any;
      if (!!savedViewId) {
        savedViewId = JSON.parse(savedViewId);
        payload = { ...payload, view_id: savedViewId }
      } else {
        if (savedJobId === 'uncategorized') {
          payload = { ...payload };
        } else if (savedJobId !== 'all') {
          payload = { ...payload, job_id: savedJobId };
        }
      }
    }

    return payload;
  }
}

import { IOptions } from 'src/app/authentication/models/authentication.model';
import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorItemData, errorPostPutItemData, receiveItemData, receivePostPutItemData, requestItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { ReceiversActionTypes } from '../actions/receivers-action-types';
import { IReceiversPageState, ReceiversPageState } from '../models/receivers-page.model';

export function receiversPageReducer(
  lastState: IReceiversPageState = new ReceiversPageState(),
  action: GenericAction<ReceiversActionTypes, any>
): IReceiversPageState {
  switch (action.type) {

    case ReceiversActionTypes.REQUEST_RECEIVERS_PAGE:
      return requestItemData<IReceiversPageState, any>(lastState, ['receiversPage']);
    case ReceiversActionTypes.RECEIVE_RECEIVERS_PAGE:
      return receiveItemData<IReceiversPageState, any>(lastState, ['receiversPage'], action.payload);
    case ReceiversActionTypes.ERROR_RECEIVE_RECEIVERS_PAGE:
      return errorItemData<IReceiversPageState, any>(lastState, ['receiversPage'], action.payload);

    case ReceiversActionTypes.REQUEST_RECEIVERS_OPTIONS:
      return requestPostPutItemData<IReceiversPageState, IOptions>(lastState, ['userOptions'], action.payload);
    case ReceiversActionTypes.RECEIVE_RECEIVERS_OPTIONS:
      return receivePostPutItemData<IReceiversPageState, IOptions>(lastState, ['userOptions'], action.payload);
    case ReceiversActionTypes.ERROR_RECEIVE_RECEIVERS_OPTIONS:
      return errorPostPutItemData<IReceiversPageState, IOptions>(lastState, ['userOptions'], action.payload);

    default:
      return lastState;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, take } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IUserManagement } from 'src/app/user-settings/models/user.model';
import { UserActions } from 'src/app/user-settings/state/actions/user.actions';
import { selectUserProfile } from 'src/app/user-settings/state/selectors/user.selector';
import { environment } from 'src/environments/environment';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';

@Component({
  selector: 'materlog-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.sass'],
})
export class ContainerComponent extends OnDestroyMixin() implements OnInit {
  tawk_id: string = environment.ENV.TAWK_ID;
  profile: any = {};

  constructor(
    public router: Router,
    private store: Store<IApplicationState>,
    private userActions: UserActions,
  ) {
    super();
  }

  ngOnInit(): void {
    this.onRequestUserProfile();
  }

  onRequestUserProfile() {
    this.userActions.requestUserProfile();
    this.store
      .pipe(
        select(selectUserProfile),
        filter((userProfile: IStoreApiItem<IUserManagement>) => !userProfile.isLoading),
        take(1)
      )
      .subscribe((userProfile: IStoreApiItem<IUserManagement>) => {
        this.profile.name = userProfile?.data?.first_name + ' ' + userProfile?.data?.last_name;
        this.profile.email = userProfile?.data?.user_email;
        localStorage.setItem('profile', JSON.stringify(this.profile));
      });
  }
}

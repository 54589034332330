import { IStoreApiItem, StoreApiItem } from "src/app/common/models/store-api-item.model";
import { ITemplate } from "../../models/template.model";


export interface ITemplatesState {
  templates: IStoreApiItem<ITemplate>;
}

export class TemplatesState implements ITemplatesState {
  templates: IStoreApiItem<ITemplate> = new StoreApiItem();
}

<div class="orders-row flex gap-2 items-center">
  <span class="orders-items">
    <div *ngFor="let image of params.value" class="orders-item-image">
      <p-image

        appendTo="body"
        class="cell-image"
        height="{{size}}"
        width="{{size}}"
        src="{{ image.image_thumbnail ? image.image_thumbnail : image.image }}"
        alt="{{ image.name }}"
        [preview]="true"

      >
        <ng-template pTemplate="indicator">
          <fa-icon class="icon" [icon]="faEye"></fa-icon>
        </ng-template>
      </p-image>

    </div>
  </span>

</div>

import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TableModule } from 'primeng/table';
import { ImageModule } from 'primeng/image';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SharedModule } from 'src/app/common/modules/shared.module';
import { SharedViewComponent } from '../components/shared-view/shared-view.component';
import { sharedViewReducer } from '../state/reducers/shared-view.reducer';
import { SharedViewEffects } from '../state/effects/shared-view.effects';
import { SharedViewService } from '../services/shared-view.service';
import { SharedCalendar } from '../components/shared-calendar/shared-calendar.component';
import { CalendarModule } from 'primeng/calendar';

import { PopupComment } from 'src/app/dashboard/components/job-table-v2/popup-comment/popup-comment.component';


@NgModule({
  declarations: [
    SharedCalendar,
    SharedViewComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    CommonModule,
    SharedModule,
    StoreModule.forFeature('sharedView', sharedViewReducer),
    EffectsModule.forFeature([SharedViewEffects]),
    TableModule,
    ImageModule,
    FontAwesomeModule,
    CalendarModule,
    PopupComment,
  ],
  exports: [SharedViewComponent],
  providers: [SharedViewService]
})
export class SharedViewModule { }

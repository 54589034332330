<div class="dependency-container">
  <div *ngIf="isEditable" #trigger class="dep-updown" (click)="togglePopup($event)">
    <img src="/assets/img/updown.svg" width="15" height="15">
  </div>
  <span class="depends-on-text">
    {{ getDependsText() }}
  </span>
  <div *ngIf="isOpen" class="dep-popup" #content>
    <div class="depends-on-toggle" >
        This item depends on
    </div>
    <input #inputText type="text"
          placeholder="Filter..."
          (input)="onFilterTextBoxChanged()"
          [(ngModel)]="filterInput"
          (keydown.enter)="keydownEnter()"
          class="dep-input"/>
    <div class="dropdown-options custom-scrollbar">
      <div class="filter-options" *ngFor="let option of filterItemOptions" (click)="setItemOption(option)">
        <div class="option-text ">
          <i *ngIf="option.depends" class="bi bi-check depends-on-check"></i>
          <div class="option-text-name">
            {{option.name}}
          </div>
          <div class="option-text-aux">
            {{option.aux}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
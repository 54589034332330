

export function deepCopy(value: any) {
  return JSON.parse(JSON.stringify(value));
}

export function deepArrayClone(value: any) {
  // @ts-ignore
  return  value.map(a => {return {...a}})
}

export function emptySort(){
  return [];
}

export function isEmptySort(x: any){
  if (!x || x.length > 1)  return false;
  return x[0].key == 'created_datetime'
         && x[0].direction == true
         && x[0].position == 0
}

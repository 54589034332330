import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorDeleteItem, errorItemData, errorPostPutItemData, receiveItemData, receivePostPutItemData, requestItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IOrderDocument } from '../../models/dashboard.model';
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { IOrderDocumentState, OrderDocumentState } from '../models/orderDocument.model';


export function orderDocumentReducer(
  lastState: IOrderDocumentState = new OrderDocumentState(),
  action: GenericAction<DashboardActionTypes, any>
): IOrderDocumentState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_ADD_ORDER_DOCUMENT:
      return requestPostPutItemData<IOrderDocumentState, IOrderDocument>(lastState, ['orderDocument'], action.payload);
    case DashboardActionTypes.RECEIVE_ADD_ORDER_DOCUMENT:
      return receivePostPutItemData<IOrderDocumentState, IOrderDocument>(lastState, ['orderDocument'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ADD_ORDER_DOCUMENT:
      return errorPostPutItemData<IOrderDocumentState, IOrderDocument>(lastState, ['orderDocument'], action.payload);

    case DashboardActionTypes.REQUEST_UPDATE_ORDER_DOCUMENT:
      return requestPostPutItemData<IOrderDocumentState, IOrderDocument>(lastState, ['orderDocument'], action.payload);
    case DashboardActionTypes.RECEIVE_UPDATE_ORDER_DOCUMENT:
      return receivePostPutItemData<IOrderDocumentState, IOrderDocument>(lastState, ['orderDocument'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_UPDATE_ORDER_DOCUMENT:
      return errorPostPutItemData<IOrderDocumentState, IOrderDocument>(lastState, ['orderDocument'], action.payload);

    case DashboardActionTypes.REQUEST_DELETE_ORDER_DOCUMENT:
      return requestPostPutItemData<IOrderDocumentState, IOrderDocument>(lastState, ['orderDocument'], action.payload);
    case DashboardActionTypes.RECEIVE_DELETE_ORDER_DOCUMENT:
      return receivePostPutItemData<IOrderDocumentState, IOrderDocument>(lastState, ['orderDocument'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_DELETE_ORDER_DOCUMENT:
      return errorDeleteItem<IOrderDocumentState, IOrderDocument>(lastState, ['orderDocument'], action.payload);

    case DashboardActionTypes.REQUEST_ORDER_DOCUMENT:
      return requestItemData<IOrderDocumentState, IOrderDocument>(lastState, ['orderDocument']);
    case DashboardActionTypes.RECEIVE_ORDER_DOCUMENT:
      return receiveItemData<IOrderDocumentState, IOrderDocument>(lastState, ['orderDocument'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ORDER_DOCUMENT:
      return errorItemData<IOrderDocumentState, IOrderDocument>(lastState, ['orderDocument'], action.payload);

    default:
      return lastState;
  }
}

import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { TableModule } from 'primeng/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SharedModule } from 'src/app/common/modules/shared.module';
import { SupplierCredentialsPageComponent } from '../components/suppliers-credentials-page/suppliers-credentials-page.component';
import { SupplierCredentialsEffects } from '../state/effects/suppliers.credentials.effects';
import { SupplierCredentialsService } from '../services/suppliers.credentials.service';
import { suppliersCredentialsPageReducer } from '../state/reducers/suppliers-credentials-page.reducer';
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import { DropDownCellRenderer } from 'src/app/dashboard/components/job-table-v2/dropdownv2/dropdownv2.component';
import { CheckboxModule } from 'primeng/checkbox';
import { SkeletonModule } from 'primeng/skeleton';

@NgModule({
  declarations: [
    // SupplierCredentialsPageComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        StoreModule.forFeature('credentials', suppliersCredentialsPageReducer),
        EffectsModule.forFeature([SupplierCredentialsEffects]),
        TableModule,
        CheckboxModule,
        FontAwesomeModule,
        FormsModule,
        InputTextModule,
        DropDownCellRenderer,
        SkeletonModule,
    ],
  exports: [
    // SupplierCredentialsPageComponent
  ],
  providers: [
    // SupplierCredentialsService
  ]
})
export class SupplierCredentialsPageModule { }

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { environment } from 'src/environments/environment';
import { ITemplates } from '../models/dashboard.model';

@Injectable()
export class TemplatesService {
  private baseUrl: string = environment.ENV.BASE_URL;
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  public getTemplates(): Observable<ITemplates | HttpErrorResponse> {
    return this.httpClient.get<ITemplates>(`${this.baseUrl}templates/active/`, this.httpHelper.options);
  }

  public addTemplate(addTemplate: any | any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<any>(`${this.baseUrl}templates/active/`, addTemplate.payload, this.httpHelper.options);
  }

  public updateTemplate(updateTemplate: any | any): Observable<any | HttpErrorResponse> {
    const payload = JSON.parse(JSON.stringify(updateTemplate.payload));

    delete payload.id;

    return this.httpClient.put<any>(`${this.baseUrl}templates/active/${updateTemplate.payload.id}/`, payload, this.httpHelper.options);
  }
}

import { IActiveJobs } from '../../models/dashboard.model';
import { IStoreApiItem, StoreApiItem } from './../../../common/models/store-api-item.model';


export interface IActiveJobsState {
  activeJobs: IStoreApiItem<IActiveJobs>;
}

export class ActiveJobsState implements IActiveJobsState {
  activeJobs: IStoreApiItem<IActiveJobs> = new StoreApiItem();
}

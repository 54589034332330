
import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs';

import { MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { TableConstants } from './../../../common/constants/table.constants';
import { IJobs, IReceiver } from './../../models/dashboard.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { selectAutocompleteReceivers, selectJobs, selectJobsList, selectOptions, selectReceivers, selectReceiversContact } from '../../state/selectors/dashboard.selector';
import { ITableColumn } from 'src/app/common/models/table.model';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { DashboardRoutes } from 'src/app/dashboard/enums/dashboard-routes.enum';
import { DropDownCellRenderer } from '../job-table-v2/dropdownv2/dropdownv2.component';
import { IReceiverContact } from './../../models/dashboard.model';
import { deepCopy } from 'src/app/common/utils/general';
import { ReceiversService } from '../../services/receivers.service';
import { DashboardService } from '../../services/dashboard.service';
import { JobsService } from '../../services/job.service';
import { UserService } from 'src/app/user-settings/services/user.service';

@Component({
  selector: 'materlog-view-jobs',
  templateUrl: './view-jobs.component.html',
  styleUrls: [
    './view-jobs.component.sass',
  ],
})
export class ViewJobsComponent extends OnDestroyMixin() implements OnInit {
  @ViewChild('materlogTable') materlogTable: Table | any;
  @Input() mailBoxVisible: boolean = false;
  @Input() commentsVisible: boolean = false;
  @Input() inputDropdownOptions: any;

  dropdownOptions: any = {};

  updateJobList: boolean = true;
  receiverContactJob: any = {};
  selectedCategory: any;
  isLoading: any = true;
  jobsList: any = undefined;
  customColumnsLayout: any = ['index', 'name', 'orders', 'receivers', 'receiver_contacts'];
  tableColumns: ITableColumn[] | any;
  selectedColumns: any;
  categories: any = ['All jobs', 'Archived'];
  allUsers: any = undefined;
  plusIcon = faPlus;
  selectedRow: any;
  receiverOptions!: any[];
  receiverDataObtained: boolean = false;
  menu: MenuItem[] = [
    { label: 'Archive job', icon: 'bi bi-archive-fill', command: () => this.archiveJob(this.selectedRow) },
    // { label: 'Do not track job', icon: 'bi bi-bell-slash-fill', command: () => this.doNotTrackJob(this.selectedRow) }
  ];
  archivedMenu: MenuItem[] = [
    { label: 'Unarchive job', icon: 'bi bi-archive-fill', command: () => this.unarchiveJob(this.selectedRow) },
  ]

  temporaryReceiverList: any;
  temporaryReceiverValue: any;
  DashboardRoutes = DashboardRoutes;

  constructor(
    public router: Router,
    private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private messageService: MessageService,
    private recevierService: ReceiversService,
    private dashboarddService: DashboardService,
    private jobsService: JobsService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    (window as any)['jj'] = this;
  }

  ngOnInit(): void {
    this.generateItems();
    this.onRequestReceivers();
    this.setSelectedCategory(this.categories[0]);
    this.onRequestAllUsers();
  }

  ngOnChanges(changes: any) {
    if (changes?.inputDropdownOptions?.currentValue) {
      this.dropdownOptions = {...changes.inputDropdownOptions.currentValue, ...this.dropdownOptions};
    }
  }

  skeletonItems: any[] = [];
  generateItems() {
    for (let i = 0; i < 30; i++) {
      const randomWidth = Math.floor(Math.random() * 25) + 5;
      this.skeletonItems.push({ width: `${randomWidth}rem` });
    }
  }

  get dataLoaded() {
    return this.receiverDataObtained && this.jobsList && this.allUsers
      && this.dropdownOptions?.receivers 
      && this.dropdownOptions?.state
      && this.dropdownOptions?.country
      && this.dropdownOptions?.weekly_checkin 
      && this.dropdownOptions?.track_job
  }

  onRequestAllUsers() {
    this.userService.getOrgUsers()
    .subscribe((users: any) => {
      this.allUsers = users
        .filter((item: any) => !item.email.endsWith('@materlog.com'))
        .map((item: any) => ({id: item.id, value: item.email}));
    });
  }

  getAllReceivers() {
    this.recevierService.getReceivers()
    .subscribe((receivers: any) => {
      if (receivers.results) {
        if (this.receiverContactJob && this.dropdownOptions?.receivers) {
          let prev_rec = this.dropdownOptions.receivers.find((item: any) => item.id == this.receiverContactJob.receiver);
          let cur_rec = receivers.results.find((item: any) => item.id == this.receiverContactJob.receiver);
          if (cur_rec && prev_rec) {
            let uniqueItem = cur_rec.contacts.find((curItem: any) => 
              !prev_rec.contacts.some((prevItem: any) => prevItem.id === curItem.id));
            if (uniqueItem) {
              const jobIndex = this.jobsList.findIndex((item: any) => item.id == this.receiverContactJob.job);
              if (jobIndex > -1) {
                this.jobsList[jobIndex].receiver_contact = uniqueItem;
                let curjob = this.jobsList[jobIndex];
                this.updateJob(curjob, true);
              }
            }
          }
        }
      }
      this.dropdownOptions = {
        ...this.inputDropdownOptions,
        receivers: receivers.results
      };
      this.receiverOptions = this.dropdownOptions['receivers'].map((item: any) => (
        { id: item.id, value: item.warehouse_name }));
      this.receiverDataObtained = true;
    });
  }

  onRequestReceivers() {
    this.getAllReceivers();
    this.store
    .pipe(
      select(selectReceivers),
      filter((receivers: IStoreApiItem<IReceiver[]>) => !receivers.isLoading),
      takeUntil(this.destroy)
    )
    .subscribe((receivers: IStoreApiItem<any>) => {
      this.getAllReceivers();
    });
  }


  setSelectedColumns(i: string) {
    this.selectedColumns = i === 'Archived' ? [...TableConstants.jobsTableColumns.slice(1)] : TableConstants.jobsTableColumns;
  }

  getAllJobs(i: string) {
    this.selectedCategory = i;
    this.setSelectedColumns(i);

    const params: any = { is_archived: i === 'Archived', page_size: 10000 };
    this.jobsService.getJobList(params)
    .subscribe((jobs: any) => {
      this.isLoading = false;
      this.jobsList = [...jobs.results];
    });
  }

  get filteredJobs() {
    return this.jobsList.filter((j: any) => j.is_archived == (this.selectedCategory === 'Archived'));
  }

  pageActive: boolean = true;
  setJobType(i: string) {
    if (this.selectedCategory == i) return;
    this.pageActive = false;
    this.cdr.detectChanges();
    this.selectedCategory = i;
    this.setSelectedColumns(i);
    this.pageActive = true;
    this.cdr.detectChanges();
  }

  setSelectedCategory(i: string): void {
    this.isLoading = true;
    this.getAllJobs(i);
    this.store
      .pipe(
        select(selectJobsList),
        filter((jobs: IStoreApiItem<any>) => !jobs.isLoading),
        takeUntil(this.destroy)
      )
      .subscribe((jobs: IStoreApiItem<any>) => {
        if (jobs.isSuccess) {
          this.isLoading = false;
          this.jobsList = JSON.parse(JSON.stringify(jobs?.data?.results));
        }
      });
  }

  navigateToAddJobWithState(receiver: boolean) {
    this.router.navigate(
      [{ outlets: { addJob: [DashboardRoutes.AddJob] } }], 
      { 
        queryParams: { 
          onlyReceiver: receiver
        },
        skipLocationChange: true
      }
    );
  }

  _setNestedProperty(obj: any, path: string, value: any): void {
    const pathParts = path.split('.');
    const lastKey = pathParts[1];
    // const lastObj = pathParts.reduce((currentObj, key) => currentObj?.[key], obj);
    const lastObj = obj[pathParts[0]];
    if (lastKey && lastObj) {
      lastObj[lastKey] = value;
    } else if (lastKey) {
      obj[pathParts[0]] = {[lastKey]: value};
    }
  }
  
  _getNestedProperty(obj: any, path: string): any {
    const pathParts = path.split('.');
    return pathParts.reduce((currentObj, key) => currentObj?.[key], obj);
  }
  
  _getDropdownDisplayValue(data: any, field: string, options: any[]): string {
    const value = this._getNestedProperty(data, field);
    const option = options.find(opt => opt.id === value);
    return option?.value === 'Not selected' ? '' : option?.value;
  }
  
  getNestedProperty(obj: any, path: string): any {
    return this._getNestedProperty(obj, path); // Utility function
  }

  setNestedProperty(obj: any, path: string, value: any): void {
    this._setNestedProperty(obj, path, value); // Utility function
  }

  getDropdownDisplayValue(data: any, field: string, options: any[]): string {
    return this._getDropdownDisplayValue(data, field, options); // Utility function
  }

  handleStatusChange(event_data: any) {
    if (event_data.colField == 'users') {
      event_data.data.watchers = event_data.event.value;
      this.updateJobList = false;
    } else if (event_data.colField == 'receiver_contacts') {
      if (event_data.event.value.value == '+ New Contact') {
        this.receiverContactJob = {'job': event_data.data.id, 'receiver': event_data.data.receiver.id};
        return;
      }
      this.onChangeReceiverContact(event_data.data, event_data.event);
      return;
    } else if (event_data.colField == 'receivers') {
      let matchingItem = this.dropdownOptions['receivers'].find((item: any) => 
        item.id === event_data.event.value.id);
      event_data.data.receiver = matchingItem;
      this.onChangeReceiver(event_data.data, event_data.data.receiver);
      return;
    }
    let data = deepCopy(event_data.data);
    if (event_data.colField.split('.').length > 1) {
      this.setNestedProperty(data, event_data.colField, event_data.event.value.id);
    } else {
      data[event_data.colField] = event_data.event.value.id;
    }
    this.onChange(data, false);
  }

  onSaveReceiverContact(col: any) {
    this.recevierService.addReceiverContact({payload: { id: col.receiver.id, ...col.receiver_contact }})
      .subscribe((receiver: any) => {
        if (receiver && receiver.id) {
          col.receiver_contact = {...receiver};
          this.updateJob(col, true);
        }
      });
  }

  onSaveReceiver(col: any) {
    col.receiver = {
      ...col.receiver,
      warehouse_name: col.receiver.warehouse_name || '',
      email: col.receiver.email || '',
      phone: col.receiver.phone || '',
      zip: (col.receiver.zip || '').slice(0, 10),
    }
    if (!col.receiver.warehouse_name) {
      return;
    }
    this.recevierService.addReceiver({ payload: col.receiver })
      .subscribe((receiver: any) => {
        col.receiver = {...receiver};
        this.updateJob(col, true);
      });
  }
  
  onChange(col: any, refreshStore?: boolean, colName: string = '') {
    if (!refreshStore) {
      refreshStore = false;
    }
    let adding_receiver = false;
    if (col.receiver && !col.receiver.id) {
      // request new receiver
      this.onSaveReceiver(col);
      adding_receiver = true;
      return;
    }
    if (col.receiver_contact && !col.receiver_contact.id && col.receiver.id) {
      // request new receiver contact
      adding_receiver = true;
      this.onSaveReceiverContact(col);
      return;
    }
    if (!adding_receiver) {
      this.updateJob(col, refreshStore, colName);
    }
  }

  updateJob(col: any, refreshStore: boolean, colName: string = '') {
    let new_watchers = [] as any;
    col.watchers.forEach((item: any) => new_watchers.push(
      {users_id: item.id || item.users_id, jobs_id: col.id}));  // may have already been changed
    col.watchers = new_watchers;
    const objToEdit: any = {
      id: col.id,
      name: col.name,
      number: col.number,
      address: col.address,
      city: col.city,
      state: col.state,
      country: col.country,
      zip: (col.zip || '').slice(0, 10),
      is_visible: col.is_visible,
      user_tracking: col.user_tracking,
      watchers: col.watchers,
      receiver: col.receiver?.id ?
        {
          id: col.receiver?.id,
          warehouse_name: col.receiver?.warehouse_name,
          phone: col.receiver?.phone,
          email: col.receiver?.email,
          address: col.receiver?.address,
          city: col.receiver?.city,
          state: col.receiver?.state,
          country: col.receiver?.country,
          zip: (col.receiver?.zip || '').slice(0, 10),
        } : null,
      receiver_contact: col.receiver_contact?.id ?
        {
          id: col.receiver_contact?.id,
          name: col.receiver_contact?.name,
          email: col.receiver_contact?.email,
          phone: col.receiver_contact?.phone,
        } : null,
      track_job: col.track_job,
      weekly_checkin: col.weekly_checkin,
    };
    this.requestEditItem(objToEdit, refreshStore, colName);
  }

  requestEditItem(objToEdit: any, refreshStore: boolean, colName: string = '') {
    this.receiverContactJob = {};
    this.jobsService.updateJob({payload: {...objToEdit}})
    .subscribe((updatedJob: any) => {
      if (this.updateJobList) {
        const index = this.jobsList.findIndex((job: any) => job.id === updatedJob.id);
        if (index > -1) {
          let oldjob = this.jobsList[index];
          updatedJob.user_tracking = oldjob.user_tracking
          this.jobsList[index] = updatedJob;
          this.jobsList = [...this.jobsList];
        }
        // this.router.navigate([{ outlets: { addJob: null } }]);
        this.cdr.detectChanges();
        if (colName == 'archive') {
          this.dashboardActions.requestActiveJobs();
        }
      }
      this.updateJobList = true;

    });
  }

  onChangeReceiver(data: any, event: any) {
    this.selectedRow = data['id'];
    const selectedJob = this.jobsList.find((elem: any) => elem?.id === data?.id);
    if (event) {
      selectedJob.receiver = event;
      selectedJob.receiver_contact = null;
    } else {
      selectedJob.receiver = null;
      selectedJob.receiver_contact = null;
    }

    this.onChange(data, true);
  }

  getReceivers(data: any) {
    this.selectedRow = data['id'];
    this.onRequestReceivers();
  }

  onClearReceiver(data: any, event: any) {
    this.selectedRow = data['id'];
    const selectedJob = this.jobsList.find((elem: any) => elem?.id === data?.id);

    selectedJob.receiver = null;
    selectedJob.receiver_contact = null;

    this.onChange(data, true);
  }

  getReceiverContactsOptions(id: string) {
    let found_rec = this.dropdownOptions['receivers']?.find((elem: any) => elem.id == id)?.contacts;
    return found_rec;
  }

  checkReceiverSuggestions(rowId: any) {
    return rowId === this.selectedRow;
  }

  setTemporaryReceiverValue(event: any) {
    this.temporaryReceiverValue = event;
  }

  onChangeReceiverContact(data: any, event: any) {
    const selectedJob = this.jobsList.find((elem: any) => elem?.id === data?.id);
    const selectedReceiverContacts = this.getReceiverContactsOptions(data['receiver'].id);
    if (event.value) {
      selectedJob.receiver_contact = selectedReceiverContacts?.find((elem: any) => elem.id == event.value.id);
    } else {
      selectedJob.receiver_contact = null;
    }
    this.onChange(data, true);
  }

  onColResize(event: any, tableColumns: any): void {
    const colIndex: number = event.element.cellIndex;
    const colWidth: number = event.element.clientWidth;
  }

  archiveJob(selectedRow: any) {
    selectedRow.is_archived = true;
    const objToEdit = JSON.parse(JSON.stringify(selectedRow));

    objToEdit.is_archived = true;
    objToEdit.watchers = [];

    if (!objToEdit.receiver?.id) {
      objToEdit.receiver = null;
    }

    if (!objToEdit.receiver_contact?.id) {
      objToEdit.receiver_contact = null;
    }
    this.jobsService.updateJob({payload: {...objToEdit}})
    .subscribe((job: any) => {
      this.dashboardActions.requestActiveJobs();
    });
  }

  unarchiveJob(selectedRow: any) {
    selectedRow.is_archived = false;
    let objToEdit = JSON.parse(JSON.stringify(selectedRow));
    objToEdit.is_archived = false;

    if (!objToEdit.receiver?.id) {
      objToEdit.receiver = null;
    }
    if (!objToEdit.receiver_contact?.id) {
      objToEdit.receiver_contact = null;
    }
    this.jobsService.updateJob({payload: {...objToEdit}})
    .subscribe((job: any) => {
      this.dashboardActions.requestActiveJobs();
    });
  }
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonConstants } from 'src/app/common/constants/common.constants';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

        console.error('SERVER ERROR => Error from error interceptor:', error);

        switch (error.error.code) {
          case 'user_not_found':
            localStorage.clear();
            localStorage.setItem('loginError', `The username/password combination doesn't exist . If you still encounter issues, contact support@materlog.com .`);
            window.location.reload();
            break;
          case 'user_inactive':
            localStorage.clear();
            localStorage.setItem('loginError', 'User inactive');
            window.location.reload();
            break;
          case 'token_not_valid':
            localStorage.clear();
            localStorage.setItem('loginError', 'Your session has expired. Please log back in.');
            window.location.reload();
            break;
        }

        if (error.status === 413) {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Files are too large. Please try again.',
            key: 'error',
          });
        }

        if (error.status === 400) {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: CommonConstants.defaultError + '(Bad request)',
            key: 'error',
          });
        }

        return throwError(() => new HttpErrorResponse(error as any));
      })
    );
  }
}

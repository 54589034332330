import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { TableModule } from 'primeng/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SharedModule } from 'src/app/common/modules/shared.module';
import { ReceiversPageComponent } from '../components/receivers-page/receivers-page.component';
import { ReceiversEffects } from '../state/effects/receivers.effects';
import { ReceiversService } from '../services/receivers.service';
import { receiversPageReducer } from '../state/reducers/receivers-page.reducer';

@NgModule({
  declarations: [
    ReceiversPageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('receivers', receiversPageReducer),
    EffectsModule.forFeature([ReceiversEffects]),
    TableModule,
    FontAwesomeModule,
  ],
  exports: [ReceiversPageComponent],
  providers: [ReceiversService]
})
export class ReceiversPageModule { }

import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { FormControl, FormGroup, Validators } from '@angular/forms';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IApplicationState } from '../../../common/state/models/app.state.model';
import { CommonConstants } from '../../../common/constants/common.constants';
import { AuthenticationActions } from '../../state/actions/authentication.actions';
import { selectLogin } from '../../state/selectors/authentication.selector';
import { ILogin, ILoginResponse } from '../../models/authentication.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { PermissionsService } from 'src/app/root/services/permissions.service';

@Component({
  selector: 'materlog-sign-in',
  templateUrl: './sign-in.component.html',
})
export class SignInComponent extends OnDestroyMixin() implements OnInit {
  permissionService: PermissionsService;
  isSubmitted: boolean = false;
  errorMessage: string | undefined = '';

  signInForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required]),
  });

  constructor(
    public router: Router,
    private injector: Injector,
    private store: Store<IApplicationState>,
    private authenticationActions: AuthenticationActions,
  ) {
    super();
    this.permissionService = this.injector.get<PermissionsService>(PermissionsService);
  }

  ngOnInit(): void {
    if (this.permissionService.isLoggedIn()) {
      this.router.navigate(['']);
    }

    this.signInForm.valueChanges.subscribe(() => {
      this.errorMessage = undefined;
    });

    if (localStorage.getItem('loginError')) {
      this.errorMessage = localStorage.getItem('loginError')?.toString();
      localStorage.clear();
    }
  }

  onSubmit() {
    this.signInForm.markAllAsTouched();
    this.isSubmitted = true;
    this.errorMessage = undefined;

    if (this.signInForm.valid) {
      const objToUpdate: ILogin = this.signInForm.value;

      this.authenticationActions.requestLogin({ ...objToUpdate });
      this.store.pipe(
        select(selectLogin),
        filter((login: IStoreApiItem<ILoginResponse>) => !login.isLoading),
        take(1)
      ).subscribe((authentication: IStoreApiItem<ILoginResponse>) => {
        if (authentication.isSuccess) {
          localStorage.setItem(CommonConstants.AUTH_TOKEN, authentication.data.access);
          // this.router.navigate(['']).then(() => window.location.reload());
          this.router.navigate(['']).then(() => {});
        } else {
          if (authentication.errors?.error?.detail === 'No active account found with the given credentials') {
            this.errorMessage = `The username/password combination't exist. Please try again or click 'Forgot Password'.`;
          } else {
            this.errorMessage = 'Something went wrong. Please try again later. If this issue persists, contact support@materlog.com.';
          }
        }
      });
    }
  }

  onNavigateToForgotPassword() {
    this.router.navigate(['forgot-password']);
  }

  onNavigateToSignUp() {
    this.router.navigate(['signup']);
  }
}

<div class="preferences">
  <div class="preferences__header">
    <div></div>
    <p>{{ checkboxList ? checkboxList[0].value : '' }}</p>
    <p>{{ checkboxList ? checkboxList[1].value : '' }}</p>
    <p>{{ checkboxList ? checkboxList[2].value : '' }}</p>
  </div>
  <p-divider></p-divider>
  <!-- New jobs row  -->
  <div class="preferences__row">
    <div class="preferences__row-text">
      <p class="preferences__row-text-title">New jobs</p>
      <p>Receive a notification when a new job is added</p>
    </div>
    <div class="preferences-checkbox" *ngFor="let checkbox of checkboxList">
      <p-checkbox
        [value]="checkbox.id"
        [(ngModel)]="notifications.new_jobs_notification"
        name="jobs"
        (onChange)="updateNotification('new_jobs_notification', notifications.new_jobs_notification)"
        pTooltip="{{ notificationTooltip(checkbox.id) }}"
        tooltipPosition="top"
      ></p-checkbox>
    </div>
  </div>
  <p-divider></p-divider>
  <!-- New order row  -->
  <div class="preferences__row">
    <div class="preferences__row-text">
      <p class="preferences__row-text-title">New order</p>
      <p>Receive a notification when a PO or order document is uploaded successfully</p>
    </div>
    <div class="preferences-checkbox" *ngFor="let checkbox of checkboxList">
      <p-checkbox
        [value]="checkbox.id"
        [(ngModel)]="notifications.new_order_notification"
        name="order"
        (onChange)="updateNotification('new_order_notification', notifications.new_order_notification)"
        pTooltip="{{ notificationTooltip(checkbox.id) }}"
        tooltipPosition="top"
      ></p-checkbox>
    </div>
  </div>
  <p-divider></p-divider>
  <!-- Item status change  -->
  <div class="preferences__row">
    <div class="preferences__row-text">
      <p class="preferences__row-text-title">Item status change</p>
      <p>Receive a notification when an item status changes</p>
    </div>
  </div>
  <!-- Item added to system  -->
  <div class="preferences__row">
    <div class="preferences__row-text indent">
      <p class="preferences__row-text-title">Item added to system</p>
    </div>
    <div class="preferences-checkbox" *ngFor="let checkbox of checkboxList">
      <p-checkbox
        [value]="checkbox.id"
        [(ngModel)]="notifications.item_added_notification"
        name="itemAdded"
        (onChange)="updateNotification('item_added_notification', notifications.item_added_notification)"
        pTooltip="{{ notificationTooltip(checkbox.id) }}"
        tooltipPosition="top"
      ></p-checkbox>
    </div>
  </div>
  <!-- Item status set to Not ordered -->
  <div class="preferences__row">
    <div class="preferences__row-text indent">
      <p class="preferences__row-text-title">Item status set to Not Ordered</p>
    </div>
    <div class="preferences-checkbox" *ngFor="let checkbox of checkboxList">
      <p-checkbox
        [value]="checkbox.id"
        [(ngModel)]="notifications.item_not_ordered_notification"
        name="itemNotOrdered"
        (onChange)="updateNotification('item_not_ordered_notification', notifications.item_not_ordered_notification)"
        pTooltip="{{ notificationTooltip(checkbox.id) }}"
        tooltipPosition="top"
      ></p-checkbox>
    </div>
  </div>
  <!-- Item status set to Ordered -->
  <div class="preferences__row">
    <div class="preferences__row-text indent">
      <p class="preferences__row-text-title">Item status set to Ordered</p>
    </div>
    <div class="preferences-checkbox" *ngFor="let checkbox of checkboxList">
      <p-checkbox
        [value]="checkbox.id"
        [(ngModel)]="notifications.item_ordered_notification"
        name="itemOrdered"
        (onChange)="updateNotification('item_ordered_notification', notifications.item_ordered_notification)"
        pTooltip="{{ notificationTooltip(checkbox.id) }}"
        tooltipPosition="top"
      ></p-checkbox>
    </div>
  </div>
  <!-- Item status set to In Stock -->
  <div class="preferences__row">
    <div class="preferences__row-text indent">
      <p class="preferences__row-text-title">Item status set to In Stock</p>
    </div>
    <div class="preferences-checkbox" *ngFor="let checkbox of checkboxList">
      <p-checkbox
        [value]="checkbox.id"
        [(ngModel)]="notifications.item_in_stock_notification"
        name="itemStock"
        (onChange)="updateNotification('item_in_stock_notification', notifications.item_in_stock_notification)"
        pTooltip="{{ notificationTooltip(checkbox.id) }}"
        tooltipPosition="top"
      ></p-checkbox>
    </div>
  </div>
  <!-- Item status set to Shipped -->
  <div class="preferences__row">
    <div class="preferences__row-text indent">
      <p class="preferences__row-text-title">Item status set to Shipped</p>
    </div>
    <div class="preferences-checkbox" *ngFor="let checkbox of checkboxList">
      <p-checkbox
        [value]="checkbox.id"
        [(ngModel)]="notifications.item_shipped_notification"
        name="itemShipped"
        (onChange)="updateNotification('item_shipped_notification', notifications.item_shipped_notification)"
        pTooltip="{{ notificationTooltip(checkbox.id) }}"
        tooltipPosition="top"
      ></p-checkbox>
    </div>
  </div>
  <!-- Item status set to Delivered -->
  <div class="preferences__row">
    <div class="preferences__row-text indent">
      <p class="preferences__row-text-title">Item status set to Delivered</p>
    </div>
    <div class="preferences-checkbox" *ngFor="let checkbox of checkboxList">
      <p-checkbox
        [value]="checkbox.id"
        [(ngModel)]="notifications.item_delivered_notification"
        name="itemDelivered"
        (onChange)="updateNotification('item_delivered_notification', notifications.item_delivered_notification)"
        pTooltip="{{ notificationTooltip(checkbox.id) }}"
        tooltipPosition="top"
      ></p-checkbox>
    </div>
  </div>
  <p-divider></p-divider>
  <!-- Item date change -->
  <div class="preferences__row">
    <div class="preferences__row-text">
      <p class="preferences__row-text-title">Item date change</p>
      <p>Receive a notification when an item’s date changes</p>
    </div>
  </div>
  <!-- In stock date  -->
  <div class="preferences__row">
    <div class="preferences__row-text indent">
      <p class="preferences__row-text-title">In stock date</p>
      <div class="preferences__row-text-radios">
        <ng-container *ngFor="let radio of radiosList">
          <p-radioButton
            [(ngModel)]="notifications.in_stock_date_type"
            [value]="radio.id"
            name="in_stock_date_type"
            [label]="radio.value.toLowerCase()"
            (onClick)="updateNotification('in_stock_date_type', notifications.in_stock_date_type)"
          ></p-radioButton>
        </ng-container>
      </div>
    </div>
    <div class="preferences-checkbox-top" *ngFor="let checkbox of checkboxList">
      <p-checkbox
        [value]="checkbox.id"
        [(ngModel)]="notifications.in_stock_date_notification"
        name="stockDate"
        (onChange)="updateNotification('in_stock_date_notification', notifications.in_stock_date_notification)"
        pTooltip="{{ notificationTooltip(checkbox.id) }}"
        tooltipPosition="top"
      ></p-checkbox>
    </div>
  </div>
  <!-- Ship date   -->
  <div class="preferences__row">
    <div class="preferences__row-text indent">
      <p class="preferences__row-text-title">Ship date</p>
      <div class="preferences__row-text-radios">
        <ng-container *ngFor="let radio of radiosList">
          <p-radioButton
            [(ngModel)]="notifications.ship_date_type"
            [value]="radio.id"
            name="ship_date_type"
            [label]="radio.value.toLowerCase()"
            (onClick)="updateNotification('ship_date_type', notifications.ship_date_type)"
          ></p-radioButton>
        </ng-container>
      </div>
    </div>
    <div class="preferences-checkbox-top" *ngFor="let checkbox of checkboxList">
      <p-checkbox
        [value]="checkbox.id"
        [(ngModel)]="notifications.ship_date_notification"
        name="shipDate"
        (onChange)="updateNotification('ship_date_notification', notifications.ship_date_notification)"
        pTooltip="{{ notificationTooltip(checkbox.id) }}"
        tooltipPosition="top"
      ></p-checkbox>
    </div>
  </div>
  <!-- Delivery date   -->
  <div class="preferences__row">
    <div class="preferences__row-text indent">
      <p class="preferences__row-text-title">Delivery date</p>
      <div class="preferences__row-text-radios">
        <ng-container *ngFor="let radio of radiosList">
          <p-radioButton
            [(ngModel)]="notifications.delivery_date_type"
            [value]="radio.id"
            name="delivery_date_type"
            [label]="radio.value.toLowerCase()"
            (onClick)="updateNotification('delivery_date_type', notifications.delivery_date_type)"
          ></p-radioButton>
        </ng-container>
      </div>
    </div>
    <div class="preferences-checkbox-top" *ngFor="let checkbox of checkboxList">
      <p-checkbox
        [value]="checkbox.id"
        [(ngModel)]="notifications.delivery_date_notification"
        name="deliveryDate"
        (onChange)="updateNotification('delivery_date_notification', notifications.delivery_date_notification)"
        pTooltip="{{ notificationTooltip(checkbox.id) }}"
        tooltipPosition="top"
      ></p-checkbox>
    </div>
  </div>
  <p-divider></p-divider>
  <!-- Job fully delivered -->
  <div class="preferences__row">
    <div class="preferences__row-text">
      <p class="preferences__row-text-title">Job fully delivered</p>
      <p>Receive a notification when all items in a job are delivered or ready for pickup</p>
    </div>
    <div class="preferences-checkbox" *ngFor="let checkbox of checkboxList">
      <p-checkbox
        [value]="checkbox.id"
        [(ngModel)]="notifications.job_delivered_notification"
        name="jobDelivered"
        (onChange)="updateNotification('job_delivered_notification', notifications.job_delivered_notification)"
        pTooltip="{{ notificationTooltip(checkbox.id) }}"
        tooltipPosition="top"
      ></p-checkbox>
    </div>
  </div>
</div>

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SelectedViewModel } from '../../models/selected-view.model';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { ElementRef, HostListener } from '@angular/core';
import { MailBoxCommunicationService } from '../../services/mailbox_comm.service';
import { selectComments, selectViews} from '../../state/selectors/dashboard.selector';
import { selectActiveJobs } from '../../state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { CommentsService } from '../../services/comment.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { MentionModule } from 'angular-mentions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { deepCopy } from 'src/app/common/utils/general';
import { DashboardJobView } from '../../services/dashboard_job_view.service';


@Component({
  selector: 'comment-pane',
  templateUrl: './comment-pane.component.html',
  styleUrls: ['./comment-pane.component.sass'],
})
export class CommentPaneComponent implements OnInit {
  makeVisible: boolean = false;
  @Input() selectedView?: SelectedViewModel;
  @Input() orgUserEmails!: any;
  @Input() cardView: boolean = false;
  @Input() cardHeight: number = 160;
  @Input() card!: any;

  @ViewChild('filterDiv') filterDiv!: ElementRef;
  @ViewChild('commentContainer') commentContainer!: ElementRef;
  @ViewChild('jobDiv') jobDiv!: ElementRef;
  @ViewChild('tooltipRefOptions') tooltipRefOptions!: ElementRef;
  @ViewChild('editCommentBox') editCommentBox!: ElementRef;

  commentText: string = '';
  activeThread: string | null = null;
  currentComment!: HTMLElement;
  currentEditingComment!: HTMLElement;
  editingText: string = '';
  numbersTest = Array.from({ length: 10 }, (_, i) => i + 1);
  allThreads: any = [];
  placeholder_reply = 'Reply or add others with @...';
  placeholder_resolved = 'Commenting will reopen this thread...';
  filterOptions = ['All', 'For you', 'Open', 'Resolved'];
  activeFilter = 'All';
  all_jobs = {id: undefined, name: 'All jobs'};
  activeJob = this.all_jobs;
  allJobs!: [];
  filterDropdownVisible: boolean = false;
  jobDropdownVisible: boolean = false;
  userEmail: string = '';
  userName: string = '';
  userId: string = '';
  taggedUsers: any = [];
  observer!: any;
  beenMutated: boolean = false;

  constructor(
    private mailCommunicationService: MailBoxCommunicationService,
    private store: Store<IApplicationState>,
    private commentsService: CommentsService,
    private dashboardActions: DashboardActions,
    private elRef: ElementRef,
    private sanitizer: DomSanitizer,
    private djv: DashboardJobView,
    ) {}

  ngOnInit() {
    (window as any)['comment'] = this;
    if (!this.cardView) {
      this.mailCommunicationService.commentPaneVisibility$.subscribe((isVisible) => {
        this.makeVisible = isVisible;
        if (!isVisible) return;
        this.setProfile();
        this.loadComments();
        // if (isVisible) this.setWindowHeightWithTimeout();
      });
    } else {
      this.setProfile();
      this.loadComments();
    }

    this.store.pipe(select(selectComments)).subscribe((comment: any) => {
      if ((!this.makeVisible && !this.cardView) || !comment?.comments?.data) return;
      if (this.cardView && comment.comments.data.order != this.card.items[0].order_id) {
        return;
      }
      this.addCommentToThread({...comment});
      }
    );

    this.store.select(selectActiveJobs as any).subscribe((activeJobs: any) => {
      if (activeJobs?.data) {
        this.allJobs = [this.all_jobs as any, ...activeJobs.data] as any;
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let vals = ['.comment-display'];
      vals.forEach((v: any) => {
        let jdisplay = document.querySelector(v);
        if (jdisplay) {
          jdisplay.addEventListener('mouseenter', function() {
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
          });
          jdisplay.addEventListener('mouseleave', function() {
            (jdisplay as any).classList.remove('custom-scrollbar');
            (jdisplay as any).classList.add('custom-scrollbar-close');
          });
        }
      })
    }, 500);
  }

  makeEditableDivs() {
    const editableDivs = this.commentContainer.nativeElement.querySelectorAll('.single-comment-textarea[contenteditable="true"]');
    
    editableDivs.forEach((div: Element) => {
      // Hide placeholder on focus
      div.addEventListener('focus', () => {
        const element = div as HTMLElement; // Type assertion to HTMLElement
        if (element.textContent?.trim() === '') {
          element.dataset.placeholder = '';
        }
      });
      
      // Show placeholder on blur if empty
      div.addEventListener('blur', () => {
        const element = div as HTMLElement; // Type assertion to HTMLElement
        if (element.textContent?.trim() === '') {
          element.dataset.placeholder = 'Reply...';
        }
      });
    });
  };

  setProfile() {
    if (localStorage.getItem('profile')) {
      let profile = JSON.parse(localStorage.getItem('profile') as any);
      this.userEmail = profile ? profile.email: '';
      this.userName = profile ? profile.name: '';
      this.userId = profile ? profile.user_id: '';
    }
  }

  closeToggle() {
    this.djv.close_comments();
  }

  deleteComment(thread: any, comment: any) {
    comment.show_options = false;
    this.commentsService.deleteComment({payload: comment.id})
    .subscribe({
      next: (response) => {
        thread.comments = thread.comments.filter((c: any) => c.id != comment.id);
        if (!thread.comments.length) {
          this.allThreads = this.allThreads.filter((t: any) => t.id != thread.id) as any;
        }
        this.djv.request_metadata();
      },
      error: (errorMessage) => {
      }
    });
  }

  editComment(comment: any) {
    comment.editing = true;
    comment.show_options = false;
    setTimeout(() => {
      this.editCommentBox.nativeElement.innerText = comment.description;
    }, 10);
  }

  onCommentDoneEditing(thread: any, comment: any) {
    comment.editing = false;
    let original_comment = deepCopy(comment.description);
    comment.description = this.editCommentBox.nativeElement.innerText;
    let final_users = this.getTaggedUsers(this.editingText);
    let payload = {
      'description': this.editingText,
      'thread_id': thread.id,
      'tagged': final_users,
    };
    this.setThreadStatus(thread, 0);
    this.editingText = '';
    this.commentsService.editComment({id: comment.id, payload: payload})
    .subscribe({
      next: (response) => {},
      error: (errorMessage) => {
        comment.description = original_comment;
      }
    });
  }

  onCancelEditing(comment: any) {
    comment.editing = false;
    this.editingText = '';
  }

  updateEditText(event: Event): void {
    const target = event.target as HTMLElement;
    this.currentEditingComment = target;
    this.editingText = this.currentEditingComment.innerText;
  }

  showMoreOptions(event: any, comment: any) {
    event.preventDefault();
    event.stopPropagation();
    comment.show_options = true;
  }

  changeScrollBarDynamically() {
    const targetNode = document.getElementById('single-comment-input-id');
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node: any) => {
          if (node instanceof HTMLElement) {
            const mentionMenu = node.querySelector('.mention-menu');
            if (mentionMenu) {
              mentionMenu.classList.add('custom-scrollbar');
              this.beenMutated = true;
            }
          }
        });
      });
      if (this.beenMutated) this.observer.disconnect();
    });
    if (targetNode) {
      this.observer.observe(targetNode, {
        childList: true,
        subtree: true
      });
    }
  }

  getHeaderHeight() {
    return (document.querySelector('materlog-container') as HTMLElement).offsetHeight;
  }

  calculateMaxHeight() {
    let height = this.getHeaderHeight();
    let targetElement = document.querySelector('.comment-container') as HTMLElement;
    if (targetElement) {
      const viewportHeight = window.innerHeight;
      const maxHeight = viewportHeight - height - 40; // buffer
      targetElement.style.maxHeight = maxHeight + 'px';
    }
  }

  setWindowHeightWithTimeout() {
    setTimeout(() => {
      this.calculateMaxHeight();
    }, 0);
  }

  resetCommentBox(thread: any) {
    this.currentComment.innerText = '';
    this.updateCommentBox(thread);
  }

  updateCommentBox(thread: any) {
    this.commentText = this.currentComment.innerText || '';
    this.currentComment.dataset.placeholder = this.commentText ? '': this.getPlaceholderText(thread);
  }

  getPlaceholderText(thread: any) {
    return thread.options == 1 ? this.placeholder_resolved: this.placeholder_reply;
  }

  updateCommentText(event: Event, thread: any): void {
    const target = event.target as HTMLElement;
    this.currentComment = target;
    this.updateCommentBox(thread);
    this.activeThread = thread.id;
  }

  getTaggedUsers(textarea: any) {
    let final_users: any = [];
    this.taggedUsers.forEach((u: string) => {
      if (textarea.includes(`@${u}`)) {
        final_users.push(u);
      }
    });
    return final_users;    
  }

  onComment(thread: any) {
    let final_users = this.getTaggedUsers(this.commentText);
    let payload: any = {
      'description': this.commentText,
      'thread_id': thread.id,
      'tagged': final_users,
    };
    if (thread.item) payload.item_id = thread.item;
    if (thread.order) payload.order_id = thread.order;
    let fake_tagged = final_users.map((u: any) => ({email: u}));
    let profile = JSON.parse(localStorage.getItem('profile') as any);
    let fake = {
      comment: {
        created_datetime: new Date(),
        username: profile.name || '',
        user: profile.user_id || '',
        description: this.commentText,
      },
      tagged_users: fake_tagged.concat(thread.tagged_users),
      is_temp: true,
      id: thread.id,
    }
    this.commentText = '';
    this.resetCommentBox(thread);
    this.setThreadStatus(thread, 0);
    this.addCommentToThread({comments: {data: fake}});
    this.dashboardActions.requestAddComment(payload);
    this.djv.request_metadata();
  }

  addCommentToThread(thread: any) {
    if (!thread?.comments?.data?.id) return;
    let new_thread = thread.comments.data;
    let found_thread = this.allThreads.find((t: any) => t.id == new_thread.id) as any;
    if (!found_thread) {
      new_thread = {...new_thread};
      let new_comments = new_thread.comments.map((comment: any) => ({...comment}));
      new_thread.comments = new_comments;
      (this.allThreads as any).unshift({...new_thread});
      return;
    }
    if (new_thread.is_temp) {
      let new_comments = [...found_thread.comments, new_thread.comment];
      new_comments = new_comments.map((comment: any) => ({...comment}));
      found_thread.comments = new_comments;
      found_thread.tagged_users = [...new_thread.tagged_users];
    } else {
      let new_comments = new_thread.comments.map((comment: any) => ({...comment}));
      found_thread.comments = new_comments;
      found_thread.tagged_users = [...new_thread.tagged_users];
    }
  }
  
  onCancel(thread: any) {
    this.activeThread = null;
    this.commentText = '';
    if (this.currentComment) {
      this.resetCommentBox(thread);
    }
  }

  formatHeader(thread: any) {
    let maxl = 50;
    let cell = ''
    cell = thread.cell ? thread.cell: cell;
    if (thread.field) {
      cell = cell ? `${thread.field}, ${cell}`: thread.field;
    }
    if (cell.length > maxl) cell = cell.substring(0, maxl) + '...';
    return cell;
  }

  formatHeaderBelow(thread: any) {
    let maxl = 65;
    let cell = `${thread.job_name}, ${thread.supplier_name}`;
    cell = thread.item_name ? `${cell}, ${thread.item_name}`: cell;
    if (cell.length > maxl) cell = cell.substring(0, maxl) + "...";
    return cell;
  }

  formatUser(comment: any) {
    return comment.username;
  }

  formatTimestamp(comment: any) {
    const date = new Date(comment.created_datetime);
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() < 12 ? 'AM' : 'PM';
    const month = date.toLocaleString('en-US', { month: 'short' });
    const day = date.getDate();
    return `${hours}:${minutes} ${ampm}, ${month} ${day}`;
  }

  formatText(thread: any, comment: any) {
    let cur_emails = [...new Set(thread.tagged_users.map((u: any) => u.email))];
    let safeHtml = comment.description;
    cur_emails.forEach((e: any) => {
      safeHtml = safeHtml.replace(e, `<a href="mailto:${e}" style="color: #3860ed;">${e}</a>`);
    });
    return this.sanitizer.bypassSecurityTrustHtml(safeHtml);
  }

  isCurrentThread(thread: any) {
    return this.commentText && this.activeThread == thread.id;
  }

  setThreadStatus(thread: any, status: number) {
    let found = this.allThreads.find((t: any) => t.id == thread.id) as any;
    if (found) {
      found.options = status;
    }
  }

  onResolveThread(thread: any) {
    let payload = {
      'thread_id': thread.id,
      'resolve': true
    }
    this.setThreadStatus(thread, 1);
    this.dashboardActions.requestAddComment(payload);
  }

  isResolved(thread: any) {
    return thread.options === 1;
  }

  toggleFilterDropdown() {
    this.filterDropdownVisible = !this.filterDropdownVisible;
  }

  toggleJobDropdown() {
    this.jobDropdownVisible = !this.jobDropdownVisible;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.filterDiv && !this.filterDiv.nativeElement.contains(event.target)) {
      this.filterDropdownVisible = false;
    }
    if (this.jobDiv && !this.jobDiv.nativeElement.contains(event.target)) {
      this.jobDropdownVisible = false;
    }
    if (this.allThreads) {
      this.allThreads.forEach((t: any) => {
        t.comments.forEach((c: any) => {
          c.show_options = false;
        })
      })
    }
  }

  setActiveFilter(option: string) {
    this.activeFilter = option;
    this.filterDropdownVisible = false;
  }

  setActiveJob(option: any) {
    this.activeJob = option;
    this.jobDropdownVisible = false;
  }

  get curDisplay() {
    return this.makeVisible || this.cardView ? 'block': 'none';
  }

  get curHeight() {
    let vh = window.innerHeight;
    return this.cardView ? `${this.cardHeight - 80}px` : `${vh - 10}px`;
  }

  get filteredThreads() {
    if (this.cardView) {
      if (!this.allThreads) return [];
    } else {
      if (!this.makeVisible || !this.allThreads) return [];
    }
    let out: any = [];
    let addThread = (t: any): boolean =>  this.activeJob.id == null || this.activeJob.id == t.job_id;
    this.allThreads.forEach((t: any) => {
      if (!addThread(t)) return;
      if (this.activeFilter == 'All') out.push(t);
      else if (this.activeFilter == 'Open' && t.options == 0) out.push(t);
      else if (this.activeFilter == 'Resolved' && t.options == 1) out.push(t);
      else if (this.activeFilter == 'For you' && t.tagged_users.some((t: any) => t.email == this.userEmail)) out.push(t);
    });
    return out;
  }

  get activeJobs() {
    if (!this.makeVisible || !this.allJobs) return [];
    return this.allJobs;
  }

  isJobSelected(job: any) {
    return job.name === this.activeJob.name;
  }

  getJobName(job: any) {
    return job.name;
  }

  toggleJobView() {
    this.jobDropdownVisible = !this.jobDropdownVisible;
  }

  onMentionSelected(event: any) {
    if (event && event.label) {
      this.taggedUsers.push(event.label);
    }
  }

  loadComments() {
    this.commentsService.getComments({payload: {}})
      .subscribe((results: any) => {
        if (this.cardView) {
          let iids = this.card.items.map((i: any) => i.id);
          let allThreads = [...deepCopy(results)] as any;
          this.allThreads = allThreads.filter((t: any) => iids.includes(t.item) || t.order == this.card.items[0].order_id);
        } else {
          this.allThreads = [...deepCopy(results)] as any;
        }
        setTimeout(() => {
          this.changeScrollBarDynamically();
          this.makeEditableDivs();
        }, 100);
      });
  }
}
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import {ISuppliers, ISupplierContacts, ISupplierCredentials} from 'src/app/dashboard/models/dashboard.model';
import { DashboardActionTypes } from '../dashboard-action-types';

export class RequestSupplierCredentials implements Action {
  readonly type: DashboardActionTypes.REQUEST_SUPPLIER_CREDENTIALS = DashboardActionTypes.REQUEST_SUPPLIER_CREDENTIALS;

  constructor(
    public payload: string) { }
}

export class ErrorReceiveSupplierCredentials implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_SUPPLIER_CREDENTIALS = DashboardActionTypes.ERROR_RECEIVE_SUPPLIER_CREDENTIALS;

  constructor(
    public payload: HttpErrorResponse) { }
}


export class RequestAddSupplierCredentials implements Action {
  readonly type: DashboardActionTypes.REQUEST_ADD_SUPPLIER_CREDENTIALS = DashboardActionTypes.REQUEST_ADD_SUPPLIER_CREDENTIALS;

  constructor(
    public payload: ISupplierCredentials) { }
}

export class ResetSupplierCredentialsCRUDState implements Action {
  readonly type: DashboardActionTypes.RESET_CRUD_SUPPLIER_CREDENTIALS_STATE = DashboardActionTypes.RESET_CRUD_SUPPLIER_CREDENTIALS_STATE;
}

import {ISupplierContacts, ISupplierCredentials, ISuppliers} from '../../models/dashboard.model';
import { IStoreApiItem, StoreApiItem } from './../../../common/models/store-api-item.model';


export interface ISupplierCredentialsState {
  credentials: IStoreApiItem<ISupplierCredentials>;
}

export class SupplierCredentialsState implements ISupplierCredentialsState {
  credentials: IStoreApiItem<ISupplierCredentials> = new StoreApiItem();
}



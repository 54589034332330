<p-calendar
  appendTo="body"
  placeholder="YYYY-MM-DD"
  dateFormat="yy/mm/yy"
  dataType="date"
  panelStyleClass="ag-custom-component-popup"
  [showClear]="true"
  [(ngModel)]="params.value"
  (onSelect)="onChangeDate()"
  (onClear)="onChangeDate()"
>
</p-calendar>

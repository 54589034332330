import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { SelectedViewModel } from '../../models/selected-view.model';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { DashboardRoutes } from '../../enums/dashboard-routes.enum';
import { ElementRef, HostListener } from '@angular/core';
import { MailBoxCommunicationService } from '../../services/mailbox_comm.service';
import { selectComments, selectViews} from '../../state/selectors/dashboard.selector';
import { selectActiveJobs } from '../../state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IUserManagement } from 'src/app/user-settings/models/user.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { CommentsService } from '../../services/comment.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { MentionModule } from 'angular-mentions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { deepCopy } from 'src/app/common/utils/general';
import { ActiveJobModel } from '../../models/active-job.model';
import { selectUserProfile } from 'src/app/user-settings/state/selectors/user.selector';
import { UserActions } from 'src/app/user-settings/state/actions/user.actions';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { PermissionsService } from 'src/app/root/services/permissions.service';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { OrderService } from '../../services/order.service';
import { initData } from '../mail-view/utils';
import { JobsService } from '../../services/job.service';
import { DashboardService } from '../../services/dashboard.service';
import { CommonConstants } from 'src/app/common/constants/common.constants';



@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit {
  @Input() selectedView?: SelectedViewModel;
  @Input() dropdownOptions?: any;
  @Input() activeJobsList!: ActiveJobModel[];
  @Input() allMailData: any;
  
  @ViewChild('noteOrder') noteOrder!: ElementRef;
  @ViewChild('noteSupplier') noteSupplier!: ElementRef;

  skeletonItems: any[] = [];
  sidePanelOpen: boolean = true;
  selectedId: any = 0;
  notifications: any = [];

  todayDate: any = undefined;

  notifTypeSupplier = 0;
  notifTypeSupplierCred = 1;
  notifTypeJobDone = 2;
  notifTypeInvalidCreds = 3;
  notifTypeItemStatus = 4;
  notifTypeMissingItems = 5;
  notifTypeNewOrder = 6;
  notifTypeNewSupplier = 7;

  showSupplierInfo: boolean = false;
  showOrderInfo: boolean = false;
  currentItem: any = undefined;
  newItem: boolean = false;

  statusOptions: any = undefined;
  statusColorOptions: any = undefined;

  constructor(
    private jobService: JobsService,
    private cdr: ChangeDetectorRef,
    private orderService: OrderService,
    private store: Store<IApplicationState>,
    private dashboardService: DashboardService,
    private djv: DashboardJobView,
    private dashboardActions: DashboardActions
  ) {
    (window as any)['note'] = this;
  }
  
  ngOnInit() {
    this.getTodayDateInEST();
    this.generateItems();
    this.getNotifications();
  }

  getTodayDateInEST() {
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'America/New_York'
    });
  
    const parts: any = formatter.formatToParts(new Date());
    const year = parts.find((part: any) => part.type === 'year').value;
    const month = parts.find((part: any) => part.type === 'month').value;
    const day = parts.find((part: any) => part.type === 'day').value;
  
    this.todayDate =`${year}-${month}-${day}`;
  }

  generateItems() {
    for (let i = 0; i < 10; i++) {
      const randomWidth = Math.floor(Math.random() * (20 - 5 + 1)) + 5;
      this.skeletonItems.push({ width: `${randomWidth}rem` });
    }
  }

  itemClicked(item: any) {
    this.newItem = true;
    this.cdr.detectChanges();
    if (item.type == this.notifTypeSupplier ||
        item.type == this.notifTypeSupplierCred ||
        item.type == this.notifTypeInvalidCreds ||
        item.type == this.notifTypeItemStatus ||
        item.type == this.notifTypeMissingItems ||
        item.type == this.notifTypeNewOrder ||
        item.type == this.notifTypeNewSupplier) {
      this.showSupplierInfo = true;
      }
    else {
      this.showSupplierInfo = false;
    }
    if (item.type == this.notifTypeItemStatus ||
      item.type == this.notifTypeMissingItems ||
      item.type == this.notifTypeNewOrder){
        this.showOrderInfo = true;  
      } else {
        this.showOrderInfo = false;  
      }
    this.newItem = false;
    this.currentItem = item;
    setTimeout(() => {
      this.setScrollbar('.all-info-container');
    }, 200);

  }

  formatDate(item: any): string {
    if (item.date == 'Today') return item.date;
    let date: any = new Date(item.date);
    date = date.toISOString();
    let cur = date.split('T')[0];
    if (cur == this.todayDate) return 'Today';
    cur = cur.split('-');
    cur = cur.slice(-2).join('/');
    return cur;
  }

  backgroundForItem(item: any) {
    let colorId: any = undefined
    if (item.type == this.notifTypeJobDone) {
      colorId = this.itemStatusType('Delivered');
      colorId = this.itemStatusColor(colorId);
      return colorId || '#5fc64c';
    } else if (item.type == this.notifTypeNewOrder ||
              item.type == this.notifTypeNewSupplier) {
      colorId = this.itemStatusType('Ordered');
      colorId = this.itemStatusColor(colorId);
      return colorId || '#ed7840';
    }
    if (!item.statuses) {
      return 'white';
    }
    if (colorId = this.itemContainsStatus(item, 'Delivered')) {
      colorId = this.itemStatusColor(colorId);
    } else if (colorId = this.itemContainsStatus(item, 'Pending payment')) {
      colorId = this.itemStatusColor(colorId);
    } else if (colorId = this.itemContainsStatus(item, 'Shipped')) {
      colorId = this.itemStatusColor(colorId);
    } else if (colorId = this.itemContainsStatus(item, 'Tracking issues')) {
      colorId = this.itemStatusColor(colorId);
    } else if (colorId = this.itemContainsStatus(item, 'Ready to be scheduled')) {
      colorId = this.itemStatusColor(colorId);
    }
    return colorId ? colorId: 'white';
  }

  borderForItem(item: any) {
    let colorId: any = undefined
    if (item.type == this.notifTypeJobDone ||
      item.type == this.notifTypeNewOrder ||
      item.type == this.notifTypeNewSupplier) {  
        return '';
    }
    if (!item.statuses) {
      return '1px solid rgba(0, 0, 255, 0.5)';
    }
    if (colorId = this.itemContainsStatus(item, 'Delivered')) {
      return '';
    } else if (colorId = this.itemContainsStatus(item, 'Pending payment')) {
      return '';
    } else if (colorId = this.itemContainsStatus(item, 'Shipped')) {
      return '';
    } else if (colorId = this.itemContainsStatus(item, 'Tracking issues')) {
      return '';
    } else if (colorId = this.itemContainsStatus(item, 'Ready to be scheduled')) {
      return '';
    }
    return '1px solid rgba(0, 0, 255, 0.5)';
  }

  cardOpenHeight: number = 190;
  cardIsOpenChange(event: any) {
    setTimeout(() => {
      this.cardOpenHeight = this.noteOrder.nativeElement.clientHeight;
    }, 100);
  }
  getMaxHeight() {
    // note-display-text + note-supplier-text * 2 + single-card + supplier-creds
    return window.innerHeight - (60 * 3 + this.cardOpenHeight + 170 + 95);
  }

  setScrollbar(v: string) {
    let jdisplay = document.querySelector(v);
    if (jdisplay) {
      jdisplay.addEventListener('mouseenter', function() {
        (jdisplay as any).classList.add('custom-scrollbar');
        (jdisplay as any).classList.remove('custom-scrollbar-close');
      });
      jdisplay.addEventListener('mouseleave', function() {
        (jdisplay as any).classList.remove('custom-scrollbar');
        (jdisplay as any).classList.add('custom-scrollbar-close');
      });
    }
  }

  itemContainsStatus(item: any, type: string) {
    let foundId = this.itemStatusType(type);
    return item.statuses.find((s: any) => s == foundId);
  }

  itemStatusType(type: string) {
    let which = this.statusOptions.find((s: any) => s.value == type);
    return which ? which.id: undefined;
  }

  itemStatusColor(type: number) {
    let which = this.statusColorOptions.find((s: any) => s.id == type);
    return which ? which.value: '';
  }

  sortDates(obj1: any, obj2: any) {
    if (obj1.date === 'Today') return -1;
    if (obj2.date === 'Today') return 1;
  
    const date1: any = new Date(obj1.date);
    const date2: any = new Date(obj2.date);
    return date2 - date1;
  }

  createNotifications(result: any) {
    this.statusColorOptions = result.options.status_colors;
    this.statusOptions = result.options.status;
    let output: any = [];
    let missing_emails = result.missing_emails;
    let index = 0;
    missing_emails.supplier.forEach((s: any) => {
      let text = `Missing email for ${s.name}`;
      output.push({index: index++, text: text, date: 'Today',
        type: this.notifTypeSupplier, supplier_name: s.name,
        supplier_id: s.id, ...s});
    });
    missing_emails.credentials.forEach((s: any) => {
      let text = `Missing email for ${s.supplier_name}`;
      output.push({index: index++, text: text, type: this.notifTypeSupplierCred, date: 'Today',
        supplier_name: s.supplier_name, supplier_id: s.supplier, ...s});
    });
    /*
    result.done_jobs.forEach((j: any) => {
      let text = `${j.name} is complete`;
      output.push({index: index++, text: text, type: this.notifTypeJobDone, date: j.date,
        job_name: j.name, ...j});
    })
    */
    result.invalid_creds.forEach((c: any) => {
      let text = `Credentials invalid for ${c.supplier_name}`;
      output.push({index: index++, text: text, date: 'Today',
        type: this.notifTypeInvalidCreds, supplier_name: c.supplier_name,
        supplier_id: c.supplier, ...c});
    })
    result.item_status.forEach((s: any) => {
      let text = '';
      if (this.itemContainsStatus(s, 'Delivered')) {
        text = 'An item was delivered';
      } else if (this.itemContainsStatus(s, 'Pending payment')) {
        text = 'An item is pending payment';
      } else if (this.itemContainsStatus(s, 'Shipped')) {
        text = 'An item was shipped';
      } else if (this.itemContainsStatus(s, 'Tracking issues')) {
        text = 'An item has tracking issues';
      } else if (this.itemContainsStatus(s, 'Ready to be scheduled')) {
        text = 'An item is ready to be scheduled';
      }
      output.push({index: index++, type: this.notifTypeItemStatus, text: text,
        name: s.name, order_name: s.order_name, supplier_name: s.supplier_name,
        job_name: s.job_name, date: s.change_date, supplier_id: s.supplier_id,
        order_id: s.order_id, ...s});
    });
    result.missing_items.forEach((i: any) => {
      let text = `Order ${i.name} is missing items`;
      output.push({index: index++, type: this.notifTypeMissingItems, text: text,
        name: i.name, order_name: i.name, supplier_name: i.supplier_name,
        date: i.created_datetime, supplier_id: i.supplier_id, order_id: i.id,
        job_name: i.job_name, ...i});
    });
    result.new_orders.forEach((i: any) => {
      let text = `New order ${i.name}`
      output.push({index: index++, type: this.notifTypeNewOrder, text: text,
        name: i.name, order_name: i.name, supplier_name: i.supplier_name,
        job_name: i.job_name, date: i.created_datetime, order_id: i.id,
        supplier_id: i.supplier_id, ...i});
    });
    result.new_suppliers.forEach((s: any) => {
      let text = `New supplier ${s.name}`
      output.push({index: index++, text: text, date: s.created_datetime,
        supplier_id: s.id, type: this.notifTypeNewSupplier,
        supplier_name: s.name, ...s});
    });
    output.sort(this.sortDates);
    this.notifications = output;
    this.currentItem = this.notifications?.length ? this.notifications[0] : undefined;
    setTimeout(() => {
      this.setScrollbar('.notifications-list-short');
    }, 200);

  }

  get dropdownRetrieved() {
    return this.dropdownOptions.document_types  &&
    this.dropdownOptions.job_id  &&
    this.dropdownOptions.status  &&
    this.dropdownOptions.status_colors  &&
    this.dropdownOptions.supplier_id  &&
    this.dropdownOptions.unit_of_measure &&
    this.allMailData?.curOrders;
  }

  orderDeleted() {
    this.currentItem = undefined;
    this.getNotifications();
    this.dashboardActions.requestUncategorizedView(
      { include_items: CommonConstants.include_items.uncategorized });
  }

  getNotifications() {
    this.orderService.getItemUpdates().subscribe((result: any) => {
      this.createNotifications(result);
    })
  }
}
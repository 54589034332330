import { Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { HostListener } from '@angular/core';
import { SelectedViewModel } from '../../models/selected-view.model';
import { Store } from '@ngrx/store';
import { MailBoxCommunicationService } from '../../services/mailbox_comm.service';
import { JobsService } from '../../services/job.service';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { first, map } from 'rxjs/operators';
import {unzipData, decodeMsgPackSimple} from 'src/app/dashboard/services/do_msgpack';
import { SkeletonModule } from 'primeng/skeleton';
import { createOrderItemData } from './tracking-utils';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { freshdesk_map_item, freshdesk_map_order, label_for_id } from './tracking-utils';
import { deepCopy } from 'src/app/common/utils/general';
import { DashboardService } from '../../services/dashboard.service';
import { OrderService } from '../../services/order.service';
import { unPackOrderItems } from 'src/app/dashboard/services/do_msgpack';


@Component({
  selector: 'tracking-pane',
  templateUrl: './tracking-component.html',
  styleUrls: ['./tracking-component.css', '../comment-view/comment-pane.component.sass'],
})
export class TrackingPaneComponent implements OnInit {
  @Input() makeVisible: boolean = false;
  @Input() selectedView?: SelectedViewModel;
  @Input() dropdownOptions?: any;
  @Input() trackingData?: any = undefined;
  @Input() isLoading: boolean = false;
  msgLoading: boolean = true;
  @Input() confirmPanel: boolean = false;
  @Input() allMailData: any;

  calendarVisible = false;
  @ViewChild('calendar') calendar!: ElementRef;
  @ViewChild('calendarWrapper', { static: false }) calendarWrapper!: ElementRef;

  @Output() confirmMail = new EventEmitter<any>();
  @Output() trackFieldChanged = new EventEmitter<any>();

  currentItemId: any = '';
  defaultBackground: string = '#fff';
  background: string = '';
  defaultStatus: string = 'Status';
  status: string = '';
  showOrder: boolean = true;
  orderId: string = '';
  defaultItemHeader = 'ITEM INFO';
  itemHeader = this.defaultItemHeader;
  private baseUrl: string = environment.ENV.BASE_URL;
  curMsgId = '';
  curMsgs: Map<string, any> = new Map<string, any>();
  skeletonItems: any[] = [];

  orderItemData: any = [];
  orderData: any = [];
  itemData: any = [];
  itemVisibility: { [index: number]: boolean } = {};

  fr_status: boolean = true;

  ngOnInit() {
    (window as any)['track'] = this;
    this.generateItems();

  }

  ngOnChanges(changes: any) {
    if (!this.trackingData || this.curMsgId == this.trackingData.id) return;
    this.curMsgId = this.trackingData.id;
    this.initTracking(this.trackingData);
  }

  constructor(
    private mailCommunicationService: MailBoxCommunicationService,
    private jobService: JobsService,
    private store: Store,
    private httpClient: HttpClient,
    private dashboardActions: DashboardActions,
    private cdr: ChangeDetectorRef,
    private dashboardService: DashboardService,
    private orderService: OrderService,
    ) {
    }

  ngAfterViewInit() {
    this.mailCommunicationService.mailVisibility$.subscribe((data: any) => {
      if (!data.isVisible) {
        this.makeVisible = false;
        return;
      }
      if (!data.item?.order_id && !data.item?.orders_info[0]?.order_id) {
        this.closeToggle();
        return;
      }
      this.initTracking(data);
    });
    setTimeout(() => {
      let vals = ['.show-order-container'];
      vals.forEach((v: any) => {
        let jdisplay = document.querySelector(v);
        if (jdisplay) {
          jdisplay.addEventListener('mouseenter', function() {
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
          });
          jdisplay.addEventListener('mouseleave', function() {
            (jdisplay as any).classList.remove('custom-scrollbar');
            (jdisplay as any).classList.add('custom-scrollbar-close');
          });
        }
      })
    }, 500);
  }

  initTracking(data: any) {
    this.fr_status = data?.item?.fr_status;
    this.showOrder = true; 
    this.makeVisible = data.isVisible;
    if (!this.makeVisible) {
      return;
    }
    this.status = this.defaultStatus;
    this.setHeader(data.item);
    this.getMessageData(data);
  }

  oidata: any = null;
  getMessageData(data: any) {
    this.isLoading = true;
    this.msgLoading = true;
    this.loadInputMessageData(data.id)
      .subscribe( (result: any) => {
        if (result.items) {
          let unpack_results = unPackOrderItems({results: result.items}, false);
          result.items = unpack_results?.results || [];
        } else {
          result.items = [];
        }
        let oidata = {result: result, data: data,
                      options: this.dropdownOptions, status: this.status};
        this.oidata = oidata;
        let out_data = createOrderItemData(oidata);
        this.orderData = out_data.order;
        this.itemData = out_data.item;
        this.orderItemData = this.orderData;
        this.setItemVisibility(this.itemData);
        this.formatItemheader(this?.itemData?.length || 0);
        this.isLoading = false;
        this.msgLoading = false;
      }
    );
  }

  get hasDataLoaded() {
    return !this.isLoading && !this.msgLoading &&
            this.dropdownOptions?.job_id && this.dropdownOptions?.supplier_id;
  }

  generateItems() {
    for (let i = 0; i < 10; i++) {
      const randomWidth = Math.floor(Math.random() * (15 - 5 + 1)) + 5; // Random number between 5 and 15
      this.skeletonItems.push({ width: `${randomWidth}rem` });
    }
  }

  closeToggle() {
    this.makeVisible = false;
    this.showOrder = true;
    this.itemHeader = this.defaultItemHeader;
    this.mailCommunicationService.get_item_data({isVisible: false});
  }


  setHeader(item: any) {
    if (!this.selectedView) return;
    let order_id = this.trackingData
                    ? item.order_id
                    : item?.orders_info[0]?.order_id || item.order_id;
    this.orderId = order_id;
    let results = this.selectedView.results;
    let found = results.find((r: any) => r.order_id == order_id);
    if (found) {
      let color = this.dropdownOptions.status_colors.find((c: any) => c.id == found.status);
      this.background = color ? color.value: this.defaultBackground;

      let status_name = this.dropdownOptions.status.find((s: any) => s.id == found.status);
      this.status = status_name ? status_name.value: this.defaultStatus;
      return;
    }
    this.background = this.defaultBackground;
    this.status = this.defaultStatus;
  }

  showOrderItem(type: string) {
    this.showOrder = type == 'order' ? true: false;
    this.orderItemData = type == 'order' ? this.orderData: this.itemData;
  }

  formatItemheader(item_count: any) {
    this.itemHeader = `${this.defaultItemHeader} (${item_count})`;
  }

  formatOrderType(val: any) {
    return val.id;
  }

  formatOrderTypeItem(val: any) {
    return val.id.toUpperCase();
  }

  formatOrderVal(val: any) {
    return Array.isArray(val.value) ? val.value : [val.value];
  }

  formatOrderItem(val: any) {
    return val.value;
  }

  stopEditingValue(val: any) {
    if (this.moneyFields.includes(val.id)) {
      let cur = val.value.value;
      cur = (cur && cur[0] == '$') ? cur.slice(1,): cur;
      const numericValue = parseFloat(cur);
      if (!isNaN(numericValue)) {
        val.value.value = `$${numericValue.toFixed(2)}`;
      }
    }
    this.fieldEdited(val, val, false);
  }

  stopEditingItemValue(item_val: any, val: any) {
    item_val.value = item_val.value || '';
    if (this.moneyFields.includes(item_val.id)) {
      let cur = val.value;
      cur = (cur && cur[0] == '$') ? cur.slice(1,): cur;
      const numericValue = parseFloat(cur);
      if (!isNaN(numericValue)) {
        item_val.value.value = `$${numericValue.toFixed(2)}`;
      }
    }
    val.fr = true;
    this.fieldEdited(item_val, val, false);
  }

  blurValue(event: any) {
    event.target.blur();
  }

  didStatusChange(event: any, val: any) {
    if (event.colField == 'status') {
      val.status = event.event.value.id;
    }
  
  }

  linkEditIdx: any = undefined;
  linkEditType: any = undefined;
  isLinkEditing: boolean = false;
  startEditingTracking(val: any, item: any, index: any) {
    this.linkEditIdx = index;
    this.isLinkEditing = true;
    this.linkEditType = val.id;
    if (item.value == '--') item.value = '';
  }

  stopEditingTracking(val: any, item: any) {
    this.isLinkEditing = false;
    this.linkEditIdx = undefined;
    this.linkEditType = undefined;
    item.fr = item.value ? true: false;
    let payload = {id: val.id, value: item};
    this.fieldEdited(payload, item, false);
    if (item.value == '') item.value = '--';
  }

  formatOrderLink(val: any, item: any, index: any) {
    if (this.isLinkEditing &&
        this.linkEditIdx == index &&
        this.linkEditType == val.id) return '';
    if (!item.value || item.value == '--') return '';
    try {
      const url = new URL(item.value);
      return `${url.hostname}`;
    } catch(error) {
      return item.value;
    }
  }

  clearLink(val: any, item: any) {
    let payload = {id: val.id, value: item};
    this.fieldEdited(payload, item, false, true);
    let add = {fr: false, value: '--'};
    val.value = val.value.filter((v: any) => v.value != item.value);
    if (!val.value.length) val.value.push(add);
  }

  addLink(val: any) {
    let add = {fr: false, value: '--'};
    val.value.push(add);
  }

  isLink(val: any) {
    return ['Supplier tracking', 'Shipment tracking'].includes(val.id);
  }

  moneyFields = ['Total cost', 'Discount', 'Sales tax', 'Shipping cost', 'Unit cost'];
  preProcessDecimal(val: any, is_item: boolean = false) {
    if (!this.moneyFields.includes(val.id)) {
      return true;
    }
  
    if (val.value && val.value.value && val.value.value[0] != '$') {
      const numericValue = parseFloat(val.value.value);
      if (!isNaN(numericValue)) {
        val.value.value = `$${numericValue}`;
      }
    }
    return true;
  }
  
  dataForOrder(val: any) {
    val.orderUpdated = this.onOrderDocChange.bind(this);
    return val;
  }

  dataForOrderNew(val: any) {
    let newval = deepCopy(val);
    delete newval.value;
    newval.orderUpdated = this.onOrderDocChange.bind(this);
    return newval;
  }

  onOrderDocChange(event: any) {
    if (event.updateOrder) {
      let uo = event.updateOrder;
      let found = this.orderData.find((od: any) => od.id == 'Order docs');
      found = found ? found.value.find((f: any) => f.value.id == uo.document_id): undefined;
      if (found) {
        found.value = {
          document_type: uo.document_type, id: uo.document_id,
          number: uo.number, order_document_date: uo.order_document_date
        }
      }
      this.dashboardService.updateOrderDocument({payload: uo})
      .subscribe((response: any) => {
        let atts = response.supplier_documents_attachments.find((da: any) => da.order_document_id == uo.document_id);
        if (atts && found) {
          found.att = atts ? [atts]: undefined;
        }
      });
    } else if (event.deleteOrder) {
      let delo = event.deleteOrder;
      let odocs = this.orderData.find((od: any) => od.id == 'Order docs');
      let item_id = odocs.value[0].data.id;
      let found = odocs.value.filter((f: any) => f.value.id != delo.order_id);
      odocs.value = found;
      if (!found.length) {
        odocs.value = [{
          fr: false, data: {id: item_id}, colDef: 'po_document_number', att: undefined
        }];
      }
      this.dashboardActions.requestDeleteOrderDocument({ ...event.deleteOrder });
    } else if (event.addOrder) {
      this.dashboardService.addOrderDocument({payload: event.addOrder})
      .subscribe((response: any) => {
        let odocs = this.orderData.find((od: any) => od.id == 'Order docs');
        if (odocs.value.length == 1 && !odocs.value[0].value) odocs.value = [];
        let curDocIds = odocs ? odocs.value.map((f: any) => f.value.id): [];
        let newDoc = response.supplier_documents_numbers.find((sd: any) => !curDocIds.includes(sd.id));
        let atts = response.supplier_documents_attachments.find((da: any) => da.order_document_id == newDoc.id);
        let items = this.oidata.result.items;
        let item_id = items && items.length ? items[0].id : undefined;
        let newVal = {
          colDef: "po_document_number",
          fr: false,
          value: newDoc,
          data: {id: item_id},
          att: atts ? [atts]: undefined,
        };
        odocs.value.push(newVal);
      });
    }
  }

  dataForOrderAtt(val: any) {
    return {
      value: val.att? val.att: [],
      data: val.data,
      fileDeleted: this.onOrderAttChange.bind(this),
    };
  }

  onOrderAttChange(event: any) {
    if (event.deleteAttachment) {
      let da = event.deleteAttachment;
      let odocs = this.orderData.find((od: any) => od.id == 'Order docs');
      let doc = odocs.value.find((v: any) => {
        return v.att && v.att.find((a: any) => a.id == da.attachment_id);
      });
      if (doc) doc.att = undefined;
    }
  }

  freshDeskRetrieved(item: any) {
    let yes = {'font-weight': '600', 'color': '#666'};
    let no = {'font-weight': '400', 'color': '#999'};
    return item?.fr ? yes: no;
  }

  addSpace(item: any) {
    return item == ' ';
  }

  formatOrderValType(val: any) {
    return val.id;
  }

  isItem(val: any) {
    return val.id.startsWith('Item');
  }

  isItemVisible(index: any): boolean {
    return !!this.itemVisibility[index.id];
  }

  isOrderDocs(val: any) {
    let ods = ['Order docs'];
    return ods.includes(val.id);
  }

  isDropdown(val: any) {
    let ddowns = [
      'Order', 'Job', 'Supplier', 'Ship to state',
      'Payment method', 'Delivery method',
      'Status', 'Unit of measure', 'Is COM',
    ];
    return ddowns.includes(val.id);
  }

  dataForTrackName(val: any, item: any, is_item: boolean = false) {
    let field = this.fieldForTrackName(val);
    let value = '';
    if (item) {
      let search = undefined;
      if (field == 'order_id') search = this.trackingData.orders;
      else if (field == 'supplier_id') {
        if (this.allMailData?.supArray) {
          search = this.allMailData.supArray.filter((s: any) => s.id != 'all');
        } else {
          search = this.dropdownOptions[field];
        }
      }
      else search = this.dropdownOptions[field];
      if (!search) {
        return {[field]: ''};
      }
      let find = search.find((d: any) => ((field == 'order_id' || is_item) ? d.id : d.value) == item.value);
      if (find) {
        value = find.id;
      }
    }
    return {[field]: value};
  }

  optionsForTrackName(val: any) {
    let field = this.fieldForTrackName(val);
    return (field == 'order_id')
      ? this.trackingData.orders
      : this.dropdownOptions[field];
  }

  fieldMap() {
    return {
      'Order': 'order_id',
      'Job': 'job_id',
      'Supplier': 'supplier_id',
      'Ship to state': 'shipping_state',
      'Payment method': 'payment_method',
      'Delivery method': 'delivery_method',
      'Status': 'status',
      'Is COM': 'item_com_selection',
      'Unit of measure': 'unit_of_measure',    
    }
  }

  fieldForTrackName(val: any) {
    return (this.fieldMap() as any)[val.id];
  }

  orderItemFloats = ['shipping_cost', 'discount', 'sales_tax',
                    'total_cost', 'quantity', 'cost_per_unit',
                    'item_total_cost'];
  orderItemDates = ['ship_date', 'delivery_date', 'in_stock_date'];
  processFieldData(field: string, new_data: string, send_alert: boolean = true) {
    if (['billing_zip', 'shipping_zip'].includes(field)) {
      return new_data.slice(0, 10);
    } else if (field == 'last_4_card_digits') {
      return new_data.slice(0, 4);
    } else if (['billing_name', 'shipping_name', 'shipping_address',
              'shipping_city', 'order_sidemark', 'ship_via', 'name',
              'item_sidemark', 'sku', 'spec', 'supplier_contact'].includes(field)) {
      return new_data.slice(0, 255);
    } else if (this.orderItemFloats.includes(field)) {
      if (new_data && new_data[0] == '$') {
        return new_data.slice(1,);
      }
      if (isNaN(Number(new_data))) {
        if (send_alert) {
          alert(`${new_data}: needs to be a valid number!`);
        }
        return undefined;
      }
    } else if (this.orderItemDates.includes(field)) {
      return this.formatSendDate(new_data);
    }
    return new_data;
  }

  fieldEdited(event: any, val: any,
      dropdown: boolean,
      delete_value: boolean = false) {
    
    let im_fields = ['order_id', 'job_id', 'supplier_id'];
    let order_fields = [
        'job_id', 'supplier_id', 'payment_method', 'shipping_state',
        'billing_zip', 'shipping_zip',
        'last_4_card_digits',
        'billing_name', 'shipping_name', 'shipping_address', 'shipping_city', 
        'order_sidemark', 'ship_via', 'supplier_contact',
        'total_cost', 'discount', 'sales_tax', 'shipping_cost', 
      ];
    let item_fields = [
      'delivery_method', 'ship_date', 'delivery_date', 'in_stock_date',
      'shipment_tracking', 'tracking_info'
    ];
    let fr_value = undefined;
    let new_data = undefined;
    let field: any = undefined;
    let fr_name = undefined;
    let is_item = !this.showOrder;
    if (dropdown) {
      field = event.colField;
      new_data = event.event.value.id;
      fr_value = undefined;
      if (field == 'order_id' || is_item) {
        fr_value = val.value.value = event.event.value.id;
      } else {
        fr_value = val.value.value = event.event.value.value;
      }
      let search = is_item ? freshdesk_map_item : freshdesk_map_order;
      fr_name = (search as any).find((val: any) => {
        return Object.entries(val).some(([label, value]) => (value as any).mlog === field);
      });
    
    } else {
      let search = is_item ? freshdesk_map_item : freshdesk_map_order;
      fr_name = (search as any).find((val: any) => {
        return Object.entries(val).some(([label, value]) => label == event.id);
      });
      new_data = deepCopy(event.value.value || '');
    }

    if (fr_name) {
      let firstKey = Object.keys(fr_name)[0];
      field = (fr_name as any)[firstKey].mlog || (fr_name as any)[firstKey].mitem;
      fr_name = is_item ? (fr_name as any)[firstKey].mlog : (fr_name as any)[firstKey].fr_1;
      if (!dropdown) {
        new_data = this.processFieldData(field, new_data);
        if (new_data == undefined) return;
        fr_value = new_data;
      }
    }
    let payload = {
      id: this.curMsgId,
      field: field,
      data: new_data,
      order_update: order_fields.includes(field) && !is_item,
      item_update: item_fields.includes(field) && !is_item,
      im_update: im_fields.includes(field) && !is_item,
      fr_field: fr_name,
      fr_value: fr_value,
      approvals: true,
      delete: delete_value,
      is_item: is_item,
      item_id: this.currentItemId,
    }
    this.updateOrderApprovals(payload);
    if (im_fields.includes(field)) {
      this.trackFieldChanged.emit({field: field, data: new_data});
    }
  }

  isDate(val: any) {
    let dates = ['In stock date', 'Ship date', 'Delivery date'];
    return dates.includes(val.id);
  }

  formatSendDate(value: any) {
    if (!value) return '--';
    let date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedMonth = month < 10 ? `0${month}` : month.toString();
    const formattedDay = day < 10 ? `0${day}` : day.toString();
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  calendar_value: any = '';
  calendarReady: boolean = false;
  openCalendar(event: any, val: any) {
    this.calendarVisible = !this.calendarVisible;
    if (!this.calendarVisible && this.calendar_value.id == val.id) return;
    else this.calendarVisible = true;
    this.calendar_value = val;
    setTimeout(() => {
      const calendarElement: any = this.calendar.nativeElement;
      const calendarWrapperElement: any = this.calendarWrapper.nativeElement;
      const rect = calendarWrapperElement.getBoundingClientRect();
      const x = rect.left;
      const y = event.y;
      calendarElement.style.left = `${x - 155}px`;
      calendarElement.style.top = `${y - 378}px`;
      this.calendarReady = true;
    }, 100);
  }


  onChangeDate(event: any) {
    this.calendar_value.value = {fr: true, value: event};
    let val = this.calendar_value;
    this.fieldEdited(val, val, false, false);
    this.calendarVisible = false;
  }

  toggleItemVisibility(index: any): void {
    this.itemVisibility[index.id] = !this.itemVisibility[index.id];
  }

  setItemVisibility(itemdata: any) {
    let vis: { [key: string]: boolean } = {};
    itemdata.forEach((i: any) => {
      if (i.id.startsWith('Item')) {
        vis[i.id] = false;
      }
    });
    this.itemVisibility = vis;
  }

  addItem() {
    let new_item: any = {};
    new_item.fr = false;
    let name = 'New Item';
    new_item.name = name;
    new_item.status = 1;
    let iid = `Item ${this.itemData.length + 1}`
    new_item.item_id = new_item.id = iid;
    let values: any = [];
    freshdesk_map_item.forEach((i: any) => {
      Object.entries(i).forEach(([label, fields]) => {
        let cur: any = {id: label};
        if ((fields as any).number) {
          cur.value = {value: 0, fr: false};
        } else if ((fields as any).dropdown) {
          let val = label == 'Status' ? 1: 0;
          cur.value = {value: val, fr: false};
        } else if ((fields as any).array) {
          cur.value = [{value: '--', fr: false}];
        } else {
          let val = label == 'Name' ? name: '';
          cur.value = {value: val, fr: false};
        }
        values.push(cur);
      })
    });
    new_item.value = values;
    this.itemData.push(new_item);
    this.orderItemData = this.itemData;
    let payload = {
      order_id: this.orderId, name: name, room_id: null, images: null,
    };
    this.orderService.addItem({payload: payload}).subscribe((result: any) => {
      let item_id = result.id;
      let found = this.itemData.find((i: any) => i.item_id == iid);
      if (found) found.item_id = item_id;
    })
  }

  deleteItem(val: any) {
    this.itemData = this.itemData.filter((i: any) => i.id != val.id);
    this.itemData.forEach((i: any, idx: number) => i.id = `Item ${idx + 1}`);
    this.orderItemData = this.itemData;
    let payload = {
      id: this.curMsgId,
      fr_field: 'items',
      approvals: true,
      delete_item: true,
      is_item: true,
      item_id: val.item_id,
    }
    if (val.item_id) {
      this.updateOrderApprovals(payload);
    }
  }

  filterItemVals(val: any) {
    return Object.entries(val.value).map(([key, value]) => ({ key, value }));
  }

  getItemHeaderBackground(val: any) {
    if (val.status == undefined) return this.background;
    let color = this.dropdownOptions.status_colors.find((c: any) => c.id == val.status);
    return color ? color.value: this.background;
  }

  getItemHeaderName(val: any) {
    return val.name ? val.name: 'No name';
  }

  getItemHeaderStatus(val: any) {
    if (val.status == undefined) return this.status;
    let status_name = this.dropdownOptions.status.find((s: any) => s.id == val.status);
    return status_name ? status_name.value: this.status;
  }


  onMailConfirm() {
    this.onConfirm();
    setTimeout(() => {
      this.confirmMail.emit(true);
    }, 0);
  }

  iterateOrderItems(searchMap: any, itemOrderData: any, is_item: boolean = false) {
    let doptions = {
      options: this.dropdownOptions,
      data: {
        jobs: this.trackingData.jobs,
        suppliers: this.trackingData.suppliers,
      }
    }
    let output: any = [];
    searchMap.forEach((val: any) => {
      let cur: any = {};
      Object.entries(val).forEach(([label, value]: [any, any]) => {
        if (label == 'Order' || label == 'Order docs') return;
        let data = itemOrderData.find((o: any) => o.id == label);
        cur.field = value.mlog || value.mitem;
        if (value.item_level) cur.item_level = true;
        if (value.dropdown) {
          let output = data.value.value;
          if (!is_item) {
            output = label_for_id(doptions, cur.field, data.value.value, true);
            output = output?.id;
          }
          cur.data = output;
          cur.dropdown = true;
        } else if (this.orderItemFloats.includes(cur.field)) {
          cur.data = this.processFieldData(cur.field, data.value.value, false);
          cur.float = true;
        } else if (this.orderItemDates.includes(cur.field)) {
          let val = this.processFieldData(cur.field, data.value.value, false);
          cur.data = (val == '--') ? undefined: val;
          cur.date = true;
        } else if (value.array) {
          cur.data = data.value.map((v: any) => v.value == '--' ? '': v.value);
          cur.array = true;
        } else {
          cur.data = this.processFieldData(cur.field, data.value.value || '', false);
        }
      });
      if (cur.field) output.push(cur);
    });
    return output;
  }

  onConfirm() {
    let items: any = [];
    this.itemData.forEach((i: any) => {
      let cur = this.iterateOrderItems(freshdesk_map_item, i.value, true);
      items.push({id: i.item_id, values: cur});
    })
    let order_values = this.iterateOrderItems(freshdesk_map_order, this.orderData);
    items.forEach((i: any) => {
      order_values.forEach((o: any) => {
        if (o.item_level && o.data) {
          let ival = i.values.find((v: any) => v.field == o.field);
          if (ival) {
            if (ival.array) ival.data = ival.data ? ival.data.concat(o.data): o.data;
            else ival.data = o.data;
          }
        }
      })
    })
    let payload = {
      order: {id: this.orderId, values: order_values},
      items: items,
      id: this.curMsgId,
      confirm: true,
    }
    this.updateOrderApprovals(payload);
  }

  loadInputMessageData(msg_id: any): Observable<any> {
    return this.httpClient.get<any>(`${this.baseUrl}input_messages/view/`+ msg_id +`/`, {
      headers: new HttpHeaders({ 'Accept': 'application/x-msgpack' }),
      responseType: 'arraybuffer' as any,
      params: {'freshdesk': true},
    }).pipe(map((response: any) => decodeMsgPackSimple(response)));
  }

  updateOrderApprovals(payload: any): void {
    this.httpClient.put(`${this.baseUrl}input_messages/approvals/`,
    payload,
    { headers: new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8'})}
    ).subscribe(() => {
      if (payload.field == 'order_id' && this.trackingData?.item) {
        this.trackingData.item.order_id = payload.data;
        this.initTracking(this.trackingData);
      }
    });
  }

}
import { ReceiversActionTypes } from '../receivers-action-types';
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

export class RequestReceiversPage implements Action {
  readonly type: ReceiversActionTypes.REQUEST_RECEIVERS_PAGE = ReceiversActionTypes.REQUEST_RECEIVERS_PAGE;

  constructor(
    public payload?: any) { }
}
export class ReceiveReceiversPage implements Action {
  readonly type: ReceiversActionTypes.RECEIVE_RECEIVERS_PAGE = ReceiversActionTypes.RECEIVE_RECEIVERS_PAGE;

  constructor(
    public payload?: any) { }
}
export class ErrorReceiveReceiversPage implements Action {
  readonly type: ReceiversActionTypes.ERROR_RECEIVE_RECEIVERS_PAGE = ReceiversActionTypes.ERROR_RECEIVE_RECEIVERS_PAGE;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class RequestReceiverOptions implements Action {
  readonly type: ReceiversActionTypes.REQUEST_RECEIVERS_OPTIONS = ReceiversActionTypes.REQUEST_RECEIVERS_OPTIONS;

  constructor(
    public payload?: any) { }
}
export class ReceiveReceiverOptions implements Action {
  readonly type: ReceiversActionTypes.RECEIVE_RECEIVERS_OPTIONS = ReceiversActionTypes.RECEIVE_RECEIVERS_OPTIONS;

  constructor(
    public payload?: any) { }
}
export class ErrorReceiveReceiverOptions implements Action {
  readonly type: ReceiversActionTypes.ERROR_RECEIVE_RECEIVERS_OPTIONS = ReceiversActionTypes.ERROR_RECEIVE_RECEIVERS_OPTIONS;

  constructor(
    public payload?: HttpErrorResponse) { }
}

import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faBell, faRightFromBracket, faSliders, faUser } from '@fortawesome/free-solid-svg-icons';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { PermissionsService } from '../../services/permissions.service';
import { JobTableService } from "../../../dashboard/services/job-table.service";
import { MailBoxCommunicationService } from 'src/app/dashboard/services/mailbox_comm.service';

@Component({
  selector: 'materlog-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent extends OnDestroyMixin() {
  @Input() jobName: any;

  profileMenu: boolean = false;
  faPerson = faUser;
  faNotification = faBell;
  faSliders = faSliders;
  faEnvelope = faEnvelope;
  faLogout = faRightFromBracket;

  // permissionService: PermissionsService;
  items!: any[];

  constructor(
    public router: Router,
    private injector: Injector,
    private jobTableService: JobTableService,
    private mailBoxComm: MailBoxCommunicationService,
    public permissionService: PermissionsService,
  ) {
    super();
    // this.permissionService = this.injector.get<PermissionsService>(PermissionsService);

  }

  flipInbox():void{
    this.mailBoxComm.toggle_inbox();
  }

  flipComments():void{
    this.mailBoxComm.toggle_comments();
  }

  openProfileMenu() {
    this.profileMenu = true;
  }
}

import {Component, NgModule} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { faCalendarDays, faRectangleList, faFile, faInbox, faAddressBook , faPassport} from '@fortawesome/free-solid-svg-icons';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { CompanyActions } from '../../../company-settings/state/actions/company.action';
import { IOrganization } from '../../../company-settings/models/company.model';
import { IStoreApiItem } from '../../../common/models/store-api-item.model';
import { selectOrganization } from '../../../company-settings/state/selectors/company.select';
import { IApplicationState } from '../../../common/state/models/app.state.model';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";


@Component({
  selector: 'materlog-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.sass']
})
export class HomePageComponent extends OnDestroyMixin() {
  company: IOrganization = {
    logo: null,
    slug: null,
    name: '',
  };
  toggleUpgrade: boolean = false;
  faCalendar = faCalendarDays;
  faList = faRectangleList;
  faFile = faFile;
  faBadge = faAddressBook;
  faPassport = faPassport;
  faInbox = faInbox;

  constructor(
    public router: Router,
    private companyActions: CompanyActions,
    private store: Store<IApplicationState>) {
    super();

    companyActions.requestOrganization();

    this.store
      .pipe(
        select(selectOrganization),
        filter((organization: IStoreApiItem<IOrganization>) => !organization.isLoading),
        take(1)
      )
      .subscribe((organization: IStoreApiItem<IOrganization>) => {
        if (organization.data != null) {
          this.company = JSON.parse(JSON.stringify(organization?.data));
        }
      });
  }

  navigateToJobs() {
    this.router.navigate(['']);
  }

  navigateToDashboard() {
    this.router.navigate(['']).then(() => {
      location.reload();  // need to reset grid state
    });
  }

  navigateToSuppliers() {
    this.router.navigate(['']);
  }

  navigateToSuppliersCredentials() {
    this.router.navigate(['']);
  }

  navigateToReceivers() {
    this.router.navigate(['']);
  }
}

import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";

import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { TableConstants } from 'src/app/common/constants/table.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { SelectedViewModel } from '../../models/selected-view.model';
import { JobTableService } from '../../services/job-table.service';
import {deepCopy, deepArrayClone} from "../../../common/utils/general"


@Component({
  selector: 'hide-fields',
  templateUrl: './hide-fields.component.html',
  styleUrls: ['./hide-fields.component.sass'],
})
export class HideFieldsComponent extends OnDestroyMixin() implements OnChanges {
  @Input() selectedView?: SelectedViewModel;
  @Input() isLoading!: boolean;

  faHidden = faEyeSlash;
  toggleFieldsModal: boolean = false;
  searchterm: string = "";
  fields = TableConstants.jobItemsTableColumns;

  constructor(
    private cdr: ChangeDetectorRef,
    private dashboardActions: DashboardActions,
    private jobTableService: JobTableService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.selectedView?.currentValue && !!this.selectedView) {
      this.fields = [];
      this.selectedView.selectedColumns.forEach((item:any)=>{
        this.fields.push(item);
      });

      TableConstants.jobItemsTableColumns.forEach(item=>{
        if(item.field == 'index') {
          return;
        }
        let found: any = this.fields.find(val => val.field == item.field);
        if (!found) {
          this.fields.push(item);
        } else {
          found.header = item.header;
        }
      });

    }
  }


  onFilterHideFieldsChanged():void {
    let search = (document.getElementById('filter-hide-field') as HTMLInputElement).value.toLowerCase()
    this.searchterm = search;

  }

  inSearchTerm(fieldname:string){
    return fieldname.toLowerCase().includes(this.searchterm);
  }


  clickOutsideModal(event: any): void {
    if (event.target.id !== 'show-fields-modal-button') {
      this.toggleFieldsModal = false;
      this.searchterm = "";
    }
  }

  get numberOfHiddenFields(): number {
    if (!this.fields) return 0;
    return this.fields.filter((field: any) => !field.visible).length;
  }

  showFieldsModal(event: any): void {
    event.preventDefault();
    this.toggleFieldsModal = !this.toggleFieldsModal;
  }

  hideAll(): void {
    this.fields.forEach((field: any) => (field.visible = false));
    this.triggerFieldsChange(null, false);
  }

  showAll(): void {
    this.fields.forEach((field: any) => (field.visible = true));
    this.triggerFieldsChange(null, true);
  }

  triggerFieldsChange(field: any, isVisible: boolean): void {
    if (!this.selectedView) return;
    let which = field == null ? (isVisible ? 'all' : 'none') : field.field;
    this.jobTableService.setShowFilter({field: which, display: true});
    this.dashboardActions.requestSaveViewReturn('fields_selected_to_display', this.fields,
      which, this.selectedView?.activeView.id).subscribe(() => {});
  }

  trackByFn(index: number, item:any) {
    if(item.order){
      return item.order;
    }
    return index;
  }

  orderByFn( index: number, item:any) {
    if(item.order > -1){
      return item.order;
    }
    return 100;
  }
}

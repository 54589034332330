<div class="orders-row">
  <span class="orders-items">
    <div
      *ngFor="let item of params.colDef?.field === 'supplier_documents_numbers' ? params.value : [params.value]"
      class="number"
    >
      <div *ngIf="item && item.number">
        <span
          class="orders-item--button"
          (click)="openOrderModal(item.id, params.colDef?.field === 'po_document_number', params.data.id)"
        >
          {{ item.number }}
        </span>

      </div>
    </div>
  </span>

</div>




import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { DashboardActionTypes } from '../dashboard-action-types';

export class RequestOptions implements Action {
  readonly type: DashboardActionTypes.REQUEST_OPTIONS = DashboardActionTypes.REQUEST_OPTIONS;

  constructor(
    public payload: string) { }
}
export class ReceiveOptions implements Action {
  readonly type: DashboardActionTypes.RECEIVE_OPTIONS = DashboardActionTypes.RECEIVE_OPTIONS;

  constructor(
    public payload: IOptions) { }
}
export class ErrorReceiveOptions implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_OPTIONS = DashboardActionTypes.ERROR_RECEIVE_OPTIONS;

  constructor(
    public payload: HttpErrorResponse) { }
}

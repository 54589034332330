import { Injectable } from '@angular/core';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { select, Store } from '@ngrx/store';
import { selectActiveJobs, selectOptions, selectSuppliers, selectViews } from 'src/app/dashboard/state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { BehaviorSubject, combineLatest, filter, Observable, takeUntil } from 'rxjs';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IViewOptions } from '../interfaces/view-options.interface';
import { IActiveJobs, ISuppliers } from 'src/app/dashboard/models/dashboard.model';
import { IDropdownOptions } from '../interfaces/dropdown-options.interface';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { TableConstants } from 'src/app/common/constants/table.constants';
import { deepCopy } from 'src/app/common/utils/general';

@Injectable({
  providedIn: 'root'
})
export class OptionsService extends OnDestroyMixin() {

  private activeJobsList: any;

  viewsOptions$ = new BehaviorSubject<IViewOptions>({});
  dropdownOptions$ = new BehaviorSubject<IDropdownOptions>({});
  privacyOptions$ = new BehaviorSubject<any>([]);
  historyOptions$ = new BehaviorSubject<any>([]);

  dropdownOptions = {};

  constructor(
    private store: Store<IApplicationState>,
    private dashboardActions: DashboardActions) {
    super();
    this.dropdownOptions = {};
  }

  get viewsOptionsObs(): Observable<IViewOptions> {
    return this.viewsOptions$.asObservable();
  }

  get dropdownOptionsObs(): Observable<IDropdownOptions> {
    return this.dropdownOptions$.asObservable();
  }

  get privacyOptionsObs(): Observable<any> {
    return this.privacyOptions$.asObservable();
  }

  get historyOptionsObs(): Observable<any> {
    return this.historyOptions$.asObservable();
  }

  initOptionsSubscription(): void {
    combineLatest([
      this.store.pipe(
        select(selectActiveJobs),
        filter((activeJobs: IStoreApiItem<IActiveJobs>) => {
          return !activeJobs.isLoading && !!activeJobs.data;
        }
        ),
        takeUntil(this.destroy)
      ),
      this.store.pipe(
        select(selectOptions),
        filter((options: IStoreApiItem<IOptions>) => !options.isLoading && !!options.data),
        takeUntil(this.destroy)
      ),
      this.store
        .pipe(
          select(selectViews),
          filter((views: IStoreApiItem<any>) => !views.isLoading),
          takeUntil(this.destroy)
        ),
      this.store
        .pipe(
          select(selectSuppliers),
          filter((suppliers: IStoreApiItem<ISuppliers>) => !suppliers.isLoading && !!suppliers.data),
          takeUntil(this.destroy)
        ),
    ]).pipe(takeUntil(this.destroy))
      .subscribe(([selectActiveJobsResult, selectOptionsResult, selectViewsResult, selectSuppliersResult]: any) => {
        setTimeout(() => {
          if (!!selectOptionsResult) {
            this.handleSelectOptionsResult(selectOptionsResult);
          }
          if (!!selectViewsResult) {
            this.handleSelectViewsResult(selectViewsResult);
          }
          if (!!selectSuppliersResult) {
            this.handleSelectSuppliersResult(selectSuppliersResult);
          }
          if (!!selectActiveJobsResult) {
            this.handleSelectActiveJobsResult(selectActiveJobsResult);
          }
          if (!!selectOptionsResult && !!selectSuppliersResult && !!selectActiveJobsResult && !!selectViewsResult) {
            this.createFasterLookup();
            this.dropdownOptions$.next(this.dropdownOptions);
          }
        }, 0);
      });
  }

  createFasterLookup() {
    if (!(this.dropdownOptions as any)['faster']) {
      (this.dropdownOptions as any)['faster'] = {} as any;
    }
    let ddownopts = TableConstants.fastDropDownOptions;
    let faster = (this.dropdownOptions as any).faster;

    Object.keys(this.dropdownOptions).forEach((key) => {
      const cur = (this.dropdownOptions as any)[key];
      if (key == 'status') {
        let curdic = {};
        cur.forEach((dict: any, index: number) => {
            // map of id to order
            (curdic as any)[dict.id] = index;
        });
        (this.dropdownOptions as any).status_ordering = curdic;
      }
      if (ddownopts.includes(key)) {
        
        if (!cur) return;
        let curdic = {};
        cur.forEach((dict: any) => {
            // Process each dictionary
            cur.forEach((dict: any) => {
              // Process each dictionary
              if (key == 'supplier_id') {
                let valueExists = Object.values(curdic).includes(dict.value);
                if (!valueExists) { (curdic as any)[dict.id] = dict.value; }
              } else {
                (curdic as any)[dict.id] = dict.value;
              }

          });
        });
        if (key == 'job_id') {
          let job_copy = deepCopy(cur);
          job_copy.unshift({id: null, value: '--'});
          faster['job_array'] = job_copy;
        }
        faster[key] = curdic;
      }
    });
  }

  private handleSelectOptionsResult(selectOptionsResult: any) {
    this.dropdownOptions = {
      ...this.dropdownOptions,
      category: selectOptionsResult?.data?.categories,
      room_id: selectOptionsResult?.data?.rooms,
      next_supplier_check_in: selectOptionsResult?.data?.next_supplier_check_in,
      floor: selectOptionsResult?.data?.floors,
      delivery_method: selectOptionsResult?.data?.delivery_method,
      delivery_method_ordering: selectOptionsResult?.data?.delivery_method.map((item:any)=>{
        return {'id': item.id, 'value': selectOptionsResult?.data?.delivery_method.findIndex((element:any)=> element.id === item.id)};
      }),
      status: selectOptionsResult?.data?.status,
      status_ordering: selectOptionsResult?.data?.status.map((item:any)=>{
        return {'id': item.id, 'value': selectOptionsResult?.data?.status.findIndex((element:any)=> element.id === item.id)};
      }),
      status_colors: selectOptionsResult?.data?.status_colors,
      payment_method: selectOptionsResult?.data?.payment_methods,
      in_stock_ship_tags: selectOptionsResult?.data?.in_stock_ship_tags,
      shipping_to: selectOptionsResult?.data?.shipping_to,
      is_reimbursable: selectOptionsResult?.data?.yes_no_not_selected,
      shipping_state: selectOptionsResult?.data?.states,
      shipping_country: selectOptionsResult?.data?.countries,
      supplier_contact_role: selectOptionsResult?.data?.supplier_roles,
      reason_to_not_display: selectOptionsResult?.data?.reason_to_not_display,
      privacy: selectOptionsResult?.data?.privacy,
      document_types: selectOptionsResult?.data?.document_types,
      shared_updates_frequency: selectOptionsResult?.data?.shared_updates_frequency,
      unit_of_measure: selectOptionsResult?.data?.unit_of_measure,
      item_com_selection: selectOptionsResult?.data?.item_com_selection,
      supplier_documents_types: selectOptionsResult?.data?.supplier_documents_types,
      track_order: selectOptionsResult?.data?.track_order,
      state: selectOptionsResult?.data?.states,
      country: selectOptionsResult?.data?.countries,
      track_job: selectOptionsResult?.data?.track_job,
      weekly_checkin: selectOptionsResult?.data?.weekly_checkin,
    };

    this.privacyOptions$.next(selectOptionsResult?.data?.privacy);
    if (selectOptionsResult?.data?.history_interval) {
      this.historyOptions$.next(selectOptionsResult?.data?.history_interval.slice(1));
    }
    this.viewsOptions$.next({
      views_fields: selectOptionsResult?.data?.views_fields,
      filter_types: selectOptionsResult?.data?.filter_types,
      main_operators: selectOptionsResult?.data?.main_operators,
      operators: selectOptionsResult?.data?.operators,
      views_sort_fields: selectOptionsResult?.data?.views_sort_fields,
    });
  }

  private handleSelectSuppliersResult(selectSuppliersResult: any): void {
    if (Array.isArray(selectSuppliersResult.data.results)) {
      const suppliersList = JSON.parse(JSON.stringify(selectSuppliersResult?.data.results));
      const supplierContactsList: any = [];

      suppliersList?.map((supplier: any) => {
        supplier.value = supplier.name;
        if (supplier.contacts) {
          supplier.contacts.map((item: any) => {
            item.supplier_id = supplier.id;
          })
          supplierContactsList.push(supplier.contacts);
        }
      });
      supplierContactsList?.flat().map((supplierContact: any) => {
        supplierContact.value = supplierContact.name;
      });

      delete (this.dropdownOptions as any).supplier_id;
      this.dropdownOptions = {
        ...this.dropdownOptions,
        supplier_id: suppliersList,
        supplier_contact_id: supplierContactsList?.flat()
      };
    }
  }

  getOrderName(item:any){
    if(item && item.supplier_documents_numbers && item.supplier_documents_numbers[0] && item.supplier_documents_numbers[0].number){
      return item.supplier_documents_numbers[0].number
    }
    if(item && item.po_document_number && item.po_document_number.number){
      return item.po_document_number.number
    }
  }


  private handleSelectViewsResult(selectViewsResult: any): void {
    if(selectViewsResult == null || selectViewsResult.data == null){
      return;
    }

    let itemid = new Map();
    let orderid = new Map();
    let supplierid =  new Map();
    let ponumber = new Map();
    if (Array.isArray(selectViewsResult.data.results)) {
      let orderItemList: any[] = [];
      let orderList: any[] = [];
      let supplierList: any[] = [];
      let ponumberList: any[] = [];
      selectViewsResult.data.results.map((item: any) => {
        if(item.id && !itemid.has(item.id)){
          itemid.set(item.id,"");
          orderItemList.push({'id': item.id,'value': item.id })
        }

        if(item.order_id && !orderid.has(item.order_id)){
          orderid.set(item.order_id,"");
          orderList.push({'id': item.order_id, 'value': item.order_id })
        }

        if(item.supplier_id && !supplierid.has(item.supplier_id)){
          supplierid.set(item.supplier_id,"");
          supplierList.push({'id': item.supplier_id, 'value': item.supplier_id })
        }
        //build the po number list
        if(item.po_document_number && !ponumber.has(item.po_document_number.id)){
          ponumber.set(item.po_document_number.id,"");
          if(item.po_document_number.number) {
            ponumberList.push({ 'id': item.po_document_number.id, 'value': item.po_document_number.number })
          }
        }


      });
      ponumberList = ponumberList.sort((a:any, b:any) => {
        if (a.value === undefined && b.value === undefined) {
          return 0; // both values are null, consider them equal
        } else if (a.value === undefined) {
          return -1; // a is null, so it should come before b
        } else if (b.value === undefined) {
          return 1; // b is null, so a should come before it
        }
        return a.value.localeCompare(b.value); // standard string comparison
      });
      this.dropdownOptions = {
        ...this.dropdownOptions,
        id: orderItemList,
        order_id: orderList,
        supplier_id_2: supplierList,
        po_document_number: ponumberList
      };
    }
  }

  private handleSelectActiveJobsResult(selectActiveJobsResult: any) {
    this.activeJobsList = selectActiveJobsResult?.data;
    const activeJobsList = JSON.parse(JSON.stringify(this.activeJobsList));

    activeJobsList?.map((activeJob: any) => {
      activeJob.value = activeJob.name;

      return activeJob;
    });

    this.dropdownOptions = {
      ...this.dropdownOptions,
      job_id: activeJobsList
    };
  }
}

<div class="grouping">
  <span
    id="show-groups-modal-button"
    (click)="showFieldsModal($event)" [ngClass]="isLoading || !!isGrouping ? 'grouping-default' : 'grouping-background'">
    <fa-icon
      [icon]="faGroup"
      [ngClass]="isLoading ? 'grouping__icon' : null"
    ></fa-icon>
    {{  groupText }}
  </span>

  <div #groupDiv
    class="grouping__container-grouping"
    *ngIf="toggleFieldsModal"
    (outsideClick)="clickOutsideModal($event)"
  >
    <div class="grouping__container-grouping-header">Group fields</div>


    <p-divider></p-divider>
    <div >
      <input
        class="px-2 py-1 rounded-md text-xs border border-neutral-300 w-5/6"
        type="text"
        id="filter-group-field"
        placeholder="Search..."
        (input)="onFilterGroupFieldsChanged()"
      />

    </div>
    <p-divider></p-divider>
    <div class="grouping__container-grouping-footer">
      <button class="grouping__container-grouping-footer-button" type="button" (click)="ungroupAll()">Ungroup all</button>
      <!--<button class="grouping__container-fields-footer-button" type="button" (click)="reset()">Reset</button>-->
    </div>
    <div class="grouping__container-grouping-container">
      <div class="grouping__container-grouping-content" *ngFor="let field of fields | orderBy: 'order'; trackBy: trackByFn">
        <div class="grouping__container-grouping-content-item">
          <ng-container *ngIf="field.field !== 'index' && inSearchTerm(field.header)">
            <p-inputSwitch
              styleClass="grouping__container-grouping-content-item-switch"
              [(ngModel)]="field.group"
              (ngModelChange)="onModelChange($event)"
              (onChange)="triggerFieldsChange()"
            ></p-inputSwitch>
            <p class="grouping__container-grouping-content-item-text">{{ field.header }} {{field.order<1000? '(' + (field.order+1) +')':''}}</p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { select, Store } from '@ngrx/store';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';

@Injectable()
export class PermissionsService {
  constructor(
    public router: Router,
    private store: Store<IApplicationState>,
  ) { }

  public isLoggedIn(): boolean {
    return localStorage.getItem(CommonConstants.AUTH_TOKEN) !== null;
  }

  public logout(): void {
    localStorage.clear();
    this.store.dispatch({ type: 'CLEAR_STATE' });
    this.router.navigate(['login']);
  }
}

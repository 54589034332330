import { IOptions } from "src/app/authentication/models/authentication.model";
import { IStoreApiItem, StoreApiItem } from "src/app/common/models/store-api-item.model";
import { IStoreApiList, StoreApiList } from "src/app/common/models/store-api-list.model";

export interface ISuppliersPageState {
  suppliersPage: IStoreApiList<any>;
  userOptions: IStoreApiItem<IOptions>;
}

export class SuppliersPageState implements ISuppliersPageState {
  suppliersPage: IStoreApiList<any> = new StoreApiList();
  userOptions: IStoreApiItem<IOptions> = new StoreApiItem();
}

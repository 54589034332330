import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";

import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { CommonConstants } from "src/app/common/constants/common.constants";
import { OnDestroyMixin } from "src/app/common/mixins/destroy-mixin";
import { getDateWithAPIFormat } from "src/app/common/utils/date-utils/date.utils";
import { DashboardActions } from "src/app/dashboard/state/actions/dashboard.actions";
import { IFieldsToFilter } from "../../interfaces/fields-to-filter.interface";
import { FilterItemPayload } from "../../interfaces/filter-item-payload.interface";
import { IFilterOperator } from "../../interfaces/filter-operator.interface";
import { IViewFieldOperator } from "../../interfaces/view-field-operator.interface";
import { IViewField } from "../../interfaces/view-field.interface";
import { IViewOptions } from "../../interfaces/view-options.interface";
import { SelectedViewModel } from "../../models/selected-view.model";
import { deepCopy, deepArrayClone } from "../../../common/utils/general";
import { JobTableService } from "../../services/job-table.service";
import moment from "moment";
import { TableConstants } from "../../../common/constants/table.constants";
import { JobTableV2Component } from "../job-table-v2/job-table-v2.component";


@Component({
  selector: "mlogfilters",
  templateUrl: "./mlog-filters.component.html",
  styleUrls: ["./mlog-filters.component.sass"],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MlogFiltersComponent extends OnDestroyMixin() implements OnChanges {
  @Input() selectedView?: SelectedViewModel;
  @Input() activeJob: any;
  @Input() viewsOptions!: IViewOptions | null;
  @Input() dropdownOptions!: any;
  @Input() isLoading!: boolean;
  @Input() mailBoxVisible: boolean = false;

  faFilter = faFilter;
  CommonConstants = CommonConstants;

  isAddFilterStateEnabled: boolean = false;
  toggleFiltersModal: boolean = false;
  selectedColumns: any = [];
  fields: any = [];
  mainOperator: any;
  mainOperators = [];
  filterArrays: FilterItemPayload[] = [{
    key: "",
    operator: null,
    value: []
  }];
  fieldsToFilterArray: any = [];
  private mapFromfrontEnd = new Map();

  constructor(
    private dashboardActions: DashboardActions,
    private jobtableService: JobTableService,
    private datePipe: DatePipe
  ) {
    super();
    (window as any)['filt'] = this;

    for (let i = 0; i < TableConstants.jobItemsTableColumns.length; i++) {
      let column = TableConstants.jobItemsTableColumns[i];
      if (column.field != "index") {
        this.mapFromfrontEnd.set(column.field, column);
      }
    }
  }

  get filterText() {
    if (this.mailBoxVisible) return '';
    return !!this.nrOfFilters && !this.isLoading
      ? this.nrOfFilters + (this.nrOfFilters === 1
          ? ' filter' : ' filters') : 'Filter';
  }

  get nrOfFilters(): number {
    if (this.selectedView?.activeView) {
      let nr = this.selectedView.activeView.get_filters();
      return nr ? nr.length : 0;
    }
    return 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.selectedView?.currentValue && !!this.selectedView) {
      //reset fields
      this.fields = [];
      this.selectedView.selectedColumnsToFilter.forEach((item: any) => {
        //make sure only in use columns in play....
        if (this.mapFromfrontEnd.has(item.field)) {
          this.fields.push(item);
        }
      });

      //this is for adding new columns into the saved view etc.
      let mapFromBackend = new Map();
      this.fields.forEach((item: { field: any; }) => {
        mapFromBackend.set(item.field, item);
      });
      TableConstants.jobItemsTableColumns.forEach(item => {
        if (item.field != "index") {
          if (!mapFromBackend.has(item.field)) {
            this.fields.push(deepCopy(item));
          }
        }
      });
      //use latest frontend header info instead of whats coming from backend...
      this.fields.forEach((item: { field: any; header: any; }) => {
        if (this.mapFromfrontEnd.has(item.field)) {
          item.header = this.mapFromfrontEnd.get(item.field).header;
        }
      });


      if (this.viewsOptions?.main_operators) {
        this.mainOperators = deepCopy(this.viewsOptions.main_operators);
      }
      if (this.selectedView?.activeView) {
        let main_operators = this.selectedView.activeView.get_main_operator();
        this.mainOperator =  main_operators ? deepCopy(main_operators) : 0;
      }
      this.setFilterArrays(
        this.selectedView?.activeView?.get_filters()?.length
          ? JSON.parse(JSON.stringify(this.selectedView?.activeView?.get_filters()))
          : [{ key: "", operator: null, value: [] }]
      );
    }
    this.resetUsedFieldsInChoiceMenu();
  }

  resetUsedFieldsInChoiceMenu(): void {
    this.fieldsToFilterArray = this.fields?.map((field: IFieldsToFilter) => {
      field.disabled = !!this.filterArrays.find((filterField: any) => filterField.key === field.field);
      return field;
    });
  }


  getOptions(key: string) {
    //goal is to only show suppliers in the filter view from the items in the views
    if (key == "supplier_id") {
      this.dropdownOptions.supplier_id_limited = this.dropdownOptions.supplier_id_2.map((item: any) => {
        return this.dropdownOptions["supplier_id"]?.find(
          (dropdownItem: any) => dropdownItem?.id === item.id
        );
      }).sort((a:any, b:any) => a.value.localeCompare(b.value));
      return this.dropdownOptions["supplier_id_limited"];
    }


    if (key == "supplier_contact_id") {

      this.dropdownOptions.supplier_contact_id_limited = this.dropdownOptions.supplier_id_2.map((item: any) => {
        return this.dropdownOptions["supplier_contact_id"]?.find(
          (dropdownItem: any) => dropdownItem?.supplier_id === item.id
        );
      }).sort((a:any, b:any) => a.value.localeCompare(b.value));
      return this.dropdownOptions["supplier_contact_id_limited"].filter((item:any) => item !== undefined);
    }

    if (key == 'room_id') {
      return this.dropdownOptions.room_id.filter((r: any) => r.job == this.activeJob.id);
    }

    return this.dropdownOptions[key];
  }


  private setFilterArrays(filters: any): void {
    if (filters?.length) {
      this.filterArrays = deepCopy(filters);
      this.setMultiSelectModel();
    }
    this.isAddFilterStateEnabled = this.filterArrays[0].key !== "";
  }

  onChangeValue(event: any) {
    this.sendBackendFilterInformation();
    if (event.value && event.value === "string" && event.value.trim() !== "" && event.value.length !== 0) {
      this.isAddFilterStateEnabled = true;
    }

  }

  onChangeDate(index: number): void {

    this.filterArrays[index].value[0] = this.datePipe.transform(this.filterArrays[index].value[0], "yyyy-MM-dd");
    this.isAddFilterStateEnabled = true;
    this.sendBackendFilterInformation();
  }

  showFiltersModal(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.toggleFiltersModal = !this.toggleFiltersModal;
    this.resetUsedFieldsInChoiceMenu();
  }

  clickOutsideModal(event: any) {
    if (!this.toggleFiltersModal) return;
    event.stopPropagation();
    event.preventDefault();
    this.toggleFiltersModal = false;
    this.isAddFilterStateEnabled = this.filterArrays[0].key !== "";
    this.filterArrays = this.selectedView?.activeView?.get_filters()?.length
      ? JSON.parse(JSON.stringify(this.selectedView?.activeView?.get_filters()))
      : [{ key: "", operator: null, value: [] }];

    this.setMultiSelectModel();
  }

  onClearAllFilters() {
    this.onResetFilterArray();
  }

  setDateModel(value: string) {
    if(value == undefined){
      return  value;
    }
    //fix to utc thing when string mapper would get utc changed date...
    return getDateWithAPIFormat(moment(value).toDate());
  }

  onAddFilterItem() {

    this.filterArrays.push({
      key: "",
      operator: null,
      value: []
    });
    this.isAddFilterStateEnabled = false;
  }

  onDeleteFilterItem(index: number) {
    this.filterArrays.splice(index, 1);

    if (!this.filterArrays.length) {
      this.onResetFilterArray();
    } else {
      this.sendBackendFilterInformation();
    }
  }

  get filters(): FilterItemPayload[] {
    return deepCopy(this.filterArrays)?.map((item: FilterItemPayload, index: number) => {
      item.position = index;

      return item;
    });
  }

  onResetFilterArray() {
    this.filterArrays = [
      {
        key: "",
        operator: null,
        value: []
      }
    ];

    this.selectedView?.activeView.set_filters([]);
    this.resetUsedFieldsInChoiceMenu();
    this.dashboardActions.requestSaveView("filters", [], this.selectedView?.activeView.id);
    this.jobtableService.setMatchFilter([]);
  }


  onChangeKey(eventValue: any, index: number) {
    this.resetUsedFieldsInChoiceMenu();
    this.isAddFilterStateEnabled = false;
    this.filterArrays[index] = {
      key: eventValue,
      operator: null,
      value: []
    };
  }

  onChangeMainOperator(event: any) {
    this.mainOperator = event;

    this.dashboardActions.requestSaveView("main_operator", this.mainOperator, this.selectedView?.activeView.id);

    this.jobtableService.setMatchFilterOperators(this.mainOperator);


  }

  onChangeOperator(operator: number) {

    if (operator !== 4 && operator !== 5) {
      this.isAddFilterStateEnabled = false;
    } else {
      this.sendBackendFilterInformation();
    }
  }

  getOperators(fieldName: string): IFilterOperator[] {
    const operatorsForFiled = this.viewsOptions?.views_fields?.find((filed: IViewField) => filed.name === fieldName)?.operators;

    return this.viewsOptions?.operators?.filter(
      (operator: IFilterOperator) => !!operatorsForFiled?.find(
        (elem: IViewFieldOperator) => operator.id === elem.id));
  }

  getFilterType(fieldName: string, selectedOperator: number): string {
    const operatorsForFiled = this.viewsOptions?.views_fields?.find((filed: IViewField) => filed.name === fieldName)?.operators;
    return this.viewsOptions?.filter_types?.find(
      (operator: IFilterOperator) => !!operatorsForFiled?.find(
        (elem: IViewFieldOperator) => operator.id === elem.type && selectedOperator === elem.id))?.value;


  }

  sendBackendFilterInformation(): void {

    let ok_to_save = true;

    if (this.filters) {

      for (let i = 0; i < this.filters.length; i++) {

        if (this.filters.length > 0 &&
          //for contains, need something in it to be able to filter
          ([0, 1, 2, 3, 6, 7, 8, 9].includes(this.filters[i].operator) && this.filters[i].value && this.filters[i].value.length > 0 && this.filters[i].value.toString().trim().length > 0) ||
          (this.filters[i].operator == 4 || this.filters[i].operator == 5)
        ) {

        } else {

          ok_to_save = false;
        }
      }

    } else {
      this.dashboardActions.requestSaveView("filters", [], this.selectedView?.activeView.id);
      this.jobtableService.setMatchFilter([]);

      this.isAddFilterStateEnabled = false;
    }

    if (ok_to_save) {
      //let offset = this.filters.length - 1;
      {
        this.dashboardActions.requestSaveView("filters", this.filters, this.selectedView?.activeView.id);
        this.jobtableService.setMatchFilter(deepCopy(this.filters));
        this.isAddFilterStateEnabled = true;
      } /*else {

        // let prefilter = deepCopy(this.filters);
        this.dashboardActions.requestSaveView("filters", [], this.selectedView?.activeView.id);
        this.jobtableService.setMatchFilter([]);

        this.isAddFilterStateEnabled = false;


        //TODO copy back all prefilter stuff...
      }*/
      //when we get it working....
    }

  }

  onApplySearchBox(): void {
    // this.dashboardActions.requestSearchBox();
  }

  private setMultiSelectModel(): void {
    this.filterArrays.map((filterArray: any) => {
      if (this.getFilterType(filterArray.key, filterArray.operator) === CommonConstants.filterTypes.SELECT &&
        filterArray.key !== "job_id" &&
        filterArray.key !== "room_id" &&
        filterArray.key !== "supplier_id" &&
        filterArray.key !== "supplier_id_2" &&
        filterArray.key !== "po_document_number" &&
        filterArray.key !== "order_id" &&
        filterArray.key !== "order_item_id" &&
        filterArray.key !== "supplier_contact_id") {
        filterArray.value = filterArray.value.map((value: any) => {
          return parseInt(value, 10);
        });
      }
    });
  }

  emptyTemplateClick(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

}

import { SharedViewActionTypes } from '../shared-view-action-types';
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

export class RequestSharedViewPage implements Action {
  readonly type: SharedViewActionTypes.REQUEST_SHARED_VIEW_PAGE = SharedViewActionTypes.REQUEST_SHARED_VIEW_PAGE;

  constructor(
    public payload?: any) { }
}
export class ReceiveSharedViewPage implements Action {
  readonly type: SharedViewActionTypes.RECEIVE_SHARED_VIEW_PAGE = SharedViewActionTypes.RECEIVE_SHARED_VIEW_PAGE;

  constructor(
    public payload?: any) { }
}
export class ErrorReceiveSharedViewPage implements Action {
  readonly type: SharedViewActionTypes.ERROR_RECEIVE_SHARED_VIEW_PAGE = SharedViewActionTypes.ERROR_RECEIVE_SHARED_VIEW_PAGE;

  constructor(
    public payload?: HttpErrorResponse) { }
}

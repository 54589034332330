import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RequestActiveJobs } from './dashboard-action-types-creators/activeJobs-action-types.creators';
import {
  INewOrder,
  ISuppliers,
  ISupplierContacts,
  INewJob,
  INewItem,
  ISupplierCredentials
} from 'src/app/dashboard/models/dashboard.model';
import { IDashboardState } from '../models/dashboard.model';
import { RequestAddSupplierContact, RequestAddSuppliers, RequestAutocompleteSuppliers,
  RequestSuppliersSmall, RequestSuppliers, ResetSupplierCRUDState } from './dashboard-action-types-creators/suppliers-action-types.creators';
import {
  RequestAddJob,
  RequestInputMessagesList,
  RequestJobList,
  RequestUpdateJob,
  ResetJobCRUDState
} from "./dashboard-action-types-creators/jobs-action-types.creators";
import { RequestOptions } from './dashboard-action-types-creators/options-action-types.creators';
import { RequestAddOrder, RequestAddRoom, RequestOrders, RequestUpdateOrder } from './dashboard-action-types-creators/orders-action-types.creators';
import { RequestAddReceiver, RequestAddReceiverContact, RequestAutocompleteReceivers, RequestReceivers, ResetReceiverCRUDState } from './dashboard-action-types-creators/receivers-action-types.creators';
import { RequestAddItem, RequestDeleteItem, RequestDeleteItemReason, RequestUpdateItem } from './dashboard-action-types-creators/items-action-types.creators';
import { RequestActiveOrders } from './dashboard-action-types-creators/activeOrders-action-types-creators';
import {
  RequestViews,
  RequestUncategorizedView,
  RequestUncategorizedViewBool,
  RequestUpdateViewCell,
  RequestAttachmentDelete,
  RequestAddView,
  RequestUpdateView,
  RequestDeleteView,
  RequestSaveView
} from "./dashboard-action-types-creators/views-action-types.creators";
import { RequestActiveTemplates, RequestAddTemplate, RequestUpdateTemplate } from './dashboard-action-types-creators/templates-action-types.creators';
import { RequestAddOrderDocument, RequestDeleteOrderDocument, RequestOrderDocument, RequestUpdateOrderDocument } from './dashboard-action-types-creators/orderDocument-action-types.creators';
import { RequestDeleteShareView, RequestDeleteShareViewEmail, RequestShareView, RequestUpdateShareView, RequestUpdateShareViewEmail } from './dashboard-action-types-creators/shareView-action-types-creators';
import {
  RequestAddSupplierCredentials,
  RequestSupplierCredentials, ResetSupplierCredentialsCRUDState
} from "./dashboard-action-types-creators/supplier-credentials-action-types.creators";
import { DashboardService } from "../../services/dashboard.service";
import { Observable } from "rxjs";
import { deepCopy } from "../../../common/utils/general";
import { HttpErrorResponse } from '@angular/common/http';
import { RequestAddComment } from './dashboard-action-types-creators/comments-action-types.creators';


@Injectable({ providedIn: 'root' })
export class DashboardActions {
  constructor(
    private dashboardService: DashboardService,
    private store: Store<IDashboardState>) { }

  public requestReceivers(params?: any): void {
    this.store.dispatch(new RequestReceivers(params));
  }

  public requestOptions(params?: any): void {
    this.store.dispatch(new RequestOptions(params));
  }

  public requestAddJob(newJob: INewJob): any {
    this.store.dispatch(new RequestAddJob(newJob));
  }

  public requestJobList(params?: any): any {
    this.store.dispatch(new RequestJobList(params));
  }

  public requestInputMessages(params?: any): any {
    this.store.dispatch(new RequestInputMessagesList(params));
  }


  public requestUpdateJob(job: any): void {
    this.store.dispatch(new RequestUpdateJob(job));
  }

  public requestActiveJobs(params?: any): void {
    this.store.dispatch(new RequestActiveJobs(params));
  }

  public resetJobCRUDState(): any {
    this.store.dispatch(new ResetJobCRUDState());
  }

  public requestAddOrder(params: INewOrder): void {
    this.store.dispatch(new RequestAddOrder(params));
  }

  public requestAddRoom(params: any): void {
    this.store.dispatch(new RequestAddRoom(params));
  }

  public requestSuppliers(params?: any): void {
    this.store.dispatch(new RequestSuppliers(params));
  }

  public requestSuppliersSmall(params?: any): void {
    this.store.dispatch(new RequestSuppliersSmall(params));
  }

  public requestSupplierCredentials(params?: any): void {
    this.store.dispatch(new RequestSupplierCredentials(params));
  }

  public requestAddReceiver(params: any): void {
    this.store.dispatch(new RequestAddReceiver(params));
  }

  public requestAddReceiverContact(params: any): void {
    this.store.dispatch(new RequestAddReceiverContact(params));
  }

  public resetReceiverCRUDState(): any {
    this.store.dispatch(new ResetReceiverCRUDState());
  }

  public requestAddSuppliers(newSupplier: ISuppliers): void {
    this.store.dispatch(new RequestAddSuppliers(newSupplier));
  }

  public requestAddSupplierCredentials(newSupplierCredentials: ISupplierCredentials): void {
    this.store.dispatch(new RequestAddSupplierCredentials(newSupplierCredentials));
  }


  public requestAddSupplierContact(newSupplierContact: ISupplierContacts): void {
    this.store.dispatch(new RequestAddSupplierContact(newSupplierContact));
  }

  public resetSuppliersCRUDState(): any {
    this.store.dispatch(new ResetSupplierCRUDState());
  }


  public resetSupplierCredentialsCRUDState(): any {
    this.store.dispatch(new ResetSupplierCredentialsCRUDState());
  }

  public requestAddItem(newItem: INewItem): void {
    this.store.dispatch(new RequestAddItem(newItem));
  }

  public requestActiveOrders(params?: any): void {
    this.store.dispatch(new RequestActiveOrders(params));
  }

  public requestOrders(params?: any): void {
    this.store.dispatch(new RequestOrders(params));
  }

  public requestViews(params?: any): void {
    this.store.dispatch(new RequestViews(params));
  }

  public requestAddView(params: any): void {
    this.store.dispatch(new RequestAddView(params));
  }

  public requestAddViewReturn(params: any): Observable<any> {
    return this.dashboardService.addView({ "payload": params});
  }

  public requestUpdateView(view: any): void {
    this.store.dispatch(new RequestUpdateView(view));
  }

  public requestUpdateViewReturn(view: any): void {
    let data = {
      ...view,
      NameUpdate: true,
    }
    this.dashboardService.updateView({'payload': data}).subscribe(val => {});
  }

  public requestUpdateViewObs(data: any): Observable<any> {
    return this.dashboardService.updateView({'payload': data});
  }

  public requestSingleView(data: any): Observable<any> {
    return this.dashboardService.getSingleView(data);
  }

  public requestSaveView(key: string, value: any, id: string | undefined): void {
    let data = {
        selectedViewId: id
    };
    // @ts-ignore
    data[key] = deepCopy(value);
    this.dashboardService.saveView({ "payload": data}).subscribe(val => {});
  }

  public requestSaveViewReturn(key: string, value: any, which: string, id: string | undefined):Observable<any> {
    let data = {
      selectedViewId: id,
      which: which
    };
    // @ts-ignore
    data[key] = JSON.parse(JSON.stringify(value));
    return this.dashboardService.saveView({ "payload": data});
    //this.store.dispatch(new RequestSaveView(view));
  }

  public requestDeleteView(view: any): void {
    this.store.dispatch(new RequestDeleteView(view));
  }

  public requestDeleteViewReturn(view: any): Observable<any> {
    return this.dashboardService.deleteView(view);
  }

  public requestDeleteViewAndNewView(data: any): Observable<any> {
    return this.dashboardService.deleteViewAndNewView(data);
  }

  public requestUpdateViewCell(view: any): void {
    this.store.dispatch(new RequestUpdateViewCell(view));
  }

  public requestUncategorizedView(params?: any): void {
    this.store.dispatch(new RequestUncategorizedView(params));
  }

  public requestUncategorizedViewBool(params?: any): void {
    this.store.dispatch(new RequestUncategorizedViewBool(params));
  }

  public requestAttachmentDelete(params: any): void {
    this.store.dispatch(new RequestAttachmentDelete(params));
  }

  public requestDeleteItem(params: any): void {
    this.store.dispatch(new RequestDeleteItem(params));
  }

  public requestUpdateItem(params: any): void {
    this.store.dispatch(new RequestUpdateItem(params));
  }

  public requestDeleteItemReason(params: any): void {
    this.store.dispatch(new RequestDeleteItemReason(params));
  }

  public requestActiveTemplates(): void {
    this.store.dispatch(new RequestActiveTemplates());
  }

  public requestAddTemplate(params: any): void {
    this.store.dispatch(new RequestAddTemplate(params));
  }

  public requestUpdateTemplate(params: any): void {
    this.store.dispatch(new RequestUpdateTemplate(params));
  }

  public requestOrderDocument(params: any): void {
    this.store.dispatch(new RequestOrderDocument(params));
  }

  public requestAddOrderDocument(params: any): void {
    this.store.dispatch(new RequestAddOrderDocument(params));
  }

  public requestUpdateOrderDocument(params: any): void {
    this.store.dispatch(new RequestUpdateOrderDocument(params));
  }

  public requestDeleteOrderDocument(params: any): void {
    this.store.dispatch(new RequestDeleteOrderDocument(params));
  }

  public requestShareView(params?: any): void {
    this.store.dispatch(new RequestShareView(params));
  }

  public requestUpdateShareView(params: any): void {
    this.store.dispatch(new RequestUpdateShareView(params));
  }

  public requestDeleteShareView(params: any): void {
    this.store.dispatch(new RequestDeleteShareView(params));
  }

  public requestUpdateShareViewEmail(params: any): void {
    this.store.dispatch(new RequestUpdateShareViewEmail(params));
  }

  public requestDeleteShareViewEmail(params: any): void {
    this.store.dispatch(new RequestDeleteShareViewEmail(params));
  }

  public requestAutocompleteSuppliers(params: any): void {
    this.store.dispatch(new RequestAutocompleteSuppliers(params));
  }

  public requestAutocompleteReceivers(params: any): void {
    this.store.dispatch(new RequestAutocompleteReceivers(params));
  }

  public requestUpdateOrder(params: any): void {
    this.store.dispatch(new RequestUpdateOrder(params));
  }

  public requestAddComment(params: any): void {
    this.store.dispatch(new RequestAddComment(params));
  }
}

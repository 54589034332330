import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { CustomErrorService } from 'src/app/dashboard/services/custom-error.service';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { selectOrderDocument } from 'src/app/dashboard/state/selectors/dashboard.selector';
import { OptionsService } from 'src/app/dashboard/services/options.service';

@Component({
  selector: 'orders-readonly-cell-renderer',
  templateUrl: './orders-readonly-cell-renderer.component.html',
  styleUrls: ['orders-cell-renderer.component.sass'],
})

export class OrdersReadonlyCellRenderer implements ICellRendererAngularComp {
  public params!: ICellRendererParams;
  readonly faEye = faEye;

  id!: string;
  selectedOrder: any = null;
  showOrderDialog: boolean = false;
  isPo: boolean = false;
  orderOptions: any;
  selectedRowId: any;
  isSharedView: boolean = false;
  isSharedEditable: boolean = false;

  constructor(
    private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private cdr: ChangeDetectorRef,
    private customErrorService: CustomErrorService,
    readonly optionsService: OptionsService,
  ) { }

  agInit(params: ICellRendererParams<any, number>): void {
    this.params = params;
    this.isSharedView = this.params.context.isSharedView;
    this.isSharedEditable = this.params.context.editable;
  }

  refresh(params: ICellRendererParams) {
    return false;
  }

  deleteOrder(orderId: any, row: any) {
    this.requestOrderDocumentChange({ deleteOrder: { order_item_id: row.id, order_id: orderId } });
  }

  private requestOrderDocumentChange(event: any) {
    if (event?.updateOrder) {
      this.dashboardActions.requestUpdateOrderDocument({ ...event.updateOrder });
    } else if (event?.addOrder) {
      this.dashboardActions.requestAddOrderDocument({ ...event.addOrder });
    } else if (event?.deleteOrder) {
      this.dashboardActions.requestDeleteOrderDocument({ ...event.deleteOrder });
    }
  }

  openOrderModal(orderId: any, isPo: boolean, itemId?: any) {
    this.showOrderDialog = true;
    this.isPo = isPo;
    this.selectedRowId = itemId;
    if (this.isSharedView) {
      if (!this.isSharedEditable) return;
      this.orderOptions = this.params.context.document_types;
    } else {
      this.optionsService.dropdownOptionsObs.subscribe((dropdownOptions: any) => {
        this.orderOptions = JSON.parse(JSON.stringify(dropdownOptions.document_types));
      }).unsubscribe();
    }
    if (!this.orderOptions) return;
    this.orderOptions.splice(1, 1);

    if (orderId != null) {
      this.onRequestOrderDocument(orderId);
    } else {
      this.selectedOrder = null;
    }
  }

  onRequestOrderDocument(orderId: any) {
    this.dashboardActions.requestOrderDocument(orderId);
    this.store
      .pipe(
        select(selectOrderDocument),
        filter((orderDocument: IStoreApiItem<any>) => !orderDocument.isLoading),
        take(1)
      )
      .subscribe((orderDocument: any) => {
        if (orderDocument?.data) {
          this.selectedOrder = JSON.parse(JSON.stringify(orderDocument.data));
        }

        if (orderDocument?.errors) {
          this.customErrorService.setCustomErrorMessage(orderDocument);
          setTimeout(() => { this.showOrderDialog = false; this.cdr.markForCheck(); }, 500);
        }

        this.cdr.markForCheck();
      });
  }

  updatedOrderModal(event: any): void {
    this.requestOrderDocumentChange(event);
  }

  closeOrderModal(event: any) {
    this.showOrderDialog = event;
  }


  protected readonly console = console;
}

<div class="profile">
  <div class="profile__personal">
    <div class="profile__personal-photo">
      <fa-icon
        *ngIf="userSettings.image_profile === null || userSettings.image_profile === ''"
        class="user"
        [icon]="faUser"
      ></fa-icon>
      <img *ngIf="userSettings.image_profile !== null && userSettings.image_profile.length >= 1" [src]="image" />
      <label for="image">
        <fa-icon [icon]="faEdit"></fa-icon>
        <input
          type="file"
          hidden
          id="image"
          name="image"
          accept=".png, .jpeg, .jpg, .jfif, .pjpeg, .pjp, .svg, .webp, .bmp"
          (change)="fileBrowseHandler($any($event.target).files)"
          multiple
        />
      </label>
    </div>
    <div class="profile__personal-info">
      <div class="profile__personal-info-single">
        <div *ngIf="userSettings.first_name || userSettings.last_name" class="profile__personal-info-single-inputs">
          <p class="input name">
            {{ userSettings.first_name + ' ' + userSettings.last_name }}
          </p>
        </div>
        <button class="edit-button" type="button" (click)="openNameDialog()">
          <fa-icon class="edit" [icon]="faEdit"></fa-icon>
          {{ userSettings.first_name || userSettings.last_name ? 'Edit name' : 'Add name' }}
        </button>
      </div>
      <div class="profile__personal-info-single">
        <p class="input email">{{ userSettings.user_email }}</p>
      </div>
      <div class="profile__personal-info-single">
        <button class="edit-button" type="button" (click)="openPasswordDialog()">
          <fa-icon class="edit" [icon]="faEdit"></fa-icon>Update password
        </button>
      </div>
    </div>
  </div>
  <!-- <p-divider></p-divider>
  <div class="profile__address">
    <p>{{ userSettings.address || '' }}</p>
    <p>
      {{ complexAddress() }}
    </p>
    <p>{{ userSettings.phone || '' }}</p>
    <p>{{ userSettings.timezone?.length >= 1 ? 'Timezone: ' + userSettings.timezone : null }}</p>
    <div class="profile__address-action">
      <button class="edit-button" type="button" (click)="editAddress()">
        <fa-icon class="edit" [icon]="faEdit"></fa-icon>Edit address
      </button>
    </div>
  </div> -->
</div>

<p-dialog
  [(visible)]="showDialog"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [closable]="false"
  [closeOnEscape]="false"
  [position]="'top'"
  [style]="{ marginTop: '150px', width: '540px' }"
  [maskStyleClass]="'dialog-mask'"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span>Edit address</span>
    </div>
    <button class="dialog-close" type="button" (click)="closeDialog()"><i class="bi bi-x-lg"></i></button>
  </ng-template>

  <div class="dialog-supplier">
    <form class="dialog-supplier__content">
      <div class="dialog-supplier__content-item">
        <input
          class="form-controls"
          name="address"
          type="text"
          placeholder="Address"
          minlength="1"
          [(ngModel)]="userSettings.address"
        />
      </div>
      <div class="dialog-supplier__content-item">
        <input class="form-controls" name="city" type="text" placeholder="City" [(ngModel)]="userSettings.city" />
      </div>
      <div class="group">
        <div class="dialog-supplier__content-item">
          <p-dropdown
            appendTo="body"
            [options]="statesList"
            id="state"
            optionValue="id"
            optionLabel="value"
            placeholder="State"
            name="state"
            [showClear]="true"
            [(ngModel)]="userSettings.state"
          >
          </p-dropdown>
        </div>
        <div class="dialog-supplier__content-item">
          <p-dropdown
            appendTo="body"
            [options]="countriesList"
            id="country"
            optionValue="id"
            optionLabel="value"
            placeholder="Country"
            name="country"
            [showClear]="true"
            [(ngModel)]="userSettings.country"
          >
          </p-dropdown>
        </div>
      </div>
      <div class="dialog-supplier__content-item">
        <input class="form-controls" name="zip" type="text" placeholder="Zip" [(ngModel)]="userSettings.zip" />
      </div>
      <div class="dialog-supplier__content-item">
        <input class="form-controls" name="phone" type="tel" placeholder="Phone" [(ngModel)]="userSettings.phone" />
      </div>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <div class="dialog-supplier__content-action">
      <button class="dialog-supplier__content-action-button" type="submit" (click)="onSave()">Save</button>
    </div>
  </ng-template>
</p-dialog>

<form>
  <p-dialog
    [(visible)]="showNameDialog"
    [modal]="true"
    [resizable]="false"
    [draggable]="false"
    [closable]="false"
    [closeOnEscape]="false"
    [position]="'top'"
    [style]="{ marginTop: '150px', width: '500px' }"
    [maskStyleClass]="'dialog-mask'"
  >
    <ng-template pTemplate="header">
      <div class="dialog__header">
        <span>Edit name</span>
      </div>
      <button class="dialog-close" type="button" (click)="closeNameDialog()"><i class="bi bi-x-lg"></i></button>
    </ng-template>

    <ng-template pTemplate="content">
      <div class="dialog-supplier">
        <div class="dialog-supplier__content">
          <div class="dialog-supplier__content-item">
            <label for="modal_first_name">First name</label>
            <input
              class="form-controls"
              name="modal_first_name"
              id="modal_first_name"
              type="text"
              placeholder="First name"
              minlength="1"
              [(ngModel)]="userSettings.first_name"
            />
          </div>
          <div class="dialog-supplier__content-item">
            <label for="modal_last_name">Last name</label>
            <input
              class="form-controls"
              name="modal_last_name"
              id="modal_last_name"
              type="text"
              minlength="1"
              placeholder="Last name"
              [(ngModel)]="userSettings.last_name"
            />
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="dialog-supplier__content-action">
        <button class="dialog-supplier__content-action-button" type="submit" (click)="onSave()">Save</button>
      </div>
    </ng-template>
  </p-dialog>
</form>

<form>
  <p-dialog
    [(visible)]="showPasswordDialog"
    [modal]="true"
    [resizable]="false"
    [draggable]="false"
    [closable]="false"
    [closeOnEscape]="false"
    [position]="'top'"
    [style]="{ marginTop: '150px', width: '500px' }"
    [maskStyleClass]="'dialog-mask'"
  >
    <ng-template pTemplate="header">
      <div class="dialog__header">
        <span>Edit password</span>
      </div>
      <button class="dialog-close" type="button" (click)="closePasswordDialog()"><i class="bi bi-x-lg"></i></button>
    </ng-template>

    <ng-template pTemplate="content">
      <div class="dialog-supplier">
        <div class="dialog-supplier__content">
          <div class="dialog-supplier__content-item">
            <label for="modal_old_password">Old password</label>
            <input
              class="form-controls"
              name="modal_old_password"
              id="modal_old_password"
              type="password"
              placeholder="Old password"
              minlength="1"
              [(ngModel)]="passwordForm.old_password"
            />
          </div>
          <div class="dialog-supplier__content-item">
            <label for="modal_new_password1">New password</label>
            <input
              class="form-controls"
              name="modal_new_password1"
              id="modal_new_password1"
              type="password"
              minlength="1"
              placeholder="New password"
              [(ngModel)]="passwordForm.new_password1"
            />
          </div>
          <div class="dialog-supplier__content-item">
            <label for="modal_new_password2">Confirm new password</label>
            <input
              class="form-controls"
              name="modal_new_password2"
              id="modal_new_password2"
              type="password"
              minlength="1"
              placeholder="Confirm new password"
              [(ngModel)]="passwordForm.new_password2"
            />
          </div>
          <p *ngIf="isPasswordSubmited && isPasswordShort" class="textfield__error-message">
            Password must have at least 8 characters.
          </p>
          <p *ngIf="isPasswordSubmited && isPasswordDifferent && !isPasswordShort" class="textfield__error-message">
            New password and confirm password must be the same.
          </p>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="dialog-supplier__content-action">
        <button class="dialog-supplier__content-action-button" type="submit" (click)="editPassword()">Save</button>
      </div>
    </ng-template>
  </p-dialog>
</form>

<p-toast position="top-center" key="error"></p-toast>
<p-toast position="top-center" key="success"></p-toast>

import { Component, OnInit, ViewChild, ViewContainerRef, Input, ChangeDetectorRef, ComponentFactoryResolver } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { SelectedViewModel } from '../../models/selected-view.model';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { ElementRef, HostListener } from '@angular/core';
import { MailBoxCommunicationService } from '../../services/mailbox_comm.service';
import { ExportSubmittalsComponent } from '../submittals-export/submittals-export.component';
import { selectComments, selectViews} from '../../state/selectors/dashboard.selector';
import { selectActiveJobs } from '../../state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { CommentsService } from '../../services/comment.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { MentionModule } from 'angular-mentions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { deepCopy } from 'src/app/common/utils/general';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { ActiveJobModel } from '../../models/active-job.model';
import { JobTableService } from '../../services/job-table.service';
import { initData } from '../mail-view/utils';
import { JobsService } from '../../services/job.service';
import { DashboardService } from '../../services/dashboard.service';
import { OrderService } from '../../services/order.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';



@Component({
  selector: 'card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.css'],
})
export class CardViewComponent implements OnInit {
  private baseUrl: string = environment.ENV.BASE_URL;

  @Input() allMailData: any;
  @Input() selectedView?: SelectedViewModel;
  @Input() activeJobsList!: ActiveJobModel[];
  @Input() isLoading!: boolean;
  @Input() isLoadingOverlay!: boolean;
  @Input() dropdownOptions: any;
  @Input() activeJob: any;
  @Input() orgUserEmails!: any;
  @Input() notificationsView: boolean = false;
  @Input() notificationMailData: any = undefined;
  @Input() panelOpen: boolean = true;
  @Input() order_id: any = undefined;

  @Output() cardIsOpen = new EventEmitter<any>();
  @Output() orderDeleted = new EventEmitter<any>();

  showPopupComment: boolean = false;
  mousePosition = { x: 0, y: 0 };

  gridApi: any = undefined;
  colApi: any = undefined;
  gridOrders: any = undefined;
  curCards: any = [];
  curCard: any;
  selectedCard: any;
  skeletonItems: any[] = [];

  isLoadingOrder: boolean = true;
  showGlobalSubmittalsModal: boolean = false;
  showJobSubmittalsModal: boolean = false;

  actAll = 'All activity';
  actComm = 'Comments';
  actEmail = 'Emails history';
  actNotif = 'Activity history';
  subHistory = 'Submittals'

  activityList = [ this.actNotif, this.actComm, this.actEmail,this.subHistory];
  defaultActivityStart = this.actAll;

  @ViewChild(ExportSubmittalsComponent, { static: false }) exportSubmittalsComponent!: ExportSubmittalsComponent;
  @ViewChild('cardContainer') cardContainer!: ElementRef;
  @ViewChild('submittalsDialog') submittalsDialog!: ElementRef;
  @ViewChild('jobSubmittalsDialog') jobSubmittalsDialog!: ElementRef;

  constructor(
    private mailCommunicationService: MailBoxCommunicationService,
    private store: Store,
    private commentsService: CommentsService,
    private dashboardService: DashboardService,
    private dashboardActions: DashboardActions,
    private elRef: ElementRef,
    private sanitizer: DomSanitizer,
    private djv: DashboardJobView,
    private jobTableService: JobTableService,
    private jobService: JobsService,
    private cdr: ChangeDetectorRef,
    private orderService: OrderService,
    private httpClient: HttpClient,
    private resolver: ComponentFactoryResolver
    ) {
      (window as any)['card'] = this;
    }

  ngOnInit() {
    this.generateItems();

    if (this.notificationsView) {
      this.getSingleOrder();
      return;
    }

    this.djv.rowDataUpdated$.subscribe(() => {
      setTimeout(() => {
        this.isLoadingOrder = true;
        this.setDisplayedOrders();
        this.isLoadingOrder = false;
      }, 0);
    })
    this.jobTableService.getGridApi
      .subscribe((result: any) => {
        this.gridApi = result;
      }
    );
    this.jobTableService.getColumnApi
      .subscribe((result: any) => {
        this.colApi = result;
      }
    );
    this.jobTableService.getResetIndex
      .subscribe((result: any) => {
        setTimeout(() => {
          this.setDisplayedOrders();
        }, 0);
      }
    );
    this.djv.requestMetadata$.subscribe(() => {
      this.maybeRefreshCardMetadata();
    });
    this.djv.toggleCloseCard$
      .subscribe((result: any) => {
        this.curCards.forEach((c: any) => c.open = result);
        this.setScrollBar('.card-item');
        this.setScrollBar('.card-tracking-links');
      }
    );
    this.djv.cardViewToggle$
      .subscribe((result: any) => {
        let cur = this.lastClickedCard || 
            (this.curCards.length ? this.curCards[0]: undefined);
        if (!cur) return;
        if (result.icon) {
          if (result.icon == 'drafts') {
            if (cur.cur_activity == this.actEmail) {
              cur.cur_activity = undefined;
              cur.sidebar = false;
              return;
            } else {
              cur.cur_activity = this.actEmail;
              this.showDrafts = true;
            }

          } else if (result.icon == 'comments') {
            if (cur.cur_activity == this.actComm) {
              cur.sidebar = false;
              cur.cur_activity = undefined;
              return;
            }
            cur.cur_activity = this.actComm;
          } else {
            if (cur.cur_activity == this.actEmail) {
              cur.sidebar = false;
              cur.cur_activity = undefined;
              return;
            }
            cur.cur_activity = this.actEmail;
          }
          cur.sidebar = true; // TODO punt
          if (cur.sidebar) {
            cur.open = true;
            this.setScrollBar('.card-item');
            this.setScrollBar('.card-tracking-links'); 
          }
          setTimeout(() => {
            this.showDrafts = false;
          }, 1000);
        }
      }
    );

  }


  showDrafts: boolean = false;
  lastClickedCard: any = undefined;
  setCardOpen(event: any, card: any, force: boolean = false) {
    this.lastClickedCard = card;
    event.stopPropagation();
    if (force) {
      card.open = true;
    } else {
      card.open = !card.open;
    }
    if (this.notificationsView) {
      this.cardIsOpen.emit(card.open);
    }
    this.setScrollBar('.card-item');
    this.setScrollBar('.card-tracking-links');
  }

  generateItems() {
    for (let i = 0; i < 10; i++) {
      const randomWidth = Math.floor(Math.random() * 25) + 20;
      this.skeletonItems.push({ width: `${randomWidth}rem` });
    }
  }

  setScrollBar(v: string) {
    setTimeout(() => {
      let jdisplays = this.cardContainer.nativeElement.querySelectorAll(v);
      if (jdisplays) {
        jdisplays.forEach((jdisplay: any) => {
          jdisplay.addEventListener('mouseenter', function() {
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
          });
          jdisplay.addEventListener('mouseleave', function() {
            (jdisplay as any).classList.remove('custom-scrollbar');
            (jdisplay as any).classList.add('custom-scrollbar-close');
          });
        })
      }
    }, 500);

  }

  ngAfterViewInit() {
    this.resetScrolling();
  }

  initDataCalled: boolean = false;
  ngOnChanges(changes: any) {
    if (changes?.panelOpen) {
      this.panelOpen = changes.panelOpen.currentValue;
      this.cdr.detectChanges();
    }
  }

  resetScrolling() {
    this.setScrollBar('.card-container');
    this.setScrollBar('.item-wrap');
    this.setScrollBar('.card-tracking-links');
  }

  creatingCards: boolean = false;
  setDisplayedOrders() {
    if (this.notificationsView) return;
    let gridOrders = new Set();
    if (this.gridApi) {
      this.gridApi.forEachNodeAfterFilterAndSort(function(node: any) {
        if (node.displayed && !node.group) {
          gridOrders.add(node.data.order_id);
        }
      });
    }
    if (!gridOrders.size) {
      this.gridOrders = [];
      this.curCards = [];
      return;
    }
    if (!this.creatingCards) {
      this.creatingCards = true;
      this.gridOrders = [...gridOrders];
      this.createCards(this.selectedView?.results || []);
      this.getCardMetaData();
      this.setScrollTypes();
    }
  }

  setScrollTypes() {
    this.resetScrolling();
    this.setScrollBar('.card-tracking-links');
    this.setScrollBar('.card-all-order-docs');
    this.setScrollBar('.card-docs');
    setTimeout(() => this.creatingCards = false, 500);
  }

  createDate(d: any) {
    if (!d) return '--';
    return this.formatDate(new Date(d));
  }

  formatDate(d: any) {
    let date = d.toISOString();
    let cur = date.split('T')[0];
    cur = cur.split('-');
    let last = cur[0].substring(2);
    cur = cur.slice(-2).join('/');
    return cur + '/' + last;
  }

  getStatusWidth(name: string, extra: boolean = false, max: number = -1) {
    if (!name) return '8ch';
    let val = name.length + 3 + (extra ? 3 : 0);
    if (max != -1) {
      val = Math.min(val, max);
    }
    return `${val}ch`;
  }

  hasSpaces(value: string) {
    return value.includes(' ');
  }

  makeURL(v: any) {
    try {
      const url = new URL(v);
      return `${url.hostname}`;
    } catch(error) {
      return v;
    }
  }


  onNotesClick(event: any, card: any) {
    event.stopPropagation();
  }

  onNotesFocus(event: any, item: any) {
  }

  onNotesBlur(event: any, item: any, field: string, is_item: boolean = false) {
    let iid = is_item ? item.id : item.items[0].id;
    event.target.scrollTop = 0; 
    let payload = {
      [field]: item.notes,
      id: iid
    };
    this.dashboardActions.requestUpdateViewCell(payload);
    
  }

  onNotesKeydown(event: any, item: any) {
    if (event.code == 'KeyV') {
      event.stopPropagation();
    }
    if (event.code == 'KeyC') {
      event.stopPropagation();
      navigator.clipboard.writeText(item.notes).then(() => {});
    }
  }

  showActivityHistoryTooltip(event: any, card: any) {
    event?.stopPropagation();
    card.activity_tooltip = !card.activity_tooltip;
  }

  onChangeActivity(event: any, card: any, act: string) {
    event.stopPropagation();
    this.selectedCard = card;
    card.activity_tooltip = false;
    if (card.cur_activity == act) {
      if (card.cur_activity == this.subHistory) {
        card.show_submittals = false;
      }
      card.cur_activity = '';
      card.sidebar = false;
      return;
    };
    card.cur_activity = act;
    card.open = true;

    if (act == this.subHistory) {
      card.sidebar = false;
      this.showSubmittals(event, card);
      return;
    }
    card.sidebar = true;
  }

  closeActivity(event: any, card: any) {
    card.sidebar = false;
    card.cur_activity = '';
    event.stopPropagation();
  }

  isValidDate(dateString: any) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  tryMakeURL(link: any) {
    try {
      const url = new URL(link);
      return url.hostname;
    } catch(error) {
      return '';
    }
  }

  createCards(all_items: any, is_order: boolean = false) {
    let cards: any = [];
    this.gridOrders.forEach((o: any) => {
      let item: any = undefined;
      let items: any = [];
      if (!is_order) {
        items = all_items.filter((i: any) => i.order_id == o);
        if (!items?.length) return;
        item = items[0];
      } else {
        item = all_items;
      }

      let cur: any = {};
      cur.open = false;
      cur.show_submittals = false;
      cur.submittals_data = [];
      cur.submittals_history = [];
      cur.activity_tooltip = false;
      cur.cur_activity = '';
      cur.sidebar = false;
      cur.comments = [];
      cur.emails = [];
      cur.qs_received = false;
      cur.comment_count = 0;
      cur.draft_count = 0;
      cur.notification_count = 0;
      let supplier = this.dropdownOptions.supplier_id.find((s: any) => s.id == item.supplier_id);
      cur.supplier = supplier ? supplier: {id: '', value: ''};
      if (supplier && this.tryMakeURL(supplier.webtrack_link)) {
        cur.supplier_tracking_link = supplier.webtrack_link;
      }

      let job = this.dropdownOptions.job_id.find((s: any) => s.id == item.job_id);
      cur.job = job ? job: {id: '', value: ''};

      cur.notes = item.order_notes || '';
      cur.odocs = this.setOrderDocs(item);
      cur.docs = this.setOrderDocAtts(item);
      cur.showDocs = false;

      let uoms = this.dropdownOptions.unit_of_measure.map((m: any) => ({...m}));
      let uom_ns = uoms.find((x: any) => x.value == 'Not selected');
      uom_ns.value = '--';
      cur.uoms = uoms;

      if (is_order) {
        cur.delivery = '';
        cur.show_date = false;
        cur.status = [];
        cur.tracking = [];
        cur.items = [];
        cur.status_change = '';
        cur.last_checked = '';
        cur.items = [item];
        cur.is_order = true;
      } else {
        cur.status_change = item.status_change_date ? this.formatDate(new Date(item.status_change_date)) : '';
        let lcd: any = [];
        items.forEach((i: any) => {
          lcd = lcd.concat(i.last_checked_datetime.filter((d: any) => d).map((d: any) => new Date(d)));
        })
        lcd.sort((a: any, b: any) => a.getTime() - b.getTime());
        cur.last_checked = lcd.length ? this.formatDate(lcd[lcd.length - 1]): '';
        
        this.setCheckin(items, cur);
        this.setDeliveryDate(items, cur);
        cur.status = this.setStatuses(items);

        let tracking = items.filter((i: any) => i.tracking_info?.length || i.shipment_tracking?.length);
        cur.tracking = this.createLinks(tracking);
  
        items = items.map((i: any) => ({...i}));
        items.forEach((i: any) => {
          i.status_color = this.setItemStatus(i);
  
          let links: any = [];
          if (i.tracking_info?.length) links = links.concat(i.tracking_info);
          if (i.shipment_tracking?.length) links = links.concat(i.shipment_tracking);
          links = new Set(links.filter((l: any) => l));
          i.tracking_links = [...links];
  
          i.notes = i.notes || '';
          i.og_quantity = i.quantity;
          i.uom = uoms.find((x: any) => x.id == i.unit_of_measure);
          i.show_calendar = false;
  
        });
        cur.items = items;
      }
      cards.push(cur);

    });
    this.setScrollBar('.single-card-notif');
    this.isLoadingOrder = false;
    this.curCards = cards;
  }

  isArrayString(str: any) {
    try {
        let jsonString = str.replace(/'/g, '"');
        let array = JSON.parse(jsonString);
        if (Array.isArray(array)) {
          return array;
        }
        return false;
    } catch (e) {
        return false;
    }
}

  createLinks(tracking_items: any) {
    let links: any = [];
    tracking_items.forEach((item: any) => {
      let ilinks = [item.tracking_info, item.shipment_tracking];
      
      ilinks.forEach((linkArray) => {
        if (!linkArray?.length) {
            return;
        }
        linkArray.forEach((link: any) => {
          let array = this.isArrayString(link);
          if (array) {
            array.forEach((a: any) => {
              links.push(a);
            });
          } else {
            links.push(link);
          }
        });
      });
    });
  
  // Remove duplicates and filter out empty values
  links = [...new Set(links.filter((l: any) => l))];
  return links;
}


  setOrderDocs(item: any) {
    let odocs = [];
    if (item.po_document_number?.number) odocs.push(item.po_document_number);
    if (item.supplier_documents_numbers?.length) {
      odocs = odocs.concat(item.supplier_documents_numbers);
    }
    let odocData: any = [];
    odocs.forEach((o: any) => {
      let cur: any = {};
      cur.value = o;
      cur.data = {id: item.id},
      cur.colDef = 'po_document_number';
      odocData.push(cur);
    })
    odocData.push({
      data: {id: item.id}, colDef: 'po_document_number'
    });
    return odocData;
  }

  capitalizeFirstLetter(value: any) {
    return value[0].toUpperCase() + value.slice(1,);
  }

  setCheckin(items: any, cur: any) {
    let inactives = ['Delivered', 'At receiver', 'Ready for pickup', 'Picked up',
      'Installed', 'Damaged', 'Returned', 'Lost',
      'Cancelled', 'On hold', 'Awaiting funds', 'Expired'];
    inactives = inactives.map((i: any) => i.toLowerCase());
    let imperatives = ['Tracking Off', 'Not tracked', 'Pending supplier review', 'Invalid creds',
        'Credentials required', 'Email missing', 'Tracking missing', 'Supplier not tracked',
        'Unmet dependency'
    ];
    imperatives = imperatives.map((i: any) => i.toLowerCase());

    let daily = items.find((i: any) => i.next_supplier_user_view == 'Daily')
    if (daily) {
      cur.next_checkin = 'Daily';
    } else if (items.length > 1) {
      let checkins = items.filter((i: any) => this.isValidDate(i.next_supplier_user_view))
        .map((i: any) => new Date(i.next_supplier_user_view))
        .sort((a: any, b: any) => a - b);
      cur.next_checkin = checkins.length ? this.formatDate(checkins[0]) : '';
      if (!cur.next_checkin) {
        let s: any = new Set(items.map((i: any) => i.next_supplier_user_view));
        s = [...s];
        let hasImp = s.filter((i: any) => imperatives.includes(i.toLowerCase()));
        if (s.length == 1) {
          cur.next_checkin = this.capitalizeFirstLetter(s[0]);
        } else if (hasImp.length) {
          cur.next_checkin = this.capitalizeFirstLetter(hasImp[0]);
        } else if (s.filter((i: any) => inactives.includes(i.toLowerCase())).length == s.length) {
          cur.next_checkin = this.capitalizeFirstLetter(s[0]);
        } else {
          cur.next_checkin = items[0].next_supplier_user_view;
        }
      }
    } else {
      cur.next_checkin = items[0].next_supplier_user_view
    }
  }

  setOrderDocAtts(item: any) {
    let docs: any = [];
    if (item.po_document_attachments?.length) {
      let dtype = this.dropdownOptions.document_types.find((dt: any) => dt.id == 1);
      let cur = {
        value: item.po_document_attachments,
        document_type: dtype ? dtype.value : 'PO',
        data: {id: item.id},
      };
      docs.push(cur);
    }
    if (item.supplier_documents_attachments?.length) {
      item.supplier_documents_attachments.forEach((sd: any) => {
        let cdoc = item.supplier_documents_numbers.find((x: any) => x.id == sd.order_document_id);
        if (cdoc) cdoc = this.dropdownOptions.document_types.find((dt: any) => dt.id == cdoc.document_type);
        let cur = {
          value: [sd],
          document_type: cdoc ? cdoc.value : 'Order',
          data: {id: item.id},
        };
        docs.push(cur);
      });
    }
    return docs;
  }

  setItemStatus(item: any) {
    let scur: any = {};
    let name = this.dropdownOptions.status.find((x: any) => x.id == item.status);
    let color = this.dropdownOptions.status_colors.find((x: any) => x.id == item.status);
    scur.name = name;
    scur.color = color ? color.value : '';
    return scur;
  }

  setStatuses(items: any) {
    let statuses: any = new Set(items.map((i: any) => i.status));
    statuses = [...statuses];
    let svalues: any = [];
    statuses.forEach((s: any) => {
      let scur: any = {};
      let name = this.dropdownOptions.status.find((x: any) => x.id == s);
      let color = this.dropdownOptions.status_colors.find((x: any) => x.id == s);
      scur.value = scur.name = name ? name.value: '';
      scur.color = color ? color.value : '';
      scur.id = s;
      svalues.push(scur);
    });
    return svalues;
  }

  orderStatusChange(event: any, card: any) {
    if (!card?.items?.length) return;
    let changes: any = [];
    card.items.forEach((i: any) => {
      if (i.status == event.data.id) {
        changes.push(i);
        i.status = event.event.value.id;
        i.status_color.name = event.event.value;
        let color = this.dropdownOptions.status_colors.find((x: any) => x.id == i.status);
        i.status_color.color = color.value;
      }
    });
    card.status = this.setStatuses(card.items);
    let payload = {
      key: 'status',
      value: event.event.value.id,
      item_ids: changes.map((i: any) => (i.id))
    }
    this.orderService.multiItemOrderChange(payload).subscribe(() => {});
  }

  setDeliveryDate(items: any, cur: any) {
    let delivery: any = items.filter((i: any) => i.delivery_date).map((i: any) => i.delivery_date);
    delivery = new Set(delivery);
    delivery = [...delivery].map((i) => new Date(i)).sort((a, b) => a.getTime() - b.getTime());
    if (!delivery.length) cur.delivery = '';
    else if (delivery.length == 1) cur.delivery = this.formatDate(delivery[0]);
    else {
      let first = this.formatDate(delivery[0]);
      let last = this.formatDate(delivery[delivery.length - 1]);
      cur.delivery = `${first} - ${last}`;
    }
    cur.show_date = false;
  }


  updateMousePosition(event: MouseEvent) {
    this.mousePosition.x = event.clientX;
    this.mousePosition.y = event.clientY + 60;
  }

  delayClose() {
    setTimeout(() => {
      this.showPopupComment = false;
    }, 200);
  }

  commentClick(event: any, card: any) {
    this.curCard = card;
    this.updateMousePosition(event);
    event.stopPropagation();
    this.showPopupComment = !this.showPopupComment;
  }

  onUpdateDropdown(event: any, card: any, item: any, is_item: boolean = false) {
    let iid = is_item ? item.id : card.items[0].id;
    let colField = event.colField;
    let value = event?.event?.value?.id;
    let payload = {
      [colField]: value,
      id: iid
    };
    if (colField == 'status' && is_item) {
      item.status = value;
      item.status_color = this.setItemStatus(item);
      card.status = this.setStatuses(card.items);
    }
    this.dashboardActions.requestUpdateViewCell(payload);
    if (colField == 'supplier_id' || colField == 'job_id') {
      let order_id = card.items[0].order_id;
      let curOrders = this.allMailData?.curOrders;
      if (curOrders) {
        let order = curOrders.find((o: any) => o.id == order_id);
        if (order) {
          order[colField] = value;
        }
      }
      if (colField == 'supplier_id') {
        this.dashboardActions.requestSuppliersSmall();
      } else if (colField == 'job_id') {
        this.dashboardActions.requestActiveJobs();
      }
    }
  }

  onOrderDocChange(event: any, card: any) {
    if (event.updateOrder) {
      let payload: any = {...event.updateOrder};
      if (card.is_order) {
        payload.order_id = payload.order_item_id;
        delete payload.order_item_id;
      }
      this.dashboardService.updateOrderDocument({payload: payload})
      .subscribe((response: any) => {
        if (card.is_order) {
          response = this.noItemOrderUpdate(response);
        }
        card.odocs = this.setOrderDocs(response);
        card.docs = this.setOrderDocAtts(response);
      });
    } else if (event.deleteOrder) {
      let payload: any = {...event.deleteOrder};
      if (card.is_order) {
        payload.orders_order_id = payload.order_item_id;
        delete payload.order_item_id;
      }
      this.dashboardService.deleteOrderDocument({payload: payload})
      .subscribe((response: any) => {
        if (card.is_order) {
          response = this.noItemOrderUpdate(response);
        }
        card.odocs = this.setOrderDocs(response);
        card.docs = this.setOrderDocAtts(response);
      });
    } else if (event.addOrder) {
      let payload: any = {...event.addOrder};
      if (card.is_order) {
        payload.order_id = payload.order_item_id;
        delete payload.order_item_id;
      }
      this.dashboardService.addOrderDocument({payload: payload})
      .subscribe((response: any) => {
        if (card.is_order) {
          response = this.noItemOrderUpdate(response);
        }
        card.odocs = this.setOrderDocs(response);
        card.docs = this.setOrderDocAtts(response);
      });
    }
  }

  onOrderAttChange(event: any, card: any) {
    if (event.deleteAttachment) {
      card.docs = card.docs.filter((d: any) => !d.value.find((v: any) => v.id == event.id));
    }
  }

  onLinkChange(event: any, value: any, is_item: boolean = false) {
    if (!is_item) {
      value.items.forEach((i: any) => {
        let links: any = [];
        if (!event.delete) {
          links = i.tracking_links.concat([event.target]);
          links = new Set(links.filter((l: any) => l));
          links = [...links];
        } else {
          links = i.tracking_links.filter((l: any) => l != event.target);
        }
        i.tracking_links = links;
      });
    }
    let vid = is_item ? value.id : value.items[0].order_id;
    let payload: any = {};
    if (is_item) payload.item_id = vid;
    else payload.order_id = vid;
    payload.link = event.target;
    payload.delete = event.delete;
    this.orderService.writeOrderItem({payload: payload}).subscribe(() => {});    
  }


  startEditingItem(event: any, card: any, item: any) {

  }

  blurEditingValue(event: any) {
    event.target.blur();
  }

  deleteOrder(event: any, card: any) {
    event.stopPropagation();
    this.curCards = this.curCards.filter((c: any) => c != card);
    this.orderService.deleteOrder({order: card.items[0].order_id}).subscribe(() => {});
    this.djv.request_metadata();
    this.orderDeleted.emit();
  }

  deleteItem(event: any, card: any, item: any) {
    event.stopPropagation();
    card.items = card.items.filter((i: any) => i.id != item.id);
    let params = {
      payload: {
        id: item.id,
        reason_to_not_display: 3,
        reason_to_not_display_text: 'Cardview delete',
        is_deleted: true,
      }
    }
    this.orderService.deleteItemReason(params).subscribe(() => {});
  }

  stopEditingTracking(item: any, value: string, field: string) {
    if (field == 'quantity' && isNaN(Number(value))) {
      item.quantity = item.og_quantity;
      return;
    }
    item.og_quantity = item.quantity;
    let payload = {
      [field]: value,
      id: item.id
    };
    this.dashboardActions.requestUpdateViewCell(payload);
  }

  onChangeDate(event: any) {
    setTimeout(() => {
      this.calendarVisible = false;
      this.cdr.detectChanges();
    }, 200);
    let date = event.toISOString();
    if (this.calendar_value?.item?.id) {
      let field = this.calendar_value.field;
      let payload = {
        [field]: date.split('T')[0],
        id: this.calendar_value.item.id,
      };
      this.calendar_value.item[field] = this.formatDate(event);
      this.setDeliveryDate(this.calendar_value.card.items, this.calendar_value.card);
      this.dashboardActions.requestUpdateViewCell(payload);
    } else {
      let payloads: any = [];
      this.calendar_value.card.items.forEach((i: any) => {
        let payload = {
          delivery_date: date.split('T')[0],
          id: i.id,
        };
        payloads.push(payload);
        i.delivery_date = this.formatDate(event);
      });
      
      this.setDeliveryDate(this.calendar_value.card.items, this.calendar_value.card);
      payloads.forEach((payload: any) => {
        this.dashboardActions.requestUpdateViewCell(payload);
      })
    }
  }

  calendar_value: any = undefined;
  calendarVisible: boolean = false;
  @ViewChild('calendar') calendar!: ElementRef;
  openCalendar(event: any, card: any, item: any, field: any) {
    event.stopPropagation();
    this.calendar_value = {card: card, item: item, field: field};
    this.calendarVisible = !this.calendarVisible;
    if (!this.calendarVisible && this.calendar_value.item.id == item.id) return;
    else this.calendarVisible = true;
    setTimeout(() => {
      const calendarElement: any = this.calendar.nativeElement;
      let x = event.clientX - 165;
      if (this.notificationsView) x += 200;
      let y = event.clientY - 378;
      calendarElement.style.left = `${x}px`;
      calendarElement.style.top = `${y}px`;
    }, 100);
  }


  showCardCalendar(event: any, card: any) {
    event.stopPropagation();
    if (!(this.isValidDate(card.delivery) || !card.delivery)) return;
    card.show_date = true;
    this.calendarVisible = !this.calendarVisible;
    if (!this.calendarVisible) return;
    this.calendar_value = {card: card, item: undefined};
    setTimeout(() => {
      const calendarElement: any = this.calendar.nativeElement;
      let x = event.clientX - 165;
      if (this.notificationsView) x += 200;
      let y = event.clientY - 250;
      calendarElement.style.left = `${x}px`;
      calendarElement.style.top = `${y}px`;
    }, 10);
  }

  deleteAllDates(event: any, card: any) {
    event.stopPropagation();
    let payloads: any = [];
    this.calendar_value.card.items.forEach((i: any) => {
      let payload = {
        delivery_date: null,
        id: i.id,
      };
      payloads.push(payload);
      i.delivery_date = null;
    });

    this.setDeliveryDate(card.items, card);
    payloads.forEach((payload: any) => {
      this.dashboardActions.requestUpdateViewCell(payload);
    })
  }

  deleteDate(event: any, card: any, item: any, field: any) {
    event.stopPropagation();
    item[field] = null;
    let payload = {
      [field]: null,
      id: item.id,
    };
    if (field == 'deliery_date') {
      this.setDeliveryDate(card.items, card);
    }
    this.dashboardActions.requestUpdateViewCell(payload);
  }

  getImageData(card: any, item: any) {
    return {
      value: item.images,
      data: {id: item.id},
      colDef: {field: 'images'},
      card: card,
      item: item
    };
  }

  onImagesUpdated(event: any, card: any, item: any) {
    if (!event.delete) {
      this.dashboardService.updateViewCell({payload: event.payload})
      .subscribe((response: any) => {
        item.images = response.images;
      })
    } else {
      let target = event.payload.image_id_to_delete;
      item.images = item.images.filter((i: any) => i.image_id != target);
      this.dashboardService.deleteAttachment({payload: event.payload})
      .subscribe((response: any) => {})
    }
  }

  getCardMetaData() {
    for (let i = 0; i < 5; i++) {
      let c = this.curCards[i];
      if (c) this.getOrderActivityCount(c);
    }
  }

  maybeRefreshCardMetadata() {
    if (this.selectedView?.activeView?.view_style == 1 && this.selectedCard) {
      setTimeout(() => {
        this.getOrderActivityCount(this.selectedCard, true);
      }, 1000);
    }
  }

  getOrderActivityCount(card: any, force: boolean = false) {
    let order_id = card.items[0].order_id;
    if (force || !card.qs_received) {
      card.qs_received = true;
      this.httpClient.get(`${this.baseUrl}input_messages/meta/`, {
        params: {order_id: order_id},
      }).subscribe((result: any) =>  {
        card.comment_count = result.comments;
        card.draft_count = result.drafts;
        card.notification_count = result.notifications;
      })
    }
  }

  showCardDrafts(event: any, card: any) {
    setTimeout(() => {
      this.showDrafts = false;
    }, 1000);
    this.showDrafts = true;
    this.onChangeActivity(event, card, this.actEmail);
  }

  showCardEmails(event: any, card: any) {
    this.onChangeActivity(event, card, this.actEmail);
  }

  showCardComments(event: any, card: any) {
    this.onChangeActivity(event, card, this.actComm);
  }

  createSubmittalOptions() {
    this.orderService.getSubmittalsOptions().subscribe((result: any) => {
      let types = result.types.map((t: any) => (
        {id: t.id, value: t.name[0].toUpperCase() + t.name.slice(1)}
      ))
      types.sort((a: any, b: any) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
      types.push({id: -1, value: 'Create new type'});
      let statuses = result.statuses.map((t: any) => ({id: t.id, value: t.name}));
      this.submittalOptions = {types: types, statuses: statuses};
    })
  }

  submittalOptions: any = undefined;
  showSubmittals(event: any, card: any) {
    event.stopPropagation();
    card.cur_activity = this.subHistory;
    this.setCardOpen(event, card, true);
    card.show_submittals = !card.show_submittals;
    if (!card.show_submittals) {
      card.cur_activity = undefined;
      return;
    }
    if (!this.submittalOptions) {
      this.createSubmittalOptions();
    }
    let order_id = card.items[0].order_id;
    this.retrieveSubmittalData(card, order_id);
    this.retrieveSubmittalHistoryData(card, order_id);
  }

  currentGlobalCard: any = undefined;
  showGlobalSubmittals(event: any, card: any) {
    event.stopPropagation();
    this.currentGlobalCard = card;
    this.showGlobalSubmittalsModal = true;
  }

  exportSubmittalsToPDF(event: any) {
    event.stopPropagation();
    this.showJobSubmittalsModal = true;
  }

  addNewSubmittalType(event: any) {
    if (this.submittalOptions.types.find((ot: any) => ot.value.toLowerCase() == event.toLowerCase())) {
      alert('This submittal type already exists!');
      return;
    }
    this.orderService.addSubmittalType({name: event}).subscribe((result: any) => {
      let curTypes = this.submittalOptions.types.slice(0, -1);
      curTypes = curTypes.concat({id: result.id, value: result.name});
      curTypes.sort((a: any, b: any) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
      curTypes.push({id: -1, value: 'Create new type'});
      this.submittalOptions = {types: curTypes, statuses: this.submittalOptions.statuses};
    })
  }

  deleteSubmittalType(stype_id: any) {
    let stypes = this.submittalOptions.types.filter((o: any) => o.id != stype_id);
    this.submittalOptions = {...this.submittalOptions, types: stypes};
    this.curCards.forEach((c: any) => {
      if (!c.submittals_data) return;
      c.submittals_data.forEach((d: any) => {
        d.values = d.values.filter((dv: any) => dv.type_id != stype_id);
      });
      c.submittals_data = [...c.submittals_data];
    });
    this.orderService.deleteSubmittalType({type: stype_id}).subscribe(() => {});
  }

  changeSubmittalTypeName(changetype: any) {
    this.submittalOptions.types.forEach((o: any) => {
      if (o.id != changetype.id) return;
      o.value = changetype.name;
    });
    let types = this.submittalOptions.types.slice(0, -1);
    types.sort((a: any, b: any) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
    types.push({id: -1, value: 'Create new type'});
    this.submittalOptions = {...this.submittalOptions, types: types};
    let payload = {type: changetype.id, name: changetype.name};
    this.orderService.updateSubmittalTypeName(payload).subscribe(() => {});
  }


  showCardNotifications(event: any, card: any) {
    this.onChangeActivity(event, card, this.actNotif);
  }

  hoverOverCard(card: any) {
    card.showDocs = true;
    this.getOrderActivityCount(card);
  }

  noItemOrderUpdate(cur: any) {
    cur.supplier_documents_numbers = [...cur.supplier_documents];
    cur.po_document_number = cur.po_document ? {...cur.po_document}: undefined;
    let supplier_atts: any = [];
    cur.supplier_documents_numbers.forEach((sd: any) => {
      supplier_atts = supplier_atts.concat(sd.attachments);
    });
    cur.supplier_documents_attachments = supplier_atts;
    cur.po_document_attachments = cur.po_document?.attachments;
    return cur;
  }

  getSingleOrder() {
    this.orderService.getFullOrder({order_id: this.order_id}).subscribe((result: any) => {
      this.isLoadingOrder = true;
      this.gridOrders = [this.order_id];
      if (result.has_items) {
        this.createCards(result.data);
      } else {
        let data = result.data;
        data = data.length? data[0]: undefined;
        if (!data) return;
        let cur = {...data};
        cur.orderIdVal = {id: cur.id, value: result.order_name};
        cur.job_id = cur.job;
        cur.order_id = cur.id;
        cur.supplier_id = cur.supplier;
        cur.order_notes = cur.order_notes || '';
        this.noItemOrderUpdate(cur);
        this.createCards(cur, true);
      }
      this.isLoadingOrder = false;
    })
  }

  getDynamicStyles() {
    if (this.notificationsView) {
      let width = this.panelOpen ? `calc(100vw - 550px)`: `calc(100vw - 240px)`; 
      return width;
    } else {
      return `calc(100vw - 205px)`;
    }
  }


  getCardWidth(card: any, is_email: boolean = false) {
    let inner = window.innerWidth - 200 - 30;
    if (is_email) inner -= 820;
    return card.sidebar ? `${inner}px`: '1270px';

  }

  onApplySubmittals(event: any) {
    this.showGlobalSubmittalsModal = false;
    this.orderService.massUpdateSubmittals(event).subscribe((result: any) => {
      let cur = this.curCards.find((c: any) => c.items[0].order_id == event.order);
      cur.submittals_data = result;
    })
  }

  retrieveSubmittalData(card: any, order_id: any) {
    let payload = {order: order_id};
    this.orderService.getOrderSubmittals(payload).subscribe((result: any) => {
      card.submittals_data = result;
    })
  }

  retrieveSubmittalHistoryData(card: any, order_id: any) {
    let payload = {order: order_id};
    this.orderService.getOrderSubmittalsHistory(payload).subscribe((result: any) => {
      card.submittals_history = result;
    })
  }
}
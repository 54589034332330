import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardJobView {
  private viewChosenSubject = new Subject<any>();
  viewChoseButtonClick$ = this.viewChosenSubject.asObservable();
  get_view_data(data: any) {
    this.viewChosenSubject.next(data);
  }

  private loadingViewsSubject = new Subject<any>();
  loadingViews$ = this.loadingViewsSubject.asObservable();
  loading_views(loading: boolean) {
    this.loadingViewsSubject.next(loading);
  }

  private loadingSubject = new Subject<any>();
  loading$ = this.loadingSubject.asObservable();
  is_loading(loading: boolean) {
    this.loadingSubject.next(loading);
  }

  private newViewSubject = new Subject<void>();
  newView$ = this.newViewSubject.asObservable();
  make_new_view() {
    this.newViewSubject.next();
  }

  private sidePanelSubject = new Subject<any>();
  sidePanelClick$ = this.sidePanelSubject.asObservable();
  side_panel_click(data: any) {
    this.sidePanelSubject.next(data);
  }

  private notificationCountSubject = new Subject<any>();
  notificationCount$ = this.notificationCountSubject.asObservable();
  set_notification_count(data: any) {
    this.notificationCountSubject.next(data);
  }

  private viewClickedSubject = new Subject<void>();
  viewClick$ = this.viewClickedSubject.asObservable();
  view_clicked() {
    this.viewClickedSubject.next();
  }

  private commentOccurSubject = new Subject<void>();
  commentOccur$ = this.commentOccurSubject.asObservable();
  on_comment() {
    this.commentOccurSubject.next();
  }

  private closeCommentsSubject = new Subject<void>();
  closeComments$ = this.closeCommentsSubject.asObservable();
  close_comments() {
    this.closeCommentsSubject.next();
  }

  private closeEmailSubject = new Subject<void>();
  closeEmail$ = this.closeEmailSubject.asObservable();
  close_email() {
    this.closeEmailSubject.next();
  }

  private requestMetaDataSubject = new Subject<void>();
  requestMetadata$ = this.requestMetaDataSubject.asObservable();
  request_metadata() {
    this.requestMetaDataSubject.next();
  }

  private closeCardsSubject = new Subject<any>();
  toggleCloseCard$ = this.closeCardsSubject.asObservable();
  toggle_close_cards(data: any) {
    this.closeCardsSubject.next(data);
  }

  private cardViewIconToggleSubject = new Subject<any>();
  cardViewToggle$ = this.cardViewIconToggleSubject.asObservable();
  cardview_icon_toggle(data: any) {
    this.cardViewIconToggleSubject.next(data);
  }

  private rowDataUpdatedSubject = new Subject<any>();
  rowDataUpdated$ = this.rowDataUpdatedSubject.asObservable();
  row_data_updated(data: any) {
    this.rowDataUpdatedSubject.next(data);
  }

  private getNewViewsSubject = new Subject<void>();
  getNewViews$ = this.getNewViewsSubject.asObservable();
  get_new_views() {
    this.getNewViewsSubject.next();
  }

  private pollMetaDataSubject = new Subject<void>();
  pollMetaData$ = this.pollMetaDataSubject.asObservable();
  do_poll_metadata() {
    this.pollMetaDataSubject.next();
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IForgotPassword, ILogin, IRecoverPassword, ISingUp } from '../../models/authentication.model';
import { ErrorReceiveForgotPassword, ReceiveForgotPassword } from '../actions/authentication-action-types-creators/forgot-password-action-types-creators';
import { ErrorReceiveLogin, ReceiveLogin } from '../actions/authentication-action-types-creators/login-action-types-creators';
import { ErrorReceiveRecoverPassword, ReceiveRecoverPassword } from '../actions/authentication-action-types-creators/recover-password-action-types-creators';
import { ErrorReceiveSingUp, ErrorReceiveUserOptions, ReceiveSingUp, ReceiveUserOptions } from '../actions/authentication-action-types-creators/sing-up-action-types-creators';
import { AuthenticationService } from './../../services/authentication.service';
import { AuthenticationActionTypes } from './../actions/authentication-action-types';

@Injectable()
export class AuthenticationEffects {
  constructor(private action$: Actions, private authenticationService: AuthenticationService) { }

  requestLogin$: Observable<Action> = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthenticationActionTypes.REQUEST_LOGIN),
      switchMap((newLogin: ILogin) =>
        this.authenticationService.login(newLogin).pipe(
          map((user: any) => new ReceiveLogin(user)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveLogin(error)))
        )
      )
    );
  });

  requestSingUp$: Observable<Action> = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthenticationActionTypes.REQUEST_SING_UP),
      switchMap((newSingUp: ISingUp) =>
        this.authenticationService.singUp(newSingUp).pipe(
          map((user: any) => new ReceiveSingUp(user)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveSingUp(error)))
        )
      )
    );
  });

  requestForgotPassword$: Observable<Action> = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthenticationActionTypes.REQUEST_FORGOT_PASSWORD),
      switchMap((newForgotPassword: IForgotPassword) =>
        this.authenticationService.forgotPassword(newForgotPassword).pipe(
          map((user: any) => new ReceiveForgotPassword(user)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveForgotPassword(error)))
        )
      )
    );
  });

  requestRecoverPassword$: Observable<Action> = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthenticationActionTypes.REQUEST_RECOVER_PASSWORD),
      switchMap((newRecoverPassword: IRecoverPassword) =>
        this.authenticationService.recoverPassword(newRecoverPassword).pipe(
          map((user: any) => new ReceiveRecoverPassword(user)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveRecoverPassword(error)))
        )
      )
    );
  });

  requestUserOptions$: Observable<Action> = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthenticationActionTypes.REQUEST_USER_OPTIONS),
      switchMap(() =>
        this.authenticationService.getOptions().pipe(
          map((user: any) => new ReceiveUserOptions(user)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUserOptions(error)))
        )
      )
    );
  });

  requestCompany$: Observable<Action> = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthenticationActionTypes.REQUEST_COMPANY),
      switchMap((company: any) =>
        this.authenticationService.addCompany(company).pipe(
          map((user: any) => new ReceiveUserOptions(user)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUserOptions(error)))
        )
      )
    );
  });

  requestRole$: Observable<Action> = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthenticationActionTypes.REQUEST_ROLE),
      switchMap((role: any) =>
        this.authenticationService.addRole(role).pipe(
          map((user: any) => new ReceiveUserOptions(user)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUserOptions(error)))
        )
      )
    );
  });
}

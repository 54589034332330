<div class="submittals-container custom-scrollbar-close" #submittalTop>
  <div class="submittal-headers">
    <div class="header-style header-large-width main-submittal-header">
      SUBMITTALS
      <div class="add-submittals" (click)="createNewSubmittal($event)">
        <i class="bi bi-plus-lg"></i>
      </div>
    </div>
    <div class="header-style header-normal-width">
      STATUS
    </div>
    <div class="header-style header-normal-width">
      STATUS DATE
    </div>
    <div class="header-style header-large-width">
      ATTACHMENTS
    </div>
    <div class="header-style header-large-width">
      TRACKING
    </div>
    <div class="header-style header-notes-width">
      NOTES
    </div>
    <div class="header-style header-history-width">
      HISTORY
    </div>
    <div class="end-padding"></div>
  </div>
  <div class="submittal-list-container custom-scrollbar-close" #submittalContainer>
    <div class="single-submittal-row" *ngFor="let sub of curSubmittals; let isLast=last"
        [ngClass]="isLast ? '' : 'submittal-bottom'">
        <div class="delete-item">
          <i class="bi bi-trash3-fill" (click)="deleteSubmittal($event, sub)"
              style="font-size: 12px; color: #ddd"></i>
          <div class="delete-item-tooltip">
            Delete submittal?
          </div>
        </div>
      <div class="header-large-width submittal-name">
        <div *ngIf="!sub.make_new">
          <dropdown-cell-renderer
          [options]="options?.types"
          [isAgGrid]="false"
          [data]="dataForType(sub)"
          [cardView]="true"
          [submittalView]="true"
          [cardViewWidth]="110"
          [colField]="'types'"
          (statusChanged)="onUpdateDropdown($event, sub)"
        ></dropdown-cell-renderer>
        </div>
        <div *ngIf="sub.make_new">
          <input class="item-text-input" 
                  type="text"
                  (focus)="startEditingSubmittalName($event, sub)"
                  (keydown.enter)="blurSubmittalName($event)"
                  (blur)="stopEditingSubmittalName(sub)"
                  [(ngModel)]="newSubmittalName"
          />
        </div>

      </div>
      <div class="header-normal-width">
        <div class="status-choice">
          <i class="bi bi-bullseye" style="padding-right: 8px"
            [ngStyle]="{'color': colorForStatus(sub)}"></i>
            <dropdown-cell-renderer
              [options]="options?.statuses"
              [isAgGrid]="false"
              [data]="dataForStatus(sub)"
              [cardView]="true"
              [credPage]="true"
              [cardViewWidth]="80"
              [colField]="'statuses'"
              (statusChanged)="onUpdateStatus($event, sub)"
          ></dropdown-cell-renderer>
        </div>
      </div>
      <div class="header-normal-width card-delivery">
        <div class="card-delivery-inner">
          <div class="card-delivery-when name-overflow calendar-wrapper"
                (click)="openCalendar($event, sub)">
            {{createDate(sub.change_date)}}
          </div>
          <div *ngIf="sub.change_date" class="card-deliver-delete">
            <i class="bi bi-x" (click)="deleteDate($event, sub)"></i>
          </div>
        </div>
      </div>
      <div class="header-large-width attachments-container custom-scrollbar-close">
        <div class="single-file-name" *ngFor="let att of sub.attachments">
          <span class="file-name-wrapper">
            <span class="file-names"
                  [ngClass]="fileIsReady(sub, att) ? 'file-ready' : 'file-not-ready'"
                  (click)="attachmentClicked($event, sub, att)">{{att.filename}}</span>
            <i class="bi bi-x file-name-delete" (click)="removeAttachment($event, sub, att)"></i>
          </span>
        </div>
        <div class="attachment-upload">
          <label (click)="uploadFile($event)">
            <i class="bi bi-plus-lg att-icon"></i>
            <input type="file" #fileInput (change)="onFileSelected($event, sub)">
          </label>
        </div>
      </div>
      <div class="header-large-width">
        <div class="card-tracking-links card-tracking-links-item custom-scrollbar-close"
            [ngClass]="sub.tracking.length? '': 'card-tracking-align' ">
          <shiplink-cell-renderer
            [cardView]="true"
            [linkData]="sub.tracking"
            [maxWidth]="110"
            (onLinkChange)="onLinkChange($event, sub)"
          >
          </shiplink-cell-renderer>
        </div>
      </div>
      <div class="header-notes-width card-delivery-when textarea-container">
        <textarea class="notes-input custom-scrollbar" 
          [(ngModel)]="sub.notes" 
          [placeholder]="sub.notes || ''"
          (click)="onNotesClick($event, sub)"
          (focus)="onNotesFocus($event, sub)" 
          (blur)="onNotesBlur($event, sub)"
          (keydown)="onNotesKeydown($event, sub)"
          rows="3">
        </textarea>
      </div>
      <div class="header-history-width sub-history-container custom-scrollbar-close">
        <div class="single-history-container" *ngFor="let history of getSubmittalHistory(sub)">
          <div class="activity-user">
            <span> {{history.user}} </span>
          </div>
          <div class="activity-history-text">
            <span>{{history.history}}</span>
          </div>
          <div class="activity-history-date">
            <span>{{history.date}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #calendar class="calendar-container" *ngIf="calendarVisible">
  <p-calendar class="max-w-full"
  [style]="{
    'width':'100%', 'height': '100%', 'z-index': '5',
    'position': 'relative', 'bottom': '-72px',
    'right': '70px',
  }"
  placeholder="YYYY-MM-DD"
  dateFormat="yy/mm/dd"
  dataType="date"
  [inline]="true"
  appendTo="body"
  (onSelect)="onChangeDate($event)"
  (onClear)="onChangeDate($event)">
</p-calendar>
</div>

<p-dialog
  [(visible)]="documentModal"
  [style]="{ marginTop: '50px', width: '90vw', height: '90vh' }"
  [closeOnEscape]="true"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span></span>
    </div>
    <button class="dialog-close" type="button" (click)="closeDocumentModal()"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <ng-template pTemplate="content">
    <iframe [src]="selectedAttachment | sanitize" allow="fullscreen" width="100%" height="98%"></iframe>
  </ng-template>
</p-dialog>
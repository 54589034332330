import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IActiveJobs, IJobs, INewJob, IUpdateJob } from './../models/dashboard.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { environment } from 'src/environments/environment';
import {decodeMsgPackSimple} from 'src/app/dashboard/services/do_msgpack'
import { map } from 'rxjs/operators';


@Injectable()
export class JobsService {
  private baseUrl: string = environment.ENV.BASE_URL;
  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }
  private httpOptionsMsgPack = {
    headers: new HttpHeaders({
      'Accept': 'application/x-msgpack',
    }),
    responseType: 'arraybuffer' as any
  };

  public addJob(newJob: INewJob | any): Observable<INewJob | HttpErrorResponse> {
    return this.httpClient.post<INewJob>(`${this.baseUrl}jobs/`, newJob.payload,
        this.httpOptionsMsgPack)
        .pipe(map((response: any) => {
          return decodeMsgPackSimple(response);
          })
        );
  }

  public updateJob(updateJob: IUpdateJob | any): Observable<IUpdateJob | HttpErrorResponse> {
    return this.httpClient.put<IUpdateJob>(`${this.baseUrl}jobs/${updateJob.payload.id}/`,
      updateJob.payload, this.httpOptionsMsgPack)
      .pipe(map((response: any) => {
        return decodeMsgPackSimple(response);
        })
      );
  }

  public getJobList(params?: any): Observable<IJobs | HttpErrorResponse> {
    return this.httpClient.get<IJobs>(`${this.baseUrl}jobs`, {
      ...this.httpOptionsMsgPack,
      params: { ...params.payload },
    })
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }

  public getActiveJobs(): Observable<IActiveJobs | HttpErrorResponse> {
    return this.httpClient.get<IActiveJobs>(`${this.baseUrl}jobs/active/`, this.httpOptionsMsgPack)
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }

}

import * as msgpack from '@msgpack/msgpack';
import { map } from 'rxjs/operators';
import * as pako from 'pako';
import { sortDocumentsByDate , deduplicateOrderTypes} from '../components/mail-view/utils';
import { TableConstants } from 'src/app/common/constants/table.constants';

export function encodeMsgPack(data: any) {}

export function unzipMsgPackData(data: any) {
  const compressedBytes = new Uint8Array(data);
  const decompressed = pako.inflate(data);
  return decompressed;
}

// decompresses a string
export function unzipData(data: any) {
  const compressedBytes = new Uint8Array(data.match(/[\da-f]{2}/gi).map((h: any)=> parseInt(h, 16)));
  const decompressed = pako.inflate(compressedBytes, { to: 'string' });
  return decompressed;
}

export function unzipByteData(data: any, to_string: boolean) {
  if (to_string) {
    return pako.inflate(data, {to: 'string'});
  } else {
    return pako.inflate(data);
  }
}

export function decodeMsgPackSimple(response: any) {
  let decoded = unzipMsgPackData(response) as any;
  decoded = msgpack.decode(new Uint8Array(decoded));
  return decoded as any;
}

export function decodeMsgPack(response: any) {
  return decodeMsgPackSimple(response);
}


function unpackDocs(docs: any) {
  let docDict: any = {};
  let po = docs.find((doc: any) => doc.document_type == 1);
  if (po) {
    let po_copy = {...po};
    delete po_copy.attachments;
    delete po_copy.order_document_date;
    docDict.po_document_number = po_copy;
    docDict.po_document_date = po.order_document_date;
    docDict.po_document_attachments = po.attachments || [];
  }
  let others = docs.filter((d: any) => d.document_type != 1);
  let atts = others.filter((d: any) => d.attachments?.length
    ).map((d: any) => (d.attachments)).flat();
  docDict.supplier_documents_attachments = atts;

  let earliest = others.filter((d: any) => d.order_document_date);
  if (earliest.length) {
    let last = earliest[earliest.length - 1];
    docDict.supplier_documents_earliest_document_date = last.order_document_date;
  }

  let sdocs: any = [];
  others.forEach((o: any) => {
    let cur: any = {...o};
    delete cur.attachments;
    sdocs.push(cur);
  });
  docDict.supplier_documents_numbers = sdocs;
  return docDict;
}


function set_last_checked_datetime(item: any) {
  const dates = [
    item.last_checked_datetime, 
    item.last_confirmed_datetime
  ];

  let dateStrings = dates
    .filter(x => x !== null && x !== undefined)
    .map(x => new Date(x).toISOString().split('T')[0]);
  item.last_checked_datetime = dateStrings;
}

function set_has_subitems(item: any) {
  item.has_subitems = {
    has: item.has_subitems,
    q: item.quantity || 0,
  };
}

function set_dependencies(item: any) {
  let deps = item.dependencies;
  if (!deps?.length) {
    item.dependencies = [];
  } else {
    item.dependencies = deps.map((d: any) => (d.id));
  }
}

function set_next_supplier_user_view(item: any) {
  if (!item.next_checkin_date) {
    let nc_note = item.next_checkin_note;
    if (nc_note) {
      item.next_supplier_user_view = nc_note;
      return;
    }
  }
  let val = item.next_supplier_follow_up_time;
  if (val) {
    val = new Date(val).toISOString().split('T')[0];
    item.next_supplier_user_view = val;
    return;
  }
  let note = item.next_supplier_follow_up_note;
  item.next_supplier_user_view = note ? note: 'Not Scheduled';
}


export function unPackOrderItems(data: any, set_fields: boolean = true) {
  let output: any = [];
  let results = data?.results || [];
  results?.forEach((order: any) => {
    let odata = order?.order || {};
    let docs = odata?.supplier_documents || [];
    odata = {...odata};
    delete odata.supplier_documents;
    sortDocumentsByDate(docs);
    docs = deduplicateOrderTypes(docs);
    let docDict = unpackDocs(docs);
    order?.items?.forEach((i: any) => {
      let item = i || {};
      let full_item = {...item, ...odata, ...docDict};
      set_last_checked_datetime(full_item);
      set_has_subitems(full_item);
      set_next_supplier_user_view(full_item);
      set_dependencies(full_item);
      output.push(full_item);
    })
  });
  data.results = output;

  if (set_fields) {
    let fields = TableConstants.jobItemsTableColumns.map((f: any) => (f.field))
    let no_sort = ['index', 'order_is_a_com'];
    let no_edit = [
      'created_datetime',
      'id',
      'index',
      'order_id',
      'order_is_a_com',
      'po_document_attachments',
      'po_document_date',
      'po_document_number',
      'supplier_documents_attachments',
      'supplier_documents_earliest_document_date',
      'supplier_documents_numbers',
      'supplier_id_2',
      'updated_datetime'
    ]
    let edit_fields = fields.filter((f: any) => !no_edit.includes(f));
    data.fields_to_edit = edit_fields;
    let sort_fields = fields.filter((f: any) => !no_sort.includes(f));
    data.fields_to_filter = sort_fields;
    data.fields_to_sort = sort_fields;
  }

  (window as any)['unpack'] = data;
  return data;
}
import { ISupplierContacts, ISuppliers } from '../../models/dashboard.model';
import { IStoreApiItem, StoreApiItem } from './../../../common/models/store-api-item.model';


export interface ISuppliersState {
  suppliers: IStoreApiItem<ISuppliers>;
  supplierContact: IStoreApiItem<ISupplierContacts>;
  suppliersAutocomplete: IStoreApiItem<ISuppliers>;
}

export class SuppliersState implements ISuppliersState {
  suppliers: IStoreApiItem<ISuppliers> = new StoreApiItem();
  supplierContact: IStoreApiItem<ISupplierContacts> = new StoreApiItem();
  suppliersAutocomplete: IStoreApiItem<ISuppliers> = new StoreApiItem();
}
<div class="orders-row flex gap-2 items-center"
      [ngStyle]="{'max-height': pixelSize, 'min-height': pixelSize}">
  <span class="orders-items" *ngIf="valid" [ngStyle]="{'max-height': pixelSize}">
    <i *ngIf="editable" class="bi bi-cloud-upload file-upload-icon" (click)="fileInput.click()">
      <input
        multiple
        #fileInput
        type="file"
        hidden
        accept=".png, .jpeg, .jpg"
        (change)="addImages(params.colDef.field, $any($event.target).files, params.data?.id)"
      />
    </i>
    <div *ngFor="let image of handleImagesPre(params.value)"
         [ngStyle]="{'max-height': pixelSize}"
         class="orders-item-image">
      <ng-container *ngIf="!image.error">
        <p-image
          appendTo="body"
          class="cell-auto-image"
          width="{{size}}"
          height="{{size}}"
          src="{{ image.image_thumbnail ? image.image_thumbnail : image.image }}"
          alt="{{ image.name }}"
          (onImageError)="handleMissingImage($event, image)"
          [preview]="true"
        >
          <ng-template pTemplate="indicator">
            <fa-icon class="icon" [icon]="faEye"></fa-icon>
          </ng-template>
        </p-image>
        <i *ngIf="!isSharedView && editable"
            class="close-icon bi bi-x-lg" (click)="deleteFile(image.image_id)"></i>
      </ng-container>
    </div>
  </span>

</div>

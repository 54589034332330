import { MemoizedSelector, DefaultProjectorFn, createSelector } from '@ngrx/store';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { ApplicationState, IApplicationState } from 'src/app/common/state/models/app.state.model';
import { ISharedViewState } from '../models/shared-view.model';

const sharedViewState = (state: IApplicationState) => state.sharedView;

export const selectSharedViewPage: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(sharedViewState, (state: ISharedViewState | any) => state.sharedViewPage.sharedViewPage);


import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IOrganization } from 'src/app/company-settings/models/company.model';
import { CompanyActionTypes } from '../company-action-types';

export class RequestOrganization implements Action {
  readonly type: CompanyActionTypes.REQUEST_ORGANIZATION = CompanyActionTypes.REQUEST_ORGANIZATION;

  constructor(
    public payload?: string) { }
}
export class ReceiveOrganization implements Action {
  readonly type: CompanyActionTypes.RECEIVE_ORGANIZATION = CompanyActionTypes.RECEIVE_ORGANIZATION;

  constructor(
    public payload?: IOrganization) { }
}
export class ErrorReceiveOrganization implements Action {
  readonly type: CompanyActionTypes.ERROR_RECEIVE_ORGANIZATION = CompanyActionTypes.ERROR_RECEIVE_ORGANIZATION;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class RequestUpdateOrganization implements Action {
  readonly type: CompanyActionTypes.REQUEST_UPDATE_ORGANIZATION = CompanyActionTypes.REQUEST_UPDATE_ORGANIZATION;

  constructor(
    public payload: IOrganization) { }
}

export class ReceiveUpdateOrganization implements Action {
  readonly type: CompanyActionTypes.RECEIVE_UPDATE_ORGANIZATION = CompanyActionTypes.RECEIVE_UPDATE_ORGANIZATION;

  constructor(
    public payload: IOrganization) { }
}
export class ErrorReceiveUpdateOrganization implements Action {
  readonly type: CompanyActionTypes.ERROR_RECEIVE_UPDATE_ORGANIZATION = CompanyActionTypes.ERROR_RECEIVE_UPDATE_ORGANIZATION;

  constructor(
    public payload: HttpErrorResponse) { }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IShareView, IShareViewEmail, IShareViewInvite } from 'src/app/dashboard/models/dashboard.model';
import { DashboardActionTypes } from '../dashboard-action-types';

export class RequestShareView implements Action {
  readonly type: DashboardActionTypes.REQUEST_SHARE_VIEW = DashboardActionTypes.REQUEST_SHARE_VIEW;

  constructor(
    public payload?: IShareView) { }
}
export class ReceiveShareView implements Action {
  readonly type: DashboardActionTypes.RECEIVE_SHARE_VIEW = DashboardActionTypes.RECEIVE_SHARE_VIEW;

  constructor(
    public payload?: IShareView) { }
}
export class ErrorReceiveShareView implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_SHARE_VIEW = DashboardActionTypes.ERROR_RECEIVE_SHARE_VIEW;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class RequestUpdateShareView implements Action {
  readonly type: DashboardActionTypes.REQUEST_UPDATE_SHARE_VIEW = DashboardActionTypes.REQUEST_UPDATE_SHARE_VIEW;

  constructor(
    public payload: IShareViewInvite) { }
}
export class ReceiveUpdateShareView implements Action {
  readonly type: DashboardActionTypes.RECEIVE_UPDATE_SHARE_VIEW = DashboardActionTypes.RECEIVE_UPDATE_SHARE_VIEW;

  constructor(
    public payload: IShareViewInvite) { }
}
export class ErrorReceiveUpdateShareView implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_UPDATE_SHARE_VIEW = DashboardActionTypes.ERROR_RECEIVE_UPDATE_SHARE_VIEW;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestDeleteShareView implements Action {
  readonly type: DashboardActionTypes.REQUEST_DELETE_SHARE_VIEW = DashboardActionTypes.REQUEST_DELETE_SHARE_VIEW;

  constructor(
    public payload: IShareViewInvite) { }
}
export class ReceiveDeleteShareView implements Action {
  readonly type: DashboardActionTypes.RECEIVE_DELETE_SHARE_VIEW = DashboardActionTypes.RECEIVE_DELETE_SHARE_VIEW;

  constructor(
    public payload: IShareViewInvite) { }
}
export class ErrorReceiveDeleteShareView implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_DELETE_SHARE_VIEW = DashboardActionTypes.ERROR_RECEIVE_DELETE_SHARE_VIEW;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestUpdateShareViewEmail implements Action {
  readonly type: DashboardActionTypes.REQUEST_UPDATE_SHARE_VIEW_EMAIL = DashboardActionTypes.REQUEST_UPDATE_SHARE_VIEW_EMAIL;

  constructor(
    public payload: IShareViewEmail) { }
}
export class ReceiveUpdateShareViewEmail implements Action {
  readonly type: DashboardActionTypes.RECEIVE_UPDATE_SHARE_VIEW_EMAIL = DashboardActionTypes.RECEIVE_UPDATE_SHARE_VIEW_EMAIL;

  constructor(
    public payload: IShareViewEmail) { }
}
export class ErrorReceiveUpdateShareViewEmail implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_UPDATE_SHARE_VIEW_EMAIL = DashboardActionTypes.ERROR_RECEIVE_UPDATE_SHARE_VIEW_EMAIL;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestDeleteShareViewEmail implements Action {
  readonly type: DashboardActionTypes.REQUEST_DELETE_SHARE_VIEW_EMAIL = DashboardActionTypes.REQUEST_DELETE_SHARE_VIEW_EMAIL;

  constructor(
    public payload: IShareViewEmail) { }
}
export class ReceiveDeleteShareViewEmail implements Action {
  readonly type: DashboardActionTypes.RECEIVE_DELETE_SHARE_VIEW_EMAIL = DashboardActionTypes.RECEIVE_DELETE_SHARE_VIEW_EMAIL;

  constructor(
    public payload: IShareViewEmail) { }
}
export class ErrorReceiveDeleteShareViewEmail implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_DELETE_SHARE_VIEW_EMAIL = DashboardActionTypes.ERROR_RECEIVE_DELETE_SHARE_VIEW_EMAIL;

  constructor(
    public payload: HttpErrorResponse) { }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SuppliersService } from '../../services/suppliers.service';
import { ErrorReceiveSupplierOptions, ErrorReceiveSuppliersPage, ReceiveSupplierOptions, ReceiveSuppliersPage } from '../actions/suppliers-action-type-creators/suppliers-page-action-types.creators';
import { SuppliersActionTypes } from '../actions/suppliers-action-types';


@Injectable()
export class SuppliersEffects {
  constructor(private actions$: Actions,
    private suppliersService: SuppliersService
  ) { }

  requestSuppliersPage$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SuppliersActionTypes.REQUEST_SUPPLIERS_PAGE),
      switchMap((params: any) =>
        this.suppliersService.getSuppliersPage(params).pipe(
          map(
            (suppliers: any) => new ReceiveSuppliersPage(suppliers)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveSuppliersPage(error)))
        )
      )
    );
  });

  requestSuppliersOptions$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SuppliersActionTypes.REQUEST_SUPPLIERS_OPTIONS),
      switchMap(() =>
        this.suppliersService.getOptions().pipe(
          map((user: any) => new ReceiveSupplierOptions(user)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveSupplierOptions(error)))
        )
      )
    );
  });
}

import { ChangeDetectorRef, Component } from "@angular/core";

import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs';

import { ICellEditorAngularComp } from "ag-grid-angular";

import { IApplicationState } from "src/app/common/state/models/app.state.model";
import { DashboardActions } from "src/app/dashboard/state/actions/dashboard.actions";
import { IStoreApiItem } from "src/app/common/models/store-api-item.model";
import { ISuppliers } from "src/app/dashboard/models/dashboard.model";
import { selectAutocompleteSuppliers, selectSuppliers } from "src/app/dashboard/state/selectors/dashboard.selector";
import { PayloadService } from "src/app/dashboard/services/payload.service";

@Component({
  selector: 'autocomplete-cell-editor',
  templateUrl: "autocomplete.component.html"
})
export class AutoCompleteEditor implements ICellEditorAngularComp {
  public params: any;
  public temporarySupplierValue: any;
  public temporarySupplierList: any;
  public selectedRowId: any;
  public addSupplierModal: any;
  public addSupplierContactModal: boolean = false;
  public selectedSupplier: any;
  public assignedNameToSupplierId: any;

  constructor(
    private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private cdr: ChangeDetectorRef,
    private payloadService: PayloadService,
  ) { }

  // Overrides the default `agInit` method to set the options for the autocomplete.
  agInit(params: any): void {
    this.params = params;
    this.params.options?.forEach((supplier: any) => {
      if (supplier.id == this.params.value) {
        this.assignedNameToSupplierId = { id: this.params.value, name: supplier.name };
      }
    });
  }

  getValue(): any {
    return this.params.value;
  }

  filterSupplier(event: any, selectedRow: any) {
    this.selectedRowId = selectedRow.id;
    const query = event.query;

    if (query.trim()?.length >= 1) {
      this.onRequestAutocompleteSuppliers(query);
    }
  }

  getSuppliers(selectedRow: any) {
    this.selectedRowId = selectedRow.id;
    this.onRequestSuppliers();
  }

  checkSupplierSuggestions(rowId: any) {
    return rowId === this.selectedRowId;
  }

  // this is used to set the supplier input. if the user changes the value of the supplier and does not select anything,
  // the cell remains empty and the value is lost until a refresh. this function bypasses that case, and leaves the previous value selected
  setTemporarySupplierValue(event: any) {
    this.temporarySupplierValue = event;
  }

  onRequestAutocompleteSuppliers(query: any) {
    this.dashboardActions.requestAutocompleteSuppliers({ search_text: query });
    this.store
      .pipe(
        select(selectAutocompleteSuppliers),
        filter((suppliers: IStoreApiItem<ISuppliers>) => !suppliers.isLoading),
        take(1)
      )
      .subscribe((suppliers: IStoreApiItem<ISuppliers>) => {
        if (Array.isArray(suppliers?.data)) {
          const suppliersList = JSON.parse(JSON.stringify(suppliers?.data));
          const supplierContactsList: any = [];

          suppliersList?.map((supplier: any) => {
            supplier.value = supplier.name;
            supplierContactsList.push(supplier.contacts);
          });
          supplierContactsList?.flat().map((supplierContact: any) => {
            supplierContact.value = supplierContact.name;
          });

        /*  this.dropdownOptions = {
            ...this.dropdownOptions,
            supplier_id: suppliersList,
            supplier_contact_id: supplierContactsList?.flat()
          };*/

          this.temporarySupplierList = suppliersList;
          this.cdr.markForCheck();
        }
      });
  }

  onRequestSuppliers() {
    this.dashboardActions.requestSuppliers();
    this.store
      .pipe(
        select(selectSuppliers),
        filter((suppliers: IStoreApiItem<ISuppliers>) => !suppliers.isLoading),
        take(1)
      )
      .subscribe((suppliers: IStoreApiItem<any>) => {
        if (Array.isArray(suppliers.data.results)) {
          const suppliersList = JSON.parse(JSON.stringify(suppliers?.data.results));

          suppliersList?.map((supplier: any) => {
            supplier.value = supplier.name;
          });

          this.temporarySupplierList = suppliersList;
          this.cdr.markForCheck();
        }
      });
  }


  closeSupplier() {
    this.addSupplierModal = false;
    this.params.stopEditing();
  }

  closeSupplierContact() {
    this.addSupplierContactModal = false;
  }

  onOpenModalAddSupplier(selectedRow: any) {
    this.selectedRowId = selectedRow;
    this.addSupplierModal = true;
  }

  supplierFormChangeEvent(data: any) {
    this.addSupplierModal = false;
    this.onChange('supplier_id', data, this.selectedRowId.id);
  }

  onChange(key: string, data: any, id: string): void {


    if (key === 'supplier_id' && data === null) {
      this.dashboardActions.requestUpdateViewCell({ [`${key}`]: null, id });
    } else {
      this.dashboardActions.requestUpdateViewCell({ [`${key}`]: key == 'supplier_id' ? data?.id : data, id });

    }

    setTimeout(() => {
      this.dashboardActions.requestViews({ ...this.payloadService.setViewsPayload() });

    }, 100);
    setTimeout(() => {
      this.onRequestSuppliers();

    }, 1000);

  }
}

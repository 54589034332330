import { combineReducers } from 'redux';
import { IDashboardState } from '../models/dashboard.model';
import { activeJobsReducer } from './activeJobs.reducer';
import { activeOrdersReducer } from './activeOrders.reducer';
import { itemsReducer } from './items.reducer';
import { jobsReducer } from './jobs.reducer';
import { optionsReducer } from './options.reducer';
import { orderDocumentReducer } from './orderDocument.reducer';
import { ordersReducer } from './orders.reducer';
import { receiversReducer } from './receivers.reducer';
import { shareViewReducer } from './shareView.reducer';
import { suppliersReducer } from './suppliers.reducer';
import { templatesReducer } from './templates.reducer';
import { viewsReducer } from './views.reducer';
import {suppliersCredentialsReducer} from "./suppliersCredentials.reducer";
import { commentsReducer } from './comments.reducer';

export const dashboardReducer: any =
  combineReducers<IDashboardState>({
    jobs: jobsReducer,
    receivers: receiversReducer,
    options: optionsReducer,
    orders: ordersReducer,
    suppliers: suppliersReducer,
    credentials: suppliersCredentialsReducer,
    activeJobs: activeJobsReducer,
    items: itemsReducer,
    activeOrders: activeOrdersReducer,
    views: viewsReducer,
    activeTemplates: templatesReducer,
    orderDocument: orderDocumentReducer,
    shareView: shareViewReducer,
    comments: commentsReducer
  });

import { IStoreApiList } from 'src/app/common/models/store-api-list.model';
import { MemoizedSelector, DefaultProjectorFn, createSelector } from '@ngrx/store';
import { ApplicationState, IApplicationState } from 'src/app/common/state/models/app.state.model';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import {ISupplierCredentialsState} from "../../../dashboard/state/models/suppliersCredentials.model";
import {ISupplierCredentialsPageState} from "../models/suppliers-credentials-page.model";

const supplierCredentialsState = (state: IApplicationState) => state.credentials;

export const selectSupplierCredentialsPage: MemoizedSelector<
  ApplicationState,
  IStoreApiList<any>,
  DefaultProjectorFn<IStoreApiList<any>>
> = createSelector(supplierCredentialsState, (state: ISupplierCredentialsState | any) => state.supplierCredentialsPage);

export const selectSupplierCredentialsOptions: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IOptions>,
  DefaultProjectorFn<IStoreApiItem<IOptions>>
> = createSelector(supplierCredentialsState, (state: ISupplierCredentialsPageState | any) => state.userOptions);

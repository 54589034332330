import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IStoreApiList } from 'src/app/common/models/store-api-list.model';
import { IForgotPassword, ILoginResponse, IRecoverPassword, IOptions } from '../../models/authentication.model';
import { IAuthenticationState } from '../models/authentication.model';
import { ApplicationState, IApplicationState } from './../../../common/state/models/app.state.model';

export const authenticationState = (state: IApplicationState) => state.authentication;

export const selectSingUp: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<ILoginResponse>,
  DefaultProjectorFn<IStoreApiItem<ILoginResponse>>
> = createSelector(authenticationState, (state: IAuthenticationState | any) => state.singUp);

export const selectLogin: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<ILoginResponse>,
  DefaultProjectorFn<IStoreApiItem<ILoginResponse>>
> = createSelector(authenticationState, (state: IAuthenticationState | any) => state.login);

export const selectForgotPassword: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IForgotPassword>,
  DefaultProjectorFn<IStoreApiItem<IForgotPassword>>
> = createSelector(authenticationState, (state: IAuthenticationState | any) => state.forgotPassword);

export const selectRecoverPassword: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IRecoverPassword>,
  DefaultProjectorFn<IStoreApiItem<IRecoverPassword>>
> = createSelector(authenticationState, (state: IAuthenticationState | any) => state.recoverPassword);

export const selectUserOptions: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IOptions>,
  DefaultProjectorFn<IStoreApiItem<IOptions>>
> = createSelector(authenticationState, (state: IAuthenticationState | any) => state.userOptionsInfo.userOptions);

export const selectCompany: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(authenticationState, (state: IAuthenticationState | any) => state.userOptionsInfo.company);

export const selectRole: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(authenticationState, (state: IAuthenticationState | any) => state.userOptionsInfo.role);


<div class="change-item-container" #container>
  <div class="item-header">Move jobs</div>
  <input class="item-text-input" #textInput
          type="text"
          (focus)="startTyping($event)"
          (keydown.enter)="stopTyping($event)"
          (blur)="stopTyping($event)"
          [(ngModel)]="search"
          (input)="onSearchChange()"
          />
  <div *ngIf="filteredJobs.length" class="job-container custom-scrollbar">
    <div class="single-job"
        (click)="jobSelected(job)"
        *ngFor="let job of filteredJobs">
          <span> {{ job.name }} </span>
    </div>
  <div>
</div>
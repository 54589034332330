import { INotifications } from './../../models/user.model';
import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorItemData, errorPostPutItemData, receiveItemData, receivePostPutItemData, requestItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { UserActionTypes } from '../actions/user-action-types';
import { INotificationsState, NotificationsState } from '../models/notifications.model';

export function notificationsReducer(
  lastState: INotificationsState = new NotificationsState(),
  action: GenericAction<UserActionTypes, any>
): INotificationsState {
  switch (action.type) {

    case UserActionTypes.REQUEST_NOTIFICATIONS:
      return requestItemData<INotificationsState, INotifications>(lastState, ['notifications']);
    case UserActionTypes.RECEIVE_NOTIFICATIONS:
      return receiveItemData<INotificationsState, INotifications>(lastState, ['notifications'], action.payload);
    case UserActionTypes.ERROR_RECEIVE_NOTIFICATIONS:
      return errorItemData<INotificationsState, INotifications>(lastState, ['notifications'], action.payload);

    case UserActionTypes.REQUEST_UPDATE_NOTIFICATION:
      return requestPostPutItemData<INotificationsState, INotifications>(lastState, ['notifications'], action.payload);
    case UserActionTypes.RECEIVE_UPDATE_NOTIFICATION:
      return receivePostPutItemData<INotificationsState, INotifications>(lastState, ['notifications'], action.payload);
    case UserActionTypes.ERROR_RECEIVE_UPDATE_NOTIFICATION:
      return errorPostPutItemData<INotificationsState, INotifications>(lastState, ['notifications'], action.payload);

    default:
      return lastState;
  }
}

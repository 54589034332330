<div class="filter-container">
  <div class="filter-container-filters">
    <mlogfilters
      [isLoading]="isLoading"
      [selectedView]="selectedView"
      [activeJob]="activeJob"
      [mailBoxVisible]="mailBoxVisible"
      [viewsOptions]="optionsService.viewsOptionsObs | async"
      [dropdownOptions]="optionsService.dropdownOptionsObs | async">
    </mlogfilters>
    <sort
      [selectedView]="selectedView"
      [mailBoxVisible]="mailBoxVisible"
      [isLoading]="isLoading"
    ></sort>
    <div class="close-cards" (click)="toggleCloseCard()"
          [ngStyle]="{'background-color': closeCards ? '#FFFCE6': ''}">
      <i *ngIf="!closeCards" class="bi bi-arrows-angle-expand"></i>
      <i *ngIf="closeCards" class="bi bi-arrows-angle-contract"></i>
      {{mailBoxVisible ? '': closeCards ? 'Close': 'Open'}}
    </div>
  </div>
  <div class="filter-container-filters" style="padding-right: 36px">
    <div>
      <input
        class="px-2 py-1 rounded-full text-xs border border-neutral-300 input-element"
        type="text"
        id="filter-text-box"
        placeholder="Search..."
        [(ngModel)] = "search[selectedView?.activeView?.id ?? 'default']"
        (input)="onFilterTextBoxChanged()"
      />
    </div>
  </div>
</div>
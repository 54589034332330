import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICompanyState } from '../models/company.model';
import { RequestOrganization, RequestUpdateOrganization } from './company-action-types-creators/organization-action-types-creators';
import { RequestAddOrganizationAddress, RequestDeleteOrganizationAddress, RequestOrganizationAddresses, RequestUpdateOrganizationAddress } from './company-action-types-creators/organization-addresses-action-types-creators';

@Injectable({ providedIn: 'root' })
export class CompanyActions {
  constructor(private store: Store<ICompanyState>) { }

  public requestOrganization(params?: any): void {
    this.store.dispatch(new RequestOrganization(params));
  }

  public requestUpdateOrganization(params?: any): void {
    this.store.dispatch(new RequestUpdateOrganization(params));
  }

  public requestOrganizationAddresses(params?: any): void {
    this.store.dispatch(new RequestOrganizationAddresses(params));
  }

  public requestAddOrganizationAddress(params?: any): void {
    this.store.dispatch(new RequestAddOrganizationAddress(params));
  }

  public requestUpdateOrganizationAddress(params?: any): void {
    this.store.dispatch(new RequestUpdateOrganizationAddress(params));
  }

  public requestDeleteOrganizationAddress(params?: any): void {
    this.store.dispatch(new RequestDeleteOrganizationAddress(params));
  }
}

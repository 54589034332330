export enum TemplateActionTypes {
  REQUEST_TEMPLATES = 'REQUEST_TEMPLATES',
  RECEIVE_TEMPLATES = 'RECEIVE_TEMPLATES',
  ERROR_RECEIVE_TEMPLATES = 'ERROR_RECEIVE_TEMPLATES',

  REQUEST_DELETE_TEMPLATE = 'REQUEST_DELETE_TEMPLATE',
  RECEIVE_DELETE_TEMPLATE = 'RECEIVE_DELETE_TEMPLATE',
  ERROR_RECEIVE_DELETE_TEMPLATE = 'ERROR_RECEIVE_DELETE_TEMPLATE',

  REQUEST_UPDATE_TEMPLATE = 'REQUEST_UPDATE_TEMPLATE',
  RECEIVE_UPDATE_TEMPLATE = 'RECEIVE_UPDATE_TEMPLATE',
  ERROR_RECEIVE_UPDATE_TEMPLATE = 'ERROR_RECEIVE_UPDATE_TEMPLATE',
}

import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
/* eslint-disable @typescript-eslint/ban-types */

export type Constructor<T = {}> = new (...args: any[]) => T;

export const OnDestroyMixin = <T extends Constructor>(base: T = class { } as T) =>
  class extends base implements OnDestroy {
    destroy: any = new Subject<void>();

    ngOnDestroy(): void {
      this.destroy.next();
      this.destroy.complete();
    }
  };

import { IReceiverContact } from './../../models/dashboard.model';
import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorItemData, errorPostPutItemData, receiveItemData, receivePostPutItemData, requestItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IReceiver } from 'src/app/dashboard/models/dashboard.model';
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { IReceiversState, ReceiversState } from '../models/receivers.model';

const resetReceiverCRUDState = (lastState: IReceiversState): IReceiversState => ({
  ...lastState,
  receivers: {
    ...lastState.receivers,
    errors: null as any,
    isSuccess: null as any,
  },
});

export function receiversReducer(
  lastState: IReceiversState = new ReceiversState(),
  action: GenericAction<DashboardActionTypes, any>
): IReceiversState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_RECEIVERS:
      return requestItemData<IReceiversState, IReceiver>(lastState, ['receivers']);
    case DashboardActionTypes.RECEIVE_RECEIVERS:
      return receiveItemData<IReceiversState, IReceiver>(lastState, ['receivers'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_RECEIVERS:
      return errorItemData<IReceiversState, IReceiver>(lastState, ['receivers'], action.payload);

    case DashboardActionTypes.REQUEST_ADD_RECEIVER:
      return requestPostPutItemData<IReceiversState, IReceiver>(lastState, ['receivers'], action.payload);
    case DashboardActionTypes.RECEIVE_ADD_RECEIVER:
      return receivePostPutItemData<IReceiversState, IReceiver>(lastState, ['receivers'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ADD_RECEIVER:
      return errorPostPutItemData<IReceiversState, IReceiver>(lastState, ['receivers'], action.payload);

    case DashboardActionTypes.REQUEST_ADD_RECEIVER_CONTACT:
      return requestPostPutItemData<IReceiversState, IReceiverContact[]>(lastState, ['receiverContacts'], action.payload);
    case DashboardActionTypes.RECEIVE_ADD_RECEIVER_CONTACT:
      return receivePostPutItemData<IReceiversState, IReceiverContact[]>(lastState, ['receiverContacts'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ADD_RECEIVER_CONTACT:
      return errorPostPutItemData<IReceiversState, IReceiverContact[]>(lastState, ['receiverContacts'], action.payload);

    case DashboardActionTypes.REQUEST_AUTOCOMPLETE_RECEIVERS:
      return requestItemData<IReceiversState, IReceiver>(lastState, ['receiversAutocomplete']);
    case DashboardActionTypes.RECEIVE_AUTOCOMPLETE_RECEIVERS:
      return receiveItemData<IReceiversState, IReceiver>(lastState, ['receiversAutocomplete'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_AUTOCOMPLETE_RECEIVERS:
      return errorItemData<IReceiversState, IReceiver>(lastState, ['receiversAutocomplete'], action.payload);

    case DashboardActionTypes.RESET_CRUD_RECEIVER_STATE:
      return resetReceiverCRUDState(lastState);

    default:
      return lastState;
  }
}

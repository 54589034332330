import { Component, Injector, isDevMode, OnInit } from "@angular/core";
import { NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { PermissionsService } from '../../services/permissions.service';
import { Subscription } from 'rxjs';
// import LogRocket from 'logrocket';
import { TableConstants } from "../../../common/constants/table.constants";
@Component({
  selector: 'materlog-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent extends OnDestroyMixin() implements OnInit {
  // permissionService: PermissionsService;
  subscription: Subscription;

  constructor(
    private location: Location,
    public router: Router,
    private injector: Injector,
    private permissionService: PermissionsService
  ) {
    super();
    // this.permissionService = this.injector.get<PermissionsService>(PermissionsService);
    if(!isDevMode()) {
      // LogRocket.init('1dkn34/materlog');
    }else{
    }

    // if refresh the page
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart && !router.navigated) {
        localStorage.setItem('history', '0');
        localStorage.removeItem('temporarySupplier');
        localStorage.removeItem('temporaryPo');
        localStorage.removeItem('itemBreadcrumb');
      }
    });
  }

  ngOnInit(): void {
    if (this.location.path() === '' && !this.permissionService.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
  }

  isLoggedIn(): any {
    return this.permissionService.isLoggedIn();
  }

  isJobsPath() {
    return this.location.path().startsWith('/jobs');
  }

  isSuppliersPath() {
    return this.location.path().startsWith('/suppliers');
  }

  isSignUpUrl(): any {
    if (this.location.path() === '/signup') { return true; }
    return false;
  }

  isSharedUrl(): any {
    if (this.location.path().slice(0, 13) === '/views/shared') { return true; }
    return false;
  }
}

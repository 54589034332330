export const getDateWithAPIFormat: (date: Date) => any = (date: Date) => {
  if (!date || !(date instanceof Date)) {
    return null;
  }

  const day: string = String(date.getDate()).padStart(2, '0');
  const month: string = String(date.getMonth() + 1).padStart(2, '0');

  return month + '/' + day + '/' + date.getFullYear();
};

import { IStoreApiList, StoreApiList } from 'src/app/common/models/store-api-list.model';
import { ITemplates } from '../../models/dashboard.model';

export interface ITemplatesState {
  templates: IStoreApiList<ITemplates>;
}

export class TemplatesState implements ITemplatesState {
  templates: IStoreApiList<ITemplates> = new StoreApiList();
}

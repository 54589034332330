import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { SelectedViewModel } from '../../models/selected-view.model';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { ElementRef, HostListener } from '@angular/core';
import { MailBoxCommunicationService } from '../../services/mailbox_comm.service';
import { selectComments, selectViews} from '../../state/selectors/dashboard.selector';
import { selectActiveJobs } from '../../state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { CommentsService } from '../../services/comment.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { MentionModule } from 'angular-mentions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { deepCopy } from 'src/app/common/utils/general';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { ActiveJobModel } from '../../models/active-job.model';
import { JobTableService } from '../../services/job-table.service';
import { initData } from '../mail-view/utils';
import { JobsService } from '../../services/job.service';
import { DashboardService } from '../../services/dashboard.service';
import { OrderService } from '../../services/order.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'global-submittals',
  templateUrl: './global-submittals.component.html',
  styleUrls: ['./global-submittals.component.css'],
})
export class GlobalSubmittalsComponent implements OnInit {
 
  @Output() close = new EventEmitter<void>();
  @Output() newSubmittalType = new EventEmitter<any>();
  @Output() deleteSubmittalType = new EventEmitter<any>();
  @Output() changeSubmittalTypeName = new EventEmitter<any>();
  @Output() applySubmittals = new EventEmitter<any>();
  @Input() submittalData: any;
  @Input() card: any;
  @Input() options: any;

  @ViewChild('globalSubmittalsContainer') globalSubmittalsContainer!: ElementRef;

  allGlobalCheckbox: boolean = false;
  optionTypes: any = [];
  ItemNameHeader = 'ITEM';
  cardSubs: any = [];

  gotCard: boolean = false;
  gotSubmittals: boolean = false;
  gotOptions: boolean = false;
  checkboxCreated: boolean = false;

  globalStatus: any = {status: -1};

  viewStyle: number = 0;
  newSubmittalName = '';
  showAddSubmittalInput: boolean = false;

  constructor(
    private mailCommunicationService: MailBoxCommunicationService,
    private store: Store,
    private commentsService: CommentsService,
    private dashboardService: DashboardService,
    private dashboardActions: DashboardActions,
    private elRef: ElementRef,
    private sanitizer: DomSanitizer,
    private djv: DashboardJobView,
    private jobTableService: JobTableService,
    private jobService: JobsService,
    private cdr: ChangeDetectorRef,
    private orderService: OrderService,
    private httpClient: HttpClient,
    ) {
      (window as any)['global'] = this;
    }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setScrollBar('.submittals-container');
    this.setScrollBar('.item-container');
  }

  ngOnChanges(changes: any) {
    if (changes?.options?.currentValue) {
      let vals = changes.options.currentValue.types
        .map((c: any) => ({...c, check: false, isEditing: false}))
        .filter((c: any) => c.id != -1);
      let cur = [{id: -1, value: this.ItemNameHeader, check: false, isEditing: false}];
      let optionTypes = cur.concat(vals);
      this.optionTypes = optionTypes.map((o: any) => ({...o, newName: o.value}));
      this.gotOptions = true;
      this.tryCreateCheckbox();
    }
    if (changes?.submittalData?.currentValue) {
      this.gotSubmittals = true;
      this.tryCreateCheckbox();
    }
    if (changes?.card?.currentValue) {
      this.gotCard = true;
      this.tryCreateCheckbox();
    }
  }

  get allDataRetrieved() {
    return this.gotSubmittals && this.gotCard && this.gotOptions;
  }

  tryCreateCheckbox() {
    if (this.allDataRetrieved) {
      this.createCardCheckboxes();
    }
  }

  closeDialog() {
    this.close.emit();
  }

  changeViewStyle(val: number) {
    this.viewStyle = val;
    this.showAddSubmittalInput = false;
    this.optionTypes.forEach((ot: any) => {
      ot.check = false;
    })
    this.cardSubs.forEach((c: any) => {
      c.check = false;
      for (const [key, value] of Object.entries(c.types)) {
        (value as any).status_check = false;
      }
    })
  }

  createCardCheckboxes() {
    let out: any = [];
    this.card.items.forEach((i: any) => {
      let cur: any = {item_id: i.id, name: i.name, check: false};
      let sub = this.submittalData.find((s: any) => s.item_id == i.id);
      let otypes: any = {};
      this.optionTypes.slice(1).forEach((ot: any) => {
        let st = sub?.values?.find((s: any) => s.type_id == ot.id);
        let val: any = {
          check: false, status_check: false,
          status: 0, submittal: st?.id || null,
          item: i.id
        };
        if (st) {
          val.check = true;
          val.status = st.status;
        }
        otypes[ot.id] = val;
      });
      cur.types = otypes;
      out.push(cur);
    })
    this.cardSubs = out;
  }


  globalClicked(type: any) {
    if (type.value == this.ItemNameHeader) {
      this.optionTypes.forEach((ot: any) => {
        ot.check = type.check;
      })
    }
    this.cardSubs.forEach((c: any) => {
      if (type.value == this.ItemNameHeader) {
        c.check = type.check;
      }
      for (const [key, value] of Object.entries(c.types)) {
        if (type.value == this.ItemNameHeader || key == type.id) {
          this.checkItem(c, key, type.check);
        }
      }
    })
  }

  maybeSetItemStatus(curItem: any, key: any, val: any) {
    if (val && !curItem.types[key].status) {
      curItem.types[key].status = 1;
    } else if (!val) {
      curItem.types[key].status = 0;
    }
  }

  checkItem(curItem: any, key: any, val: any) {
    if (this.viewStyle == 1) {
      curItem.types[key].check = val;
      this.maybeSetItemStatus(curItem, key, val);
    } else {
      if (curItem.types[key].status) {
        curItem.types[key].check = val;
      }
      curItem.types[key].status_check = val;
    }
  }

  itemRowClicked(item: any) {
    let curItem = this.cardSubs.find((i: any) => i.item_id == item.item_id);
    for (const [key, value] of Object.entries(curItem.types)) {
      this.checkItem(curItem, key, item.check);
    }
  }
  
  singleOptionClicked(item: any, type: any) {
    this.maybeSetItemStatus(item, type.id, item.types[type.id].check);
  }

  applyAllSelect() {
    let out: any = [];
    this.cardSubs.forEach((c: any) => {
      for (const [key, value] of Object.entries(c.types)) {
        if ((value as any).check && (key as any) != -1) {
          let obj: any = value;
          let cur = {item: obj.item, type: key,
            status: obj.status, submittal: obj.submittal};
          out.push(cur);
        }
      }
    });
    let payload = {order: this.card.items[0].order_id, values: out};
    this.applySubmittals.emit(payload);
  }


  colorForStatus(submittal: any) {
    switch (submittal.status) {
      case 1:
        return '#C5C5C5';
      case 2:
        return '#F1B73F';
      case 3:
        return '#5FC64C';
      default:
        return '#C5C5C5';
    }
  }

  onUpdateStatus(event: any, item: any, type: any) {
    item.types[type.id].status = event.event.value.id;
  }

  onUpdateGlobalStatus(event: any) {
    let stat = event.event.value.id;
    this.globalStatus.status = stat;
    this.cardSubs.forEach((c: any) => {
      for (const [key, value] of Object.entries(c.types)) {
        if (c.types[key].status_check && c.types[key].status) {
          c.types[key].status = stat;
        }
      }
    })
  }

  dataForStatus(status: any) {
    return this.options.statuses.find((s: any) => s.id == status);
  }

  startEditingSubmittalName(event: any) {

  }

  blurSubmittalName(event: any) {
    event.target.blur();
  }

  stopEditingSubmittalName() {
    this.showAddSubmittalInput = false;
    let newName = this.newSubmittalName.trim();
    if (newName) {
      this.newSubmittalType.emit(newName);
    }
    this.newSubmittalName = '';
  }

  addSubmittalType() {

  }

  setScrollBar(v: string) {
    setTimeout(() => {
      let jdisplays = this.globalSubmittalsContainer.nativeElement.querySelectorAll(v);
      if (jdisplays) {
        jdisplays.forEach((jdisplay: any) => {
          jdisplay.addEventListener('mouseenter', function() {
            (jdisplay as any).classList.add('custom-scrollbar');
            (jdisplay as any).classList.remove('custom-scrollbar-close');
          });
          jdisplay.addEventListener('mouseleave', function() {
            (jdisplay as any).classList.remove('custom-scrollbar');
            (jdisplay as any).classList.add('custom-scrollbar-close');
          });
        })
      }
    }, 500);

  }

  editSubmittalType(event: any, stype: any) {
    stype.isEditing = true;
    event.stopPropagation();
    const parentElement = (event.target as HTMLElement).closest('.type-header-name');
    setTimeout(() => {
      const inputElement = parentElement?.querySelector('.item-text-input') as HTMLInputElement;
      inputElement.focus();
      inputElement.setSelectionRange(0, 0);
      inputElement.scrollLeft = 0;
    }, 50);
  }

  removeSubmittalType(event: any, stype: any) {
    event.stopPropagation();
    this.deleteSubmittalType.emit(stype.id);
  }

  doesTypeExist(name: any) {
    return this.optionTypes.find((t: any) => t.value.toLowerCase() == name.toLowerCase());
  }

  stopEditingSubmittalType(stype: any) {
    stype.isEditing = false;
    stype.newName = stype.newName.trim();
    if (stype.newName.toLowerCase() == stype.value.toLowerCase()) return;
    if (!stype.newName) {
      stype.newName = stype.value;
      alert('Submittal type must have a name!');
      return;
    }
    if (this.doesTypeExist(stype.newName)) {
      stype.newName = stype.value;
      alert('Cannot add submittal type with same name!');
      return;
    }
    let newval = stype.newName;
    newval = newval[0].toUpperCase() + newval.slice(1).toLowerCase();
    stype.value = newval;
    this.changeSubmittalTypeName.emit({id: stype.id, name: stype.value});
  }
}
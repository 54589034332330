import { Input, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { Output, EventEmitter } from '@angular/core';

import { MessageService } from 'primeng/api';
import { faExclamationTriangle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { TableConstants } from 'src/app/common/constants/table.constants';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { selectTemplates } from '../../state/selectors/template.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { ITemplate } from '../../models/template.model';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { selectActiveJobs, selectOptions } from 'src/app/dashboard/state/selectors/dashboard.selector';
import { IOptions } from 'src/app/authentication/models/authentication.model';
import { IActiveJobs } from 'src/app/dashboard/models/dashboard.model';
import { TemplateActions } from '../../state/actions/template.actions';
import { PayloadService } from 'src/app/dashboard/services/payload.service';
import { SelectedViewModel } from 'src/app/dashboard/models/selected-view.model';
import { TemplateService } from '../../services/template.service';

@Component({
  selector: 'materlog-templates',
  templateUrl: './templates-dialog.component.html',
  styleUrls: ['./templates-dialog.component.sass']
})
export class TemplatesDialogComponent extends OnDestroyMixin() implements OnInit {
  @Input() selectedView?: SelectedViewModel;
  @Input() show: boolean = false;
  faTriangle = faExclamationTriangle;
  faXMark = faXmark;

  showDialog: boolean = false;
  isLoading: boolean = true;

  templateList: any;
  jobList: any;
  privacyList: any;

  constructor(
    public router: Router,
    private store: Store<IApplicationState>,
    private templateActions: TemplateActions,
    private dashboardActions: DashboardActions,
    private messageService: MessageService,
    private payloadService: PayloadService,
    private templateService: TemplateService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    (window as any)['temp'] = this;
  }

  @Output() close = new EventEmitter<void>();

  ngOnChanges(changes: any) {
    if (changes.show) {
      this.showDialog = this.show;
    }
  }

  ngOnInit(): void {
    this.onRequestTemplates();
    this.onRequestOptions();
    this.onRequestActiveJobs();
  }

  onRequestTemplates() {
    this.templateService.getTemplates()
      .subscribe((templates: any) => {
        this.isLoading = false;
        this.templateList = templates;
        // the foreach is used to replace the jobs objects which are type {id,name} into id only so it can be selected by the dropdown
        this.templateList?.forEach((template: any) => {
          template.jobs = template.jobs?.map((job: any) => {
            return job.id;
          });
        });
        this.cdr.detectChanges();
      });
  }

  onRequestOptions() {
    this.store
      .pipe(
        select(selectOptions),
        filter((options: IStoreApiItem<IOptions>) => !options.isLoading),
        take(1)
      )
      .subscribe((options: IStoreApiItem<IOptions>) => {
        this.privacyList = JSON.parse(JSON.stringify(options?.data?.privacy));
      });
  }

  onRequestActiveJobs() {
    this.store
      .pipe(
        select(selectActiveJobs),
        filter((activeJobs: IStoreApiItem<IActiveJobs>) => !activeJobs.isLoading),
        take(1)
      )
      .subscribe((activeJobs: IStoreApiItem<IActiveJobs>) => {
        this.jobList = JSON.parse(JSON.stringify(activeJobs?.data));
        // all jobs can't be added in the Back-End so we add the option manually
        this.jobList.unshift({ id: 'all_jobs', name: 'All jobs' });
      });
  }

  deleteTemplate(id: string) {
    this.templateActions.requestDeleteTemplate(id);

    this.store
      .pipe(
        select(selectTemplates),
        filter((templates: IStoreApiItem<ITemplate>) => !templates.isLoading),
        take(1)
      )
      .subscribe((templates: any) => {
        if (templates?.isSuccess) {
          this.templateList = JSON.parse(JSON.stringify(templates?.data));
          this.templateList?.forEach((template: any) => {
            template.jobs = template.jobs?.map((job: any) => {
              return job.id;
            });
          });
          this.onRefreshData();
        }

        if (templates?.errors) {
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong. Please try again later. If this issue persists, contact support@materlog.com.',
            key: 'error',
          });
        }
      });
  }

  onUpdateTemplate(temp: any) {
    let objToSend: any;

    if (temp.is_owner) {
      objToSend = JSON.parse(JSON.stringify(temp));

      //Because id 'all_jobs' does not exist in the Back-End, we set a boolean depending on if the option is selected or not in the FE
      if (objToSend.jobs.includes('all_jobs')) {
        objToSend.jobs.splice(objToSend.jobs.indexOf('all_jobs'), 1);
        objToSend.apply_to_all_jobs = true;
      } else {
        objToSend.apply_to_all_jobs = false;
      }
    } else {
      objToSend = {
        apply_to_each_new_jobs: JSON.parse(JSON.stringify(temp.apply_to_each_new_jobs)),
        jobs: JSON.parse(JSON.stringify(temp.jobs)),
        id: JSON.parse(JSON.stringify(temp.id))
      };

      //Because id 'all_jobs' does not exist in the Back-End, we set a boolean depending on if the option is selected or not in the FE
      if (objToSend.jobs.includes('all_jobs')) {
        objToSend.jobs.splice(objToSend.jobs.indexOf('all_jobs'), 1);
        objToSend.apply_to_all_jobs = true;
      } else {
        objToSend.apply_to_all_jobs = false;
      }
    }

    let can_delete = true;
    if (this.selectedView && this.selectedView.activeView) {
      let cur_job = this.selectedView.selectedJobId;
      let sel_view = localStorage.getItem(`viewIdFor${cur_job}`) as any;
      if (sel_view.replaceAll('"', '') == this.selectedView.activeView.id
          && this.selectedView.activeView.template_id) {
        if (this.selectedView.views.length == 1) {
          can_delete = false;
        } else {
          const cur_view = this.selectedView.activeView;
          let new_cur = this.selectedView.views.find((v) => v.id != cur_view.id);
          if (!new_cur) {
            can_delete = false;
          } else {
            cur_view.selected = false;
            new_cur.selected = true;
            localStorage.setItem(`viewIdFor${cur_job}`, JSON.stringify(new_cur.id));
          }
        }
      }
    }
    
    if (!can_delete) {
      this.dashboardActions.requestAddViewReturn({
        NewView: true, 
        name: 'Default View',
        job: this.selectedView?.selectedJobId === 'all' ? undefined : this.selectedView?.selectedJobId,
        fields_selected_to_display: JSON.parse(JSON.stringify(TableConstants.jobItemsTableColumns))
      }).subscribe(
        (results) => {
          if (results && results.views && this.selectedView) {
            let cur_job = this.selectedView.selectedJobId;
            let view = results.views.find((v: any) => v.name == 'Default View');
            if (view) {
              localStorage.setItem(`viewIdFor${cur_job}`, JSON.stringify(view.id));
            }
          }
          this.doUpdateTemplate(objToSend);
        });
    } else {
      this.doUpdateTemplate(objToSend);
    }
  }

  doUpdateTemplate(objToSend: any) {
    this.templateActions.requestUpdateTemplate(objToSend);

    this.store
      .pipe(
        select(selectTemplates),
        filter((templates: IStoreApiItem<ITemplate>) => !templates.isLoading),
        take(1)
      )
      .subscribe((template: any) => {
        if (template?.isSuccess) {
          this.onRefreshData();
        }

        if (template?.errors) {
          this.onRequestTemplates();
          this.messageService.clear();
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong. Please try again later. If this issue persists, contact support@materlog.com.',
            key: 'error',
          });
        }
      });
  }

  onRefreshData() {
    this.dashboardActions.requestViews({ ...this.payloadService.setViewsPayload() });
    this.dashboardActions.requestActiveTemplates();
  }

  closeDialog() {
    this.showDialog = false;
    this.close.emit();
    // this.router.navigate([{ outlets: { templates: null } }]);
  }
}

import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { INotifications } from '../../models/user.model';


export interface INotificationsState {
  notifications: IStoreApiItem<INotifications>;
}

export class NotificationsState implements INotificationsState {
  notifications: IStoreApiItem<INotifications> = new StoreApiItem();
}

// eslint-disable-next-line no-shadow
export enum ReceiversActionTypes {
  REQUEST_RECEIVERS_PAGE = 'REQUEST_RECEIVERS_PAGE',
  RECEIVE_RECEIVERS_PAGE = 'RECEIVE_RECEIVERS_PAGE',
  ERROR_RECEIVE_RECEIVERS_PAGE = 'ERROR_RECEIVE_RECEIVERS_PAGE',

  REQUEST_RECEIVERS_OPTIONS = 'REQUEST_RECEIVERS_OPTIONS',
  RECEIVE_RECEIVERS_OPTIONS = 'RECEIVE_RECEIVERS_OPTIONS',
  ERROR_RECEIVE_RECEIVERS_OPTIONS = 'ERROR_RECEIVE_RECEIVERS_OPTIONS',
}

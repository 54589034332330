import { IOptions } from 'src/app/authentication/models/authentication.model';
import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';

export interface IOptionsState {
  options: IStoreApiItem<IOptions>;
}

export class OptionsState implements IOptionsState {
  options: IStoreApiItem<IOptions> = new StoreApiItem();
}

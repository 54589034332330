import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { ILoginResponse } from './../../../models/authentication.model';
import { ILogin } from '../../../models/authentication.model';
import { AuthenticationActionTypes } from '../authentication-action-types';

export class RequestLogin implements Action {
  readonly type: AuthenticationActionTypes.REQUEST_LOGIN = AuthenticationActionTypes.REQUEST_LOGIN;

  constructor(
    public payload: ILogin) { }
}

export class ReceiveLogin implements Action {
  readonly type: AuthenticationActionTypes.RECEIVE_LOGIN = AuthenticationActionTypes.RECEIVE_LOGIN;

  constructor(
    public payload: ILoginResponse) { }
}

export class ErrorReceiveLogin implements Action {
  readonly type: AuthenticationActionTypes.ERROR_LOGIN = AuthenticationActionTypes.ERROR_LOGIN;

  constructor(
    public payload: HttpErrorResponse) { }
}

import { UserActionTypes } from '../user-action-types';
import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IUserManagement } from 'src/app/user-settings/models/user.model';


export class RequestUserProfile implements Action {
  readonly type: UserActionTypes.REQUEST_USER_PROFILE = UserActionTypes.REQUEST_USER_PROFILE;

  constructor(
    public payload?: string) { }
}
export class ReceiveUserProfile implements Action {
  readonly type: UserActionTypes.RECEIVE_USER_PROFILE = UserActionTypes.RECEIVE_USER_PROFILE;

  constructor(
    public payload?: IUserManagement) { }
}
export class ErrorReceiveUserProfile implements Action {
  readonly type: UserActionTypes.ERROR_RECEIVE_USER_PROFILE = UserActionTypes.ERROR_RECEIVE_USER_PROFILE;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class RequestUpdateUserProfile implements Action {
  readonly type: UserActionTypes.REQUEST_UPDATE_USER_PROFILE = UserActionTypes.REQUEST_UPDATE_USER_PROFILE;

  constructor(
    public payload: IUserManagement) { }
}

export class ReceiveUpdateUserProfile implements Action {
  readonly type: UserActionTypes.RECEIVE_UPDATE_USER_PROFILE = UserActionTypes.RECEIVE_UPDATE_USER_PROFILE;

  constructor(
    public payload: IUserManagement) { }
}
export class ErrorReceiveUpdateUserProfile implements Action {
  readonly type: UserActionTypes.ERROR_RECEIVE_UPDATE_USER_PROFILE = UserActionTypes.ERROR_RECEIVE_UPDATE_USER_PROFILE;

  constructor(
    public payload: HttpErrorResponse) { }
}

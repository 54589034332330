import { IStoreApiList, StoreApiList } from 'src/app/common/models/store-api-list.model';
import { IViews } from '../../models/dashboard.model';

export interface IViewsState {
  views: IStoreApiList<IViews>;
  uncategorizedView: IStoreApiList<IViews>;
}

export class ViewsState implements IViewsState {
  views: IStoreApiList<IViews> = new StoreApiList();
  uncategorizedView: IStoreApiList<IViews> = new StoreApiList();
}

import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { INewOrder, IOrders } from '../../models/dashboard.model';

export interface IOrdersState {
  newOrders: IStoreApiItem<INewOrder>;
  orders: IStoreApiItem<IOrders>;
  roomOptions: IStoreApiItem<any>;
}

export class OrdersState implements IOrdersState {
  newOrders: IStoreApiItem<INewOrder> = new StoreApiItem();
  orders: IStoreApiItem<IOrders> = new StoreApiItem();
  roomOptions: IStoreApiItem<any> = new StoreApiItem();
}

<div class="card-container">
  <div class="all-view card-view" (click)="changeViewStyle(1)"
        [ngClass]="viewStyle == 1 ? 'active-view': 'inactive-view'">
    <i class="bi bi-grid"></i>
  </div>
  <div class="all-view item-view" (click)="changeViewStyle(0)"
      [ngClass]="viewStyle == 0 ? 'active-view': 'inactive-view'">
      <i class="bi bi-list-task"></i>
  </div>
  <!--
  <div class="all-view filter-control"
      [ngClass]="filterActive ? 'active-view': 'inactive-view'"    
      (click)="filterToggle()">
    <i class="bi bi-filter"></i>
    <div class="filter-text">
      Filter
    </div>
  </div>
  -->

</div>
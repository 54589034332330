export class JobModel {

  static fromJSON(object: any): JobModel {
    return new JobModel(
      object.category,
      object.created_datetime,
      object.delivery_date,
      object.delivery_method,
      object.description,
      object.floor,
      object.id,
      object.order_id,
      object.images,
      object.in_stock_date,
      object.in_stock_ship_tags,
      object.is_reimbursable,
      object.job_id,
      object.last_4_card_digits,
      object.link_to_material,
      object.name,
      object.next_supplier_follow_up_date,
      object.next_supplier_follow_up_time,
      object.last_email_sent,
      object.next_supplier_user_view,
      object.next_supplier_follow_up_note,
      object.next_checkin_date,
      object.next_checkin_note,
      object.custom_supplier_follow_up_date,
      object.notes,
      object.payment_method,
      object.po_document_attachments,
      object.po_document_date,
      object.po_document_number,
      object.quantity,
      object.room_id,
      object.ship_date,
      object.shipping_address,
      object.shipping_city,
      object.shipping_country,
      object.shipping_name,
      object.shipping_state,
      object.shipping_to,
      object.shipping_zip,
      object.sku,
      object.status,
      object.status_color,
      object.supplier_contact_email,
      object.supplier_contact_id,
      object.supplier_contact_phone,
      object.supplier_contact_role,
      object.supplier_contact_title,
      object.supplier_documents_attachments,
      object.supplier_documents_earliest_document_date,
      object.supplier_documents_numbers,
      object.supplier_email,
      object.supplier_id,
      object.supplier_phone,
      object.supplier_website,
      object.total_cost,
      object.track_order,
      object.tracking_info,
      object.updated_datetime,
      object.fields_for_history_visible,
      object.size,
      object.finish,
      object.warehouse_id,
      object.pallet_number,
      object.cost_per_unit,
      object.discount,
      object.shipping_cost,
      object.warehouse_rcpt_date,
      object.custom1t,
      object.custom2t,
      object.custom3t,
      object.custom5u,
      object.custom6d,
      object.supplier_tracking,
      object.shipment_tracking,
      object.fob_point,
      object.unit_of_measure,
      object.requested_delivery_date,
      object.lead_time,
      object.linked_item,
      object.payment_sent,
      object.payment_received,
      object.spec,
      object.sales_tax,
      object.lead_time_start,
      object.notes_external,
      object.item_com_selection,
      object.has_subitems,
      object.order_notes,
      object.last_checked_datetime,
      object.status_change_date,
      object.dependencies
    )
  }

  constructor(
    readonly category: number,
    readonly created_datetime: string,
    readonly delivery_date: string | null,
    readonly delivery_method: number,
    readonly description: string,
    readonly floor: number,
    readonly id: string,
    readonly order_id: string,
    readonly images: string[],
    readonly in_stock_date: string | null,
    readonly in_stock_ship_tags: number,
    readonly is_reimbursable: number,
    readonly job_id: string,
    readonly last_4_card_digits: string,
    readonly link_to_material: string,
    readonly name: string,
    readonly next_supplier_follow_up_date: string | null,
    readonly next_supplier_follow_up_time: string | null,
    readonly last_email_sent: string | null,
    readonly next_supplier_user_view: string | null,
    readonly next_supplier_follow_up_note: string | null,
    readonly next_checkin_date: string | null,
    readonly next_checkin_note: string | null,
    readonly custom_supplier_follow_up_date: string | null,
    readonly notes: string,
    readonly payment_method: number,
    readonly po_document_attachments: string[],
    readonly po_document_date: string | null,
    readonly po_document_number: { id: string; number: string },
    readonly quantity: string,
    readonly room_id: any,
    readonly ship_date: string,
    readonly shipping_address: string,
    readonly shipping_city: string,
    readonly shipping_country: string | null,
    readonly shipping_name: string,
    readonly shipping_state: number,
    readonly shipping_to: number,
    readonly shipping_zip: string,
    readonly sku: string,
    readonly status: number,
    readonly status_color: string,
    readonly supplier_contact_email: any,
    readonly supplier_contact_id: any,
    readonly supplier_contact_phone: any,
    readonly supplier_contact_role: any,
    readonly supplier_contact_title: any,
    readonly supplier_documents_attachments: any,
    readonly supplier_documents_earliest_document_date: any,
    readonly supplier_documents_numbers: any,
    readonly supplier_email: string,
    readonly supplier_id: string,
    readonly supplier_phone: string,
    readonly supplier_website: string,
    readonly total_cost: string,
    readonly track_order: number,
    readonly tracking_info: string,
    readonly updated_datetime: string,
    readonly fields_for_history_visible: string,
    readonly size: string|null,
    readonly finish: string|null,
    readonly warehouse_id: string|null,
    readonly pallet_number:string|null,
    readonly cost_per_unit: string,
    readonly discount:string,
    readonly shipping_cost:string,
    readonly warehouse_rcpt_date: string|null,
    readonly custom1t: string|null,
    readonly custom2t: string|null,
    readonly custom3t: string|null,
    readonly custom5u: string|null,
    readonly custom6d: string|null,
    readonly supplier_tracking: string|null,
    readonly shipment_tracking: string|null,
    readonly fob_point: string|null,
    readonly unit_of_measure: number,
    readonly requested_delivery_date: string|null,
    readonly lead_time: string|null,
    readonly linked_item: string|null,
    readonly payment_sent: string|null,
    readonly payment_received: string|null,
    readonly spec: string|null,
    readonly sales_tax: string|null,
    readonly lead_time_start: string|null,
    readonly notes_external: string|null,
    readonly item_com_selection: number,
    readonly has_subitems: boolean,
    readonly order_notes: string|null,
    readonly last_checked_datetime: string[],
    readonly status_change_date: string|null,
    readonly dependencies: string[],
  ) { }
}

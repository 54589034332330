<div class="suppliers">
  <div class="suppliers__container">
    <materlog-loader class="loader" *ngIf="isLoading"></materlog-loader>
    <p-table
      *ngIf="!isLoading"
      [columns]="columnsSuppliers"
      [value]="suppliers"
      styleClass="editable-table"
      [resizableColumns]="true"
      [reorderableColumns]="true"
      [columnResizeMode]="'expand'"
      [scrollable]="true"
      scrollHeight="flex"
      dataKey="id"
      [virtualScroll]="true"
      [virtualScrollItemSize]="32"
      [rowTrackBy]="trackByRows"
      [rows]="100"
      [lazy]="true"
      (onLazyLoad)="loadJobsLazy()"
    >
      <ng-template pTemplate="header" let-columns>
        <tr style="height: 32px">
          <th
            *ngFor="let col of columnsSuppliers"
            [hidden]="!col.visible"
            pResizableColumn
            pReorderableColumn
            [ngStyle]="{
              textOverflow: 'ellipsis'
            }"
          >
            <div class="row-border-right">{{ col.header ? col.header : '&nbsp;' }}</div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-data let-columns="columns" let-index="rowIndex" let-expanded="expanded">
        <tr style="height: 32px">
          <td
            *ngFor="let col of columns"
            [hidden]="!col.visible"
            pEditableColumn
            [ngStyle]="{
              height: '32px',
              width: col.field === 'expansion_button' ? '40px' : 'auto',
              textOverflow: 'ellipsis',
              color: col.field === 'index' ? '#9a9a9a' : 'inherit'
            }"
          >
            <span
              class="cell-output"
              [ngClass]="{ expanded: expanded, 'no-border-top': !index }"
              *ngIf="!col.type && !customColumnsLayout?.includes(col.field)"
            >
              {{ data[col.field] }}
            </span>
            <span *ngIf="col.type === 'dropdown' && !customColumnsLayout?.includes(col.field)" class="cell-output">
              <ng-container *ngFor="let dropdownItem of dropdownOptions[col.field]">
                <ng-container *ngIf="dropdownItem.id === data[col.field]">
                  {{ dropdownItem.value === 'Not selected' || dropdownItem.value === 'No' ? '' : dropdownItem.value }}
                </ng-container>
              </ng-container>
            </span>

            <!--Custom columns layout-->
            <div
              *ngIf="col.field === 'index'"
              class="cell-output"
              [ngClass]="{ expanded: expanded, 'no-border-top': !index }"
            >
              <span class="cell-centered-text"> {{ index + 1 }} </span>
            </div>
            <div
              *ngIf="col.field === 'expansion_button'"
              class="cell-output"
              [ngClass]="{ expanded: expanded, 'no-border-top': !index }"
              [ngStyle]="{
                minWidth: '40px'
              }"
            >
              <i [pRowToggler]="data" ngClass="{{ expanded ? 'bi bi-chevron-down' : 'bi bi-chevron-right' }}"></i>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-supplier>
        <tr>
          <td colspan="7">
            <div>
              <p-table
                *ngIf="!isLoading && supplier.contacts.length"
                [value]="supplier.contacts"
                dataKey="id"
                [columns]="columnsSupplierContacts"
                styleClass="editable-table nested-table"
                [resizableColumns]="true"
                [reorderableColumns]="true"
                [columnResizeMode]="'expand'"
                [scrollable]="true"
                scrollHeight="flex"
                dataKey="warehouse_name"
              >
                <ng-template pTemplate="header" let-columns>
                  <tr style="height: 32px">
                    <th
                      *ngFor="let col of columnsSupplierContacts"
                      [hidden]="!col.visible"
                      pResizableColumn
                      pReorderableColumn
                      [ngStyle]="{
                        textOverflow: 'ellipsis'
                      }"
                    >
                      <div class="row-border-right">{{ col.header ? col.header : '&nbsp;' }}</div>
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-data let-columns="columns" let-index="rowIndex">
                  <tr style="height: 32px">
                    <td
                      *ngFor="let col of columnsSupplierContacts"
                      [hidden]="!col.visible"
                      pEditableColumn
                      [ngStyle]="{
                        height: '32px',
                        textOverflow: 'ellipsis',
                        color: col.field === 'index' ? '#9a9a9a' : 'inherit'
                      }"
                    >
                      <span *ngIf="!col.type && !customColumnsLayout?.includes(col.field)" class="cell-output">
                        {{ data[col.field] }}
                      </span>
                      <span
                        *ngIf="col.type === 'dropdown' && !customColumnsLayout?.includes(col.field)"
                        class="cell-output"
                      >
                        <ng-container *ngFor="let dropdownItem of dropdownOptions[col.field]">
                          <ng-container *ngIf="dropdownItem.id === data[col.field]">
                            {{
                              dropdownItem.value === 'Not selected' || dropdownItem.value === 'No'
                                ? ''
                                : dropdownItem.value
                            }}
                          </ng-container>
                        </ng-container>
                      </span>

                      <!--Custom columns layout-->
                      <div *ngIf="col.field === 'index'" class="cell-output">
                        <span class="cell-centered-text"> {{ index + 1 }} </span>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <div *ngIf="!isLoading && !supplier.contacts.length">No contacts</div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length">
            <div class="empty-table">
              <img src="assets/img/empty-table-loop.svg" alt="Magnifying glass empty table" />
              <b class="empty-table-box-bold">Start adding suppliers</b>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div class="dashboard-container">
  <ng-container *ngIf="isLoading && !dataLoaded">
    <div class="flex flex-col">
      <div style="margin-top: 15px"></div>
      <div class="flex flex-row">
        <div style="margin-left: 20px"></div>
        <p-skeleton height="20px" width="200px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
        <div style="margin-left: 10px"></div>
        <p-skeleton height="20px" width="200px" styleClass="mb-2" borderRadius="16px"></p-skeleton>
      </div>
      <div class="flex flex-col" style="margin-left: 10px">
        <div style="margin-top: 20px"></div>
        <div *ngFor="let item of skeletonItems">
          <p-skeleton height="20px" [width]="item.width" styleClass="mb-2" borderRadius="16px"></p-skeleton>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isLoading && dataLoaded">
    <div class="flex flex-col">
      <div>
        <div class="job-section flex flex-row">
          <div
            class="job-list__item"
            *ngFor="let category of categories; let i = index"
            ngClass="{{ selectedCategory === category ? 'job-list__name job-list__name--active' : 'job-list__name' }}"
            (click)="setJobType(category)"
          >
            {{ category }}
          </div>
        </div>
      </div>
    
      <div *ngIf="pageActive" class="content-container"
          [ngClass]="mailBoxVisible || commentsVisible ? (mailBoxVisible ? 'mail-inner' : 'comment-inner'): 'default-width'">
        <p-table
          #materlogTable
          [loading]="isLoading"
          styleClass="p-datatable-gridlines editable-table editable-table--jobs"
          [columns]="selectedColumns"
          [value]="filteredJobs"
          [resizableColumns]="true"
          [reorderableColumns]="true"
          [reorderableColumns]="true"
          [columnResizeMode]="'expand'"
          [scrollable]="true"
          scrollHeight="flex"
          (onColResize)="onColResize($event, selectedColumns)"
          [contextMenu]="cm"
          [(contextMenuSelection)]="selectedRow"
        >
          <ng-template pTemplate="header" let-columns>
            <tr style="display: flex">
              <th
                *ngFor="let col of columns"
                pResizableColumn
                pReorderableColumn
                pFrozenColumn
                [frozen]="col?.is_frozen_column"
                [ngStyle]="{
                  width: col.width + 'px',
                  textOverflow: 'ellipsis',
                  fontSize: col.field === 'user_tracking' ? '7.5px' : 'null',
                  whiteSpace: col.field === 'user_tracking' ? 'normal' : 'no-wrap'
                }"
              >
                <span [ngClass]="col.field === 'user_tracking' ? 'wrap' : ''">
                  <div class="row-border-right">{{ col.header ? col.header : '&nbsp;' }}</div>
                </span>
              </th>
            </tr>
          </ng-template>
    
          <ng-template pTemplate="body" let-data let-columns="columns" let-index="rowIndex">
            <tr [pContextMenuRow]="data" style="height: 100%; display: flex">
              <td
                *ngFor="let col of columns"
                pEditableColumn
                pFrozenColumn
                [frozen]="col?.is_frozen_column"
                [ngStyle]="{
                  width: col.width + 'px',
                  textOverflow: 'ellipsis',
                  color: col.field === 'index' ? '#9a9a9a' : 'inherit'
                }"
              >
              <div *ngIf="col.field.split('.').length === 2; else singleRow">
                <span *ngIf="!customColumnsLayout?.includes(col.field)">
                  <span *ngIf="col.editable && !col.type">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input
                          blockLateralArrowNavigation
                          pInputText
                          nullValue
                          type="text"
                          [ngModel]="getNestedProperty(data, col.field)"
                          (ngModelChange)="setNestedProperty(data, col.field, $event)"
                          (change)="onChange(data, true)"
                          (keydown.enter)="onChange(data, true)"
                        />
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{ getNestedProperty(data, col.field) }}
                      </ng-template>
                    </p-cellEditor>
                  </span>
                  <span *ngIf="col.type === 'dropdown'">
                    <dropdown-cell-renderer
                    [options]="dropdownOptions[col.field.split('.')[1]]"
                    [isAgGrid]="false"
                    [jobsPage]="true"
                    [data]="data"
                    [colField]="col.field"
                    (statusChanged)="handleStatusChange($event)"
                  ></dropdown-cell-renderer>
                  </span>
                </span>
              </div>
              
                <ng-template #singleRow>
                  <span *ngIf="!customColumnsLayout?.includes(col.field)">
                    <span *ngIf="col.editable && !col.type">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input
                            blockLateralArrowNavigation
                            pInputText
                            nullValue
                            type="text"
                            [(ngModel)]="data[col.field]"
                            (keydown.enter)="onChange(data)"
                            (change)="onChange(data)"
                          />
                        </ng-template>
                        <ng-template pTemplate="output"> {{ data[col.field] }} </ng-template>
                      </p-cellEditor>
                    </span>
                    <span *ngIf="col.editable && col.type === 'toggle'">
                      <div>
                        <p-inputSwitch [(ngModel)]="data[col.field]" (onChange)="onChange(data, false, 'archive')"></p-inputSwitch>
                      </div>
                    </span>
                    <span *ngIf="col.type === 'dropdown'">
                      <dropdown-cell-renderer
                        [options]="dropdownOptions[col.field]"
                        [isAgGrid]="false"
                        [jobsPage]="true"
                        [data]="data"
                        [colField]="col.field"
                        (statusChanged)="handleStatusChange($event)"
                      ></dropdown-cell-renderer>
                    </span>
                    <span *ngIf="col.type === 'multidropdown'">
                      <dropdown-cell-renderer
                        [options]="allUsers"
                        [isAgGrid]="false"
                        [jobsPage]="true"
                        [data]="data"
                        [colField]="col.field"
                        [isMultiple]="true"
                        (statusChanged)="handleStatusChange($event)"
                        >
                        ></dropdown-cell-renderer>
                    </span>
                    <span *ngIf="!col.editable" class="cell-output">
                      {{ data[col.field] }}
                    </span>
                  </span>
                </ng-template>
    
                <!-- customColumnsLayout -->
                <span *ngIf="col.field === 'index'" class="cell-output index">
                  <span>{{ index + 1 }}</span>
                </span>
    
                <div class="orders-row" *ngIf="col.field === 'orders'">
                  <span class="orders-items">
                    <div class="orders-item" *ngFor="let order of data[col.field]">
                      {{ order }}
                    </div>
                  </span>
                  <div
                    class="orders-item orders-item--button"
                    (click)="
                      router.navigate([{ outlets: { addOrder: DashboardRoutes.AddOrder } }], { skipLocationChange: true })
                    "
                  >
                    +
                  </div>
                </div>
    
                <span *ngIf="col.field === 'receivers'">
                  <dropdown-cell-renderer
                    [options]="this.receiverOptions"
                    [isAgGrid]="false"
                    [jobsPage]="true"
                    [data]="data"
                    [colField]="col.field"
                    (statusChanged)="handleStatusChange($event)"
                  ></dropdown-cell-renderer>
                </span>
    
                <span *ngIf="col.field === 'receiver_contacts'">
                  <dropdown-cell-renderer
                  [options]="getReceiverContactsOptions(data['receiver']?.id)"
                  [isAgGrid]="false"
                  [jobsPage]="true"
                  [data]="data"
                  [colField]="col.field"
                  (statusChanged)="handleStatusChange($event)"
                ></dropdown-cell-renderer>
                </span>
    
                <span *ngIf="col.field === 'name'">
                  <div class="row-border-right row-border-right--frozen-column">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input
                          blockLateralArrowNavigation
                          pInputText
                          nullValue
                          type="text"
                          [(ngModel)]="data[col.field]"
                          (keydown.enter)="onChange(data)"
                          (change)="onChange(data)"
                        />
                      </ng-template>
                      <ng-template pTemplate="output"> {{ data[col.field] }} </ng-template>
                    </p-cellEditor>
                  </div>
                </span>
              </td>
            </tr>
          </ng-template>
    
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td [attr.colspan]="columns.length">
                <div class="empty-table">
                  <div class="empty-table-box">
                    <img src="assets/img/empty-table-loop.svg" alt="Magnifying glass empty table" />
                    <b class="empty-table-box-bold">Start adding orders</b>
                    <p class="empty-table-box-text">Click "+ Add order"</p>
                    <p class="empty-table-box-text">or</p>
                    <p class="empty-table-box-text">
                      Forward POs or order confirmations to <a href="mailto:orders@materlog.com">orders@materlog.com</a>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

  </ng-container>

</div>

<p-contextMenu #cm [model]="selectedCategory === 'Archived' ? archivedMenu : menu"></p-contextMenu>

<p-toast position="top-center" key="success"></p-toast>
<p-toast position="top-center" key="error"></p-toast>

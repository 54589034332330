import {ISuppliers, ISupplierContacts, Suppliers} from './../models/dashboard.model';
import { Observable } from 'rxjs';
import { HttpHelper } from './../../common/services/http-helper/http-helper';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {decodeMsgPackSimple} from 'src/app/dashboard/services/do_msgpack'



@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  private baseUrl: string = environment.ENV.BASE_URL;

  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  private httpOptionsMsgPack = {
    headers: new HttpHeaders({
      'Accept': 'application/x-msgpack',
    }),
    responseType: 'arraybuffer' as any
  };

  public getSuppliers(): Observable<ISuppliers | HttpErrorResponse> {
    return this.httpClient.get<ISuppliers>(`${this.baseUrl}suppliers/`, this.httpOptionsMsgPack)
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }

  public getSuppliersAll(): Observable<ISuppliers | HttpErrorResponse> {
    const params = new HttpParams().set('all', true);
    const options = {
      ...this.httpOptionsMsgPack,
      params: params
    };
    return this.httpClient.get<ISuppliers>(`${this.baseUrl}suppliers/`, options)
      .pipe(map((response: any) => {
        return decodeMsgPackSimple(response);
        })
      );
  }

  public getSuppliersSmall(): Observable<ISuppliers | HttpErrorResponse> {
    const params = new HttpParams().set('small', true);
    const options = {
      ...this.httpOptionsMsgPack,
      params: params
    };
    return this.httpClient.get<ISuppliers>(`${this.baseUrl}suppliers/`, options)
      .pipe(map((response: any) => {
        return decodeMsgPackSimple(response);
        })
      );
  }

  public addSupplier(newSupplier: ISuppliers | any): Observable<ISuppliers | HttpErrorResponse> {
    return this.httpClient.post<ISuppliers>(`${this.baseUrl}suppliers/`, newSupplier.payload,
      this.httpOptionsMsgPack)
      .pipe(map((response: any) => {
        return decodeMsgPackSimple(response);
        })
      );
  }

  public editSupplier(payload: any): Observable<ISupplierContacts | HttpErrorResponse> {
    return this.httpClient.put<ISupplierContacts>(`${this.baseUrl}suppliers/${payload.id}/`,
      payload.payload, this.httpOptionsMsgPack)
      .pipe(
        map((response: any) => {
          return decodeMsgPackSimple(response);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  public addSupplierContact(newSupplierContact: ISupplierContacts | any): Observable<ISupplierContacts | HttpErrorResponse> {
    return this.httpClient.post<ISupplierContacts>(`${this.baseUrl}suppliers/contacts/`, newSupplierContact.payload,
      this.httpOptionsMsgPack)
      .pipe(map((response: any) => {
        return decodeMsgPackSimple(response);
        })
      );
  }

  public editSupplierContact(payload: any): Observable<ISupplierContacts | HttpErrorResponse> {
    return this.httpClient.put<ISupplierContacts>(`${this.baseUrl}suppliers/contacts/${payload.id}/`,
      payload.payload, this.httpOptionsMsgPack)
      .pipe(
        map((response: any) => {
          return decodeMsgPackSimple(response);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  public deleteSupplierContact(payload: any): Observable<HttpErrorResponse> {
    return this.httpClient.delete<any>(`${this.baseUrl}suppliers/contacts/${payload.id}/`, this.httpOptionsMsgPack)
    .pipe(
      catchError(() => {return throwError('Bad Supplier Contact delete');})
    );
  }

  public getAutocompleteSuppliers(params: any): Observable<ISuppliers | HttpErrorResponse> {
    return this.httpClient.get<ISuppliers>(`${this.baseUrl}autocomplete/suppliers/`, {
      ...this.httpOptionsMsgPack,
      params: { ...params.payload },
    })
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }

  public getSupplier(param?: any): Observable<Suppliers | HttpErrorResponse> {
    return this.httpClient.get<Suppliers>(`${this.baseUrl}suppliers/${param.payload.id}/`,
      this.httpOptionsMsgPack)
      .pipe(map((response: any) => {
        return decodeMsgPackSimple(response);
        })
      );
  }

  public deleteSupplier(payload: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.get<any>(`${this.baseUrl}suppliers/remove/`, {
      ...this.httpOptionsMsgPack,
      params: { ...payload },
    })
    .pipe(map((response: any) => {
      return decodeMsgPackSimple(response);
      })
    );
  }
}

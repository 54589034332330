<div class="orders-row">
  <span class="orders-items"
          *ngIf="!isApprovals || (isApprovals && params.value)"
          [ngClass]="isApprovals ? 'flex flex-row': ''">
    <div
      *ngFor="let item of params.colDef?.field === 'supplier_documents_numbers' ? params.value : [params.value]"
      class="number"
      [ngClass]="cardView ? 'cardview-max-width': ''"

    >
      <div *ngIf="item && item.number"
            [ngStyle]="isApprovals ? 
            {'display': 'inline-flex'}
            : {}"
      >
        <span
          class="orders-item--button "
          [ngClass]="cardView || isApprovals ? 'cardview-overflow': ''"
          (click)="openOrderModal(item.id, params.colDef?.field === 'po_document_number', params.data.id)"
        >
          {{ item.number }}
        </span> 
        <i *ngIf="!isSharedView"
          [ngStyle]="isApprovals ? 
            {'position': 'relative', 'display': 'inline',
              'top': '2px', 'padding-right': '18px',
              'background': 'transparent', 'right': '-3px',
              'padding-left': '2px'
            }:
            {}"
          class="close-icon bi bi-x-lg"
          (click)="deleteOrder(item.id, params.data)"></i>
      </div>
    </div>
  </span>
  <div *ngIf="isApprovals && !params.value" class="add-order-doc-appr">
    <i class="bi bi-plus-lg" 
      (click)="openOrderModal(null, params.colDef?.field === 'po_document_number', params.data.id)"
    ></i>
  </div>
  <div class="add-order-doc">
    <span
    *ngIf="
      params.colDef?.field === 'supplier_documents_numbers' ||
      (params.colDef?.field === 'po_document_number' && params.data && params.data.hasOwnProperty('po_document_number') && !params.data['po_document_number'].id)
    "
    class="orders-item orders-item--button-plus"
    (click)="openOrderModal(null, params.colDef?.field === 'po_document_number', params.data.id)"
  >
    +
    </span>
  </div>

</div>

<add-edit-doc
  [data]="selectedOrder"
  [docTypes]="orderOptions"
  [showDialog]="showOrderDialog"
  [isPO]="isPo"
  [itemId]="selectedRowId"
  (closeDialog)="closeOrderModal($event)"
  (addUpdateOrder)="updatedOrderModal($event)"
></add-edit-doc>



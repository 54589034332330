import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { filter, Observable, ReplaySubject, take } from 'rxjs';

import { MenuItem } from 'primeng/api';
import { faExclamationTriangle, faEye, faXmark } from '@fortawesome/free-solid-svg-icons';

import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { ActiveJobModel } from '../../models/active-job.model';
import { SelectedViewModel } from '../../models/selected-view.model';
import { JobTableService } from '../../services/job-table.service';
import { selectAddRoom, selectAutocompleteSuppliers, selectItems, selectOrderDocument, selectSuppliers } from 'src/app/dashboard/state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { CustomErrorService } from '../../services/custom-error.service';
import { TableConstants } from 'src/app/common/constants/table.constants';
import { ISuppliers } from 'src/app/dashboard/models/dashboard.model';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import {deepCopy, deepArrayClone} from "../../../common/utils/general"



@Component({
  selector: 'job-table',
  templateUrl: './job-table.component.html',
  styleUrls: ['job-table.component.sass'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobTableComponent extends OnDestroyMixin() implements OnChanges {
  @ViewChild('cm') cm: any | any;

  @Input() selectedView?: SelectedViewModel;
  @Input() activeJobsList!: ActiveJobModel[];
  @Input() isLoading!: boolean;
  @Input() isLoadingOverlay!: boolean;
  @Input() dropdownOptions: any;
  @Input() activeJob: any;
  @Input() responseItems: any;

  @Output() selectJob: EventEmitter<any> = new EventEmitter();

  readonly faEye = faEye;
  readonly faTriangle = faExclamationTriangle;
  readonly faXMark = faXmark;

  jobs: any;
  history: any;
  columns: any;
  lastLazyLoadEvent: any;
  previousLoadedPage?: number | null;
  imagesList: any[] = [];
  selectedRow: any;
  deleteRowId: any;
  showDialog: boolean = false;
  isSubmitted: boolean = false;
  orderOptions: any;
  reasonToDelete: any;
  reasonToDeleteText: any;
  showOrderDialog: boolean = false;
  isPo: boolean = false;
  selectedOrder: any = null;
  selectedRowId: any;
  addRoomModal: boolean = false;
  isAddRoomNameSubmitted: boolean = false;
  roomName: string = '';
  documentModal: boolean = false;
  selectedAttachment: any;
  addSupplierModal: boolean = false;
  addSupplierContactModal: boolean = false;
  selectedSupplier: any;
  temporarySupplierValue: any;
  temporarySupplierList: any;
  CommonConstants: any = CommonConstants;
  menu: MenuItem[] = [{ label: 'Delete', icon: 'pi pi-fw pi-times', command: () => this.openDialog(this.selectedRow) }];
  customColumnsLayout = [
    'index',
    'status',
    'po_document_number',
    'po_document_date',
    'po_document_attachments',
    'supplier_documents_numbers',
    'supplier_documents_attachments',
    'images',
    'supplier_id',
    'supplier_contact_id',
    'room_id',
    'total_cost',
    'link_to_material',
    'supplier_website',
    'tracking_info',
    'next_supplier_follow_up_date',
  ];

  excludedFieldsForOrderUpdate = [
    'status_color',
    'room_id',
    'updated_datetime',
    'id',
  ];
  excludedFieldsForItemUpdate = [
    'order_id',
    'id',
  ];
  fieldsForOrderUpdate = [
    'job_id',
    'supplier_id',
    'supplier_contact_id',
    'shipping_to',
    'shipping_name',
    'shipping_address',
    'shipping_city',
    'shipping_state',
    'shipping_zip',
    'shipping_country',
    'total_cost',
    'payment_method',
    'last_4_card_digits',
    'is_reimbursable',
    'next_supplier_follow_up_date',
  ];
  fieldsForSupplierUpdate = [
    'supplier_id',
    'supplier_phone',
    'supplier_email',
    'supplier_website',
  ];
  fieldsForShippingToUpdate = [
    'shipping_name',
    'shipping_address',
    'shipping_city',
    'shipping_state',
    'shipping_zip',
    'shipping_country',
  ];
  fieldsForSupplierContactUpdate = [
    'supplier_contact_id',
    'supplier_contact_title',
    'supplier_contact_phone',
    'supplier_contact_email',
    'supplier_contact_role'
  ];
  fieldsForHistoryUpdate = [
    'status',
    'delivery_method',
    'in_stock_date',
    'ship_date',
    'delivery_date',
    'in_stock_ship_tags',
    'supplier_id',
  ];

  constructor(
    private jobTableService: JobTableService,
    private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private customErrorService: CustomErrorService,
  ) {
    super();
    this.initHideShowColumnFilterSubscription();
  }

  get appliedFilters(): boolean {
    return !!this.selectedView?.activeView?.get_filters()?.length;
  }

  get isDeleteError() {
    if (this.reasonToDelete === 3 && (this.reasonToDeleteText?.trim()?.length <= 1 || !this.reasonToDeleteText)) {
      return true;
    } else if (!this.reasonToDelete) {
      return true;
    } else { return false; }
  }

  assignHistoryToResults(data: any) {
    let selectedView = JSON.parse(JSON.stringify(data));
    selectedView.results?.map((item: any) => {
      selectedView.history?.map((itemToAdd: any) => {
        if (item.id === itemToAdd.id) {
          item.fields_for_history_visible = itemToAdd.fields_changed;
        }
      })
    });

    return selectedView.results;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.selectedView?.currentValue && !!this.selectedView) {
      this.jobs = deepCopy(this.assignHistoryToResults(this.selectedView));
      this.assignNameToSupplierId();
      this.columns = deepCopy(this.selectedView.selectedColumns);
      if (this.selectedView?.selectedJobId === 'uncategorized') {
        this.columns = deepCopy(JSON.parse(JSON.stringify(TableConstants.jobItemsTableColumns))?.map((field: any) => ({ ...field, visible: true })));
      }
    }

    if ('selectedView' in changes) {
      this.previousLoadedPage = 1;
    }

    // in the response from edit call we receive from the backend
    // the fields that needs to be updated in the other rows (items that belongs to the same order / suppliers)
    if ('responseItems' in changes && !changes.responseItems?.firstChange && !changes.responseItems.currentValue.errors) {
      const response = JSON.parse(JSON.stringify(changes.responseItems.currentValue));

      this.jobs?.forEach((item: any) => {
        if (item.id === changes.responseItems.currentValue?.id) {
          const copyResponse = JSON.parse(JSON.stringify(changes.responseItems.currentValue));
          this.excludedFieldsForItemUpdate.forEach(e => delete copyResponse[e]);
          Object.assign(item, copyResponse);
        }

        if (Object.keys(response).some((field: any) => this.fieldsForSupplierUpdate.includes(field))) {
          const copyResponse = JSON.parse(JSON.stringify(changes.responseItems.currentValue));
          this.excludedFieldsForOrderUpdate.forEach(e => delete copyResponse[e]);

          if (item.supplier_id?.id === copyResponse.supplier_id) {
            Object.assign(item, copyResponse);
          }
        }

        if (Object.keys(response).some((field: any) => this.fieldsForShippingToUpdate.includes(field))) {
          const copyResponse = JSON.parse(JSON.stringify(changes.responseItems.currentValue));
          this.excludedFieldsForOrderUpdate.forEach(e => delete copyResponse[e]);

          if (item.order_id === copyResponse.order_id) {
            Object.assign(item, copyResponse);
          }
        }

        if (Object.keys(response).some((field: any) => this.fieldsForSupplierContactUpdate.includes(field))) {
          const copyResponse = JSON.parse(JSON.stringify(changes.responseItems.currentValue));
          this.excludedFieldsForOrderUpdate.forEach(e => delete copyResponse[e]);

          if (item.supplier_contact_id === copyResponse.supplier_contact_id) {
            Object.assign(item, copyResponse);
          }
        }
      });

      if (this.selectedView?.selectedJobId === 'uncategorized') {
        this.jobs = this.jobs.filter((item: any) => !item.job_id);
        this.dashboardActions.requestUncategorizedView({ include_items: CommonConstants.include_items.uncategorized });
      }

      this.assignNameToSupplierId();
    }

    this.cdr.detectChanges();
  }

  assignNameToSupplierId() {
    this.jobs?.forEach((row: any) => {
      const id = row.supplier_id;

      if (row.supplier_id && typeof (row.supplier_id) === 'string') {
        row.supplier_id = {
          id: row.supplier_id,
          name: '',
        };
        this.dropdownOptions?.supplier_id?.forEach((supplier: any) => {
          if (supplier.id == id) { row.supplier_id.name = supplier.name; }
        });
      }
    });
  }

  loadJobsLazy(event: any): any {
    if (JSON.stringify(this.lastLazyLoadEvent) === JSON.stringify(event) && this.previousLoadedPage !== 1) {
      return false;
    }

    this.lastLazyLoadEvent = event;

    if (this.selectedView?.requestNextPagePayload) {
      if (this.selectedView.requestNextPagePayload?.page == this.previousLoadedPage) {
        return false;
      }

      this.previousLoadedPage = this.selectedView.requestNextPagePayload.page;

      this.jobTableService.loadJobList(this.selectedView?.requestNextPagePayload)
        .subscribe((result: any) => {
          this.selectedView?.updateNextPageUrl(result?.next);

          if (result?.results) {
            this.jobs = deepCopy(this.jobs.concat(this.assignHistoryToResults(result)));
            this.assignNameToSupplierId();
            event.forceUpdate();
            this.cdr.detectChanges();
          }
        });
    }
  }

  trackByRows(index: number, item: any): string {
    return index + item?.job_id;
  }

  onChange(key: string, data: any, id: string, orderId?: string): void {
    //refresh table if sort/filters applied
    let active_view = this.selectedView?.activeView;
    if (active_view) {
      let json_sort_fields = active_view.get_fields_selected_to_sort_json();
      if (
        (json_sort_fields && json_sort_fields.some((filter: any) => filter.key === key) ||
          active_view.get_filters().some((filter: any) => filter.key === key)) &&
          this.selectedView?.activeView ) {
        this.dashboardActions.requestViews({ view_id: this.selectedView?.activeView?.id });
      }
    }

    if (key === 'supplier_id' && data === null) {
      this.dashboardActions.requestUpdateViewCell({ [`${key}`]: null, id });
    } else {
      this.dashboardActions.requestUpdateViewCell({ [`${key}`]: key == 'supplier_id' ? data?.id : data, id });
    }

    if (key === 'supplier_id' || key === 'supplier_contact_id') {
      setTimeout(() => {
        this.onRequestSuppliers();
      }, 1000);
    }

    if (key === 'job_id') {
      this.dashboardActions.requestUncategorizedView({ include_items: CommonConstants.include_items.uncategorized });
    }

    if (this.fieldsForSupplierUpdate.concat(this.fieldsForSupplierContactUpdate).includes(key)) {
      this.dashboardActions.requestSuppliers();
    }

    // update the fields that needs to be updated in the other rows (items that belongs to the same order / suppliers)
    this.jobs?.forEach((item: any) => {
      if (this.fieldsForOrderUpdate.includes(key) && item.order_id === orderId) {
        if (key === 'supplier_id') { this.onAutocompleteSupplierInfo(data?.id, orderId); }

        if (key === 'supplier_contact_id') { this.onAutocompleteSupplierContactInfo(data, orderId); }

        if (this.fieldsForHistoryUpdate.includes(key)) {
          item.fields_for_history_visible?.push(key)
        }

        if (this.fieldsForShippingToUpdate.includes(key)) {
          if (!item.shipping_to) item.shipping_to = 4;
        }

        if (key === 'job_id') {
          this.jobs = this.jobs.filter((item: any) => item.job_id === this.activeJob.id);
        }

        item[key] = data;
      }

      if (!this.fieldsForOrderUpdate.includes(key) && item.id === id) {
        item[key] = data;
      }

      if (this.fieldsForHistoryUpdate.includes(key) && item.id === id) {
        item.fields_for_history_visible?.push(key)
      }
    });
  }

  onChangeDate(key: string, data: string, id: string): void {
    this.dashboardActions.requestUpdateViewCell({ [`${key}`]: this.datePipe.transform(data, 'yyyy-MM-dd'), id });

    this.jobs?.forEach((item: any) => {
      if (this.fieldsForHistoryUpdate.includes(key) && item.id === id) {
        item.fields_for_history_visible?.push(key)
      }
    });
  }

  onAutocompleteSupplierInfo(supplierId: any, orderId: any): void {
    setTimeout(() => {
      this.jobs?.forEach((item: any) => {
        if (item.order_id === orderId) {
          if (!supplierId) {
            item.supplier_phone = null;
            item.supplier_email = null;
            item.supplier_website = null;
            item.supplier_contact_id = null;
            item.supplier_contact_title = null;
            item.supplier_contact_phone = null;
            item.supplier_contact_email = null;
            item.supplier_contact_role = null;

            return;
          }

          this.dropdownOptions?.supplier_id?.forEach((supplier: any) => {
            if (supplier.id === supplierId) {
              item.supplier_phone = supplier.phone;
              item.supplier_email = supplier.email;
              item.supplier_website = supplier.website;
              item.supplier_contact_id = null;
              item.supplier_contact_title = null;
              item.supplier_contact_phone = null;
              item.supplier_contact_email = null;
              item.supplier_contact_role = null;
            }
          });
        }
      });
      this.cdr.detectChanges();
    }, 500);
  }

  onAutocompleteSupplierContactInfo(supplierContactId: any, orderId: any): void {
    setTimeout(() => {
      this.jobs.forEach((item: any) => {
        if (item.order_id === orderId) {
          if (!supplierContactId) {
            item.supplier_contact_title = null;
            item.supplier_contact_phone = null;
            item.supplier_contact_email = null;
            item.supplier_contact_role = null;

            return;
          }

          this.dropdownOptions?.supplier_contact_id?.forEach((supplierContact: any) => {
            if (supplierContact.id === supplierContactId) {
              item.supplier_contact_title = supplierContact.title;
              item.supplier_contact_phone = supplierContact.phone;
              item.supplier_contact_email = supplierContact.email;
              item.supplier_contact_role = supplierContact.role;
            }
          });
        }
      });
      this.cdr.detectChanges();
    }, 500);
  }

  getSupplierContactsOptions(id: string) {
    return this.dropdownOptions.supplier_id?.find((elem: any) => elem.id == id)?.contacts;
  }

  addImages(key: string, files: File[], id: string) {
    this.imagesList = [];

    for (const item of files) {
      this.onSendImages(key, item, id, files.length);
    }
  }

  onSendImages(key: string, file: any, id: string, length: number) {
    this.onConvertBase64(file).subscribe((base64: string) => {
      this.imagesList.push({
        image_name: file.name,
        image: base64,
      });

      if (this.imagesList.length === length) {
        this.dashboardActions.requestUpdateViewCell({ [`${key}`]: this.imagesList, id });
      }
    });
  }

  onConvertBase64(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();

    reader.readAsBinaryString(file);
    reader.onload = (event: any) => result.next(btoa(event.target?.result?.toString()));

    return result;
  }

  deleteFile(event: Event, key: string, id: string, attachment_id: string, colField: string) {
    event.preventDefault();

    this.jobs?.map((item: any) => {
      item[colField] = item[colField]?.filter(
        (item: any) => (key === 'image_id_to_delete' ? item.image_id : item.id) !== attachment_id
      );
    });

    this.dashboardActions.requestAttachmentDelete({ [`${key}`]: attachment_id, id });
  }

  openDialog(selectedRow: any) {
    this.showDialog = true;
    this.deleteRowId = selectedRow.id;
    this.resetModal();
  }

  closeDialog() {
    this.showDialog = false;
    this.resetModal();
  }

  deleteItem(itemId: any) {
    const sendDeleteRequestAndSelectJob = (payload: any) => {
      this.dashboardActions.requestDeleteItemReason(payload);
      this.store
        .pipe(
          select(selectItems),
          filter((items: any) => !items.items.isLoading),
          take(1)
        )
        .subscribe((item: any) => {
          if (item.items?.errors) {
            this.customErrorService.setCustomErrorMessage(item.items);
          } else {
            this.jobs.splice(this.jobs.findIndex((item: any) => item.id === itemId), 1);
            this.cdr.markForCheck();
          }
        });

      this.closeDialog();
    };

    this.isSubmitted = true;

    if (this.reasonToDelete == 3 && this.reasonToDeleteText?.trim()?.length >= 1) {
      sendDeleteRequestAndSelectJob({
        id: itemId,
        reason_to_not_display: this.reasonToDelete,
        reason_to_not_display_text: this.reasonToDeleteText,
      });
    } else if (this.reasonToDelete != 0 && this.reasonToDelete !== 3 && this.reasonToDelete) {
      sendDeleteRequestAndSelectJob({
        id: itemId,
        reason_to_not_display: this.reasonToDelete,
      });
    }
  }

  resetModal() {
    this.isSubmitted = false;
    this.reasonToDelete = null;
    this.reasonToDeleteText = null;
  }

  onColumnReorder(event: any) {
    if (this.selectedView?.activeView) {
      this.dashboardActions.requestUpdateView({ fields_selected_to_display: event.columns, selectedViewId: this.selectedView?.activeView?.id });
    }
  }

  onColResize(event: any) {
    this.columns[event.element.cellIndex].width = this.columns[event.element.cellIndex].width + event.delta;

    if (this.selectedView?.activeView) {
      this.dashboardActions.requestUpdateView({ fields_selected_to_display: this.columns, selectedViewId: this.selectedView?.activeView?.id });
    }

    this.columns = deepCopy(this.columns); // this deep copy is used because after the request the columns variable becomes read-only

  }

  openOrderModal(orderId: any, isPo: boolean, itemId?: any) {
    this.showOrderDialog = true;
    this.isPo = isPo;
    this.selectedRowId = itemId;
    this.orderOptions = JSON.parse(JSON.stringify(this.dropdownOptions?.document_types));
    this.orderOptions.splice(1, 1);

    if (orderId != null) {
      this.onRequestOrderDocument(orderId);
    } else {
      this.selectedOrder = null;
    }
  }

  closeOrderModal(event: any) {
    this.showOrderDialog = event;
  }

  onRequestOrderDocument(orderId: any) {
    this.dashboardActions.requestOrderDocument(orderId);
    this.store
      .pipe(
        select(selectOrderDocument),
        filter((orderDocument: IStoreApiItem<any>) => !orderDocument.isLoading),
        take(1)
      )
      .subscribe((orderDocument: any) => {
        if (orderDocument?.data) {
          this.selectedOrder = JSON.parse(JSON.stringify(orderDocument.data));
        }

        if (orderDocument?.errors) {
          this.customErrorService.setCustomErrorMessage(orderDocument);
          setTimeout(() => { this.showOrderDialog = false; this.cdr.markForCheck(); }, 500);
        }

        this.cdr.markForCheck();
      });
  }

  updatedOrderModal(event: any): void {
    this.requestOrderDocumentChange(event);
  }

  private orderDocStoreSelector() {
    this.store.pipe(
      select(selectOrderDocument),
      filter((orderDocument: IStoreApiItem<any>) => !orderDocument.isLoading),
      take(1)
    )
      .subscribe((orderDocument: any) => {
        if (orderDocument.isSuccess) {
          const response = JSON.parse(JSON.stringify(orderDocument.data));

          this.jobs?.forEach((item: any) => {
            if (item.order_id === response.order_id) {
              this.excludedFieldsForOrderUpdate?.forEach((e: any) => delete response[e]);
              Object.assign(item, response);
            }
          });
          this.cdr.markForCheck();
        }

        if (orderDocument.errors) {
          this.customErrorService.setCustomErrorMessage(orderDocument);
        }
      });
  }

  deleteOrder(orderId: any, row: any) {
    this.requestOrderDocumentChange({ deleteOrder: { order_item_id: row.id, order_id: orderId } });
  }

  onOpenAddRoomModal(itemId: string) {
    this.addRoomModal = true;
    this.isAddRoomNameSubmitted = false;
    this.selectedRowId = itemId;
    this.roomName = '';
  }

  onCloseAddRoomModal() {
    this.addRoomModal = false;
  }

  onSaveRoomName(rowId: string) {
    this.isAddRoomNameSubmitted = true;

    if (this.roomName.trim()?.length >= 1) {
      this.addRoomModal = false;
      this.dashboardActions.requestAddRoom({ name: this.roomName });
      this.store
        .pipe(
          select(selectAddRoom),
          filter((room: IStoreApiItem<any>) => !room.isLoading),
          take(1)
        )
        .subscribe((room: any) => {
          if (room?.isSuccess) {
            this.dashboardActions.requestUpdateViewCell({ room_id: room.data.id, id: rowId });
            this.dashboardActions.requestOptions();
          }

          if (room?.errors) {
            this.customErrorService.setCustomErrorMessage(room);
          }
        });
    }
  }

  private requestOrderDocumentChange(event: any) {
    if (event?.updateOrder) {
      this.dashboardActions.requestUpdateOrderDocument({
        ...event.updateOrder
      });
      this.orderDocStoreSelector();
    } else if (event?.addOrder) {
      this.dashboardActions.requestAddOrderDocument({ ...event.addOrder });
      this.orderDocStoreSelector();
    } else if (event?.deleteOrder) {
      this.dashboardActions.requestDeleteOrderDocument({ ...event.deleteOrder });
      this.orderDocStoreSelector();
    }
  }



  private initHideShowColumnFilterSubscription() {
    this.jobTableService.hideFilterObs.subscribe(result => {
      if (result) {
        this.columns = result;
        this.cdr.markForCheck();
      }
    });
  }

  closeSupplier() {
    this.addSupplierModal = false;
  }

  closeSupplierContact() {
    this.addSupplierContactModal = false;
  }

  onOpenModalAddSupplier(selectedRow: any) {
    this.selectedRowId = selectedRow;
    this.addSupplierModal = true;
  }

  onOpenModalAddSupplierContact(selectedRow: any) {
    this.selectedSupplier = selectedRow.supplier_id.id;
    this.selectedRowId = selectedRow;
    this.addSupplierContactModal = true;
  }

  supplierFormChangeEvent(event: any) {
    this.addSupplierModal = false;
    this.onChange('supplier_id', event.id, this.selectedRowId.id, this.selectedRowId.order_id);
  }

  supplierContactFormChangeEvent(event: any) {
    this.addSupplierContactModal = false;
    this.onChange('supplier_contact_id', event.id, this.selectedRowId.id, this.selectedRowId.order_id);
  }

  filterSupplier(event: any, selectedRow: any) {
    this.selectedRowId = selectedRow.id;
    const query = event.query;

    if (query.trim()?.length >= 1) {
      this.onRequestAutocompleteSuppliers(query);
    }
  }

  getSuppliers(selectedRow: any) {
    this.selectedRowId = selectedRow.id;
    this.onRequestSuppliers();
  }

  checkSupplierSuggestions(rowId: any) {
    return rowId === this.selectedRowId;
  }

  // this is used to set the supplier input. if the user changes the value of the supplier and does not select anything,
  // the cell remains empty and the value is lost until a refresh. this function bypasses that case, and leaves the previous value selected
  setTemporarySupplierValue(event: any) {
    this.temporarySupplierValue = event;
  }

  onRequestAutocompleteSuppliers(query: any) {
    this.dashboardActions.requestAutocompleteSuppliers({ search_text: query });
    this.store
      .pipe(
        select(selectAutocompleteSuppliers),
        filter((suppliers: IStoreApiItem<ISuppliers>) => !suppliers.isLoading),
        take(1)
      )
      .subscribe((suppliers: IStoreApiItem<ISuppliers>) => {
        if (Array.isArray(suppliers?.data)) {
          const suppliersList = JSON.parse(JSON.stringify(suppliers?.data));
          const supplierContactsList: any = [];

          suppliersList?.map((supplier: any) => {
            supplier.value = supplier.name;
            supplierContactsList.push(supplier.contacts);
          });
          supplierContactsList?.flat().map((supplierContact: any) => {
            supplierContact.value = supplierContact.name;
          });
          this.temporarySupplierList = suppliersList;
          this.cdr.markForCheck();
        }
      });
  }

  onRequestSuppliers() {
    this.dashboardActions.requestSuppliers();
    this.store
      .pipe(
        select(selectSuppliers),
        filter((suppliers: IStoreApiItem<ISuppliers>) => !suppliers.isLoading),
        take(1)
      )
      .subscribe((suppliers: IStoreApiItem<any>) => {
        if (Array.isArray(suppliers.data.results)) {
          const suppliersList = JSON.parse(JSON.stringify(suppliers?.data.results));
          const supplierContactsList: any = [];

          suppliersList?.map((supplier: any) => {
            supplier.value = supplier.name;
            supplierContactsList.push(supplier.contacts);
          });
          supplierContactsList?.flat().map((supplierContact: any) => {
            supplierContact.value = supplierContact.name;
          });
          this.dropdownOptions = {
            ...this.dropdownOptions,
            supplier_id: suppliersList,
            supplier_contact_id: supplierContactsList?.flat()
          };

          this.temporarySupplierList = suppliersList;
        }
      });
  }

  openDocumentModal(document: any, filename: string) {
    //the filetype check is needed for doc/docx types as they are not rendered as HTML pages by the AWS to be shown in the modal.
    const prepareFile: any = filename.split('.');
    const fileType = prepareFile?.findLast((el: any) => el.length > 1).toLowerCase();
    const docTypes = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'bmp', 'eml'];
    if (document) {
      if (docTypes.includes(fileType)) {
        this.documentModal = true;
        this.selectedAttachment = document;
      } else {
        window.open(document);
      }
    } else {
      this.customErrorService.setCustomErrorMessage('documentNotFound');
    }
  }

  closeDocumentModal() {
    this.documentModal = false;
  }

  //checks if the file is an image or not
  checkFileType(filename: any): boolean {
    const prepareFile: any = filename.split('.');
    const fileType = prepareFile?.findLast((el: any) => el.length > 1);
    const types = ['png', 'jpeg', 'jpg', 'jfif', 'pjpeg', 'pjp', 'svg', 'webp', 'bmp'];

    return types.includes(fileType);
  }
}

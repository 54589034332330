import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { INewItem } from '../../models/dashboard.model';

export interface IItemsState {
  items: IStoreApiItem<INewItem>;
}

export class ItemsState implements IItemsState {
  items: IStoreApiItem<INewItem> = new StoreApiItem();
}

import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorItemData, receiveItemData, requestItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { SharedViewActionTypes } from '../actions/shared-view-action-types';
import { ISharedViewPageState, SharedViewPageState } from '../models/shared-view-page.model';

export function sharedViewPageReducer(
  lastState: ISharedViewPageState = new SharedViewPageState(),
  action: GenericAction<SharedViewActionTypes, any>
): ISharedViewPageState {
  switch (action.type) {

    case SharedViewActionTypes.REQUEST_SHARED_VIEW_PAGE:
      return requestItemData<ISharedViewPageState, any>(lastState, ['sharedViewPage']);
    case SharedViewActionTypes.RECEIVE_SHARED_VIEW_PAGE:
      return receiveItemData<ISharedViewPageState, any>(lastState, ['sharedViewPage'], action.payload);
    case SharedViewActionTypes.ERROR_RECEIVE_SHARED_VIEW_PAGE:
      return errorItemData<ISharedViewPageState, any>(lastState, ['sharedViewPage'], action.payload);

    default:
      return lastState;
  }
}

import { IStoreApiItem, StoreApiItem } from "src/app/common/models/store-api-item.model";
import { IOrganizationAddresses } from "../../models/company.model";

export interface IOrganizationAddressesState {
  organizationAddresses: IStoreApiItem<IOrganizationAddresses>;
}

export class OrganizationAddressesState implements IOrganizationAddressesState {
  organizationAddresses: IStoreApiItem<IOrganizationAddresses> = new StoreApiItem();
}

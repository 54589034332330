<header class="header">
  <!--
  <div class="header__logo" routerLink="home">
    <img src="/assets/img/logo.svg" alt="materlog-logo" />
  </div>
  -->
  <div class="header__logo" routerLink="home">
    <img src="/assets/img/materlog_icon.svg" alt="materlog-logo" />
  </div>

  <div class="header__settings flex flex-row gap-3	">
    <i class="bi bi-chat-left-text text-azure small-icon"
        style="font-size: 15px; margin-top: 2px; cursor: pointer"
        (click)="flipComments()" *ngIf="this.router.url==='/'"></i>
    <i class="bi bi-inbox-fill text-azure " (click)="flipInbox()" *ngIf="this.router.url==='/'"></i>
    <a href="mailto:support@materlog.com"><i class="bi bi-question-circle-fill text-azure"></i></a>
    <!-- <i class="bi bi-bell-fill"></i> -->
    <div class="profile">
      <i class="bi bi-person-circle text-azure" (click)="openProfileMenu()"></i>
      <div class="profile__menu" *ngIf="profileMenu" (outsideClick)="profileMenu = false" outsideClick>
        <div class="profile__menu-option" routerLink="user-settings" (click)="profileMenu = false">
          <fa-icon [icon]="faPerson"></fa-icon> Account
        </div>
        <div class="profile__menu-option spacing" routerLink="user-settings" (click)="profileMenu = false">
          <fa-icon [icon]="faNotification"></fa-icon>Notification preferences
        </div>
        <div class="menu-divider"><p-divider></p-divider></div>
        <div class="profile__menu-option" routerLink="company-settings" (click)="profileMenu = false">
          <fa-icon [icon]="faSliders"></fa-icon>Company settings
        </div>
        <!-- <div class="profile__menu-option spacing"><fa-icon [icon]="faEnvelope"></fa-icon>Tell a friend</div> -->
        <div class="menu-divider"><p-divider></p-divider></div>
        <div class="profile__menu-option" (click)="permissionService.logout()">
          <fa-icon [icon]="faLogout"></fa-icon>Log out
        </div>
      </div>
    </div>
  </div>
</header>

<!--


-->
import {Component, OnDestroy, ViewChild, ElementRef} from '@angular/core';

import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
//import {MaterlogSystemService} from "@app/_services/materlog.system.service";
//import {ViewConstants} from "@app/_constants/viewConstants";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {OrderService} from "../../../services/order.service";
import {UserService} from "../../../../user-settings/services/user.service";
import {OrganizationService} from "../../../../company-settings/services/organization.service";
import {SupplierService} from "../../../services/supplier.service";
import {INewItem, Items, Suppliers} from "../../../models/dashboard.model";
import {catchError, map} from "rxjs/operators";
import {
  ErrorReceiveActiveOrders,
  ReceiveActiveOrders
} from "../../../state/actions/dashboard-action-types-creators/activeOrders-action-types-creators";
import {HttpErrorResponse} from "@angular/common/http";
import {of} from "rxjs";
import {SuppliersService} from "../../../../suppliers/services/suppliers.service";
import {DashboardService} from "../../../services/dashboard.service";
@Component({
  selector: 'materlog-manual-communication',
  templateUrl: './manual-communication.component.html',
  styleUrls: ['./manual-communication.component.css'],
  providers: [DialogService]
})
export class ManualCommunicationComponent {

  editor_config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '100',
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    enableToolbar: true,
    showToolbar: true,
    sanitize: false,
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    toolbarPosition: 'top'
  };
  public from_email:string = "";
  public to_email:string = "";
  public cc_email:string = "";
  public bcc_email:string = "";
  public email_all_open = false;
  public subject:string = "";
  public body:string = "";
  private datalayer:INewItem|undefined = undefined;
  selectedFiles: File[] = [];

  private user: any = {};
  private organization: any = {};
  private supplier: any = {};
  private entire_order: any;
  private open_orders:any[] = [];
  private  orderService: OrderService;
  //ref: DynamicDialogRef | undefined;
  update_type: any;
  constructor(public ref: DynamicDialogRef,public config: DynamicDialogConfig,
              orderService: OrderService,userservice: UserService,organizationservice: OrganizationService,
              supplierservice: SupplierService, dash: DashboardService
              ) {
    
    (window as any)['comm'] = this;
    this.selectedFiles = [];
    this.orderService = orderService;
    //grab user who is logged in
    userservice.getUserProfile().subscribe(data => {
      this.user = data;

    organizationservice.getOrganization().subscribe(data => {
      this.organization = data;

    //really need to fetch whole record based on this.datalayer.id
    // example:
    // "63b75aa6-6087-44d0-a1bb-047ab5d63d1b"
    //hit backend /orders/id

    let jsonid = {
      'payload': {
        'id': config['data'].id
      }
    }

    orderService.getOrderItems(jsonid).subscribe(
      (data: any) => {

        this.datalayer = data;

        orderService.getOrders({'payload': this.datalayer?.order_id}).subscribe(data2 => {
          this.entire_order = data2;
          let pay = {'payload': {'id': this.entire_order?.supplier}};

          supplierservice.getSuppliers().subscribe((data3: any) => {
            for (let i = 0; i < data3.count; i++) {
              if(data3.results[i].id === this.entire_order?.supplier){
                this.supplier = data3.results[i];
              }
            }

            orderService.getOpenOrders({'id': this.supplier.id}).subscribe(
              (data5:any)=>{
                this.open_orders = data5;
              }
            )

            this.to_email = this.getToEmail();
            this.from_email = this.getFromEmail();
            this.single();
          });
        })

        //prefetch other data
        //all items in the order
        let jsonFilterChoices = {
          includeCustomers: true,
          includeTrials: true,
          includeUnpaid: true,
          includeArchived: false,
          includeTracking: true,
          includeStatusTracking: true,
          includeCancelled: false,
          includeEnded: false
        }
      }
    );
    });
    });

  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        this.selectedFiles.push(input.files[i]);
      }
    }
  }

  removeAttachment(file: any) {
    this.selectedFiles = this.selectedFiles.filter((f: any) => f.name != file.name);
  }


  getOrderName(item:any){
    if(item) {
      let itemnumber = undefined;

      if(item.po_document && item.po_document.length > 0) {
        itemnumber = ""+item.po_documentt[0].number;
      }else if(item.supplier_documents && item.supplier_documents.length > 0){
        itemnumber = ""+item.supplier_documents[0].number
      }
      let ordername = "";

      if (itemnumber != undefined) {
        if (!itemnumber.startsWith('PO')) {
          ordername = "PO ";
        } else if (!itemnumber.startsWith('Order')) {
          ordername = "Order "
        }
      }
      return ordername + itemnumber;
    }
    return "";
  }


  getOrderNameFromFull(item:any){
    if(item) {
      let itemnumber = undefined;

      if(item.po_doc && item.po_doc.length > 0) {
        itemnumber = ""+item.po_doc[0].number;
      }else if(item.supp_doc && item.supp_doc.length > 0){
        itemnumber = ""+item.supp_doc[0].number
      }
      let ordername = "";

      if (itemnumber != undefined) {
        if (!itemnumber.startsWith('PO')) {
          ordername = "PO ";
        } else if (!itemnumber.startsWith('Order')) {
          ordername = "Order "
        }
      }
      return ordername + itemnumber;
    }
    return "";
  }


  getToEmail(){
    if(this.supplier) {
      if (this.supplier.contacts && this.supplier.contacts.length > 0) {
        return this.supplier.contacts[0].email;
      } else if (this.supplier.email) {
        return this.supplier.email;
      }
    }
    return undefined;
  }

  getFromEmail(){
    let fromstring = "";
       if(this.user){
           fromstring = this.user.first_name;
         }
       if(this.organization){
            fromstring += " at " + this.organization.name;
          }
    let fromemail =  "";
    if(this.organization.stub){
      fromemail  = " <orders+" + this.organization.stub + "@materlog.com>" ;
        }
    else {
      fromemail = " <orders@materlog.com>";
    }
    return fromstring + fromemail;
  }

  getToName(){
    if(this.supplier) {
      if (this.supplier.contacts && this.supplier.contacts.length >0) {
        return this.supplier.contacts[0].name;
        //this.datalayer.supplier_id;
      } else if (this.supplier.name) {
        return this.supplier.name;
      }
    }
    return undefined;
  }



  sendSupplierEmail(){
    let cleanbody = this.body;

    const formData = new FormData();
    formData.append('email_from', this.from_email);
    formData.append('email_to', this.to_email);
    formData.append('email_cc', this.cc_email);
    formData.append('email_bcc', this.bcc_email);
    formData.append('email_subject', this.subject);
    formData.append('email_body', cleanbody);
    formData.append('email_organization', this.organization.id);
    formData.append('email_order', this.datalayer?.order_id as string);
    formData.append('email_order_item', this.datalayer?.id as string);
    formData.append('email_whole', this.email_all_open.toString());
  
    // Append files to the FormData object
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append(`attachments`, this.selectedFiles[i], this.selectedFiles[i].name);
      }
    }

    this.orderService.sendOrderSupplierCommunication(formData).subscribe({
      next: (data: any) => {
        this.ref?.close();
      },
      error: (err: any) => {
        this.ref?.close();
      }
    });
  }

  get_short_item_name(){

    let sname = this.datalayer?.name.slice(0,20);
    if(this.datalayer) {
      if (this.datalayer?.name.length > 20) {
        sname += "...";
      }
    }

    return sname;
  }

  get_est_ship(item:any){
    if(item.ship_date){
      return  "Est. Ship ".concat(item.ship_date);
    }

    return undefined;
  }

  get_est_instock(item:any){
    if(item.in_stock_date){
      return "Est. In stock ".concat(item.in_stock_date);
    }
    return undefined;
  }

  get_est_delivery(item:any){
    if(item.delivery_date){
      return  "Est. Delivery ".concat(item.delivery_date);
    }

    return undefined;
  }


  single(){
    this.subject = "Quick question about " + this.get_short_item_name() + " - " + this.getOrderName(this.entire_order);
    let item = undefined;
    if(this.datalayer){
      item =   [this.datalayer];
    }
    this.body = this.generate_body_items(item);
  }


  generate_body_items(items: INewItem[]|undefined){
    if(items != undefined) {
      let body = "Hi " + this.getToName() + ",<BR><BR>"
        + "I wanted to check in on ";
      if (items.length == 1) {
        body += " an item in ";
      }
      body += "our " + this.getOrderName(this.entire_order) + ". Are the below details still accurate?<BR><BR><P>"

        + "<ul id='supplier-request-item-list' class='list-inside list-disc'> ";
      for (let i = 0; i < items.length; i++) {

        if (items[i].reason_to_not_display != 0) {
          continue;
        }

        let instock = this.get_est_instock(items[i]);
        let shipdate = this.get_est_ship(items[i]);
        let deliverydate = this.get_est_delivery(items[i]);
        body += "<li>" + items[i].name + " - " +
          //ViewConstants.mapKeyValueConstants("status",items[i].status) +
          " - " +
          (instock != undefined ? instock + ", " : "") +
          (shipdate != undefined ? shipdate + ", " : "") +
          (deliverydate! + undefined ? deliverydate : "")
          + "</li>";
      }

      body += " </ul> </p> <BR><br>Thanks!<BR>" + this.user.first_name
      ;


      return body;
    }
    return "";
  }

  generate_body_open_items(items: any[]){
    const seen = new Map();
    let body = "Hi "+ this.getToName()  + ",<BR><BR>"
      + "I wanted to check in on our open orders. Can we please get a status update on each?<BR><BR><P>"
      + "<ul id='supplier-request-item-list' class='list-inside list-disc'> ";

    for (let i = 0; i <  items.length; i++) {



      let name = this.getOrderNameFromFull(items[i]);
      if(seen.get(name) == 1){
        continue;
      }
      seen.set(name,1);
      if(name != undefined && name != 'undefined') {
        body += "<li>" + name + "</li>";
      }
    }

    body += " </ul> </p> <BR><br>Thanks!<BR>" + this.user.first_name
    ;


    return body;
  }


  whole(){
    this.subject = "Quick question about " + this.getOrderName(this.entire_order);
    this.body = this.generate_body_items(this.entire_order.items);
  }

  all() {
    this.subject = "Open Orders from: " + this.organization.name;
    this.body = this.generate_body_open_items(this.open_orders);


  }

  handleChange(event:any){

    if(event.target.value =='single'){
      this.single();
      this.email_all_open = false;
    }else if(event.target.value =='whole'){
      this.whole();
      this.email_all_open = false;
    }else if(event.target.value =='all'){
      this.all();
      this.email_all_open = true;
    }

  }

}


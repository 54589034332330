import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IJobs, INewJob } from 'src/app/dashboard/models/dashboard.model';
import { DashboardActionTypes } from '../dashboard-action-types';

export class RequestAddJob implements Action {
  readonly type: DashboardActionTypes.REQUEST_ADD_JOB = DashboardActionTypes.REQUEST_ADD_JOB;

  constructor(
    public payload: INewJob) { }
}

export class ReceiveAddJob implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ADD_JOB = DashboardActionTypes.RECEIVE_ADD_JOB;

  constructor(
    public payload: IJobs) { }
}

export class ErrorReceiveAddJob implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ADD_JOB = DashboardActionTypes.ERROR_RECEIVE_ADD_JOB;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class RequestJobList implements Action {
  readonly type: DashboardActionTypes.REQUEST_JOB_LIST = DashboardActionTypes.REQUEST_JOB_LIST;

  constructor(
    public payload?: string) { }
}


export class RequestInputMessagesList implements Action {
  readonly type: DashboardActionTypes.REQUEST_INPUT_MESSAGES_LIST = DashboardActionTypes.REQUEST_INPUT_MESSAGES_LIST;

  constructor(
    public payload?: string) {
  }
}

export class ReceiveJobList implements Action {
  readonly type: DashboardActionTypes.RECEIVE_JOB_LIST = DashboardActionTypes.RECEIVE_JOB_LIST;

  constructor(
    public payload: IJobs) { }
}

export class ErrorJobList implements Action {
  readonly type: DashboardActionTypes.ERROR_JOB_LIST = DashboardActionTypes.ERROR_JOB_LIST;

  constructor(
    public payload: HttpErrorResponse) { }
}

export class ReceiveUpdateJob implements Action {
  readonly type: DashboardActionTypes.RECEIVE_UPDATE_JOB = DashboardActionTypes.RECEIVE_UPDATE_JOB;

  constructor(
    public payload: IJobs) { }
}

export class RequestUpdateJob implements Action {
  readonly type: DashboardActionTypes.REQUEST_UPDATE_JOB = DashboardActionTypes.REQUEST_UPDATE_JOB;

  constructor(
    public payload?: string) { }
}

export class ErrorUpdateJob implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_UPDATE_JOB = DashboardActionTypes.ERROR_RECEIVE_UPDATE_JOB;
  constructor(
    public payload: HttpErrorResponse) { }
}

export class ResetJobCRUDState implements Action {
  readonly type: DashboardActionTypes.RESET_CRUD_JOB_STATE = DashboardActionTypes.RESET_CRUD_JOB_STATE;
}

<div class="global-submitters-container" #globalSubmittalsContainer>
  <div class="submittals-header">
    <div class="view-container">
      <div class="apply-all-container">
        <div class="apply-all-select" (click)="applyAllSelect()">
          <div class="apply-all-text">
            Apply
          </div>
        </div>
      </div>
      <div class="all-view check-view" (click)="changeViewStyle(0)"
      [ngClass]="viewStyle == 0 ? 'active-view': 'inactive-view'">
        <i class="bi bi-bullseye"></i>
      </div>
      <div class="all-view status-view" (click)="changeViewStyle(1)"
          [ngClass]="viewStyle == 1 ? 'active-view': 'inactive-view'">
        <i class="bi bi-check"></i>
      </div>
      <div class="global-status" *ngIf="viewStyle == 0">
        <i class="bi bi-bullseye" style="padding-right: 8px"
        [ngStyle]="{'color': colorForStatus(globalStatus)}"></i>
        <dropdown-cell-renderer
          [options]="options?.statuses"
          [isAgGrid]="false"
          [data]="dataForStatus(globalStatus.status)"
          [cardView]="true"
          [submittalView]="true"
          [credPage]="true"
          [cardViewWidth]="100"
          [colField]="'statuses'"
          (statusChanged)="onUpdateGlobalStatus($event)">
        </dropdown-cell-renderer>
      </div>
      <div *ngIf="viewStyle == 1 && !showAddSubmittalInput" class="add-submittals-type" (click)="showAddSubmittalInput = !showAddSubmittalInput">
        <div class="apply-all-text">
          + Add submittal type
        </div>
      </div>
      <input *ngIf="showAddSubmittalInput && viewStyle == 1" class="item-text-input" 
        type="text"
        (focus)="startEditingSubmittalName($event)"
        (keydown.enter)="blurSubmittalName($event)"
        (blur)="stopEditingSubmittalName()"
        [(ngModel)]="newSubmittalName"
      />
      <div *ngIf="showAddSubmittalInput && viewStyle == 1" class="apply-all-container">
        <div class="apply-all-select" (click)="addSubmittalType()">
          <div class="apply-all-text">
            Add type
          </div>
        </div>
      </div>
    </div>

    <div class="close-submittals" (click)="closeDialog()">
      <i class="bi bi-x-lg"></i>
      <div class="delete-item">
        <div class="delete-item-tooltip">
          Close without saving?
        </div>
      </div>
    </div>
  </div>
  <div class="submittals-container custom-scrollbar-close">
    <div class="global-headers">
      <div class="type-header-name item-name-outer" *ngFor="let t of optionTypes"
          [ngClass]="t.value == ItemNameHeader ? 'item-name-widths pinned-column' : 'type-widths'">
        <div class="global-checkbox-width">
          <p-checkbox
            [(ngModel)]="t.check"
            name="global"
            [binary]="true"
            (onChange)="globalClicked(t)">
          </p-checkbox>
        </div>
        <div class="item-name-outer" *ngIf="!t.isEditing"
              [ngStyle]="{'padding-left': t.value == ItemNameHeader ? '6px': ''}">
          {{t.value.toUpperCase()}}
        </div>
        <input *ngIf="t.isEditing"
              class="item-text-input"
              [ngStyle]="{'margin': '0px'}"
              type="text"
              (focus)="startEditingSubmittalName($event)"
              (keydown.enter)="blurSubmittalName($event)"
              (blur)="stopEditingSubmittalType(t)"
              [(ngModel)]="t.newName"
        />
        <div class="edit-tools" *ngIf="t.value != ItemNameHeader">
          <i class="bi bi-pencil remove-link"
            [ngStyle]="{'display': !t.isEditing ? 'block': 'none'}"
            style="position: relative; font-size: 9px" (click)="editSubmittalType($event, t)"></i>
          <i class="bi bi-x remove-link"
              style="position: relative; padding-left: 4px" (click)="removeSubmittalType($event, t)"></i>
        </div>
      </div>
    </div>
    <div class="single-item-row" *ngFor="let item of cardSubs; let isLast=last"
        [ngClass]="isLast ? '': 'single-item-row-nonlast'">
      <div class="item-name-widths pinned-column">
        <div class="global-checkbox-width">
          <p-checkbox
            [(ngModel)]="item.check"
            name="global"
            [binary]="true"
            (onChange)="itemRowClicked(item)">
          </p-checkbox>
        </div>
        <div class="item-name-outer" style="padding-left: 6px">
          {{item.name}}
        </div>
      </div>
      <div class="single-item-name type-widths" *ngFor="let t of optionTypes.slice(1)">
        <div class="global-checkbox-width" *ngIf="viewStyle == 1">
          <p-checkbox
            [(ngModel)]="item.types[t.id].check"
            name="global"
            [binary]="true"
            (onChange)="singleOptionClicked(item, t)">
          </p-checkbox>
        </div>
        <div class="status-choice" *ngIf="viewStyle == 0 && dataForStatus(item.types[t.id].status)">
          <div class="status-selected" [ngClass]="item.types[t.id].status_check ? 'status-active': ''">
            <i class="bi bi-bullseye" style="padding-right: 8px"
            [ngStyle]="{'color': colorForStatus(item.types[t.id])}"></i>
            <dropdown-cell-renderer
              [options]="options?.statuses"
              [isAgGrid]="false"
              [data]="dataForStatus(item.types[t.id].status)"
              [cardView]="true"
              [credPage]="true"
              [cardViewWidth]="100"
              [colField]="'statuses'"
              (statusChanged)="onUpdateStatus($event, item, t)">
            </dropdown-cell-renderer>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
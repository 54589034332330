import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IOrderDocument } from 'src/app/dashboard/models/dashboard.model';
import { DashboardActionTypes } from '../dashboard-action-types';

export class RequestOrderDocument implements Action {
  readonly type: DashboardActionTypes.REQUEST_ORDER_DOCUMENT = DashboardActionTypes.REQUEST_ORDER_DOCUMENT;

  constructor(
    public payload?: any) { }
}
export class ReceiveOrderDocument implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ORDER_DOCUMENT = DashboardActionTypes.RECEIVE_ORDER_DOCUMENT;

  constructor(
    public payload?: IOrderDocument) { }
}
export class ErrorReceiveOrderDocument implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ORDER_DOCUMENT = DashboardActionTypes.ERROR_RECEIVE_ORDER_DOCUMENT;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class RequestAddOrderDocument implements Action {
  readonly type: DashboardActionTypes.REQUEST_ADD_ORDER_DOCUMENT = DashboardActionTypes.REQUEST_ADD_ORDER_DOCUMENT;

  constructor(
    public payload?: any) { }
}
export class ReceiveAddOrderDocument implements Action {
  readonly type: DashboardActionTypes.RECEIVE_ADD_ORDER_DOCUMENT = DashboardActionTypes.RECEIVE_ADD_ORDER_DOCUMENT;

  constructor(
    public payload?: IOrderDocument) { }
}
export class ErrorReceiveAddOrderDocument implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_ADD_ORDER_DOCUMENT = DashboardActionTypes.ERROR_RECEIVE_ADD_ORDER_DOCUMENT;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class RequestUpdateOrderDocument implements Action {
  readonly type: DashboardActionTypes.REQUEST_UPDATE_ORDER_DOCUMENT = DashboardActionTypes.REQUEST_UPDATE_ORDER_DOCUMENT;

  constructor(
    public payload?: any) { }
}
export class ReceiveUpdateOrderDocument implements Action {
  readonly type: DashboardActionTypes.RECEIVE_UPDATE_ORDER_DOCUMENT = DashboardActionTypes.RECEIVE_UPDATE_ORDER_DOCUMENT;

  constructor(
    public payload?: IOrderDocument) { }
}
export class ErrorReceiveUpdateOrderDocument implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_UPDATE_ORDER_DOCUMENT = DashboardActionTypes.ERROR_RECEIVE_UPDATE_ORDER_DOCUMENT;

  constructor(
    public payload?: HttpErrorResponse) { }
}

export class RequestDeleteOrderDocument implements Action {
  readonly type: DashboardActionTypes.REQUEST_DELETE_ORDER_DOCUMENT = DashboardActionTypes.REQUEST_DELETE_ORDER_DOCUMENT;

  constructor(
    public payload?: any) { }
}
export class ReceiveDeleteOrderDocument implements Action {
  readonly type: DashboardActionTypes.RECEIVE_DELETE_ORDER_DOCUMENT = DashboardActionTypes.RECEIVE_DELETE_ORDER_DOCUMENT;

  constructor(
    public payload?: IOrderDocument) { }
}
export class ErrorReceiveDeleteOrderDocument implements Action {
  readonly type: DashboardActionTypes.ERROR_RECEIVE_DELETE_ORDER_DOCUMENT = DashboardActionTypes.ERROR_RECEIVE_DELETE_ORDER_DOCUMENT;

  constructor(
    public payload?: HttpErrorResponse) { }
}

import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IStoreApiList, StoreApiList } from 'src/app/common/models/store-api-list.model';
import { IReceiverContact, IReceiver } from '../../models/dashboard.model';

export interface IReceiversState {
  receivers: IStoreApiItem<IReceiver>;
  receiverContacts: IStoreApiList<IReceiverContact[]>;
  receiversAutocomplete: IStoreApiItem<IReceiver>;
}

export class ReceiversState implements IReceiversState {
  receivers: IStoreApiItem<IReceiver> = new StoreApiItem();
  receiverContacts: IStoreApiList<IReceiverContact[]> = new StoreApiList();
  receiversAutocomplete: IStoreApiItem<IReceiver> = new StoreApiItem();
}

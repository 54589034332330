<div class="orders-row">
  <span class="orders-items">
    <div *ngIf="params?.value?.length"
          class="d-flex gap-3">
      <div
        style="cursor: pointer"
        *ngFor="let attachment of handleImagesPre(params?.value)"
        [ngClass]="attachment.attachment_preview ? 'orders-item' : 'orders-item orders-item--no-border'"
        [ngStyle]="isApprovals ? 
        {'display': 'flex', 'flex-direction': 'row'}:
        {}"
      >
        <!-- if it's an image open the p-image , if not call openDocumentModal -->
        <div *ngIf="!checkFileType(attachment.filename); else isImage">
          <div (click)="openDocumentModal($event, attachment.attachment, attachment.filename)" class="bg-gray-100 h-[30px] w-[20px]" >
          <p-image
            class="cell-image hover:border-2 hover:opacity-50"
            src="{{ attachment.attachment_preview ? attachment.attachment_preview : 'assets/img/default-doc-img.png' }}"
            alt="{{ attachment.filename }}"
            width="20"
            (onImageError)="handleMissingImage($event, attachment.attachment_preview)"
          >
            <ng-template pTemplate="indicator">
              <fa-icon class="icon" [icon]="faEye"></fa-icon>
            </ng-template>
          </p-image>
          </div>
        </div>
        <ng-template #isImage>
          <div [ngStyle]="isApprovals ? {
            'max-height': '30px'
          }: {}">
            <p-image
            appendTo="body"
            class="cell-image"
            src="{{ attachment.attachment ? attachment.attachment : 'assets/img/default-doc-img.png' }}"
            alt="{{ attachment.filename }}"
            [preview]="true"
            width="30"
            (onImageError)="handleMissingImage($event, attachment.attachment)"
          >
            <ng-template pTemplate="indicator">
              <fa-icon class="icon" [icon]="faEye"></fa-icon>
            </ng-template>
          </p-image>
          </div>

        </ng-template>
        <i
          class="close-icon bi bi-x-lg"
          [ngStyle]="isApprovals ? 
            {'position': 'relative', 'display': 'inline',
              'top': '1px', 'right': '-3px',
              'margin-left': '4px', 'margin-right': '4px'
            }:
            {}"
          (click)="deleteFile($event, 'attachment_id_to_delete', params.data['id'], attachment.id)"
        ></i>
      </div>
    </div>
  </span>
</div>

<p-dialog
  [(visible)]="documentModal"
  [style]="{ marginTop: '50px', width: '90vw', height: '90vh' }"
  [closeOnEscape]="true"
  [modal]="true"
  [resizable]="false"
  [draggable]="false"
  [position]="'top'"
  [closable]="false"
  [maskStyleClass]="'dialog-mask'"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <div class="dialog__header">
      <span></span>
    </div>
    <button class="dialog-close" type="button" (click)="$event.stopPropagation(); closeDocumentModal()"><i class="bi bi-x-lg"></i></button>
  </ng-template>
  <ng-template pTemplate="content">
    <iframe [src]="selectedAttachment | sanitize" allow="fullscreen" width="100%" height="98%"></iframe>
  </ng-template>
</p-dialog>

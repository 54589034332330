import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs';

import { ConfirmationService } from 'primeng/api';

import { faQuestionCircle, faUser, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { DashboardActions } from 'src/app/dashboard/state/actions/dashboard.actions';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { selectShareView, selectShareViewEmail } from 'src/app/dashboard/state/selectors/dashboard.selector';

@Component({
  selector: 'share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.sass']
})
export class ShareComponent extends OnDestroyMixin() {
  @Input() dropdownOptions: any;
  @Input() viewId: any;
  @Input() isShared: any;
  @Input() isLoading: any;
  @Input() selectedView: any;
  @Input() mailBoxVisible: boolean = false;

  sharedView: any = {
    link: '',
    emails: []
  };

  commentAccess: any = [
    {id: 1, value: 'Viewer'},
    {id: 2, value: 'Editor'}
  ];

  emailRegex = CommonConstants.emailRegex;
  showDialog: boolean = false;
  inviteTab: boolean = true;
  faShare = faUsers;
  faUser = faUser;
  faDelete = faXmark;
  faQuestion = faQuestionCircle;

  emails: any = [];
  message: any = '';

  color: any = '#76D0ED';

  tooltipMessage: string = "<strong>Weekly:</strong> sent on the selected day at <strong>10:00 AM.</strong>" ;
  oldToolTipContinue: string = "<br> <strong>First Monday:</strong> sent on the first Monday of the month at <strong>10:00 AM.</strong> <br> <strong>Last Friday:</strong> sent on the last Friday of the month at <strong>17:00 PM.</strong>";
  accessMessage: string = 'Editor can edit the following 6 fields, if visible to them in their view: (1) Status, (2) Images, (3) Order doc #, (4) Warehouse item ID, (5) Warehouse receipt date, and (6) Warehouse pallet #';


  constructor(private dashboardActions: DashboardActions,
    private store: Store<IApplicationState>,
    private confirmationService: ConfirmationService
  ) {
    super();
    (window as any)['shared'] = this;
  }

  ngOnChanges(changes: any) {
    if (changes.selectedView?.currentValue) {
      let cv = changes.selectedView.currentValue;
      if (cv.activeView) {
        this.isShared = cv.activeView.is_shared;
      }
    }
  }

  get shareText() {
    return this.mailBoxVisible ? '': 'Share';
  }

  onRequestSharedView() {
    this.sharedView = {
      link: '',
      emails: []
    };
    this.store
      .pipe(
        select(selectShareView),
        filter((shareView: IStoreApiItem<any>) => !shareView.isLoading),
        take(1)
      )
      .subscribe((shareView: IStoreApiItem<any>) => {
        this.sharedView = JSON.parse(JSON.stringify(shareView.data));
      });
  }

  openDialog() {
    if (this.viewId) {
      this.dashboardActions.requestShareView(this.viewId);
      this.onRequestSharedView();
    }

    this.showDialog = true;
  }

  handleChipAdd(event: any) {
    let evalue = event.value;
    if (!evalue) return;
    evalue = evalue.toLowerCase();
    let curEmails = this.sharedView?.emails?.map(
      (e: any) => (e.email?.toLowerCase())) || [];
    if (curEmails.includes(evalue)) {
      this.emails = this.emails.filter((e: any) => e != evalue);
    }
  }

  formatTime(time: string) {
    if (!time) return '';
    let vals = time.split('T')[0].split('-');
    return vals[1] + '/' + vals[2] + '/' + vals[0];
  }

  closeDialog() {
    this.showDialog = false;
    this.inviteTab = true;
    this.resetInvite();
  }

  selectInvite() {
    this.inviteTab = true;
  }

  selectLink() {
    this.inviteTab = false;
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.sharedView.link);
  }

  disableLink() {
    this.dashboardActions.requestDeleteShareView(this.viewId);
    this.onRequestSharedView();
  }

  emailInvite() {
    if (!this.isEmailDuplicate && !this.isNotEmail) {
      this.dashboardActions.requestUpdateShareView({
        id: this.viewId,
        emails: this.emails,
        message: this.message.length > 0 ? this.message : null,
      });
      this.store
        .pipe(
          select(selectShareView),
          filter((shareView: IStoreApiItem<any>) => !shareView.isLoading),
          take(1)
        )
        .subscribe((shareView: IStoreApiItem<any>) => {
          if (shareView.isSuccess) {
            this.resetInvite();
            this.sharedView.emails = JSON.parse(JSON.stringify(shareView.data.emails));
            this.isShared = this.sharedView.emails?.length > 0;
            if (this.selectedView.activeView) {
              this.selectedView.activeView.is_shared = this.isShared;
            }
          }
        });
    }
  }

  resetInvite() {
    this.emails = [];
    this.message = '';
  }

  deleteEmail(emailId: string) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove this user from the view ?',
      accept: () => {
        this.dashboardActions.requestDeleteShareViewEmail(emailId);
        this.store
          .pipe(
            select(selectShareViewEmail),
            filter((shareViewEmail: IStoreApiItem<any>) => !shareViewEmail.isLoading),
            take(1)
          )
          .subscribe((shareViewEmail: IStoreApiItem<any>) => {
            if (shareViewEmail.isSuccess) {
              this.sharedView.emails = JSON.parse(JSON.stringify(shareViewEmail.data));
              this.isShared = this.sharedView.emails?.length > 0;
              if (this.selectedView.activeView) {
                this.selectedView.activeView.is_shared = this.isShared;
              }
            }
          });
      },
    });
  }

  updateEmail(email: any) {
    const objToSend = JSON.parse(JSON.stringify(email));

    this.dashboardActions.requestUpdateShareViewEmail(objToSend);

    this.store
      .pipe(
        select(selectShareViewEmail),
        filter((shareViewEmail: IStoreApiItem<any>) => !shareViewEmail.isLoading),
        take(1)
      )
      .subscribe((shareViewEmail: IStoreApiItem<any>) => {
        if (shareViewEmail.isSuccess) { this.sharedView.emails = JSON.parse(JSON.stringify(shareViewEmail.data)); }
      });
  }

  get isEmailDuplicate() {
    let count = 0;

    this.emails?.forEach((input: any) => {
      if (!this.sharedView?.emails?.every((email: any) => email.email !== input)) {
        count++;
      }
    });

    return count > 0;
  }

  get isNotEmail() {
    let emailCount = 0;

    this.emails?.forEach((email: any) => {
      if (this.emailRegex.test(email)) { emailCount++; }
    });

    return emailCount !== this.emails?.length;

  }


  get sharedUpdatesFreq() {
    return this.dropdownOptions?.shared_updates_frequency?.filter(
      (v: any) => v.value.toLowerCase() != 'monthly') || []
  }

}

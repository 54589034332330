import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DividerModule } from 'primeng/divider';
import { MultiSelectModule } from 'primeng/multiselect';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SharedModule } from 'src/app/common/modules/shared.module';
import { TemplatesDialogComponent } from '../components/templates/templates-dialog.component';
import { TemplateService } from '../services/template.service';
import { templateReducer } from '../state/reducers/template.reducer';
import { TemplateEffects } from '../state/effects/template.effects';


@NgModule({
  declarations: [
    // TemplatesDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('template', templateReducer),
    EffectsModule.forFeature([TemplateEffects]),
    DialogModule,
    ConfirmDialogModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    DropdownModule,
    FontAwesomeModule,
    InputSwitchModule,
    CheckboxModule,
    FormsModule,
    DividerModule,
    MultiSelectModule
  ],
  providers: [ConfirmationService, MessageService, TemplateService]
})
export class TemplateModule { }

import { Component, OnInit, ViewChild, Input, Output, ChangeDetectorRef } from '@angular/core';
import { SelectedViewModel } from '../../models/selected-view.model';
import { EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { ElementRef, HostListener } from '@angular/core';
import { MailBoxCommunicationService } from '../../services/mailbox_comm.service';
import { selectComments, selectViews} from '../../state/selectors/dashboard.selector';
import { selectActiveJobs } from '../../state/selectors/dashboard.selector';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { CommentsService } from '../../services/comment.service';
import { DashboardActions } from '../../state/actions/dashboard.actions';
import { MentionModule } from 'angular-mentions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { deepCopy } from 'src/app/common/utils/general';
import { DashboardJobView } from '../../services/dashboard_job_view.service';
import { PayloadService } from '../../services/payload.service';
import { DashboardService } from '../../services/dashboard.service';


@Component({
  selector: 'card-controller',
  templateUrl: './card-controller.component.html',
  styleUrls: ['./card-controller.component.css'],
})
export class CardControllerComponent implements OnInit {
  @Input() selectedView?: SelectedViewModel;
  @Output() toggleFilter: EventEmitter<any> = new EventEmitter();
  @Output() selectedViewStyle: EventEmitter<any> = new EventEmitter();

  filterActive: boolean = true;

  viewStyle: number = -1;

  constructor(
    private mailCommunicationService: MailBoxCommunicationService,
    private store: Store<IApplicationState>,
    private commentsService: CommentsService,
    private dashboardActions: DashboardActions,
    private elRef: ElementRef,
    private sanitizer: DomSanitizer,
    private djv: DashboardJobView,
    private payloadService: PayloadService,
    private dashboardService: DashboardService,
    private cdr: ChangeDetectorRef
    ) {
      (window as any)['cc'] = this;
    }

  ngOnInit() {
    if (this.selectedView?.activeView?.view_style) {
      this.viewStyle = this.selectedView.activeView.view_style;
    }
  }

  ngOnChanges(changes: any) {
    if (changes?.selectedView?.currentValue?.activeView) {
      this.viewStyle = changes.selectedView.currentValue.activeView.view_style;
      this.selectedViewStyle.emit(this.viewStyle);
    }
  }

  changeViewStyle(i: any) {
    if (this.selectedView?.activeView) {
      this.viewStyle = i;
      this.selectedViewStyle.emit(this.viewStyle);
      let data = {
        selectedViewId: this.selectedView?.activeView.id,
        view_style: i
      };
      this.dashboardService.saveView({ payload: data}).subscribe((val: any) => {
        if (i == 0) {
          this.dashboardActions.requestViews({ ...this.payloadService.setViewsPayload() });
        }        
      });
      this.djv.close_comments();
      this.djv.close_email();
      this.djv.side_panel_click({'page': 'items'});
    }
  }

  filterToggle() {
    this.filterActive = !this.filterActive;
    this.toggleFilter.emit(this.filterActive);
  }
}
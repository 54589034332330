<div class="filters">
  <span
    (click)="showHistoryModal($event)"
    [ngClass]="viewHistoryOption != 0 ? 'filters-background' : 'filters-default'"
  >
    <fa-icon [icon]="faClockRotateLeft"></fa-icon>
  </span>
  <div
    class="filters__container-history"
    *ngIf="toggleHistoryModal && !isLoading"
    (outsideClick)="clickOutsideModal()"

  >
    <div class="filters__container-history-header">View changes made in last</div>
    <div class="filters__container-form">
      <ng-container *ngFor="let radio of dropdownOptions">
        <p-radioButton
          [(ngModel)]="viewHistoryOption"
          name="history"
          [value]="radio.id"
          [label]="radio.value"
          (onClick)="emitUpdateHistory($event.value)"
        >
        </p-radioButton>
      </ng-container>
    </div>
    <button type="button" class="filters__container-button" (click)="onClearHistory()">Hide change history</button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IUserState } from '../models/user.model';
import { RequestNotifications, RequestUpdateNotification } from './user-action-types-creators/notifications-action-types-creators';
import { RequestUpdateUserPassword } from './user-action-types-creators/password-action-types-creators';
import { RequestUpdateUserProfile, RequestUserProfile } from './user-action-types-creators/user-profile-action-types-creators';


@Injectable({ providedIn: 'root' })
export class UserActions {
  constructor(private store: Store<IUserState>) { }

  public requestNotifications(params?: any): void {
    this.store.dispatch(new RequestNotifications(params));
  }

  public requestUpdateNotification(params: any): void {
    this.store.dispatch(new RequestUpdateNotification(params));
  }

  public requestUserProfile(params?: any): void {
    this.store.dispatch(new RequestUserProfile(params));
  }

  public requestUpdateUserProfile(params: any): void {
    this.store.dispatch(new RequestUpdateUserProfile(params));
  }

  public requestUpdateUserPassword(params: any): void {
    this.store.dispatch(new RequestUpdateUserPassword(params));
  }
}

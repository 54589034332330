import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Injector, OnInit, Renderer2, ViewChild } from "@angular/core";
import { select, Store } from '@ngrx/store';
import { NavigationStart, Router } from '@angular/router';
import { catchError, filter, Observable, of, takeUntil, Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

import { MessageService } from 'primeng/api';

import { OnDestroyMixin } from '../common/mixins/destroy-mixin';
import { IStoreApiItem } from '../common/models/store-api-item.model';
import { IApplicationState } from '../common/state/models/app.state.model';
import { IActiveJobs } from './models/dashboard.model';
import { DashboardActions } from './state/actions/dashboard.actions';
import { selectActiveJobs, selectOrderDocument, selectViews } from './state/selectors/dashboard.selector';
import { ICurrentView } from './interfaces/current-view.interface';
import { ActiveJobModel } from './models/active-job.model';
import { SelectedViewModel } from './models/selected-view.model';
import { ViewModel } from './models/view.model';
import { CustomErrorService } from './services/custom-error.service';
import { JobTableService } from './services/job-table.service';
import { OptionsService } from './services/options.service';
import { CommonConstants } from '../common/constants/common.constants';
import { PermissionsService } from '../root/services/permissions.service';
import { PayloadService } from './services/payload.service';
import { MailBoxCommunicationService } from './services/mailbox_comm.service';
import { selectUserProfile } from '../user-settings/state/selectors/user.selector';
import { DashboardService } from './services/dashboard.service';
import { JobTableV2Component } from './components/job-table-v2/job-table-v2.component';
import { DashboardJobView } from './services/dashboard_job_view.service';
import { initData } from './components/mail-view/utils';
import { JobsService } from './services/job.service';


@Component({
  templateUrl: './dashboard-v2.component.html',
  styleUrls: ['./dashboard-v2.component.sass']
})
export class DashboardV2Component  extends OnDestroyMixin() implements AfterViewInit  {
  itemPageActive: boolean = true;
  supplierPageActive: boolean = false;
  jobPageActive: boolean = false;
  notifPageActive: boolean = false;
  userSettingsActive: boolean = false;
  companySettingsActive: boolean = false;
  approvalPageActive: boolean = false;
  mailBoxVisible: boolean = false;
  draftBoxVisible: boolean = false;
  commentPaneVisible: boolean = false;

  isPasting: boolean = false;

  currentView!: ICurrentView;
  selectedView?: SelectedViewModel;
  isLoading: boolean = true;
  isLoadingViews: boolean = true;
  isLoadingOverlay: boolean = true;
  showHistory: boolean = false;

  bufferHeight: number = 94;
  panelHeight: any = this.setPanelHeight();
  verticalPanes: any = this.verticalPanelSizes();
  horizontalPanes: any = this.horizontalPanelSizes();

  dashboardContainerSize: number = 85;
  sidePanelSize: number = 15;
  jobListContainerSize!: number;
  agGridContainerSize!: number;
  mailContainerSize!: number;
  trackContainerSize!: number;
  showTracking: boolean = false;
  maxPercentMail = 40;
  maxPercentTrack = 20;
  tog_size = 1.5;
  left_pane = 12;

  sidePanelOpen: boolean = true;
  innerWidth!: number;
  orgUserEmails!: [];
  dropdownOptions: any;
  dropdownOptionsLoaded: boolean = false;

  activeJobs: ActiveJobModel[] = [];
  showOnboardingDialog = false;
  dialogOpen = false;
  responseItems: any;

  allJobsOption = {id: 'all', value: 'All Jobs'};
  allSuppliersOption = {id: 'all', value: 'All Suppliers'};
  allOrdersOption = {id: 'all', value: 'All Orders'};

  curJobs!: Map<string, string>;
  jobsArray!: [];
  curOrders: any = undefined;
  selectedOrder!: any;
  activeJobsList!: [];
  selectedJob: any;
  supArray!: [];
  selectedSupplier: any;

  initDataRetrieved: boolean = false;
  // permissionService: PermissionsService;

  private resultsPerPage: number = CommonConstants.resultsPerPage;
  excludedFieldsForOrderUpdate = ['status_color', 'room_id', 'updated_datetime', 'id'];
  @ViewChild(JobTableV2Component) job_table!: JobTableV2Component;

  constructor(
    readonly optionsService: OptionsService,
    private store: Store<IApplicationState>,
    private messageService: MessageService,
    private dashboardActions: DashboardActions,
    private jobTableService: JobTableService,
    private customErrorService: CustomErrorService,
    private payloadService: PayloadService,
    public router: Router,
    private injector: Injector,
    private renderer: Renderer2,
    private mailBoxComm: MailBoxCommunicationService,
    private cdRef: ChangeDetectorRef,
    private dashboardService: DashboardService,
    private permissionService: PermissionsService,
    private mailCommunicationService: MailBoxCommunicationService,
    private djv: DashboardJobView,
    private jobService: JobsService,
  ) {
    super();
    (window as any)['dash'] = this;
    this.isLoading = true

    this.initSelectViewsSubscription();
    this.optionsService.initOptionsSubscription();
    this.initActiveJobListSubscription();

    this.optionsService.dropdownOptionsObs.subscribe(options => {
      this.dropdownOptions = options;
      this.dropdownOptionsLoaded = true;
    });

    document.addEventListener('paste', (e) => {
      this.pasteEvent();
    }, true);

    document.addEventListener('beforepaste', (e) => {
      this.pasteEvent();
    }, true);

    this.mailCommunicationService.mailVisibility$.subscribe((data: any) => {
      if (!data.isVisible) {
        this.showTracking = false;
      } else {
        this.showTracking = true;
      }
      this.setPanelSize();
    });

    this.getUsers();
    this.orderDocStoreSelector();

    this.setPanelSize();
    this.calculateMaxWidth();

    if (JSON.parse((localStorage.getItem('selectedJob') || null as any)) == 'uncategorized') {
      localStorage.removeItem('selectedJob');
      localStorage.removeItem('viewIdForuncategorized');
    }
    let savedJobId = localStorage.getItem('selectedJob') as any;
    if (!savedJobId) {
      this.triggerRequestViewsInitial();
    } else {
      this.triggerRequestViews();
    }

    this.dashboardActions.requestActiveJobs();
    this.dashboardActions.requestOptions();
    this.dashboardActions.requestSuppliersSmall();
    localStorage.setItem('searchPhrase', '');

    this.djv.viewChoseButtonClick$.subscribe((data: any) => {
      if (!data) return;
      this.activePage({'page': 'items'});
      if (data.deleted) {
        this.onViewDeleted(data.deleted);
      } else if (data.retrieve) {
        this.onViewRetrieved(data.retrieve);
      }
    });

    this.djv.sidePanelClick$.subscribe((data: any) => {
      this.activePage(data);
    });

    this.djv.closeComments$.subscribe(() => {
      this.close_comments();
    });
    this.djv.closeEmail$.subscribe(() => {
      this.close_inbox();
    });
    this.djv.getNewViews$.subscribe(() => {
      this.triggerRequestViews();
    });

    window.addEventListener('resize', (event) => {
      this.calculateMaxWidth();
      this.panelHeight = this.setPanelHeight();
      this.verticalPanes = this.verticalPanelSizes();
      this.horizontalPanes   = this.horizontalPanelSizes();
    });
    // initData.bind(this)();
  }

  ngAfterViewInit(): void {
    this.verticalPanes = this.verticalPanelSizes();
    this.horizontalPanes = this.horizontalPanelSizes();
    this.setPanelSize();
    const mailPanelTemplate: HTMLElement | null = document.querySelector('ng-template[pTemplate="mailPage"]');

    if (mailPanelTemplate) {
      mailPanelTemplate.style.display = this.mailBoxVisible ? 'block' : 'none';
    }
  }

  pasteEvent() {
    this.isPasting = true;
    this.cdRef.detectChanges();
    setTimeout(() => {
      this.isPasting = false;
    }, 2000);
  }

  get mailVisible() {
    return this.mailBoxVisible || this.draftBoxVisible;
  }

  get sideDisplayVisible() {
    return this.mailBoxVisible || this.draftBoxVisible || this.commentPaneVisible;
  }

  get allMailData() {
    return {
      curJobs: this.curJobs,
      jobsArray: this.jobsArray,
      activeJobsList: this.activeJobsList,
      supArray: this.supArray,
      curOrders: this.curOrders,
      selectedJob: this.selectedJob,
      selectedSupplier: this.selectedSupplier,
      selectedOrder: this.selectedOrder,      
    }
  }

  filterActive: boolean = true;
  toggleFilter(event: any) {
    this.filterActive = event;
  }


  close_inbox() {
    this.mailBoxVisible = false;
    this.draftBoxVisible = false;
    this.mailBoxComm.get_email({show: false});
  }

  toggle_inbox(with_drafts: boolean) {
    if (with_drafts) {
      this.draftBoxVisible = !this.draftBoxVisible;
      this.mailBoxVisible = false;
    } else {
      this.mailBoxVisible = !this.mailBoxVisible;
      this.draftBoxVisible = false;
    }
    this.mailBoxComm.get_email(
      {
        show: this.mailBoxVisible || this.draftBoxVisible, drafts: with_drafts
      });
  }

  close_comments() {
    this.commentPaneVisible = false;
    this.mailBoxComm.get_comments(false);
  }

  toggle_comments() {
    this.commentPaneVisible = !this.commentPaneVisible;
    this.mailBoxComm.get_comments(this.commentPaneVisible);
  }

  inactivatePages(which: string) {
    this.itemPageActive = which == 'items';
    this.supplierPageActive = which == 'suppliers';
    this.jobPageActive = which == 'jobs';
    this.userSettingsActive = which == 'user-settings';
    this.companySettingsActive = which == 'company-settings';
    let notifPageActive = which == 'notifications'
    let approvalPageActive = which == 'confirmations';
    if (approvalPageActive || notifPageActive) {
      this.close_inbox();
      this.close_comments();
    }
    this.notifPageActive = notifPageActive;
    this.approvalPageActive = approvalPageActive;
  }

  activePage(data: any) {
    if (data.page) {
      this.inactivatePages(data.page);
    }
    // if card view open
    if (this.selectedViewStyle == 1 && this.itemPageActive) {
      this.close_comments();
      this.close_inbox();
      this.djv.cardview_icon_toggle(data);
      return;
    }
    if (data.icon) {
      if (this.approvalPageActive) {
        this.approvalPageActive = false;
        this.itemPageActive = true;
      }
      if (data.icon == 'emails') {
        this.close_comments();
        this.toggle_inbox(false);
      } else if (data.icon == 'comments') {
        this.close_inbox();
        this.toggle_comments();
      } else if (data.icon == 'drafts') {
        this.close_comments();
        this.toggle_inbox(true);
      }
    }
  }

  selectedViewStyle: number = -1;
  onSelectViewStyle(event: any) {
    this.selectedViewStyle = event;
  }

  setPanelHeight() {
    return window.innerHeight - 22; // buffer to avoid scroll bar
  }

  verticalPanelSizes() {
    const vph = window.innerHeight;
    const filter_display = 36;
    const jobsHeight = vph - filter_display;
    return [100 * (filter_display / vph) , 100 * (jobsHeight / vph)] ;
  }

  horizontalPanelSizes() {
    const vpw = window.innerWidth;
    const sp_width = 200;
    const jobsHeight = vpw - sp_width;
    return [100 * (sp_width / vpw) , 100 * (jobsHeight / vpw)] ;
  }

  onSidePanelToggle() {
    this.sidePanelOpen = !this.sidePanelOpen;
    this.setPanelSize();
  }



  calculateMaxWidth() {
    let vpw = window.innerWidth;
    this.innerWidth = vpw * this.dashboardContainerSize;
  }

  setPanelSize() {
    this.calculateMaxWidth();
    this.trackContainerSize = this.showTracking && this.mailBoxVisible ? this.maxPercentTrack : .05;
    this.mailContainerSize = this.mailBoxVisible || this.commentPaneVisible ? this.maxPercentMail: .05;
    this.agGridContainerSize = 100 - this.trackContainerSize - this.mailContainerSize;
  }


  get activeJob(): ActiveJobModel {
    let savedJobId = localStorage.getItem('selectedJob') as any;

    if (savedJobId === 'undefined') { localStorage.removeItem('selectedJob') }
    if (!!savedJobId) { savedJobId = JSON.parse(savedJobId); }

    const _activeJob = this.activeJobs.find((activeJob: ActiveJobModel) => activeJob.isSelected);


    return savedJobId === 'uncategorized'
      ? new ActiveJobModel('', 'Uncategorized items')
      : savedJobId === 'all'
        ? new ActiveJobModel('', 'All jobs')
        : _activeJob || new ActiveJobModel('', '');
  }


  onViewRetrieved(data: any) {
    let view = data.view ? data.view: null;
    if (!view) return;
    let vm = ViewModel.fromJSON(view as any);
    if (this.selectedView) {
      let updatedViews = [...this.selectedView.views];
      const viewIndex = updatedViews.findIndex(v => v.id === vm.id);
      if (viewIndex !== -1) {
        updatedViews[viewIndex] = vm;
      } else {
        updatedViews.push(vm);
      }
      let data = {...this.selectedView, views: updatedViews};
      let new_sel = SelectedViewModel.fromJSON({...data});
      new_sel.setViewAsSelected(vm.id);
      this.selectedView = new_sel;
      this.cdRef.detectChanges();
    }
  }

  onViewDeleted(view_id: any) {
    if (this.selectedView) {
      let updatedViews = [...this.selectedView.views];
      const viewIndex = updatedViews.findIndex(v => v.id === view_id);
      if (viewIndex !== -1) {
        updatedViews.splice(viewIndex, 1);
      }
      this.selectedView = SelectedViewModel.fromJSON({...this.selectedView, views: updatedViews});
    }
  }

  onViewSelect(viewId: string): void {
    let savedJobId = localStorage.getItem('selectedJob') as any;

    if (!!savedJobId) {
      savedJobId = JSON.parse(savedJobId);
      localStorage.setItem(`viewIdFor${savedJobId}`, JSON.stringify(viewId));
    }
    this.selectedView = SelectedViewModel.fromJSON({...this.selectedView});
    this.cdRef.detectChanges();
  }

  onUpdateLoadingOverlay() {
    this.isLoadingOverlay = true;
  }

  onUpdateSearchTerm(term: any){
    localStorage.setItem('searchterm', term);
    let keyst = ""+this.selectedView?.activeView.id;
    let search_term = term.hasOwnProperty(keyst) ? term[keyst]: undefined;
    localStorage.setItem('searchPhrase', search_term);
    this.jobTableService.setSearchFilter(term);
  }

  onUpdateImageSize(size:string){
    localStorage.setItem('image_size',size);
    this.jobTableService.setImageSize(size);
  }

  onSelectedJob(id: string): void {
    localStorage.setItem('selectedJob', JSON.stringify(id));
    this.triggerRequestViews();
    this.isLoadingViews = true;
    this.djv.loading_views(this.isLoadingViews);
  }

  orgSlug = '';
  private getUsers(): void {
    this.store
      .pipe(
        select(selectUserProfile),
      )
      .subscribe((userProfile: any) => {
        if (userProfile?.data?.org_slug) {
          this.orgSlug = userProfile.data.org_slug;
        }
        if (userProfile?.data?.all_users && userProfile.data.all_users.length) {
          this.orgUserEmails = userProfile.data.all_users;
        }
      });
  }

  private initSelectViewsSubscription(): void {

    this.store.pipe(
      select(selectViews),
      filter((views: IStoreApiItem<any>) => !views.isLoading),
      takeUntil(this.destroy)
    )
      .subscribe((views: IStoreApiItem<any>) => {
        if (!views.errors) {
          if (views.data?.results) {
            if (!this.initDataRetrieved) {
              initData.bind(this)();
              this.djv.request_metadata();
              this.initDataRetrieved = true;
            }
            // response from first loading the view
            if (this.isLoading || this.isLoadingOverlay) {
              // switched jobs from side panel
              this.selectedView = SelectedViewModel.fromJSON({ resultsPerNextPage: this.resultsPerPage, ...views.data });
              this.onViewSelect(this.selectedView.activeView?.id || '');

            } else {
              const selectedViewId = views.data?.views?.find((view: any) => view?.is_selected)?.id;
              const lastSelectedViewId = this.selectedView?.views?.find((view: any) => view.is_selected)?.id;
              if (views.data?.views?.length) {
                if (lastSelectedViewId !== selectedViewId) {
                  this.onViewSelect(selectedViewId);
                }
                this.selectedView = SelectedViewModel.fromJSON({ resultsPerNextPage: this.resultsPerPage, ...views.data});
                this.cdRef.detectChanges();
              }
            }
            this.isLoading = false;
            this.isLoadingViews = false;
            this.isLoadingOverlay = false;
            this.cdRef.detectChanges();
            setTimeout(() => {
              this.djv.is_loading(this.isLoading);
              this.djv.loading_views(this.isLoadingViews);
            }, 0);

          }
        }

        if (!!views.errors) {
          this.customErrorService.setCustomErrorMessage(views);

          if (views?.errors?.status === 400 && Object.keys(CommonConstants.errorMessages).includes(Object.keys(views?.errors?.error)[0])) {
            this.dashboardActions.requestViews({ ...this.payloadService.setViewsPayload() });
          }

          if (views?.errors?.status === 400 && (Object.keys(views?.errors?.error)[0] === 'view_id' || Object.keys(views?.errors?.error)[0] === 'job_id')) {
            let savedJobId = localStorage.getItem('selectedJob') as any;

            if (!!savedJobId) {
              savedJobId = JSON.parse(savedJobId);
              localStorage.removeItem(`viewIdFor${savedJobId}`);
              localStorage.removeItem('selectedJob');
              window.location.reload();
            }
          }
        }
      });
  }

  private orderDocStoreSelector() {
    this.store.pipe(
      select(selectOrderDocument),
      // filter((orderDocument: IStoreApiItem<any>) => !orderDocument.isLoading),
      takeUntil(this.destroy)
    )
      .subscribe((orderDocument: any) => {
        if (!orderDocument?.data?.order_id) return;
        let items = this.selectedView?.results.filter((j: any) => j.order_id == orderDocument.data.order_id);
        let order_data = orderDocument.data;
        if (items) {
          items.forEach((item: any) => {
            if (order_data.po_document_number) {
              item.po_document_number = order_data.po_document_number;
              item.po_document_attachments = order_data.po_document_attachments;
              item.po_document_date = order_data.po_document_date;
            } else if (order_data.supplier_documents_numbers) {
              item.supplier_documents_numbers = order_data.supplier_documents_numbers;
              item.supplier_documents_attachments = order_data.supplier_documents_attachments;
              item.supplier_documents_earliest_document_date = order_data.supplier_documents_earliest_document_date;
              item.supplier_documents_types = order_data.supplier_documents_types;
            }
          });
          if (this.job_table) {
            this.job_table.applyTransactionOnTable(items);
          }
        }
      });
  }

  private initActiveJobListSubscription(): void {
    this.store.pipe(
      select(selectActiveJobs),
      filter((activeJobs: IStoreApiItem<IActiveJobs>) => !activeJobs.isLoading && !!activeJobs.data),
      takeUntil(this.destroy)
    ).subscribe((activeJobs: IStoreApiItem<any>) => {
      if (activeJobs?.data) {
        this.activeJobs = activeJobs.data.map((activeJob: IActiveJobs) => ActiveJobModel.fromJSON(activeJob));
        this.showOnboardingDialog = !this.activeJobs?.length;
        if (this.payloadService.setViewsPayload().view_id || this.payloadService.setViewsPayload().job_id) {
          // pass
        } else {
          if (this.activeJobs?.length) {
            localStorage.setItem('selectedJob', JSON.stringify(this.activeJobs[0].id));
          } else {
            localStorage.setItem('selectedJob', JSON.stringify('all'));
          }
          if (this.selectedView?.activeView?.id) {
            this.onViewSelect(this.selectedView?.activeView?.id);
          }
        }

      }
    });
  }

  private triggerRequestViewsInitial(): void {
    this.isLoading = true;
    this.djv.is_loading(this.isLoading);
    let payload = { ...this.payloadService.setViewsPayload(), initial_request: true };
    this.dashboardActions.requestViews(payload);
  }

  private triggerRequestViews(): void {
    this.isLoading = true;
    this.djv.is_loading(this.isLoading);
    this.dashboardActions.requestViews({ ...this.payloadService.setViewsPayload() });
  }

}

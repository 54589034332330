import { GenericAction } from 'src/app/common/state/models/generic-action.model';
import { errorItemData, errorPostPutItemData, receiveItemData, receivePostPutItemData, requestItemData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IJobs, INewJob, IUpdateJob } from 'src/app/dashboard/models/dashboard.model';
import { DashboardActionTypes } from '../actions/dashboard-action-types';
import { IJobsState, JobsState } from '../models/jobs.model';

const resetJobsCRUDState = (lastState: IJobsState): IJobsState => ({
  ...lastState,
  jobs: {
    ...lastState.jobs,
    errors: null as any,
    isSuccess: null as any,
  },
});

export function jobsReducer(
  lastState: IJobsState = new JobsState(),
  action: GenericAction<DashboardActionTypes, any>
): IJobsState {
  switch (action.type) {

    case DashboardActionTypes.REQUEST_JOBS:
      return requestItemData<IJobsState, IJobs>(lastState, ['jobs']);
    case DashboardActionTypes.RECEIVE_JOBS:
      return receiveItemData<IJobsState, IJobs>(lastState, ['jobs'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_JOBS:
      return errorItemData<IJobsState, IJobs>(lastState, ['jobs'], action.payload);

    case DashboardActionTypes.REQUEST_ADD_JOB:
      return requestPostPutItemData<IJobsState, INewJob>(lastState, ['jobs'], action.payload);
    case DashboardActionTypes.RECEIVE_ADD_JOB:
      return receivePostPutItemData<IJobsState, IJobs>(lastState, ['jobs'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_ADD_JOB:
      return errorPostPutItemData<IJobsState, INewJob>(lastState, ['jobs'], action.payload);

    case DashboardActionTypes.REQUEST_JOB_LIST:
      return requestPostPutItemData<IJobsState, IJobs[]>(lastState, ['jobsList'], action.payload);
    case DashboardActionTypes.RECEIVE_JOB_LIST:
      return receivePostPutItemData<IJobsState, IJobs[]>(lastState, ['jobsList'], action.payload);
    case DashboardActionTypes.ERROR_JOB_LIST:
      return errorPostPutItemData<IJobsState, IJobs[]>(lastState, ['jobsList'], action.payload);

    case DashboardActionTypes.REQUEST_UPDATE_JOB:
      return requestPostPutItemData<IJobsState, IUpdateJob>(lastState, ['jobs'], action.payload);
    case DashboardActionTypes.RECEIVE_UPDATE_JOB:
      return receivePostPutItemData<IJobsState, IJobs>(lastState, ['jobs'], action.payload);
    case DashboardActionTypes.ERROR_RECEIVE_UPDATE_JOB:
      return errorPostPutItemData<IJobsState, IJobs>(lastState, ['jobs'], action.payload);

    case DashboardActionTypes.RESET_CRUD_JOB_STATE:
      return resetJobsCRUDState(lastState);

    default:
      return lastState;
  }
}
